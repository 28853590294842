import {Channel as TypeChannel, ChannelFilters, ChannelSort, DefaultGenerics, StreamChat} from "stream-chat";
import {
  Avatar,
  Channel,
  ChannelHeader,
  ChannelList,
  ChannelPreviewUIComponentProps,
  ChannelSearchFunctionParams,
  ChannelSearchProps,
  Chat,
  InfiniteScroll,
  LoadingIndicator,
  MessageList,
  Window
} from "stream-chat-react";
import "stream-chat-react/dist/css/v2/index.css";
import "./DispatchMessaging.css";
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {AuthContext} from "../AuthProvider";
import {useAppContext} from "../../ApplicationContext";
import {CustomChannelPreview, ChatTitle, CustomSearchResultItem} from "./DispatchMessagingCommon";
import styled from "@emotion/styled";
import {Button, Drawer} from "@blueprintjs/core";
import {InforIcon} from "../job/AssignmentBarV2";
import {DefaultStreamChatGenerics} from "stream-chat-react/dist/types/types";
import useChatUserDetails from "./useChatUserDetails";
import {FeatureFlagContext} from "../../providers/FeatureFlagProvider";
import {DispatchGroupDataContext, DispatchGroupDataState} from "../common/DispatchGroupDataProvider";
import {CustomMessage} from "./CustomMessage";
import {PreferenceContext, extractJsonPref} from "../../providers/PreferenceProvider";
import {UserPreferences} from "../common/Constants";
import {DEFAULT_CARD_CONFIGURATIONS_VALUE} from "../manifest/card-configuration/ManifestCardConfigurationDialog";
import {IConfigurationFormValues} from "../manifest/card-configuration/ManifestCardConfiguration";
import DispatchMessageInput from "./DispatchMessageInput";

type DispatchMessagingProps = {
  driverId?: number;
};

export type TSetSeletedChannel = (channel: TypeChannel<DefaultStreamChatGenerics> | undefined) => Promise<void>;

const sort: ChannelSort<DefaultGenerics> = {last_message_at: -1, has_unread: -1};

export const getDisplayTitle = (
  channel: TypeChannel<DefaultStreamChatGenerics> | undefined,
  cardConfigurationState: IConfigurationFormValues
): string => {
  const retVal = cardConfigurationState.driverName ? channel?.data?.name : (channel?.data?.driver_code as string);
  return retVal ?? "";
};

const DispatchMessaging = ({driverId}: DispatchMessagingProps) => {
  const authContext = useContext(AuthContext);
  const {appState, dispatch} = useAppContext();
  const {selectedDriverChannelId, isChatOpen} = appState;
  const {driverMessaging} = useContext(FeatureFlagContext);
  const chatUserDetails = useChatUserDetails(driverMessaging);
  const dispatchGroupData = useContext<DispatchGroupDataState>(DispatchGroupDataContext);
  const [chatClient, setChatClient] = useState<StreamChat | null>();
  const [filters, setFilters] = useState<ChannelFilters>({});
  const [selectedChannel, setSelectedChannel] = useState<TypeChannel<DefaultStreamChatGenerics> | undefined>();
  const {userPreferences} = useContext(PreferenceContext);
  const cardConfigurationState = extractJsonPref(
    userPreferences,
    UserPreferences.manifestCardConfigurations,
    DEFAULT_CARD_CONFIGURATIONS_VALUE
  ).value as IConfigurationFormValues;

  const channelTitle = getDisplayTitle(selectedChannel, cardConfigurationState);

  const customSearchFunction = async (
    props: ChannelSearchFunctionParams,
    event: {target: {value: string}},
    client: StreamChat
  ) => {
    // eslint-disable-next-line react/prop-types
    const {setResults, setSearching, setQuery} = props;
    const value = event.target.value;
    const customFilters: ChannelFilters = {
      ...filters,
      $and: [
        {
          $or: [{name: {$autocomplete: value}}, {driver_code: {$autocomplete: value}}]
        }
      ]
    };
    setQuery(value);
    if (!value.trim()) return;
    setSearching(true);
    const channels = await client.queryChannels(customFilters);
    setResults(channels);
    setSearching(false);
  };

  const onSelecteChannel: TSetSeletedChannel = useCallback(
    async (channel: TypeChannel<DefaultStreamChatGenerics> | undefined) => {
      if (channel) {
        setSelectedChannel(channel);
      }
    },
    []
  );

  const additionalChannelSearchProps: Omit<ChannelSearchProps<DefaultGenerics>, "setChannels"> = useMemo(() => {
    return {
      searchFunction(params, event) {
        if (chatClient) {
          return customSearchFunction(params, event, chatClient);
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      SearchResultItem: (props) => CustomSearchResultItem({...props, setSelectedChannel: onSelecteChannel})
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, chatClient]);

  const renderAvatar = useCallback(
    () => <Avatar name={selectedChannel?.data?.name} size={39} />,
    [selectedChannel?.data?.name]
  );

  const renderMessage = useCallback(() => {
    return <CustomMessage driverTitle={channelTitle} />;
  }, [channelTitle]);

  const renderPreviewChannel = useCallback(
    (props: ChannelPreviewUIComponentProps<DefaultGenerics>) => (
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      <CustomChannelPreview
        {...props}
        setSelectedChannel={onSelecteChannel}
        active={selectedChannel?.cid === props?.channel?.cid}
      />
    ),
    [onSelecteChannel, selectedChannel?.cid]
  );

  const renderBody = () => {
    if (!chatClient) {
      return <LoadingIndicator />;
    }
    return (
      <Chat client={chatClient}>
        <Container>
          <LeftContainer data-testid="chat-modal">
            <ChatTitle />
            <ChannelList
              filters={filters}
              sort={sort}
              showChannelSearch
              setActiveChannelOnMount={false}
              Paginator={InfiniteScroll}
              allowNewMessagesFromUnfilteredChannels={false}
              Preview={renderPreviewChannel}
              additionalChannelSearchProps={additionalChannelSearchProps}
            />
          </LeftContainer>
          <RightContainer>
            <CloseButton
              minimal
              icon="cross"
              data-testid="close-chat-button"
              onClick={() => {
                dispatch({
                  type: "SetIsChatOpen",
                  payload: false
                });
              }}
            />
            {selectedChannel && appState.isChatOpen ? (
              <Channel channel={selectedChannel}>
                <Window>
                  <ChannelHeader title={channelTitle} Avatar={renderAvatar} />
                  <MessageList Message={renderMessage} messageActions={[]} returnAllReadData scrolledUpThreshold={50} />
                  <DispatchMessageInput />
                </Window>
              </Channel>
            ) : (
              <NoChannelContainer>
                <InforText>
                  <InforIcon />
                  <span>Select the Driver to See the Chat or Send a Message</span>
                </InforText>
              </NoChannelContainer>
            )}
          </RightContainer>
        </Container>
      </Chat>
    );
  };

  useEffect(() => {
    const dispatchGroupIds = dispatchGroupData.driverGroups.map((driverGroup) => driverGroup.dispatchGroupId);
    let retValue = {
      team: {$eq: authContext.tenantId},
      type: {$eq: "driver"}
    } as any;
    if (!authContext.isSuperUser) {
      retValue = {
        ...retValue,
        members: {$in: [chatUserDetails?.userId]}
      };
    }
    if (dispatchGroupIds.length > 0) {
      retValue = {
        ...retValue,
        dispatch_group_id: {$in: dispatchGroupIds}
      };
    }
    setFilters(retValue);
  }, [authContext.tenantId, chatUserDetails?.userId, dispatchGroupData.driverGroups, authContext.isSuperUser]);

  useEffect(() => {
    setSelectedChannel(undefined);
  }, [filters]);

  useEffect(() => {
    if (chatUserDetails) {
      console.debug("Connecting to streamchat");
      let wasInterrupted = false;
      const client = StreamChat.getInstance(chatUserDetails.apiKey);
      const connectionPromise = client
        .connectUser({id: chatUserDetails.userId}, chatUserDetails.userToken)
        .then(async (res) => {
          if (!wasInterrupted) {
            dispatch({
              type: "SetTotalUnreadChannel",
              payload: res?.me?.unread_channels ?? 0
            });
            client.on((event) => {
              console.debug("Messaging event", event);
              if (event.unread_channels !== undefined) {
                dispatch({
                  type: "SetTotalUnreadChannel",
                  payload: event.unread_channels ?? 0
                });
              }
            });
            setChatClient(client);
            console.debug("Connected to streamchat!");
          } else {
            setChatClient(undefined);
          }
        });
      return () => {
        wasInterrupted = true;
        connectionPromise
          .then(() => {
            client.disconnectUser();
            dispatch({type: "ResetMessageState"});
          })
          .then(() => console.debug("Chat disconnected"));
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatUserDetails?.userToken]);

  useEffect(() => {
    if (chatClient && selectedDriverChannelId) {
      chatClient.on((event) => {
        if (event.cid === selectedDriverChannelId) {
          if (event.type === "notification.mark_read") {
            dispatch({
              type: "SetSelectedDriverUnreadCount",
              payload: 0
            });
          }
          //check is message from the driver
          if (event.type === "message.new" && event?.user?.role === "driver") {
            dispatch({
              type: "SetSelectedDriverUnreadCount",
              payload: 1
            });
          }
        }
      });
    }
  }, [chatClient, dispatch, selectedDriverChannelId]);

  useEffect(() => {
    if (driverId === undefined) {
      dispatch({type: "SetSelectedDriverChannelId", payload: undefined});
      dispatch({type: "SetSelectedDriverUnreadCount", payload: 0});
      setSelectedChannel(undefined);
    } else if (chatClient) {
      chatClient
        .queryChannels({
          ...filters,
          driver_id: {
            $eq: driverId
          }
        })
        .then((queryDriverChannelRes) => {
          if (queryDriverChannelRes.length > 0) {
            const driverChannel = queryDriverChannelRes[0];
            dispatch({type: "SetSelectedDriverChannelId", payload: driverChannel.cid});
            dispatch({type: "SetSelectedDriverUnreadCount", payload: driverChannel.state.unreadCount > 0 ? 1 : 0});
            setSelectedChannel(driverChannel);
          }
        });
    }
  }, [chatClient, dispatch, driverId, filters]);

  return (
    <StyledChat
      isShow={isChatOpen}
      isOpen={true}
      enforceFocus={false}
      isCloseButtonShown={true}
      canOutsideClickClose={false}
      hasBackdrop={false}
      position="bottom-right"
      portalClassName="chat-portal"
    >
      {renderBody()}
    </StyledChat>
  );
};

export default DispatchMessaging;

const StyledChat = styled(Drawer)<{isShow: boolean}>`
  font-family: "Roboto", sans-serif;
  height: ${(props) => (props.isShow ? "50vh" : "0 !important")};
  min-height: ${(props) => (props.isShow ? "530px" : "0 !important")};
  bottom: 12px !important;
  right: 12px !important;
  left: calc(100vw - 600px) !important;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px #00000040;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const LeftContainer = styled.div`
  width: 224px;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  flex-direction: column;
  flex: 1 1;
`;

const RightContainer = styled.div`
  position: relative;
  flex-grow: 1;
  visibility: visible;
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1000;
`;

const NoChannelContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InforText = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  justify-content: center;
  max-width: 70%;
`;
