import React from "react";
import {add} from "date-fns";
import {isSameDate} from "../../../utils/General";
import DatePickerToolbarButton from "../date-picker/DatePickerToolbarButton";
import DatePickerHeader from "../date-picker/DatePickerHeader";
import DatePickerToolbar from "../date-picker/DatePickerToolbar";
import ManifestDatePicker from "../date-picker/ManifestDatePicker";
import styled from "@emotion/styled";

const Container = styled.div`
  width: 320px;
  border-radius: 4px;
  overflow: hidden;
`;

export const formatDate: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "numeric"
};

type CreateManifestDatePickerProps = {
  selectedDate: Date | undefined;
  onChangeDate: (date: Date | undefined, isUserChange: boolean) => void;
};

const CreateManifestDatePicker = ({selectedDate, onChangeDate}: CreateManifestDatePickerProps) => {
  const today = new Date();
  const tomorrow = add(today, {
    days: 1
  });

  return (
    <Container>
      <DatePickerHeader title={"Select Manifest(s) Date"}>
        <DatePickerToolbar>
          <DatePickerToolbarButton
            data-testid="today-button"
            isSelected={isSameDate(today, selectedDate)}
            onClick={() => onChangeDate(today, true)}
          >
            Today, {today.toLocaleString("US", formatDate)}
          </DatePickerToolbarButton>
          <DatePickerToolbarButton
            data-testid="tomorrow-button"
            isSelected={isSameDate(tomorrow, selectedDate)}
            onClick={() => onChangeDate(tomorrow, true)}
          >
            Tomorrow, {tomorrow.toLocaleString("US", formatDate)}
          </DatePickerToolbarButton>
        </DatePickerToolbar>
      </DatePickerHeader>
      <ManifestDatePicker
        dayPickerProps={{
          firstDayOfWeek: 1,
          weekdaysShort: ["S", "M", "T", "W", "T", "F", "S"]
        }}
        highlightCurrentDay={!isSameDate(today, selectedDate)}
        value={selectedDate}
        onChange={onChangeDate}
        minDate={add(today, {months: -1})}
        maxDate={add(today, {months: 3})}
      />
    </Container>
  );
};

export default CreateManifestDatePicker;
