import styled from "@emotion/styled";
import {differenceInMilliseconds, formatDistanceStrict} from "date-fns";
import {toDate} from "date-fns-tz";
import {GrayBeacon, GreenBeacon, RedBeacon, YellowBeacon} from "./DriverLocationIcons";
import {DriverCords} from "./DriverMarker";
import {ManifestDriver} from "../../../generated/graphql";
import {EmptyValueStrings} from "../../common/Constants";

const TooltipTick = styled.div`
  position: absolute;
  padding: 5px;
  z-index: 1002;
  left: 20px;
  top: -37px;

  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;

  background: #ffffff;
  border-radius: 0.4em;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 25%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #ffffff;
    border-bottom: 0;
    border-left: 0;
    margin-left: -18px;
    margin-bottom: -8px;
  }
`;

const DriverName = styled.div`
  margin-left: 8px;
  white-space: nowrap;
`;

const AgeString = styled.div`
  white-space: nowrap;
`;

type DriverTitleProps = {
  driver: ManifestDriver;
  cords: DriverCords;
  $hover?: boolean;
};

export const renderTime = (cords: DriverCords) => {
  let distance = "?";
  if (cords.receiveDateTime) {
    distance = formatDistanceStrict(toDate(cords.receiveDateTime), new Date());
  }
  return <AgeString>{distance} ago</AgeString>;
};

export const DriverTitle = ({driver, cords, $hover}: DriverTitleProps) => {
  const renderBeacon = () => {
    const diffInMinutes = differenceInMilliseconds(Date.now(), new Date(cords.receiveDateTime)) / (60 * 1000);
    if (diffInMinutes <= 5) {
      return <GreenBeacon />;
    } else if (diffInMinutes <= 15) {
      return <YellowBeacon />;
    } else if (diffInMinutes <= 60) {
      return <RedBeacon />;
    } else {
      return <GrayBeacon />;
    }
  };

  const renderName = (name: string) => {
    const firstAndLast = name.split(" ");
    if (firstAndLast.length === 2) {
      return `${firstAndLast[0]} ${firstAndLast[1].charAt(0)}.`;
    }
    return name;
  };

  return (
    <TooltipTick>
      {$hover ? renderTime(cords) : renderBeacon()}
      <DriverName>{renderName(driver.name!)}</DriverName>
    </TooltipTick>
  );
};

export const renderDriverName = (driver: ManifestDriver) => {
  if (driver.name) {
    const firstAndLast = driver.name.split(" ");
    if (firstAndLast.length >= 2) {
      return `${firstAndLast[0]} ${firstAndLast[1].charAt(0)}.`;
    }
    return driver.name;
  }
  if (driver.code) {
    return `Driver ${driver.code}`;
  }
  return EmptyValueStrings.notAvailable;
};

export const getDriverName = (driver: ManifestDriver) => {
  if (driver.name) {
    return driver.name;
  }
  return "";
};

export const getDriverCode = (driver: ManifestDriver) => {
  if (driver.code) {
    return driver.code;
  }
  return "";
};
