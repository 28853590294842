import {Button, Collapse, Icon, InputGroup, Label, Radio, RadioGroup} from "@blueprintjs/core";
import React, {useCallback, useState} from "react";
import styled from "@emotion/styled";
import {Tooltip2} from "@blueprintjs/popover2";
import {UserProfile} from "./UserProfile";
import {DISPATCH_ADMINS, DISPATCH_TENANT_ADMIN, User} from "./UserManagement";
import {FormMode} from "./InviteUserDialog";

interface UserFormProps {
  formMode: FormMode;
  formId: string;
  formData: UserProfile;
  onFormChange: (formId: string, fieldName: keyof UserProfile, value: any) => void;
  onRemoveForm?: (formId: string) => void;
}

export interface InviteUserDialogRef {
  onOpen: () => void;
  onClose: () => void;
  onOpenUpdate: (userToEdit: User) => void;
}

const isDispatchAdmin = (user: UserProfile) => {
  return user.groups.includes(DISPATCH_TENANT_ADMIN);
};

const isDispatchSuperAdmin = (user: UserProfile) => {
  return user.groups.includes(DISPATCH_ADMINS);
};

const RADIO_USER = "user";
const RADIO_ADMIN = "admin";

const UserForm = ({formId, formData, formMode, onFormChange, onRemoveForm}: UserFormProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [selectedUserType, setSelectedUserType] = useState<string>(() =>
    isDispatchSuperAdmin(formData) || isDispatchAdmin(formData) ? RADIO_ADMIN : RADIO_USER
  );

  const onRoleRadioChange = useCallback(
    (e: any) => {
      let updatedGroups = [...formData.groups];
      setSelectedUserType(e.target.value);
      if (e.target.value === RADIO_ADMIN) {
        updatedGroups.push(DISPATCH_TENANT_ADMIN);
      } else {
        const index = formData.groups.indexOf(DISPATCH_TENANT_ADMIN);
        if (index > -1) {
          updatedGroups = [...formData.groups.slice(0, index), ...formData.groups.slice(index + 1)];
        }
      }
      onFormChange(formId, e.target.name, updatedGroups);
    },
    [formData.groups, formId, onFormChange]
  );

  const onFieldChange = useCallback(
    (e) => {
      onFormChange(formId, e.target.name, e.target.value);
    },
    [formId, onFormChange]
  );

  const handleExpand = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <Container>
      <Header>
        <StyledButton minimal icon={isExpanded ? "chevron-up" : "chevron-down"} onClick={handleExpand}>
          {formData.email.trim() || "New User"}
        </StyledButton>
        {formMode === FormMode.new && onRemoveForm && (
          <Button
            data-testid="delete-user-form"
            className="delete-button"
            minimal
            icon={<Icon icon="trash" size={12} />}
            onClick={() => onRemoveForm(formId)}
          />
        )}
      </Header>
      <Collapse keepChildrenMounted isOpen={isExpanded}>
        <Form data-testid={"form-container"}>
          <Label inputMode={"text"}>
            <StyledLabel isRequired>First Name</StyledLabel>
            <InputGroup
              data-testid={"create-firstName"}
              name={"firstName"}
              placeholder={"First name"}
              fill={false}
              onChange={onFieldChange}
              value={formData.firstName}
            />
          </Label>
          <Label inputMode={"text"}>
            <StyledLabel isRequired>Last Name</StyledLabel>
            <InputGroup
              data-testid={"create-lastName"}
              name={"lastName"}
              placeholder={"Last name"}
              fill={false}
              onChange={onFieldChange}
              value={formData.lastName}
            />
          </Label>
          <Label inputMode={"email"}>
            <StyledLabel isRequired>Email Address</StyledLabel>
            <InputGroup
              data-testid={"create-email"}
              name={"email"}
              placeholder={"Email address"}
              fill={false}
              onChange={onFieldChange}
              value={formData.email}
              readOnly={formMode === FormMode.edit}
            />
          </Label>
          <Label inputMode={"tel"}>
            <StyledLabel>Phone</StyledLabel>
            <InputGroup
              data-testid={"create-phone"}
              name={"phone"}
              placeholder={"Phone"}
              fill={false}
              onChange={onFieldChange}
              value={formData.phone}
            />
          </Label>
          <Label>
            <StyledLabel isRequired>e-Courier Username</StyledLabel>
            <InputGroup
              data-testid={"create-legacyUserId"}
              name={"legacyUserId"}
              placeholder={"e-Courier Username"}
              fill={false}
              onChange={onFieldChange}
              value={formData.legacyUserId}
            />
          </Label>
          <Label>
            <Tooltip2 content={"Dispatch Admins can manage and invite other Dispatch Users"} hoverOpenDelay={1000}>
              <RadioGroup
                name={"groups"}
                disabled={isDispatchSuperAdmin(formData)}
                onChange={onRoleRadioChange}
                selectedValue={selectedUserType}
                inline
              >
                <Radio label="Dispatch User" value={RADIO_USER} data-testid={"is-user-radio-btn"} />
                <Radio label="Dispatch Admin" value={RADIO_ADMIN} data-testid={"is-admin-radio-btn"} />
              </RadioGroup>
            </Tooltip2>
          </Label>
          <InputGroup
            data-testid={"user-id"}
            name={"id"}
            placeholder={"Id"}
            fill={false}
            value={formData.id}
            hidden={true}
          />
        </Form>
      </Collapse>
    </Container>
  );
};

export default UserForm;

const Container = styled.div`
  border-radius: 8px;
  padding: 8px 0;

  .delete-button {
    display: none;
  }

  :hover {
    background-color: #dddddd53;

    .delete-button {
      display: flex;
    }
  }

  transition: background-color 0.2s ease-in-out;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
`;
const StyledLabel = styled.div<{isRequired?: boolean}>`
  font-size: 13px;
  :after {
    content: " *";
    color: #fa545e;
    display: ${(props) => (props.isRequired ? "inline" : "none")};
  }
`;

const Form = styled.form`
  padding: 0 30px 8px;

  .bp4-input {
    border-radius: 4px;
    box-shadow: none;
    border: 1px solid #797979;
  }

  .bp4-label {
    margin-top: 12px;
    margin-bottom: 0;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: left;
  width: 100%;
  font-weight: 500;
  background: none;

  :hover {
    background: none !important;
  }
`;
