export const NormalCardIcon = () => {
  return (
    <svg
      data-testid="normal-card-icon"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="16" height="7.5" rx="1" fill="#797979" />
      <rect x="1" y="9.5" width="16" height="7.5" rx="1" fill="#797979" />
    </svg>
  );
};
export const MinimumCardIcon = () => {
  return (
    <svg
      data-testid="minimum-card-icon"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="16" height="4.7" rx="1" fill="#797979" />
      <rect x="1" y="6.7002" width="16" height="4.7" rx="1" fill="#797979" />
      <rect x="1" y="12.3999" width="16" height="4.7" rx="1" fill="#797979" />
    </svg>
  );
};
export const CompactCardIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="7.5" height="4.66667" rx="1" fill="#797979" />
      <rect x="1" y="6.66699" width="7.5" height="4.66667" rx="1" fill="#797979" />
      <rect x="1" y="12.333" width="7.5" height="4.66667" rx="1" fill="#797979" />
      <rect x="9.5" y="1" width="7.5" height="4.66667" rx="1" fill="#797979" />
      <rect x="9.5" y="6.66699" width="7.5" height="4.66667" rx="1" fill="#797979" />
      <rect x="9.5" y="12.333" width="7.5" height="4.66667" rx="1" fill="#797979" />
    </svg>
  );
};
