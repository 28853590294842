export const AscendingIcon = () => {
  return (
    <svg
      data-testid="ascending-icon"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2455_5558)">
        <path
          d="M4.73764 15.8586C4.54701 16.0675 4.28139 16.1877 4.00014 16.1877C3.71889 16.1877 3.45326 16.0675 3.26264 15.8586L0.512637 12.8211C0.140762 12.4098 0.165762 11.7675 0.575137 11.391C0.984512 11.0145 1.61576 11.0398 1.98764 11.4543L3.00014 12.5712V3.0252C3.00014 2.46516 3.44701 2.0127 4.00014 2.0127C4.55326 2.0127 5.00014 2.46516 5.00014 3.0252V12.5712L6.01264 11.4511C6.38451 11.0398 7.01889 11.0113 7.42514 11.3878C7.83139 11.7643 7.85951 12.4066 7.48764 12.818L4.73764 15.8555V15.8586ZM10.0001 16.1877C9.44701 16.1877 9.00014 15.7352 9.00014 15.1752C9.00014 14.6152 9.44701 14.1627 10.0001 14.1627H11.0001C11.5533 14.1627 12.0001 14.6152 12.0001 15.1752C12.0001 15.7352 11.5533 16.1877 11.0001 16.1877H10.0001ZM10.0001 12.1377C9.44701 12.1377 9.00014 11.6852 9.00014 11.1252C9.00014 10.5652 9.44701 10.1127 10.0001 10.1127H13.0001C13.5533 10.1127 14.0001 10.5652 14.0001 11.1252C14.0001 11.6852 13.5533 12.1377 13.0001 12.1377H10.0001ZM10.0001 8.0877C9.44701 8.0877 9.00014 7.63523 9.00014 7.0752C9.00014 6.51516 9.44701 6.0627 10.0001 6.0627H15.0001C15.5533 6.0627 16.0001 6.51516 16.0001 7.0752C16.0001 7.63523 15.5533 8.0877 15.0001 8.0877H10.0001ZM10.0001 4.0377C9.44701 4.0377 9.00014 3.58523 9.00014 3.0252C9.00014 2.46516 9.44701 2.0127 10.0001 2.0127H17.0001C17.5533 2.0127 18.0001 2.46516 18.0001 3.0252C18.0001 3.58523 17.5533 4.0377 17.0001 4.0377H10.0001Z"
          fill="#797979"
        />
      </g>
      <defs>
        <clipPath id="clip0_2455_5558">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DescendingIcon = () => {
  return (
    <svg
      data-testid="descending-icon"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2455_5560)">
        <path
          d="M4.73764 2.325C4.54701 2.11875 4.28139 2 4.00014 2C3.71889 2 3.45326 2.11875 3.26264 2.325L0.512637 5.325C0.140762 5.73125 0.165762 6.36563 0.575137 6.7375C0.984512 7.10938 1.61576 7.08438 1.98764 6.675L3.00014 5.57188V15C3.00014 15.5531 3.44701 16 4.00014 16C4.55326 16 5.00014 15.5531 5.00014 15V5.57188L6.01264 6.67812C6.38451 7.08437 7.01889 7.1125 7.42514 6.74062C7.83139 6.36875 7.85951 5.73438 7.48764 5.32812L4.73764 2.32812V2.325ZM10.0001 2C9.44701 2 9.00014 2.44687 9.00014 3C9.00014 3.55313 9.44701 4 10.0001 4H11.0001C11.5533 4 12.0001 3.55313 12.0001 3C12.0001 2.44687 11.5533 2 11.0001 2H10.0001ZM10.0001 6C9.44701 6 9.00014 6.44688 9.00014 7C9.00014 7.55312 9.44701 8 10.0001 8H13.0001C13.5533 8 14.0001 7.55312 14.0001 7C14.0001 6.44688 13.5533 6 13.0001 6H10.0001ZM10.0001 10C9.44701 10 9.00014 10.4469 9.00014 11C9.00014 11.5531 9.44701 12 10.0001 12H15.0001C15.5533 12 16.0001 11.5531 16.0001 11C16.0001 10.4469 15.5533 10 15.0001 10H10.0001ZM10.0001 14C9.44701 14 9.00014 14.4469 9.00014 15C9.00014 15.5531 9.44701 16 10.0001 16H17.0001C17.5533 16 18.0001 15.5531 18.0001 15C18.0001 14.4469 17.5533 14 17.0001 14H10.0001Z"
          fill="#797979"
        />
      </g>
      <defs>
        <clipPath id="clip0_2455_5560">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ByDriverCodeIcon = () => {
  return (
    <svg
      data-testid="by-driver-code-icon"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2 5.52161V12.5651H1.8V5.52161H16.2ZM1.8 3.76074C0.807187 3.76074 0 4.55038 0 5.52161V12.5651C0 13.5363 0.807187 14.326 1.8 14.326H16.2C17.1928 14.326 18 13.5363 18 12.5651V5.52161C18 4.55038 17.1928 3.76074 16.2 3.76074H1.8ZM2.925 7.06237C2.925 7.4283 3.22594 7.7227 3.6 7.7227H3.825V10.364H3.375C3.00094 10.364 2.7 10.6584 2.7 11.0243C2.7 11.3903 3.00094 11.6847 3.375 11.6847H5.625C5.99906 11.6847 6.3 11.3903 6.3 11.0243C6.3 10.6584 5.99906 10.364 5.625 10.364H5.175V7.06237C5.175 6.69644 4.87406 6.40205 4.5 6.40205H3.6C3.22594 6.40205 2.925 6.69644 2.925 7.06237ZM8.28562 7.89879C8.44594 7.67868 8.77781 7.66217 8.96062 7.86577C9.10687 8.0281 9.10125 8.27022 8.95219 8.4298L6.92719 10.5759C6.74437 10.7685 6.69656 11.0491 6.80625 11.2885C6.91594 11.5278 7.15781 11.6847 7.425 11.6847H9.9C10.2741 11.6847 10.575 11.3903 10.575 11.0243C10.575 10.6584 10.2741 10.364 9.9 10.364H8.96625L9.945 9.32674C10.5638 8.67192 10.575 7.66767 9.97594 6.99634C9.21937 6.15167 7.85531 6.21495 7.18594 7.13116L6.87375 7.56037C6.65719 7.85752 6.7275 8.27022 7.03125 8.48207C7.335 8.69393 7.75688 8.62514 7.97344 8.328L8.28562 7.89879Z"
        fill="#797979"
      />
    </svg>
  );
};

export const ByDriverNameIcon = () => {
  return (
    <svg
      data-testid="by-driver-name-icon"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.8 5.5V12.5H16.2V5.5H1.8ZM0 5.5C0 4.53477 0.807187 3.75 1.8 3.75H16.2C17.1928 3.75 18 4.53477 18 5.5V12.5C18 13.4652 17.1928 14.25 16.2 14.25H1.8C0.807187 14.25 0 13.4652 0 12.5V5.5ZM5.175 6.375C5.44219 6.375 5.68406 6.52813 5.79094 6.76602L7.59094 10.7035C7.74281 11.0344 7.58813 11.4227 7.24781 11.5703C6.9075 11.718 6.50812 11.5676 6.35625 11.2367L6.23531 10.9715H4.11469L3.99375 11.2367C3.84187 11.5676 3.4425 11.718 3.10219 11.5703C2.76187 11.4227 2.60719 11.0344 2.75906 10.7035L4.55906 6.76602C4.66875 6.52813 4.90781 6.375 5.175 6.375ZM5.175 8.64727L4.71375 9.65625H5.63625L5.175 8.64727ZM8.55 7.03125C8.55 6.66758 8.85094 6.375 9.225 6.375H10.6875C11.6184 6.375 12.375 7.11055 12.375 8.01562C12.375 8.26719 12.3159 8.50508 12.2119 8.71836C12.5859 9.01914 12.825 9.47305 12.825 9.98438C12.825 10.8895 12.0684 11.625 11.1375 11.625H9.225C8.85094 11.625 8.55 11.3324 8.55 10.9688V10.75V9V7.25V7.03125ZM9.9 7.6875V8.34375H10.6875C10.8731 8.34375 11.025 8.19609 11.025 8.01562C11.025 7.83516 10.8731 7.6875 10.6875 7.6875H9.9ZM9.9 10.3125H11.1375C11.3231 10.3125 11.475 10.1648 11.475 9.98438C11.475 9.80391 11.3231 9.65625 11.1375 9.65625H10.6875H9.9V10.3125Z"
        fill="#797979"
      />
    </svg>
  );
};

export const ByColorIcon = () => {
  return (
    <svg
      data-testid="by-color-icon"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 3C1 1.89688 1.89688 1 3 1H12C13.1031 1 14 1.89688 14 3V5C14 6.10313 13.1031 7 12 7H3C1.89688 7 1 6.10313 1 5V3ZM6 12C6 11.4469 6.44688 11 7 11V10.5C7 9.11875 8.11875 8 9.5 8H14C14.5531 8 15 7.55312 15 7V6V3.17188C16.1656 3.58437 17 4.69375 17 6V7C17 8.65625 15.6562 10 14 10H9.5C9.225 10 9 10.225 9 10.5V11C9.55313 11 10 11.4469 10 12V16C10 16.5531 9.55313 17 9 17H7C6.44688 17 6 16.5531 6 16V12Z"
        fill="#797979"
      />
    </svg>
  );
};
