import {Tag, Text} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {CSSProperties, useContext, useMemo} from "react";
import {IndicatorColors} from "../settings/ColorizedIndicators/ColorizedIndicators";
import {PreferenceContext, extractSimplePref} from "../../providers/PreferenceProvider";
import {ServiceTypeColorType} from "../settings/ServiceTypeColor/ServiceTypeColor";
import {TenantPreferences} from "../common/Constants";

type ServiceBadgeProps = {
  service: string | undefined;
  ellipsize?: boolean;
};

type ServiceDisplay = {
  color: string | undefined;
  backgroundColor: string | undefined;
};

const serviceMap = new Map<string, ServiceDisplay>();

export const ServiceBadge = ({service, ellipsize}: ServiceBadgeProps) => {
  const {tenantPreferences} = useContext(PreferenceContext);

  const serviceTypeColorData: ServiceTypeColorType[] = useMemo(() => {
    return extractSimplePref(tenantPreferences, TenantPreferences.serviceTypeColor, []).value as ServiceTypeColorType[];
  }, [tenantPreferences]);

  if (!service) {
    return <span />;
  }

  const backGroundColor = serviceTypeColorData.find((sv) => sv.name === service)?.color;

  const resolvedService = serviceMap.get(service?.trim()?.toLowerCase());
  let style: CSSProperties = {
    color: backGroundColor ? "#FFFFFF" : "#828282",
    backgroundColor: backGroundColor ? IndicatorColors[backGroundColor].hex : "#E5E6E6",
    borderRadius: "4px",
    fontWeight: 500
  };
  if (resolvedService) {
    style = {...style, ...resolvedService};
  }
  return (
    <Tag minimal style={style}>
      <Text ellipsize={ellipsize} title={service}>
        {service}
      </Text>
    </Tag>
  );
};

export const NewServiceBadge = ({service}: ServiceBadgeProps) => {
  const {tenantPreferences} = useContext(PreferenceContext);

  const serviceTypeColorData: ServiceTypeColorType[] = useMemo(() => {
    return extractSimplePref(tenantPreferences, TenantPreferences.serviceTypeColor, []).value as ServiceTypeColorType[];
  }, [tenantPreferences]);

  if (!service) {
    return <span />;
  }

  const backGroundColor = serviceTypeColorData.find((sv) => sv.name === service)?.color;

  const style: CSSProperties = {
    color: backGroundColor ? "#FFFFFF" : "#797979",
    backgroundColor: backGroundColor ? IndicatorColors[backGroundColor].hex : "#E5E6E6"
  };

  return (
    <ServicesTag style={style} htmlTitle="">
      {service}
    </ServicesTag>
  );
};

const ServicesTag = styled(Tag)`
  box-sizing: border-box;
  min-height: 16px;
  width: fit-content;
  border-radius: 4px;
  padding: 0 3px;
  font-size: 11px;
  font-weight: 500;
  color: #797979;
  letter-spacing: -0.5px;
`;
