import {Elevation} from "@blueprintjs/core";
import {useEffect} from "react";
import {createCardHandlers} from "../ManifestCard";
import {CardContainerV3, ManifestCompactCard} from "../ManifestDriverCardStyles";
import {ManifestCardHeader, StopInfoItem} from "../ManifestCardCommonV3";

import {ManifestCardProps} from "../ManifestCardCommon";
import {CardGrid, ScrollableList, StopListContainer, getStopList, scrollToElement} from "../card/ManifestDriverCardV3";
import {Constants} from "../../common/Constants";

const ManifestDriverCompactCard = ({
  manifest,
  colors,
  isSelected,
  isHighlighted,
  disabled,
  configurationState,
  onSelectedManifest,
  onDrillDownManifest,
  onLocationMarkerClick,
  cardType
}: ManifestCardProps) => {
  const {handleOnSelected, handleDrillDownManifest} = createCardHandlers({
    onSelectedManifest,
    onDrillDownManifest
  });

  const stopList = getStopList(
    manifest,
    isSelected,
    configurationState.topStopInformation,
    Constants.NUMBER_OF_COMPACT_CARD_STOPS
  );

  useEffect(() => {
    if (isSelected) {
      scrollToElement(`manifest-compact-card-item-${manifest.manifestDriverId}`);
    }
  }, [isSelected, manifest.manifestDriverId]);

  return (
    <CardContainerV3
      id={`manifest-dnd-target-${manifest.manifestDriverId}`}
      data-manifestdriverid={manifest.manifestDriverId}
      data-cardtype={"card"}
    >
      <ManifestCompactCard
        id={`manifest-compact-card-item-${manifest.manifestDriverId}`}
        elevation={Elevation.ONE}
        isSelected={isSelected}
        isHighlighted={isHighlighted}
        interactive={!disabled}
        disabled={disabled}
        data-testid={`manifest-compact-card-item-${manifest.manifestDriverId}`}
        data-manifest={JSON.stringify(manifest)}
        onClick={(e: any) => {
          if (!disabled) {
            handleOnSelected(e, manifest);
          }
        }}
      >
        <CardGrid data-testid={"card-grid"} isSelected={isSelected} bodyHeight={81}>
          <ManifestCardHeader
            manifest={manifest}
            handleDrillDownManifest={handleDrillDownManifest}
            onLocationMarkerClick={onLocationMarkerClick}
            stopsColor={colors ?? []}
            isSelected={isSelected}
            disabled={disabled}
            configurationState={configurationState}
            cardType={cardType}
          />
          <StopListContainer>
            <ScrollableList
              id={`scroll-list-${manifest.manifestDriverId}`}
              data-testid="scroll-list"
              isSelected={isSelected}
              hasScroll={(stopList ?? []).length > Constants.NUMBER_OF_COMPACT_CARD_STOPS}
            >
              {stopList?.map((x, index) => {
                const color = (colors ?? []).find((y) => y.jobStopID === x.jobStopId)?.color ?? "";
                return (
                  <StopInfoItem
                    index={index}
                    key={`card-stop-info-${x.jobStopId}`}
                    stop={x}
                    color={color}
                    disabled={disabled ?? false}
                    configurationState={configurationState}
                    cardType={cardType}
                  />
                );
              })}
            </ScrollableList>
          </StopListContainer>
        </CardGrid>
      </ManifestCompactCard>
    </CardContainerV3>
  );
};

export default ManifestDriverCompactCard;
