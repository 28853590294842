import styled from "@emotion/styled";
import {Icon} from "@blueprintjs/core";
import {
  ClearIcon,
  CompletedStopIcon,
  DeliveryIcon,
  DriverIcon,
  PickUpIcon,
  RoadIcon,
  SatelliteIcon,
  StopDetailIcon,
  TrafficLightIcon,
  VehicleIcon
} from "./MapIcons";
import {Popover2} from "@blueprintjs/popover2";
import {MAP_LAYERS} from "../MapAnchor";
import {Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import ActionButton, {ContainerPosition} from "./ActionButton";
import {
  EntityVisibilityState,
  ManifestVisibilityState,
  MapVisibilityAction,
  MapVisibilityState,
  UnassignedJobsVisibilityTypes
} from "../MapVisibilityContext";
import {useSetUserPrefMutation} from "../../../generated/graphql";
import {createJsonPref, extractJsonPref, PreferenceContext} from "../../../providers/PreferenceProvider";
import {UserPreferences} from "../../common/Constants";
import {isEmpty, isEqual} from "lodash";

export enum MapActionBreakPoint {
  HIDETEXT = 600,
  VERTICAL_MENU = 410,
  HIDE_MENU = 110
}

export type MapActionBarProps = {
  apiLoaded: boolean;
  mapType: string;
  jobIds: number[];
  totalJobCount: number;
  visibleManifests: ManifestVisibilityState[];
  visibleJobs: EntityVisibilityState[];
  visibleDriverCount: number;
  totalDriverCount: number;
  isShowVehicles: boolean;
  mapResponsiveWidth: number;
  visibleStopDetails: boolean;
  onTrafficClicked: () => void;
  onChangeMapType: (type: string) => void;
  onStopDetailsClick: Dispatch<SetStateAction<boolean>>;
  mapVisibilityDispatch: Dispatch<MapVisibilityAction>;
  mapVisibilityState?: MapVisibilityState;
  onShowVehiclesToogle: (type?: boolean) => void;
  isActiveCompletedStops: boolean;
  onCompletedStopsClicked: () => void;
  isJobPanelLoaded?: boolean;
  newWindow?: Window;
  usePortal?: boolean;
};

type ActionsContainerProps = {isOpenMapTypeMenu?: boolean; switchToVertical?: boolean};

type MapTypeIcon = {
  title: string;
  mapType: MAP_LAYERS;
  icon: JSX.Element;
};

const MapTypeIcons: MapTypeIcon[] = [
  {title: "Roadmap", mapType: MAP_LAYERS.ROADMAP, icon: <RoadIcon />},
  {title: "Satellite", mapType: MAP_LAYERS.HYBRID, icon: <SatelliteIcon />}
];

const tooltipContent = {
  drivers: {
    active: "Click to hide drivers",
    inactive: "Click to show drivers"
  },
  vehicles: {
    active: "Click to hide vehicles and show simplified view",
    inactive: "Click to show vehicles and regular view"
  },
  unassignedPickups: {
    active: "Click to hide unassigned pickups",
    inactive: "Click to show unassigned pickups"
  },
  unassignedDeliveries: {
    active: "Click to hide unassigned deliveries",
    inactive: "Click to show unassigned deliveries"
  },
  stopDetails: {
    active: "Click to hide stop details",
    inactive: "Click to show stop details"
  },
  completedStops: {
    active: "Click to hide completed jobs",
    inactive: "Click to show completed jobs"
  },
  clearMap: {
    inactive: (
      <div>
        <div>Click to remove everything from the map</div>
        <div>
          <span style={{color: "#FA545E", fontWeight: 500}}>Attention:</span> this action is irreversible
        </div>
      </div>
    )
  },
  traffic: {
    active: "Click to hide traffic",
    inactive: "Click to show traffic"
  },
  mapType: {
    active: "Click to select a different map type"
  }
};

const defaultDataUserPref: DataUserPref = {
  showDrivers: true,
  showVehicles: true,
  showTraffic: false,
  modeMap: "roadmap",
  showUnassignedType: UnassignedJobsVisibilityTypes.NONE
};

interface DataUserPref {
  showDrivers: boolean;
  showVehicles: boolean;
  showTraffic: boolean;
  modeMap: string;
  showUnassignedType: UnassignedJobsVisibilityTypes;
}

const MapActionBar = ({
  apiLoaded,
  mapType,
  jobIds,
  totalJobCount,
  visibleDriverCount,
  totalDriverCount,
  isShowVehicles,
  mapResponsiveWidth,
  visibleManifests,
  visibleJobs,
  onChangeMapType,
  mapVisibilityDispatch,
  onTrafficClicked,
  mapVisibilityState,
  onShowVehiclesToogle,
  isActiveCompletedStops,
  onCompletedStopsClicked,
  visibleStopDetails,
  onStopDetailsClick,
  isJobPanelLoaded,
  newWindow,
  usePortal
}: MapActionBarProps) => {
  const [isOpenMapTypeMenu, setIsOpenMapTypeMenu] = useState(false);
  const [isActiveDrivers, setIsActiveDrivers] = useState(true);
  const [isActiveTrafic, setIsActiveTrafic] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [setUserPref] = useSetUserPrefMutation();
  const {userPreferences, userPrefsQueryRefetch} = useContext(PreferenceContext);
  const dataUserPref = useRef<DataUserPref>(defaultDataUserPref);

  const updateUserPref = useCallback(() => {
    const mapActionsBarState = extractJsonPref(userPreferences, UserPreferences.mapActionsBarButton)?.value;
    const newMapActionsBarState = {...dataUserPref.current};
    if (!isEqual(mapActionsBarState, newMapActionsBarState)) {
      setUserPref({
        variables: {
          name: UserPreferences.mapActionsBarButton,
          input: createJsonPref(newMapActionsBarState, true)
        }
      }).then(() => {
        userPrefsQueryRefetch?.();
      });
    }
  }, [setUserPref, userPreferences, userPrefsQueryRefetch]);

  const onDriversClick = () => {
    setIsActiveDrivers(!isActiveDrivers);
    dataUserPref.current.showDrivers = !isActiveDrivers;
    updateUserPref();
    mapVisibilityDispatch({type: "ToggleAllAvailableDrivers", isShowAllAvailableDrivers: !isActiveDrivers});
  };

  const toogleVehicle = () => {
    onShowVehiclesToogle();
    dataUserPref.current.showVehicles = !dataUserPref.current.showVehicles;
    updateUserPref();
  };

  const toggleUnassignedJobsVisibilityType = useCallback(
    (type: UnassignedJobsVisibilityTypes) => {
      if (mapVisibilityState?.unassignedJobVisibilityType === type) {
        mapVisibilityDispatch({
          type: "SetUnassignedJobsVisibilityType",
          visibilityType: UnassignedJobsVisibilityTypes.NONE
        });
        mapVisibilityDispatch({type: "SetAllUnassignedJobsVisibility", jobIds: []});
        dataUserPref.current.showUnassignedType = UnassignedJobsVisibilityTypes.NONE;
      } else {
        mapVisibilityDispatch({
          type: "SetUnassignedJobsVisibilityType",
          visibilityType: type
        });
        dataUserPref.current.showUnassignedType = type;
        if (mapVisibilityState?.unassignedJobVisibilityType === UnassignedJobsVisibilityTypes.NONE) {
          mapVisibilityDispatch({type: "SetAllUnassignedJobsVisibility", jobIds: jobIds});
        }
      }
      updateUserPref();
      mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
    },
    [jobIds, mapVisibilityDispatch, mapVisibilityState?.unassignedJobVisibilityType, updateUserPref]
  );

  const isUnassignedPickupsActive = useMemo(() => {
    return mapVisibilityState?.unassignedJobVisibilityType === UnassignedJobsVisibilityTypes.PICK_UP;
  }, [mapVisibilityState?.unassignedJobVisibilityType]);

  const isUnassignedDeliveriesActive = useMemo(() => {
    return mapVisibilityState?.unassignedJobVisibilityType === UnassignedJobsVisibilityTypes.DELIVERIES;
  }, [mapVisibilityState?.unassignedJobVisibilityType]);

  const numberActiveJobs = useMemo(() => {
    return Array.from(mapVisibilityState?.jobs.entries() ?? []).filter(([, value]) => value.visibilityLevel !== 0)
      .length;
  }, [mapVisibilityState?.jobs]);

  const stopDetailsClick = () => {
    onStopDetailsClick((prev) => !prev);
  };

  const onClearMapClick = () => {
    mapVisibilityDispatch({type: "ClearAllMapVisibility"});
    setIsActiveDrivers(false);
    dataUserPref.current = {
      ...defaultDataUserPref,
      showDrivers: false,
      showVehicles: false
    };
    updateUserPref();
  };

  const isShowClearMapButton = useMemo(() => {
    return (
      mapVisibilityState?.unassignedJobVisibilityType !== UnassignedJobsVisibilityTypes.NONE ||
      Boolean(mapVisibilityState?.isShowAllAvailableDrivers) ||
      visibleManifests.length > 0 ||
      visibleJobs.length > 0
    );
  }, [
    mapVisibilityState?.unassignedJobVisibilityType,
    mapVisibilityState?.isShowAllAvailableDrivers,
    visibleManifests.length,
    visibleJobs.length
  ]);

  const onTrafficClick = () => {
    onTrafficClicked();
    setIsActiveTrafic(!isActiveTrafic);
    dataUserPref.current.showTraffic = !isActiveTrafic;
    updateUserPref();
  };

  const onMapTypeClick = (mapType: string) => {
    onChangeMapType(mapType);
    setIsOpenMapTypeMenu(false);
    dataUserPref.current.modeMap = mapType;
    updateUserPref();
  };
  const renderMapTypeMenuItems = () => {
    return MapTypeIcons.filter((v) => v.mapType !== mapType).map((value) => {
      return (
        <ActionButton
          dataTestId={`map-type-item-${value.mapType}`}
          key={value.title}
          active={false}
          onClick={() => onMapTypeClick(value.mapType)}
          icon={value.icon}
          isHideText={isHideMenuText}
          text={value.title}
        />
      );
    });
  };

  const currentSelectedMapType = useMemo(() => {
    return MapTypeIcons.find((v) => v.mapType === mapType) as MapTypeIcon;
  }, [mapType]);

  const isHideMenuText = useMemo(() => {
    return mapResponsiveWidth < MapActionBreakPoint.HIDETEXT;
  }, [mapResponsiveWidth]);

  const switchToVerticalMenu = useMemo(() => {
    return mapResponsiveWidth < MapActionBreakPoint.VERTICAL_MENU;
  }, [mapResponsiveWidth]);

  const isHideMenu = useMemo(() => {
    return mapResponsiveWidth < MapActionBreakPoint.HIDE_MENU;
  }, [mapResponsiveWidth]);

  const replaceToolTipContent = useMemo(() => {
    return {
      drivers: {
        active: (
          <div>
            <TooltipTilte>Drivers</TooltipTilte>
            <TooltipSubTitle>
              {visibleDriverCount} of {totalDriverCount}
            </TooltipSubTitle>
          </div>
        ),
        inactive: "Drivers"
      },
      unassignedPickups: {
        active: (
          <div>
            <TooltipTilte>Unassigned Pickups</TooltipTilte>
            <TooltipSubTitle>
              {jobIds?.length} of {totalJobCount}
            </TooltipSubTitle>
          </div>
        ),

        inactive: "Unassigned Pickups"
      },
      unassignedDeliveries: {
        active: (
          <div>
            <TooltipTilte>Unassigned Deliveries</TooltipTilte>
            <TooltipSubTitle>
              {jobIds?.length} of {totalJobCount}
            </TooltipSubTitle>
          </div>
        ),

        inactive: "Unassigned Deliveries"
      }
    };
  }, [jobIds?.length, totalDriverCount, totalJobCount, visibleDriverCount]);

  useEffect(() => {
    const mapActionsBarState = extractJsonPref(
      userPreferences,
      UserPreferences.mapActionsBarButton,
      defaultDataUserPref
    ).value;
    const hasMapActionsBarState = !isEmpty(mapActionsBarState);
    if (hasMapActionsBarState && initialRender && isJobPanelLoaded && apiLoaded) {
      if (
        [UnassignedJobsVisibilityTypes.PICK_UP, UnassignedJobsVisibilityTypes.DELIVERIES].includes(
          mapActionsBarState.showUnassignedType
        )
      ) {
        mapVisibilityDispatch({type: "SetAllUnassignedJobsVisibility", jobIds: jobIds});
        mapVisibilityDispatch({
          type: "SetUnassignedJobsVisibilityType",
          visibilityType: mapActionsBarState.showUnassignedType
        });
        mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
      }

      setIsActiveDrivers(mapActionsBarState.showDrivers);

      if (mapVisibilityState?.isShowAllAvailableDrivers !== mapActionsBarState.showDrivers) {
        mapVisibilityDispatch({
          type: "ToggleAllAvailableDrivers",
          isShowAllAvailableDrivers: mapActionsBarState.showDrivers
        });
      }

      onShowVehiclesToogle(!!mapActionsBarState.showVehicles);

      if (mapActionsBarState.showTraffic && !isActiveTrafic) {
        onTrafficClicked();
        setIsActiveTrafic(true);
      }
      if (mapActionsBarState.modeMap) {
        onChangeMapType(mapActionsBarState.modeMap);
        setIsOpenMapTypeMenu(false);
      }
      dataUserPref.current = {...mapActionsBarState};
      setInitialRender(false);
    }
  }, [
    apiLoaded,
    initialRender,
    isActiveTrafic,
    isJobPanelLoaded,
    jobIds,
    mapVisibilityDispatch,
    mapVisibilityState?.isShowAllAvailableDrivers,
    onChangeMapType,
    onShowVehiclesToogle,
    onTrafficClicked,
    userPreferences
  ]);

  return (
    <Container data-testid="map-actions-bar">
      <ActionsContainer switchToVertical={switchToVerticalMenu}>
        <ActionButton
          newWindow={newWindow}
          usePortal={usePortal}
          dataTestId="map-action-drivers"
          active={isActiveDrivers}
          onClick={onDriversClick}
          icon={<DriverIcon />}
          tooltipContent={isHideMenuText ? replaceToolTipContent.drivers : tooltipContent.drivers}
          isHideText={isHideMenuText}
          text={isActiveDrivers ? `${visibleDriverCount} of ${totalDriverCount}` : "Drivers"}
          containerPosition={ContainerPosition.LEFT}
          switchToVerticalMenu={switchToVerticalMenu}
        />
        {isActiveDrivers && (
          <ActionButton
            newWindow={newWindow}
            usePortal={usePortal}
            dataTestId="map-action-vehicles"
            active={isShowVehicles}
            onClick={toogleVehicle}
            icon={<VehicleIcon />}
            tooltipContent={tooltipContent.vehicles}
            isHideText={isHideMenuText}
            text="Vehicles"
            containerPosition={ContainerPosition.LEFT}
            switchToVerticalMenu={switchToVerticalMenu}
          />
        )}
        {isJobPanelLoaded && (
          <>
            <ActionButton
              newWindow={newWindow}
              usePortal={usePortal}
              dataTestId="map-action-unassigned-pickups"
              active={isUnassignedPickupsActive}
              onClick={() => toggleUnassignedJobsVisibilityType(UnassignedJobsVisibilityTypes.PICK_UP)}
              icon={<PickUpIcon isActive={isUnassignedPickupsActive} />}
              tooltipContent={
                isHideMenuText ? replaceToolTipContent.unassignedPickups : tooltipContent.unassignedPickups
              }
              isHideText={isHideMenuText}
              text={isUnassignedPickupsActive ? `${numberActiveJobs} of ${totalJobCount}` : "Unassigned Pickups"}
              containerPosition={ContainerPosition.LEFT}
              switchToVerticalMenu={switchToVerticalMenu}
            />
            <ActionButton
              newWindow={newWindow}
              usePortal={usePortal}
              dataTestId="map-action-unassigned-deliveries"
              active={isUnassignedDeliveriesActive}
              onClick={() => toggleUnassignedJobsVisibilityType(UnassignedJobsVisibilityTypes.DELIVERIES)}
              icon={<DeliveryIcon isActive={isUnassignedDeliveriesActive} />}
              tooltipContent={
                isHideMenuText ? replaceToolTipContent.unassignedDeliveries : tooltipContent.unassignedDeliveries
              }
              isHideText={isHideMenuText}
              text={isUnassignedDeliveriesActive ? `${numberActiveJobs} of ${totalJobCount}` : "Unassigned Deliveries"}
              containerPosition={ContainerPosition.LEFT}
              switchToVerticalMenu={switchToVerticalMenu}
            />
          </>
        )}
        {visibleManifests.length > 0 && (
          <>
            <ActionButton
              newWindow={newWindow}
              usePortal={usePortal}
              dataTestId="map-action-stopdetails"
              active={visibleStopDetails}
              onClick={stopDetailsClick}
              icon={<StopDetailIcon />}
              tooltipContent={tooltipContent.stopDetails}
              isHideText={isHideMenuText}
              text="Stop Details"
              containerPosition={ContainerPosition.LEFT}
              switchToVerticalMenu={switchToVerticalMenu}
            />

            <ActionButton
              newWindow={newWindow}
              usePortal={usePortal}
              dataTestId="map-action-completedstops"
              active={isActiveCompletedStops}
              onClick={onCompletedStopsClicked}
              icon={<CompletedStopIcon />}
              tooltipContent={tooltipContent.completedStops}
              isHideText={isHideMenuText}
              text="Compl Stops"
              containerPosition={ContainerPosition.LEFT}
              switchToVerticalMenu={switchToVerticalMenu}
            />
          </>
        )}
        {isShowClearMapButton && (
          <ActionButton
            newWindow={newWindow}
            usePortal={usePortal}
            dataTestId="map-action-clear"
            onClick={onClearMapClick}
            icon={<ClearIcon />}
            tooltipContent={tooltipContent.clearMap}
            isHideText={isHideMenuText}
            text={<span style={{color: "#FA545E"}}>Clear Map</span>}
            containerPosition={ContainerPosition.LEFT}
            switchToVerticalMenu={switchToVerticalMenu}
          />
        )}
      </ActionsContainer>
      <ActionsContainer isOpenMapTypeMenu={isOpenMapTypeMenu} switchToVertical={switchToVerticalMenu}>
        <ActionButton
          newWindow={newWindow}
          usePortal={usePortal}
          dataTestId="map-action-traffic"
          active={isActiveTrafic}
          onClick={onTrafficClick}
          icon={<TrafficLightIcon />}
          tooltipContent={tooltipContent.traffic}
          isHideText={isHideMenuText}
          text="Traffic"
          containerPosition={ContainerPosition.RIGHT}
          switchToVerticalMenu={switchToVerticalMenu}
        />
        <Popover2
          portalContainer={newWindow?.document.body}
          usePortal={usePortal}
          content={<>{renderMapTypeMenuItems()}</>}
          position="bottom"
          minimal={true}
          matchTargetWidth={true}
          popoverClassName="map-action-bar-popover"
          isOpen={isOpenMapTypeMenu}
          disabled={isHideMenu}
        >
          <ActionButton
            newWindow={newWindow}
            usePortal={usePortal}
            dataTestId="map-action-maptype"
            onClick={() => setIsOpenMapTypeMenu(!isOpenMapTypeMenu)}
            active={true}
            icon={currentSelectedMapType?.icon}
            tooltipContent={tooltipContent.mapType}
            isHideText={isHideMenuText}
            rigthIcon={
              <Icon data-testid={"maptype-icon"} icon={`${isOpenMapTypeMenu ? "chevron-up" : "chevron-down"}`} />
            }
            text={
              <>
                {currentSelectedMapType?.title} &nbsp;&nbsp;
                <Icon data-testid={"maptype-icon"} icon={`${isOpenMapTypeMenu ? "chevron-up" : "chevron-down"}`} />
              </>
            }
            containerPosition={ContainerPosition.RIGHT}
            switchToVerticalMenu={switchToVerticalMenu}
          />
        </Popover2>
      </ActionsContainer>
    </Container>
  );
};

export default MapActionBar;

const Container = styled.div`
  font-family: "Roboto", sans-serif !important;
  z-index: 1000;
  inset: 12px 12px auto 12px;
  position: absolute;
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;

const ActionsContainer = styled.div<ActionsContainerProps>`
  box-shadow: 0px 2px 2px 0px #00000040;
  border-radius: ${(props) => (props.isOpenMapTypeMenu ? "10px 10px 0 10px" : "10px")};
  overflow: hidden;
  display: grid;
  grid-auto-flow: ${(props) => (props.switchToVertical ? "row" : "column")};
  grid-auto-columns: minmax(0, 1fr);
  background-color: none;
  height: ${(props) => (props.switchToVertical ? "fit-content" : "100%")};
  & .bp4-popover2-target {
    height: ${(props) => (props.switchToVertical ? "fit-content" : "100%")};
    width: 100%;
  }

  & > .bp4-popover2-target:not(:last-child) {
    & > .bp4-button {
      border-right: 2px solid #0e0e0e14 !important;
    }

    & > .bp4-button.bp4-active {
      border-right: 2px solid #35578a !important;
    }
  }
`;

const TooltipTilte = styled.p`
  padding: 0;
  margin: 0;
`;

const TooltipSubTitle = styled.p`
  padding: 0;
  margin: 0;
  color: #797979;
`;
