import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import styled from "@emotion/styled";
import {Tooltip2} from "@blueprintjs/popover2";
import {Intent, Position} from "@blueprintjs/core";
import {Fragment, useEffect, useState} from "react";
import React from "react";

const WarningContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const WarningIcon = styled(FontAwesomeIcon)`
  padding-right: 5px;
`;

type MapIconTooltipProps = {
  text?: string;
  isOpen?: boolean;
  children: JSX.Element[] | JSX.Element;
};

const MapIconTooltip = ({text, isOpen, children}: MapIconTooltipProps) => {
  const [warningDisplayed, setWarningDisplayed] = useState<boolean>(false);

  useEffect(() => {
    setWarningDisplayed(true);
    setTimeout(() => {
      setWarningDisplayed(false);
    }, 3000);
  }, [isOpen]);

  if (isOpen) {
    return (
      <Tooltip2
        intent={Intent.NONE}
        popoverClassName="error-popover"
        position={Position.BOTTOM_RIGHT}
        isOpen={warningDisplayed}
        content={
          <WarningContainer>
            <WarningIcon size={"lg"} icon={solid("triangle-exclamation")} />
            <div>{text}</div>
          </WarningContainer>
        }
      >
        <React.Fragment>{children}</React.Fragment>
      </Tooltip2>
    );
  } else {
    return <Fragment>{children}</Fragment>;
  }
};

export default MapIconTooltip;
