import {JobTypeSelectorValues} from "./jobTypeSelectorValues";
import {JobAttributes} from "./jobAttributes";
import {TriggerSelectorValues} from "./triggerSelectorValues";
import {JsonRule} from "./jsonRule";
import {manifestAttributes} from "./manifestAttributes";
import {OptionType} from "../../../common/types/optionType";

export enum ComparisonTypes {
  ScheduledTime = "SCHEDULEDTIME",
  LateTime = "LATETIME",
  FirstStop = "FIRSTSTOP",
  LastStop = "LASTSTOP",
  Continuous = "CONTINUOUS"
}

export enum UserColor {
  RED = "red",
  ORANGE = "orange",
  YELLOW = "yellow",
  OLIVE = "olive",
  GREEN = "green",
  TURQUOISE = "turquoise",
  BLUE = "blue",
  PURPLE = "purple",
  MAGENTA = "magenta",
  BROWN = "brown",
  GREY = "grey",
  BLACK = "black"
}

export interface IndicatorLogicType {
  type?: JobTypeSelectorValues;
  color?: UserColor;
  jobAttributes: JobAttributes;
  orderPriority: OptionType[];
  manifestAttributes?: manifestAttributes;
  comparison?: ComparisonTypes;
  trigger?: TriggerSelectorValues;
  key: string;
  rank: number;
  /**
   * This is the set of rules for json-rules-engine.
   * This does not represent an executable rule,
   * it only has the configuration pieces that are set from the UI
   */
  rule: JsonRule;
}
