import React, {useMemo} from "react";
import {PresentUponHoverBase, PresentUponHoverBlockCommon} from "./PresentUponHoverBlockCommon";
import styled from "@emotion/styled";
import hoverDriverSvg from "./card-config-svgs/hover-driver-svg.svg";
import hoverStopsSvg from "./card-config-svgs/hover-stops-svg.svg";
import {getMaxHeightOfOptionOrSelection, renderManifestOption} from "./ManifestConfigService";
import {HoverOnDriversValues, HoverOnStopsValues} from "./ManifestConfigurationTypes";
import {Controller, useFormContext} from "react-hook-form";
import {IConfigurationFormValues} from "./ManifestCardConfiguration";

export const hoverOnDriverValues = Object.values(HoverOnDriversValues);
export const hoverOnStopsValues = Object.values(HoverOnStopsValues);
const defaultHeightOfSearchContainer = 48;
const defaultHeightOfFooter = 36;
const maxHeightOfDropdownDriver = 400;
const maxHeightOfDropdownStops = 322;
const aboveAndBelowPaddingSelectionBox = 6;

const PresentUponHoverForm = () => {
  const {control} = useFormContext<IConfigurationFormValues>();
  const hoverOnDriverProps: PresentUponHoverBase = useMemo(() => {
    return {
      dataTestId: "hover-on-driver-block",
      mainImg: hoverDriverSvg,
      mainTitle: "1. Hover on Driver Information",
      subTitle: "Select up to six data points from the drop-down",
      reArrangeTitle: "Rearrange the order in which you want the information presented by dragging the handle",
      totalOptions: renderManifestOption(hoverOnDriverValues),
      defaultOptions: renderManifestOption(hoverOnDriverValues).slice(0, 5),
      exceedMaxSelection: 6,
      maxHeightOfOptionOrSelection: getMaxHeightOfOptionOrSelection(
        maxHeightOfDropdownDriver,
        defaultHeightOfSearchContainer,
        defaultHeightOfFooter,
        aboveAndBelowPaddingSelectionBox
      )
    };
  }, []);
  const hoverOnStopProps: PresentUponHoverBase = useMemo(() => {
    return {
      dataTestId: "hover-on-stop-block",
      mainImg: hoverStopsSvg,
      mainTitle: "2. Hover on Stop Information",
      subTitle: "Select up to ten data points from the drop-down",
      reArrangeTitle: "Rearrange the order in which you want the information presented by dragging the handle",
      totalOptions: renderManifestOption(hoverOnStopsValues),
      defaultOptions: renderManifestOption(hoverOnStopsValues).slice(0, 5),
      exceedMaxSelection: 10,
      maxHeightOfOptionOrSelection: getMaxHeightOfOptionOrSelection(
        maxHeightOfDropdownStops,
        defaultHeightOfSearchContainer,
        defaultHeightOfFooter,
        aboveAndBelowPaddingSelectionBox
      )
    };
  }, []);
  return (
    <HoverFormContainer>
      <Controller
        control={control}
        name={"hoverDriverConfig"}
        render={({field: {onChange, value}}) => (
          <PresentUponHoverBlockCommon {...hoverOnDriverProps} onFieldChange={onChange} fieldValue={value} />
        )}
      />
      <Controller
        control={control}
        name={"hoverStopsConfig"}
        render={({field: {onChange, value}}) => (
          <PresentUponHoverBlockCommon {...hoverOnStopProps} onFieldChange={onChange} fieldValue={value} />
        )}
      />
    </HoverFormContainer>
  );
};

export default PresentUponHoverForm;

const HoverFormContainer = styled.div`
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 50px;
`;
