import {ManifestFilterType} from "../ManifestFilterState";
import {ManifestAttributeFilter} from "../../../generated/graphql";
import {format} from "date-fns";
import {AbstractManifestViewFilter} from "./AbstractManifestViewFilter";
import {DateFormats} from "../../common/Constants";
import {DateRange} from "@blueprintjs/datetime";

export class ManifestDateRangeFilter extends AbstractManifestViewFilter<DateRange> {
  constructor() {
    super(ManifestFilterType.DATE_RANGE);
  }
  toFilter(): ManifestAttributeFilter | undefined {
    if (this.value.size > 0) {
      const filterItems: ManifestAttributeFilter[] = Array.from(this.value).map((value) => {
        let [start, end] = value;
        if (start === null) {
          start = new Date(0);
        }
        if (end === null) {
          end = new Date(9999, 12, 31);
        }
        const dateStringStart = format(start, DateFormats.localDate);
        const dateStringEnd = format(end, DateFormats.localDate);
        return {
          manifestDate: {
            range: [dateStringStart, dateStringEnd]
          }
        } as ManifestAttributeFilter;
      });
      return this.handleMultiValuedFilter(filterItems);
    }
    return undefined;
  }
}
