import React from "react";
import styled from "@emotion/styled";
import ActionButton from "../action/ActionButton";
import {CompletedStopIcon} from "../action/MapIcons";

export interface ManifestDetailActionBarProps {
  onToggleActiveCompletedStops: () => void;
  isActiveCompletedStops: boolean;
  isOptimized: boolean;
}

const tooltipContent = {
  completedStops: {
    active: "Click to hide completed stops",
    inactive: "Click to show completed stops"
  }
};

const ActionBarContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
  border-radius: 10px;
  overflow: hidden;
`;

export const ManifestDetailActionBar: React.FC<ManifestDetailActionBarProps> = ({
  isActiveCompletedStops,
  onToggleActiveCompletedStops,
  isOptimized
}) => {
  return isOptimized ? null : (
    <ActionBarContainer>
      <ActionButton
        dataTestId="manifest-map-action-completedstops"
        active={isActiveCompletedStops}
        onClick={onToggleActiveCompletedStops}
        icon={<CompletedStopIcon />}
        tooltipContent={tooltipContent.completedStops}
        text="Compl Stops"
      />
    </ActionBarContainer>
  );
};
