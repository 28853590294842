import {Button, ButtonGroup} from "@blueprintjs/core";
import {Job, Manifest} from "../../generated/graphql";
import styled from "@emotion/styled";
import {getArrayFromField} from "../../utils/General";
import {Tooltip2} from "@blueprintjs/popover2";

export type AssignmentBarV2Props = {
  className?: string;
  selectedJobs: Job[];
  selectedManifest: Manifest | undefined;
  isEnableQualified: boolean;
  assignText: string;
  onQualify(): void;
  onAssign(): void;
  onClearSelection(): void;
};

const AssignmentBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 5px;
  height: 50px;
  background: #fcfcfc;
  border-radius: 12px;

  filter: drop-shadow(0px 10px 1px rgba(0, 0, 0, 0.02)) drop-shadow(0px 7px 15px rgba(0, 0, 0, 0.4));
`;

const ClearSelectionBtn = styled(Button)`
  &.bp4-button:not([class*="bp4-intent-"]) {
    width: 190px;
    height: 40px;
    border-radius: 8px 0px 0px 8px;
    font-size: 16px;
    background: linear-gradient(180deg, #fcfcfc 0%, #f3f3f3 100%);
    color: #fa545e;
    box-shadow: 0px 1px 2px 0px #00000040;
    z-index: 1;
    opacity: 0.85;

    :hover {
      opacity: 1;
    }
  }
`;

const QualDriversBtn = styled(Button)`
  &.bp4-button:not([class*="bp4-intent-"]) {
    width: 190px;
    height: 40px;
    border-radius: 0px 8px 8px 0px;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    gap: 12px;
    color: #161616 !important;
    background: linear-gradient(180deg, #fcfcfc 0%, #f3f3f3 100%) !important;
    box-shadow: 0px 1px 2px 0px #00000040 !important;
    z-index: 1;
    opacity: 0.9;

    :hover {
      opacity: 1;
    }

    &.bp4-active {
      color: #ffffff !important;
      background: linear-gradient(180deg, #8da4be 0%, #7c8ea2 100%) !important;

      path {
        fill: #ffffff;
      }
    }
  }
`;

const AssignBtn = styled(Button)`
  &.bp4-button:not([class*="bp4-intent-"]) {
    width: 122px;
    height: 40px;
    border-radius: 0px 8px 8px 0px;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    color: #ffffff;
    box-shadow: 0px 1px 2px 0px #00000040 !important;
    background: linear-gradient(180deg, #214a89 0%, #14305a 100%);
    z-index: 1;
    opacity: 0.9;

    :hover {
      opacity: 1;
    }
  }

  &.bp4-button[disabled] {
    color: rgba(119, 119, 119, 0.3);
    background: #ededed;
  }
`;

const InforIcon = ({color = "#797979", size = 16}: {color?: string; size?: number}) => {
  return (
    <svg
      style={{cursor: "pointer"}}
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM7.59375 11.8125H8.4375V9.5625H7.59375C7.12617 9.5625 6.75 9.18633 6.75 8.71875C6.75 8.25117 7.12617 7.875 7.59375 7.875H9.28125C9.74883 7.875 10.125 8.25117 10.125 8.71875V11.8125H10.4062C10.8738 11.8125 11.25 12.1887 11.25 12.6562C11.25 13.1238 10.8738 13.5 10.4062 13.5H7.59375C7.12617 13.5 6.75 13.1238 6.75 12.6562C6.75 12.1887 7.12617 11.8125 7.59375 11.8125ZM9 4.5C9.29837 4.5 9.58452 4.6193 9.7955 4.8295C10.0065 5.04048 10.125 5.32663 10.125 5.625C10.125 5.92337 10.0065 6.20952 9.7955 6.4205C9.58452 6.63147 9.29837 6.75 9 6.75C8.70163 6.75 8.41548 6.63147 8.2045 6.4205C7.99353 6.20952 7.875 5.92337 7.875 5.625C7.875 5.32663 7.99353 5.04048 8.2045 4.8295C8.41548 4.61853 8.70163 4.5 9 4.5Z"
        fill={color}
      />
    </svg>
  );
};

const AssignmentBarV2 = ({
  className,
  selectedJobs,
  selectedManifest,
  isEnableQualified,
  assignText,
  onAssign,
  onClearSelection,
  onQualify
}: AssignmentBarV2Props) => {
  const isHaveRequirement = selectedJobs.some((job) => getArrayFromField(job.order?.requirements).length > 0);

  return (
    <AssignmentBarContainer data-testid={"assignment-bar"} className={className}>
      <ButtonGroup>
        <ClearSelectionBtn
          data-testid={"clear-selection-btn"}
          text={`Clear Selection${selectedJobs.length > 0 ? " (" + selectedJobs.length + ")" : ""}`}
          onClick={onClearSelection}
        />
        {isHaveRequirement && (
          <QualDriversBtn
            data-testid={"qual-drivers-btn"}
            text={"Qualified Drivers"}
            active={isEnableQualified}
            onClick={onQualify}
            rightIcon={
              <div>
                <Tooltip2
                  position="bottom"
                  content="The recommended driver will have the necessary capabilities and will be outlined in blue."
                  popoverClassName="driver-qualified-popover"
                >
                  <InforIcon />
                </Tooltip2>
              </div>
            }
          />
        )}
        <AssignBtn
          data-testid={"assign-btn"}
          text={assignText}
          onClick={onAssign}
          disabled={selectedManifest === undefined}
        />
      </ButtonGroup>
    </AssignmentBarContainer>
  );
};

export default AssignmentBarV2;
export {InforIcon};
