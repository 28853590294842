import {Menu, MenuDivider, MenuItem} from "@blueprintjs/core";
import {Dispatch, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {SearchableSortDirection, useSetUserPrefMutation} from "../../generated/graphql";
import {JobViewActions, JobViewActionTypes, ManifestSortOptions} from "../../views/JobAssignmentViewReducer";
import SortMenuItem, {FaIcon} from "../common/SortMenuItem";
import {createJsonPref, PreferenceContext} from "../../providers/PreferenceProvider";
import {UserPreferences} from "../common/Constants";
import {useMapVisibilityContext} from "../map/MapVisibilityContext";
import styled from "@emotion/styled";
import {AscendingIcon, ByColorIcon, ByDriverCodeIcon, ByDriverNameIcon, DescendingIcon} from "./ManifestSortMenuIcons";
import {FeatureFlagContext} from "../../providers/FeatureFlagProvider";

const menuItems = [
  {
    key: "driver.code.keyword",
    text: "By Driver Code",
    icon: <ByDriverCodeIcon />
  },
  {
    key: "driver.name.keyword",
    text: "By Driver Name",
    icon: <ByDriverNameIcon />
  }
];

type ManifestSortMenuProps = {
  dispatch: Dispatch<JobViewActions>;
  manifestSort: ManifestSortOptions;
  sortByColor: {
    enable: boolean;
    direction: SearchableSortDirection;
  };
};

const ManifestSortMenu = ({dispatch, manifestSort, sortByColor}: ManifestSortMenuProps) => {
  const [setUserPref] = useSetUserPrefMutation();
  const {userPrefsQueryRefetch} = useContext(PreferenceContext);
  const {dispatch: mapVisibilityDispatch} = useMapVisibilityContext();
  const [selectProperty, setSelectProperty] = useState<boolean>(false);
  const {colorSort: colorSortEnable} = useContext(FeatureFlagContext);

  const onMenuPropertyClicked = (property: string) => {
    if (colorSortEnable && sortByColor.enable) {
      const turnOffColorSortPayload = {
        enable: false,
        direction: sortByColor.direction
      };
      dispatch({
        type: JobViewActionTypes.SET_SORT_BY_COLOR,
        payload: turnOffColorSortPayload
      });
      setUserPref({
        variables: {
          name: UserPreferences.manifestCardSortByColor,
          input: createJsonPref(turnOffColorSortPayload, true)
        }
      });
      const updatedSort = {
        property: property,
        sortDirection: sortByColor.direction
      };
      setUserPref({
        variables: {
          name: UserPreferences.manifestSortingHeader,
          input: createJsonPref(updatedSort, true)
        }
      }).then(() => {
        userPrefsQueryRefetch?.();
      });
      dispatch({
        type: JobViewActionTypes.SET_MANIFEST_SORT,
        payload: updatedSort
      });
    } else {
      const updatedSort = {
        property: property,
        sortDirection: manifestSort.sortDirection
      };
      setUserPref({
        variables: {
          name: UserPreferences.manifestSortingHeader,
          input: createJsonPref(updatedSort, true)
        }
      }).then(() => {
        userPrefsQueryRefetch?.();
      });
      dispatch({
        type: JobViewActionTypes.SET_MANIFEST_SORT,
        payload: updatedSort
      });
    }
    mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
  };

  const onMenuOrderClicked = (sort: SearchableSortDirection) => {
    if (colorSortEnable && sortByColor.enable) {
      const updatedSortColor = {
        enable: true,
        direction: sort
      };
      dispatch({
        type: JobViewActionTypes.SET_SORT_BY_COLOR,
        payload: updatedSortColor
      });
      setUserPref({
        variables: {
          name: UserPreferences.manifestCardSortByColor,
          input: createJsonPref(updatedSortColor, true)
        }
      }).then(() => {
        userPrefsQueryRefetch?.();
      });
    } else {
      const updatedSort = {
        property: manifestSort.property,
        sortDirection: sort
      };
      setUserPref({
        variables: {
          name: UserPreferences.manifestSortingHeader,
          input: createJsonPref(updatedSort, true)
        }
      }).then(() => {
        userPrefsQueryRefetch?.();
      });
      dispatch({
        type: JobViewActionTypes.SET_MANIFEST_SORT,
        payload: updatedSort
      });
      mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
    }
  };
  const onSortColorClick = useCallback(() => {
    if (sortByColor.enable) {
      return;
    }
    mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
    const updatedSort = {
      enable: !sortByColor.enable,
      direction: manifestSort.sortDirection
    };
    dispatch({
      type: JobViewActionTypes.SET_SORT_BY_COLOR,
      payload: updatedSort
    });
    setUserPref({
      variables: {
        name: UserPreferences.manifestCardSortByColor,
        input: createJsonPref(updatedSort, true)
      }
    }).then(() => {
      userPrefsQueryRefetch?.();
    });
  }, [
    sortByColor.enable,
    mapVisibilityDispatch,
    manifestSort.sortDirection,
    dispatch,
    setUserPref,
    userPrefsQueryRefetch
  ]);
  const renderAscOrderSort = useMemo(() => {
    if (sortByColor.enable && colorSortEnable) {
      return sortByColor.direction === SearchableSortDirection.Asc;
    } else {
      return manifestSort.sortDirection === SearchableSortDirection.Asc;
    }
  }, [colorSortEnable, manifestSort.sortDirection, sortByColor.direction, sortByColor.enable]);
  const renderDescOrderSort = useMemo(() => {
    if (sortByColor.enable && colorSortEnable) {
      return sortByColor.direction === SearchableSortDirection.Desc;
    } else {
      return manifestSort.sortDirection === SearchableSortDirection.Desc;
    }
  }, [colorSortEnable, manifestSort.sortDirection, sortByColor.direction, sortByColor.enable]);
  useEffect(() => {
    if (colorSortEnable) {
      if (sortByColor.enable) {
        setSelectProperty(false);
      } else {
        setSelectProperty(true);
      }
    }
  }, [colorSortEnable, sortByColor]);
  return colorSortEnable ? (
    <StyledManifestSortMenu>
      <MenuDivider title={<StyledManifestSortTitle>SORTING OPTIONS</StyledManifestSortTitle>} />
      <MenuItem
        icon={<AscendingIcon />}
        data-testid="manifest-sort-ascending-button"
        text="Ascending"
        onClick={() => onMenuOrderClicked(SearchableSortDirection.Asc)}
        labelElement={<FaIcon isVisible={renderAscOrderSort} />}
      />
      <MenuItem
        icon={<DescendingIcon />}
        data-testid="manifest-sort-decending-button"
        text="Descending"
        onClick={() => onMenuOrderClicked(SearchableSortDirection.Desc)}
        labelElement={<FaIcon isVisible={renderDescOrderSort} />}
      />
      <MenuDivider></MenuDivider>
      {menuItems.map((item) => {
        return (
          <MenuItem
            icon={item.icon}
            key={item.key}
            text={item.text}
            onClick={() => onMenuPropertyClicked(item.key)}
            labelElement={selectProperty && <FaIcon isVisible={manifestSort.property === item.key} />}
          />
        );
      })}
      <MenuItem
        icon={<ByColorIcon />}
        data-testid="manifest-sort-by-color-button"
        onClick={onSortColorClick}
        text="By Color"
        labelElement={<FaIcon isVisible={sortByColor.enable} />}
      />
    </StyledManifestSortMenu>
  ) : (
    <Menu>
      <MenuDivider title="Sorting Options"></MenuDivider>
      <SortMenuItem
        propertyKey="ascending"
        isSelected={manifestSort.sortDirection === SearchableSortDirection.Asc}
        text="Ascending"
        handleMenuItemClick={() => onMenuOrderClicked(SearchableSortDirection.Asc)}
      />
      <SortMenuItem
        propertyKey="descending"
        isSelected={manifestSort.sortDirection === SearchableSortDirection.Desc}
        text="Descending"
        handleMenuItemClick={() => onMenuOrderClicked(SearchableSortDirection.Desc)}
      />
      <MenuDivider></MenuDivider>
      {menuItems.map((item) => {
        return (
          <SortMenuItem
            key={item.key}
            propertyKey={item.key}
            isSelected={manifestSort.property === item.key}
            text={item.text}
            handleMenuItemClick={() => onMenuPropertyClicked(item.key)}
          />
        );
      })}
    </Menu>
  );
};

export default ManifestSortMenu;

export const StyledManifestSortMenu = styled(Menu)`
  font-family: Roboto, sans-serif;
`;
export const StyledManifestSortTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
`;
