import {ItemRenderer} from "@blueprintjs/select";
import styled from "@emotion/styled";
import IndicatorSelect, {IndicatorListTagItem} from "../../common/IndicatorSelect";

import {useCallback, useContext} from "react";
import {IndicatorColors} from "../ColorizedIndicators/ColorizedIndicators";

import {UserColor} from "../ColorizedIndicators/types/indicatorLogicType";
import {ServiceTypeColorContext, ServiceTypeColorDispatchContext} from "./ServiceTypeColorStateProvider";

type ColorChipProps = {
  color: string;
};
const ColorChip = styled.div<ColorChipProps>`
  width: 15px;
  height: 15px;
  border-radius: 2px;

  border: ${(props) => (props.color !== "#transparent" ? "white solid 1px" : "none")};

  background-color: ${(props) => props.color};
`;

const ColorSelector = () => {
  const {selectedColor, selectedServices} = useContext(ServiceTypeColorContext);
  const serviceTypeColorDispatch = useContext(ServiceTypeColorDispatchContext);

  const onJobColorSelectorChange = useCallback(
    (selectedItem: UserColor) => {
      serviceTypeColorDispatch({type: "SET_COLOR", payload: selectedItem});
    },
    [serviceTypeColorDispatch]
  );

  const renderItem: ItemRenderer<UserColor> = (item, {handleClick}) => {
    return (
      <IndicatorListTagItem
        key={item}
        onClick={handleClick}
        isSelected={item && selectedColor ? IndicatorColors[item].name === IndicatorColors[selectedColor].name : false}
      >
        <ColorChip color={IndicatorColors[item].hex} />
        {item && IndicatorColors[item].name}
      </IndicatorListTagItem>
    );
  };

  return (
    <IndicatorSelect
      name="service-type-color"
      disable={selectedServices.length === 0}
      dropdownPlacement="bottom-end"
      items={Object.keys(IndicatorColors) as UserColor[]}
      itemRenderer={renderItem}
      onSelectedItem={onJobColorSelectorChange}
      selectedItemText={selectedColor && IndicatorColors[selectedColor].name}
      placeholderText="Select Color"
      tag={<ColorChip color={selectedColor ? IndicatorColors[selectedColor].hex : "#transparent"} />}
    />
  );
};

export default ColorSelector;
