import styled from "@emotion/styled";
import {ControlGroup} from "@blueprintjs/core";
import {ItemRenderer} from "@blueprintjs/select";
import IndicatorSelect, {IndicatorListItem} from "../../common/IndicatorSelect";
import {useContext} from "react";
import {ColorizedIndicatorContext, ColorizedIndicatorDispatchContext} from "./ColorizedIndicatorStateProvider";
import {ManifestStatusTypes} from "./types/manifestAttributes";

const Container = styled.div``;

export const StyledControlGroup = styled(ControlGroup)`
  gap: 8px;
`;

export const manifestStatusOptions: Map<ManifestStatusTypes, string> = new Map([
  [ManifestStatusTypes.Future, "Future"],
  [ManifestStatusTypes.Active, "Active"],
  [ManifestStatusTypes.Inactive, "Inactive"]
]);

const ManifestAttributeSelector = () => {
  const colorizedIndicatorState = useContext(ColorizedIndicatorContext);
  const colorizedIndicatorDispatch = useContext(ColorizedIndicatorDispatchContext);

  const selectedValues = colorizedIndicatorState.selectedLogic?.manifestAttributes?.manifestStatus;

  const renderManifestStatusType: ItemRenderer<[ManifestStatusTypes, string]> = (item, {handleClick}) => {
    return (
      <IndicatorListItem key={item[0]} onClick={handleClick} isSelected={item[0] === selectedValues}>
        {item[1]}
      </IndicatorListItem>
    );
  };

  const onSelectManifestStatusType = (item: [ManifestStatusTypes, string]) => {
    colorizedIndicatorDispatch({type: "SET_MANIFEST_ATTRIBUTES_STATUS", payload: item[0]});
    colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
  };

  const onClearManifestStatusType = () => {
    colorizedIndicatorDispatch({type: "SET_MANIFEST_ATTRIBUTES_STATUS", payload: undefined});
    colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
  };

  return (
    <Container>
      <IndicatorSelect
        name="manifest-status"
        items={Array.from(manifestStatusOptions)}
        itemRenderer={renderManifestStatusType}
        onSelectedItem={onSelectManifestStatusType}
        placeholderText="Manifest Status"
        selectedItemText={manifestStatusOptions.get(selectedValues as ManifestStatusTypes)}
        onClear={onClearManifestStatusType}
      />
    </Container>
  );
};

export default ManifestAttributeSelector;
