import React, {JSXElementConstructor, useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../../ApplicationContext";
import ReactDOM from "react-dom";
import {Alert, Intent} from "@blueprintjs/core";
import styled from "@emotion/styled";
interface NewWindowPortalProps {
  children: React.ReactNode;
  target?: string;
  title?: string;
  alertTextWhenOpen?: string;
  alertTextWhenPaused?: string;
}
export const NewWindowPortalCommon: React.FC<NewWindowPortalProps> = ({
  children,
  target,
  title,
  alertTextWhenOpen,
  alertTextWhenPaused
}) => {
  const appContext = useContext(AppContext);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [usePortal, setUsePortal] = useState(false);

  const [isMinimizedAlertOpen, setIsMinimizedAlertOpen] = useState(false);

  const closeAlert = () => setIsAlertOpen(false);
  const newWindow = useMemo(() => {
    try {
      const windowOptions = "width=600,height=400,left=200,top=200";
      const openedWindow = window.open("", `${target ?? ""}`, windowOptions) ?? undefined;

      if (openedWindow) {
        setUsePortal(true);
        openedWindow.document.title = `${title ?? location.origin}`;
        openedWindow.addEventListener("beforeunload", () => {
          setUsePortal(false);
          appContext.dispatch({
            type: "SetOpenMapPortal",
            payload: false
          });
        });
      }
      return openedWindow;
    } catch (error) {
      console.error("Error opening new window:", error);
      return undefined;
    }
  }, [appContext, target, title]);

  useEffect(() => {
    if (alertTextWhenOpen) {
      setIsAlertOpen(true);
    }
    const handleCloseNewWindow = () => {
      if (newWindow && !newWindow.closed) {
        newWindow.close();
      }
    };

    const handleVisibilityChanged = () => {
      setIsMinimizedAlertOpen(document.visibilityState === "hidden");
    };

    window.addEventListener("beforeunload", handleCloseNewWindow);
    document.addEventListener("visibilitychange", handleVisibilityChanged);
    return () => {
      window.removeEventListener("beforeunload", handleCloseNewWindow);
    };
  }, [newWindow, alertTextWhenOpen]);

  if (children && newWindow) {
    // Ensure children is a valid React element
    copyStyles(window.document, newWindow.document);
    const validChildren = React.Children.only(children) as React.ReactElement<any, string | JSXElementConstructor<any>>;
    return (
      <>
        {ReactDOM.createPortal(React.cloneElement(validChildren, {newWindow, usePortal}), newWindow.document.body)}
        <StyledAlert
          icon={"info-sign"}
          isOpen={isAlertOpen}
          intent={Intent.PRIMARY}
          onClose={closeAlert}
          canOutsideClickCancel={false}
          confirmButtonText="Ok"
          portalContainer={newWindow.document.body}
        >
          <AlertContent>{alertTextWhenOpen}</AlertContent>
        </StyledAlert>
        <StyledAlert
          icon={"pause"}
          isOpen={isMinimizedAlertOpen}
          canOutsideClickCancel={false}
          intent={Intent.WARNING}
          portalContainer={newWindow.document.body}
          disableOkButton={true}
        >
          <AlertContent>{alertTextWhenPaused}</AlertContent>
        </StyledAlert>
      </>
    );
  }

  return null;
};

export function copyStyles(sourceDoc: Document, targetDoc: Document): void {
  Array.from(sourceDoc.styleSheets).forEach((styleSheet) => {
    try {
      if (styleSheet instanceof CSSStyleSheet) {
        const newStyleEl = sourceDoc.createElement("style");

        Array.from(styleSheet.cssRules).forEach((cssRule) => {
          newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
        });

        targetDoc.head.appendChild(newStyleEl);
      } else if ("href" in styleSheet) {
        const newLinkEl = sourceDoc.createElement("link");

        newLinkEl.rel = "stylesheet";
        newLinkEl.href = (styleSheet as any).href;
        targetDoc.head.appendChild(newLinkEl);
      }
    } catch (error) {
      // Error handling is intentionally empty as we are not logging errors.
      // This is to avoid cluttering the console with expected errors.
    }
  });
}
const StyledAlert = styled(Alert)<{disableOkButton?: boolean}>`
  .bp4-button.bp4-intent-primary {
    display: ${(props) => (props.disableOkButton ? "none" : "block")};
  }
  .bp4-button.bp4-intent-warning {
    display: ${(props) => (props.disableOkButton ? "none" : "block")};
  }
`;
const AlertContent = styled.span`
  font-size: 14px;
  font-family: Roboto, sans-serif;
`;
