import {ManifestViewFilter} from "./ManifestViewFilter";
import {ManifestAttributeFilter, NestedJobStopFilterInput} from "../../../generated/graphql";

export abstract class AbstractManifestViewFilter<T> implements ManifestViewFilter<T> {
  readonly name: string;
  public value: Set<T> = new Set();

  constructor(name: string) {
    this.name = name;
  }

  abstract toFilter(): ManifestAttributeFilter | undefined;

  toNestedFilter(): NestedJobStopFilterInput | undefined {
    return undefined;
  }

  protected handleMultiValuedFilter(filter: ManifestAttributeFilter[]): ManifestAttributeFilter {
    if (filter.length == 1) {
      return filter[0];
    } else {
      return {
        or: filter
      } as ManifestAttributeFilter;
    }
  }
}
