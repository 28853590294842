import {Constants} from "../../common/Constants";

export const convertMilesFromMeters = (meters: number) => {
  if (!meters) return 0;
  return Number((meters * Constants.MeterToMileRatio).toFixed(2));
};

export const millisecondsToHMS = (milliseconds: number) => {
  if (milliseconds === 0) return "0h 0m";
  let seconds = Math.floor(Math.abs(milliseconds) / 1000);

  const days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;

  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;

  const minutes = Math.floor(seconds / 60);

  return `${milliseconds < 0 ? "-" : ""}${days > 0 ? days + "d " : ""}${hours}h ${minutes}m`;
};
