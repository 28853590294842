import {Manifest} from "../../generated/graphql";
import styled from "@emotion/styled";
import {DateFormats, EmptyValueStrings} from "../common/Constants";
import {HoverOnDriversValues} from "./card-configuration/ManifestConfigurationTypes";
import {useCallback, useMemo} from "react";
import {getFormattedManifestDate} from "./types/ManifestSupport";

export interface DriverToolTipContentProps {
  manifest?: Manifest;
  driverHoverConfigs: string[] | undefined;
}

const DriverToolTipContent = ({manifest, driverHoverConfigs}: DriverToolTipContentProps) => {
  const driverCode = manifest?.driver?.code;
  const driverPhone = manifest?.driver?.phone;
  const driverName = manifest?.driver?.name;
  const driverAlias = manifest?.driver?.alias;
  const manifestID = manifest?.manifestDriverId;
  const lastZone = manifest?.lastZone;
  const endZip = manifest?.endZip;
  const driverNotes = manifest?.driver?.note;
  const totalPieces = manifest?.totalPieces;

  const renderDate = useCallback(() => {
    if (!manifest?.manifestDate) {
      return "";
    }
    return getFormattedManifestDate(manifest.manifestDate, DateFormats.smallDate);
  }, [manifest?.manifestDate]);

  const renderHoverContent = useMemo(() => {
    const renderText = new Map<string, {content: string | number}>();
    driverHoverConfigs?.forEach((config) => {
      switch (config) {
        case HoverOnDriversValues.DRIVER_CODE:
          renderText.set(HoverOnDriversValues.DRIVER_CODE, {content: driverCode ?? EmptyValueStrings.notProvided});
          break;
        case HoverOnDriversValues.DRIVER_NAME:
          renderText.set(HoverOnDriversValues.DRIVER_NAME, {content: driverName ?? EmptyValueStrings.notProvided});
          break;
        case HoverOnDriversValues.DRIVER_PHONE:
          renderText.set(HoverOnDriversValues.DRIVER_PHONE, {content: driverPhone ?? EmptyValueStrings.notProvided});
          break;
        case HoverOnDriversValues.ALIAS:
          renderText.set(HoverOnDriversValues.ALIAS, {content: driverAlias ?? EmptyValueStrings.notProvided});
          break;
        case HoverOnDriversValues.MANIFEST_ID:
          renderText.set(HoverOnDriversValues.MANIFEST_ID, {
            content: manifestID ?? EmptyValueStrings.notProvided
          });
          break;
        case HoverOnDriversValues.MANIFEST_DATE:
          renderText.set(HoverOnDriversValues.MANIFEST_DATE, {content: renderDate()});
          break;
        case HoverOnDriversValues.LAST_ZONE:
          renderText.set(HoverOnDriversValues.LAST_ZONE, {content: lastZone ?? EmptyValueStrings.notProvided});
          break;
        case HoverOnDriversValues.END_ZIP:
          renderText.set(HoverOnDriversValues.END_ZIP, {
            content: endZip ?? EmptyValueStrings.notProvided
          });
          break;
        case HoverOnDriversValues.NOTES:
          renderText.set(HoverOnDriversValues.NOTES, {content: driverNotes ?? EmptyValueStrings.notProvided});
          break;
        case HoverOnDriversValues.TOTAL_PIECES:
          renderText.set(HoverOnDriversValues.TOTAL_PIECES, {content: totalPieces ?? EmptyValueStrings.notProvided});
          break;
      }
    });

    return renderText;
  }, [
    driverHoverConfigs,
    driverCode,
    driverName,
    driverPhone,
    driverAlias,
    manifestID,
    renderDate,
    lastZone,
    endZip,
    driverNotes,
    totalPieces
  ]);
  const renderElementToolTip = useCallback(() => {
    const arrayOfContent = Array.from(renderHoverContent);
    return arrayOfContent.map(([key, value]) => {
      return (
        <DriverManifestText key={key}>
          {key}: {value.content}
        </DriverManifestText>
      );
    });
  }, [renderHoverContent]);
  return driverHoverConfigs ? (
    <DriverToolTipContainer data-testid="driver-tooltip-container">{renderElementToolTip()}</DriverToolTipContainer>
  ) : (
    <DriverToolTipContainer data-testid="driver-tooltip-container">
      <DriverToolTipTop data-testid="driver-tooltip-top">
        <DriverManifestText isBoldText={true}>Driver Code: {driverCode}</DriverManifestText>
        {driverPhone && <DriverManifestText isBoldText={true}>{driverPhone}</DriverManifestText>}
        <DriverManifestText isBoldText={true}>Alias: {driverAlias}</DriverManifestText>
      </DriverToolTipTop>
      <DriverToolTipBot data-testid="driver-tooltip-bottom">
        <DriverManifestText isBoldText={false}>Manifest ID: {manifestID}</DriverManifestText>
        <DriverManifestText isBoldText={false}>Date: {renderDate()}</DriverManifestText>
        <DriverManifestText isBoldText={false}>End Zone: {lastZone}</DriverManifestText>
      </DriverToolTipBot>
    </DriverToolTipContainer>
  );
};

export default DriverToolTipContent;

const DriverToolTipContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-family: Roboto;
`;
const DriverToolTipTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
`;
const DriverToolTipBot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
`;
const DriverManifestText = styled.p<{isBoldText?: boolean}>`
  font-size: ${(props) => (props.isBoldText ? "12px" : "10px")};
  color: ${(props) => (props.isBoldText ? "#161616" : "#797979")};
  margin: 0;
  padding: 0;
`;
