import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import SettingViewContainer from "../components/SettingViewContainer";
import ServiceTypeColorConfig from "./ServiceTypeColorConfig";
import ActionContainer from "./ActionContainer";
import {
  SearchableSortDirection,
  Service,
  ServiceSortableField,
  useSearchServicesQuery
} from "../../../generated/graphql";
import {getArrayFromField} from "../../../utils/General";
import {LoadingSpinner} from "../../common/LoadingSpinner";

import {PreferenceContext, extractSimplePref} from "../../../providers/PreferenceProvider";
import {TenantPreferences} from "../../common/Constants";
import {UserColor} from "../ColorizedIndicators/types/indicatorLogicType";

export type ServiceTypeColorType = {
  name: string;
  description: string;
  color: UserColor;
};

const ServiceTypeColor = () => {
  const {tenantPreferences} = useContext(PreferenceContext);
  const [services, setServices] = useState<ServiceTypeColorType[] | undefined>(undefined);

  const serviceTypeColorData: ServiceTypeColorType[] = useMemo(() => {
    return extractSimplePref(tenantPreferences, TenantPreferences.serviceTypeColor, []).value as ServiceTypeColorType[];
  }, [tenantPreferences]);

  const servicesResults = useSearchServicesQuery({
    variables: {
      limit: 200,
      sort: {
        field: ServiceSortableField.Description,
        direction: SearchableSortDirection.Asc
      },
      filter: {
        status: {
          match: "A"
        }
      }
    }
  });

  useEffect(() => {
    if (!servicesResults.loading) {
      const services = getArrayFromField(servicesResults.data?.searchServices?.items).map((item: Service) => {
        return {
          name: item.service,
          description: item.description,
          color: serviceTypeColorData.find((sv) => sv.name === item.service)?.color
        } as ServiceTypeColorType;
      });
      setServices(services);
    }
  }, [serviceTypeColorData, servicesResults.data?.searchServices?.items, servicesResults.loading]);

  const renderContent = useCallback(() => {
    if (servicesResults.loading || !services) {
      return <LoadingSpinner />;
    }
    return (
      <>
        <ServiceTypeColorConfig services={services} />
        <ActionContainer />
      </>
    );
  }, [services, servicesResults.loading]);

  return <SettingViewContainer title={"Service Type Color Settings"}>{renderContent()}</SettingViewContainer>;
};

export default ServiceTypeColor;
