import {ManifestStop, NestedJobStop} from "../generated/graphql";
import {jobStopStatuses} from "./JobStopStatusResolver";

export const pickupStop = (stops: NestedJobStop[]): NestedJobStop | undefined => {
  if (stops && stops.length > 0) {
    return stops.find((stop) => stop.stopType === "P" || stop.stopType === "B");
  }
  return undefined;
};

export const deliveryStop = (stops: NestedJobStop[]): NestedJobStop | undefined => {
  if (stops && stops.length > 0) {
    const reversedStops = stops.slice().reverse();
    return reversedStops.find((stop) => stop.stopType === "D" || stop.stopType === "B");
  }
  return undefined;
};

export const isCompleted = (stop: NestedJobStop | ManifestStop) =>
  stop.jobStopStatus === jobStopStatuses.completed.code;
export const isActive = (stop: NestedJobStop | ManifestStop) => stop.jobStopStatus === jobStopStatuses.active.code;

export const sequenceComparator = (
  a: NestedJobStop | ManifestStop | undefined,
  b: NestedJobStop | ManifestStop | undefined
): number => {
  if (a?.manifestSequence && b?.manifestSequence) {
    return (a.manifestSequence || 0) - (b.manifestSequence || 0);
  } else {
    return (a?.sequence ?? 0) - (b?.sequence ?? 0);
  }
};
