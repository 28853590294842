import {createContext, useCallback, useEffect, useMemo, useState} from "react";
import {
  Pref,
  SetUserPrefInput,
  TenantPref,
  UserPref,
  useSearchTenantPrefsQuery,
  useSearchUserPrefsQuery
} from "../generated/graphql";
import {useOktaAuth} from "@okta/okta-react";

interface PrefValue<T> {
  name: string;
  value?: T;
}

type PreferenceContextState = {
  userPreferences: Pref[];
  tenantPreferences: Pref[];
  userPrefsQueryRefetch?: () => void;
  tenantPrefsQueryRefetch?: () => void;
};

const initialState: PreferenceContextState = {
  userPreferences: [],
  tenantPreferences: []
};

const PreferenceContext = createContext<PreferenceContextState>(initialState);

type PreferenceProviderProps = {
  children: React.ReactNode;
};

const PreferenceProvider = ({children}: PreferenceProviderProps) => {
  //This to pass pipline because cannot destructure property 'authState' as it is null.
  const oktaAuth = useOktaAuth();
  const {authState} = oktaAuth || {};
  const [state, setState] = useState<PreferenceContextState>(initialState);
  const userPrefsQuery = useSearchUserPrefsQuery({
    skip: !authState?.accessToken
  });

  const tenantPrefsQuery = useSearchTenantPrefsQuery({
    skip: !authState?.accessToken
  });

  useEffect(() => {
    const isCannotGetAccessToken = !authState?.accessToken && userPrefsQuery.loading && !userPrefsQuery.data;
    if (isCannotGetAccessToken) {
      userPrefsQuery.refetch();
    }
  }, [authState?.accessToken, userPrefsQuery]);

  useEffect(() => {
    if (authState?.accessToken && !tenantPrefsQuery.data) {
      tenantPrefsQuery.refetch();
    }
  }, [authState?.accessToken, tenantPrefsQuery]);

  const tenantPrefsQueryRefetch = useCallback(() => {
    tenantPrefsQuery.refetch();
  }, [tenantPrefsQuery]);

  const userPrefsQueryRefetch = useCallback(() => {
    userPrefsQuery.refetch();
  }, [userPrefsQuery]);

  useEffect(() => {
    if (userPrefsQuery.error) {
      console.error("Error loading user preferences", userPrefsQuery.error);
    }

    if (userPrefsQuery.data && !userPrefsQuery.loading) {
      console.debug(
        `${userPrefsQuery.data.searchUserPrefs?.items.length} User preferences loaded`,
        userPrefsQuery.data.searchUserPrefs?.items
      );
      setState((prevState) => {
        return {
          ...prevState,
          userPreferences: userPrefsQuery.data?.searchUserPrefs?.items
            ? (userPrefsQuery.data.searchUserPrefs.items as [])
            : ([] as UserPref[])
        };
      });
    }
  }, [userPrefsQuery.data, userPrefsQuery.loading, userPrefsQuery.error]);

  useEffect(() => {
    if (tenantPrefsQuery.error) {
      console.error("Error loading tenant preferences", tenantPrefsQuery.error);
    }

    if (tenantPrefsQuery.data && !tenantPrefsQuery.loading) {
      console.debug(
        `${tenantPrefsQuery.data.searchTenantPrefs?.items.length} Tenant preferences loaded`,
        tenantPrefsQuery.data.searchTenantPrefs?.items
      );
      setState((prevState) => {
        return {
          ...prevState,
          tenantPreferences: tenantPrefsQuery.data?.searchTenantPrefs?.items
            ? (tenantPrefsQuery.data.searchTenantPrefs.items as [])
            : ([] as TenantPref[])
        };
      });
    }
  }, [tenantPrefsQuery.data, tenantPrefsQuery.loading, tenantPrefsQuery.error]);

  const valueState = useMemo(
    () => ({
      ...state,
      tenantPrefsQueryRefetch,
      userPrefsQueryRefetch
    }),
    [state, tenantPrefsQueryRefetch, userPrefsQueryRefetch]
  );

  return <PreferenceContext.Provider value={valueState}>{children}</PreferenceContext.Provider>;
};

const extractSimplePref = <T,>(prefs: Pref[], key: string, defaultValue?: T): PrefValue<T> => {
  const keyValue = prefs.filter((pref) => pref.name === key)[0];

  if (keyValue && keyValue.value !== null) {
    return {name: keyValue.name, value: JSON.parse(keyValue.value).value as T};
  }

  return {name: key, value: defaultValue};
};

const extractJsonPref = (prefs: Pref[], key: string, defaultValue?: any) => {
  const keyValue = prefs.filter((pref) => pref.name === key)[0];
  if (keyValue && keyValue.value !== null) {
    return {name: keyValue.name, value: JSON.parse(keyValue.value)};
  }

  return {name: key, value: defaultValue};
};

const createJsonPref = (value: any, isArrayValue?: boolean): SetUserPrefInput => {
  if (isArrayValue) {
    return {
      value: JSON.stringify(value)
    };
  }
  return {
    value: JSON.stringify({
      value: value
    })
  };
};

export default PreferenceProvider;
export {PreferenceContext, extractJsonPref, extractSimplePref, createJsonPref};
export type {PreferenceContextState, PrefValue};
