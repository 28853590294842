import {ICellRendererParams} from "@ag-grid-community/core";
import {ManifestStop} from "../../../generated/graphql";
import styled from "@emotion/styled";
import {EmptyValueStrings} from "../Constants";
import {Tooltip2} from "@blueprintjs/popover2";
import {Text} from "@blueprintjs/core";

const Container = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
`;

export const CellRendererWithToolTip = (props: ICellRendererParams<ManifestStop, string | undefined>) => {
  const value = props.value ?? EmptyValueStrings.notProvided;
  return (
    <Tooltip2 fill placement={"top-start"} content={value}>
      <Container>
        <Text ellipsize>{value}</Text>
      </Container>
    </Tooltip2>
  );
};
