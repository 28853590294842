import {loadDevMessages, loadErrorMessages} from "@apollo/client/dev";
import {Constants} from "./components/common/Constants";
import {OktaAuthOptions} from "@okta/okta-auth-js";
// TODO: Move this to .env
// https://create-react-app.dev/docs/adding-custom-environment-variables/
// https://12factor.net/config

// Environments
export interface Environment {
  name: string;
  okta: {
    clientId: string;
  };
  gqlApiUrl: string;
  servicesApiUrl: string;
  configCatKey: string;
  configCatRefreshInSeconds: number;
  updateCheckInSeconds: number;
}

const prod: Environment = {
  name: "prod",
  okta: {
    clientId: "0oa3mgg0gcI3vXges5d7"
  },
  gqlApiUrl: "https://gql.e-courier.com/graphql",
  servicesApiUrl: "https://api.e-courier.com",
  configCatKey: "yFraCLRBA0qH1-3zZIOFKA/X_pS1R02nU23gER9p0sp1Q",
  configCatRefreshInSeconds: 300,
  updateCheckInSeconds: 1800
};

const staging: Environment = {
  name: "staging",
  okta: {
    clientId: "0oa531956lHA3bOpM5d7"
  },
  gqlApiUrl: "https://gql.dispatch.staging.anabasius.net/graphql",
  servicesApiUrl: "https://api.dispatch.staging.anabasius.net",
  configCatKey: "yFraCLRBA0qH1-3zZIOFKA/wFNir9ncdkSR_woiP05fgQ",
  configCatRefreshInSeconds: 300,
  updateCheckInSeconds: 600
};

const dev: Environment = {
  name: "dev",
  okta: {
    clientId: "0oa531956lHA3bOpM5d7"
  },
  gqlApiUrl: "https://gql.dispatch.dev.anabasius.net/graphql",
  servicesApiUrl: "https://api.dispatch.dev.anabasius.net",
  configCatKey: "yFraCLRBA0qH1-3zZIOFKA/wFNir9ncdkSR_woiP05fgQ",
  configCatRefreshInSeconds: 300,
  updateCheckInSeconds: 600
};

let env: Environment = dev;
const hostname = window.location.hostname;

if (hostname.match(/^dispatch\.(e-courier\.com|anabasius\.net)/)) {
  env = prod;
} else if (hostname === "staging.dispatch.anabasius.net") {
  env = staging;
}

if (env === dev) {
  //apollo error messages in dev
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

// Okta Config
const oktaAuthConfig: OktaAuthOptions = {
  issuer: "https://ecourier.okta.com/oauth2/aus3u2bl237MTIwSx5d7",
  clientId: env.okta.clientId,
  redirectUri: window.location.origin + "/login/callback",
  maxClockSkew: Constants.DEFAULT_MAX_CLOCK_SKEW_SECONDS,
  scopes: ["openid", "profile", "email", "offline_access"],
  devMode: false,
  tokenManager: {
    autoRenew: true,
    expireEarlySeconds: 60 //accounting for 1 min of clock drift
  },
  services: {
    autoRenew: true
  },
  cookies: {
    secure: true
  }
};

// GraphQL Config
const GQL_API_URL = env.gqlApiUrl;
const SERVICES_API_URL = env.servicesApiUrl;
const CONFIG_CAT_KEY = env.configCatKey;
const CONFIG_CAT_REFRESH_INTERVAL = env.configCatRefreshInSeconds;
const REGION = "us-east-1";

export const isTestingEnv = (): boolean => {
  return [dev.name, staging.name].includes(env.name);
};

export {
  env,
  oktaAuthConfig,
  GQL_API_URL,
  SERVICES_API_URL,
  REGION,
  CONFIG_CAT_KEY,
  CONFIG_CAT_REFRESH_INTERVAL,
  prod,
  staging,
  dev
};
