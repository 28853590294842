import {MouseEvent} from "react";
import {Manifest} from "../../generated/graphql";

type CreateCardHandlers = {
  onSelectedManifest(manifest: Manifest, isSelected?: boolean): void;
  onDrillDownManifest?(manifest: Manifest): void;
};

const createCardHandlers = ({onSelectedManifest, onDrillDownManifest}: CreateCardHandlers) => {
  const handleOnSelected = (e: MouseEvent, selectedManifest: Manifest, isSelected?: boolean) => {
    onSelectedManifest(selectedManifest, isSelected);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrillDownManifest = (e: MouseEvent, openedManifest: Manifest) => {
    onDrillDownManifest?.(openedManifest);
    e.preventDefault();
    e.stopPropagation();
  };

  const hasOnSelectedHandler = !!onSelectedManifest;
  const hasDrillDownHandler = !!onDrillDownManifest;

  return {handleOnSelected, handleDrillDownManifest, hasOnSelectedHandler, hasDrillDownHandler};
};

export {createCardHandlers};
