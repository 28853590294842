import {Button, ButtonGroup, IconName, MaybeElement} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {useListActionBarState} from "./ListActionBarProvider";
import {Key} from "react";

type ListActionButton = {
  id: string;
  label: () => string;
  action: () => void;
  active?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  intent: ButtonIntent;
  rightIcon?: IconName | MaybeElement;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 5px;
  height: 50px;
  background: #fcfcfc;
  border-radius: 12px;

  filter: drop-shadow(0px 10px 1px rgba(0, 0, 0, 0.02)) drop-shadow(0px 7px 15px rgba(0, 0, 0, 0.4));
`;

const PinnedContainer = styled(Container)`
  position: absolute;
  bottom: 95px;
  left: 0;
  right: 0;
  margin: auto;
`;

const StyledButton = styled(Button)`
  &.bp4-button:not([class*="bp4-intent-"]) {
    height: 40px;
    border-radius: 8px 0px 0px 8px;
    font-size: 16px;
    box-shadow: 0px 1px 2px 0px #00000040;
    z-index: 1;
    opacity: 0.85;

    :hover {
      opacity: 1;
    }
  }
`;

const SecondaryButton = styled(StyledButton)`
  &.bp4-button:not([class*="bp4-intent-"]) {
    width: 190px;
    background: linear-gradient(180deg, #fcfcfc 0%, #f3f3f3 100%);
    color: #fa545e;
  }
`;

const PrimaryButton = styled(Button)`
  &.bp4-button:not([class*="bp4-intent-"]) {
    width: 122px;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    color: #ffffff;
    box-shadow: 0px 1px 2px 0px #00000040 !important;
    background: linear-gradient(180deg, #214a89 0%, #14305a 100%);
  }

  &.bp4-button[disabled] {
    color: rgba(119, 119, 119, 0.3);
    background: #ededed;
  }
`;

const ToggleInfoButton = styled(Button)`
  &.bp4-button:not([class*="bp4-intent-"]) {
    width: 190px;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    gap: 12px;
    color: #161616 !important;
    background: linear-gradient(180deg, #fcfcfc 0%, #f3f3f3 100%) !important;
    box-shadow: 0px 1px 2px 0px #00000040 !important;
    opacity: 0.9;

    &.bp4-active {
      color: #ffffff !important;
      background: linear-gradient(180deg, #8da4be 0%, #7c8ea2 100%) !important;

      path {
        fill: #ffffff;
      }
    }
  }
`;

type ButtonIntent = "primary" | "info" | "secondary";

type ListActionBarProps = {
  buttons: ListActionButton[];
};

type IntentButtonProps = {
  buttonIntent: ButtonIntent;
  onClick: () => void;
  active?: boolean;
  disabled?: boolean;
  key: Key;
  rightIcon?: IconName | MaybeElement;
  children: JSX.Element[] | JSX.Element;
};

const IntentButton = (props: IntentButtonProps) => {
  switch (props.buttonIntent) {
    case "primary":
      return <PrimaryButton {...props}></PrimaryButton>;
    case "info":
      return <ToggleInfoButton {...props}></ToggleInfoButton>;
    case "secondary":
      return <SecondaryButton {...props}></SecondaryButton>;
    default:
      return <SecondaryButton {...props}></SecondaryButton>;
  }
};

const ListActionBar = () => {
  const {isShowing, buttons} = useListActionBarState();

  return isShowing ? (
    <PinnedContainer>
      <ButtonGroup role="toolbar" aria-label="list-action-bar">
        {buttons.map(
          (button) =>
            !button.hidden && (
              <IntentButton
                buttonIntent={button.intent}
                key={button.label()}
                onClick={button.action}
                rightIcon={button.rightIcon}
                active={button.active ?? true}
                disabled={button.disabled ?? false}
              >
                <span>{button.label()}</span>
              </IntentButton>
            )
        )}
      </ButtonGroup>
    </PinnedContainer>
  ) : (
    <div></div>
  );
};

export default ListActionBar;
export type {ListActionBarProps, ListActionButton};
