import {Icon, Tag} from "@blueprintjs/core";
import {Popover2} from "@blueprintjs/popover2";

import styled from "@emotion/styled";
import {StyledButton} from "./IndicatorSelect";
import {debounce} from "lodash";
import {useEffect, useMemo, useState} from "react";

import {OptionType} from "./types/optionType";
import InfiniteScroll from "react-infinite-scroll-component";

type OptionItemProps = {
  item: OptionType;
  handleClick: (item: OptionType) => void;
};

type SelectedItemProps = {
  item: OptionType;
  handleClick: (item: OptionType) => void;
};
interface MultiSelectProps<T> {
  options: T[];
  testIdPrefix?: string;
  onOptionSelect: (item: any) => void;
  selectedOptions: T[];
  onClear: () => void;
  placeholder: string;
  text?: string;
  onQueryChange: (query: string) => void;
  onLoadMore?: () => void;
  activeBackGroundColor?: string;
  defaultTextColor?: string;
  iconSize?: number;
  maxHeight?: number;
  maxWith?: number;
  exceedMaxSelection?: number;
  maxHeightOfOptionOrSelection?: number;
}

const FilterableMultiSelect = (props: MultiSelectProps<OptionType>) => {
  return (
    <StyledPopover
      popoverClassName="colorized-indicators-multiselect-popover"
      data-testid={`${props.testIdPrefix}-filterable-multiselect-popover`}
      content={<MultipleSelect {...props} />}
      position="bottom-right"
    >
      <StyledButton
        maxWidth={props.maxWith}
        hasValue={Boolean(props.selectedOptions?.length > 0)}
        data-testid={`${props.testIdPrefix}-filterable-multiselect`}
        text={props.text}
        activeBackGroundColor={props.activeBackGroundColor ?? "#8DA4BE"}
        defaultTextColor={props.defaultTextColor ?? "#8DA4BE"}
        rightIcon={<Icon className="dropdown-icon" icon="chevron-down" size={props.iconSize ?? undefined} />}
      />
    </StyledPopover>
  );
};

export default FilterableMultiSelect;

const isClearDisabled = (selectedOptions: OptionType[]) => {
  return !selectedOptions || selectedOptions.length === 0;
};
const defaultMaxHeightOfOptionList = 200;
const defaultMaxHeightOfSelectionList = 160;
const MultipleSelect = ({
  options,
  testIdPrefix = "",
  onClear,
  onOptionSelect,
  placeholder,
  selectedOptions,
  onQueryChange,
  onLoadMore = () => {
    /* NO OP */
  },
  maxHeight,
  maxWith,
  exceedMaxSelection,
  maxHeightOfOptionOrSelection
}: MultiSelectProps<OptionType>) => {
  const [unselectedOptions, setUnselectedOptions] = useState<OptionType[]>([]);
  const selectedOptionKeys = useMemo<any[]>(() => selectedOptions.map((o) => o.key), [selectedOptions]);
  useEffect(() => {
    return () => {
      onQueryChange("");
    };
  }, [onQueryChange]);

  useEffect(() => {
    const unselected = options.filter((o) => !selectedOptionKeys.includes(o.key));
    setUnselectedOptions(unselected);
  }, [options, selectedOptionKeys]);

  const onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    onQueryChange(query);
  };

  const renderOptionList = (options: OptionType[]) => {
    return options.map((option) => {
      return <OptionItem key={option.key} item={option} handleClick={onOptionSelect} />;
    });
  };

  const renderTagList = (options: OptionType[]) => {
    if (!options || options.length === 0) {
      return [] as Element[];
    } else {
      return options.map((option) => {
        return (
          <ItemTag
            data-testid={`selected-item-${option.key}`}
            key={option.key}
            item={option}
            handleClick={onOptionSelect}
          />
        );
      });
    }
  };
  const renderCount = () => {
    return `${selectedOptions.length} of ${exceedMaxSelection}`;
  };
  const renderMaxHeightSelectionList = useMemo(() => {
    return maxHeightOfOptionOrSelection ?? defaultMaxHeightOfSelectionList;
  }, [maxHeightOfOptionOrSelection]);
  const renderMaxHeightOptionList = useMemo(() => {
    return maxHeightOfOptionOrSelection ?? defaultMaxHeightOfOptionList;
  }, [maxHeightOfOptionOrSelection]);
  return (
    <Container data-testid={`${testIdPrefix}-multiple-select`} maxWidth={maxWith}>
      <Body>
        <SearchContainer>
          <Search>
            <StyledIcon icon="search" />
            <StyledInput
              data-testid={`${testIdPrefix}-multi-search-input`}
              type="text"
              placeholder={placeholder}
              onChange={debounce(onChangeFilter, 400)}
            />
          </Search>
        </SearchContainer>
        {selectedOptions.length > 0 && (
          <SelectedItemsContainer>
            <SelectedItemsList
              maxHeight={maxHeight && unselectedOptions.length === 0 ? maxHeight : renderMaxHeightSelectionList}
            >
              {renderTagList(selectedOptions)}
            </SelectedItemsList>
          </SelectedItemsContainer>
        )}
        {unselectedOptions.length > 0 && (
          <OptionsContainer>
            <InfiniteScroll
              dataLength={unselectedOptions.length}
              next={onLoadMore}
              hasMore={true}
              loader={""}
              scrollableTarget="infinity-scroll-list"
            >
              <OptionList
                id="infinity-scroll-list"
                data-testid={`${testIdPrefix}-infinity-scroll-list`}
                maxHeight={maxHeight && selectedOptions.length === 0 ? maxHeight : renderMaxHeightOptionList}
              >
                {renderOptionList(unselectedOptions)}
              </OptionList>
            </InfiniteScroll>
          </OptionsContainer>
        )}
      </Body>
      <Footer>
        {exceedMaxSelection && (
          <SelectionCounter isExceed={exceedMaxSelection === selectedOptions.length}>{renderCount()}</SelectionCounter>
        )}
        <ClearButton onClick={onClear} disabled={isClearDisabled(selectedOptions)}>
          Clear Filter <span style={{color: "red"}}>({selectedOptions?.length ?? 0})</span>
        </ClearButton>
      </Footer>
    </Container>
  );
};

const ItemTag = ({item, handleClick}: SelectedItemProps) => {
  return (
    <StyledTag
      style={{margin: "1px"}}
      key={item.key}
      rightIcon={
        <Icon
          data-testid={`item-deselect-${item.key}`}
          style={{cursor: "pointer"}}
          icon="cross"
          size={16}
          onClick={() => handleClick(item)}
        />
      }
    >
      {item.value}
    </StyledTag>
  );
};

const OptionItem = ({item, handleClick}: OptionItemProps) => {
  return (
    <Option data-testid="option-item" key={item.key} onClick={() => handleClick(item)}>
      {item.value}
    </Option>
  );
};

const StyledPopover = styled(Popover2)``;

const Container = styled.div<{maxWidth?: number}>`
  font-family: "Roboto", sans-serif;
  width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "max-content")};
  max-width: 300px;
  background-color: white;
  box-shadow: 0px 2px 6px 0px #0000001a;
  border-radius: 4px;
`;

const SearchContainer = styled.div`
  padding: 8px 10px;
  box-shadow: 0px 1px 2px 0px #0000001a;
  background-color: #fcfcfc;
  height: 48px;
`;

const SelectedItemsContainer = styled.div`
  padding: 3px;
  box-shadow: 0px 1px 2px 0px #0000001a;
`;

const SelectedItemsList = styled.div<{maxHeight: number}>`
  overflow-y: auto;
  padding: 6px 10px;
  background-color: #fcfcfc;
  max-height: ${(props) => `${props.maxHeight}px`};
  ::-webkit-scrollbar-track {
    background-color: #fcfcfc;
  }
`;

const OptionsContainer = styled.div`
  padding: 3px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;

  & ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #d9d9d9;
  }

  & ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 30px;
    background-color: #d9d9d9;
  }
`;

const Footer = styled.div`
  padding: 10px;
  background-color: #fcfcfc;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 0px 3px 0px #00000026;
  height: 36px;
`;

const OptionList = styled.ul<{maxHeight: number}>`
  overflow-y: auto;
  list-style: none;
  text-overflow: ellipsis;
  padding: 0;
  margin: 0;
  max-height: ${(props) => `${props.maxHeight}px`};
  ::-webkit-scrollbar-track {
    background-color: #ffffff;
  }
`;

const StyledTag = styled(Tag)`
  background-color: #8da4beb2;
  font-size: 12px;
`;

const Option = styled.li`
  border-radius: 4px;
  padding: 5px;
  margin: 5px;
  color: #161616;
  font-size: 14px;

  :hover {
    background-color: #0038ff1a;
    cursor: pointer;
  }
`;

const Search = styled.div`
  font-family: "Roboto", sans-serif;
  position: relative;
  border: 1px solid #c3c3c373;
  border-radius: 4px;
  display: flex;
  height: 32px;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  color: #a4a5a6;
  padding: 8px 8px 8px 12px;
`;

const StyledInput = styled.input`
  display: inline;
  width: 100%;
  line-height: 16px;
  border: none;
  outline: none;
`;

const ClearButton = styled.button`
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  border: none;
  color: #14305a;
  background-color: transparent;
  cursor: pointer;

  span {
    color: #fa545e;
  }

  :disabled {
    opacity: 0.5;
  }
`;
const SelectionCounter = styled.span<{isExceed: boolean}>`
  font-size: 10px;
  font-weight: 400;
  color: ${(props) => (props.isExceed ? "#FA545E" : "#797979")};
  margin: 0 auto 0 3px;
`;
