import styled from "@emotion/styled";
import {ControlGroup} from "@blueprintjs/core";
import {ItemRenderer} from "@blueprintjs/select";
import IndicatorSelect, {IndicatorListItem} from "../../common/IndicatorSelect";
import {JobTypes, StopTypes} from "./types/jobTypeSelectorValues";
import {useContext} from "react";
import {ColorizedIndicatorContext, ColorizedIndicatorDispatchContext} from "./ColorizedIndicatorStateProvider";
import {FeatureFlagContext} from "../../../providers/FeatureFlagProvider";

const JobTypeSelectorContainer = styled.div``;

export const StyledControlGroup = styled(ControlGroup)`
  gap: 8px;
`;

const jobTypes: Map<JobTypes, string> = new Map([
  [JobTypes.UnassignedJobs, "Unassigned Jobs"],
  [JobTypes.AssignedStops, "Assigned Stops"]
]);

const stopTypes: Map<StopTypes, string> = new Map([
  [StopTypes.Pickup, "Pickup"],
  [StopTypes.DeliveryOrBoth, "Delivery + Both"]
]);

const additionalStopTypes: Map<StopTypes, string> = new Map([
  [StopTypes.Pickup, "Pickup"],
  [StopTypes.Delivery, "Delivery"],
  [StopTypes.Both, "Both"],
  [StopTypes.DeliveryOrBoth, "Delivery + Both"],
  [StopTypes.PickupOrBoth, "Pickup + Both"]
]);

const JobTypeSelector = () => {
  const colorizedIndicatorState = useContext(ColorizedIndicatorContext);
  const colorizedIndicatorDispatch = useContext(ColorizedIndicatorDispatchContext);
  const {colorsDP1665: additionalStopTypeEnabled} = useContext(FeatureFlagContext);

  const selectedValues = colorizedIndicatorState.selectedLogic?.type;

  const renderJobType: ItemRenderer<[JobTypes, string]> = (item, {handleClick}) => {
    return (
      <IndicatorListItem key={item[0]} onClick={handleClick} isSelected={item[0] === selectedValues?.jobTypeValue}>
        {item[1]}
      </IndicatorListItem>
    );
  };

  const renderStopType: ItemRenderer<[StopTypes, string]> = (item, {handleClick}) => {
    return (
      <IndicatorListItem key={item[0]} onClick={handleClick} isSelected={item[0] === selectedValues?.stopTypeValue}>
        {item[1]}
      </IndicatorListItem>
    );
  };

  const onSelectJobType = (item: [JobTypes, string]) => {
    if (item[0] === JobTypes.UnassignedJobs) {
      colorizedIndicatorDispatch({type: "SET_JOB_TYPE", payload: {jobTypeValue: item[0]}});
      colorizedIndicatorDispatch({type: "SET_MANIFEST_ATTRIBUTES_STATUS", payload: undefined});
      colorizedIndicatorDispatch({type: "SET_JOB_ATTRIBUTES_STOPSTATUS", payload: undefined});
      colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
    } else {
      colorizedIndicatorDispatch({
        type: "SET_JOB_TYPE",
        payload: {
          ...selectedValues,
          jobTypeValue: item[0],
          stopTypeValue: selectedValues?.stopTypeValue ?? undefined
        }
      });
      if (!colorizedIndicatorState.selectedLogic?.jobAttributes.stopStatus) {
        colorizedIndicatorDispatch({type: "SET_JOB_ATTRIBUTES_STOPSTATUS", payload: []});
      }
      colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
    }
  };

  const renderStopTypes = additionalStopTypeEnabled ? additionalStopTypes : stopTypes;

  const onSelectStopType = (item: [StopTypes, string]) => {
    colorizedIndicatorDispatch({
      type: "SET_JOB_TYPE",
      payload: {
        ...selectedValues,
        stopTypeValue: item[0]
      }
    });
    colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
  };
  return (
    <JobTypeSelectorContainer>
      <StyledControlGroup>
        <IndicatorSelect
          name="job-type"
          items={Array.from(jobTypes)}
          itemRenderer={renderJobType}
          onSelectedItem={onSelectJobType}
          placeholderText="Unassigned Job/ Assigned Stop"
          selectedItemText={jobTypes.get(selectedValues?.jobTypeValue as JobTypes)}
        />
        {selectedValues?.jobTypeValue === JobTypes.AssignedStops && (
          <IndicatorSelect
            name="stop-type"
            items={Array.from(renderStopTypes)}
            itemRenderer={renderStopType}
            onSelectedItem={onSelectStopType}
            placeholderText="Stop Type"
            selectedItemText={renderStopTypes.get(selectedValues.stopTypeValue as StopTypes)}
          />
        )}
      </StyledControlGroup>
    </JobTypeSelectorContainer>
  );
};

export default JobTypeSelector;
export {jobTypes, stopTypes, additionalStopTypes};
