import {createContext, useCallback, useContext, useEffect, useState} from "react";
import {Constants} from "./Constants";
import {
  DispatchGroup,
  ModelDispatchGroupFilterInput,
  SearchableDispatchGroupSortableFields,
  useSearchDispatchGroupsQuery
} from "../../generated/graphql";
import {DispatchStationDataContext} from "./DispatchStationDataProvider";

type DispatchGroupDataProps = {
  children: JSX.Element[] | JSX.Element;
};

type DispatchGroupDataState = {
  jobGroups: DispatchGroup[];
  driverGroups: DispatchGroup[];
};

const initialState: DispatchGroupDataState = {
  jobGroups: [],
  driverGroups: []
};

const DispatchGroupDataContext = createContext<DispatchGroupDataState>(initialState);

const DispatchGroupDataProvider = ({children}: DispatchGroupDataProps) => {
  const [state, setState] = useState<DispatchGroupDataState>({
    ...initialState
  });
  const dispatchStationState = useContext(DispatchStationDataContext);

  const getDispatchGroupFilter = useCallback(() => {
    if (dispatchStationState.hasSelectedDispatchStations()) {
      const retVal = {} as ModelDispatchGroupFilterInput;
      retVal.or = Array.from(dispatchStationState.selectedDispatchStations.values()).flatMap((ds) => {
        const retVal = [] as ModelDispatchGroupFilterInput[];
        ds.driverGroups?.forEach((dg) => {
          retVal.push({
            dispatchGroupId: {
              eq: dg
            }
          });
        });
        ds.jobGroups?.forEach((jg) => {
          retVal.push({
            dispatchGroupId: {
              eq: jg
            }
          });
        });
        return retVal;
      });
      return retVal;
    } else {
      return undefined;
    }
  }, [dispatchStationState]);

  const queryResult = useSearchDispatchGroupsQuery({
    variables: {
      filter: getDispatchGroupFilter(),
      sort: {
        field: SearchableDispatchGroupSortableFields.DispatchGroup
      },
      limit: Constants.DISPATCH_GROUP_LIMIT
    }
  });

  useEffect(() => {
    const jobGroups: DispatchGroup[] = [];
    const driverGroups: DispatchGroup[] = [];
    if (queryResult.error) {
      console.error("Error loading dispatch groups", queryResult.error);
    } else if (queryResult.loading) {
      console.debug("Loading dispatch groups");
    } else if (queryResult.data?.searchDispatchGroups?.items) {
      console.debug("Loaded dispatch groups");
      const data = queryResult.data.searchDispatchGroups.items as DispatchGroup[];
      data.forEach((d) => {
        if (d.dispatchType === "J") {
          jobGroups.push(d);
        } else if (d.dispatchType === "D") {
          driverGroups.push(d);
        }
      });
      setState({
        jobGroups: jobGroups,
        driverGroups: driverGroups
      });
    }
  }, [queryResult.data, queryResult.error, queryResult.loading]);

  useEffect(() => {
    queryResult.refetch({
      filter: getDispatchGroupFilter()
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchStationState.selectedDispatchStations, queryResult]);

  return <DispatchGroupDataContext.Provider value={state}>{children}</DispatchGroupDataContext.Provider>;
};

export default DispatchGroupDataProvider;
export {DispatchGroupDataContext};
export type {DispatchGroupDataState};
