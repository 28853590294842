import {forwardRef, useCallback, useImperativeHandle, useState} from "react";
import {Button, Dialog} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {Constants} from "../../../common/Constants";
interface TimeWindowErrorDialogRefs {
  handleClose: () => void;
  handleOpen: () => void;
}

interface TimeWindowErrorDialogProps {
  isIgnoreTimeWindow: boolean;
  handleIgnoreTimeWindow: () => void;
}

const widthOfTimeWindowErrorDialog = 528;
const distanceFromLeftTimeWindowErrorDialog = () => {
  const widthOfManifestDetail = (window.innerWidth * Constants.MANIFEST_DRAWER_RATIO_WIDTH) / 100;
  return (widthOfManifestDetail - widthOfTimeWindowErrorDialog) / 2;
};

const DialogContainer = styled(Dialog)`
  font-family: "Roboto", sans-serif;
  width: ${widthOfTimeWindowErrorDialog}px;
  height: fit-content;
  padding: 24px;
  background-color: #fcfcfc;
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  position: fixed;
  left: ${distanceFromLeftTimeWindowErrorDialog()}px;
`;
const DialogHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
`;
const Title = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
`;
const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const BodyBlock = styled.div`
  font-size: 16px;
  font-weight: 400;
`;
const DialogFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 10px;
`;
const CancelButton = styled.div`
  background-color: #f8f8f8;
  padding: 6px 14px;
  font-size: 16px;
  box-shadow: 0 1px 1px 0 #00000040;
  cursor: pointer;
  border-radius: 4px;
`;

const YesButton = styled.div`
  background: linear-gradient(180deg, #214a89 0%, #14305a 100%);
  color: white;
  padding: 6px 14px;
  font-size: 16px;
  box-shadow: 0 1px 1px 0 #00000040;
  cursor: pointer;
  border-radius: 4px;
`;

const HeaderText = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: #797979;
  margin: 0;
  text-transform: uppercase;
`;

const notification =
  "Our engine analyzed the starting time, starting location, dwell time, traffic, and the stops in this manifest. We did not find any route that would meet the time requirements of each stop.";
const callToAction = "Would you like to ignore the time windows of the stops, and generate the fastest possible route?";

const TimeWindowErrorDialog = forwardRef<TimeWindowErrorDialogRefs, TimeWindowErrorDialogProps>(
  ({isIgnoreTimeWindow, handleIgnoreTimeWindow}, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = useCallback(() => {
      setIsOpen(true);
    }, []);

    const handleClose = useCallback(() => {
      setIsOpen(false);
    }, []);

    const handleOptimizeWithoutTimeWindow = useCallback(() => {
      setIsOpen(false);
      handleIgnoreTimeWindow();
    }, [handleIgnoreTimeWindow]);

    useImperativeHandle(ref, () => ({
      handleClose,
      handleOpen
    }));

    const renderContent = useCallback(() => {
      if (isIgnoreTimeWindow) {
        return (
          <>
            <DialogHeader data-testid="dialog-header">
              <Title>
                <HeaderText data-testid="header-text">No Feasible Route Found</HeaderText>
              </Title>
              <Button minimal icon={"cross"} onClick={handleClose} />
            </DialogHeader>
            <DialogBody data-testid="dialog-body">
              <BodyBlock>{notification}</BodyBlock>
              <BodyBlock>{callToAction}</BodyBlock>
            </DialogBody>
            <DialogFooter data-testid="dialog-footer">
              <CancelButton data-testid="cancel-button" onClick={handleClose}>
                No, cancel
              </CancelButton>
              <YesButton onClick={handleOptimizeWithoutTimeWindow}>Yes, ignore time windows</YesButton>
            </DialogFooter>
          </>
        );
      }
      return (
        <>
          <DialogHeader data-testid="dialog-header">
            <Title>
              <WarningIcon />
              <HeaderText data-testid="header-text">No Feasible Route Found</HeaderText>
            </Title>
            <Button minimal icon={"cross"} onClick={handleClose} />
          </DialogHeader>
          <DialogBody data-testid="dialog-body">
            <BodyBlock>{notification}</BodyBlock>
          </DialogBody>
          <DialogFooter data-testid="dialog-footer">
            <CancelButton data-testid="cancel-button" onClick={handleClose}>
              Close
            </CancelButton>
          </DialogFooter>
        </>
      );
    }, [isIgnoreTimeWindow, handleClose, handleOptimizeWithoutTimeWindow]);

    return (
      <DialogContainer isOpen={isOpen} onClose={handleClose}>
        {renderContent()}
      </DialogContainer>
    );
  }
);

TimeWindowErrorDialog.displayName = "TimeWindowErrorDialog";

const WarningIcon = () => {
  return (
    <svg
      data-testid="warning-icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25694 4.09882C9.02154 2.73952 10.9786 2.73952 11.7432 4.09882L17.3235 14.0194C18.0735 15.3526 17.11 16.9999 15.5804 16.9999H4.41978C2.89013 16.9999 1.9267 15.3526 2.67663 14.0194L8.25694 4.09882ZM11 14C11 14.5523 10.5523 15 10 15C9.44772 15 9 14.5523 9 14C9 13.4477 9.44772 13 10 13C10.5523 13 11 13.4477 11 14ZM10 6C9.44772 6 9 6.44772 9 7V10C9 10.5523 9.44772 11 10 11C10.5523 11 11 10.5523 11 10V7C11 6.44772 10.5523 6 10 6Z"
        fill="#797979"
      />
    </svg>
  );
};

export {TimeWindowErrorDialog, WarningIcon};
export type {TimeWindowErrorDialogRefs};
