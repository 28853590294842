import {NestedJobStop} from "../../../generated/graphql";
import StopInfo, {getStopSequence, StopInfoMetadata} from "./StopInfo";
import styled from "@emotion/styled";
import React, {HTMLAttributes, useEffect, useRef} from "react";
import {Tooltip2} from "@blueprintjs/popover2";
import {PopoverPosition} from "@blueprintjs/core";
import MultiStopIcon from "./MultiStopIcon";
import {getAttributes} from "./StopMarker";
import {ManifestStopEnhanced} from "../../manifest/details/ManifestDetailsV2";
import {EntityVisibilityLevel} from "../MapVisibilityContext";
import {JobAssignmentViewState} from "../../../views/JobAssignmentViewReducer";
import {MappedStop} from "../AssignmentMapV2";

const PackagePanelStyling = styled.div<{showJobNumber: boolean}>`
  box-sizing: border-box;
  padding: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 610px;
  max-height: 600px;
  overflow-y: auto;
  gap: 12px;
`;

const PackagePanelMarker = ({
  stop,
  info,
  mapJobStop,
  alwaysShowJobNumber,
  assignmentViewState,
  handleAssignmentByClickingMarker,
  usePortal,
  newWindow
}: {
  stop: NestedJobStop | ManifestStopEnhanced;
  info: StopInfoMetadata;
  mapJobStop?: number[];
  alwaysShowJobNumber?: boolean;
  assignmentViewState?: JobAssignmentViewState;
  handleAssignmentByClickingMarker: (info: StopInfoMetadata) => void;
  usePortal?: boolean;
  newWindow?: Window;
}) => {
  const attributes = getAttributes(stop);
  const stopSequence = getStopSequence(stop);
  const isExpanded = info.visibilityLevel === EntityVisibilityLevel.ROUTE;
  return (
    <Tooltip2
      content={<StopInfo stop={stop} info={info} color={alwaysShowJobNumber ? "#000000" : "#FA545E"} />}
      openOnTargetFocus={false}
      position={PopoverPosition.TOP}
      popoverClassName={"marker-popover"}
      usePortal={usePortal}
      portalContainer={newWindow?.document.body}
    >
      <MultiStopIcon
        data-testid={`multi-stop-icon-${stop.jobStopId}`}
        position={"relative"}
        clasName={"MultiStopIcon"}
        info={info}
        jobStopId={stop.jobStopId}
        jobNumber={info.jobNumber}
        {...attributes}
        mapJobStop={mapJobStop}
        counter={stopSequence}
        showPin={true}
        showCounter={true}
        jobColor={
          (assignmentViewState?.assignmentTotalJobsNumbers || []).includes(info.jobNumber) ? "red" : info.routeColor
        }
        showJobNumber={(isExpanded && info.isFirstStop) || (isExpanded && alwaysShowJobNumber)}
        handleAssignmentByClickingMarker={handleAssignmentByClickingMarker}
      />
    </Tooltip2>
  );
};

const PackagePanel: React.FC<
  HTMLAttributes<HTMLDivElement> & {
    mappedStops: MappedStop[];
    mapJobStop?: number[];
    alwaysShowJobNumber?: boolean;
    assignmentViewState?: JobAssignmentViewState;
    handleAssignmentByClickingMarker: (info: StopInfoMetadata) => void;
    usePortal?: boolean;
    newWindow?: Window;
    setPackagePanelOpenState?: React.Dispatch<React.SetStateAction<boolean>>;
  }
> = (props) => {
  const PackageRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    //Fix the case when popover open on newWindow, click onClose popover don't work
    if (!props.newWindow) return;
    const handleClickOutside = (event: MouseEvent) => {
      if (props.setPackagePanelOpenState && PackageRef.current && !PackageRef.current.contains(event.target as Node)) {
        props.setPackagePanelOpenState(false);
      }
    };
    props.newWindow.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      props.newWindow?.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, [props]);
  return (
    <PackagePanelStyling
      data-testid="package-panel"
      ref={PackageRef}
      showJobNumber={Boolean(props.alwaysShowJobNumber)}
    >
      {props.mappedStops.map((stop) => (
        <PackagePanelMarker
          key={stop.jobStopId}
          stop={stop}
          info={stop.stopInfoMetaData}
          mapJobStop={props.mapJobStop}
          alwaysShowJobNumber={props.alwaysShowJobNumber}
          assignmentViewState={props.assignmentViewState}
          handleAssignmentByClickingMarker={props.handleAssignmentByClickingMarker}
          usePortal={props.usePortal}
          newWindow={props.newWindow}
        />
      ))}
    </PackagePanelStyling>
  );
};
export default PackagePanel;
