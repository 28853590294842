import styled from "@emotion/styled";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useContext} from "react";

import {Button, Menu, MenuDivider, MenuItem} from "@blueprintjs/core";
import {isTestingEnv} from "../../config";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Popover2} from "@blueprintjs/popover2";
import {AuthContext} from "../AuthProvider";
import {handleSimulateDriverRoute} from "../../services/GPSService";
import {Manifest} from "../../generated/graphql";
import {optimizeManifest} from "./optimization/OptimizationService";

interface MapAnchorProps {
  mapType: string;
  layers: string[];
  onChangeMapType(type: string): void;
  onTrafficClicked(): void;
  selectedManifest?: Manifest;
  optionState: MapAnchorOptionState;
  onStopDetailToggle(): void;
}

interface MapAnchorOptionState {
  stopDetail: boolean;
  showDrivers: boolean;
}

const MapAnchorPopover = styled(Popover2)`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1000;
`;

export enum MAP_LAYERS {
  ROADMAP = "roadmap",
  SATELLITE = "satellite",
  HYBRID = "hybrid",
  TERRAIN = "terrain"
}

const MapAnchor = ({
  mapType,
  layers,
  onChangeMapType,
  onTrafficClicked,
  selectedManifest,
  optionState,
  onStopDetailToggle
}: MapAnchorProps) => {
  const {token, tenantId, isSuperUser} = useContext(AuthContext);

  const renderDevMenuItems = () => {
    return (
      <span>
        <MenuDivider title="Dev Options" />
        {isTestingEnv() && (
          <MenuItem
            text="Simulate Driver Route"
            icon={"blank"}
            disabled={selectedManifest === undefined}
            onClick={() => handleSimulateDriverRoute(selectedManifest!, token!, tenantId!)}
          ></MenuItem>
        )}
        <MenuItem
          text="Optimize Route"
          icon={"blank"}
          disabled={selectedManifest === undefined}
          onClick={() => optimizeManifest(selectedManifest!)}
        ></MenuItem>
      </span>
    );
  };

  return (
    <MapAnchorPopover
      content={
        <Menu style={{marginRight: "10px"}}>
          <MenuDivider title="Map Type" />
          {Object.values(MAP_LAYERS).map((x) => {
            return (
              <MenuItem
                key={x}
                text={x.charAt(0).toUpperCase() + x.slice(1)}
                icon={mapType === x ? "tick" : "blank"}
                onClick={() => onChangeMapType(x)}
              ></MenuItem>
            );
          })}
          <MenuDivider title="Map Options" />
          <MenuItem
            data-testid={"traffic-layer-btn"}
            text="Traffic"
            icon={layers.includes("TrafficLayer") ? "tick" : "blank"}
            onClick={onTrafficClicked}
          ></MenuItem>
          {isSuperUser && renderDevMenuItems()}
          <MenuDivider title="Route Options" />
          <MenuItem
            data-testid={"show-stop-detail-btn"}
            text="Stop Detail"
            icon={optionState.stopDetail ? "tick" : "blank"}
            onClick={onStopDetailToggle}
          ></MenuItem>
        </Menu>
      }
      placement={"bottom"}
    >
      <Button data-testid={"map-anchor-btn"} icon={<FontAwesomeIcon icon={solid("bars")} size={"lg"} />}></Button>
    </MapAnchorPopover>
  );
};

export default MapAnchor;
