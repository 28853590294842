import {ManifestModalDialog} from "../ManifestModalDialog";
import ManifestFilterDatePicker, {StatusType} from "./ManifestFilterDatePicker";
import {ManifestFilterType} from "../ManifestFilterState";
import {DateRange} from "@blueprintjs/datetime";

type ManifestFilterDialogProps = {
  filterDateRange: DateRange | undefined;
  onFilterDateRangeChanged: (dateRange: DateRange | undefined) => void;
  onFilterSelect: (
    filterType: ManifestFilterType.NONE | ManifestFilterType.MANIFEST_AGE | ManifestFilterType.STOP_STATUS,
    filterValue: string | number | undefined
  ) => void;
  onClose: () => void;
  statusTypes: StatusType[];
  setStatusTypes: React.Dispatch<React.SetStateAction<StatusType[]>>;
  emptyActive: boolean;
  newActive: boolean;
  setEmptyActive: React.Dispatch<React.SetStateAction<boolean>>;
  setNewActive: React.Dispatch<React.SetStateAction<boolean>>;
  dateSelectionText: string;
  setDateSelectionText: React.Dispatch<React.SetStateAction<string>>;
};

const ManifestFilterDialog = ({
  filterDateRange,
  onFilterDateRangeChanged,
  onFilterSelect,
  onClose,
  statusTypes,
  setStatusTypes,
  emptyActive,
  newActive,
  setEmptyActive,
  setNewActive,
  dateSelectionText,
  setDateSelectionText
}: ManifestFilterDialogProps) => {
  return (
    <ManifestModalDialog id="filter-manifest" onClose={onClose}>
      <ManifestFilterDatePicker
        selectedDateRange={filterDateRange}
        onChangeDateRange={onFilterDateRangeChanged}
        onFilterSelect={onFilterSelect}
        statusTypes={statusTypes}
        setStatusTypes={setStatusTypes}
        emptyActive={emptyActive}
        setEmptyActive={setEmptyActive}
        newActive={newActive}
        setNewActive={setNewActive}
        dateSelectionText={dateSelectionText}
        setDateSelectionText={setDateSelectionText}
      />
    </ManifestModalDialog>
  );
};

export default ManifestFilterDialog;
