import styled from "@emotion/styled";
import {ICellRendererParams} from "@ag-grid-community/core";
import {parseJSON, format} from "date-fns";
import {DateFormats} from "../../common/Constants";

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const TextContainer = styled.div`
  line-height: normal;
`;

const TimeContainer = styled(TextContainer)`
  font-weight: lighter;
`;

const DateTimeCellRenderer = (props: ICellRendererParams) => {
  if (!props.value) return null;

  if (props.data.status === "PROVISIONED") {
    if (props.colDef?.field === "activated" || props.colDef?.field === "lastLogin") {
      return null;
    }
  }

  const localeDateTime = parseJSON(props.value);
  const dateData = format(localeDateTime, DateFormats.cellRendererDate);
  const timeData = format(localeDateTime, DateFormats.cellRendererTime);

  return (
    <Cell>
      <TextContainer>{dateData}</TextContainer>
      <TimeContainer>{timeData}</TimeContainer>
    </Cell>
  );
};

export default DateTimeCellRenderer;
