import {Button, Icon, Intent, Position, Text} from "@blueprintjs/core";
import {
  Driver,
  JobStopManifestSequence,
  Manifest,
  ManifestStop,
  RouteOptimizationActivity,
  RouteOptimizationRequest,
  RouteOptimizationRequestOptionType,
  RouteOptimizationResponse,
  RouteOptimizationResultItem,
  RouteOptimizationUnassignedDetail,
  SaveClearJobStop,
  SearchableDispatchGroupSortableFields,
  SearchableSortDirection,
  useCloseManifestMutation,
  useOptimizeRoutesLazyQuery,
  useReassignJobStopsMutation,
  useResequenceManifestStopsMutation,
  useSaveClearJobStopsMutation,
  useSearchDispatchGroupsQuery,
  useSearchDriverLocationQuery,
  useSearchDriversEnhancedQuery,
  useSetUserPrefMutation,
  useUnassignJobStopsFromManifestMutation,
  useUpdateManifestMutation
} from "../../../generated/graphql";
import React, {Dispatch, ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {JobAssignmentActions, JobAssignmentAsyncActions, JobAssignmentState} from "../../common/JobAssignmentReducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid, thin} from "@fortawesome/fontawesome-svg-core/import.macro";
import VehicleIconResolver from "../../vehicles/VehicleIconResolver";
import {AgGridReact} from "@ag-grid-community/react";
import {
  ColDef,
  ColumnApi,
  ColumnMovedEvent,
  ColumnVisibleEvent,
  CsvCell,
  CsvExportParams,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  ICellRendererParams,
  IRowDragItem,
  ModuleRegistry,
  RowDragCallbackParams,
  RowDragLeaveEvent,
  RowDragMoveEvent,
  RowSelectedEvent,
  SideBarDef,
  SortChangedEvent
} from "@ag-grid-community/core";
import DoubleFieldCellRenderer, {DoubleFieldCellValue} from "../../common/cell-renderers/DoubleFieldCellRenderer";
import {
  ManifestJobColorPalette,
  ManifestJobNumberCellRenderer
} from "../../common/cell-renderers/ManifestJobNumberCellRenderer";
import * as _ from "lodash";
import {renderStopTypeIcon} from "../StopTypeIcons";
import {ServiceBadge} from "../../job/ServiceBadge";
import {
  GetStopTimeCellValueProps,
  StopTimeCellRenderer,
  getStopTimeCellValue
} from "../../common/cell-renderers/StopTimeCellRenderer";
import {useAppContext} from "../../../ApplicationContext";
import ManifestDetailsNoRowsOverlay from "./components/ManifestDetailsNoRowsOverlay";
import {JobPiecesOrWeightCellRenderer} from "../../common/cell-renderers/JobPiecesAndWeightCellRenderer";
import {ManifestStopDetailsCellRenderer} from "../../common/cell-renderers/ManifestStopDetailsCellRenderer";
import "./ManifestDetails.css";
import {hasOpenStops} from "../../../utils/ManifestUtils";
import {AppToaster, AppToasterTopLeft} from "../../../utils/toaster";
import {Popover2, Tooltip2} from "@blueprintjs/popover2";
import {ManifestDetailsContextMenu} from "./components/ManifestDetailsContextMenu";
import {ConfirmationDialog} from "../../common/ConfirmationDialog";
import {
  Constants,
  DateFormats,
  EmptyValueStrings,
  INVALID_FILE_NAME_REGEX,
  OptimizedResultCords,
  START_OR_END_LOCATIONS_OPTIMIZATION_SERVICEID,
  TenantPreferences,
  UserPreferences
} from "../../common/Constants";
import {
  getStopsByNewSequences,
  isActiveOrQueued,
  isCompleted,
  JobStopStatus,
  JobStopType,
  jobStopTypeToString
} from "../../../services/ManifestStopService";
import StopStatusRenderer from "./StopStatusRenderer";
import OptimizeDialog, {OptimizeFormData} from "./components/OptimizeDialog";
import {
  addressSort,
  filterInvalidAddresses,
  filterInvalidStartEndLocation,
  getArrayFromField,
  groupDetailsByCode,
  InvalidAddresses
} from "../../../utils/General";
import ErrorDialog, {ErrorDialogRefs} from "./components/ErrorDialog";
import {OptimizeSpiner, OptimizeSpinerRefs, SIZE_OF_OPTIMIZED_SPINNER} from "./components/OptimizeSpiner";
import {DispatchRulesDataContext, DispatchRulesDataState} from "../../common/DispatchRulesDataProvider";
import {IndicatorColors} from "../../settings/ColorizedIndicators/ColorizedIndicators";
import ManifestDetailsMap from "../../map/manifest-details/ManifestDetailsMap";
import {useMapVisibilityContext} from "../../map/MapVisibilityContext";
import {FeatureFlagContext} from "../../../providers/FeatureFlagProvider";
import {ConditionNames} from "../../settings/ColorizedIndicators/RuleGenerator.service";
import {
  createJsonPref,
  extractJsonPref,
  extractSimplePref,
  PreferenceContext
} from "../../../providers/PreferenceProvider";
import {JobAssignmentViewState, JobViewActions} from "../../../views/JobAssignmentViewReducer";
import {useListActionBarDispatch, useListActionBarState} from "../../common/ListActionBarProvider";
import {InforIcon} from "../../job/AssignmentBarV2";
import {useAuthState} from "../../../components/AuthProvider";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {CsvExportModule} from "@ag-grid-community/csv-export";
import JobColHeaderRenderer from "./components/JobColHeaderRenderer";
import {DetailViews} from "./ManifestDetailsContainer";
import {ManifestDetailsManifestSelectorProps} from "./ManifestDetailsManifestSelector";
import {ManifestDetailsActions, ManifestDetailsState} from "./ManifestDetailsReducer";
import CompleteStopDialog from "./components/CompleteStopDialog";
import AdjustStopTime from "./components/AdjustStopTime";
import {getFormattedManifestDate} from "../types/ManifestSupport";
import {SwapIcon, TGroupOption} from "../create-manifest/CreateManifestModal";
import DispatchGroupsSeletor from "../DispatchGroupsSeletor";
import {Loader} from "@googlemaps/js-api-loader";
import {UserColor} from "../../settings/ColorizedIndicators/types/indicatorLogicType";
import {getColorPriority} from "../../job/JobPanel";
import {getHighestRankedColor} from "../ManifestCardCommonV3";
import {getManifestColors} from "../list/ManifestDriverListV2";
import DriverQualV3 from "../DriverQualV3";
import DragCellRenderer from "./components/DragCellRenderer";
import {ManifestStatus, ManifestStatusType} from "./components/ManifestStatus";
import {ContactCellRenderer, ContactValueGetter} from "../../common/cell-renderers/ContactCellRenderer";
import {CellRendererWithToolTip} from "../../common/cell-renderers/CellRendererWithToolTip";
import {TimeWindowErrorDialog, TimeWindowErrorDialogRefs} from "./components/TimeWindowErrorDialog";
import {excludeTimeWindowErrors, extractAddressFromMessage} from "./ManifestDetailsV2.service";
import StopCountIndicatorV3 from "../StopCountIndicatorV3";
import {
  checkNoSpaceBetweenSelectedRows,
  checkRowMoveAndDoFunction,
  getDraggingStopsList,
  getJobQueuedStatus,
  getUpdatedStops,
  handleScrollTop,
  jobStopStatusFilterValueGetter,
  jobStopStatusValueFormatter
} from "./ManifestDetails.service";
import {
  ButtonsHeaderContainer,
  ClickHereButton,
  ColorIndicatorDiv,
  ManifestDriverDetailsContainer,
  ManifestDriverDetailsToolsContainer,
  ManifestDriverDetailsWrapper,
  ToolbarButtonsContainer,
  CancelButton,
  CancelButtonText,
  CancelReassignmentButton,
  CloseManifestToolTip,
  DispatchGroupButton,
  DriverCode,
  GridContainer,
  GridHeaderContainer,
  ManifestCardItemContainer,
  ManifestDateAndConfigButton,
  ManifestDateContainer,
  ManifestDateContainerChangesPending,
  ManifestToolbarActionButton,
  ManifestToolbarButton,
  PipeIcon,
  SaveChangesButton,
  SaveChangesContainer,
  StopsGrid,
  WindowButtonsContainer
} from "./ManifestDetail.styles";
import {clearAllSort} from "../../../services/AgGridService";
import {format} from "date-fns";
import {getStopTime} from "../../../utils/StopTime";
import decodePolyLine from "../../map/DecodePolyLine";
import DateFilter from "../../common/DateFilter";
import {ServiceTypeFilter} from "./components/ServiceFilter";
import {findNotCompletedStops} from "../../map/optimization/OptimizationService";

ModuleRegistry.registerModules([ClientSideRowModelModule, MenuModule, CsvExportModule]);

export type GraphHopperInvalidStopMess = {
  stopId: number;
  address: Promise<any> | string;
  message: string;
};
export type GraphHopperValidStopMess = {
  stopId: number;
  address: string;
} & RouteOptimizationUnassignedDetail;

export type GraphHopperInvalidLocationMess = {
  message: string;
  address: string;
  serviceId: string;
};

type ManifestDetailsProps = {
  manifestDriverId: number;
  manifestDetailsState: ManifestDetailsState;
  manifestDetailsDispatch: Dispatch<ManifestDetailsActions>;
  onClose(): void;
  jobAssignmentDispatch: Dispatch<JobAssignmentActions | JobAssignmentAsyncActions>;
  jobAssignmentState: JobAssignmentState;
  assignmentViewState: JobAssignmentViewState;
  assignmentViewStateDispatch: Dispatch<JobViewActions>;
  detailViewType: DetailViews;
  assignmentManifestList: ReactElement<ManifestDetailsManifestSelectorProps>;
};

type HandleUpdateStopCallProps = {
  manifest: Manifest;
  updateJobStopVariables: SaveClearJobStop[];
  jobStopId?: number;
  callType: UpdateStopCallType;
  finallyCallback: () => void;
};

enum UpdateStopCallType {
  COMPLETE = "Complete",
  INCOMPLETE = "Incomplete",
  ADJUSTTIME = "AdjustTime"
}

type UnassignJobState = {
  jobStopIds: number[];
  jobNumbers: string[];
  jobIds: number[];
};

export type ManifestDetailsContext = {
  selectedStops: ManifestStopEnhanced[];
  manifest: Manifest;
  handleUnassignJob({jobStopIds, jobNumbers}: UnassignJobState): void;
  handleMarkJobStopComplete(jobStopId: number): void;
  handleMarkJobStopIncomplete(jobStopId: number): void;
  handleReassignJob(singleSelectedJob?: ManifestStopEnhanced): void;
  handleAdjustTimeJob(stops: ManifestStop[]): void;
  jobsQueuedStatus: Map<number, boolean>;
  reassignJobEnabled: boolean;
  canUnassignPartialJob: boolean;
};

export type ManifestStopEnhanced = ManifestStop & {
  indicatorColor?: UserColor;
  originalSequence?: number;
  optimizedSequence?: number;
};

export type SaveMilesAndTime = {
  time: number;
  distance: number;
};

export type AdjustTimeState = Pick<SaveClearJobStop, "adjustScheduledMinutes" | "adjustLateMinutes">;

const StopTypeIconCellRenderer = (props: ICellRendererParams<any>) => {
  return <div className="stop-type-body-cell">{renderStopTypeIcon(props.data.stopType)}</div>;
};

const ServiceTypeCellRenderer = (props: ICellRendererParams<any>) => {
  return <ServiceBadge service={props.value} ellipsize />;
};

const ColorIndicatorCellRenderer = (props: ICellRendererParams<any, UserColor>) => {
  return (
    <ColorIndicatorDiv
      color={props.value && IndicatorColors[props.value] ? IndicatorColors[props.value].hex : ""}
    ></ColorIndicatorDiv>
  );
};

const getDragIconTooltipContent = ({
  isSorting,
  canResequenceByFilter,
  isSelectRowUncontinuously,
  params,
  clearAllSelection
}: {
  isSorting: boolean;
  canResequenceByFilter: boolean;
  isSelectRowUncontinuously: boolean;
  params: ICellRendererParams<ManifestStopEnhanced>;
  clearAllSelection: () => void;
}) => {
  const reasons = [];
  const actions = [];

  if (isSorting) {
    reasons.push("sorting");
    actions.push("sorting");
  }
  if (!canResequenceByFilter) {
    reasons.push("not filtering by both  Active and Queued status");
    actions.push("filtering");
  }
  if (!isSelectRowUncontinuously) {
    reasons.push("selecting stops that are not sequential");
    actions.push("selected stops");
  }

  const handleClearGridState = () => {
    if (!canResequenceByFilter) {
      params.api.setFilterModel(null);
    }
    clearAllSort(params);
    clearAllSelection();
  };

  return (
    <div style={{padding: "4px", maxWidth: "550px"}}>
      <span>Resequencing is disabled when {reasons.join(", ")}</span>
      <div>
        <ClickHereButton data-testid="clear-grid-state-button" onClick={handleClearGridState}>
          Click here
        </ClickHereButton>
        <span> to clear {actions.join(", ")}</span>
      </div>
    </div>
  );
};

const UnExportedColumnIds = ["indicatorColor", "dragColumn", "details"];

const loader = new Loader({
  apiKey: Constants.GOOGLE_API_KEY
});
loader.importLibrary("places").then();

const ManifestDetailsV2 = ({
  manifestDriverId,
  manifestDetailsState,
  manifestDetailsDispatch,
  onClose,
  jobAssignmentDispatch,
  jobAssignmentState,
  assignmentManifestList,
  assignmentViewStateDispatch
}: // detailViewType
ManifestDetailsProps) => {
  const {appState} = useAppContext();
  const authState = useAuthState();
  const {
    manifestOptimization: manifestOptimizationFeature,
    optimizeUsingShipments,
    dispatcherIgnoreTimeWindows,
    manifestDetailsConfiguration
  } = useContext(FeatureFlagContext);
  const gridRef = useRef<AgGridReact<ManifestStopEnhanced>>(null);
  const jobColoringIndex = useMemo<Map<string, number>>(() => {
    const coloringIndex = new Map<string, number>();
    const jobNumbers = _.uniq(manifestDetailsState.selectedManifest?.stops?.map((s) => s.job.jobNumber));
    if (jobNumbers.length <= ManifestJobColorPalette.length) {
      jobNumbers.forEach((value: string, index: number) => coloringIndex.set(value, index));
    }
    return coloringIndex;
  }, [manifestDetailsState.selectedManifest?.stops]);
  const [unassignJobState, setUnassignJobState] = useState<UnassignJobState | undefined>(undefined);
  const [markCompletedJobStopId, setMarkCompletedJobStopId] = useState<number | undefined>(undefined);
  const [markIncompleteJobStopId, setMarkIncompleteJobStopId] = useState<number | undefined>(undefined);
  const [adjustStops, setAdjustStops] = useState<ManifestStop[] | undefined>(undefined);
  const [workingStops, setWorkingStops] = useState<ManifestStopEnhanced[]>();
  const [isOptimized, setIsOptimized] = useState<boolean>(false);
  const [newOptimizeStops, setNewOptimizeStops] = useState<ManifestStopEnhanced[] | undefined>();
  const [isDraggedStops, setIsDraggedStops] = useState<boolean>(false);
  const [canResequenceByFilter, setCanResequenceByFilter] = useState<boolean>(true);
  const [isSorting, setIsSorting] = useState<boolean>(false);
  const [closeManifest] = useCloseManifestMutation();
  const [resequenceManifestStops] = useResequenceManifestStopsMutation();
  /* create a map of orders, key is stop.job.jobId, value is true if any stop for the order is queued */
  const jobsQueuedStatus = useMemo<Map<number, boolean>>(() => {
    return getJobQueuedStatus(manifestDetailsState.selectedManifest?.stops);
  }, [manifestDetailsState.selectedManifest?.stops]);
  const [unassignJob] = useUnassignJobStopsFromManifestMutation();
  const [updateJobStop] = useSaveClearJobStopsMutation();
  const {state: mapVisibilityState, dispatch: mapVisibilityDispatch} = useMapVisibilityContext();
  const [setUserPref] = useSetUserPrefMutation();
  const [initializing, setInitializing] = useState<boolean>(true);
  const {userPreferences, userPrefsQueryRefetch, tenantPreferences} = useContext(PreferenceContext);
  const [gridReady, setGridReady] = useState<boolean>(false);
  const [hasPendingSequenceUpdates, setHasPendingSequenceUpdates] = useState<boolean>(false);
  const [hasPendingSeqUpdatesAlertVisible, setHasPendingSeqUpdatesAlertVisible] = useState<boolean>(false);
  const [dialogOptimizeRouteOpen, setDialogOptimizeRouteOpen] = useState(false);
  const [optimizeFormData, setOptimizeFormData] = useState<OptimizeFormData>();
  const [optimizeRoutesLazyQuery] = useOptimizeRoutesLazyQuery({});
  const [typeOptimizeErrors, setTypeOptimizeErrors] = useState<"" | "googleGeo" | "graphhopper" | "genetic">("");
  const [invalidAddresses, setInvalidAddresses] = useState<InvalidAddresses[]>([]);
  const [graphHopperInvalidStopMessages, setGraphHopperInvalidStopMessages] = useState<GraphHopperInvalidStopMess[]>(
    []
  );
  const [graphHopperValidStopMessages, setGraphHopperValidStopMessages] = useState<GraphHopperValidStopMess[][]>([]);
  const [graphHopperGeneticMess, setGraphhopperGeneticMessages] = useState<string>("");
  const [graphHopperInvalidLocationMess, setGraphHopperInvalidLocationMess] = useState<
    GraphHopperInvalidLocationMess[]
  >([]);
  const optimizeSpinerRef = useRef<OptimizeSpinerRefs>(null);
  const errorDialogRef = useRef<ErrorDialogRefs>(null);
  const timeWindowErrorDialogRef = useRef<TimeWindowErrorDialogRefs>(null);
  const ruleContextState = useContext<DispatchRulesDataState>(DispatchRulesDataContext);

  const [highlightQualifiedDrivers, setHighlightQualifiedDrivers] = useState<boolean>(false);
  const listActionBarDispatch = useListActionBarDispatch();
  const listActionBarState = useListActionBarState();
  const {
    reassignJob: reassignJobEnabled,
    markStopComplete: markStopCompleteEnabled,
    extraManifestFilters
  } = useContext(FeatureFlagContext);
  const [openReassignJobConfirmation, setOpenReassignJobConfirmation] = useState<boolean>(false);
  const [reassignJobStops] = useReassignJobStopsMutation();
  const [isDispatcherIgnoreTimeWindows, setDispatcherIgnoreTimeWindows] = useState<boolean>(false);
  const [isOutsideAgGrid, setIsOutsideAgGrid] = useState(false);
  const [rowDataWhenStartedDragging, setRowDataWhenStartedDragging] = useState<ManifestStopEnhanced[]>();
  const timezoneOfJobStop = extractSimplePref(tenantPreferences, TenantPreferences.timezoneOfJobStop, false).value;
  const [isOpenDispatchGroupSelector, setIsOpenDispatchGroupSelector] = useState<boolean>(false);
  const [dispatchGroupOptions, setDispatchGroupOptions] = useState<TGroupOption[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<TGroupOption | undefined>(undefined);
  const [driverInfo, setDriverInfo] = useState<Driver | undefined>(undefined);
  const [openNormalUnassignDialog, setOpenNormalUnassignDialog] = useState<boolean>(false);
  const [openPartialUnassignDialog, setOpenPartialUnassignDialog] = useState<boolean>(false);
  const [isOpenExportConfirmDialog, setIsOpenExportConfirmDialog] = useState<boolean>(false);
  const [saveDistanceAndTime, setSaveDistanceAndTime] = useState<SaveMilesAndTime | null>(null);
  const [optimizedResultCords, setOptimizedResultCords] = useState<Map<
    OptimizedResultCords,
    Array<{lat: number; lng: number}>
  > | null>(null);
  const [isOptimizeFromHomeLocation, setIsOptimizeFromHomeLocation] = useState<boolean>(false);
  const [isFirstDataRendered, setIsFirstDataRendered] = useState<boolean>(false);
  const manifestStatusType = useMemo(() => {
    return manifestDetailsState.selectedManifest?.manifestStatus ?? ManifestStatusType.UNKNOWN;
  }, [manifestDetailsState.selectedManifest?.manifestStatus]);

  const canUnassignPartialJob = useMemo(() => {
    return extractJsonPref(tenantPreferences, TenantPreferences.unassignPartialJob, false).value.value;
  }, [tenantPreferences]);
  const [highestRankIndicatorColor, setHighestRankIndicatorColor] = useState("");
  const driverLocationResult = useSearchDriverLocationQuery({
    pollInterval: 15000,
    variables: {
      filter: {
        driverIds: [manifestDetailsState.selectedManifestDriver?.driverId ?? 0]
      },
      limit: Constants.DRIVER_LOCATION_LIMIT
    }
  });

  useSearchDriversEnhancedQuery({
    variables: {
      filter: {
        driver: {
          driverId: {eq: manifestDetailsState.selectedManifest?.driver.driverId}
        }
      }
    },
    onCompleted(data) {
      if (data.searchDriversEnhanced?.items?.at(0)) setDriverInfo(data.searchDriversEnhanced?.items?.at(0) as Driver);
    }
  });

  const [updateManifestMutation] = useUpdateManifestMutation();

  useSearchDispatchGroupsQuery({
    variables: {
      filter: {
        dispatchType: {
          eq: "D"
        }
      },
      sort: {
        field: SearchableDispatchGroupSortableFields.DispatchGroup,
        direction: SearchableSortDirection.Asc
      },
      limit: Constants.DISPATCH_GROUP_LIMIT
    },
    onCompleted(data) {
      if (data.searchDispatchGroups?.items) {
        const groupOptions: TGroupOption[] = data.searchDispatchGroups.items.map((item) => {
          return {key: item?.dispatchGroupId as number, value: item?.dispatchGroup ?? EmptyValueStrings.unknown};
        });
        setDispatchGroupOptions(groupOptions);
      }
    }
  });

  const renderUnassignJobIdsText = useMemo(() => {
    return unassignJobState && unassignJobState.jobNumbers.length > 1
      ? `${[...new Set(unassignJobState.jobNumbers)].join(", ")}`
      : unassignJobState?.jobNumbers.at(0) ?? "";
  }, [unassignJobState]);

  const renderReassignJobText = useMemo(() => {
    return jobAssignmentState.selectedStops.length > 1
      ? `${[...new Set(jobAssignmentState.selectedStops.map((s) => s.job.jobNumber))].join(", ")}`
      : jobAssignmentState.selectedStops[0]?.job?.jobNumber ?? "";
  }, [jobAssignmentState.selectedStops]);

  const selectedJobStopIds = useMemo(() => {
    return jobAssignmentState.selectedStops.map((x) => x.jobStopId);
  }, [jobAssignmentState.selectedStops]);

  const isSelectRowUncontinuously = useMemo(() => {
    const selectedNodes = gridRef.current?.api.getSelectedNodes() ?? [];
    const sortedRowIndex = selectedNodes
      .map((node) => node.rowIndex)
      .filter((index): index is number => index !== null)
      .sort((a, b) => a - b);
    return checkNoSpaceBetweenSelectedRows(sortedRowIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobAssignmentState.selectedStops]);

  const isAllowResequence = useMemo(
    () => !isSorting && canResequenceByFilter && isSelectRowUncontinuously,
    [canResequenceByFilter, isSelectRowUncontinuously, isSorting]
  );

  const clearAllSelection = useCallback(() => {
    gridRef.current?.api.deselectAll();
    jobAssignmentDispatch({type: "ClearSelections"});
    listActionBarDispatch({type: "SET_IS_SHOWING", payload: false});
  }, [jobAssignmentDispatch, listActionBarDispatch]);

  const columnTypes: {[key: string]: ColDef<ManifestStop>} | undefined = useMemo(() => {
    if (!extraManifestFilters) return undefined;
    return {
      filterColumn: {
        suppressMenu: false,
        menuTabs: ["filterMenuTab"],
        filterParams: {
          buttons: ["apply", "reset"],
          closeOnApply: true
        }
      },
      textFilter: {
        filter: "agTextColumnFilter"
      },
      numberFilter: {
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: [
            "equals",
            "notEqual",
            "lessThan",
            "lessThanOrEqual",
            "greaterThan",
            "greaterThanOrEqual",
            "inRange"
          ]
        }
      },
      setFilter: {
        filter: "agSetColumnFilter"
      }
    };
  }, [extraManifestFilters]);
  const colDefs: ColDef[] = useMemo(
    () =>
      [
        {
          field: "indicatorColor",
          headerName: "",
          pinned: "left",
          lockPosition: "left",
          headerClass: "manifest-detail-header-cell color-header-cell",
          cellClass: "manifest-detail-body-cell color-body-cell",
          lockPinned: true,
          suppressMovable: true,
          resizable: false,
          rowDrag: false,
          width: 20,
          minWidth: 20,
          suppressColumnsToolPanel: true,
          cellRenderer: ColorIndicatorCellRenderer,
          comparator(valueA, valueB) {
            return getColorPriority(ruleContextState.rules, valueA) - getColorPriority(ruleContextState.rules, valueB);
          }
        },
        {
          colId: "dragColumn",
          headerName: "",
          pinned: "left",
          lockPosition: "left",
          headerClass: "manifest-detail-header-cell drag-header-cell",
          cellClass: "manifest-detail-body-cell drag-body-cell",
          lockPinned: true,
          suppressMovable: true,
          suppressColumnsToolPanel: true,
          sortable: false,
          resizable: false,
          width: 10,
          minWidth: 10,
          rowDrag: (params: RowDragCallbackParams<ManifestStopEnhanced>) =>
            isAllowResequence && !isCompleted(params.data!),
          rowDragText: (params: IRowDragItem) => params.rowNode?.data?.manifestSequence,
          cellRenderer: DragCellRenderer,
          cellRendererParams: (params: ICellRendererParams<ManifestStopEnhanced>) => ({
            isCompleted: isCompleted(params.data!),
            isAllowResequence,
            content: getDragIconTooltipContent({
              isSorting,
              canResequenceByFilter,
              isSelectRowUncontinuously,
              params,
              clearAllSelection
            })
          })
        },
        {
          field: "manifestSequence",
          headerName: "Sequence #",
          cellClass: "manifest-detail-body-cell sequence-body-cell",
          initialWidth: 60,
          lockPosition: "left",
          type: ["filterColumn", "numberFilter"]
        },
        {
          field: "jobStopStatus",
          headerName: "Status",
          cellRenderer: StopStatusRenderer,
          cellRendererParams: {jobsQueuedStatus},
          cellStyle: {"--ag-cell-horizontal-padding": "4px", display: "flex", "justify-content": "center"},
          type: ["filterColumn", "setFilter"],
          filterParams: {
            values: Object.values(JobStopStatus),
            valueFormatter: jobStopStatusValueFormatter
          },
          filterValueGetter: jobStopStatusFilterValueGetter,
          valueFormatter: jobStopStatusValueFormatter,
          initialWidth: 70,
          lockPosition: "left",
          ...(!extraManifestFilters && {
            filter: "agSetColumnFilter",
            floatingFilterComponentParams: {
              "data-testid": "stop-status-filter"
            },
            floatingFilter: true
          })
        },
        {
          field: "job.jobNumber",
          headerName: "Job #",
          headerComponent: JobColHeaderRenderer,
          headerComponentParams: {
            jobColoringIndex: jobColoringIndex,
            stopLength: getArrayFromField(manifestDetailsState.selectedManifest?.stops).length,
            extraManifestFiltersEnable: extraManifestFilters
          },
          initialWidth: 100,
          cellRenderer: ManifestJobNumberCellRenderer,
          cellRendererParams: {
            jobColoringIndex: jobColoringIndex
          },
          type: ["filterColumn", "textFilter"],
          filterValueGetter: (params) => {
            return params.data.job.jobNumber;
          }
        },
        {
          field: "stopType",
          headerName: "Type",
          initialWidth: 70,
          cellRenderer: StopTypeIconCellRenderer,
          type: ["filterColumn", "setFilter"],
          filterParams: {
            values: Object.values(JobStopType),
            valueFormatter: jobStopTypeToString
          },
          filterValueGetter: (params) => params.data.stopType
        },
        {
          field: "stopTime",
          headerName: "Time",
          initialWidth: 120,
          cellRenderer: StopTimeCellRenderer,
          valueGetter: (params) => {
            return getStopTimeCellValue({
              data: params.data,
              is24HourFormat: appState.isTimeFormat24hr,
              timezoneOfJobStop
            } as GetStopTimeCellValueProps);
          },
          comparator(_valueA, _valueB, nodeA, nodeB) {
            const a = getStopTime(nodeA.data)?.getTime() ?? 0;
            const b = getStopTime(nodeB.data)?.getTime() ?? 0;
            return a - b;
          },
          type: "filterColumn",
          filter: DateFilter,
          filterParams: {
            type: "range",
            isClientSideRangeFilter: true
          }
        },
        {
          field: "address",
          headerName: "Address",
          cellRenderer: DoubleFieldCellRenderer,
          valueGetter: (params) => {
            return {
              upperField: {
                value: params.data.address,
                tooltip: params.data.address
              },
              lowerField: {
                value: `${params.data.city}, ${params.data.state}, ${params.data.zip}`,
                tooltip: `${params.data.city}, ${params.data.state}, ${params.data.zip}`,
                style: {
                  color: "#6B7280"
                }
              }
            } as DoubleFieldCellValue;
          },
          initialWidth: 109,
          comparator(valueA, valueB) {
            const addressA = valueA.upperField.value + valueA.lowerField.value;
            const addressB = valueB.upperField.value + valueB.lowerField.value;
            return addressSort(addressA, addressB);
          },

          type: ["filterColumn", "textFilter"],
          filterValueGetter: (params) => {
            return `${params.data.address} ${params.data.city} ${params.data.state} ${params.data.zip}`;
          }
        },
        {
          field: "dispatchZone",
          headerName: "Zone",
          cellClass: "manifest-detail-body-cell zone-body-cell",
          initialWidth: 100,
          type: ["filterColumn", "textFilter"]
        },
        {
          field: "order.service",
          headerName: "SVT",
          initialWidth: 100,
          cellStyle: {"--ag-cell-horizontal-padding": "4px"},
          cellRenderer: ServiceTypeCellRenderer,
          type: ["filterColumn"],
          filter: ServiceTypeFilter
        },
        {
          field: "job.pieces",
          headerName: "Pieces",
          initialWidth: 100,
          sortable: true,
          cellRenderer: JobPiecesOrWeightCellRenderer,
          type: ["filterColumn", "numberFilter"]
        },
        {
          field: "job.weight",
          headerName: "Weight",
          initialWidth: 100,
          sortable: true,
          cellRenderer: JobPiecesOrWeightCellRenderer,
          type: ["filterColumn", "numberFilter"]
        },
        {
          field: "name",
          headerName: "Stop Name",
          sortable: true,
          initialWidth: 140,
          cellRenderer: CellRendererWithToolTip,
          type: ["filterColumn", "textFilter"]
        },
        {
          field: "order.customer.name",
          headerName: "Customer Name",
          sortable: true,
          initialWidth: 150,
          tooltipField: "order.customer.name",
          cellRenderer: CellRendererWithToolTip,
          type: ["filterColumn", "textFilter"]
        },
        {
          colId: "details",
          headerName: "Details",
          sortable: false,
          initialWidth: 90,
          cellRenderer: ManifestStopDetailsCellRenderer
        },
        {
          field: "see",
          headerName: "Contact",
          sortable: false,
          initialWidth: 90,
          cellRenderer: ContactCellRenderer,
          valueGetter: ContactValueGetter,
          initialHide: true
        },
        {
          field: "order.notes",
          headerName: "Order Notes",
          sortable: false,
          initialWidth: 90,
          cellRenderer: CellRendererWithToolTip,
          initialHide: true
        },
        {
          field: "note",
          headerName: "Stop Notes",
          sortable: false,
          initialWidth: 90,
          cellRenderer: CellRendererWithToolTip,
          initialHide: true
        },
        {
          field: "order.customer.notes",
          headerName: "Customer Notes",
          sortable: false,
          initialWidth: 90,
          cellRenderer: CellRendererWithToolTip,
          initialHide: true
        }
      ] as ColDef[],
    [
      appState.isTimeFormat24hr,
      canResequenceByFilter,
      clearAllSelection,
      isAllowResequence,
      isSelectRowUncontinuously,
      isSorting,
      jobColoringIndex,
      jobsQueuedStatus,
      manifestDetailsState.selectedManifest?.stops,
      ruleContextState.rules,
      timezoneOfJobStop,
      extraManifestFilters
    ]
  );

  useEffect(() => {
    if (tenantPreferences) {
      if (dispatcherIgnoreTimeWindows) {
        setDispatcherIgnoreTimeWindows(
          extractSimplePref(tenantPreferences, TenantPreferences.dispatcherIgnoreTimeWindows, false).value ?? false
        );
      } else {
        setDispatcherIgnoreTimeWindows(false);
      }
    }
  }, [dispatcherIgnoreTimeWindows, tenantPreferences]);

  useEffect(() => {
    if (gridReady && userPreferences.length > 0 && initializing && isFirstDataRendered) {
      const columnState = extractJsonPref(userPreferences, UserPreferences.manifestDetailGridColumnState);
      if (columnState.value) {
        ["indicatorColor", "dragColumn"].forEach((colId) => {
          const existColIndex = columnState.value?.findIndex((item: any) => item.colId === colId);
          if (existColIndex > -1) {
            columnState.value[existColIndex].width = colDefs.find((col) => col.colId === colId)?.width;
          }
        });
        gridRef.current!.columnApi.applyColumnState({state: columnState.value, applyOrder: true});
      }
      const filterState = extractJsonPref(userPreferences, UserPreferences.manifestDetailGridFilterState);
      if (Object.keys(filterState.value ?? {}).length !== 0) {
        gridRef.current!.api.setFilterModel(filterState.value);
      }
      setInitializing(false);
    }
  }, [gridReady, userPreferences, initializing, colDefs, isFirstDataRendered]);

  useEffect(() => {
    if (manifestDetailsState.selectedManifest && mapVisibilityState.manifests.size === 0) {
      mapVisibilityDispatch({
        type: "IncrementManifestVisibility",
        manifest: manifestDetailsState.selectedManifest
      });
    }
  }, [manifestDetailsState.selectedManifest, mapVisibilityDispatch, mapVisibilityState.manifests.size]);

  const onSelectGroupHandler = useCallback(
    (group: TGroupOption) => {
      if (selectedGroup?.key !== group.key) {
        setSelectedGroup(group);
        updateManifestMutation({
          variables: {
            manifestDriverId: manifestDetailsState.selectedManifest?.manifestDriverId as number,
            dispatchGroupId: Number(group.key)
          },
          onCompleted() {
            AppToasterTopLeft.show({
              intent: Intent.SUCCESS,
              icon: "tick",
              message:
                "Manifest's driver group updated request has been submitted. Updates will be reflected in the UI after the request has been processed."
            });
          }
        });
      }
      setIsOpenDispatchGroupSelector(false);
    },
    [manifestDetailsState.selectedManifest?.manifestDriverId, selectedGroup?.key, updateManifestMutation]
  );

  const getColorIndicator = useCallback(
    async (stop: ManifestStopEnhanced) => {
      if (
        !ruleContextState.loading &&
        ruleContextState.rules &&
        ruleContextState.rules.length > 0 &&
        manifestDetailsState.selectedManifest &&
        stop
      ) {
        const engine = ruleContextState.getConfiguredEngine(
          {manifest: manifestDetailsState.selectedManifest, stop: stop},
          ruleContextState.rules.filter((rule) => rule.conditions.name !== ConditionNames.MANIFESTONLY)
        );
        if (engine === undefined) {
          console.warn("Engine not configured");
          return;
        }

        const result = await engine
          .run()
          .then(({events}) => {
            if (events.length > 0) {
              return events[0].params?.color;
            }
          })
          .catch((err) => {
            console.warn("Error getting color indicator", err);
          });
        stop.indicatorColor = result;
      }
    },
    [ruleContextState, manifestDetailsState.selectedManifest]
  );

  const buildIndicatorColors = useCallback(
    async (newRows: ManifestStopEnhanced[]) => {
      const promises = newRows.map(async (incomingRow) => {
        await getColorIndicator(incomingRow);
      });

      const results = await Promise.allSettled(promises);

      results.forEach((result) => {
        if (result.status === "rejected") {
          console.warn("Error getting color indicator", result.reason);
        }
      });
    },
    [getColorIndicator]
  );

  const handleCloseDetails = () => {
    if (hasPendingSequenceUpdates) {
      setHasPendingSeqUpdatesAlertVisible(true);
    } else {
      onClose();
    }
  };
  const handleCheckoutDriver = () => {
    closeManifest({
      variables: {
        manifestDriverId: manifestDetailsState.selectedManifest
          ? manifestDetailsState.selectedManifest.manifestDriverId
          : 0
      }
    })
      .then((r) => {
        if (r.errors) {
          console.error(r.errors);
          AppToasterTopLeft.show({
            intent: Intent.DANGER,
            icon: "asterisk",
            message: `Unable to close ${manifestDetailsState.selectedManifest?.driver.name}'s Manifest`
          });
        } else {
          AppToasterTopLeft.show({
            intent: Intent.SUCCESS,
            icon: "tick",
            message: `Closed ${manifestDetailsState.selectedManifest?.driver.name}'s Manifest`
          });
          onClose();
        }
      })
      .catch((reason) => {
        console.error(reason);
        AppToasterTopLeft.show({
          intent: Intent.DANGER,
          icon: "asterisk",
          message: `Unable to close ${manifestDetailsState.selectedManifest?.driver.name}'s Manifest`
        });
      });
  };

  const handleSaveResequnceManifest = () => {
    const newSequence: JobStopManifestSequence[] = [];
    gridRef.current!.api.forEachNode((row, index) => {
      newSequence.push({
        jobStopId: row.data!.jobStopId,
        manifestSequence: index + 1
      });
    });
    resequenceManifestStops({
      variables: {
        manifestDriverId: manifestDetailsState.selectedManifest
          ? manifestDetailsState.selectedManifest.manifestDriverId
          : 0,
        jobStopManifestSequences: newSequence
      }
    })
      .then((r) => {
        if (r.errors) {
          console.error(r.errors);
          AppToasterTopLeft.show({
            intent: Intent.DANGER,
            icon: "asterisk",
            message: `Error submitting request to resequence ${manifestDetailsState.selectedManifest?.driver.name}'s Manifest`
          });
        } else {
          AppToasterTopLeft.show({
            intent: Intent.SUCCESS,
            icon: "tick",
            message:
              "Manifest resequencing request has been submitted. Updates will be reflected in the UI after the request has been processed."
          });
          setHasPendingSequenceUpdates(false);
          setIsOptimized(false);
          clearAllSelection();
        }
      })
      .catch((reason) => {
        console.error(reason);
        AppToasterTopLeft.show({
          intent: Intent.DANGER,
          icon: "asterisk",
          message: `Error submitting request to resequence ${manifestDetailsState.selectedManifest?.driver.name}'s Manifest`
        });
      });
  };
  const handleSaveAndClose = () => {
    setHasPendingSeqUpdatesAlertVisible(false);
    handleSaveResequnceManifest();
    onClose();
  };
  const handleConfirmClose = () => {
    setHasPendingSeqUpdatesAlertVisible(false);
    onClose();
  };

  const handleOpenOptimizeRouteDialog = () => {
    setDialogOptimizeRouteOpen(true);
  };

  const handleRunOptimizeRouteDialog = () => {
    console.debug("handleRunOptimizeRouteDialog");
    setDialogOptimizeRouteOpen(false);
    handleOptimizeRoute();
  };

  const handleOptimizeDataChange = useCallback((data: OptimizeFormData) => {
    console.debug("handleOptimizeDataChange", data);
    setOptimizeFormData(data);
  }, []);

  const handleCloseOptimizeRouteDialog = () => {
    setDialogOptimizeRouteOpen(false);
  };

  const handleValidOptimization = (routeOptimizationItem: RouteOptimizationResultItem) => {
    const solution: RouteOptimizationResponse = routeOptimizationItem.solution as RouteOptimizationResponse;
    console.debug("Route optimization response", solution);
    if (solution.solution?.no_unassigned) {
      const unassignedDetailed = solution.solution.unassigned?.details;
      const filterNonTimeWindowErrors = excludeTimeWindowErrors(unassignedDetailed);
      if (filterNonTimeWindowErrors.length > 0) {
        const filterErrorByCode = groupDetailsByCode(filterNonTimeWindowErrors);
        const arrayErrorStops: GraphHopperValidStopMess[][] = [];
        filterErrorByCode.forEach((item) => {
          const errorStops = item.map((x) => {
            const stopByID = manifestDetailsState.selectedManifest?.stops?.find((stop) => {
              if (optimizeUsingShipments) {
                return stop.jobStopId.toString() === x.id?.split(":")[1];
              } else {
                return stop.jobStopId.toString() === x.id;
              }
            });
            const newErrWithAddress = {
              stopId: stopByID?.manifestSequence,
              address: `${stopByID?.address}, ${stopByID?.city}, ${stopByID?.state} ${stopByID?.zip}`
            };
            return {
              ...x,
              ...newErrWithAddress
            };
          });
          arrayErrorStops.push(errorStops as GraphHopperValidStopMess[]);
        });

        setGraphHopperValidStopMessages(arrayErrorStops);
        setTypeOptimizeErrors("graphhopper");
        errorDialogRef.current?.handleOpen();
        optimizeSpinerRef.current?.handleCloseSpinner();
      } else {
        timeWindowErrorDialogRef.current?.handleOpen();
        optimizeSpinerRef.current?.handleCloseSpinner();
      }
    } else {
      gridRef.current!.columnApi.applyColumnState({
        defaultState: {sort: null}
      });
      const updatedStops: Map<number, ManifestStopEnhanced> = new Map<number, ManifestStopEnhanced>();
      workingStops?.forEach((stop) => {
        const newStop: ManifestStopEnhanced = {...stop};
        updatedStops.set(newStop.jobStopId, newStop);
      });

      let introducesPendingUpdates = hasPendingSequenceUpdates;
      let newSequence = 1;

      let activities: any;
      if (optimizeUsingShipments) {
        const distinctActivities = new Map();

        solution.solution?.routes?.[0].activities?.forEach((act) => {
          if (act.type !== "start") {
            if (!act.address?.name?.startsWith("Phantom_")) {
              distinctActivities.set(`${act.id}_${act.type}`, act);
            }
          }
        });
        activities = distinctActivities;
      } else {
        activities = solution?.solution?.routes?.[0]?.activities;
      }

      activities?.forEach((act: RouteOptimizationActivity) => {
        let stop;
        if (optimizeUsingShipments) {
          if (act.type === "pickupShipment") {
            stop = updatedStops.get(parseInt(act.id?.split(":")[1] ?? "0"));
          } else if (act.type === "deliverShipment") {
            stop = updatedStops.get(parseInt(act.id?.split(":")[2] ?? "0"));
          } else {
            // this will be a start and end so do nothing
          }
        } else {
          stop = updatedStops.get(parseInt(act.id ?? "0"));
        }

        if (stop) {
          console.debug("Found matching stop", stop.address, stop.stopType, stop.jobStopId);
          stop.optimizedSequence = newSequence++;
          introducesPendingUpdates = introducesPendingUpdates || stop.originalSequence != stop.optimizedSequence;
          if (stop.originalSequence != stop.optimizedSequence) console.info("Changed sequence number");
        } else {
          console.info("could not match activity to stop ", act);
        }
      });

      if (routeOptimizationItem.routeDifference) {
        setSaveDistanceAndTime({
          time: routeOptimizationItem.routeDifference?.timeDifference ?? 0,
          distance: routeOptimizationItem.routeDifference?.distanceDifference ?? 0
        });
      }

      setHasPendingSequenceUpdates(introducesPendingUpdates);
      const updatedStopsArray = getUpdatedStops(updatedStops);
      setWorkingStops(updatedStopsArray);
      setIsOptimized(introducesPendingUpdates);
      setNewOptimizeStops(updatedStopsArray);
      setIsDraggedStops(false);
      //Original cords does not have end location cords
      const originalCords = decodePolyLine(
        routeOptimizationItem?.originalRoute?.paths?.at(0)?.points?.encodedLine ?? ""
      );
      const optimizePoints = solution?.solution?.routes?.at(0)?.points;
      //Remove end locations points from optimized points
      const optimizedCords =
        optimizePoints
          ?.slice(0, optimizeFormData?.endLocation ? -1 : optimizePoints.length)
          .flatMap((item) => item.coordinates)
          .map((cords) => {
            return {lat: cords.at(1) ?? 0, lng: cords.at(0) ?? 0};
          }) ?? [];

      const optimizedResultCordsMap = new Map<OptimizedResultCords, Array<{lat: number; lng: number}>>();
      if (isOptimizeFromHomeLocation) {
        optimizedResultCordsMap.set(OptimizedResultCords.HomeCords, optimizedCords?.slice(0, 1));
      }

      optimizedResultCordsMap.set(OptimizedResultCords.OptimizedCords, optimizedCords);
      optimizedResultCordsMap.set(OptimizedResultCords.OriginalCords, originalCords);
      setOptimizedResultCords(optimizedResultCordsMap);

      AppToasterTopLeft.show({
        intent: Intent.SUCCESS,
        icon: "asterisk",
        message: `Optimization Success!   Credits: ${solution?.rateLimitCredits}, proc_time: ${solution?.processing_time}ms, wait_q_time: ${solution?.waiting_time_in_queue}ms, distance: ${solution?.solution?.distance}m, time: ${solution?.solution?.completion_time}s`
      });
      optimizeSpinerRef.current?.handleCloseSpinner();
    }
  };

  const handleInvalidOptimization = (routeOptimizationItem: RouteOptimizationResultItem) => {
    const haveServiceId = routeOptimizationItem.validationErrors?.hints?.every((item) => item.serviceId);
    const isStartEndLocationError = routeOptimizationItem.validationErrors?.hints?.some((item) =>
      START_OR_END_LOCATIONS_OPTIMIZATION_SERVICEID.includes(item.serviceId ?? "")
    );

    const message = routeOptimizationItem.validationErrors?.message;
    if (!haveServiceId) {
      setGraphhopperGeneticMessages(message ?? "");
      setTypeOptimizeErrors("genetic");
      errorDialogRef.current?.handleOpen();
      optimizeSpinerRef.current?.handleCloseSpinner();
    }

    const multiPickupMultiDeliverNotSupported = routeOptimizationItem.validationErrors?.hints?.some(
      (hint) => hint.details == "Manifests with multiple pickups and deliveries cannot be optimized at this time."
    );

    if (multiPickupMultiDeliverNotSupported) {
      setGraphhopperGeneticMessages(
        "Hello! Manifests containing a job that contains multiple pickups and multiple deliveries in that one job is not yet supported."
      );
      setTypeOptimizeErrors("genetic");
      errorDialogRef.current?.handleOpen();
      optimizeSpinerRef.current?.handleCloseSpinner();
    } else {
      if (isStartEndLocationError) {
        const locationRequestInvalidError = routeOptimizationItem.validationErrors?.hints
          ?.filter((item) => START_OR_END_LOCATIONS_OPTIMIZATION_SERVICEID.includes(item.serviceId ?? ""))
          .map((item) => {
            return {
              message: message ?? "",
              serviceId: item.serviceId ?? "",
              address: extractAddressFromMessage(item.message)
            };
          });
        setGraphHopperInvalidLocationMess(locationRequestInvalidError ?? []);
      } else {
        const errorValid = routeOptimizationItem.validationErrors?.hints?.map((item) => {
          const stopByID = manifestDetailsState.selectedManifest?.stops?.find(
            (h) => h.jobStopId.toString() === item.serviceId
          );
          return {
            address: `${stopByID?.address}, ${stopByID?.city}, ${stopByID?.state} ${stopByID?.zip}`,
            message: message,
            stopId: stopByID?.manifestSequence
          };
        });
        setGraphHopperInvalidStopMessages(errorValid as GraphHopperInvalidStopMess[]);
      }

      setTypeOptimizeErrors("graphhopper");
      errorDialogRef.current?.handleOpen();
      optimizeSpinerRef.current?.handleCloseSpinner();
    }
  };

  const handleOptimizeRoute = async (ignoreTimeWindowError?: boolean) => {
    setInvalidAddresses([]);
    setGraphHopperValidStopMessages([]);
    setGraphHopperInvalidStopMessages([]);
    setGraphhopperGeneticMessages("");
    setGraphHopperInvalidLocationMess([]);
    setSaveDistanceAndTime(null);
    setOptimizedResultCords(null);
    setIsDraggedStops(false);
    optimizeSpinerRef?.current?.handleOpenSpinner();
    const startingLocation = optimizeFormData?.startingLocation;
    const endLocation = optimizeFormData?.endLocation;
    try {
      if (!ignoreTimeWindowError) {
        const startEndLocationResult: InvalidAddresses[] =
          (await filterInvalidStartEndLocation(startingLocation!, endLocation)) ?? [];
        const addressesResult: InvalidAddresses[] =
          (await filterInvalidAddresses(findNotCompletedStops(manifestDetailsState.selectedManifest?.stops))) ?? [];
        if (
          (addressesResult && addressesResult.length > 0) ||
          (startEndLocationResult && startEndLocationResult.length > 0)
        ) {
          setTypeOptimizeErrors("googleGeo");
          setInvalidAddresses([...startEndLocationResult, ...addressesResult]);
          errorDialogRef?.current?.handleOpen();
          optimizeSpinerRef.current?.handleCloseSpinner();
          return;
        }
      }

      if (manifestDetailsState.selectedManifest) {
        const startingDateTime = Date.parse(optimizeFormData?.startingTime as string);
        const request: RouteOptimizationRequest = {
          manifestDriverId: manifestDetailsState.selectedManifest.manifestDriverId,
          dwellTime: optimizeFormData?.dwellTime as number,
          startingDateTime: isNaN(startingDateTime)
            ? ""
            : new Date(new Date(startingDateTime).setSeconds(0, 0)).toISOString(),
          startingLocation,
          endLocation,
          source: Constants.GRAPHHOPPER_SOURCE
        };
        const optimizationResponse = await optimizeRoutesLazyQuery({
          variables: {
            routeOptimizationRequests: [request],
            routeOptimizationRequestOptions: [
              {
                requestOption: RouteOptimizationRequestOptionType.IgnoreTimeWindows,
                enabled: ignoreTimeWindowError ?? false
              },
              {
                requestOption: RouteOptimizationRequestOptionType.IncludeOriginalRouteDetail,
                enabled: true
              },
              {
                requestOption: RouteOptimizationRequestOptionType.EncodeOriginalRoutePoints,
                enabled: true
              }
            ]
          }
        });
        const routeOptimizationItem: RouteOptimizationResultItem = optimizationResponse.data?.optimizeRoutes
          ?.items[0] as RouteOptimizationResultItem;

        if (routeOptimizationItem.valid) {
          handleValidOptimization(routeOptimizationItem);
        } else {
          handleInvalidOptimization(routeOptimizationItem);
        }
      }
    } catch (error) {
      console.error("Optimization Error: ", error);
      setTypeOptimizeErrors("genetic");
      if (errorDialogRef?.current) {
        errorDialogRef.current.handleOpen();
      }
    }
  };

  const handleOptimizeIgnoreTimeWindow = () => {
    handleOptimizeRoute(true);
  };

  const handleUnassignJobConfirm = () => {
    if (manifestDetailsState.selectedManifest && unassignJobState?.jobStopIds) {
      unassignJob({
        variables: {
          jobStopIds: unassignJobState?.jobStopIds
        }
      })
        .then((r) => {
          if (r.errors) {
            console.error(r.errors);
            AppToasterTopLeft.show({
              intent: Intent.DANGER,
              icon: "asterisk",
              message: `Unable to unassign ${renderUnassignJobIdsText} from ${manifestDetailsState.selectedManifest?.driver.name}`
            });
          } else {
            AppToasterTopLeft.show({
              intent: Intent.SUCCESS,
              icon: "tick",
              message: `Unassigned ${renderUnassignJobIdsText} from ${manifestDetailsState.selectedManifest?.driver.name}`
            });
          }
        })
        .catch((reason) => {
          console.error(reason);
          AppToasterTopLeft.show({
            intent: Intent.DANGER,
            icon: "asterisk",
            message: `Unable to unassign ${renderUnassignJobIdsText} from ${manifestDetailsState.selectedManifest?.driver.name}`
          });
        })
        .finally(() => {
          setUnassignJobState(undefined);
          setOpenPartialUnassignDialog(false);
          setOpenNormalUnassignDialog(false);
          clearAllSelection();
        });
    }
  };

  const handleMarkJobStopCompleteConfirm = () => {
    if (manifestDetailsState.selectedManifest) {
      const manifestStop = manifestDetailsState.selectedManifest.stops!.filter(
        (s) => s.jobStopId === markCompletedJobStopId
      )[0];
      handleUpdateJobStopCall({
        manifest: manifestDetailsState.selectedManifest,
        updateJobStopVariables: [
          {jobStopId: markCompletedJobStopId!, jobStopStatus: manifestStop.jobStopStatus, nextJobStopStatus: "Y"}
        ],
        jobStopId: markCompletedJobStopId!,
        callType: UpdateStopCallType.COMPLETE,
        finallyCallback: () => setMarkCompletedJobStopId(undefined)
      });
    }
  };

  const handleMarkJobStopIncompleteConfirm = () => {
    if (manifestDetailsState.selectedManifest) {
      handleUpdateJobStopCall({
        manifest: manifestDetailsState.selectedManifest,
        updateJobStopVariables: [{jobStopId: markIncompleteJobStopId!, jobStopStatus: "A", nextJobStopStatus: "N"}],
        jobStopId: markIncompleteJobStopId!,
        callType: UpdateStopCallType.INCOMPLETE,
        finallyCallback: () => setMarkIncompleteJobStopId(undefined)
      });
    }
  };

  const handleAdjustJobsStopTimesConfirm = (adjustTimeState: AdjustTimeState) => {
    if (manifestDetailsState.selectedManifest && adjustStops && adjustStops?.length > 0) {
      const variables: SaveClearJobStop[] = [];

      adjustStops.forEach((stop) =>
        variables.push({
          jobStopId: stop.jobStopId,
          ...adjustTimeState
        })
      );
      console.debug("Adjust time: ", variables);
      handleUpdateJobStopCall({
        manifest: manifestDetailsState.selectedManifest,
        updateJobStopVariables: variables,
        callType: UpdateStopCallType.ADJUSTTIME,
        finallyCallback: () => setAdjustStops(undefined)
      });
    }
  };

  const handleUpdateJobStopCall = ({
    manifest,
    updateJobStopVariables,
    jobStopId,
    callType,
    finallyCallback
  }: HandleUpdateStopCallProps) => {
    console.debug(`Updating ${callType} ${jobStopId}`);
    const successMessage =
      callType === UpdateStopCallType.ADJUSTTIME
        ? `The adjusted stop times for job #${
            adjustStops?.at(0)?.job.jobNumber
          } have been submitted. This adjustment will show on your screen once it’s updated in the platform for all users.`
        : `Mark ${callType} ${jobStopId} from ${manifest.driver.name}`;
    const failedMessage =
      callType === UpdateStopCallType.ADJUSTTIME
        ? `Unable to adjust Stop times for Job #${adjustStops?.at(0)?.job.jobNumber}`
        : `Unable to Mark ${callType} ${jobStopId} from ${manifest.driver.name}`;

    updateJobStop({
      variables: {
        saveClearJobStops: updateJobStopVariables
      }
    })
      .then((r: any) => {
        if (r.errors) {
          console.error(r.errors);
          AppToasterTopLeft.show({
            intent: Intent.DANGER,
            icon: "asterisk",
            message: failedMessage
          });
        } else {
          AppToasterTopLeft.show({
            intent: Intent.SUCCESS,
            icon: "tick",
            message: successMessage
          });
        }
      })
      .catch((reason: any) => {
        console.error(reason);
        AppToasterTopLeft.show({
          intent: Intent.DANGER,
          icon: "asterisk",
          message: failedMessage
        });
      })
      .finally(finallyCallback);
  };

  const defaultColDef: ColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
      suppressMenu: true,
      autoHeight: true,
      sortable: true,
      headerClass: "manifest-detail-header-cell",
      cellClass: "manifest-detail-body-cell",
      minWidth: 45
    };
  }, []);

  const manifestHasOpenStops = hasOpenStops(manifestDetailsState.selectedManifest?.stops);

  const exceedMaximumManifest = (manifestDetailsState.selectedManifest?.stopCount ?? 0) > 500;

  const checkForSequenceChanges = useCallback(() => {
    if (!gridRef.current?.api) {
      setHasPendingSequenceUpdates(false);
      return;
    }

    const newSequence: JobStopManifestSequence[] = [];
    gridRef.current.api.forEachNode((row, index) => {
      newSequence.push({
        jobStopId: row.data!.jobStopId,
        manifestSequence: index + 1
      });
    });
    if (newSequence.length > 0 && manifestDetailsState.selectedManifest) {
      const newStops = getStopsByNewSequences(newSequence, manifestDetailsState.selectedManifest);
      if (newStops !== undefined) {
        buildIndicatorColors(newStops).then(() => {
          setWorkingStops(newStops);
          setNewOptimizeStops(newStops);
        });
      }
    }
    let pending = false;
    gridRef.current.api.forEachNode((row, index) => {
      pending = pending || row.data!.originalSequence != index + 1;
    });

    setHasPendingSequenceUpdates(pending);
  }, [manifestDetailsState.selectedManifest, buildIndicatorColors]);

  const handleSelectRow = useCallback(
    (e: RowSelectedEvent<ManifestStopEnhanced>) => {
      if (["apiSelectAll", "api"].includes(e?.source)) return;
      const manifestStop = e.data;
      if (!manifestStop) return;
      const jobStopId = manifestStop.jobStopId;
      const reassignListActionBarOpen =
        listActionBarState.buttons.find((b) => b.id === "reassign") && listActionBarState.isShowing;
      if (reassignListActionBarOpen) {
        //Don't allow user select a stop with completed status
        const jobId = manifestStop.job.jobId;
        const jobNumber = manifestStop.job.jobNumber;
        const completedJobStops =
          manifestDetailsState.selectedManifest?.stops?.filter(
            (x) => x.job.jobId === jobId && x.jobStopStatus === "C"
          ) ?? [];
        if (completedJobStops.length > 0) {
          AppToaster.show({
            intent: Intent.WARNING,
            message: `The Job: ${jobNumber} has completed stop(s). Please select another stop.`,
            className: "toast",
            timeout: 3000
          });
          e.node.setSelected(false);
          return;
        }
      }
      let newSelectedStops = [];
      const existingStops = jobAssignmentState.selectedStops.find((js) => js.jobStopId === jobStopId);
      if (existingStops) {
        newSelectedStops = jobAssignmentState.selectedStops.filter((js) => js.jobStopId !== jobStopId);
      } else {
        newSelectedStops = [...jobAssignmentState.selectedStops, manifestStop];
      }

      jobAssignmentDispatch({type: "SetSelectedStops", payload: newSelectedStops});
    },
    [
      jobAssignmentDispatch,
      jobAssignmentState.selectedStops,
      listActionBarState.buttons,
      listActionBarState.isShowing,
      manifestDetailsState.selectedManifest?.stops
    ]
  );

  const resetManifestStops = useCallback(() => {
    if (manifestDetailsState.selectedManifest && ruleContextState.loading == false) {
      const enhancedStops: ManifestStopEnhanced[] =
        manifestDetailsState.selectedManifest?.stops?.map(
          (stop, i) => ({...stop, originalSequence: i + 1, optimizedSequence: undefined} as ManifestStopEnhanced)
        ) ?? [];

      buildIndicatorColors(enhancedStops).then(() => {
        setWorkingStops(enhancedStops);
        setNewOptimizeStops(undefined);
        setHasPendingSequenceUpdates(false);
        setIsOptimized(false);
        setIsDraggedStops(false);
      });
    }
  }, [manifestDetailsState.selectedManifest, ruleContextState.loading, buildIndicatorColors]);

  const formattedManifestDate = () => {
    return getFormattedManifestDate(
      manifestDetailsState.selectedManifest?.manifestDate as string,
      DateFormats.longDate
    );
  };

  const handleExportCSV = useCallback(
    (skip = false) => {
      if (!skip && !_.isEmpty(gridRef.current!.api.getFilterModel())) {
        setIsOpenExportConfirmDialog(true);
        return;
      }

      const prependContent: CsvCell[][] | string = [
        [{data: {value: "Driver Name: " + manifestDetailsState.selectedManifest?.driver.name}}],
        [{data: {value: "Driver Code: " + manifestDetailsState.selectedManifest?.driver.code}}],
        [{data: {value: "Manifest Number: " + manifestDetailsState.selectedManifest?.manifestDriverId.toString()}}]
      ];

      const fileName = `${format(new Date(), DateFormats.monthDay)} - ${
        manifestDetailsState.selectedManifest?.driver.name
      }`.replace(INVALID_FILE_NAME_REGEX, "_");

      const exportParams: CsvExportParams = {
        fileName,
        columnKeys: gridRef
          .current!.columnApi.getAllDisplayedColumns()
          .map((col) => col.getColId())
          .filter((colId) => !UnExportedColumnIds.includes(colId)),
        prependContent: prependContent,
        processCellCallback(cellParams) {
          if (cellParams.column.getColId() === "address") {
            const addressValue = cellParams.value as DoubleFieldCellValue;
            if (!addressValue) return "";
            return [addressValue.upperField.value, addressValue.lowerField.value].join(", ");
          }
          if (cellParams.column.getColId() === "stopTime") {
            const stopTimeValue = cellParams.value;
            if (!stopTimeValue) return "";
            return [stopTimeValue.lowerField.value, stopTimeValue.upperField.value].join(" ");
          }
          return cellParams.value;
        }
      };

      gridRef.current!.api.exportDataAsCsv(exportParams);
      setIsOpenExportConfirmDialog(false);
    },
    [
      manifestDetailsState.selectedManifest?.driver.code,
      manifestDetailsState.selectedManifest?.driver.name,
      manifestDetailsState.selectedManifest?.manifestDriverId
    ]
  );

  useEffect(() => {
    resetManifestStops();
  }, [manifestDetailsState.selectedManifest?.stops, resetManifestStops, ruleContextState, ruleContextState.rules]);

  useEffect(() => {
    checkForSequenceChanges();
  }, [checkForSequenceChanges]);

  useEffect(() => {
    if (!gridRef.current?.api) {
      setHasPendingSequenceUpdates(false);
      return;
    }
    const setHighestRankColor = async () => {
      if (ruleContextState.loading || !manifestDetailsState.selectedManifest || !ruleContextState.colorRank) return;
      const manifestColor = await getManifestColors({
        manifest: manifestDetailsState.selectedManifest,
        ruleContextState
      });
      setHighestRankIndicatorColor(getHighestRankedColor(manifestColor, ruleContextState.colorRank ?? []));
    };
    const setColors = () => {
      const currentStops: JobStopManifestSequence[] = [];
      gridRef?.current?.api.forEachNode((row, index) => {
        currentStops.push({
          jobStopId: row.data!.jobStopId,
          manifestSequence: index + 1
        });
      });
      if (currentStops.length > 0 && manifestDetailsState.selectedManifest) {
        const newStops = getStopsByNewSequences(currentStops, manifestDetailsState.selectedManifest);
        if (newStops !== undefined) {
          buildIndicatorColors(newStops).then(() => {
            setWorkingStops(newStops);
          });
        }
      }
    };

    setColors();
    setHighestRankColor();
    const interval = setInterval(() => {
      setColors();
      setHighestRankColor();
    }, Constants.MANIFEST_POLLING_INTERVAL);

    return () => clearInterval(interval);
  }, [buildIndicatorColors, manifestDetailsState.selectedManifest, ruleContextState]);

  useEffect(() => {
    listActionBarDispatch({
      type: "SET_IS_SHOWING",
      payload: manifestDetailsState.detailsViewType === "ASSIGNDRIVER" && jobAssignmentState.selectedStops.length > 0
    });
  }, [jobAssignmentState.selectedStops.length, listActionBarDispatch, manifestDetailsState.detailsViewType]);

  useEffect(() => {
    listActionBarDispatch({
      type: "SET_BUTTON_DISABLED",
      payload: {id: "reassign", disabled: !jobAssignmentState.selectedManifest}
    });
  }, [jobAssignmentState.selectedManifest, listActionBarDispatch]);

  const onFilterChange = (event: FilterChangedEvent) => {
    if (gridReady && !initializing) {
      clearAllSelection();
      const filterState = extractJsonPref(userPreferences, UserPreferences.manifestDetailGridFilterState)?.value;
      const newFilterState = event.api.getFilterModel();
      if (!_.isEqual(filterState, newFilterState))
        setUserPref({
          variables: {
            name: UserPreferences.manifestDetailGridFilterState,
            input: createJsonPref(newFilterState, true)
          }
        }).then(() => {
          if (userPrefsQueryRefetch) userPrefsQueryRefetch();
        });

      if (Object.keys(newFilterState).length === 0) {
        setCanResequenceByFilter(true);
      } else if (Object.keys(newFilterState).length >= 2) {
        setCanResequenceByFilter(false);
      } else {
        setCanResequenceByFilter(
          newFilterState.jobStopStatus &&
            newFilterState.jobStopStatus.values.includes(JobStopStatus.ACTIVE) &&
            newFilterState.jobStopStatus.values.includes(JobStopStatus.QUEUED)
        );
      }
    }
  };

  const updateColumn = useCallback(
    (columnApi: ColumnApi, api: GridApi<ManifestStopEnhanced>) => {
      const columnState = extractJsonPref(userPreferences, UserPreferences.manifestDetailGridColumnState)?.value;
      const newColumnState = columnApi.getColumnState();
      if (gridReady && !_.isEqual(columnState, newColumnState) && !initializing) {
        setUserPref({
          variables: {
            name: UserPreferences.manifestDetailGridColumnState,
            input: createJsonPref(newColumnState, true)
          }
        }).then(() => {
          if (userPrefsQueryRefetch) userPrefsQueryRefetch();
        });
        api.refreshServerSide();
      }
    },
    [initializing, userPreferences, gridReady, setUserPref, userPrefsQueryRefetch]
  );

  const onColumnSortChanged = useCallback(
    (e: SortChangedEvent<ManifestStopEnhanced>) => {
      const isSorting = Boolean(e.columnApi.getColumnState().find((s) => s.sort != null));
      setIsSorting(isSorting);
      updateColumn(e.columnApi, e.api);
    },
    [updateColumn]
  );

  const onColumnMoveChanged = useCallback(
    (e: ColumnMovedEvent<ManifestStopEnhanced>) => {
      if (!e.finished || e.source === "api") return;
      updateColumn(e.columnApi, e.api);
    },
    [updateColumn]
  );

  const onColumnVisibleChanged = useCallback(
    (e: ColumnVisibleEvent<ManifestStopEnhanced>) => {
      updateColumn(e.columnApi, e.api);
    },
    [updateColumn]
  );

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    const filterState = extractJsonPref(userPreferences, UserPreferences.manifestDetailGridFilterState);
    if (!filterState.value)
      event.api.getFilterInstance("jobStopStatus", (filter) => {
        filter?.setModel({type: "set", values: [JobStopStatus.ACTIVE.toString(), JobStopStatus.QUEUED.toString()]});
        event.api.onFilterChanged();
      });

    setIsFirstDataRendered(true);
  };

  const onDragging = (event: RowDragMoveEvent) => {
    setIsOutsideAgGrid(false);
    checkRowMoveAndDoFunction(event, handleScrollTop);
    return getDraggingStopsList(event, getArrayFromField(workingStops), setWorkingStops);
  };

  const onDragEnd = () => {
    checkForSequenceChanges();
    setIsDraggedStops(true);
  };

  const onRowDragLeave = useCallback((event: RowDragLeaveEvent) => {
    setIsOutsideAgGrid(true);
    const totalRows = gridRef.current?.api.getModel()?.getRowCount();
    checkRowMoveAndDoFunction(event, handleScrollTop, totalRows);
  }, []);

  const onRowDragEnter = useCallback(() => {
    setRowDataWhenStartedDragging(workingStops);
  }, [workingStops]);

  useEffect(() => {
    const handleMouseUpOutsideAgGrid = () => {
      handleScrollTop("stop");
      setIsOutsideAgGrid(false);
      setWorkingStops(rowDataWhenStartedDragging);
    };
    if (isOutsideAgGrid) {
      document.addEventListener("mouseup", handleMouseUpOutsideAgGrid);
    } else {
      document.removeEventListener("mouseup", handleMouseUpOutsideAgGrid);
    }
    return () => {
      document.removeEventListener("mouseup", handleMouseUpOutsideAgGrid);
    };
  }, [isOutsideAgGrid, rowDataWhenStartedDragging]);

  const handleGridReady = useCallback(() => {
    setGridReady(true);
  }, []);

  const handleUnassignJobClicked = useCallback(
    (unassignJobState: UnassignJobState) => {
      setUnassignJobState(unassignJobState);
      if (!canUnassignPartialJob) {
        setOpenNormalUnassignDialog(true);
      } else {
        const filterStopsByJobID = manifestDetailsState.selectedManifest?.stops?.filter((ms) =>
          unassignJobState.jobIds?.includes(ms.job.jobId)
        );
        if (
          filterStopsByJobID?.find((ms) => isCompleted(ms)) &&
          filterStopsByJobID?.find((ms) => isActiveOrQueued(ms))
        ) {
          setOpenPartialUnassignDialog(true);
        } else {
          setOpenNormalUnassignDialog(true);
        }
      }
    },
    [canUnassignPartialJob, manifestDetailsState.selectedManifest?.stops]
  );

  const handleReassignJobClicked = useCallback(
    (singleSelectedJobStop?: ManifestStopEnhanced) => {
      manifestDetailsDispatch({type: "SET_DETAILS_VIEW_TYPE", payload: "ASSIGNDRIVER"});
      listActionBarDispatch({
        type: "SET_BUTTONS",
        payload: [
          {
            id: "clear-selection",
            intent: "secondary",
            label: () => "Clear Selection",
            action: () => {
              jobAssignmentDispatch({type: "ClearSelections"});
              clearAllSelection();
            }
          },
          {
            id: "qualified-drivers",
            intent: "info",
            label: () => "Qualified Drivers",
            action: () => {
              listActionBarDispatch({
                type: "TOGGLE_BUTTON_ACTIVE",
                payload: {id: "qualified-drivers"}
              });
              setHighlightQualifiedDrivers((prev) => {
                return !prev;
              });
            },
            rightIcon: (
              <div>
                <Tooltip2
                  position="bottom"
                  content="The recommended driver will have the necessary capabilities and will be outlined in blue."
                  popoverClassName="driver-qualified-popover"
                >
                  <InforIcon />
                </Tooltip2>
              </div>
            ),
            active: highlightQualifiedDrivers,
            hidden: true
          },
          {
            id: "reassign",
            intent: "primary",
            disabled: true,
            label: () => "Reassign",
            action: () => setOpenReassignJobConfirmation(true)
          }
        ]
      });
      manifestDetailsDispatch({
        type: "SET_DISABLED_MANIFEST_IDS",
        payload: manifestDetailsState.selectedManifest?.manifestDriverId
          ? [manifestDetailsState.selectedManifest?.manifestDriverId]
          : []
      });
      const newSelectedStops: ManifestStopEnhanced[] = [];
      if (singleSelectedJobStop) {
        gridRef.current?.api.forEachNode((node) => {
          if (node.data?.job.jobId === singleSelectedJobStop.job.jobId) {
            newSelectedStops.push(node.data);
            node.setSelected(true);
          }
        });
      } else {
        const selectedJobIds = [...new Set(jobAssignmentState.selectedStops.map((js) => js.job.jobId))];
        gridRef.current?.api.forEachNode((node) => {
          if (selectedJobIds.includes(node.data?.job.jobId ?? 0)) {
            newSelectedStops.push(node.data as ManifestStopEnhanced);
            node.setSelected(true);
          }
        });
      }
      jobAssignmentDispatch({type: "SetSelectedStops", payload: newSelectedStops});
    },
    [
      manifestDetailsDispatch,
      listActionBarDispatch,
      highlightQualifiedDrivers,
      manifestDetailsState.selectedManifest?.manifestDriverId,
      jobAssignmentDispatch,
      clearAllSelection,
      jobAssignmentState.selectedStops
    ]
  );

  const handleReassignJobConfirm = useCallback(() => {
    jobAssignmentDispatch({type: "SetOriginManifest", payload: manifestDetailsState.selectedManifest});
    manifestDetailsDispatch({type: "SET_DETAILS_VIEW_TYPE", payload: "MAP"});
    setOpenReassignJobConfirmation(false);
    jobAssignmentDispatch({
      type: "ReassignJobStops",
      payload: {
        reassignFunction: reassignJobStops,
        jobStopIds: selectedJobStopIds,
        authState: authState,
        assignmentViewStateDispatch: assignmentViewStateDispatch
      }
    });
    clearAllSelection();
  }, [
    jobAssignmentDispatch,
    manifestDetailsState.selectedManifest,
    manifestDetailsDispatch,
    clearAllSelection,
    reassignJobStops,
    selectedJobStopIds,
    authState,
    assignmentViewStateDispatch
  ]);

  const onCancelReassignment = useCallback(() => {
    manifestDetailsDispatch({type: "SET_DETAILS_VIEW_TYPE", payload: "MAP"});
    clearAllSelection();
  }, [clearAllSelection, manifestDetailsDispatch]);

  const completeStopDialogManifestStop = useMemo(() => {
    return manifestDetailsState.selectedManifest?.stops?.find(
      (s) => s.jobStopId === markCompletedJobStopId
    ) as ManifestStopEnhanced;
  }, [manifestDetailsState.selectedManifest?.stops, markCompletedJobStopId]);

  const completeStopDialogComplete = useCallback(
    (success: boolean) => {
      if (success) {
        AppToasterTopLeft.show({
          intent: Intent.SUCCESS,
          icon: "tick",
          message: `Mark ${UpdateStopCallType.COMPLETE} ${markCompletedJobStopId} from ${manifestDetailsState.selectedManifest?.driver.name}`
        });
      } else {
        AppToasterTopLeft.show({
          intent: Intent.DANGER,
          icon: "asterisk",
          message: `Unable to Mark ${UpdateStopCallType.COMPLETE} ${markCompletedJobStopId} from ${manifestDetailsState.selectedManifest?.driver.name}`
        });
      }
      setMarkCompletedJobStopId(undefined);
    },
    [manifestDetailsState.selectedManifest?.driver.name, markCompletedJobStopId]
  );

  const completeStopDialogCancel = useCallback(() => {
    setMarkCompletedJobStopId(undefined);
  }, []);

  const isDispatchGroupChanged = useMemo(() => {
    if (selectedGroup) {
      return selectedGroup.key !== driverInfo?.dispatchGroupId;
    } else {
      if (!manifestDetailsState.selectedManifest?.dispatchGroup) return false;
      return manifestDetailsState.selectedManifest?.dispatchGroup?.dispatchGroupId !== driverInfo?.dispatchGroupId;
    }
  }, [selectedGroup, driverInfo?.dispatchGroupId, manifestDetailsState.selectedManifest?.dispatchGroup]);

  const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressColumnSelectAll: true,
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true
          }
        }
      ],
      defaultToolPanel: "customColumns"
    };
  }, []);

  const openOrCloseToolPanel = (key: string) => {
    if (gridReady) {
      if (gridRef.current!.api.getOpenedToolPanel() === key) {
        gridRef.current!.api.closeToolPanel();
      } else {
        gridRef.current!.api.openToolPanel(key);
      }
    }
  };

  return (
    <ManifestDriverDetailsWrapper>
      <ManifestDriverDetailsContainer data-testid={"manifest-details-container"}>
        <ConfirmationDialog
          testIdPrefix={"manifest-details-unassign-job"}
          isVisible={openNormalUnassignDialog}
          header={"Unassign Job?"}
          body={`Are you sure you want to unassign the Job ${renderUnassignJobIdsText} from ${manifestDetailsState.selectedManifest?.driver.name}?`}
          yesText={"Yes, Unassign"}
          handleConfirm={handleUnassignJobConfirm}
          handleClose={() => {
            setUnassignJobState(undefined);
            setOpenNormalUnassignDialog(false);
          }}
        />
        <ConfirmationDialog
          testIdPrefix={"manifest-details-unassign-partial-job"}
          isVisible={openPartialUnassignDialog}
          header={"Unassign Partly Complete Job?"}
          body={"Are you sure you want to unassign this partially complete job?"}
          yesText={"Yes"}
          noText={"Cancel"}
          handleConfirm={handleUnassignJobConfirm}
          handleClose={() => {
            setUnassignJobState(undefined);
            setOpenPartialUnassignDialog(false);
          }}
        />
        <ConfirmationDialog
          testIdPrefix={"manifest-details-mark-jobstop-complete"}
          isVisible={markCompletedJobStopId !== undefined && !markStopCompleteEnabled}
          header={"Mark Complete?"}
          body={"Are you sure you want to mark the job stop complete?"}
          yesText={"Yes, Mark complete"}
          handleConfirm={handleMarkJobStopCompleteConfirm}
          handleClose={() => setMarkCompletedJobStopId(undefined)}
        />
        {markCompletedJobStopId !== undefined && markStopCompleteEnabled && (
          <CompleteStopDialog
            manifestStop={completeStopDialogManifestStop}
            markComplete={completeStopDialogComplete}
            onCancel={completeStopDialogCancel}
          ></CompleteStopDialog>
        )}
        <ConfirmationDialog
          testIdPrefix={"manifest-details-mark-jobstop-incomplete"}
          isVisible={markIncompleteJobStopId !== undefined}
          header={"Mark Incomplete?"}
          body={"Are you sure you want to mark the job stop incomplete?"}
          yesText={"Yes, Mark incomplete"}
          handleConfirm={handleMarkJobStopIncompleteConfirm}
          handleClose={() => setMarkIncompleteJobStopId(undefined)}
        />
        <ConfirmationDialog
          testIdPrefix={"manifest-details-pending-seq-updates-alert"}
          isVisible={hasPendingSeqUpdatesAlertVisible}
          header={"Close Manifest?"}
          body={"You have unsaved adjustments that will be lost if you close the manifest."}
          yesText={"Save and Close"}
          noText={"Close Without Saving"}
          confirmTextColor={"#32A467"}
          handleConfirm={handleSaveAndClose}
          handleClose={handleConfirmClose}
        />
        <ConfirmationDialog
          testIdPrefix={"manifest-details-reassign-job"}
          isVisible={openReassignJobConfirmation}
          header={"Reassign a Job?"}
          body={`Are you sure you want to reassign the Job ${renderReassignJobText} from Driver  ${manifestDetailsState.selectedManifest?.driver.name} to Driver ${jobAssignmentState.selectedManifest?.driver.name}?`}
          yesText={"Yes, Reassign"}
          handleConfirm={handleReassignJobConfirm}
          handleClose={() => setOpenReassignJobConfirmation(false)}
        />
        <ConfirmationDialog
          testIdPrefix={"manifest-details-adjust-time-job"}
          isVisible={adjustStops !== undefined}
          icon={<></>}
          header={"Adjust Stop Time"}
          body={
            <AdjustStopTime
              stops={adjustStops!}
              onConfirm={handleAdjustJobsStopTimesConfirm}
              onClose={() => setAdjustStops(undefined)}
            />
          }
          handleClose={() => setAdjustStops(undefined)}
          useFooter={false}
        />
        <ConfirmationDialog
          testIdPrefix={"manifest-details-export"}
          isVisible={isOpenExportConfirmDialog}
          header={"Export with Filtered Data?"}
          body={
            "The export will contain what’s currently shown within the filters. Remove filters if you’d like all information in the export"
          }
          handleClose={() => setIsOpenExportConfirmDialog(false)}
          yesText={"Continue"}
          noText={"Cancel"}
          handleConfirm={() => handleExportCSV(true)}
        />
        {/* Toolbar buttons, window operator buttons */}
        <ButtonsHeaderContainer>
          <ToolbarButtonsContainer>
            <CloseManifestToolTip
              position={Position.RIGHT}
              disabled={!manifestHasOpenStops}
              intent={Intent.WARNING}
              content={"All stops must be complete before the driver is Checked Out."}
            >
              <ManifestToolbarActionButton
                disabled={manifestHasOpenStops || exceedMaximumManifest}
                onClick={handleCheckoutDriver}
              >
                Check Out Driver
              </ManifestToolbarActionButton>
            </CloseManifestToolTip>
            {manifestOptimizationFeature && (
              <ManifestToolbarActionButton disabled={exceedMaximumManifest} onClick={handleOpenOptimizeRouteDialog}>
                Optimize Manifest
              </ManifestToolbarActionButton>
            )}
          </ToolbarButtonsContainer>
          <WindowButtonsContainer>
            <ManifestToolbarButton
              minimal
              disabled={!gridReady}
              onClick={() => handleExportCSV(false)}
              icon={<Icon icon="import" size={18} />}
              title="Export CSV"
              data-testid={"manifest-details-export-btn"}
            />
            <ManifestToolbarButton
              minimal
              data-testid={"manifest-details-close-btn"}
              onClick={handleCloseDetails}
              icon={<Icon icon="cross" size={24} />}
            />
          </WindowButtonsContainer>
        </ButtonsHeaderContainer>
        {dialogOptimizeRouteOpen && (
          <OptimizeDialog
            onClose={handleCloseOptimizeRouteDialog}
            onSave={handleRunOptimizeRouteDialog}
            onDataChange={handleOptimizeDataChange}
            manifest={manifestDetailsState.selectedManifest}
            homeAddress={manifestDetailsState.manifestDriverHomeAddress}
            setIsOptimizeFromHomeLocation={setIsOptimizeFromHomeLocation}
            driverLocationResult={driverLocationResult}
          />
        )}
        <OptimizeSpiner ref={optimizeSpinerRef} size={SIZE_OF_OPTIMIZED_SPINNER} />
        <ErrorDialog
          ref={errorDialogRef}
          typeError={typeOptimizeErrors}
          invalidAddresses={invalidAddresses}
          graphHopperInvalidStopMess={graphHopperInvalidStopMessages}
          graphHopperValidStopMess={graphHopperValidStopMessages}
          graphHopperGeneticMess={graphHopperGeneticMess}
          graphHopperInvalidLocationMess={graphHopperInvalidLocationMess}
        />
        <TimeWindowErrorDialog
          isIgnoreTimeWindow={isDispatcherIgnoreTimeWindows}
          ref={timeWindowErrorDialogRef}
          handleIgnoreTimeWindow={handleOptimizeIgnoreTimeWindow}
        />
        <DriverCode data-testid={"driver-code"}>
          <Text className="driver-code-text" data-testid={"driver-code-text"}>
            {manifestDetailsState.selectedManifest?.driver?.code ?? ""}
          </Text>
        </DriverCode>
        {/* Card header details */}
        <GridHeaderContainer>
          <ManifestCardItemContainer>
            <Text style={{color: "#797979"}}>{manifestDetailsState.selectedManifest?.driver.name?.toUpperCase()}</Text>
            <PipeIcon size="lg" icon={thin("pipe")}></PipeIcon>
            <FontAwesomeIcon
              icon={VehicleIconResolver(
                manifestDetailsState.selectedManifest?.vehicleType.description,
                tenantPreferences
              )}
            />
            <PipeIcon size="lg" icon={thin("pipe")}></PipeIcon>
            <Popover2
              minimal
              placement="bottom-start"
              isOpen={isOpenDispatchGroupSelector}
              onClose={() => setIsOpenDispatchGroupSelector(false)}
              canEscapeKeyClose
              content={
                <DispatchGroupsSeletor
                  initGroup={{
                    key: manifestDetailsState.selectedManifest?.dispatchGroup?.dispatchGroupId as number,
                    value: manifestDetailsState.selectedManifest?.dispatchGroup?.name ?? EmptyValueStrings.unknown
                  }}
                  options={dispatchGroupOptions}
                  selectedGroup={selectedGroup}
                  onSelectGroup={onSelectGroupHandler}
                  originalGroupId={driverInfo?.dispatchGroupId as number}
                  originalText={isDispatchGroupChanged ? "original" : ""}
                  maxHeight={"50vh"}
                  maxWidth={"190px"}
                />
              }
            >
              <DispatchGroupButton
                data-testid="manifest-dispatch-group"
                minimal
                rightIcon="chevron-down"
                onClick={() => setIsOpenDispatchGroupSelector((prev) => !prev)}
              >
                {selectedGroup?.value ??
                  manifestDetailsState.selectedManifest?.dispatchGroup?.name ??
                  EmptyValueStrings.unknown}
                {isDispatchGroupChanged && (
                  <span style={{marginLeft: "3px"}}>
                    <Tooltip2
                      popoverClassName="white-popover"
                      position="top"
                      content={`Changed from '${
                        driverInfo?.dispatchGroup?.dispatchGroup ?? EmptyValueStrings.unknown
                      }'`}
                    >
                      <SwapIcon />
                    </Tooltip2>
                  </span>
                )}
              </DispatchGroupButton>
            </Popover2>
            <PipeIcon size="lg" icon={thin("pipe")}></PipeIcon>
            <ManifestStatus manifestStatusType={manifestStatusType} />
            <PipeIcon size="lg" icon={thin("pipe")}></PipeIcon>
            <Text style={{color: "#797979"}}>{manifestDetailsState.selectedManifest?.manifestDriverId}</Text>
            <PipeIcon size="lg" icon={thin("pipe")}></PipeIcon>
            <StopCountIndicatorV3
              stops={manifestDetailsState.selectedManifest?.stops}
              indicatorColor={highestRankIndicatorColor}
            />
            <PipeIcon size="lg" icon={thin("pipe")}></PipeIcon>
            <DriverQualV3 qualifications={manifestDetailsState.selectedManifest?.driver.qualifications} />
          </ManifestCardItemContainer>
          <ManifestCardItemContainer>
            {hasPendingSequenceUpdates && (
              <SaveChangesContainer>
                <ManifestDateContainerChangesPending>{formattedManifestDate()}</ManifestDateContainerChangesPending>
                <SaveChangesButton
                  onClick={handleSaveResequnceManifest}
                  data-testid={"manifest-resequence-save-button"}
                >
                  Save Changes
                </SaveChangesButton>
                <CancelButton minimal={true}>
                  <CancelButtonText onClick={resetManifestStops}>Cancel</CancelButtonText>
                </CancelButton>
              </SaveChangesContainer>
            )}
            {manifestDetailsState.detailsViewType === "ASSIGNDRIVER" && (
              <CancelReassignmentButton minimal={true} onClick={onCancelReassignment}>
                Cancel Reassignment
              </CancelReassignmentButton>
            )}
            <ManifestDateAndConfigButton>
              {!hasPendingSequenceUpdates && manifestDetailsState.detailsViewType !== "ASSIGNDRIVER" && (
                <ManifestDateContainer>{formattedManifestDate()}</ManifestDateContainer>
              )}
              {manifestDetailsConfiguration && (
                <Button
                  data-testid="manifest-detail-tool-panel"
                  minimal
                  title={"Columns"}
                  icon={<FontAwesomeIcon style={{width: "16px", height: "14px"}} icon={solid("line-columns")} />}
                  onClick={() => {
                    openOrCloseToolPanel("columns");
                  }}
                />
              )}
            </ManifestDateAndConfigButton>
          </ManifestCardItemContainer>
        </GridHeaderContainer>
        <GridContainer className={"ag-theme-alpine"}>
          <StopsGrid
            context={
              {
                selectedStops: jobAssignmentState.selectedStops,
                manifest: manifestDetailsState.selectedManifest,
                handleUnassignJob: handleUnassignJobClicked,
                handleMarkJobStopComplete: setMarkCompletedJobStopId,
                handleMarkJobStopIncomplete: setMarkIncompleteJobStopId,
                handleReassignJob: handleReassignJobClicked,
                handleAdjustTimeJob: setAdjustStops,
                jobsQueuedStatus,
                reassignJobEnabled: reassignJobEnabled,
                canUnassignPartialJob
              } as ManifestDetailsContext
            }
            getRowId={(params) => "" + params.data.jobStopId}
            rowData={workingStops}
            rowDragManaged={false}
            rowDragMultiRow={true}
            onRowDragLeave={onRowDragLeave}
            rowSelection={"multiple"}
            onRowSelected={handleSelectRow}
            rowMultiSelectWithClick={true}
            onFilterChanged={onFilterChange}
            onRowDragEnd={onDragEnd}
            onRowDragMove={onDragging}
            onRowDragEnter={onRowDragEnter}
            columnDefs={colDefs}
            rowHeight={52}
            headerHeight={40}
            ref={gridRef}
            defaultColDef={defaultColDef}
            animateRows={true}
            overlayLoadingTemplate={`Loading manifest ${manifestDriverId}...`}
            noRowsOverlayComponent={ManifestDetailsNoRowsOverlay}
            getContextMenuItems={ManifestDetailsContextMenu}
            onFirstDataRendered={onFirstDataRendered}
            onGridReady={handleGridReady}
            onSortChanged={onColumnSortChanged}
            onColumnMoved={onColumnMoveChanged}
            onColumnResized={onColumnMoveChanged}
            onColumnVisible={onColumnVisibleChanged}
            maintainColumnOrder
            suppressDragLeaveHidesColumns
            sideBar={sideBar}
            columnTypes={columnTypes}
            floatingFiltersHeight={18}
            isShowFloatFilter={!extraManifestFilters}
          ></StopsGrid>
        </GridContainer>
      </ManifestDriverDetailsContainer>

      <ManifestDriverDetailsToolsContainer data-testid="manifest-details-tools-container">
        {manifestDetailsState.detailsViewType === "MAP" && (
          <ManifestDetailsMap
            mapJobStop={selectedJobStopIds}
            manifestState={manifestDetailsState.selectedManifest}
            optimizedStops={newOptimizeStops}
            driverLocationResult={driverLocationResult}
            isDraggedStops={isDraggedStops}
            isOptimized={isOptimized}
            saveDistanceAndTime={saveDistanceAndTime}
            optimizedResultCords={optimizedResultCords}
          />
        )}
        {manifestDetailsState.detailsViewType === "ASSIGNDRIVER" && assignmentManifestList}
      </ManifestDriverDetailsToolsContainer>
    </ManifestDriverDetailsWrapper>
  );
};

export default ManifestDetailsV2;
