import {useOktaAuth} from "@okta/okta-react";
import {useContext, useEffect} from "react";
import {FeatureFlagContext} from "./FeatureFlagProvider";

type OktaConfigProviderProps = {
  children: JSX.Element[] | JSX.Element;
};

const OktaConfigProvider = ({children}: OktaConfigProviderProps) => {
  const {oktaAuth} = useOktaAuth();
  const {oktaDevMode, oktaMaxClockSkew} = useContext(FeatureFlagContext);

  useEffect(() => {
    if (oktaAuth?.options) {
      let changed = false;
      console.debug(`OktaConfig - current options: ${JSON.stringify(oktaAuth.options)}`);
      if (oktaAuth.options.devMode != oktaDevMode) {
        console.debug(`OktaConfig - Updating Okta devMode=${oktaDevMode}`);
        oktaAuth.options.devMode = oktaDevMode;
        console.debug(`OktaConfig - Updated Okta devMode=${oktaAuth.options.devMode}`);
        changed = true;
      }
      if (oktaAuth.options.maxClockSkew != oktaMaxClockSkew) {
        console.debug(`OktaConfig - Updating Okta maxClockSkew=${oktaMaxClockSkew}`);
        oktaAuth.options.maxClockSkew = oktaMaxClockSkew;
        console.debug(`OktaConfig - Updated Okta maxClockSkew=${oktaAuth.options.maxClockSkew}`);
        changed = true;
      }
      if (changed) {
        oktaAuth.tokenManager.renew("accessToken").then(() => {
          console.debug("Renewed token after config change");
        });
      }
    }
  }, [oktaDevMode, oktaMaxClockSkew, oktaAuth]);

  return <>{children}</>;
};

export default OktaConfigProvider;
