import styled from "@emotion/styled";
import {Text} from "@blueprintjs/core";
import {StopInfoMetadata} from "./StopInfo";

const PinIcon = styled.svg``;

const BoxIcon = styled.svg`
  padding: 2px;
  width: 41px;
`;

type SequenceNumberProps = {
  backgroundColor: string;
};

export const SequenceNumber = styled.div<SequenceNumberProps>`
  color: white;
  position: absolute;
  left: 55%;
  bottom: 75%;
  min-width: 20px;
  padding: 2px 6px;
  background: ${(props) => props.backgroundColor ?? "#161616"};
  border: 1px solid #ffffff;
  border-radius: 9px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
  font-size: 11px;
  font-family: "Roboto", sans-serif;
`;

export const JobNumber = styled(Text)`
  position: relative;
  margin: 6px 2px 2px;
  padding: 4px 6px;
  bottom: 2px;
  font-size: 14px;
  color: white;
  background-color: ${(props) => props.color ?? "#14305A"};
  border-radius: 4px;
  box-shadow: 0px 1px 1px 0px #00000036;
  overflow: visible;

  &:after {
    content: "";
    position: absolute;
    top: -2px;
    left: calc(50% - 3px);
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top-color: ${(props) => props.color ?? "#14305A"};
    border-bottom: 0;
    border-left: 0;
    transform: rotate(45deg);
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 41px;
`;

const theme = {
  textColor: "#FFFFFF",
  borderColor: "#FFFFFF",
  backgroundColor: "#000000"
};

type StopIconProps = {
  jobStopId: number;
  jobNumber: number;
  showPin: boolean;
  showSequenceNumber: boolean;
  showJobNumber: boolean;
  sequenceNumberColor: string;
  symbol: string;
  sequenceNumber: number;
  primaryColor: string;
  secondaryColor?: string;
  handleAssignmentByClickingMarker: (info: StopInfoMetadata) => void;
  info: StopInfoMetadata;
};

const JobStopIcon = ({
  jobStopId,
  jobNumber,
  symbol,
  sequenceNumber,
  primaryColor,
  secondaryColor,
  showPin,
  showSequenceNumber,
  showJobNumber,
  sequenceNumberColor,
  handleAssignmentByClickingMarker,
  info
}: StopIconProps) => (
  <IconWrapper
    role={`job-stop-icon-${jobStopId}`}
    onClick={() => handleAssignmentByClickingMarker(info)}
    className={"job-stop-icon"}
    data-testid={`stop-icon-${jobStopId}`}
  >
    {showPin && (
      <PinIcon viewBox="0 0 42 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="B with number">
          <g id="material-symbols" filter="url(#filter0_d_627_29587)">
            <g id="Group 824">
              <path
                id="Vector"
                d="M20.6667 40.1667C16.0826 36.266 12.6591 32.6432 10.3962 29.2982C8.13319 25.9533 7.00114 22.8567 7 20.0083C7 15.7375 8.37407 12.3351 11.1222 9.80104C13.8703 7.26701 17.0518 6 20.6667 6C24.2826 6 27.4647 7.26701 30.2128 9.80104C32.961 12.3351 34.3345 15.7375 34.3333 20.0083C34.3333 22.8556 33.2013 25.9522 30.9372 29.2982C28.6731 32.6443 25.2496 36.2671 20.6667 40.1667Z"
                fill={primaryColor}
              />
              {secondaryColor && (
                <path
                  id="Subtract"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.3962 29.2982C12.6591 32.6432 16.0826 36.266 20.6667 40.1667C20.6674 40.166 20.6682 40.1654 20.6689 40.1647V6C20.6682 6 20.6674 6 20.6667 6C17.0518 6 13.8703 7.26701 11.1222 9.80104C8.37407 12.3351 7 15.7375 7 20.0083C7.00114 22.8567 8.13319 25.9533 10.3962 29.2982Z"
                  fill={secondaryColor}
                />
              )}
              <path
                id="Vector_2"
                d="M20.6667 40.1667C16.0826 36.266 12.6591 32.6432 10.3962 29.2982C8.13319 25.9533 7.00114 22.8567 7 20.0083C7 15.7375 8.37407 12.3351 11.1222 9.80104C13.8703 7.26701 17.0518 6 20.6667 6C24.2826 6 27.4647 7.26701 30.2128 9.80104C32.961 12.3351 34.3345 15.7375 34.3333 20.0083C34.3333 22.8556 33.2013 25.9522 30.9372 29.2982C28.6731 32.6443 25.2496 36.2671 20.6667 40.1667Z"
                stroke={theme.borderColor}
                strokeWidth="2"
              />
              <text
                id="B"
                fill={theme.textColor}
                xmlSpace="preserve"
                fontFamily="Arial"
                fontSize="16"
                fontWeight="bold"
                letterSpacing="0px"
              >
                <tspan x="15.5134" y="26.8442">
                  {symbol}
                </tspan>
              </text>
            </g>
          </g>
        </g>
        <defs>
          <filter
            id="box_filter"
            x="0"
            y="0"
            width="90"
            height="90"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_627_29587" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_627_29587" result="shape" />
          </filter>
          <filter
            id="filter0_d_627_29587"
            x="-4"
            y="3"
            width="49"
            height="49"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_627_29587" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_627_29587" result="shape" />
          </filter>
          <filter
            id="filter1_d_627_29587"
            x="13.8"
            y="44"
            width="14"
            height="14"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="1" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_627_29587" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_627_29587" result="shape" />
          </filter>
        </defs>
      </PinIcon>
    )}
    <BoxIcon
      className={"box-icon"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M7.9998 1.20001L1.1998 4.00001L0.799805 12L7.9998 14.8L15.1998 12.4L14.7998 4.00001L7.9998 1.20001Z"
          fill="white"
        />
        <path
          d="M7.32812 0.178101C7.7625 0.0218506 8.2375 0.0218506 8.675 0.178101L14.675 2.32185C15.4688 2.60623 16 3.35935 16 4.20623V11.7937C16 12.6375 15.4688 13.3937 14.6719 13.6781L8.67188 15.8218C8.2375 15.9781 7.7625 15.9781 7.325 15.8218L1.325 13.6781C0.53125 13.3937 0 12.6406 0 11.7937V4.20623C0 3.36248 0.53125 2.60623 1.32812 2.32185L7.32812 0.178101ZM8 2.06248L2.57188 3.99998L8 5.93748L13.4281 3.99998L8 2.06248ZM9 13.5812L14 11.7969V5.92185L9 7.70623V13.5812Z"
          fill="black"
        />
      </g>
    </BoxIcon>
    {showPin && showSequenceNumber && (
      <SequenceNumber className="job-stop sequence-number" backgroundColor={sequenceNumberColor}>
        {sequenceNumber}
      </SequenceNumber>
    )}
    {showJobNumber && (
      <JobNumber className={"job-number"} ellipsize>
        {jobNumber}
      </JobNumber>
    )}
  </IconWrapper>
);

export default JobStopIcon;
