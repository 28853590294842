import {Button, Divider, Radio, RadioGroup} from "@blueprintjs/core";
import styled from "@emotion/styled";
import React, {FormEvent, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {DriverRequirementEnforcementLevel} from "./types/DriverRequirementEnforcementLevel";
import {Pref, UserPref, useSetTenantPrefMutation} from "../../generated/graphql";
import {TenantPreferences} from "../common/Constants";
import {extractSimplePref, PreferenceContext, PrefValue} from "../../providers/PreferenceProvider";
import {Tooltip2} from "@blueprintjs/popover2";
import "./CompanySettings.css";
import {OrderEntryType} from "./types/OrderEntryType";
import {FeatureFlagContext} from "../../providers/FeatureFlagProvider";
import _ from "lodash";

const heightOfHeader = 69;
const heightOfFooter = 71;
const primaryColor = "#14305A";
const secondaryColor = "#797979";
const primaryText = "F161616";

const Container = styled.div`
  font-family: Roboto;

  height: 100%;
  width: calc(100vw - 200px);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Header = styled.div`
  width: 100%;
  height: ${heightOfHeader}px;
  padding: 20px 25px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  box-shadow: 0px 1px 6px 0px #0000001a;
`;
const HeaderText = styled.h2`
  font-size: 24px;
  font-weight: 400;
`;

const Body = styled.div`
  height: calc(100vh - ${heightOfHeader + heightOfFooter}px);
  width: 60%;

  grid-template-columns: repeat(3, 1fr);

  overflow: auto;
`;

const Footer = styled.div`
  width: 100%;
  height: ${heightOfFooter}px;
  padding: 20px 25px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 80px;

  box-shadow: 0px 7px 20px 0px #00000059;
`;

const CancelButton = styled(Button)`
  margin: 0;

  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 0px;

  cursor: pointer;
  color: ${primaryColor} !important;

  border: none !important;
  &:hover {
    background: none !important;
  }

  &[disabled] {
    color: rgba(119, 119, 119, 0.3) !important;
  }
`;

const SaveButton = styled(Button)`
  width: 63px;

  color: white !important;

  border-radius: 4px;
  background: linear-gradient(180deg, #214a89 0%, #14305a 100%);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);

  &[disabled] {
    border: 1px solid #d9d9d9;
    background: rgba(15, 18, 23, 0.1) !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05) !important;
  }
`;

const DwellInput = styled.input`
  border: 1px solid #c3c3c3;
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
  font-family: Roboto;
  width: 70px;
  height: 32px;
  padding: 6px 15px 6px 15px;
  border-radius: 4px;
  text-align: center;
  margin-right: 13px;
`;
const DwellText = styled.span`
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto;
`;

export const DEFAULT_AVG_DWELL = 10;

export enum TimeWindowPrefType {
  DISPATCHER = "dispatcher",
  DRIVER = "driver"
}

const CompanySettings = () => {
  const {tenantPreferences, tenantPrefsQueryRefetch} = useContext(PreferenceContext);
  const [setTenantPref, {loading: isSetTenantPrefLoading, data: setTenantPrefData}] = useSetTenantPrefMutation();
  const {dispatcherIgnoreTimeWindows} = useContext(FeatureFlagContext);
  const [enforcementLevel, setEnforcementLevel] = useState<DriverRequirementEnforcementLevel>(
    DriverRequirementEnforcementLevel.Warning
  );
  const [initEnforcementLevel, setInitEnforcementLevel] = useState<DriverRequirementEnforcementLevel | null>(null);
  const [orderEntryType, setOrderEntryType] = useState<OrderEntryType>(OrderEntryType.Current);
  const [initOrderEntryType, setInitOrderEntryType] = useState<OrderEntryType | null>(null);
  const [isDispatcherIgnoreTimeWindows, setDispatcherIgnoreTimeWindows] = useState<boolean>(false);
  const [driverIgnoreTimeWindows, setDriverIgnoreTimeWindows] = useState<boolean>(false);
  const [driverAverageDwellTime, setDriverAverageDwellTime] = useState<number | undefined>(undefined);
  const [timezoneOfJobStop, setTimezoneOfJobStop] = useState<boolean>(false);
  const [requireArrAndDepTimes, setRequireArrAndDepTimes] = useState<boolean>(false);
  const [isDriverAverageDwellTimeDisabled, setDriverAverageDwellTimeDisabled] = useState<boolean>(true);
  const [initDispatcherIgnoreTimeWindows, setInitDispatcherIgnoreTimeWindows] = useState<boolean>(false);
  const [initDriverIgnoreTimeWindows, setInitDriverIgnoreTimeWindows] = useState<boolean>(false);
  const [initDriverAverageDwellTime, setInitDriverAverageDwellTime] = useState<number | undefined>(undefined);
  const [initTimezoneOfJobStop, setInitTimezoneOfJobStop] = useState<boolean>(false);
  const [initRequireArrAndDepTimes, setInitRequireArrAndDepTimes] = useState<boolean>(false);
  const {driverRequirements: driverRequirementsEnabled} = useContext(FeatureFlagContext);
  const [canUnassignPartialJob, setCanUnassignPartialJob] = useState<boolean>(false);
  const [initCanUnassignPartialJob, setInitCanUnassignPartialJob] = useState<boolean>(false);

  const updateTenantPrefs = useCallback(
    (key: string, value: any) => {
      setTenantPref({
        variables: {
          name: key,
          input: {
            value: JSON.stringify({value: value})
          }
        }
      })
        .then(() => {
          if (tenantPrefsQueryRefetch) tenantPrefsQueryRefetch();
        })
        .catch((error: Error) => {
          console.error(error);
        });
    },
    [setTenantPref, tenantPrefsQueryRefetch]
  );

  const handleDriverRequiredChange = useCallback((event: FormEvent<HTMLInputElement>): void => {
    const selectedValue = event.currentTarget.value as DriverRequirementEnforcementLevel;
    setEnforcementLevel(selectedValue);
  }, []);

  const handleOrderEntryTypeChange = useCallback((event: FormEvent<HTMLInputElement>): void => {
    const selectedValue = event.currentTarget.value as OrderEntryType;
    setOrderEntryType(selectedValue);
  }, []);

  const handleDispatcherIgnoreTimeWindowsChange = useCallback((event: FormEvent<HTMLInputElement>): void => {
    const selectedValue = Boolean(JSON.parse(event.currentTarget.value));
    setDispatcherIgnoreTimeWindows(selectedValue);
  }, []);

  const handleDriverIgnoreTimeWindowsChange = useCallback((event: FormEvent<HTMLInputElement>): void => {
    const selectedValue = Boolean(JSON.parse(event.currentTarget.value));
    setDriverIgnoreTimeWindows(selectedValue);
    setDriverAverageDwellTimeDisabled(!selectedValue);
  }, []);

  const handleDriverAverageDwellTimeChange = useCallback((event: FormEvent<HTMLInputElement>): void => {
    let selectedValue: number | undefined = parseInt(event.currentTarget.value);
    if (isNaN(selectedValue)) {
      selectedValue = undefined;
    }
    setDriverAverageDwellTime(selectedValue);
  }, []);

  const handleTimezoneOfJobStopChange = useCallback((event: FormEvent<HTMLInputElement>): void => {
    const selectedValue = Boolean(JSON.parse(event.currentTarget.value));
    setTimezoneOfJobStop(selectedValue);
  }, []);

  const handleRequireArrAndDepTimesChange = useCallback((event: FormEvent<HTMLInputElement>): void => {
    const selectedValue = Boolean(JSON.parse(event.currentTarget.value));
    setRequireArrAndDepTimes(selectedValue);
  }, []);

  const handleCanUnassignPartialJobChange = useCallback((event: FormEvent<HTMLInputElement>): void => {
    const selectedValue = Boolean(JSON.parse(event.currentTarget.value));
    setCanUnassignPartialJob(selectedValue);
  }, []);

  const isEnforcementLevelDirty = useCallback(() => {
    return initEnforcementLevel !== enforcementLevel;
  }, [enforcementLevel, initEnforcementLevel]);

  const isOrderEntryTypeDirty = useCallback(() => {
    return initOrderEntryType !== orderEntryType;
  }, [initOrderEntryType, orderEntryType]);

  const isDispatcherIgnoreTimeWindowsDirty = useCallback(() => {
    return initDispatcherIgnoreTimeWindows !== isDispatcherIgnoreTimeWindows;
  }, [initDispatcherIgnoreTimeWindows, isDispatcherIgnoreTimeWindows]);

  const isDriverIgnoreTimeWindowsDirty = useCallback(() => {
    return initDriverIgnoreTimeWindows !== driverIgnoreTimeWindows;
  }, [initDriverIgnoreTimeWindows, driverIgnoreTimeWindows]);

  const isDriverAverageDwellTimeDirty = useCallback(() => {
    return initDriverAverageDwellTime !== driverAverageDwellTime;
  }, [initDriverAverageDwellTime, driverAverageDwellTime]);

  const isTimezoneOfJobStopDirty = useCallback(() => {
    return initTimezoneOfJobStop !== timezoneOfJobStop;
  }, [initTimezoneOfJobStop, timezoneOfJobStop]);

  const isRequireArrAndDepTimesDirty = useCallback(() => {
    return initRequireArrAndDepTimes !== requireArrAndDepTimes;
  }, [initRequireArrAndDepTimes, requireArrAndDepTimes]);

  const isCanUnassignPartialJobDirty = useCallback(() => {
    return initCanUnassignPartialJob !== canUnassignPartialJob;
  }, [canUnassignPartialJob, initCanUnassignPartialJob]);

  const isSaveChange = () => {
    return (
      isEnforcementLevelDirty() ||
      isOrderEntryTypeDirty() ||
      isDispatcherIgnoreTimeWindowsDirty() ||
      isDriverIgnoreTimeWindowsDirty() ||
      isDriverAverageDwellTimeDirty() ||
      isTimezoneOfJobStopDirty() ||
      isRequireArrAndDepTimesDirty() ||
      isCanUnassignPartialJobDirty()
    );
  };

  const handleSaveSetting = useCallback(() => {
    if (isEnforcementLevelDirty()) {
      updateTenantPrefs(TenantPreferences.driverRequirementEnforcement, enforcementLevel);
    }
    if (isOrderEntryTypeDirty()) {
      updateTenantPrefs(TenantPreferences.orderEntryType, orderEntryType);
    }
    if (isDispatcherIgnoreTimeWindowsDirty()) {
      updateTenantPrefs(TenantPreferences.dispatcherIgnoreTimeWindows, isDispatcherIgnoreTimeWindows);
    }
    if (isDriverIgnoreTimeWindowsDirty()) {
      updateTenantPrefs(TenantPreferences.driverIgnoreTimeWindows, driverIgnoreTimeWindows);
      if (driverIgnoreTimeWindows && !driverAverageDwellTime) {
        updateTenantPrefs(TenantPreferences.driverAverageDwellTime, DEFAULT_AVG_DWELL);
      }
    }
    if (isDriverAverageDwellTimeDirty()) {
      updateTenantPrefs(TenantPreferences.driverAverageDwellTime, driverAverageDwellTime);
    }
    if (isTimezoneOfJobStopDirty()) {
      updateTenantPrefs(TenantPreferences.timezoneOfJobStop, timezoneOfJobStop);
    }
    if (isRequireArrAndDepTimesDirty()) {
      updateTenantPrefs(TenantPreferences.requireArrAndDepTimes, requireArrAndDepTimes);
    }
    if (isCanUnassignPartialJobDirty()) {
      updateTenantPrefs(TenantPreferences.unassignPartialJob, canUnassignPartialJob);
    }
  }, [
    isEnforcementLevelDirty,
    isOrderEntryTypeDirty,
    isDispatcherIgnoreTimeWindowsDirty,
    isDriverIgnoreTimeWindowsDirty,
    isDriverAverageDwellTimeDirty,
    isTimezoneOfJobStopDirty,
    isRequireArrAndDepTimesDirty,
    isCanUnassignPartialJobDirty,
    updateTenantPrefs,
    enforcementLevel,
    orderEntryType,
    isDispatcherIgnoreTimeWindows,
    driverIgnoreTimeWindows,
    driverAverageDwellTime,
    timezoneOfJobStop,
    requireArrAndDepTimes,
    canUnassignPartialJob
  ]);

  const handleCancelSetting = useCallback(() => {
    setEnforcementLevel(initEnforcementLevel as DriverRequirementEnforcementLevel);
    setOrderEntryType(initOrderEntryType as OrderEntryType);
    setDispatcherIgnoreTimeWindows(initDispatcherIgnoreTimeWindows);
    setDriverIgnoreTimeWindows(initDriverIgnoreTimeWindows);
    setDriverAverageDwellTime(initDriverAverageDwellTime);
    setTimezoneOfJobStop(initTimezoneOfJobStop);
    setRequireArrAndDepTimes(initRequireArrAndDepTimes);
    setCanUnassignPartialJob(initCanUnassignPartialJob);
  }, [
    initCanUnassignPartialJob,
    initDispatcherIgnoreTimeWindows,
    initDriverAverageDwellTime,
    initDriverIgnoreTimeWindows,
    initEnforcementLevel,
    initOrderEntryType,
    initRequireArrAndDepTimes,
    initTimezoneOfJobStop
  ]);

  const renderDriverRequirementSettings = useMemo(() => {
    return [
      {
        ...ContentOptionProps,
        contentTitle: "Assignment to non-qualified drivers",
        contentOptions: [
          {
            label: "Always allow",
            value: DriverRequirementEnforcementLevel.Allow
          },
          {
            label: "Allow with confirmation",
            value: DriverRequirementEnforcementLevel.Warning
          },
          {
            label: "Never allow",
            value: DriverRequirementEnforcementLevel.Prevent
          }
        ],
        contentInformation: "Assignment of the jobs with driver requirements to the drivers with missing requirements",
        selectedOption: enforcementLevel,
        handleChangeOption: handleDriverRequiredChange
      }
    ] as ContentProps[];
  }, [enforcementLevel, handleDriverRequiredChange]);

  const renderGeneralSettings = useMemo(() => {
    const retVal: ContentProps[] = [];

    retVal.push({
      ...ContentOptionProps,
      contentTitle: "Order Entry Version",
      contentOptions: [
        {
          label: "Current",
          value: OrderEntryType.Current
        },
        {
          label: "Legacy",
          value: OrderEntryType.Legacy
        }
      ],
      contentInformation:
        "Sets which version of order entry to use.  The current CSHTML version, or the legacy version.",
      selectedOption: orderEntryType,
      handleChangeOption: handleOrderEntryTypeChange,
      renderPropComponents: renderRadioGroupProps
    });

    if (dispatcherIgnoreTimeWindows) {
      retVal.push({
        ...ContentOptionProps,
        contentTitle: "Allow Dispatchers to Ignore Optimization Time Window",
        contentOptions: [
          {
            group: TimeWindowPrefType.DISPATCHER,
            label: "Yes",
            value: "true"
          },
          {
            group: TimeWindowPrefType.DISPATCHER,
            label: "No",
            value: "false"
          }
        ],
        contentInformation:
          "Allow dispatchers to execute route optimizations while ignoring time windows determined by stops.",
        selectedOption: _.toString(isDispatcherIgnoreTimeWindows),
        handleChangeOption: handleDispatcherIgnoreTimeWindowsChange,
        renderPropComponents: renderRadioGroupProps
      });
    }

    retVal.push(
      {
        ...ContentOptionProps,
        contentTitle: "Allow Drivers to Ignore Optimization Time Window",
        contentOptions: [
          {
            group: TimeWindowPrefType.DRIVER,
            label: "Yes",
            value: "true"
          },
          {
            group: TimeWindowPrefType.DRIVER,
            label: "No",
            value: "false"
          }
        ],
        contentInformation:
          "Allow drivers to execute route optimizations while ignoring time windows determined by stops.",
        selectedOption: _.toString(driverIgnoreTimeWindows),
        handleChangeOption: handleDriverIgnoreTimeWindowsChange,
        renderPropComponents: renderRadioGroupProps
      },
      {
        ...ContentNumericProp,
        contentTitle: "Average Driver Dwell Time",
        contentInformation: "Average dwell time per stop.",
        selectedOption: _.toString(driverAverageDwellTime),
        handleChangeOption: handleDriverAverageDwellTimeChange,
        renderPropComponents: renderNumericTextBox,
        isDisabled: () => isDriverAverageDwellTimeDisabled
      }
    );

    retVal.push({
      ...ContentOptionProps,
      contentTitle: "Time zone of job/stop",
      contentOptions: [
        {
          group: "timezone",
          label: "Yes",
          value: "true"
        },
        {
          group: "timezone",
          label: "No",
          value: "false"
        }
      ],
      contentInformation:
        "Allow show the times of stops and jobs based on the it's timezone. Default: No = Browser's timezone.",
      selectedOption: _.toString(timezoneOfJobStop),
      handleChangeOption: handleTimezoneOfJobStopChange,
      renderPropComponents: renderRadioGroupProps
    });

    retVal.push({
      ...ContentOptionProps,
      contentTitle: "Require arrival & departure times",
      contentOptions: [
        {
          group: "ArrivalAndDepartureTimes",
          label: "Yes",
          value: "true"
        },
        {
          group: "ArrivalAndDepartureTimes",
          label: "No",
          value: "false"
        }
      ],
      contentInformation: "The arrival/departure time would be required or not when mark stop as completed.",
      selectedOption: _.toString(requireArrAndDepTimes),
      handleChangeOption: handleRequireArrAndDepTimesChange,
      renderPropComponents: renderRadioGroupProps
    });

    retVal.push({
      ...ContentOptionProps,
      contentTitle: "Allow Unassign/Reassign Partially Complete Job",
      contentInformation: "Allow dispatchers to unassign partially complete jobs.",
      contentOptions: [
        {
          group: "unassignPartialJob",
          label: "Yes",
          value: "true"
        },
        {
          group: "unassignPartialJob",
          label: "No",
          value: "false"
        }
      ],
      handleChangeOption: handleCanUnassignPartialJobChange,
      renderPropComponents: renderRadioGroupProps,
      selectedOption: _.toString(canUnassignPartialJob)
    });
    return retVal;
  }, [
    orderEntryType,
    handleOrderEntryTypeChange,
    dispatcherIgnoreTimeWindows,
    driverIgnoreTimeWindows,
    handleDriverIgnoreTimeWindowsChange,
    driverAverageDwellTime,
    handleDriverAverageDwellTimeChange,
    timezoneOfJobStop,
    handleTimezoneOfJobStopChange,
    requireArrAndDepTimes,
    handleRequireArrAndDepTimesChange,
    handleCanUnassignPartialJobChange,
    canUnassignPartialJob,
    isDispatcherIgnoreTimeWindows,
    handleDispatcherIgnoreTimeWindowsChange,
    isDriverAverageDwellTimeDisabled
  ]);

  const handleEnforcementLevel = useCallback((prefs: Pref[]) => {
    const prefVal: PrefValue<DriverRequirementEnforcementLevel> = extractSimplePref(
      prefs,
      TenantPreferences.driverRequirementEnforcement,
      DriverRequirementEnforcementLevel.Warning
    );
    setEnforcementLevel(prefVal.value as DriverRequirementEnforcementLevel);
    setInitEnforcementLevel(prefVal.value as DriverRequirementEnforcementLevel);
  }, []);

  const handleOrderEntryType = useCallback((prefs: Pref[]) => {
    const prefVal: PrefValue<OrderEntryType> = extractSimplePref(
      prefs,
      TenantPreferences.orderEntryType,
      OrderEntryType.Current
    );
    setOrderEntryType(prefVal.value as OrderEntryType);
    setInitOrderEntryType(prefVal.value as OrderEntryType);
  }, []);

  const handleDispatcherIgnoreTimeWindows = useCallback((prefs: Pref[]) => {
    const prefVal: PrefValue<boolean> = extractSimplePref(prefs, TenantPreferences.dispatcherIgnoreTimeWindows, false);
    setDispatcherIgnoreTimeWindows(prefVal.value as boolean);
    setInitDispatcherIgnoreTimeWindows(prefVal.value as boolean);
  }, []);

  const handleDriverIgnoreTimeWindows = useCallback((prefs: Pref[]) => {
    const prefVal: PrefValue<boolean> = extractSimplePref(prefs, TenantPreferences.driverIgnoreTimeWindows, false);
    setDriverIgnoreTimeWindows(prefVal.value as boolean);
    setInitDriverIgnoreTimeWindows(prefVal.value as boolean);
    setDriverAverageDwellTimeDisabled(!prefVal.value);
  }, []);

  const handleDriverAverageDwellTime = useCallback((prefs: Pref[]) => {
    const prefVal: PrefValue<number> = extractSimplePref(
      prefs,
      TenantPreferences.driverAverageDwellTime,
      DEFAULT_AVG_DWELL
    );
    setDriverAverageDwellTime(prefVal.value as number);
    setInitDriverAverageDwellTime(prefVal.value as number);
  }, []);

  const handleTimezoneOfJobStop = useCallback((prefs: Pref[]) => {
    const prefVal: PrefValue<boolean> = extractSimplePref(prefs, TenantPreferences.timezoneOfJobStop, false);
    setTimezoneOfJobStop(prefVal.value as boolean);
    setInitTimezoneOfJobStop(prefVal.value as boolean);
  }, []);

  const handleRequireArrAndDepTimes = useCallback((prefs: Pref[]) => {
    const prefVal: PrefValue<boolean> = extractSimplePref(prefs, TenantPreferences.requireArrAndDepTimes, false);
    setRequireArrAndDepTimes(prefVal.value as boolean);
    setInitRequireArrAndDepTimes(prefVal.value as boolean);
  }, []);

  const handleCanUnassignPartialJob = useCallback((prefs: Pref[]) => {
    const prefVal: PrefValue<boolean> = extractSimplePref(prefs, TenantPreferences.unassignPartialJob, false);
    setCanUnassignPartialJob(prefVal.value as boolean);
    setInitCanUnassignPartialJob(prefVal.value as boolean);
  }, []);

  useEffect(() => {
    console.debug("Tenant preferences loaded: ", tenantPreferences);
    handleEnforcementLevel(tenantPreferences);
    handleOrderEntryType(tenantPreferences);
    handleDispatcherIgnoreTimeWindows(tenantPreferences);
    handleDriverIgnoreTimeWindows(tenantPreferences);
    handleDriverAverageDwellTime(tenantPreferences);
    handleTimezoneOfJobStop(tenantPreferences);
    handleRequireArrAndDepTimes(tenantPreferences);
    handleCanUnassignPartialJob(tenantPreferences);
  }, [
    handleCanUnassignPartialJob,
    handleDispatcherIgnoreTimeWindows,
    handleDriverAverageDwellTime,
    handleDriverIgnoreTimeWindows,
    handleEnforcementLevel,
    handleOrderEntryType,
    handleRequireArrAndDepTimes,
    handleTimezoneOfJobStop,
    tenantPreferences
  ]);

  useEffect(() => {
    const data = setTenantPrefData?.setTenantPref as UserPref;
    console.debug("Pref saved: ", data);
    if (data && data.name === TenantPreferences.orderEntryType) {
      handleOrderEntryType([data]);
    }
    if (data && data.name === TenantPreferences.driverRequirementEnforcement) {
      handleEnforcementLevel([data]);
    }
    if (data && data.name === TenantPreferences.dispatcherIgnoreTimeWindows) {
      handleDispatcherIgnoreTimeWindows([data]);
    }
    if (data && data.name === TenantPreferences.driverIgnoreTimeWindows) {
      handleDriverIgnoreTimeWindows([data]);
    }
    if (data && data.name === TenantPreferences.driverAverageDwellTime) {
      handleDriverAverageDwellTime([data]);
    }
    if (data && data.name === TenantPreferences.timezoneOfJobStop) {
      handleTimezoneOfJobStop([data]);
    }
    if (data && data.name === TenantPreferences.requireArrAndDepTimes) {
      handleRequireArrAndDepTimes([data]);
    }
    if (data && data.name === TenantPreferences.unassignPartialJob) {
      handleCanUnassignPartialJob([data]);
    }
  }, [
    handleEnforcementLevel,
    handleOrderEntryType,
    handleDispatcherIgnoreTimeWindows,
    setTenantPrefData?.setTenantPref,
    handleDriverIgnoreTimeWindows,
    handleDriverAverageDwellTime,
    handleTimezoneOfJobStop,
    handleRequireArrAndDepTimes,
    handleCanUnassignPartialJob
  ]);

  return (
    <Container>
      <Header>
        <HeaderText>Company Settings</HeaderText>
      </Header>
      <Body>
        <Section
          sectionName="general-settings-section"
          sectionTitle="General Settings"
          render={renderGeneralSettings}
        />
        {driverRequirementsEnabled && (
          <Section
            sectionName="driver-requirements-section"
            sectionTitle="Driver Requirement Settings"
            render={renderDriverRequirementSettings}
          />
        )}
      </Body>
      <Footer>
        <CancelButton
          data-testid="cancel-button"
          disabled={!isSaveChange() || isSetTenantPrefLoading}
          onClick={handleCancelSetting}
          intent="none"
          outlined
        >
          Cancel
        </CancelButton>
        <SaveButton
          data-testid="save-button"
          disabled={!isSaveChange() || isSetTenantPrefLoading}
          onClick={handleSaveSetting}
          intent="primary"
        >
          Save
        </SaveButton>
      </Footer>
    </Container>
  );
};

export default CompanySettings;

const SectionContainer = styled.div`
  font-family: Roboto;
  padding: 40px 76px 0px 25px;
`;

const SectionTitle = styled.h3`
  margin: 0px 0px 26px 0px;

  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  color: ${secondaryColor};

  text-transform: uppercase;
`;

const CustomDivider = styled(Divider)`
  width: 100%;
  margin: 0px;
`;

interface SectionProps {
  sectionTitle: string;
  render: ContentProps[];
  sectionName?: string;
}

export const Section: React.FC<SectionProps> = ({sectionTitle, render, sectionName}) => {
  return (
    <SectionContainer data-testid={sectionName}>
      <SectionTitle>{sectionTitle}</SectionTitle>
      <CustomDivider />
      {render.length > 0 && render.map((content: ContentProps) => <Content key={content.contentTitle} {...content} />)}
    </SectionContainer>
  );
};

interface ContentOption {
  group?: string;
  label: string;
  value: string;
}
export interface ContentProps {
  contentTitle: string;
  contentOptions: ContentOption[];
  contentInformation: string;
  selectedOption: string;
  handleChangeOption: (event: FormEvent<HTMLInputElement>) => void;
  renderPropComponents: (contentProps: ContentProps) => JSX.Element;
  isDisabled: () => boolean;
}
export const renderRadioGroupProps = (contentProps: ContentProps) => {
  const {handleChangeOption, selectedOption, contentOptions, isDisabled} = contentProps;
  return (
    <RadioGroupCustom onChange={handleChangeOption} selectedValue={selectedOption} disabled={isDisabled()}>
      {contentOptions.map((option) => (
        <Radio
          key={option.value}
          label={option.label}
          value={option.value}
          radioGroup={option.group}
          data-testid={`radio-${option.group}-${option.value}`}
        />
      ))}
    </RadioGroupCustom>
  );
};

const renderNumericTextBox = (contentProps: ContentProps) => {
  const {handleChangeOption, selectedOption, isDisabled} = contentProps;
  return (
    <AverageDwellContainer>
      <DwellInput
        type="text"
        placeholder="ex. 15"
        value={selectedOption}
        onChange={handleChangeOption}
        disabled={isDisabled()}
        data-testid="driver-dwell-input"
      />
      <DwellText data-testid="dwell-text">minutes</DwellText>
    </AverageDwellContainer>
  );
};
export const ContentOptionProps: ContentProps = {
  contentTitle: "",
  contentOptions: [],
  contentInformation: "",
  selectedOption: "",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleChangeOption: () => {},
  renderPropComponents: renderRadioGroupProps,
  isDisabled: () => false
};

export const ContentNumericProp: ContentProps = {
  contentTitle: "",
  contentOptions: [],
  contentInformation: "",
  selectedOption: "",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleChangeOption: () => {},
  renderPropComponents: renderNumericTextBox,
  isDisabled: () => true
};

const ContentContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;
const ContentTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin: 25px 0px 12px;
`;

const ContentBottom = styled.div`
  width: 100%;
`;
const ContentTitle = styled.h4`
  margin: 0;

  font-size: 16px;
  font-weight: 400;

  color: ${primaryText};
`;
export const RadioGroupCustom = styled(RadioGroup)`
  .bp4-control input:checked ~ .bp4-control-indicator {
    background-color: ${primaryColor} !important;
  }
  .bp4-control input:checked ~ span {
    color: ${primaryText} !important;
  }
`;

export const AverageDwellContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Content: React.FC<ContentProps> = (contentProps: ContentProps) => {
  const {contentTitle, contentInformation, renderPropComponents} = contentProps;
  return (
    <ContentContainer>
      <ContentTop>
        <ContentTitle>{contentTitle}</ContentTitle>
        <Tooltip2 popoverClassName="content-tooltip" content={contentInformation} placement="bottom-end" intent="none">
          <div data-testid="information-icon">
            <InformationIcon />
          </div>
        </Tooltip2>
      </ContentTop>
      <ContentBottom>{renderPropComponents(contentProps)}</ContentBottom>
    </ContentContainer>
  );
};

export const InformationIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 22C13.9174 22 16.7153 20.8411 18.7782 18.7782C20.8411 16.7153 22 13.9174 22 11C22 8.08262 20.8411 5.28473 18.7782 3.22183C16.7153 1.15893 13.9174 0 11 0C8.08262 0 5.28473 1.15893 3.22183 3.22183C1.15893 5.28473 0 8.08262 0 11C0 13.9174 1.15893 16.7153 3.22183 18.7782C5.28473 20.8411 8.08262 22 11 22ZM9.28125 14.4375H10.3125V11.6875H9.28125C8.70977 11.6875 8.25 11.2277 8.25 10.6562C8.25 10.0848 8.70977 9.625 9.28125 9.625H11.3438C11.9152 9.625 12.375 10.0848 12.375 10.6562V14.4375H12.7188C13.2902 14.4375 13.75 14.8973 13.75 15.4688C13.75 16.0402 13.2902 16.5 12.7188 16.5H9.28125C8.70977 16.5 8.25 16.0402 8.25 15.4688C8.25 14.8973 8.70977 14.4375 9.28125 14.4375ZM11 5.5C11.3647 5.5 11.7144 5.64487 11.9723 5.90273C12.2301 6.16059 12.375 6.51033 12.375 6.875C12.375 7.23967 12.2301 7.58941 11.9723 7.84727C11.7144 8.10513 11.3647 8.25 11 8.25C10.6353 8.25 10.2856 8.10513 10.0277 7.84727C9.76987 7.58941 9.625 7.23967 9.625 6.875C9.625 6.51033 9.76987 6.16059 10.0277 5.90273C10.2856 5.64487 10.6353 5.5 11 5.5Z"
        fill="#797979"
      />
    </svg>
  );
};
