enum JobStopStatusEnum {
  Active = "active",
  Completed = "completed",
  New = "new",
  Queued = "queued",
  Deleted = "deleted",
  Failed = "failed"
}

const jobStopStatuses: {[key in JobStopStatusEnum]: {name: string; code: string | string[]; color: string}} = {
  active: {
    name: "Active",
    code: "A",
    color: "#2d72d2"
  },
  completed: {
    name: "Completed",
    code: "C",
    color: "#32A467FF"
  },
  new: {
    name: "New",
    code: "N",
    color: "#7CDDDD"
  },
  queued: {
    name: "Queued",
    code: "Q",
    color: "#FFEC00"
  },
  deleted: {
    name: "Deleted",
    code: ["X", "D"],
    color: "#EC9A3C"
  },
  failed: {
    name: "Failed",
    code: "I",
    color: "#ac2f33"
  }
};

export {jobStopStatuses};
