import {Button, InputGroup, Intent, Text} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {solid, thin} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CellClickedEvent, ColDef, ICellRendererParams, ModuleRegistry} from "@ag-grid-community/core";
import {AgGridReact} from "@ag-grid-community/react";
import axios from "axios";
import React, {FormEvent, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import DateTimeCellRenderer from "./DateTimeCellRenderer";
import StatusCellRenderer from "./StatusCellRenderer";
import UserCellRenderer from "./UserCellRenderer";
import {UserData, UserProfile} from "./UserProfile";
import {SERVICES_API_URL} from "../../../config";
import {deleteUser, updateUser} from "../../../services/TenantService";
import {AppToaster} from "../../../utils/toaster";
import {AuthContext, AuthState} from "../../AuthProvider";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import InviteUserDialog, {InviteUserDialogRef} from "./InviteUserDialog";

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const DISPATCH_ACCESS = "dispatch-access";
export const DISPATCH_ADMINS = "dispatch-admins";
export const DISPATCH_TENANT_ADMIN = "dispatch-tenant-admins";

const Container = styled.div`
  height: 100%;
  width: calc(100vw - 200px);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 25px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LeftHeader = styled.div`
  margin-right: auto;
  margin-left: 10px;
  white-space: nowrap;
`;

const RightHeader = styled.div`
  margin-right: 10px;
`;

const GridContainer = styled.div`
  flex: 1;
`;

const SettingsGrid = styled(AgGridReact)`
  width: 100%;
`;

const FilterInput = styled(InputGroup)`
  margin-left: 12px;
  width: 250px;
  display: inline-block;
`;

const InputGroupIcons = styled(FontAwesomeIcon)`
  display: inline-block;
  flex: 0 0 auto;
  vertical-align: text-bottom;
  color: #5f6b7c;
  padding-top: 6px;
`;

const FilterIcon = styled(InputGroupIcons)`
  padding-left: 34px;
  z-index: 1;
`;

const LocalTimesLabel = styled(Text)`
  color: #738091;
  text-align: right;
  font-style: italic;
  margin-right: 5px;
`;

const Cell = styled.div`
  display: flex;
  flex-direction: row;
`;
const DeleteCellRenderer = (props: ICellRendererParams<any, number>) => {
  return !props.data.isDispatchTenantAdmin && !props.data.isDispatchAdmin ? (
    <Cell data-testid={`delete-user-submit-btn-${props.data.profile.id}`}>
      <Button minimal={true} icon={<FontAwesomeIcon icon={solid("trash")} />} />
    </Cell>
  ) : null;
};

export class User implements UserData {
  status: string;
  created: Date;
  activated: Date;
  lastLogin: Date;
  lastUpdated: Date;
  profile: UserProfile;

  constructor(
    status: string,
    created: Date,
    activated: Date,
    lastLogin: Date,
    lastUpdated: Date,
    profile: UserProfile
  ) {
    this.status = status;
    this.created = created;
    this.activated = activated;
    this.lastLogin = lastLogin;
    this.lastUpdated = lastUpdated;
    this.profile = profile;
  }
}

export const defaultGroups = (authContext: AuthState): string[] => {
  return [DISPATCH_ACCESS, `tenant-${authContext.tenant?.shortName}`];
};

const columnDefs = [
  {field: "id", headerName: "Id", hide: true},
  {field: "profile.firstName", headerName: "Name", cellRenderer: UserCellRenderer, width: 300, sort: "asc"},
  {field: "profile.firstName", headerName: "First Name", hide: true},
  {field: "profile.lastName", headerName: "Last Name", hide: true},
  {field: "profile.email", headerName: "Email", hide: true},
  {field: "profile.login", headerName: "Login", hide: true},
  {field: "profile.legacyUserId", headerName: "ECourier Id", width: 120},
  {field: "status", cellRenderer: StatusCellRenderer, filter: true, width: 140},
  {field: "created", cellRenderer: DateTimeCellRenderer},
  {field: "activated", cellRenderer: DateTimeCellRenderer},
  {field: "lastLogin", cellRenderer: DateTimeCellRenderer},
  {field: "lastUpdated", cellRenderer: DateTimeCellRenderer},
  {field: "profile.tenantId", headerName: "Tenant Id"},
  {headerName: "Delete", cellRenderer: DeleteCellRenderer, width: 40}
] as ColDef[];

const UserManagement = () => {
  const gridRef = useRef<any>();
  const inviteUserDialogRef = useRef<InviteUserDialogRef>(null);
  const authContext = useContext(AuthContext);

  const [data, setData] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filterText, setFilterText] = useState<string>("");
  const [gridReady, setGridReady] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);

  useEffect(() => {
    let cancelled = false;
    if (authContext.tenantId && gridReady) {
      const tenantId = authContext.tenantId;
      const url = `${SERVICES_API_URL}/tenants/users/${tenantId}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${authContext.token}`
          }
        })
        .then((response) => {
          const userData = response.data.users;
          let users: User[] = userData.map((userItem: any) => {
            const userGroups: string[] = defaultGroups(authContext);
            if (userItem.isDispatchAdmin) {
              userGroups.push(DISPATCH_ADMINS);
            } else if (userItem.isDispatchTenantAdmin) {
              userGroups.push(DISPATCH_TENANT_ADMIN);
            } else {
              userGroups.push(DISPATCH_ACCESS);
            }
            return {
              status: userItem.user.status,
              created: userItem.user.created,
              activated: userItem.user.activated,
              lastLogin: userItem.user.lastLogin,
              lastUpdated: userItem.user.lastUpdated,
              profile: new UserProfile(
                userItem.user.profile.firstName,
                userItem.user.profile.lastName,
                userItem.user.profile.login,
                userItem.user.profile.email,
                userItem.user.profile.mobilePhone,
                userItem.user.profile.legacyUserId,
                userItem.user.profile.tenantId,
                userGroups,
                updateUser,
                userItem.user.id
              ),
              isDispatchAdmin: userItem.isDispatchAdmin,
              isDispatchTenantAdmin: userItem.isDispatchTenantAdmin
            };
          });
          if (!cancelled) {
            //If dispatch-admin then show all users otherwise only show normal users and dispatch-tenant-admins
            if (!authContext.groups?.includes(DISPATCH_ADMINS)) {
              users = users.filter((user: any) => !user.isDispatchAdmin);
            }
            setData(users);
            setLoading(false);
            setRefreshData(false);
          }
        })
        .catch((ex) => {
          console.error(ex);
        });
    }
    return () => {
      cancelled = true;
    };
  }, [authContext, gridReady, refreshData]);

  const handleOpenInviteUser = useCallback(() => {
    inviteUserDialogRef.current?.onOpen();
  }, []);

  const handleGridReady = (e: any) => {
    setGridReady(true);
    if (loading) e.api.showLoadingOverlay();
  };

  const handleFirstDataRendered = (e: any) => {
    e.api.sizeColumnsToFit({
      defaultMinWidth: 100,
      columnLimits: [{key: "profile.firstName", minWidth: 300}]
    });
  };

  const handleFilterInput = (e: FormEvent<HTMLInputElement>) => {
    const filter = e.currentTarget.value;
    if (filter.length >= 3 || filter.length === 0) {
      gridRef.current.api.setQuickFilter(filter);
    }
    setFilterText(filter);
  };

  const handleClearFilter = () => {
    setFilterText("");
    gridRef.current.api.setQuickFilter("");
  };

  const handleCellClicked = (e: CellClickedEvent) => {
    const columnName = e.colDef.headerName;
    if ("Name" === columnName) {
      const userToEdit = e.data as User;
      inviteUserDialogRef.current?.onOpenUpdate(userToEdit);
    } else if ("Delete" === columnName) {
      const userToDelete = e.data as User;

      deleteUser(authContext.token as string, userToDelete.profile)
        .then(() => {
          AppToaster.show({
            intent: Intent.SUCCESS,
            icon: "tick",
            message: `Deleted user: ${userToDelete.profile.email}.`
          });
          setRefreshData(true);
        })
        .catch((e: any) => {
          console.error(e);
          AppToaster.show({
            intent: Intent.WARNING,
            icon: "warning-sign",
            message: "Failed to delete user."
          });
        });
    }
  };

  const handleRefreshData = useCallback(() => {
    setRefreshData(true);
  }, []);

  const defaultColumnDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      minWidth: 100
    } as ColDef;
  }, []);

  return (
    <Container>
      <Header>
        <LeftHeader>
          <h2>
            <FontAwesomeIcon icon={solid("users")} /> User Management
            <FilterInput
              inputMode={"text"}
              placeholder={"filter"}
              round
              leftIcon={<FilterIcon icon={thin("filter")} listItem size={"xs"} />}
              rightElement={
                filterText ? (
                  <Button
                    icon={<FontAwesomeIcon icon={solid("close")} size={"sm"} />}
                    minimal
                    onClick={handleClearFilter}
                  />
                ) : (
                  <></>
                )
              }
              onInput={handleFilterInput}
              value={filterText}
            />
          </h2>
        </LeftHeader>
        <RightHeader>
          <Button
            data-testid={"create-user-btn"}
            style={{marginRight: "10px"}}
            text={"Invite User to Dispatch"}
            intent={Intent.PRIMARY}
            onClick={handleOpenInviteUser}
          />
          <InviteUserDialog ref={inviteUserDialogRef} refreshData={handleRefreshData} />
        </RightHeader>
      </Header>
      <LocalTimesLabel>All times local</LocalTimesLabel>
      <GridContainer className={"ag-theme-alpine"}>
        <SettingsGrid
          ref={gridRef}
          onGridReady={handleGridReady}
          onFirstDataRendered={handleFirstDataRendered}
          suppressCellFocus={true}
          rowData={data}
          columnDefs={columnDefs}
          defaultColDef={defaultColumnDef}
          rowSelection={"single"}
          onCellClicked={handleCellClicked}
        />
      </GridContainer>
    </Container>
  );
};

export default UserManagement;
