import {UserClaims} from "@okta/okta-auth-js";
import {Dispatch} from "react";
import {JobAssignmentActions, JobAssignmentAsyncActions} from "./JobAssignmentReducer";
import {useOnJobAssignmentUpdateSubscription} from "../../generated/graphql";
import {JobAssignmentEventHandler} from "../../utils/JobAssignmentEventHandler";

type AssignmentSubscriptionProps = {
  dispatch: Dispatch<JobAssignmentActions | JobAssignmentAsyncActions>;
  userClaims: UserClaims;
};

const AssignmentSubscription = ({dispatch, userClaims}: AssignmentSubscriptionProps) => {
  useOnJobAssignmentUpdateSubscription({
    onSubscriptionData: (options) => {
      JobAssignmentEventHandler({
        update: options.subscriptionData.data?.onJobAssignmentUpdate,
        error: options.subscriptionData?.error,
        dispatch: dispatch,
        userClaims: userClaims
      });
    },
    shouldResubscribe: true
  });

  return null;
};

export default AssignmentSubscription;
