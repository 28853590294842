import {BrowserRouter as Router} from "react-router-dom";
import App from "./App";

const AppWithRouterAccess = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWithRouterAccess;
