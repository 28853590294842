import {CSSProperties} from "react";

type DriverEllipseProps = {
  style?: CSSProperties;
};

export const DriverEllipse = ({style}: DriverEllipseProps) => {
  return (
    <svg width="62" height="62" viewBox="0 0 62 62" fill="none" style={style} xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_652_29475)">
        <circle cx="31.0694" cy="31.0501" r="20.9155" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d_652_29475"
          x="0.153854"
          y="0.134644"
          width="61.831"
          height="61.831"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_652_29475" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_652_29475" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
