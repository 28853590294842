import {format, parse} from "date-fns";
import {DateFormats} from "../../common/Constants";

const getFormattedManifestDate = (manifestDateString: string | undefined, dateFormat: string) => {
  if (!manifestDateString) {
    return "";
  }
  let manifestDate = parse(manifestDateString, DateFormats.localDate, new Date(0));
  if (isNaN(manifestDate.getDate())) {
    manifestDate = new Date(manifestDateString);
  }
  return format(manifestDate, dateFormat, {});
};

export {getFormattedManifestDate};
