import {ControlGroup, NumericInput, Tag} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {useEffect, useCallback, useContext, SyntheticEvent, useState} from "react";
import IndicatorSelect, {IndicatorListItem} from "../../common/IndicatorSelect";
import {ItemRenderer} from "@blueprintjs/select";
import {Slider} from "@mui/material";
import {EventTypeSelectItem} from "./types/eventTypeSelectItem";
import {TriggerEventType, TriggerSelectorValues} from "./types/triggerSelectorValues";
import {ColorizedIndicatorContext, ColorizedIndicatorDispatchContext} from "./ColorizedIndicatorStateProvider";

const TriggerInfinityValue = 999999;
const defaultStepSize = 30;
const minStepSize = 1;

const eventTypeValues: EventTypeSelectItem[] = [
  {key: TriggerEventType.BeforeEvent, value: "Before Event"},
  {key: TriggerEventType.AfterEvent, value: "After Event"}
];

const TriggerSelector = () => {
  const colorizedIndicatorState = useContext(ColorizedIndicatorContext);
  const colorizedIndicatorDispatch = useContext(ColorizedIndicatorDispatchContext);

  const selectedValues = colorizedIndicatorState.selectedLogic?.trigger;

  const calcStepSize = (lowerValue: number, upperValue: number) => {
    console.debug("Calcualte step size", lowerValue, upperValue);
    if (
      isNaN(lowerValue) ||
      isNaN(upperValue) ||
      (lowerValue === 0 && upperValue === 0) ||
      (lowerValue === TriggerInfinityValue && upperValue === TriggerInfinityValue)
    )
      return defaultStepSize;

    let stepSize = Math.floor(Math.max(lowerValue, upperValue) / 2);

    if (upperValue === TriggerInfinityValue) {
      stepSize = lowerValue === 0 ? defaultStepSize : Math.floor(lowerValue / 2);
    }
    if (lowerValue === TriggerInfinityValue) {
      stepSize = upperValue === 0 ? defaultStepSize : Math.floor(upperValue / 2);
    }

    return stepSize || minStepSize;
  };

  const [stepSize, setStepSize] = useState<number>(() => {
    return calcStepSize(Number(selectedValues?.lowerValue), Number(selectedValues?.upperValue));
  });

  const [sliderPosition, setSliderPosition] = useState<number[]>(() => {
    const lowerPosition: number =
      selectedValues?.lowerValue === TriggerInfinityValue ? stepSize * 8 : (selectedValues?.lowerValue as number);
    const upperPosition: number =
      selectedValues?.upperValue === TriggerInfinityValue ? stepSize * 4 : (selectedValues?.upperValue as number);

    return [
      selectedValues?.lowerType === TriggerEventType.BeforeEvent ? -lowerPosition : lowerPosition,
      selectedValues?.upperType === TriggerEventType.BeforeEvent ? -upperPosition : upperPosition
    ];
  });

  const [lowerValue, setLowerValue] = useState<number>(selectedValues?.lowerValue as number);
  const [upperValue, setUpperValue] = useState<number>(selectedValues?.upperValue as number);

  useEffect(() => {
    if (selectedValues) {
      if (selectedValues.lowerValue >= TriggerInfinityValue || selectedValues.upperValue >= TriggerInfinityValue) {
        return;
      }
      if (
        (selectedValues.lowerType === TriggerEventType.BeforeEvent && selectedValues.lowerValue > 8 * stepSize) ||
        (selectedValues.upperType === TriggerEventType.AfterEvent && selectedValues.upperValue > 4 * stepSize)
      ) {
        const updatedStepSize = Math.floor(Math.max(selectedValues.lowerValue, selectedValues.upperValue) / 2);
        setStepSize(updatedStepSize);
        console.debug("TriggerSelector - stepSize", updatedStepSize);
      }
    }
  }, [selectedValues, stepSize]);

  useEffect(() => {
    setStepSize(colorizedIndicatorState.triggerSelectorStepSize);
  }, [colorizedIndicatorState.triggerSelectorStepSize]);

  // Set uppper and lower text values
  useEffect(() => {
    setLowerValue(selectedValues?.lowerValue as number);
    setUpperValue(selectedValues?.upperValue as number);
  }, [selectedValues?.lowerValue, selectedValues?.upperValue]);

  useEffect(() => {
    //We only want to recalcuate stepsize if we are changing logics
    setStepSize(calcStepSize(selectedValues?.lowerValue as number, selectedValues?.upperValue as number));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorizedIndicatorState.selectedLogic?.key]);

  useEffect(() => {
    if (selectedValues) {
      const lowerPosition =
        selectedValues.lowerType === TriggerEventType.BeforeEvent
          ? -selectedValues.lowerValue
          : selectedValues.lowerValue;
      const upperPosition =
        selectedValues.upperType === TriggerEventType.BeforeEvent
          ? -selectedValues.upperValue
          : selectedValues.upperValue;

      setSliderPosition([lowerPosition, upperPosition]);
    }
  }, [colorizedIndicatorDispatch, selectedValues]);

  const handleOnSelectLowerBound = useCallback(
    (item: EventTypeSelectItem): void => {
      let newTriggerValues = {...selectedValues, lowerType: item.key};

      if (item.key === TriggerEventType.AfterEvent && selectedValues?.upperType === TriggerEventType.BeforeEvent) {
        newTriggerValues = {
          ...newTriggerValues,
          upperType: TriggerEventType.AfterEvent,
          upperValue: selectedValues?.lowerValue
        };
      } else if (
        item.key === TriggerEventType.AfterEvent &&
        selectedValues?.upperType === TriggerEventType.AfterEvent &&
        selectedValues?.upperValue < selectedValues?.lowerValue
      ) {
        newTriggerValues = {
          ...newTriggerValues,
          upperValue: selectedValues?.lowerValue
        };
      }

      const updatedTriggerValues = {
        ...selectedValues,
        ...newTriggerValues
      } as TriggerSelectorValues;

      colorizedIndicatorDispatch({type: "SET_TRIGGER_SELECTOR", payload: updatedTriggerValues});
      colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
    },
    [colorizedIndicatorDispatch, selectedValues]
  );

  const handleOnSelectUpperBound = (item: EventTypeSelectItem): void => {
    let newTriggerValues = {...selectedValues, upperType: item.key};

    if (item.key === TriggerEventType.BeforeEvent && selectedValues?.lowerType === TriggerEventType.AfterEvent) {
      newTriggerValues = {
        ...newTriggerValues,
        lowerType: TriggerEventType.BeforeEvent,
        lowerValue: selectedValues?.upperValue
      };
    } else if (
      item.key === TriggerEventType.BeforeEvent &&
      selectedValues?.lowerType === TriggerEventType.BeforeEvent &&
      selectedValues?.upperValue > selectedValues?.lowerValue
    ) {
      newTriggerValues = {
        ...newTriggerValues,
        lowerValue: selectedValues?.upperValue
      };
    }

    const updatedTriggerValues = {
      ...selectedValues,
      ...newTriggerValues
    } as TriggerSelectorValues;

    colorizedIndicatorDispatch({type: "SET_TRIGGER_SELECTOR", payload: updatedTriggerValues});
    colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
  };

  const handleLowerBoundInputChanged = useCallback(
    (valueAsNumber: number) => {
      colorizedIndicatorDispatch({type: "SET_TRIGGER_SELECTOR_LOWERVALUE", payload: valueAsNumber});
      colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
    },
    [colorizedIndicatorDispatch]
  );

  const handleLowerBoundInputBlur = useCallback(
    (e: any) => {
      const valueAsNumber = Number(e.target.value);

      if (
        selectedValues?.lowerType === selectedValues?.upperType &&
        selectedValues?.lowerType === TriggerEventType.BeforeEvent &&
        valueAsNumber < selectedValues?.upperValue
      ) {
        colorizedIndicatorDispatch({type: "SET_TRIGGER_SELECTOR_UPPERVALUE", payload: valueAsNumber});
        colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
        setStepSize(calcStepSize(selectedValues?.lowerValue, valueAsNumber));
      }

      if (
        selectedValues?.lowerType === selectedValues?.upperType &&
        selectedValues?.lowerType === TriggerEventType.AfterEvent &&
        valueAsNumber > selectedValues?.upperValue
      ) {
        colorizedIndicatorDispatch({type: "SET_TRIGGER_SELECTOR_LOWERVALUE", payload: valueAsNumber});
        colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
        setStepSize(calcStepSize(valueAsNumber, selectedValues?.upperValue));
      }
    },
    [
      colorizedIndicatorDispatch,
      selectedValues?.lowerType,
      selectedValues?.lowerValue,
      selectedValues?.upperType,
      selectedValues?.upperValue
    ]
  );

  const handleUpperBoundInputChanged = useCallback(
    (valueAsNumber: number) => {
      colorizedIndicatorDispatch({type: "SET_TRIGGER_SELECTOR_UPPERVALUE", payload: valueAsNumber});
      colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
    },
    [colorizedIndicatorDispatch]
  );

  const handleUpperBoundInputBlur = useCallback(
    (e: any) => {
      const valueAsNumber = Number(e.target.value);

      if (
        selectedValues?.lowerType === selectedValues?.upperType &&
        selectedValues?.lowerType === TriggerEventType.BeforeEvent &&
        valueAsNumber > selectedValues?.lowerValue
      ) {
        colorizedIndicatorDispatch({type: "SET_TRIGGER_SELECTOR_UPPERVALUE", payload: valueAsNumber});
        colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
      }

      if (
        selectedValues?.lowerType === selectedValues?.upperType &&
        selectedValues?.lowerType === TriggerEventType.AfterEvent &&
        valueAsNumber < selectedValues?.lowerValue
      ) {
        colorizedIndicatorDispatch({type: "SET_TRIGGER_SELECTOR_LOWERVALUE", payload: valueAsNumber});
        colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
      }
    },
    [colorizedIndicatorDispatch, selectedValues?.lowerType, selectedValues?.lowerValue, selectedValues?.upperType]
  );

  const renderLowerItem: ItemRenderer<EventTypeSelectItem> = useCallback(
    (item: EventTypeSelectItem, {handleClick}) => {
      return (
        <IndicatorListItem key={item.key} onClick={handleClick} isSelected={item.key === selectedValues?.lowerType}>
          {item.value}
        </IndicatorListItem>
      );
    },
    [selectedValues?.lowerType]
  );

  const renderUpperItem: ItemRenderer<EventTypeSelectItem> = (item: EventTypeSelectItem, {handleClick}) => {
    return (
      <IndicatorListItem key={item.key} onClick={handleClick} isSelected={item.key === selectedValues?.upperType}>
        {item.value}
      </IndicatorListItem>
    );
  };

  const onSliderChangeCommitted = useCallback(
    (_event: Event | SyntheticEvent<Element, Event>, newPosition: number | number[]): void => {
      const position = newPosition as number[];
      colorizedIndicatorDispatch({
        type: "SET_TRIGGER_SELECTOR_SLIDER_POSITION_VALUES",
        payload: {
          position: position,
          lowerValue: lowerValue,
          upperValue: upperValue
        }
      });
      colorizedIndicatorDispatch({
        type: "SET_ENGINE_RULES"
      });
    },
    [colorizedIndicatorDispatch, lowerValue, upperValue]
  );

  const onSliderChange = useCallback(
    (_event: Event | SyntheticEvent<Element, Event>, newPosition: number | number[]): void => {
      const position = newPosition as number[];

      if (selectedValues?.lowerType === TriggerEventType.BeforeEvent && position[0] > 0) {
        return;
      }
      if (selectedValues?.lowerType === TriggerEventType.AfterEvent && position[0] < 0) {
        return;
      }
      if (selectedValues?.upperType === TriggerEventType.BeforeEvent && position[1] > 0) {
        return;
      }
      if (selectedValues?.upperType === TriggerEventType.AfterEvent && position[1] < 0) {
        return;
      }

      setSliderPosition(position);
      setLowerValue(position[0] === -stepSize * 8 ? TriggerInfinityValue : Math.abs(position[0]));
      setUpperValue(position[1] === stepSize * 4 ? TriggerInfinityValue : Math.abs(position[1]));
    },
    [selectedValues?.lowerType, selectedValues?.upperType, stepSize]
  );

  return (
    <TriggerSelectorContainer>
      <StyledControlGroup>
        <StyledControl>
          <StyledNumberInput
            data-testid="lower-bound-input"
            buttonPosition="none"
            maxLength={6}
            value={lowerValue}
            onValueChange={handleLowerBoundInputChanged}
            onBlur={handleLowerBoundInputBlur}
            rightElement={<TimeTag>min</TimeTag>}
          />
          <IndicatorSelect
            name="lower-bound"
            itemRenderer={renderLowerItem}
            items={eventTypeValues}
            onSelectedItem={handleOnSelectLowerBound}
            placeholderText="Select lower bound"
            selectedItemText={eventTypeValues.find((val) => val.key === selectedValues?.lowerType)?.value}
          />
        </StyledControl>
        <SeparatorTag>-</SeparatorTag>
        <StyledControl>
          <StyledNumberInput
            data-testid="upper-bound-input"
            buttonPosition="none"
            maxLength={6}
            value={upperValue}
            onValueChange={handleUpperBoundInputChanged}
            onBlur={handleUpperBoundInputBlur}
            rightElement={<TimeTag>min</TimeTag>}
          />
          <IndicatorSelect
            name="upper-bound"
            itemRenderer={renderUpperItem}
            items={eventTypeValues}
            onSelectedItem={handleOnSelectUpperBound}
            placeholderText="Select uppper bound"
            selectedItemText={eventTypeValues.find((val) => val.key === selectedValues?.upperType)?.value}
          />
        </StyledControl>
      </StyledControlGroup>
      <SliderContainer>
        <SpaceBetweenContainer>
          <StyledText>Start</StyledText>
          <StyledText>End</StyledText>
        </SpaceBetweenContainer>
        <FlagContainer>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div title="Event">
            <FlagIcon />
          </div>
        </FlagContainer>
        <StyledSlider
          data-testid="time-slider"
          value={sliderPosition}
          onChange={onSliderChange}
          onChangeCommitted={onSliderChangeCommitted}
          valueLabelDisplay="off"
          marks={true}
          step={stepSize}
          min={stepSize * -8}
          max={stepSize * 4}
          disableSwap
        />
        <SpaceBetweenContainer>
          <InfinityIcon />
          <InfinityIcon />
        </SpaceBetweenContainer>
      </SliderContainer>
    </TriggerSelectorContainer>
  );
};

export default TriggerSelector;
export {TriggerInfinityValue, defaultStepSize};

const TriggerSelectorContainer = styled.div``;

const StyledControlGroup = styled(ControlGroup)`
  display: flex;
  flex-direction: row;
  gap: 10px;

  .bp4-button {
    width: 128px !important;
  }
`;

const StyledControl = styled.div`
  display: flex;
  gap: 4px;
`;

const StyledNumberInput = styled(NumericInput)`
  font-size: 14px;

  .bp4-input {
    padding: 8px;
    text-align: center;
    width: 75px;
    border-radius: 4px;
    border: 1px solid #c3c3c373;
    box-shadow: 0px 1px 3px 0px #0000001a inset;
  }
`;

export const TimeTag = styled(Tag)`
  margin: auto;
  font-size: 14px;
  padding: 0;
  color: #161616;
  width: fit-content;
  background-color: transparent;
`;

const SeparatorTag = styled.p`
  margin: 0 0 0 -12px;
  color: #161616;
  font-weight: 500;
  align-self: center;
`;

const SliderContainer = styled.div`
  margin-top: 15px;
  margin-right: 35px;
`;

const SpaceBetweenContainer = styled.div`
  margin: 12px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledText = styled.p`
  font-size: 12px;
  color: #797979;
`;

const StyledSlider = styled(Slider)`
  color: #14305a !important;
  height: 5px;
  padding-top: 0px;
  margin-top: -4px;

  .MuiSlider-rail {
    background-color: #d4d4d4;
    box-shadow: 0px 1px 2px 0px #00000040 inset;
  }

  .MuiSlider-mark {
    height: 5px;
    width: 2px;
    color: #ffffff;
  }
  .MuiSlider-thumb {
    width: 12px;
    height: 12px;
  }
`;

const FlagContainer = styled.div`
  display: grid;
  place-items: left;
  grid-template-columns: repeat(12, 1fr);
`;

const FlagIcon = () => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.02947 1C2.02947 0.446875 1.57601 0 1.01473 0C0.453459 0 0 0.446875 0 1V15C0 15.5531 0.453459 16 1.01473 16C1.57601 16 2.02947 15.5531 2.02947 15V11H13.4452C13.867 11 14.2063 10.6656 14.2063 10.25C14.2063 10.0875 14.1524 9.93125 14.0541 9.8L11.1621 6L14.0541 2.2C14.1524 2.06875 14.2063 1.9125 14.2063 1.75C14.2063 1.33438 13.867 1 13.4452 1H2.02947Z"
      fill="#FA545E"
    />
  </svg>
);
const InfinityIcon = () => (
  <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 2.29629C0 1.20117 1.01563 0.3125 2.26719 0.3125C2.86875 0.3125 3.44531 0.52168 3.87031 0.893555L5 1.88066L6.12813 0.893555C6.55469 0.52168 7.13125 0.3125 7.73281 0.3125C8.98438 0.3125 10 1.20117 10 2.29629V2.70234C10 3.79883 8.98438 4.6875 7.73281 4.6875C7.13125 4.6875 6.55469 4.47832 6.12969 4.10645L5 3.11934L3.87188 4.10645C3.44531 4.47832 2.86875 4.6875 2.26719 4.6875C1.01563 4.6875 0 3.79883 0 2.70371V2.29629ZM4.29219 2.5L3.16406 1.51289C2.92656 1.30508 2.60313 1.1875 2.26719 1.1875C1.56719 1.1875 1 1.68379 1 2.29629V2.70234C1 3.31484 1.56719 3.81113 2.26719 3.81113C2.60313 3.81113 2.92656 3.69492 3.16406 3.48574L4.29219 2.5ZM5.70625 2.5L6.83437 3.48711C7.07188 3.69492 7.39531 3.8125 7.73125 3.8125C8.43125 3.8125 8.99844 3.31621 8.99844 2.70371V2.29629C8.99844 1.68379 8.43125 1.1875 7.73125 1.1875C7.39531 1.1875 7.07188 1.30371 6.83437 1.51289L5.70781 2.5H5.70625Z"
      fill="#797979"
    />
  </svg>
);
