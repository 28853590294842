import styled from "@emotion/styled";

const DatePickerToolbarButton = styled.div<{isSelected: boolean}>`
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid #436bf8;
  color: ${(props) => (props.isSelected ? " #FFFFFF" : "#8DA4BE")};
  background-color: ${(props) => (props.isSelected ? " #8DA4BE" : "#FFFFFF")};
  cursor: pointer;
`;

export default DatePickerToolbarButton;
