/** @jsxImportSource @emotion/react */
import {createContext, Dispatch} from "react";
import "../App.css";
import {JobViewActions} from "./JobAssignmentViewReducer";
import "./JobAssignmentView.css";
import {
  JobAssignmentActions,
  JobAssignmentAsyncActions,
  PendingAssignment
} from "../components/common/JobAssignmentReducer";

import {AssignJobsToManifestMutationFn} from "../generated/graphql";

export enum CardType {
  Detailed = 1,
  Minimal = 2,
  Compact = 3
}

//TODO - Should one of these context be somewhere else? higher level, lower level??

export type JobAssignmentContext = {
  updateState: Dispatch<JobAssignmentActions | JobAssignmentAsyncActions>;
  assignJobsToManifestMutator: AssignJobsToManifestMutationFn;
  pendingActions: PendingAssignment[];
  highlightManifests: number[] | undefined;
};

export const JobAssignment = createContext<JobAssignmentContext | null>(null);

export type JobAssignmentViewContext = {
  updateState: Dispatch<JobViewActions>;
};

export const JobAssignmentViewContext = createContext<JobAssignmentViewContext | null>(null);
