import styled from "@emotion/styled";

const Header = styled.div`
  padding: 15px 20px 20px;
`;

const Title = styled.div`
  font-weight: 400;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

type DatePickerHeaderProps = {
  title: string;
  children?: Element | JSX.Element | null;
};

const DatePickerHeader = ({title, children}: DatePickerHeaderProps) => {
  return (
    <Header>
      <Title>{title}</Title>
      {children}
    </Header>
  );
};
export default DatePickerHeader;
