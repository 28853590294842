import {datadogRum} from "@datadog/browser-rum";
import {useContext, useEffect} from "react";
import {env, GQL_API_URL, SERVICES_API_URL} from "../../config";
import {AuthContext} from "../AuthProvider";
import {FeatureFlagContext} from "../../providers/FeatureFlagProvider";

type DataDogProviderProps = {
  children: JSX.Element[] | JSX.Element;
};

const DataDogProvider = ({children}: DataDogProviderProps) => {
  const {featureFlagsInitialized, datadogEnabled, datadogSessionSampleRate, datadogSessionReplaySampleRate} =
    useContext(FeatureFlagContext);
  const {user, tenant, isEcourierUser} = useContext(AuthContext);

  useEffect(() => {
    if (featureFlagsInitialized) {
      if (datadogEnabled) {
        if (!datadogRum.getInternalContext()) {
          console.debug("DataDog - initializing.");
          datadogRum.init({
            applicationId: "fbe22e93-bac0-4cbd-b022-d3f6bf252758",
            clientToken: "pubfe7b6c6f6a93b48f4cc8598b94e167da",
            site: "datadoghq.com",
            service: "dispatch",
            env: env.name,
            sessionSampleRate: datadogSessionSampleRate,
            sessionReplaySampleRate: datadogSessionReplaySampleRate,
            trackUserInteractions: true,
            defaultPrivacyLevel: "mask-user-input",
            allowedTracingUrls: [GQL_API_URL, SERVICES_API_URL]
          });
          console.debug("DataDog - initialized.");
          console.debug("DataDog - session recording starting.");
          datadogRum.startSessionReplayRecording();
          console.debug("DataDog - session recording started.");
        } else {
          console.warn("DataDog - Already initialized, cannot update config without refresh");
        }
      }
    } else {
      console.debug("DataDog - Awaiting feature flags to initialize");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlagsInitialized, datadogEnabled, datadogSessionSampleRate, datadogSessionReplaySampleRate]);

  useEffect(() => {
    if (datadogEnabled) {
      if (user && tenant && isEcourierUser !== undefined) {
        const datadogUser = {
          name: user.name,
          email: user.email,
          tenantId: user.tenantId,
          tenantName: tenant.shortName,
          tenantHub: tenant.hubName,
          isEcourierUser: isEcourierUser
        };
        console.debug(`Datadog - Setting user: ${JSON.stringify(datadogUser)}`);
        datadogRum.setUser(datadogUser);
      } else {
        console.debug("DataDog - Cannot set datadog user, details not yet available");
      }
    }
  }, [user, tenant, isEcourierUser, datadogEnabled]);

  return <div>{children}</div>;
};

export default DataDogProvider;
