import {Intent, Position, Toaster} from "@blueprintjs/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import styled from "@emotion/styled";
import "./toaster.css";

const AppToasterTopLeft = Toaster.create({
  position: Position.TOP_LEFT,
  maxToasts: 3
});

const ToastContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const Body = styled.div``;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

type ToastProps = {
  message: string;
  title: string;
};

const Toast = ({message, title}: ToastProps) => {
  return (
    <ToastContainer>
      <Header>
        <FontAwesomeIcon size={"xl"} color={"#32A467"} icon={solid("circle-check")} />
        <Title>{title}</Title>
      </Header>
      <Body>{message}</Body>
    </ToastContainer>
  );
};

export const showToast = ({itent, title, message}: {itent: Intent; title: string; message: string}) => {
  AppToasterTopLeft.show({
    intent: itent,
    message: <Toast message={message} title={title} />,
    className: "toast",
    timeout: 5000
  });
};
