import {ChangeEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useState} from "react";
import {IAfterGuiAttachedParams, IFilterParams} from "@ag-grid-community/core";
import {CustomerSortableField, SearchableSortDirection, useSearchCustomersQuery} from "../../generated/graphql";
import {andSearchWithWildcards} from "../settings/ColorizedIndicators/RuleGenerator.service";
import styled from "@emotion/styled";
import {InputGroup, Checkbox, Button, Intent} from "@blueprintjs/core";
import {Tooltip2} from "@blueprintjs/popover2";
import {Constants} from "./Constants";
export const heightDifferenceFromTop = 0.5;

type TCustomerOptions = {name: string; customerId: number};
export enum CustomerFilterOperators {
  Equal = "Equal",
  NotEqual = "NotEqual"
}

const getNextSelectedCustomerIds = (currentSelectedIds: number[], id: number) => {
  if (currentSelectedIds.includes(id)) return currentSelectedIds.filter((i) => i !== id);
  return [...currentSelectedIds, id];
};

// eslint-disable-next-line react/display-name
export default forwardRef((props: IFilterParams, ref) => {
  //Modal
  const [afterGuiAttachedParams, setAfterGuiAttachedParams] = useState<IAfterGuiAttachedParams>();
  //Customers Query
  const [customerKeyWords, setCustomerKeyWords] = useState<string>("");
  const [customerFilter, setCustomerFilter] = useState<any>({});
  const [customerOptions, setCustomerOptions] = useState<TCustomerOptions[]>([]);
  //MultiSelect
  const [customerFilterOperator, setCustomerFilterOperator] = useState<CustomerFilterOperators>(
    CustomerFilterOperators.Equal
  );
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(true);
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<number[]>([]);
  //Tooltip
  const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false);

  const searchCustomerResult = useSearchCustomersQuery({
    variables: {
      limit: 100,
      filter: customerFilter,
      sort: {
        field: CustomerSortableField.Name,
        direction: SearchableSortDirection.Asc
      }
    }
  });
  const handleSearchCustomer = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setCustomerKeyWords(event.target.value);
  }, []);

  useEffect(() => {
    if (customerKeyWords?.trim()) {
      const tokens = customerKeyWords.trim().split(" ");
      setCustomerFilter(andSearchWithWildcards("name", tokens));
    } else {
      setCustomerFilter({});
    }
  }, [customerKeyWords]);

  useEffect(() => {
    if (!searchCustomerResult.loading && searchCustomerResult.data?.searchCustomers) {
      const customersOptions: TCustomerOptions[] =
        searchCustomerResult.data?.searchCustomers?.items?.map(({name, customerId}) => ({
          customerId: customerId,
          name: name ?? ""
        })) ?? [];
      setCustomerOptions(customersOptions);
    }
  }, [isSelectedAll, searchCustomerResult.data, searchCustomerResult.loading]);

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return !isSelectedAll;
      },

      getModel() {
        if (isSelectedAll) {
          return null;
        }
        return {
          filterType: "setNumber",
          values: selectedCustomerIds,
          customerFilterOperator: customerFilterOperator
        };
      },

      setModel(model: any) {
        if (!model) {
          onClear();
          return;
        }
        console.debug("saved customer filters: ", model);
        const selectedCustomers = model?.values?.filter((customerId: any) => !isNaN(customerId)) ?? [];
        setCustomerFilterOperator(model?.customerFilterOperator ?? CustomerFilterOperators.Equal);
        setSelectedCustomerIds(selectedCustomers);
        if (selectedCustomers.length > 0 || model?.customerFilterOperator === CustomerFilterOperators.NotEqual) {
          setIsSelectedAll(false);
        }
      },

      afterGuiAttached(params: IAfterGuiAttachedParams) {
        setAfterGuiAttachedParams(params);
      }
    };
  });
  const onApply = () => {
    props.filterChangedCallback();
    afterGuiAttachedParams?.hidePopup?.();
  };

  const onClear = () => {
    setIsSelectedAll(true);
    setCustomerFilterOperator(CustomerFilterOperators.Equal);
    setSelectedCustomerIds([]);
    setCustomerFilter({});
    setCustomerKeyWords("");
    searchCustomerResult.refetch({
      limit: 100
    });
    setTimeout(() => {
      props.filterChangedCallback();
    }, 500);
    afterGuiAttachedParams?.hidePopup?.();
  };

  const onLoadMore = useCallback(() => {
    searchCustomerResult.refetch({
      limit: customerOptions.length + 50
    });
  }, [customerOptions.length, searchCustomerResult]);

  const handleSelectAll = useCallback(() => {
    const nextState = !isSelectedAll;
    setSelectedCustomerIds([]);
    setCustomerFilterOperator(nextState ? CustomerFilterOperators.Equal : CustomerFilterOperators.NotEqual);
    setIsSelectedAll(nextState);
  }, [isSelectedAll]);

  const handleSelectCustomer = useCallback(
    (customerId: number) => {
      const nextSelectedCustomerIds = getNextSelectedCustomerIds(selectedCustomerIds, customerId);
      if (
        nextSelectedCustomerIds.length > Constants.MAX_NUMBER_OF_CUSTOMERS_FILTERS &&
        customerFilterOperator === CustomerFilterOperators.Equal
      ) {
        setIsWarningOpen(true);
        setTimeout(() => {
          setIsWarningOpen(false);
        }, 2000);
        return;
      }

      setSelectedCustomerIds(nextSelectedCustomerIds);
      setIsSelectedAll(false);

      if (isSelectedAll) {
        setCustomerFilterOperator(CustomerFilterOperators.NotEqual);
      }
      if (customerFilterOperator === CustomerFilterOperators.NotEqual && nextSelectedCustomerIds.length === 0) {
        setIsSelectedAll(true);
        setCustomerFilterOperator(CustomerFilterOperators.Equal);
      }
      if (customerFilterOperator === CustomerFilterOperators.NotEqual && selectedCustomerIds.length === 0) {
        setCustomerFilterOperator(CustomerFilterOperators.Equal);
      }
    },
    [selectedCustomerIds, isSelectedAll, customerFilterOperator]
  );

  useEffect(() => {
    const container = document.getElementById("infinity-scroll-list");
    if (!container) return;

    const handleScroll = () => {
      const scrollTop = container.scrollTop + heightDifferenceFromTop;
      const clientHeight = container.clientHeight;
      const scrollHeight = container.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        onLoadMore();
      }
    };

    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <StyledContainer>
      <InputContainer>
        <StyledInput
          data-testid="search-customer-input"
          placeholder="Search..."
          small
          type="search"
          onChange={handleSearchCustomer}
        />
      </InputContainer>
      {customerOptions.length > 0 && (
        <OptionsContainer id="infinity-scroll-list" data-testid="infinity-scroll-list">
          <Checkbox
            data-testid="checkbox-select-all"
            label={"Select All"}
            onChange={handleSelectAll}
            checked={isSelectedAll}
          />
          {customerOptions.map(({customerId, name}) => (
            <Checkbox
              data-testid="checkbox-customer"
              key={customerId}
              label={name}
              checked={
                (isSelectedAll && customerFilterOperator === CustomerFilterOperators.Equal) ||
                (customerFilterOperator === CustomerFilterOperators.Equal &&
                  selectedCustomerIds.includes(customerId)) ||
                (customerFilterOperator === CustomerFilterOperators.NotEqual &&
                  selectedCustomerIds.length > 0 &&
                  !selectedCustomerIds.includes(customerId))
              }
              onChange={() => {
                handleSelectCustomer(customerId);
              }}
            />
          ))}
        </OptionsContainer>
      )}

      <ButtonContainer>
        <Tooltip2 isOpen={isWarningOpen} content={"You can only select up to 10 customers"} placement={"left"}>
          <ApplyButton data-testid="apply-button" intent={Intent.PRIMARY} onClick={onApply}>
            Apply
          </ApplyButton>
        </Tooltip2>

        <ClearButton data-testid="clear-button" onClick={onClear}>
          Reset
        </ClearButton>
      </ButtonContainer>
    </StyledContainer>
  );
});

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 3px;
`;
const OptionsContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
  padding-left: 5px;
`;
const InputContainer = styled.div`
  padding: 4px 6px;
`;
const StyledInput = styled(InputGroup)`
  .bp4-input {
    border-radius: 4px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  padding: 5px 12px;
`;
const ApplyButton = styled(Button)`
  padding: 5px 10px;
`;
const ClearButton = styled(Button)`
  padding: 5px 10px;
`;
