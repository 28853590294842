import {PopoverPosition, Text} from "@blueprintjs/core";
import {Tooltip2} from "@blueprintjs/popover2";
import styled from "@emotion/styled";
import {ICellRendererParams} from "@ag-grid-community/core";
import React, {CSSProperties} from "react";

const Cell = styled.div`
  display: flex;
  height: 100%;

  flex-direction: column;
  justify-content: center;
  line-height: normal;
`;

type DoubleFieldCellValue = {
  upperField: FieldProps;
  lowerField: FieldProps;
};

type FieldProps = {
  value: string;
  tooltip?: string;
  style?: CSSProperties;
};

const DoubleFieldCellRenderer = (props: ICellRendererParams<any, DoubleFieldCellValue>) => {
  return StackedFieldCellRenderer(props.value);
};

export const StackedFieldCellRenderer = (cellValues: DoubleFieldCellValue) => {
  return (
    <Cell>
      <Tooltip2
        disabled={cellValues.upperField?.tooltip === undefined && cellValues.lowerField?.tooltip === undefined}
        fill
        content={
          <div>
            <div>{cellValues.upperField?.tooltip}</div>
            <div>{cellValues.lowerField?.tooltip}</div>
          </div>
        }
        hoverOpenDelay={600}
        position={PopoverPosition.TOP}
      >
        <div>
          <Text style={cellValues.upperField?.style} ellipsize title={""}>
            {cellValues.upperField.value}
          </Text>
          <Text style={cellValues.lowerField?.style} ellipsize title={""}>
            {cellValues.lowerField.value}
          </Text>
        </div>
      </Tooltip2>
    </Cell>
  );
};

export default DoubleFieldCellRenderer;
export type {DoubleFieldCellValue};
