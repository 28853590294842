import styled from "@emotion/styled";
import {Fragment} from "react";

const HeaderItem = styled.div`
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  background-color: #d9d9d9;
  color: #000000;
  padding: 6px;
  white-space: nowrap;
`;

const ColorizedIndicatorsHeader = () => {
  return (
    <Fragment>
      <HeaderItem>#</HeaderItem>
      <HeaderItem>Status</HeaderItem>
      <HeaderItem>Attributes</HeaderItem>
      <HeaderItem>Time Logic</HeaderItem>
      <HeaderItem>Trigger Event</HeaderItem>
      <HeaderItem>Edit</HeaderItem>
    </Fragment>
  );
};

export default ColorizedIndicatorsHeader;
