import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, InputGroup} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {Tooltip2} from "@blueprintjs/popover2";
import {debounce} from "lodash";

type SearchBarProps = {
  idPrefix: string;
  onSearch: (query: string) => void;
  hasActiveExternalFilters: boolean;
  setSearchExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
  hasBadData?: boolean;
};

const WarningIcon = styled(FontAwesomeIcon)`
  padding-right: 4px;
`;

const Tooltip = styled(Tooltip2)`
  background-color: #d9d9d9;
`;

const SearchContainer = styled.div`
  align-self: center;
`;

type SearchInputGroupProps = {
  inError: boolean;
  hasBadData?: boolean;
};

const SearchInputGroup = styled(InputGroup)<SearchInputGroupProps>`
  .bp4-input {
    box-shadow: ${(props) =>
      props.inError
        ? "0 0 0 0 rgba(250, 84, 94, 0), 0 0 0 0 rgba(250, 84, 94, 0), inset 0 0 0 1px rgba(250, 84, 94, 0.2), inset 0 1px 1px rgba(250, 84, 94, 0.5)"
        : "0 0 0 0 rgba(45, 114, 210, 0), 0 0 0 0 rgba(45, 114, 210, 0), inset 0 0 0 1px rgba(17, 20, 24, 0.2), inset 0 1px 1px rgba(17, 20, 24, 0.5)"};
    min-width: ${(props) => props.hasBadData && "65px"};
  }

  .bp4-input-left-container {
    height: 100%;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 4px;
  }

  .bp4-input-action {
    height: 100%;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    .bp4-button {
      min-width: 20px;
      min-height: 20px;
      padding: 4px;
      margin: 0;
    }
  }
`;

const SearchBar = ({idPrefix, onSearch, hasActiveExternalFilters, setSearchExpanded, hasBadData}: SearchBarProps) => {
  const [query, setQuery] = useState<string>("");
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const [showActiveFilters, setShowActiveFilters] = useState<boolean>(false);
  const [showMinimumChars, setShowMinimumChars] = useState<boolean>(false);
  const [showInvalidChars, setShowInvalidChars] = useState<boolean>(false);

  const queryMeetsLengthRequirement = (queryString: string) => {
    return queryString.length === 0 || queryString.length >= 2;
  };

  const handleQueryChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const queryString = event.target.value;
    setShowMinimumChars(!queryMeetsLengthRequirement(queryString));
    setQuery(queryString);
  }, []);

  const debouncedChangeHandler = useMemo(() => debounce(handleQueryChange, 1000), [handleQueryChange]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setShowMinimumChars(false);
      setShowInvalidChars(false);
      debouncedChangeHandler.flush();
      return;
    }
    if (!/[\w\s-.]/.test(event.key)) {
      setShowInvalidChars(true);
      event.preventDefault();
    } else {
      setShowInvalidChars(false);
      setShowMinimumChars(false);
    }
  };

  const handleExpandSearchBar = () => {
    setExpanded(true);
    setSearchExpanded?.(true);
    setShowActiveFilters(hasActiveExternalFilters);
  };

  const handleCloseSearchBar = () => {
    setExpanded(false);
    setSearchExpanded?.(false);
    debouncedChangeHandler.cancel();
    setQuery("");
  };

  useEffect(() => {
    if (queryMeetsLengthRequirement(query)) {
      onSearch(query);
    }
  }, [onSearch, query]);

  useEffect(() => {
    setShowActiveFilters(hasActiveExternalFilters);
  }, [hasActiveExternalFilters]);

  const hasWarnings = (): boolean => {
    return showInvalidChars || showMinimumChars || showActiveFilters;
  };

  const isErrorState = (): boolean => {
    return showInvalidChars || showMinimumChars;
  };

  return (
    <SearchContainer>
      {!isExpanded ? (
        <Button
          minimal
          data-testid={`${idPrefix}-search-btn`}
          type={"button"}
          title={"Search"}
          onClick={handleExpandSearchBar}
          icon={<FontAwesomeIcon icon={solid("magnifying-glass")} />}
        />
      ) : (
        <Tooltip
          isOpen={hasWarnings()}
          portalClassName={isErrorState() ? `${idPrefix}-searchbar-error` : `${idPrefix}-searchbar-warning`}
          content={
            <>
              {showActiveFilters && !showInvalidChars && !showMinimumChars && (
                <Fragment>
                  <WarningIcon color="#FF6B00" icon={solid("circle-exclamation")} />
                  <span>Search is constrained by filters</span>
                </Fragment>
              )}
              {showMinimumChars && !showInvalidChars && (
                <Fragment>
                  <WarningIcon
                    data-testid={`${idPrefix}-search-length-warning`}
                    color="#FA545E"
                    icon={solid("triangle-exclamation")}
                  />
                  <span>Search must contain at least 2 characters</span>
                </Fragment>
              )}
              {showInvalidChars && (
                <Fragment>
                  <WarningIcon
                    data-testid={`${idPrefix}-search-allowed-character-warning`}
                    color="#FA545E"
                    icon={solid("triangle-exclamation")}
                  />
                  <span>Search is limited to alpha-numerics, spaces, dashes, dots, and underscores</span>
                </Fragment>
              )}
            </>
          }
          position="top-left"
        >
          <SearchInputGroup
            hasBadData={hasBadData}
            data-testid={`${idPrefix}-search-input`}
            autoFocus={true}
            inError={isErrorState()}
            leftElement={<FontAwesomeIcon size="sm" color="#5f6b7c" icon={solid("magnifying-glass")} />}
            rightElement={
              <Button
                minimal
                data-testid={`${idPrefix}-search-close-btn`}
                type={"button"}
                title={"Close"}
                onClick={handleCloseSearchBar}
                icon={<FontAwesomeIcon size="1x" icon={solid("times")} />}
              />
            }
            onChange={debouncedChangeHandler}
            onKeyDown={handleKeyDown}
          />
        </Tooltip>
      )}
    </SearchContainer>
  );
};

export default SearchBar;
