import {Tag, Text} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {ICellRendererParams} from "@ag-grid-community/core";
import React from "react";
import {createAvatarComponent, GoogleSource, GravatarSource, IconSource, ValueSource} from "react-avatar";

const Cell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const UserInfoContainer = styled.div`
  min-width: 0;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;

  min-width: 0;
  line-height: normal;
  padding-left: 14px;
`;

const EmailText = styled(Text)`
  line-height: normal;
  padding-left: 14px;
  font-weight: lighter;
`;

const RoleTag = styled(Tag)`
  padding: 2px;
  margin-left: 2px;
`;

const CustomAvatar = createAvatarComponent({
  sources: [GravatarSource, ValueSource, IconSource, GoogleSource]
});

const Avatar = styled(CustomAvatar)`
  min-width: 36px;
`;

const UserCellRenderer = (props: ICellRendererParams<any, number>) => {
  const fullName = `${props.data.profile.firstName} ${props.data.profile.lastName}`;

  return (
    <Cell data-testid={`user-name-cell-${props.data.profile.id}`}>
      <Avatar name={fullName} email={props.data.profile.email} round={true} size="36" textSizeRatio={2} />
      <UserInfoContainer>
        <NameContainer>
          <Text ellipsize={true}>{fullName}</Text>
          {props.data.isDispatchAdmin && (
            <RoleTag title={"Dispatch Super Admin"} minimal intent={"danger"}>
              Dispatch Super Admin
            </RoleTag>
          )}
          {props.data.isDispatchTenantAdmin && !props.data.isDispatchAdmin && (
            <RoleTag title={"Dispatch Admin"} minimal intent={"primary"}>
              Dispatch Admin
            </RoleTag>
          )}
        </NameContainer>
        <EmailText ellipsize={true}>{props.data.profile.email}</EmailText>
      </UserInfoContainer>
    </Cell>
  );
};

export default UserCellRenderer;
