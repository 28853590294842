import {Dialog} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {forwardRef, useImperativeHandle, useState} from "react";
import {LoadingSpinner} from "../../../common/LoadingSpinner";
import {Constants} from "../../../common/Constants";
interface OptimizeSpinerProps {
  size: number;
}
export interface OptimizeSpinerRefs {
  handleCloseSpinner: () => void;
  handleOpenSpinner: () => void;
}
export const SIZE_OF_OPTIMIZED_SPINNER = 100;
const distanceFromLeftOptimizeSpinner = () => {
  const widthOfManifestDrawer = (window.innerWidth * Constants.MANIFEST_DRAWER_RATIO_WIDTH) / 100;
  return (widthOfManifestDrawer - SIZE_OF_OPTIMIZED_SPINNER) / 2;
};
export const OptimizeSpiner = forwardRef<OptimizeSpinerRefs, OptimizeSpinerProps>(
  ({size}: OptimizeSpinerProps, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleOpenSpinner = () => {
      setIsOpen(true);
    };
    const handleCloseSpinner = () => {
      setIsOpen(false);
    };
    useImperativeHandle(ref, () => ({
      handleOpenSpinner,
      handleCloseSpinner
    }));
    return (
      <SpinerContainer
        data-testid="spiner-container"
        isOpen={isOpen}
        onClose={handleCloseSpinner}
        distanceFromLeft={distanceFromLeftOptimizeSpinner()}
      >
        <div data-testid="spiner-loading">
          <LoadingSpinner size={size} />
        </div>
      </SpinerContainer>
    );
  }
);
OptimizeSpiner.displayName = "OptimizeSpiner";
interface SpinnerContainerProps {
  distanceFromLeft: number;
}
const SpinerContainer = styled(Dialog)<SpinnerContainerProps>`
  position: absolute;
  left: ${(props) => props.distanceFromLeft && `${props.distanceFromLeft}px`};
  width: fit-content;
  height: fit-content;
  border: none;
  background-color: transparent;
  box-shadow: none;
`;
