export enum JobTypes {
  UnassignedJobs = "UNASSIGNEDJOBS",
  AssignedStops = "ASSIGNEDSTOPS"
}

export enum StopTypes {
  Pickup = "PICKUP",
  Delivery = "DELIVERY",
  Both = "BOTH",
  DeliveryOrBoth = "DELIVERYORBOTH",
  PickupOrBoth = "PICKUPORBOTH"
}

export type JobTypeSelectorValues = {
  jobTypeValue?: JobTypes;
  stopTypeValue?: StopTypes;
};

export enum StopStatus {
  Active = "A",
  Queued = "Q"
}
