import {Button, InputGroup, Menu, MenuDivider, MenuItem, Tag} from "@blueprintjs/core";
import {Popover2, Classes} from "@blueprintjs/popover2";
import styled from "@emotion/styled";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import {TextClearFilter} from "../job/TimeQuickFilterSelect";

type MultiSelectDropDownItem = {
  id: number;
  name: string;
  selected: boolean;
};

const HiddenFAIcon = styled(FontAwesomeIcon)`
  visibility: hidden;
`;

const GroupTab = styled(Tag)`
  margin: 1px;
`;

const SearchBox = styled(InputGroup)`
  margin-bottom: 2px;

  .bp4-input {
    box-shadow: none;
  }
`;

const ItemMenu = styled(Menu)`
  max-width: 200px;

  .bp4-menu-divider {
    margin-left: 0;
    margin-right: 0;
  }
`;

const UnSelectedItemsListContainer = styled.div`
  max-height: 450px;
  overflow-y: auto;
`;

const SelectedCountLabel = styled.span`
  color: #2d72d2;
  margin-left: 2px;
  font-weight: 500;
`;

type MultiSelectDropDownProps = {
  text: string;
  items: Map<number, MultiSelectDropDownItem>;
  manifestHeaderUi?: boolean;
  testIdPrefix?: string;
  clearFilter?: boolean;
  onSelected(key: number): void;
  onUnselected(key: number): void;
  onUnselectedAll?(): void;
};
const MultiSelectDropDown = ({
  text,
  items,
  manifestHeaderUi = true,
  testIdPrefix,
  clearFilter,
  onSelected,
  onUnselected,
  onUnselectedAll
}: MultiSelectDropDownProps) => {
  const [map, setMap] = useState<Map<number, MultiSelectDropDownItem>>(new Map());
  const [filter, setFilter] = useState<string>("");

  const selectedItems = [...map.keys()].filter((value) => map.get(value)?.selected);
  const unSelectedItems = [...map.keys()].filter((value) => !map.get(value)?.selected);

  useEffect(() => {
    setMap(items);
  }, [items]);

  const updateSelected = (itemId: number) => {
    const item = map.get(itemId);
    map.set(itemId, {
      ...item,
      selected: !item!.selected
    } as MultiSelectDropDownItem);

    setMap(new Map(map.entries()));
  };

  const unSelectItem = (itemId: number) => {
    updateSelected(itemId);
    onUnselected(itemId);
  };

  const selectItem = (itemId: number) => {
    updateSelected(itemId);
    onSelected(itemId);
  };

  const updateFilter = (value: string) => {
    setFilter(value);
  };

  const unSelectAll = () => {
    setFilter("");
    map.forEach((value, key) => {
      if (value.selected) {
        updateSelected(key);
      }
    });
    if (onUnselectedAll) onUnselectedAll();
  };

  return (
    <Popover2
      content={
        <>
          <ItemMenu data-testid={`${testIdPrefix}-item-menu`}>
            <SearchBox
              leftIcon={"search"}
              placeholder="Search for a group"
              onChange={(value) => updateFilter(value.target.value)}
              value={filter}
              rightElement={filter ? <Button icon={"cross"} minimal onClick={() => updateFilter("")} /> : undefined}
            />
            <MenuDivider></MenuDivider>
            {selectedItems.map((k) => (
              <GroupTab role={"selected-menuitem"} key={k} intent={"primary"} minimal onRemove={() => unSelectItem(k)}>
                {map.get(k)?.name}
              </GroupTab>
            ))}
            {selectedItems.length > 0 && <MenuDivider></MenuDivider>}
            <UnSelectedItemsListContainer>
              {unSelectedItems
                .filter((value) => map.get(value)?.name.toLowerCase().includes(filter.toLowerCase()))
                .map((k) => (
                  <MenuItem
                    key={k}
                    text={map.get(k)?.name}
                    intent={"none"}
                    icon={<HiddenFAIcon icon={solid("add")} />}
                    onClick={(e) => {
                      selectItem(k);
                      e.stopPropagation();
                    }}
                  ></MenuItem>
                ))}
            </UnSelectedItemsListContainer>
          </ItemMenu>
          {clearFilter && selectedItems.length > 0 && (
            <Footer>
              <TextClearFilter
                data-testid="clear-filter-multi-select"
                className={Classes.POPOVER2_DISMISS}
                onClick={unSelectAll}
              >
                Clear Filter <TextRed>({selectedItems.length})</TextRed>
              </TextClearFilter>
            </Footer>
          )}
        </>
      }
      position={"bottom"}
    >
      {manifestHeaderUi ? (
        <StyledButton
          data-testid={`${testIdPrefix}-button`}
          hasValue={Boolean(selectedItems.length > 0)}
          text={`${selectedItems.length === 0 ? text : `${selectedItems.length} ${text}`}`}
          rightIcon="chevron-down"
        />
      ) : (
        <Button rightIcon={"caret-down"}>
          data-testid={`${testIdPrefix}-button`}
          {text}
          {selectedItems.length > 0 && <SelectedCountLabel>({selectedItems.length})</SelectedCountLabel>}
        </Button>
      )}
    </Popover2>
  );
};

export default MultiSelectDropDown;
export type {MultiSelectDropDownItem};

export const StyledButton = styled(Button)<{hasValue: boolean}>`
  position: relative;
  background: ${(props) => (props.hasValue ? "#8da4be !important" : "#ffffff !important")};
  color: ${(props) => (props.hasValue ? "#ffffff !important" : "#8DA4BE !important")};
  box-shadow: 0px 1px 1px 0px #00000040 !important;
  padding: 6px 10px 6px 10px !important;
  border-radius: 4px !important;
  white-space: nowrap;
  :hover {
    opacity: 0.8;
    background: ${(props) => (props.hasValue ? "" : "#edeff2 !important")};
  }

  .bp4-button-text {
    display: flex;
    flex-direction: row;
    align-items: end;
  }

  .bp4-icon {
    color: ${(props) => (props.hasValue ? "#ffffff" : "#8DA4BE")};
  }
`;

const TextRed = styled.p`
  display: inline;
  color: #fa545e;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Footer = styled.div`
  background: #fcfcfc;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
  padding: 10px;
`;
