import {ItemRenderer, Select2} from "@blueprintjs/select";
import {InputGroup, Menu, MenuDivider, MenuItem, Tag, Text} from "@blueprintjs/core";
import {SyntheticEvent, useCallback, useContext, useEffect, useState} from "react";
import {endOfMinute, startOfMinute, subHours, subMinutes} from "date-fns";
import {Classes, Popover2} from "@blueprintjs/popover2";
import styled from "@emotion/styled";
import "./TimeQuickFilterSelect.css";
import {StyledButton} from "../common/MultiSelectDropDown";
import {Constants, UserPreferences} from "../common/Constants";
import {OKButton} from "../manifest/details/components/TimePickerCommon";
import {isEqual} from "lodash";
import {PreferenceContext, extractJsonPref} from "../../providers/PreferenceProvider";
import {
  InitialTimeFilterCustomValue,
  TTimeFilterCustomValue,
  TimeFilterTitle,
  TimeFilterTitles,
  isCustomOption
} from "./JobPanel";

export interface TimeOption {
  type?: TimeFilterTitle;
  title: string;
  isClearFilter?: boolean;
  getFilterValue(customValue?: number): any;
  customValue?: number;
}

export const getTimeOptionFilter = (option?: TimeOption) => {
  return option
    ? {
        filterType: "date",
        type: "inRange",
        value: isCustomOption(option) ? option?.getFilterValue(option.customValue) : option?.getFilterValue()
      }
    : {};
};

export const placedInLastOptions = [
  {
    title: "30min",
    getFilterValue(): any {
      return [startOfMinute(subMinutes(new Date(), 30)), endOfMinute(new Date())];
    }
  },
  {
    title: "1hr",
    getFilterValue(): any {
      return [startOfMinute(subHours(new Date(), 1)), endOfMinute(new Date())];
    }
  },
  {
    title: "1hr 30min",
    getFilterValue(): any {
      return [startOfMinute(subMinutes(new Date(), 90)), endOfMinute(new Date())];
    }
  },
  {
    title: "2hr",
    getFilterValue(): any {
      return [startOfMinute(subHours(new Date(), 2)), endOfMinute(new Date())];
    }
  },
  {
    title: "2hr 30min",
    getFilterValue(): any {
      return [startOfMinute(subMinutes(new Date(), 150)), endOfMinute(new Date())];
    }
  },
  {
    title: "3hr",
    getFilterValue(): any {
      return [startOfMinute(subHours(new Date(), 3)), endOfMinute(new Date())];
    }
  },
  {
    title: "Clear Filter",
    isClearFilter: true,
    getFilterValue(): any {
      return [];
    }
  }
];

type TimeQuickFilterSelectProps = {
  title: string;
  options: TimeOption[];
  selectedOption?: TimeOption;
  onSelectedOption(item: TimeOption, event?: SyntheticEvent<HTMLElement, Event> | undefined): void;
  onClearFilter(): void;
};

export const TimeQuickFilterSelect = ({
  title,
  options,
  selectedOption,
  onSelectedOption,
  onClearFilter
}: TimeQuickFilterSelectProps) => {
  const renderFilm: ItemRenderer<TimeOption> = (option, {handleClick, handleFocus, modifiers}) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    if (option.isClearFilter) {
      return (
        <Footer>
          <TextClearFilter
            data-testid="clear-filter-placed-in-last"
            className={Classes.POPOVER2_DISMISS}
            onClick={onClearFilter}
          >
            Clear Filter
          </TextClearFilter>
        </Footer>
      );
    }
    return (
      <MenuItem
        data-testid={`time-select-${option.title}`}
        active={selectedOption?.title === option.title}
        disabled={modifiers.disabled}
        icon={selectedOption?.title === option.title ? "tick" : "blank"}
        key={option.title}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={option.title}
      />
    );
  };

  return (
    <Select2<TimeOption>
      popoverProps={{
        popoverClassName: "time-quick-filter-select"
      }}
      items={options}
      filterable={false}
      itemRenderer={renderFilm}
      onItemSelect={onSelectedOption}
    >
      <StyledButton
        data-testid={`${title}-btn`}
        text={`${title} ${selectedOption?.title ?? ""}`}
        hasValue={!!selectedOption}
        rightIcon="caret-down"
      />
    </Select2>
  );
};

interface TimePickerWithPopoverProps {
  title: string;
  options: TimeOption[];
  selectedOption: TimeOption | undefined;
  onConfirm: (option: TimeOption) => void;
  onClear(): void;
}

export const TimePickerFilterPopover = ({
  title,
  options,
  selectedOption,
  onConfirm,
  onClear
}: TimePickerWithPopoverProps) => {
  const {userPreferences} = useContext(PreferenceContext);
  const timeFilterCustomValue = extractJsonPref(
    userPreferences,
    UserPreferences.jobGridFilterReadyDueCustomValue,
    InitialTimeFilterCustomValue
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectingOption, setSelectingOption] = useState<TimeOption | undefined>(selectedOption);
  const [customValue, setCustomValue] = useState<TTimeFilterCustomValue>({
    ...InitialTimeFilterCustomValue,
    ...timeFilterCustomValue.value
  });

  const onChangeCustomInput = useCallback((value, type: TimeFilterTitle) => {
    setCustomValue((prev) => {
      return {...prev, [type]: value};
    });
  }, []);

  const renderTitle = useCallback(() => {
    return title.includes("Now") ? title.replace("in Next", "") : title;
  }, [title]);

  const onConfirmFilter = useCallback(() => {
    if (!isEqual(selectingOption, selectedOption)) {
      onConfirm(selectingOption!);
      setIsOpen(false);
    }
  }, [onConfirm, selectedOption, selectingOption]);

  const onClearFilter = useCallback(() => {
    onClear();
    setIsOpen(false);
  }, [onClear]);

  const onPressEnter = useCallback(
    (e) => {
      if (e.key === "Enter" || e.keyCode === 13) {
        if ((customValue.ready as any) === "" || (customValue.due as any) === "") {
          e.stopPropagation();
          return;
        }
        onConfirmFilter();
      }
    },
    [customValue.due, customValue.ready, onConfirmFilter]
  );

  useEffect(() => {
    if (selectingOption && isCustomOption(selectingOption)) {
      const customOption: TimeOption = {
        ...selectingOption,
        customValue: customValue[selectingOption.type as TimeFilterTitle]
      };
      setSelectingOption(customOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customValue]);

  useEffect(() => {
    if (!isOpen) {
      if (selectedOption && isCustomOption(selectedOption)) {
        setCustomValue({
          ...InitialTimeFilterCustomValue,
          ...timeFilterCustomValue.value,
          [selectedOption?.type as string]: selectedOption?.customValue
        });
      } else {
        setCustomValue({
          ...InitialTimeFilterCustomValue,
          ...timeFilterCustomValue.value
        });
      }
      setSelectingOption(selectedOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, selectedOption]);

  const renderMenuPicker = TimeFilterTitles.map((title) => (
    <TimePickerMenu
      key={title}
      titleDivider={title}
      options={options.filter((o) => o.type === title)}
      selectingOption={selectingOption}
      onSelectOption={setSelectingOption}
      customInputValue={customValue[title]}
      onChangeCustomInput={(value) => onChangeCustomInput(value, title)}
    />
  ));

  return (
    <Popover2
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      canEscapeKeyClose
      content={
        <TimePickerContainer onKeyDown={onPressEnter}>
          <Body>
            <TimePickerMenuCustom>{renderMenuPicker}</TimePickerMenuCustom>
          </Body>
          <Footer>
            <OKButton
              data-testid="confirm-filter-time"
              style={{fontSize: "14px"}}
              active={!isEqual(selectingOption, selectedOption)}
              onClick={onConfirmFilter}
            >
              OK
            </OKButton>
            <TextClearFilter
              data-testid="clear-filter-ready-due"
              className={Classes.POPOVER2_DISMISS}
              onClick={onClearFilter}
            >
              Clear Filter
            </TextClearFilter>
          </Footer>
        </TimePickerContainer>
      }
      placement="bottom-start"
      popoverClassName="time-picker-popover"
      minimal
    >
      <StyledButton
        data-testid={`${renderTitle()}-btn`}
        alignText="left"
        text={renderTitle()}
        rightIcon="caret-down"
        hasValue={Boolean(selectedOption)}
        onClick={() => setIsOpen((prev) => !prev)}
      />
    </Popover2>
  );
};
interface TimePickerMenuProps {
  titleDivider: string;
  options: TimeOption[];
  selectingOption: TimeOption | undefined;
  customInputValue: number;
  onSelectOption: React.Dispatch<React.SetStateAction<TimeOption | undefined>>;
  onChangeCustomInput: (value: number | string) => void;
}

const TimePickerMenu = ({
  titleDivider,
  options,
  selectingOption,
  customInputValue,
  onChangeCustomInput,
  onSelectOption
}: TimePickerMenuProps) => {
  const isMenuItemActive = useCallback(
    (option: TimeOption) => {
      if (!selectingOption) return false;
      return selectingOption.title === option.title && selectingOption.type === option.type;
    },
    [selectingOption]
  );

  const renderIcon = useCallback(
    (option: TimeOption) => {
      return isMenuItemActive(option) ? "tick" : "blank";
    },
    [isMenuItemActive]
  );

  const renderMenuItems = () =>
    options.map((option) => {
      if (isCustomOption(option)) {
        return (
          <MenuItem
            key={option.title}
            data-testid={`${option.title}-selection`}
            onClick={(e) => {
              e.stopPropagation();
              onSelectOption({...option, customValue: customInputValue});
            }}
            selected={isMenuItemActive(option)}
            icon={renderIcon(option)}
            text={
              <CustomTimeFilterContainer>
                <TextGray>In Next</TextGray>
                <StyledInput
                  data-testid="custom-time-input"
                  title=""
                  type="number"
                  min={0}
                  max={99}
                  step={0.1}
                  value={customInputValue.toString()}
                  rightElement={<TimeTag style={{color: "#161616"}}>hr</TimeTag>}
                  onBlur={(e) => {
                    onChangeCustomInput(parseFloat(Number(e.target.value).toFixed(2)));
                  }}
                  onChange={(e) => {
                    if (Number(e.target.value) <= Constants.MAX_HOUR_JOBS_FILTER) {
                      onChangeCustomInput(e.target.value.trim().replaceAll("-", ""));
                    }
                  }}
                />
              </CustomTimeFilterContainer>
            }
          />
        );
      }

      return (
        <MenuItem
          data-testid={`${option.title}-selection`}
          key={option.title}
          onClick={(e) => {
            e.stopPropagation();
            onSelectOption(option);
          }}
          selected={isMenuItemActive(option)}
          icon={renderIcon(option)}
          text={
            <MenuItemContainer>
              {option.title === "Now" ? (
                <TextBlack>{option.title}</TextBlack>
              ) : (
                <>
                  <TextGray>In Next</TextGray> <TextBlack>{option.title}</TextBlack>
                </>
              )}
            </MenuItemContainer>
          }
        />
      );
    });

  return (
    <>
      <MenuDivider data-testid="time-menu-divider" title={<TextGray>{titleDivider}</TextGray>} />
      {renderMenuItems()}
    </>
  );
};

const TextGray = styled(Text)`
  text-transform: capitalize;
  font-size: 14px;
  color: rgb(106, 106, 106);
`;
const TextBlack = styled(Text)`
  font-size: 14px;
  color: black;
`;
const MenuItemContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 4px;
`;
const TimePickerMenuCustom = styled(Menu)`
  &.bp4-menu {
    max-height: 410px;
    overflow-y: scroll;
  }
  &.bp4-menu::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &.bp4-menu::-webkit-scrollbar {
    width: 8px;
    background-color: #d9d9d9;
  }

  &.bp4-menu::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 30px;
    background-color: #d9d9d9;
  }
  &.bp4-menu-item {
    padding-left: 15px;
  }
  &.bp4-menu.bp4-menu-header {
    font-weight: 100;
  }
`;

const TimePickerContainer = styled.div``;

const Body = styled.div`
  padding: 4px 3px 0;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fcfcfc;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
  padding: 10px;
`;

export const TextClearFilter = styled(Text)`
  color: #14305a;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  text-align: right;
`;

const CustomTimeFilterContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const StyledInput = styled(InputGroup)`
  display: flex;
  align-items: center;

  .bp4-input {
    width: 60px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #c3c3c373;
    box-shadow: none;
    padding: 4px;

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const TimeTag = styled(Tag)`
  background: none !important;
  margin: 0 !important;
  padding: 0 2px !important;
`;
