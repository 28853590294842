import styled from "@emotion/styled";
import {Button, Dialog, DialogBody, DialogFooter, Text} from "@blueprintjs/core";
import {Fragment} from "react";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {JobAssignmentState} from "../../../common/JobAssignmentReducer";
import "./AssignJobsConfirmation.css";
import {DriverRequirementEnforcementLevel} from "../../../settings/types/DriverRequirementEnforcementLevel";

const DialogBox = styled(Dialog)`
  box-sizing: border-box;
  background: #fcfcfc;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  width: 390px;
  &.prevent {
    background: #ffdee0;
    .bp4-dialog-header {
      background: #ffdee0;
    }
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 13px;
  padding-top: 13px;
`;

const Title = styled(Text)`
  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-left: 19px;
  color: #161616;
`;

const HeaderIcon = styled(FontAwesomeIcon)``;

const Body = styled(DialogBody)`
  background: transparent;
  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.51px;

  padding: 9px 28px 14px;
`;

const BodyText = styled(Text)`
  color: #161616;
  &.body-text-bottom {
    padding-right: 4px;
  }
`;

const BodyTextCenter = styled(BodyText)`
  padding: 24px 0;
`;

const TextDetail = styled.div`
  padding-bottom: 4px;
  &:last-child {
    padding-bottom: 0;
  }
`;

const JobIdText = styled(Text)`
  color: #0000ee;
  display: inline;
`;

const RequireMentText = styled(Text)`
  color: #fa545e;
  display: inline;
  font-style: italic;
`;

const Footer = styled(DialogFooter)`
  background: transparent;
  border-radius: 0 0 0 0;
  border-top: 0;
  padding: 0 28px 18px;
`;

const ConfirmButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 14px;
  background: #ffffff !important;
  box-shadow: 0px 2px 2px 0px #00000040 !important;
  border-radius: 4px;
  border: none;
  outline: none;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  &.button-no {
    color: #161616;
  }
  &:hover {
    background: rgba(143, 153, 168, 0.15) !important;
  }
`;

export interface AssignJobsConfirmationProps {
  testIdPrefix: string;
  isVisible: boolean;
  driverRequiredSetting: string;
  assignmentState: JobAssignmentState;
  handleConfirm(): void;
  handleClose(): void;
}

export const AssignJobsConfirmation = ({
  testIdPrefix,
  isVisible,
  assignmentState,
  driverRequiredSetting,
  handleConfirm,
  handleClose
}: AssignJobsConfirmationProps) => {
  const actions =
    driverRequiredSetting === DriverRequirementEnforcementLevel.Warning ? (
      <Fragment>
        <ConfirmButton
          className="button-no"
          data-testid={`${testIdPrefix}-dialog-no-button`}
          text={"No"}
          onClick={handleClose}
        />
        <ConfirmButton
          data-testid={`${testIdPrefix}-dialog-yes-button`}
          text={"Yes, Assign"}
          style={{color: "#fa545e"}}
          onClick={handleConfirm}
        />
      </Fragment>
    ) : (
      <ConfirmButton
        className="button-no"
        data-testid={`${testIdPrefix}-dialog-no-button`}
        text={"Close"}
        onClick={handleClose}
      />
    );

  const title =
    driverRequiredSetting === DriverRequirementEnforcementLevel.Warning ? (
      <Header>
        <HeaderIcon data-testid={"header-icon"} color={"#FF6B00"} size={"xl"} icon={solid("circle-exclamation")} />
        <Title data-testid={"header-title"}>{`Assign to ${assignmentState?.selectedManifest?.driver?.name}?`}</Title>
      </Header>
    ) : (
      <Header>
        <HeaderIcon data-testid={"header-icon"} color={"#FA545E"} size={"xl"} icon={solid("triangle-exclamation")} />
        <Title data-testid={"header-title"}>Can&apos;t Assign to This Driver</Title>
      </Header>
    );

  const bodyTextCenter = assignmentState.selectedJobs
    .filter((job) => job?.order?.requirements)
    .map((job) => {
      const orderRequirements = job?.order?.requirements?.map((requirement) => requirement.name) ?? [];
      const length = orderRequirements.length - 1;
      const requirements = orderRequirements.map((requirement, index) => {
        if (index !== length) {
          return (
            <>
              <RequireMentText>&apos;{requirement}&apos;</RequireMentText>;{" "}
            </>
          );
        }
        return <RequireMentText key={requirement}>&apos;{requirement}&apos;</RequireMentText>;
      });
      return (
        <TextDetail key={job.jobId}>
          Job <JobIdText>{job.jobId}</JobIdText> - {requirements}
        </TextDetail>
      );
    });

  const bodyTextBottom =
    driverRequiredSetting === DriverRequirementEnforcementLevel.Warning
      ? "Would you like to proceed with assignment anyway?"
      : "If you feel this is by mistake, please navigate to 'Driver Information' within CMS to update this driver's record";

  return (
    <DialogBox
      data-testid={`${testIdPrefix}-dialog-box`}
      title={title}
      isOpen={isVisible}
      onClose={handleClose}
      className={`assign-jobs-confirmation-dialog ${driverRequiredSetting.toLowerCase()}`}
    >
      <Body>
        <BodyText>
          The job(s) have requirement(s) that the selected driver does not have based on our system&apos;s record.
        </BodyText>
        <BodyTextCenter>{bodyTextCenter}</BodyTextCenter>
        <BodyText data-testid={"body-text-bottom"} className="body-text-bottom">
          {bodyTextBottom}
        </BodyText>
      </Body>
      <Footer actions={actions}></Footer>
    </DialogBox>
  );
};
