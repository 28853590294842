import {Intent, Position, Text, Toaster} from "@blueprintjs/core";
import styled from "@emotion/styled";
import "./qualified_drivers_toaster.css";

const ToasterTopLeft = Toaster.create({
  position: Position.TOP_LEFT,
  maxToasts: 3
});

const ToastContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 390px;
  height: 194px;
  font-family: "Roboto", sans-serif;
  padding: 18px 28px 20px;
  border-radius: 4px;
`;

const ToastHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-break: 20px;
  color: #161616;
  margin-left: 19px;
`;

const ToastBody = styled.div``;

const ToastBodyText = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #161616;
  &:first-child {
    margin-bottom: 20px;
  }
`;

const ToastFooter = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  width: 64px;
  height: 28px;
  border-radius: 4px;
  padding: 6px 14px;
  box-shadow: 0px 2px 2px 0px #00000040;
  background-color: #fff !important;
  color: #161616;
  padding: 0;
  outline: none;
  border: none;
  &:hover {
    cursor: pointer;
    background-color: #ff8d23 !important;
  }
`;

const ExclamationIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <g clipPath="url(#clip0_284_30468)">
        <path
          d="M11 21.5686C13.9174 21.5686 16.7153 20.4324 18.7782 18.41C20.8411 16.3875 22 13.6445 22 10.7843C22 7.92414 20.8411 5.1811 18.7782 3.15865C16.7153 1.1362 13.9174 0 11 0C8.08262 0 5.28473 1.1362 3.22183 3.15865C1.15893 5.1811 0 7.92414 0 10.7843C0 13.6445 1.15893 16.3875 3.22183 18.41C5.28473 20.4324 8.08262 21.5686 11 21.5686ZM11 5.39216C11.5715 5.39216 12.0312 5.84291 12.0312 6.40319V11.1213C12.0312 11.6816 11.5715 12.1324 11 12.1324C10.4285 12.1324 9.96875 11.6816 9.96875 11.1213V6.40319C9.96875 5.84291 10.4285 5.39216 11 5.39216ZM9.625 14.8284C9.625 14.4709 9.76987 14.128 10.0277 13.8752C10.2856 13.6224 10.6353 13.4804 11 13.4804C11.3647 13.4804 11.7144 13.6224 11.9723 13.8752C12.2301 14.128 12.375 14.4709 12.375 14.8284C12.375 15.186 12.2301 15.5288 11.9723 15.7816C11.7144 16.0344 11.3647 16.1765 11 16.1765C10.6353 16.1765 10.2856 16.0344 10.0277 15.7816C9.76987 15.5288 9.625 15.186 9.625 14.8284Z"
          fill="#FF8D23"
        />
      </g>
      <defs>
        <clipPath id="clip0_284_30468">
          <rect width="22" height="21.5686" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const WarningToast = () => {
  return (
    <ToastContainer data-testid={"warning-toast"}>
      <ToastHeader>
        <ExclamationIcon />
        <Title>No Qualified Drivers Available</Title>
      </ToastHeader>
      <ToastBody>
        <ToastBodyText>
          To update individual driver information, navigate to the &apos;Driver Information&apos; section of CMS.
        </ToastBodyText>
        <ToastBodyText>If you don&apos;t have access to this area, please contact your supervisor</ToastBodyText>
      </ToastBody>
      <ToastFooter>
        <CloseButton data-testid={"close-warning-toast"} onClick={() => ToasterTopLeft.clear()}>
          Close
        </CloseButton>
      </ToastFooter>
    </ToastContainer>
  );
};

export const showQualifiedDriversWarningToast = () => {
  ToasterTopLeft.show({
    intent: Intent.WARNING,
    message: <WarningToast />,
    className: "warning-toast",
    timeout: 5000
  });
};
