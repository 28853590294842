/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import {getColorIndicator, ManifestCardListProps, ManifestCardProps} from "../ManifestCardCommon";
import {JobAssignment, CardType} from "../../../views/JobAssignmentView";
import {Manifest, SearchableSortDirection} from "../../../generated/graphql";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {DragAndDropTargetPayload} from "../../../views/JobAssignmentViewReducer";
import {DispatchRulesDataContext, DispatchRulesDataState} from "../../common/DispatchRulesDataProvider";

import ManifestDriverCardV3 from "../card/ManifestDriverCardV3";
import ManifestDriverMinimumCardV3 from "../minimum-card/ManifestDriverMinimumCardV3";
import {getHighestRankedColor, StopColor} from "../ManifestCardCommonV3";
import {Constants, UserPreferences} from "../../common/Constants";
import {ConditionNames} from "../../settings/ColorizedIndicators/RuleGenerator.service";
import {isCompleted} from "../../../services/ManifestStopService";
import {extractJsonPref, PreferenceContext} from "../../../providers/PreferenceProvider";
import {IConfigurationFormValues} from "../card-configuration/ManifestCardConfiguration";
import {DEFAULT_CARD_CONFIGURATIONS_VALUE} from "../card-configuration/ManifestCardConfigurationDialog";
import {FeatureFlagContext} from "../../../providers/FeatureFlagProvider";
import {getColorPriority} from "../../job/JobPanel";
import ManifestDriverCompactCard from "../compact-card/ManifestDriverCompactCard";
const ManifestDriverCardContainer = styled.span`
  display: flex;
  min-height: 0;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
  padding-top: 2px;
  padding-left: 16px;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
`;

export type GetSortedByColorManifests = {
  data: Manifest[];
  ruleContextState: DispatchRulesDataState;
  stopColors: any;
  direction: SearchableSortDirection;
};

export const getManifestColors = async ({
  manifest,
  ruleContextState
}: {
  manifest: Manifest;
  ruleContextState: DispatchRulesDataState;
}) => {
  const colors: StopColor[] = [];

  //get color of manifest stops
  const stopRules =
    ruleContextState.rules?.filter((rule) => rule.conditions.name !== ConditionNames.MANIFESTONLY) ?? [];
  for (const stop of manifest.stops ?? []) {
    if (!isCompleted(stop)) {
      const engine = ruleContextState.getConfiguredEngine({manifest: manifest, stop: stop}, stopRules);
      if (engine) {
        const color = await getColorIndicator(engine);
        colors.push({color: color, jobStopID: stop.jobStopId});
      }
    }
  }

  //get color if have only manifest status rule {to show in the header of manifest card}
  const onlyManifestRules =
    ruleContextState.rules?.filter((rule) => rule.conditions.name === ConditionNames.MANIFESTONLY) ?? [];
  if (onlyManifestRules.length > 0) {
    const engine = ruleContextState.getConfiguredEngine({manifest: manifest}, onlyManifestRules);
    if (engine) {
      const manifestColor = await getColorIndicator(engine);
      manifestColor && colors.push({color: manifestColor} as StopColor);
    }
  }

  return colors;
};

export const getSortedByColorManifests = ({
  data,
  ruleContextState,
  stopColors,
  direction
}: GetSortedByColorManifests) => {
  const directionValue = direction === SearchableSortDirection.Desc ? -1 : 1;
  const sortedByColorData = [...data].sort((a, b) => {
    const aPriority = getColorPriority(
      ruleContextState.rules,
      getHighestRankedColor(stopColors.get(a.manifestDriverId) ?? [], ruleContextState.colorRank ?? [])
    );
    const bPriority = getColorPriority(
      ruleContextState.rules,
      getHighestRankedColor(stopColors.get(b.manifestDriverId) ?? [], ruleContextState.colorRank ?? [])
    );
    return (aPriority - bPriority) * directionValue;
  });
  return sortedByColorData;
};

const getCardId = (cardType: CardType) => {
  switch (cardType) {
    case CardType.Compact:
      return "manifest-compact-card-item";

    case CardType.Minimal:
      return "manifest-minimal-card-item";

    default:
      return "manifest-detailed-card-item";
  }
};

const getCardComponent = (cardType: CardType) => {
  switch (cardType) {
    case CardType.Compact:
      return ManifestDriverCompactCard;

    case CardType.Minimal:
      return ManifestDriverMinimumCardV3;

    default:
      return ManifestDriverCardV3;
  }
};

const ManifestDriverListV2 = ({
  selectedManifest,
  disabledManifestIds,
  data,
  cardType,
  onSelectedManifest,
  onDrillDownManifest,
  pendingActions,
  handleUpdateDropTargets,
  onLocationMarkerClick,
  sortByColor,
  isManifestDetailsDrawerOpen
}: ManifestCardListProps) => {
  const JobAssignmentContext = useContext(JobAssignment);
  const listRef = useRef<HTMLSpanElement>(null);
  const [stopColors, setStopColors] = useState<any>(new Map<number, StopColor[]>());
  const ruleContextState = useContext<DispatchRulesDataState>(DispatchRulesDataContext);
  const {userPreferences} = useContext(PreferenceContext);
  const {colorSort: colorSortEnable} = useContext(FeatureFlagContext);

  const configurationState: IConfigurationFormValues = extractJsonPref(
    userPreferences,
    UserPreferences.manifestCardConfigurations,
    DEFAULT_CARD_CONFIGURATIONS_VALUE
  ).value;

  useEffect(() => {
    const cardId = getCardId(cardType);
    const elements = listRef.current?.querySelectorAll(`[data-testid^='${cardId}']`);

    if (elements?.length && elements.length > 0) {
      const payload: DragAndDropTargetPayload[] = Array.from(elements).map(
        (node) =>
          ({
            element: node,
            manifest: JSON.parse((node as HTMLElement).attributes.getNamedItem("data-manifest")?.value as string)
          } as DragAndDropTargetPayload)
      );

      handleUpdateDropTargets(payload);
    }
  }, [data, cardType, handleUpdateDropTargets, isManifestDetailsDrawerOpen]);

  useEffect(() => {
    let interval: any;
    const manifestColors = new Map<number, StopColor[]>();
    const setColors = async () => {
      for (const manifest of data) {
        const manifestColor = await getManifestColors({manifest, ruleContextState});
        manifestColors.set(manifest.manifestDriverId, manifestColor);
      }
      setStopColors(new Map(manifestColors));
    };
    if (!ruleContextState.loading) {
      setColors();
      interval = setInterval(() => {
        setColors();
      }, Constants.MANIFEST_POLLING_INTERVAL);
    }
    return () => {
      clearInterval(interval);
    };
  }, [ruleContextState, data]);

  const sortDataManifests = useCallback(
    (data: Manifest[]) => {
      if (!ruleContextState.loading && colorSortEnable && sortByColor?.enable) {
        return getSortedByColorManifests({data, ruleContextState, stopColors, direction: sortByColor.direction});
      } else {
        return data;
      }
    },
    [colorSortEnable, ruleContextState, sortByColor?.direction, sortByColor?.enable, stopColors]
  );

  const getCardFunction = (cardType: CardType) => {
    const CardComponent = getCardComponent(cardType);
    const cardFunction = (item: Manifest) => {
      const props: ManifestCardProps = {
        manifest: item,
        colors: stopColors.get(item.manifestDriverId),
        onSelectedManifest: onSelectedManifest,
        isSelected: item.manifestDriverId === selectedManifest?.manifestDriverId,
        isHighlighted: Boolean(JobAssignmentContext?.highlightManifests?.includes(item.manifestDriverId)),
        disabled: disabledManifestIds.includes(item.manifestDriverId),
        pendingActions: pendingActions,
        onDrillDownManifest: onDrillDownManifest,
        onLocationMarkerClick: onLocationMarkerClick,
        configurationState: configurationState,
        cardType: cardType
      };
      return <CardComponent key={`manifest-card-${item.manifestDriverId}`} {...props} />;
    };
    return cardFunction;
  };

  const cardFunction = getCardFunction(cardType);

  return (
    <ManifestDriverCardContainer ref={listRef}>
      {data && <ScrollContainer>{sortDataManifests(data).map(cardFunction)}</ScrollContainer>}
    </ManifestDriverCardContainer>
  );
};

export default ManifestDriverListV2;
