import {ItemRenderer} from "@blueprintjs/select";
import styled from "@emotion/styled";
import IndicatorSelect, {IndicatorListTagItem} from "../../common/IndicatorSelect";
import {IndicatorColors} from "./ColorizedIndicators";

import {UserColor} from "./types/indicatorLogicType";
import {ColorizedIndicatorContext, ColorizedIndicatorDispatchContext} from "./ColorizedIndicatorStateProvider";
import {useCallback, useContext} from "react";

type ColorChipProps = {
  color: string;
};
export const ColorChip = styled.div<ColorChipProps>`
  width: 15px;
  height: 15px;
  border-radius: 2px;

  border: ${(props) => (props.color !== "#transparent" ? "white solid 1px" : "none")};

  background-color: ${(props) => props.color};
`;

const JobColorSelector = () => {
  const colorizedIndicatorState = useContext(ColorizedIndicatorContext);
  const colorizedIndicatorDispatch = useContext(ColorizedIndicatorDispatchContext);

  const selectedColor = colorizedIndicatorState.selectedLogic?.color;

  const onJobColorSelectorChange = useCallback(
    (selectedItem: UserColor) => {
      colorizedIndicatorDispatch({type: "SET_COLOR", payload: selectedItem});
      colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
    },
    [colorizedIndicatorDispatch]
  );

  const renderItem: ItemRenderer<UserColor> = (item, {handleClick}) => {
    return (
      <IndicatorListTagItem
        key={item}
        onClick={handleClick}
        isSelected={
          item && selectedColor
            ? IndicatorColors[item].name === IndicatorColors[selectedColor as UserColor].name
            : false
        }
      >
        <ColorChip color={IndicatorColors[item].hex} />
        {item && IndicatorColors[item].name}
      </IndicatorListTagItem>
    );
  };

  return (
    <IndicatorSelect
      name="color-selector"
      items={Object.keys(IndicatorColors) as UserColor[]}
      itemRenderer={renderItem}
      onSelectedItem={onJobColorSelectorChange}
      selectedItemText={selectedColor && IndicatorColors[selectedColor].name}
      placeholderText="Color"
      tag={<ColorChip color={selectedColor ? IndicatorColors[selectedColor].hex : "#transparent"} />}
    />
  );
};

export default JobColorSelector;
