import {createContext, useReducer} from "react";
import colorizedIndicatorReducer, {ColorizedIndicatorReducerAction} from "./ColorizedIndicatorReducer";
import {IndicatorLogicType} from "./types/indicatorLogicType";
import {SettingItemEditorMode} from "../components/types/SettingItemEditorMode";
import {ExpandedState} from "./ColorizedIndicatorsList";
import {IndicatorLogicSettings} from "./types/indicatorLogicSettings";

type ColorizedIndicatorState = {
  logicData: IndicatorLogicSettings | undefined;
  selectedLogic: IndicatorLogicType | undefined;
  beforeEditLogic: IndicatorLogicType | undefined;
  isEditingLogicChanged: boolean;
  formMode: SettingItemEditorMode;
  expandedState: ExpandedState;
  isFormValid: boolean;
  triggerSelectorStepSize: number;
  triggerSelectorSliderPosition: number[];
};

type ColorizedIndicatorStateProviderProps = {
  children: React.ReactNode;
};

const initialColorizedIndicatorState: ColorizedIndicatorState = {
  logicData: undefined,
  selectedLogic: {
    jobAttributes: {
      customer: [],
      service: [],
      vehicleType: [],
      stopStatus: undefined,
      site: []
    },
    orderPriority: [],
    manifestAttributes: {
      manifestStatus: undefined
    }
  } as unknown as IndicatorLogicType,
  beforeEditLogic: undefined,
  isEditingLogicChanged: false,
  formMode: SettingItemEditorMode.Disable,
  isFormValid: false,
  triggerSelectorStepSize: 60,
  triggerSelectorSliderPosition: [-1, 1],
  expandedState: {
    red: false,
    orange: false,
    yellow: false,
    olive: false,
    green: false,
    turquoise: false,
    blue: false,
    purple: false,
    magenta: false,
    black: false,
    brown: false,
    grey: false
  }
};

const ColorizedIndicatorContext = createContext<ColorizedIndicatorState>(initialColorizedIndicatorState);
const ColorizedIndicatorDispatchContext = createContext<React.Dispatch<ColorizedIndicatorReducerAction>>(() => {
  /*No OP*/
});

const ColorizedIndicatorStateProvider = ({children}: ColorizedIndicatorStateProviderProps) => {
  const [state, dispatch] = useReducer(colorizedIndicatorReducer, initialColorizedIndicatorState);

  return (
    <ColorizedIndicatorContext.Provider value={state}>
      <ColorizedIndicatorDispatchContext.Provider value={dispatch}>
        {children}
      </ColorizedIndicatorDispatchContext.Provider>
    </ColorizedIndicatorContext.Provider>
  );
};

export default ColorizedIndicatorStateProvider;
export type {ColorizedIndicatorState};
export {ColorizedIndicatorContext, ColorizedIndicatorDispatchContext, initialColorizedIndicatorState};
