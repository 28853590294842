import React from "react";
import styled from "@emotion/styled";
import PlacesAutocomplete from "react-places-autocomplete";
import {LoadingSpinner} from "../../../common/LoadingSpinner";
import {Popover2} from "@blueprintjs/popover2";
import {ProvideLocationType} from "./OptimizeDialog";

interface PlacesAutoCompleteInputProps {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
  type: ProvideLocationType;
}

const PlacesAutoCompleteInput: React.FC<PlacesAutoCompleteInputProps> = ({value, onChange, disabled, type}) => {
  const searchOptions = {
    types: ["street_number", "street_address", "premise", "point_of_interest", "subpremise"]
  };
  return (
    <PlacesAutocomplete
      value={value}
      onChange={onChange}
      onSelect={onChange}
      shouldFetchSuggestions={value.length > 1}
      searchOptions={searchOptions}
    >
      {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
        <Popover2
          isOpen={suggestions.length > 0 || loading}
          autoFocus={false}
          matchTargetWidth
          targetProps={{style: {width: "calc(100% - 26px)", marginLeft: "26px"}}}
          popoverClassName="places-auto-popover"
          minimal
          position={type === ProvideLocationType.starting ? "bottom" : "top"}
          content={
            <PlaceAutoCompleteDropDown className="autocomplete-dropdown-container">
              {loading && <LoadingSpinner size={16} />}
              {suggestions.map((suggestion) => {
                const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {backgroundColor: "#fafafa", cursor: "pointer"}
                  : {backgroundColor: "#ffffff", cursor: "pointer"};
                const suggestionItemProps = getSuggestionItemProps(suggestion, {
                  className,
                  style
                });
                return (
                  <PlaceAutoCompleteItem key={suggestion.id}>
                    <div data-testid={"optimize-selection"} {...suggestionItemProps}>
                      <span>{suggestion.description}</span>
                    </div>
                  </PlaceAutoCompleteItem>
                );
              })}
            </PlaceAutoCompleteDropDown>
          }
        >
          <InputCustom
            data-testid={"optimize-input"}
            {...getInputProps({
              placeholder: "ex. 9519 Hill Ave., Defiance, OH 43512",
              className: "location-search-input",
              disabled
            })}
          />
        </Popover2>
      )}
    </PlacesAutocomplete>
  );
};

export default PlacesAutoCompleteInput;

const PlaceAutoCompleteDropDown = styled.div`
  background-color: #fff;
`;

const PlaceAutoCompleteItem = styled.div`
  padding: 8px 11px;
  border-bottom: 1px solid #c3c3c3;
`;
const InputCustom = styled.input`
  width: 100%;
  height: 32px;
  padding: 0 8px;
  border-radius: 4px;
  gap: 13px;
  border: 1px solid #c3c3c3;

  ::placeholder {
    font-style: italic;
  }
`;
