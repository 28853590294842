import ListActionBarProvider from "../components/common/ListActionBarProvider";
import UnassignedJobsView from "./UnassignedJobsView";

const UnassignedJobsViewContainer = () => {
  return (
    <ListActionBarProvider>
      <UnassignedJobsView />
    </ListActionBarProvider>
  );
};
export default UnassignedJobsViewContainer;
