import {useContext, useEffect} from "react";
import {AuthContext} from "../AuthProvider";
import {DispatchConfigurationContext} from "../../providers/DispatchConfigurationProvider";

declare global {
  interface Window {
    userGuiding: {
      identify(email: string, payload: any): void;
    };
  }
}

const useUserGuiding = () => {
  const {userGuidingEnabled} = useContext(DispatchConfigurationContext);
  const userGuidingJs =
    "(function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify','triggerNps','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','644867205ID');";
  const {user, tenant, isEcourierUser} = useContext(AuthContext);

  useEffect(() => {
    if (userGuidingEnabled && user! && tenant! && isEcourierUser !== undefined) {
      const script = document.createElement("script");

      script.id = "userGuidingScript";
      script.text = userGuidingJs;

      document.body.appendChild(script);

      window.userGuiding.identify(user.email as string, {
        email: user.email,
        name: user.name,
        Tenant: tenant.shortName,
        isEcourierUser: isEcourierUser
      });

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [userGuidingEnabled, user, tenant, isEcourierUser]);
};

export default useUserGuiding;
