import {ICellRendererParams} from "@ag-grid-community/core";
import React from "react";
import {ManifestStop} from "../../../generated/graphql";
import styled from "@emotion/styled";
import {EmptyValueStrings} from "../Constants";

const Container = styled.div`
  font-family: Roboto, serif;
  font-weight: 400;
  font-size: 12px;
`;

const Text = styled.span`
  color: #111827;
`;

const JobPiecesOrWeightCellRenderer = (props: ICellRendererParams<ManifestStop, number | undefined>) => {
  const value = props.value ?? EmptyValueStrings.notAvailable;
  return (
    <Container>
      <Text>{value}</Text>
    </Container>
  );
};

export {JobPiecesOrWeightCellRenderer};
