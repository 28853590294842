import {Button, Divider} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {IToolPanelParams} from "@ag-grid-community/core";
import {useCallback} from "react";

const Container = styled.div({
  padding: "10px",
  width: "100%",
  display: "flex",
  flexDirection: "column"
});

const StyledButton = styled(Button)<{width?: number}>`
  width: ${(props) => props.width}px;
  max-width: 100%;
`;

const StyledIcon = styled.svg``;

const AutoSizeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StyledTitle = styled.div`
  position: relative;

  :hover[data-title]::after {
    content: attr(data-title);
    position: absolute;
    font-size: 12px;
    top: 110%;
    right: 0;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 295px;
    box-shadow: 2px 3px 2px 0px rgba(0, 0, 0, 0.05);
    color: #1c2127;
    background-color: #ffffff;
    font-family: "Roboto", serif;
  }
`;
interface GridSettingsToolPanelParams extends IToolPanelParams {
  onRestoreDefaults(): void;
}

const GridSettingsToolPanel = (props: GridSettingsToolPanelParams) => {
  const autoSizingAllColumns = useCallback(
    (skipHeader: boolean) => {
      // eslint-disable-next-line testing-library/render-result-naming-convention
      const renderedData = props.api.getRenderedNodes();
      const isNoData = renderedData?.[0]?.failedLoad === true || renderedData?.length === 0;
      if (isNoData) return;

      const resizeCols = props.columnApi
        .getAllDisplayedColumns()
        .filter((c) => !c.getUserProvidedColDef()?.suppressColumnsToolPanel)
        .map((c) => c.getColId());

      props.columnApi.autoSizeColumns(resizeCols, skipHeader);
    },
    [props.columnApi, props.api]
  );

  const restoreDefaults = () => {
    props.onRestoreDefaults();
    props.columnApi.resetColumnState();
    props.api.onFilterChanged();
  };

  return (
    <Container data-testid={"grid-settings-tool-panel"}>
      <h2>Table Configuration</h2>
      <Divider />
      <h3>Columns & Filters</h3>
      <AutoSizeContainer>
        <StyledButton
          data-testid={"grid-settings-autosize-cols"}
          icon={
            <StyledIcon width="16" height="14" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2 0.699276C0.896875 0.699276 0 1.6254 0 2.76449V13.0906C0 14.2297 0.896875 15.1558 2 15.1558H18C19.1031 15.1558 20 14.2297 20 13.0906V2.76449C20 1.6254 19.1031 0.699276 18 0.699276H2ZM6 2.76449V13.0906H2V2.76449H6ZM8 2.76449H12V13.0906H8V2.76449ZM18 2.76449V13.0906H14V2.76449H18Z"
                fill="#5F6B7C"
              />
            </StyledIcon>
          }
          text={"Autosize Columns"}
          onClick={() => autoSizingAllColumns(true)}
          width={165}
          alignText="left"
        />
        <StyledTitle
          data-title="Autosize dynamically adjusts column widths based on their contents, ensuring optimal space for the largest
              items."
        >
          <StyledIcon
            style={{margin: "0px 4px 0px 2px"}}
            width="16"
            height="16"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 22C13.9174 22 16.7153 20.8411 18.7782 18.7782C20.8411 16.7153 22 13.9174 22 11C22 8.08262 20.8411 5.28473 18.7782 3.22183C16.7153 1.15893 13.9174 0 11 0C8.08262 0 5.28473 1.15893 3.22183 3.22183C1.15893 5.28473 0 8.08262 0 11C0 13.9174 1.15893 16.7153 3.22183 18.7782C5.28473 20.8411 8.08262 22 11 22ZM9.28125 14.4375H10.3125V11.6875H9.28125C8.70977 11.6875 8.25 11.2277 8.25 10.6562C8.25 10.0848 8.70977 9.625 9.28125 9.625H11.3438C11.9152 9.625 12.375 10.0848 12.375 10.6562V14.4375H12.7188C13.2902 14.4375 13.75 14.8973 13.75 15.4688C13.75 16.0402 13.2902 16.5 12.7188 16.5H9.28125C8.70977 16.5 8.25 16.0402 8.25 15.4688C8.25 14.8973 8.70977 14.4375 9.28125 14.4375ZM11 5.5C11.3647 5.5 11.7144 5.64487 11.9723 5.90273C12.2301 6.16059 12.375 6.51033 12.375 6.875C12.375 7.23967 12.2301 7.58941 11.9723 7.84727C11.7144 8.10513 11.3647 8.25 11 8.25C10.6353 8.25 10.2856 8.10513 10.0277 7.84727C9.76987 7.58941 9.625 7.23967 9.625 6.875C9.625 6.51033 9.76987 6.16059 10.0277 5.90273C10.2856 5.64487 10.6353 5.5 11 5.5Z"
              fill="#797979"
            />
            <defs>
              <clipPath id="clip0_1605_89736">
                <rect width="22" height="22" fill="white" />
              </clipPath>
            </defs>
          </StyledIcon>
        </StyledTitle>
      </AutoSizeContainer>
      <StyledButton
        data-testid={"grid-settings-reset-defaults"}
        icon={"reset"}
        text={"Restore Defaults"}
        alignText="left"
        onClick={restoreDefaults}
        width={165}
      />
    </Container>
  );
};

export default GridSettingsToolPanel;
