import {ApolloClient, ApolloError, ApolloQueryResult, ServerError, gql} from "@apollo/client";
import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
  SortModelItem
} from "@ag-grid-community/core";
import {
  Job,
  JobAttributeFilter,
  JobFilter,
  JobSortInput,
  JobStopTextFields,
  JobTextFields,
  NestedJobStopFilterInput,
  SearchableSortDirection
} from "../../generated/graphql";
import {AgJob} from "./JobPanel";
import {makeQueryStringFilter} from "../../utils/QueryUtils";
import {Constants} from "../common/Constants";
import {CustomerFilterOperators} from "../common/CustomerFilter";

type Decorator = (params: IServerSideGetRowsParams, results: Job[], res: ApolloQueryResult<any>) => Promise<void>;

type CreateJobsServerSideDatasourceProps = {
  client: ApolloClient<any>;
  defaultFields?: string[];
  excludeFields?: string[];
  onDatasourceFail?(error: ApolloError | undefined): void;
  decorateResults: Decorator[];
  tokenExpiry: Date | undefined;
};

const MappingFilterFields = new Map<string, string>([["order.customer.name", "order.customer.customerId"]]);

export const createJobsServerSideDatasource = ({
  client,
  defaultFields,
  excludeFields,
  onDatasourceFail,
  decorateResults,
  tokenExpiry
}: CreateJobsServerSideDatasourceProps): IServerSideDatasource => {
  return {
    getRows: function (params: IServerSideGetRowsParams) {
      const {startRow, filterModel, sortModel}: IServerSideGetRowsRequest = params.request;
      const colIdToFields: {[key: string]: string[]} = params.columnApi
        .getAllDisplayedColumns()
        .filter((x) => !excludeFields?.includes(x.getId()))
        .reduce((map, col) => {
          const colId = col.getColId();
          map[colId] = col.getColDef().field?.split(",") || [colId];
          return map;
        }, {} as {[key: string]: string[]});

      const jobStatusFilter: JobAttributeFilter[] = Constants.UNASSIGNED_JOB_STATUSES.map((s) => {
        return {
          jobStatus: {match: s}
        };
      });

      let filter: JobFilter = {
        job: {
          order_orderId: {gte: 0},
          or: jobStatusFilter,
          and: buildJobsFilter(filterModel)
        }
      };

      if (params.context?.globalFilter) {
        filter = {
          job: {
            and: [params.context.globalFilter, {...filter?.job}]
          }
        };
      }

      const stopsFilter = buildStopsFilter(filterModel);
      if (Object.keys(stopsFilter).length) {
        filter = {...filter, ...{stops: stopsFilter}};
      }

      const globalQuery = params.context?.globalQuery;
      if (globalQuery) {
        filter.queryString = makeQueryStringFilter(globalQuery, [
          "jobNumber",
          "routeNumber",
          "order.customer.name",
          "order.caller",
          "order.callerPhone",
          "order.alias",
          "order.auth",
          "order.orderId",
          "stops.name",
          "stops.address",
          "stops.city",
          "stops.state",
          "stops.dispatchZone"
        ]);
      }
      console.debug("JobFilter:", filter);

      const sort = [...buildSort(sortModel, colIdToFields)];

      const hasInvalidJobNumberColumn = sort.find((sortInput) => sortInput.field?.startsWith("jobNumber_"));
      if (hasInvalidJobNumberColumn) {
        console.error(
          "[JobServerSideDataSource] - Detected an invalid JobNumber Column",
          sort,
          sortModel,
          colIdToFields
        );
      }

      const jobSortInSortModal = sort.find((item) => item.field === "jobNumber");

      if (!jobSortInSortModal) {
        const jobSortDefault = {field: "jobNumber", direction: "asc"} as JobSortInput;
        sort.push(jobSortDefault);
      }

      console.debug(`JobSort: ${JSON.stringify(sort)}`);

      let visibleColumnIds: string[] = Object.values(colIdToFields).flatMap((x) => x);
      if (defaultFields) {
        visibleColumnIds = visibleColumnIds.concat(defaultFields);
      }

      const gqlCols = buildQuery(visibleColumnIds);
      console.debug(`GQL Columns: ${gqlCols}`);

      const query = gql(/* GRAPHQL */ `
                    query SearchJobs($filter: JobFilter, $sort: [JobSortInput], $offset: Int, $limit: Int) {
                        searchJobs(filter: $filter, sort: $sort, offset: $offset, limit: $limit) {
                            items {
                                ${gqlCols}
                            }
                            nextToken,
                            total
                        }
                    }
                `);

      let retry = 0;

      const getData = async () => {
        try {
          const res = await client.query({
            query: query,
            fetchPolicy: "network-only",
            variables: {
              offset: startRow,
              limit: params.api.paginationGetPageSize(),
              filter: filter,
              sort: sort
            }
          });

          const serverSideResults = (res.data.searchJobs.items as Job[]).map((j) => {
            return {...j} as AgJob;
          });

          if (decorateResults) {
            for await (const decorator of decorateResults) {
              await decorator(params, serverSideResults, res);
            }
          }

          params.success({
            rowData: serverSideResults,
            rowCount: res.data.searchJobs.total
          });
          retry = 0;
        } catch (error) {
          handleError(error as ApolloError);
        }
      };

      const handleError = (err: ApolloError) => {
        const netError = err.networkError as ServerError;
        if (netError && netError.statusCode === 401) {
          //This will retry at the ApolloLink level
          return;
        }
        //Refetch if type error
        if (err.name === "TypeError") {
          console.debug("Retrying by TypeError:", err);
          getData();
          return;
        }
        if (retry === Constants.MAX_NUMBER_OF_RETRY) {
          console.error("Jobs grid error", {
            error: err,
            raw: JSON.stringify(err),
            tokenExpiry: tokenExpiry,
            clientDateTime: new Date()
          });
          onDatasourceFail?.(err);
          params.fail();
          return;
        }
        retry++;
        console.debug(`Retrying ${retry} by error:`, err);
        getData();
      };
      onDatasourceFail?.(undefined);
      getData();
    }
  };
};

const buildQuery = (cols: string[]) => {
  const set = (o: {[key: string]: any} = {}, a: string[]) => {
    const k = a.shift() as string;
    o[k] = a.length ? set(o[k], a) : null;
    return o;
  };

  const o = cols.reduce((o: object, a: string) => set(o, a.split(".")), {});

  return JSON.stringify(o)
    .replace(/"|:|null/g, "")
    .replace(/^\{/, "")
    .replace(/}$/, "");
};

const buildSort = (sortModel: SortModelItem[], colIdToFields: {[key: string]: string[]}) => {
  const newSortModel = [...sortModel];
  const newColIdToFields = {...colIdToFields};
  const pickupAddressSortIndex = newSortModel.findIndex((item) => item.colId === "stop.pickup.address");
  const filterAndSortByColorsIndex = newSortModel.findIndex((item) => item.colId === "indicatorColor");
  if (pickupAddressSortIndex !== -1) {
    newSortModel.splice(pickupAddressSortIndex, 0, {
      colId: "stop.pickup.zip",
      sort: newSortModel[pickupAddressSortIndex].sort
    });
    newColIdToFields["stop.pickup.zip"] = ["stops.zip"];
  }
  if (filterAndSortByColorsIndex !== -1) {
    newSortModel.splice(filterAndSortByColorsIndex, 1);
  }
  return newSortModel
    .filter((sm) => newColIdToFields[sm.colId] !== undefined)
    .map((sm) => {
      let sortKey, textFieldKey: string;
      let textFields;
      const sortInput = {} as JobSortInput;
      if (sm.colId.startsWith("stop.")) {
        sortKey = newColIdToFields[sm.colId]?.at(0) || sm.colId;
        textFieldKey = sortKey.substring(sortKey.indexOf(".") + 1).replaceAll(".", "_");
        textFields = JobStopTextFields;
        if (sm.colId.startsWith("stop.pickup")) {
          sortInput.nestedFilter = {
            stopType: {
              match: "P"
            }
          };
        } else if (sm.colId.startsWith("stop.delivery")) {
          sortInput.nestedFilter = {
            stopType: {
              match: "D"
            }
          };
        }
        if (sm.colId === "stop.pickup.address" || sm.colId === "stop.pickup.zip") {
          sortInput.nestedFilter = {
            sequence: {
              eq: 1
            }
          };
          textFieldKey.replaceAll("_", "");
        }
      } else {
        sortKey = sm.colId;
        textFieldKey = sortKey.replaceAll(".", "_");
        textFields = JobTextFields;
      }
      if (Object.values(textFields).includes(textFieldKey)) {
        sortKey += ".keyword";
      }
      sortInput.field = sortKey;
      sortInput.direction = sm.sort === "asc" ? SearchableSortDirection.Asc : SearchableSortDirection.Desc;
      return sortInput;
    });
};

const buildJobsFilter = (filterModel: any): any[] => {
  const filters: any[] = [];
  if (filterModel) {
    Object.keys(filterModel)
      .filter((x) => !x.startsWith("stop") && x !== "indicatorColor")
      .forEach(function (columnKey) {
        let key = columnKey;
        if (MappingFilterFields.has(columnKey)) {
          key = MappingFilterFields.get(columnKey)!;
        }
        const condition = createCondition(key.replaceAll(".", "_"), filterModel[columnKey]);
        filters.push(condition);
      });
  }
  return filters;
};

const buildStopsFilter = (filterModel: any): NestedJobStopFilterInput[] => {
  const filters: NestedJobStopFilterInput[] = [];
  let pickupFilters: NestedJobStopFilterInput = {};
  let deliveryFilters: NestedJobStopFilterInput = {};

  if (filterModel) {
    Object.keys(filterModel)
      .filter((x) => x.startsWith("stop."))
      .forEach(function (columnKey) {
        const condition = createCondition(columnKey.replace(/stop.\w+./, ""), filterModel[columnKey]);
        if (columnKey.startsWith("stop.pickup")) {
          pickupFilters = {...pickupFilters, ...condition};
        } else {
          deliveryFilters = {...deliveryFilters, ...condition};
        }
      });
  }
  if (Object.keys(pickupFilters).length) {
    filters.push({...{stopType: {match: "P"}}, ...pickupFilters});
  }
  if (Object.keys(deliveryFilters).length) {
    filters.push({...{stopType: {match: "D"}}, ...deliveryFilters});
  }
  return filters;
};

const createCondition: any = (column: string, filter: any) => {
  if (filter.operator) {
    return {
      [filter.operator.toLowerCase()]: [
        createCondition(column, filter.condition1),
        createCondition(column, filter.condition2)
      ]
    };
  }
  if (filter.filterType === "set") {
    if (filter.values.length === 0) {
      return {[column]: {match: ""}};
    }
    return {
      or: filter.values.map((x: string) => {
        return {[column]: {matchPhrase: x}};
      })
    };
  }
  if (filter.filterType === "setNumber") {
    if (filter.values.length === 0) {
      return {
        [column]: {eq: -1}
      };
    }
    if (filter?.customerFilterOperator === CustomerFilterOperators.NotEqual) {
      return {
        and: filter.values.map((x: number) => {
          return {[column]: {ne: x}};
        })
      };
    }
    return {
      or: filter.values.map((x: number) => {
        return {[column]: {eq: x}};
      })
    };
  }
  switch (filter.type) {
    case "equals":
      if (filter.filterType === "number") {
        return {[column]: {eq: filter.filter}};
      }
      return {[column]: {matchPhrase: filter.filter}};
    case "notEqual":
      return {[column]: {ne: filter.filter}};
    case "lessThan":
      return {[column]: {lt: filter.filter}};
    case "lessThanOrEqual":
      return {[column]: {lte: filter.filter}};
    case "greaterThan":
      return {[column]: {gt: filter.filter}};
    case "greaterThanOrEqual":
      return {[column]: {gte: filter.filter}};
    case "inRange":
      return {[column]: {range: [filter.filter, filter.filterTo]}};
    case "contains":
      return {[column]: {wildcard: `*${filter.filter}*`}};
    case "notContains":
      return {not: {[column]: {wildcard: `*${filter.filter}*`}}};
    case "startsWith":
      return {[column]: {wildcard: `${filter.filter}*`}};
    case "endsWith":
      return {[column]: {wildcard: `*${filter.filter}`}};
    case "blank":
      return {[column]: {match: filter.filterType === "number" ? null : ""}};
    case "notBlank":
      return {not: {[column]: {match: filter.filterType === "number" ? null : ""}}};
  }
};
