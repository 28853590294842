import {Intent, Tag, Text} from "@blueprintjs/core";
import React, {CSSProperties, useContext} from "react";
import {AuthContext} from "../../AuthProvider";
import {ICellRendererParams} from "@ag-grid-community/core";
import {ManifestStop} from "../../../generated/graphql";
import {navigateToCmsOrder} from "../../../services/CmsService";
import styled from "@emotion/styled";
import {PreferenceContext} from "../../../providers/PreferenceProvider";

const ManifestJobBadge = styled(Tag)`
  border-radius: 4px;
  background-color: transparent;
`;

const LinkText = styled(Text)`
  opacity: 1;
  font-family: Roboto;
  font-weight: 500;
  font-size: 12px;
  color: #1f2937;
`;

interface ManifestJobCellRendererParams extends ICellRendererParams<ManifestStop, string> {
  jobColoringIndex: Map<string, number>;
  children?: React.ReactNode;
}

export const ManifestJobColorPalette: CSSProperties[] = [
  {backgroundColor: "#C996FD"},
  {backgroundColor: "#62EAA9"},
  {backgroundColor: "#E4D03D"},
  {backgroundColor: "#FF9495"},
  {backgroundColor: "rgba(119,179,211,0.5)"},
  {backgroundColor: "#FFAC75"},
  {backgroundColor: "rgba(235,57,235,0.5)"},
  {backgroundColor: "#7CC254"},
  {backgroundColor: "rgba(255,207,224,0.5)"},
  {backgroundColor: "#FFC328"},
  {backgroundColor: "rgba(239,64,66,0.5)"},
  {backgroundColor: "rgba(113,255,216,0.5)"},
  {backgroundColor: "rgba(228,208,61,0.5)"},
  {backgroundColor: "rgba(64,66,179,0.5)"},
  {backgroundColor: "#2AB3FD"},
  {backgroundColor: "rgba(255,172,117,0.5)"},
  {backgroundColor: "#EB39EB"},
  {backgroundColor: "rgba(124,194,84,0.5)"},
  {backgroundColor: "#42A673"},
  {backgroundColor: "#8CFF64"}
];

export const ManifestJobNumberCellRenderer = (props: ManifestJobCellRendererParams) => {
  const authState = useContext(AuthContext);
  const {tenantPreferences} = useContext(PreferenceContext);

  const backgroundColorIndex = props.jobColoringIndex.get(props.data!.job.jobNumber);
  let styling: CSSProperties = {
    backgroundColor: "transparent"
  };

  if (backgroundColorIndex !== undefined && backgroundColorIndex < ManifestJobColorPalette.length) {
    styling = {...styling, ...ManifestJobColorPalette[backgroundColorIndex]};
  }

  return (
    <ManifestJobBadge
      style={styling}
      data-testid={`manifest-job-stop-number-button-${props.node.id}`}
      intent={Intent.PRIMARY}
      interactive={true}
      minimal={true}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        props.api.showLoadingOverlay();
        navigateToCmsOrder(authState.tenant!, authState.token!, props.data!.order.orderId, tenantPreferences).finally(
          () => props.api.hideOverlay()
        );
      }}
    >
      <LinkText title={`Navigate to Order ${props.data!.order.orderId}`} ellipsize>
        {props.value}
      </LinkText>
    </ManifestJobBadge>
  );
};
