import {Address, AddressResolutionStatus, LatLong, ResolvedAddress} from "../domain/geocode-types";
import LocationMap from "../components/common/LocationMap";
import axios from "axios";
import {Constants} from "../components/common/Constants";
import {GeoCodeAddressesDocument, GeocodeResult} from "../generated/graphql";
import {ApolloClient, ApolloError, ApolloQueryResult} from "@apollo/client";

export enum GeoCodeServiceType {
  googleDirect,
  appSyncProxied
}
export interface GeoCodeService {
  geoCodeAddresses(addresses: Address[]): Promise<Map<number, ResolvedAddress>>;
}

export interface GeoCodeServiceProperties {
  apolloClient?: ApolloClient<any>;
  serviceType?: GeoCodeServiceType;
}
export class AppSyncGeoCodeService implements GeoCodeService {
  private client: ApolloClient<any>;
  constructor(props: GeoCodeServiceProperties) {
    if (!props.apolloClient) {
      throw new Error("A valid Apollo client must be provided.");
    }
    this.client = props.apolloClient;
  }
  async geoCodeAddresses(addresses: Address[]): Promise<Map<number, ResolvedAddress>> {
    const record = LocationMap.getInstance();
    const retVal = new Map<number, ResolvedAddress>();
    const queryAddresses = [] as Address[];
    for (const address of addresses) {
      const latLng = record[address.hashAddress()];
      if (latLng) {
        retVal.set(address.hashAddress(), new ResolvedAddress(address, latLng, AddressResolutionStatus.cacheHit));
      } else {
        queryAddresses.push(address);
      }
    }

    if (queryAddresses.length > 0) {
      console.debug("queried addresses:", queryAddresses);
      return this.client
        .query({
          query: GeoCodeAddressesDocument,
          variables: {
            formattedAddresses: queryAddresses.map((a) => {
              return a.formatAddress();
            })
          }
        })
        .then((res: ApolloQueryResult<any>) => {
          return ((res.data.geocodeAddresses.items as GeocodeResult[]) ?? ([] as GeocodeResult[])).reduce(
            (map, gcR, x) => {
              console.debug("gql returned:", gcR);

              const address = queryAddresses[x];
              if (!address) {
                throw new Error(`No address for at ${x} for result: ${JSON.stringify(gcR)}`);
              }
              const hashedAddress = queryAddresses[x].hashAddress();
              const latLng = {
                lat: gcR.lat,
                lng: gcR.lon
              } as LatLong;
              const resolutionStatus = this.getResolutionStatus(gcR.status);
              if (AddressResolutionStatus.unresolvableAddress != resolutionStatus) {
                record[hashedAddress] = latLng;
              }
              map.set(hashedAddress, new ResolvedAddress(address, latLng, resolutionStatus));
              return map;
            },
            retVal
          );
        })
        .catch((err: ApolloError) => {
          console.error("Error", err);
          throw new Error("Error performing geocode query.", {
            cause: err
          });
        });
    } else {
      console.debug("All results retrieved from cache.");
      return retVal;
    }
  }

  private getResolutionStatus(geoCodeStatus: string) {
    switch (geoCodeStatus) {
      case "cacheMiss":
        return AddressResolutionStatus.cacheMiss;
      case "cacheHit":
        return AddressResolutionStatus.cacheHit;
      default:
        return AddressResolutionStatus.unresolvableAddress;
    }
  }
}
export class GoogleGeoCodeService implements GeoCodeService {
  async geoCodeAddresses(addresses: Address[]): Promise<Map<number, ResolvedAddress>> {
    const updatedStopLocations: Map<number, ResolvedAddress> = new Map<number, ResolvedAddress>();

    const record = LocationMap.getInstance();
    const promiseData = [];

    for (const address of addresses) {
      const latLng = record[address.hashAddress()];
      if (latLng) {
        updatedStopLocations.set(
          address.hashAddress(),
          new ResolvedAddress(address, latLng, AddressResolutionStatus.cacheHit)
        );
      } else {
        promiseData.push(
          axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
            params: {address: address.formatAddress(), key: Constants.GOOGLE_API_KEY}
          })
        );
      }
    }

    return Promise.all(promiseData)
      .then((results) => {
        for (let x = 0; x < results.length; x++) {
          if (results[x].data.results.length > 0) {
            const hashedAddress = addresses[x].hashAddress();
            const latLong = {
              lat: results[x].data.results[0].geometry.location.lat,
              lng: results[x].data.results[0].geometry.location.lng
            } as LatLong;
            record[hashedAddress] = latLong;
            updatedStopLocations.set(
              addresses[x].hashAddress(),
              new ResolvedAddress(
                addresses[x],
                {
                  lat: results[x].data.results[0].geometry.location.lat,
                  lng: results[x].data.results[0].geometry.location.lng
                },
                AddressResolutionStatus.cacheMiss
              )
            );
          } else {
            console.warn("Cannot resolve address", results[x]);
            updatedStopLocations.set(
              addresses[x].hashAddress(),
              new ResolvedAddress(
                addresses[x],
                {
                  lat: 0,
                  lng: 0
                },
                AddressResolutionStatus.unresolvableAddress
              )
            );
          }
        }
        return updatedStopLocations;
      })
      .catch((e) => {
        throw e;
      });
  }
}

export const getGeoCodeService = (
  geoCodeServiceProps: GeoCodeServiceProperties = {serviceType: GeoCodeServiceType.googleDirect}
): GeoCodeService => {
  if (GeoCodeServiceType.appSyncProxied == geoCodeServiceProps.serviceType) {
    console.debug("Using app sync geocode proxy.");
    return new AppSyncGeoCodeService(geoCodeServiceProps);
  } else {
    console.debug("Using google direct geocode service.");
    return new GoogleGeoCodeService();
  }
};
