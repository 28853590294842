import {ICellRendererParams} from "@ag-grid-community/core";
import {ManifestStop} from "../../../generated/graphql";
import styled from "@emotion/styled";
import {EmptyValueStrings} from "../Constants";
import {Tooltip2} from "@blueprintjs/popover2";
import {Text} from "@blueprintjs/core";
const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ContactValueGetter = (params: ICellRendererParams<ManifestStop, string | undefined>) => {
  const contactInfo = [];
  const see = params.data?.see;
  const phone = params.data?.phone;
  if (!see && !phone) return "";
  if (see) contactInfo.push(see);
  if (phone) contactInfo.push(phone);
  return contactInfo.join(" - ");
};

export const ContactCellRenderer = (props: ICellRendererParams) => {
  if (!props.value) return <span>{EmptyValueStrings.notProvided}</span>;
  return (
    <Tooltip2 fill placement={"top-start"} content={props.value}>
      <ContactContainer>
        <Text ellipsize={true}>{props.value}</Text>
      </ContactContainer>
    </Tooltip2>
  );
};
