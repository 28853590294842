import {Button, Checkbox, Dialog, InputGroup, Label, MenuItem, Text} from "@blueprintjs/core";
import {useCallback, useContext, useMemo, useEffect, useState, ChangeEvent} from "react";
import {Controller, ControllerRenderProps, SubmitErrorHandler, SubmitHandler, Validate, useForm} from "react-hook-form";
import {
  EventType,
  EventTypeSortableField,
  LateStop,
  LateStopSortableField,
  ManifestStop,
  SaveClearJobStop,
  SearchableSortDirection,
  Service,
  ServiceSortableField,
  useSaveClearJobStopsMutation,
  useSearchEventTypesQuery,
  useSearchLateStopsQuery,
  useSearchServicesQuery
} from "../../../../generated/graphql";
import styled from "@emotion/styled";
import {
  addMinutes,
  compareAsc,
  format,
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  parse,
  parseISO,
  startOfDay
} from "date-fns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {light} from "@fortawesome/fontawesome-svg-core/import.macro";
import {DateInput2} from "@blueprintjs/datetime2";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import "./TimePickerCommon.css";
import {Select2} from "@blueprintjs/select";
import {Constants, DateFormats, TenantPreferences} from "../../../common/Constants";
import {PreferenceContext, extractSimplePref} from "../../../../providers/PreferenceProvider";
import {formatCMSDateTimeInTimeZone, getIANATimezone, isValidDateTime} from "../../../../utils/DateUtils";
import {InforIcon} from "../../../job/AssignmentBarV2";
import {Tooltip2} from "@blueprintjs/popover2";
import {isEmpty} from "lodash";
import {getTimezoneOffset} from "date-fns-tz";

type CompleteStopDialogProps = {
  manifestStop: ManifestStop;
  onCancel?: () => void;
  markComplete?: (success: boolean) => void;
};

type DateTimeValidator =
  | Validate<string | undefined, IFormValues>
  | Record<string, Validate<string | undefined, IFormValues>>
  | undefined;

enum TriggerType {
  StopComplete = 0,
  ArriveLocation = 1,
  StopSelected = 2
}

enum TimeType {
  ScheduledTime = 0,
  LateTime = 1
}

type IFormValues = {
  podName: string | undefined;
  podDateTime: string;
  arrivalDateTime: string | undefined;
  departureDateTime: string | undefined;
  pieces: string;
  weight: string;
  lateReason?: string;
};

const TimeInforIcon = () => (
  <Tooltip2
    position="top"
    content="Enter this time based on the time in your time zone, not the driver’s time zone."
    popoverClassName="white-popover mark-complete-stop-time-popover"
  >
    <InforIcon size={12} />
  </Tooltip2>
);

const getDateTime = (date: number | Date, podTime: Date | undefined) =>
  new Date(
    getYear(date),
    getMonth(date),
    getDate(date),
    podTime ? getHours(podTime) : 0,
    podTime ? getMinutes(podTime) : 0,
    0,
    0
  );

export enum DateTimeErrorMsg {
  FutureTimeError = "The time entered must be equal to the current time, or in the past",
  InvalidDate = "Invalid date",
  PodPriorArrTime = "The POD time must be at least 1 minute after the arrival time",
  DepTimePriorArrTime = "The Depart time must be at least 1 minute after the arrival time"
}

const titleMessage = "Additional Information to mark stop(s) as complete";

const CompleteStopDialog = ({manifestStop, onCancel, markComplete}: CompleteStopDialogProps) => {
  const {tenantPreferences} = useContext(PreferenceContext);
  const requireArrAndDepTimes = extractSimplePref(tenantPreferences, TenantPreferences.requireArrAndDepTimes, false)
    .value as boolean;

  const [eventTypes, setEventTypes] = useState<EventType[]>([]);
  const [lateStops, setLateStops] = useState<LateStop[]>([]);
  const [services, setServices] = useState<Service[]>([]);

  const [markCompleteJob] = useSaveClearJobStopsMutation();

  useSearchEventTypesQuery({
    variables: {
      filter: {
        lateReason: {
          eq: true
        }
      },
      sort: {
        field: EventTypeSortableField.EventType,
        direction: SearchableSortDirection.Asc
      },
      limit: 20
    },
    onCompleted(data) {
      if (data) {
        setEventTypes([
          {
            eventType: "(none)",
            eventTypeId: 0
          },
          ...(data.searchEventTypes?.items as EventType[])
        ]);
      }
    },
    onError: console.error
  });

  useSearchLateStopsQuery({
    variables: {
      sort: {
        field: LateStopSortableField.Sequence,
        direction: SearchableSortDirection.Asc
      }
    },
    onCompleted(data) {
      if (data) {
        setLateStops(data?.searchLateStops?.items as LateStop[]);
      }
    },
    onError: console.error
  });

  useSearchServicesQuery({
    variables: {
      sort: {
        field: ServiceSortableField.ServiceId,
        direction: SearchableSortDirection.Asc
      }
    },
    onCompleted(data) {
      if (data) {
        setServices(data?.searchServices?.items as Service[]);
      }
    },
    onError: console.error
  });

  const currentDateTime = useMemo(() => new Date(), []);

  const {orderPodRequired, piecesOnTabExists, stopPodRequired} = useMemo(
    () => ({
      piecesOnTabExists: (manifestStop.order.tabPieces ?? 0) > 0,
      stopPodRequired: manifestStop.podRequired ?? false,
      orderPodRequired: manifestStop.order.podRequired ?? false
    }),
    [manifestStop.order.podRequired, manifestStop.order.tabPieces, manifestStop.podRequired]
  );

  const jobStopTimezoneOffsetMinutes = useMemo(
    () => getTimezoneOffset(getIANATimezone(manifestStop.timeZone ?? "")) / (60 * 1000),
    [manifestStop.timeZone]
  );

  const offsetMinutesBetweenJobStopAndBrowser = useMemo(
    () => jobStopTimezoneOffsetMinutes - Constants.BROWSER_TIMEZONE_OFFSET_MINUTES,
    [jobStopTimezoneOffsetMinutes]
  );

  const currentDateTimeInJobStopTZ = useMemo(
    () => addMinutes(new Date(), offsetMinutesBetweenJobStopAndBrowser),
    [offsetMinutesBetweenJobStopAndBrowser]
  );

  const podRequired = useMemo<boolean>(() => {
    if (stopPodRequired) return true;
    if (orderPodRequired && manifestStop.stopType != "P") return true;
    return false;
  }, [manifestStop.stopType, orderPodRequired, stopPodRequired]);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    clearErrors,
    watch,
    formState: {errors, isValid, validatingFields, touchedFields}
  } = useForm<IFormValues>({
    mode: "onBlur",
    defaultValues: {
      lateReason: "",
      podDateTime: currentDateTime.toISOString(),
      arrivalDateTime: manifestStop.arriveDateTime ?? undefined,
      weight: manifestStop.job.weight?.toString() ?? "0",
      pieces: manifestStop.job.pieces?.toString() ?? "0"
    }
  });

  const [podDateTime, arrivalDateTime, departureDateTime] = [
    watch("podDateTime"),
    watch("arrivalDateTime"),
    watch("departureDateTime")
  ];

  const failedSubmitHandler: SubmitErrorHandler<IFormValues> | undefined = useCallback((values) => {
    console.error("Failed form validation on complete stop submit.", values);
  }, []);

  const submitHandler: SubmitHandler<IFormValues> = useCallback(
    async (data) => {
      const payload: SaveClearJobStop = {
        jobStopId: manifestStop.jobStopId,
        podName: data.podName,
        podDateTime: formatCMSDateTimeInTimeZone(
          data.podDateTime,
          manifestStop.timeZone as string,
          DateFormats.awsDateTimeWithOffset
        ),
        eventType: data.lateReason,
        arriveDateTime: arrivalDateTime
          ? formatCMSDateTimeInTimeZone(
              arrivalDateTime,
              manifestStop.timeZone as string,
              DateFormats.awsDateTimeWithOffset
            )
          : undefined,
        departDateTime: departureDateTime
          ? formatCMSDateTimeInTimeZone(
              departureDateTime,
              manifestStop.timeZone as string,
              DateFormats.awsDateTimeWithOffset
            )
          : undefined,
        pieces: Number(data.pieces),
        weight: Number(data.weight)
      };

      try {
        await markCompleteJob({
          variables: {
            saveClearJobStops: payload
          }
        });
        markComplete?.(true);
      } catch (err) {
        console.error("Failed to save clear job stop", err);
        markComplete?.(false);
      }
    },
    [arrivalDateTime, departureDateTime, manifestStop.jobStopId, manifestStop.timeZone, markComplete, markCompleteJob]
  );

  const isLate = useCallback(() => {
    const siteValid = (lateStopSiteId: number) => {
      const siteId = manifestStop.job.site?.siteId;
      if (lateStopSiteId === 0 || lateStopSiteId === siteId) return true;
      else return false;
    };

    const customerValid = (lateStopCustomerId: number) => {
      const customerId = manifestStop.order.customer.customerId;
      if (lateStopCustomerId === 0 || lateStopCustomerId === customerId) return true;
      else return false;
    };

    const customerGroupValid = (lateStopCustomerGroup: string) => {
      const customerGroup = manifestStop.order.customer.group;
      if (!lateStopCustomerGroup || lateStopCustomerGroup === customerGroup) return true;
      else return false;
    };

    const serviceValid = (lateStopServiceId: number) => {
      const lateStopServiceName = services.find((s) => s.serviceId === lateStopServiceId)?.service;
      if (!lateStopServiceName || lateStopServiceName === manifestStop.order.service) return true;
      else return false;
    };

    const stopTypeValid = (lateStopType: string) => {
      return lateStopType === manifestStop.stopType;
    };

    const timeTypeValid = (lateStopTimeType: TimeType) => {
      if (lateStopTimeType === TimeType.ScheduledTime) {
        const scheduledDateTime = Date.parse(manifestStop?.scheduledDateTime as string);
        if (isNaN(scheduledDateTime)) {
          console.debug("[CompleteStopDialog] - Time type is scheduled time but scheduledDateTime is not valid");
          return false;
        }
      } else if (lateStopTimeType === TimeType.LateTime) {
        const lateDateTime = Date.parse(manifestStop?.lateDateTime as string);
        if (isNaN(lateDateTime)) {
          console.debug("[CompleteStopDialog] - Time type is late time but lateDateTime is not valid");
          return false;
        }
      }
      return lateStopTimeType === TimeType.ScheduledTime || lateStopTimeType === TimeType.LateTime;
    };

    for (const lateStop of lateStops) {
      if (
        siteValid(lateStop.siteId as number) &&
        customerValid(lateStop.customerId as number) &&
        customerGroupValid(lateStop.customerGroup as string) &&
        serviceValid(lateStop.serviceId as number) &&
        stopTypeValid(lateStop.stopType as string) &&
        timeTypeValid(lateStop.timeType)
      ) {
        const compareDateTime = Date.parse(podDateTime);
        const diff =
          lateStop.timeType === TimeType.ScheduledTime
            ? compareDateTime - Date.parse(manifestStop?.scheduledDateTime as string)
            : compareDateTime - Date.parse(manifestStop?.lateDateTime as string);
        const late = diff >= Number(lateStop.stopMinutes) * 60 * 1000;
        return late;
      }
    }
    return false;
  }, [
    lateStops,
    manifestStop.job.site?.siteId,
    manifestStop?.lateDateTime,
    manifestStop.order.customer.customerId,
    manifestStop.order.customer.group,
    manifestStop.order.service,
    manifestStop?.scheduledDateTime,
    manifestStop.stopType,
    podDateTime,
    services
  ]);

  const setValueAndValidate = useCallback(
    (fieldName: any, fieldValue: string) => {
      setValue(fieldName, fieldValue, {
        shouldDirty: true,
        shouldValidate: true
      });
    },
    [setValue]
  );

  const lateReasonDisabled = useCallback(() => {
    if (podDateTime && !isNaN(Date.parse(podDateTime))) {
      const late = isLate();
      if (late && getValues().lateReason === undefined) {
        setValueAndValidate("lateReason", "");
      }
      return !late;
    } else {
      return true;
    }
  }, [getValues, isLate, podDateTime, setValueAndValidate]);

  const eventTypeItemRenderer = (item: EventType) => {
    return (
      <MenuItem
        text={item.eventType}
        selected={getValues()?.lateReason === item.eventType}
        onClick={() => {
          console.debug("lateItemClicked " + item.eventType);
          setValueAndValidate("lateReason", item.eventType as string);
        }}
      />
    );
  };

  //pod date time validate
  useEffect(() => {
    clearErrors(["podDateTime"]);
    if (podDateTime) {
      const parsedPodDate = parseISO(podDateTime);

      //compare entered pod time with arrival time
      if (arrivalDateTime && +parsedPodDate - +parseISO(arrivalDateTime) < Constants.ARRIVAL_TIME_PRIOR_AT_LEAST) {
        if (!parsedPodDate) {
          control.setError("podDateTime", {message: DateTimeErrorMsg.PodPriorArrTime});
        }

        if (Date.parse(parsedPodDate.toISOString()) - Date.parse(arrivalDateTime) <= 60000) {
          control.setError("podDateTime", {message: DateTimeErrorMsg.PodPriorArrTime});
        }
      }

      if (compareAsc(parsedPodDate, new Date()) > 0) {
        control.setError("podDateTime", {message: DateTimeErrorMsg.FutureTimeError});
      }
    }
  }, [
    arrivalDateTime,
    clearErrors,
    control,
    currentDateTimeInJobStopTZ,
    offsetMinutesBetweenJobStopAndBrowser,
    podDateTime
  ]);

  //arrival time validate
  useEffect(() => {
    clearErrors(["arrivalDateTime"]);
    if (arrivalDateTime) {
      const arrival = parseISO(arrivalDateTime);
      const now = new Date();

      if (+arrival - +now > 0) {
        control.setError("arrivalDateTime", {message: DateTimeErrorMsg.FutureTimeError});
      }
    }
  }, [arrivalDateTime, clearErrors, control]);

  //departure time validate
  useEffect(() => {
    clearErrors(["departureDateTime"]);

    //compare departure time with arrival time
    if (
      departureDateTime &&
      arrivalDateTime &&
      +parseISO(departureDateTime) - +parseISO(arrivalDateTime) < Constants.ARRIVAL_TIME_PRIOR_AT_LEAST
    ) {
      control.setError("departureDateTime", {message: DateTimeErrorMsg.DepTimePriorArrTime});
      if (isValidDateTime(departureDateTime)) {
        clearErrors("departureDateTime");
        control.setError("departureDateTime", {message: DateTimeErrorMsg.DepTimePriorArrTime});
      }
      if (+startOfDay(parseISO(departureDateTime)) - +startOfDay(parseISO(arrivalDateTime)) < 0) {
        clearErrors("departureDateTime");
        control.setError("departureDateTime", {message: DateTimeErrorMsg.DepTimePriorArrTime});
      }
    }
    //compare departure time with current browser time
    if (departureDateTime) {
      const departure = parseISO(departureDateTime);
      const now = new Date();

      if (+departure - +now > 0) {
        control.setError("departureDateTime", {message: DateTimeErrorMsg.FutureTimeError});
      }
    }
  }, [arrivalDateTime, clearErrors, control, departureDateTime]);

  const handleTimeChange = useCallback(
    (value: unknown, field: ControllerRenderProps<IFormValues, any>) => {
      const fieldDateTime = field.value ? new Date(field.value) : new Date();
      if (isValidDateTime(fieldDateTime) && isValidDateTime(value)) {
        const time = value as Date;
        setValueAndValidate(field.name, getDateTime(fieldDateTime, time).toISOString());
      }
    },
    [setValueAndValidate]
  );

  const handleDateChange = useCallback(
    (newDate: string | null, field: ControllerRenderProps<IFormValues, any>) => {
      if (newDate !== null) {
        const fieldDateTime = field.value ? new Date(field.value) : new Date();
        if (isValidDateTime(fieldDateTime)) {
          const date = parse(newDate, "yyyy-MM-dd", new Date());
          setValueAndValidate(field.name, getDateTime(date, fieldDateTime).toISOString());
        }
      }
    },
    [setValueAndValidate]
  );

  const handleCurrentPOD = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setValueAndValidate("podDateTime", new Date().toISOString());
      }
    },
    [setValueAndValidate]
  );
  const handleCurrentArrival = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setValueAndValidate("arrivalDateTime", new Date().toISOString());
      }
    },
    [setValueAndValidate]
  );

  const handleCurrentDeparture = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setValueAndValidate("departureDateTime", new Date().toISOString());
      }
    },
    [setValueAndValidate]
  );

  const isNotFutureDate = useCallback((dateToCheck: string | undefined) => {
    if (dateToCheck === undefined) return true;
    return new Date(dateToCheck) <= new Date() || DateTimeErrorMsg.FutureTimeError;
  }, []);

  const isValidDate = useCallback((dateToCheck: string | undefined) => {
    if (dateToCheck === undefined) return true;
    return isValidDateTime(dateToCheck) || DateTimeErrorMsg.InvalidDate;
  }, []);

  const isPodDateTimeAfterArrivalDateTime = useCallback(
    (podDateTime: string | undefined, arrivalDateTime: string | undefined) => {
      if (arrivalDateTime && podDateTime) {
        if (+new Date(podDateTime) - +new Date(arrivalDateTime) < Constants.ARRIVAL_TIME_PRIOR_AT_LEAST) {
          return DateTimeErrorMsg.PodPriorArrTime;
        }
      }
      return true;
    },
    []
  );

  const isDepartureDateTimeAfterArrivalDateTime = useCallback(
    (departureDateTime: string | undefined, arrivalDateTime: string | undefined) => {
      if (departureDateTime && arrivalDateTime) {
        if (+new Date(departureDateTime) - +new Date(arrivalDateTime) < Constants.ARRIVAL_TIME_PRIOR_AT_LEAST) {
          return DateTimeErrorMsg.DepTimePriorArrTime;
        }
      }
      return true;
    },
    []
  );

  const podDateTimeValidator = useMemo<DateTimeValidator>(() => {
    return {
      isNotFuture: (v) => isNotFutureDate(v),
      isValid: (v) => isValidDate(v),
      isPodAfterArrivalTime: (v) => isPodDateTimeAfterArrivalDateTime(v, arrivalDateTime)
    };
  }, [arrivalDateTime, isNotFutureDate, isPodDateTimeAfterArrivalDateTime, isValidDate]);

  const arrivalDateTimeValidator = useMemo<DateTimeValidator>(() => {
    return {
      isNotFuture: (v) => isNotFutureDate(v),
      isValid: (v) => isValidDate(v),
      isPodAfterArrivalTime: (v) => isPodDateTimeAfterArrivalDateTime(podDateTime, v),
      isDepartureAfterArrivalTime: (v) => isDepartureDateTimeAfterArrivalDateTime(departureDateTime, v)
    };
  }, [
    departureDateTime,
    isDepartureDateTimeAfterArrivalDateTime,
    isNotFutureDate,
    isPodDateTimeAfterArrivalDateTime,
    isValidDate,
    podDateTime
  ]);

  const departureDateTimeValidator = useMemo<DateTimeValidator>(() => {
    return {
      isNotFuture: (v) => isNotFutureDate(v),
      isValid: (v) => isValidDate(v),
      isDepartureAfterArrivalTime: (v) => isDepartureDateTimeAfterArrivalDateTime(v, arrivalDateTime)
    };
  }, [arrivalDateTime, isDepartureDateTimeAfterArrivalDateTime, isNotFutureDate, isValidDate]);

  useEffect(() => {
    console.debug("Field validation warnings", {
      errors: errors,
      validatingFields: validatingFields,
      touchedFields: touchedFields,
      isValid: isValid
    });
  }, [errors, isValid, touchedFields, validatingFields]);

  return (
    <StyledDialog isOpen={true} title={titleMessage} isCloseButtonShown={false}>
      <DialogBody>
        <ManifestSequence>#{manifestStop.manifestSequence}</ManifestSequence>
        <HeaderHR />
        <InfoGroup>
          <InfoItem>
            <InfoTitleLeft>Expected delivery time</InfoTitleLeft>
            {manifestStop.earlyDateTime && (
              <InfoLineItemLeft title={new Date(Date.parse(manifestStop.earlyDateTime as string)).toLocaleDateString()}>
                Early time: {format(Date.parse(manifestStop.earlyDateTime), "MM/dd/yyyy - hh:mm a")}
              </InfoLineItemLeft>
            )}
            {manifestStop.scheduledDateTime && (
              <InfoLineItemLeft
                title={new Date(Date.parse(manifestStop.scheduledDateTime as string)).toLocaleDateString()}
              >
                Scheduled time: {format(Date.parse(manifestStop.scheduledDateTime), "MM/dd/yyyy - hh:mm a")}
              </InfoLineItemLeft>
            )}
            {manifestStop.lateDateTime && (
              <InfoLineItemLeft title={new Date(Date.parse(manifestStop.lateDateTime as string)).toLocaleDateString()}>
                Late time: {format(Date.parse(manifestStop.lateDateTime), "MM/dd/yyyy - hh:mm a")}
              </InfoLineItemLeft>
            )}
          </InfoItem>
          <InfoItem>
            <InfoTitleRight>Stop location</InfoTitleRight>
            <InfoLineItemRight>{manifestStop.address}</InfoLineItemRight>
            <InfoLineItemRight>
              {manifestStop.city}, {manifestStop.state}
            </InfoLineItemRight>
            <InfoLineItemRight>{manifestStop.zip}</InfoLineItemRight>
          </InfoItem>
        </InfoGroup>
        <StyledForm onSubmit={handleSubmit(submitHandler, failedSubmitHandler) as any}>
          {/* POD Information */}
          <InfoTitleLeft>1. POD Information</InfoTitleLeft>
          <StyledLabel htmlFor="podName" isRequired={podRequired}>
            Name of the person who accepted delivery
          </StyledLabel>
          <Controller
            name="podName"
            control={control}
            rules={{
              required: podRequired,
              validate: {
                required: (v) => {
                  if (podRequired && v === "") {
                    return "POD name is required";
                  }
                  return true; //if not required, always return true to pass the required check
                }
              }
            }}
            render={({field}) => <InputGroup id="podName" placeholder="ex. Anna Smith" {...field} />}
          />
          <DateTimeGroup>
            <DateInputContainer>
              <StyledLabel htmlFor="podDate" isRequired={podRequired}>
                POD date
              </StyledLabel>
              <Controller
                name="podDateTime"
                control={control}
                rules={{
                  required: podRequired,
                  validate: podDateTimeValidator
                }}
                render={({field}) => (
                  <StyledDateInput
                    isValid={!errors.podDateTime}
                    formatDate={(date) => {
                      return format(date, "MM/dd/yyyy");
                    }}
                    parseDate={(str) => {
                      return parse(str, "MM/dd/yyyy", new Date());
                    }}
                    placeholder="ex. 01/01/2021"
                    onError={(error) => {
                      if (error) {
                        control.setError("podDateTime", {
                          message: DateTimeErrorMsg.InvalidDate
                        });
                      }
                    }}
                    {...field}
                    onChange={(newDate: string | null) => handleDateChange(newDate, field)}
                    inputProps={{
                      id: "podDate"
                    }}
                    className="podDate"
                    rightElement={
                      <CalendarIcon
                        style={{cursor: "pointer"}}
                        icon={light("calendar")}
                        onClick={() => {
                          const inputEl = document.querySelector("div.podDate input") as HTMLInputElement;
                          inputEl?.focus();
                        }}
                      />
                    }
                  />
                )}
              />
            </DateInputContainer>
            <div>
              <StyledLabel htmlFor="podTime" isRequired={podRequired}>
                POD time
                <TimeInforIcon />
              </StyledLabel>
              <Controller
                name="podDateTime"
                control={control}
                rules={{
                  required: podRequired,
                  validate: podDateTimeValidator
                }}
                render={({field}) => (
                  <StyledTimePicker
                    isValid={!errors.podDateTime}
                    className="optimization-time-picker podTime"
                    {...field}
                    value={new Date(field.value)}
                    onChange={(value: unknown) => handleTimeChange(value, field)}
                  />
                )}
              />
            </div>
            <StyledCheckboxContainer>
              <StyledCheckbox label={"Current Date & Time"} onChange={handleCurrentPOD} />
            </StyledCheckboxContainer>
          </DateTimeGroup>
          {/* Late reason */}
          <InfoTitleLeft>2. State the Reason for the Late Arrival Time</InfoTitleLeft>
          <Controller
            name="lateReason"
            control={control}
            rules={{
              required: !lateReasonDisabled(),
              validate: {
                required: (v) => {
                  if (lateReasonDisabled()) return true;
                  return v !== "(none)" || "Late reason is required";
                }
              }
            }}
            disabled={lateReasonDisabled()}
            render={({field}) => (
              <StyledSelect
                items={eventTypes}
                itemRenderer={eventTypeItemRenderer}
                filterable={false}
                onItemSelect={() => {
                  //NOOP
                }}
              >
                <StyledSelectButton
                  text={field.value && field.value.length > 1 ? field.value : "Select an option"}
                  rightIcon="caret-down"
                  className={field.value && field.value.length > 1 ? "hasvalue" : ""}
                  disabled={lateReasonDisabled()}
                />
              </StyledSelect>
            )}
          />
          {/* Arrival Departure */}
          <InfoTitleLeft>
            3. State Arrival / Departure Time {!requireArrAndDepTimes && <LighterText>(optional)</LighterText>}
          </InfoTitleLeft>
          <DateTimeGroup>
            <DateInputContainer>
              <StyledLabel htmlFor="arrivalDate" isRequired={requireArrAndDepTimes}>
                Arrival date
              </StyledLabel>
              <Controller
                name="arrivalDateTime"
                control={control}
                rules={{
                  required: requireArrAndDepTimes,
                  validate: arrivalDateTimeValidator
                }}
                render={({field}) => (
                  <StyledDateInput
                    isValid={!errors.arrivalDateTime}
                    formatDate={(date) => {
                      return format(date, "MM/dd/yyyy");
                    }}
                    parseDate={(str) => {
                      return parse(str, "MM/dd/yyyy", new Date());
                    }}
                    placeholder="ex. 01/01/2021"
                    onError={(error) => {
                      if (error) {
                        control.setError("arrivalDateTime", {
                          message: DateTimeErrorMsg.InvalidDate
                        });
                      }
                    }}
                    {...field}
                    onChange={(newDate: string | null) => handleDateChange(newDate, field)}
                    className="arrivalDate"
                    inputProps={{
                      id: "arrivalDate"
                    }}
                    rightElement={
                      <CalendarIcon
                        style={{cursor: "pointer"}}
                        icon={light("calendar")}
                        onClick={() => {
                          const inputEl = document.querySelector("div.arrivalDate input") as HTMLInputElement;
                          inputEl?.focus();
                        }}
                      />
                    }
                  />
                )}
              />
            </DateInputContainer>
            <div>
              <StyledLabel htmlFor="arrivalTime" isRequired={requireArrAndDepTimes}>
                Arrival time
                <TimeInforIcon />
              </StyledLabel>
              <Controller
                name="arrivalDateTime"
                control={control}
                rules={{
                  required: requireArrAndDepTimes,
                  validate: arrivalDateTimeValidator
                }}
                render={({field}) => (
                  <StyledTimePicker
                    isValid={!errors.arrivalDateTime}
                    className="optimization-time-picker arrivalTime"
                    {...field}
                    value={field.value ? new Date(field.value) : undefined}
                    onChange={(value: unknown) => handleTimeChange(value, field)}
                  />
                )}
              />
            </div>
            <StyledCheckboxContainer>
              <StyledCheckbox label={"Current Date & Time"} onChange={handleCurrentArrival} />
            </StyledCheckboxContainer>
          </DateTimeGroup>
          <DateTimeGroup>
            <DateInputContainer>
              <StyledLabel htmlFor="departureDate" isRequired={requireArrAndDepTimes}>
                Departure date
              </StyledLabel>
              <Controller
                name="departureDateTime"
                control={control}
                rules={{
                  required: requireArrAndDepTimes,
                  validate: departureDateTimeValidator
                }}
                render={({field}) => (
                  <StyledDateInput
                    isValid={!errors.departureDateTime}
                    formatDate={(date) => {
                      return format(date, "MM/dd/yyyy");
                    }}
                    parseDate={(str) => {
                      return parse(str, "MM/dd/yyyy", new Date());
                    }}
                    placeholder="ex. 01/01/2021"
                    onError={(error) => {
                      if (error) {
                        control.setError("departureDateTime", {
                          message: DateTimeErrorMsg.InvalidDate
                        });
                      }
                    }}
                    {...field}
                    onChange={(newDate: string | null) => handleDateChange(newDate, field)}
                    inputProps={{
                      id: "departureDate"
                    }}
                    className="departureDate"
                    rightElement={
                      <CalendarIcon
                        style={{cursor: "pointer"}}
                        icon={light("calendar")}
                        onClick={() => {
                          const inputEl = document.querySelector("div.departureDate input") as HTMLInputElement;
                          inputEl?.focus();
                        }}
                      />
                    }
                  />
                )}
              />
            </DateInputContainer>
            <div>
              <StyledLabel htmlFor="departureTime" isRequired={requireArrAndDepTimes}>
                Departure time
                <TimeInforIcon />
              </StyledLabel>
              <Controller
                name="departureDateTime"
                control={control}
                rules={{
                  required: requireArrAndDepTimes,
                  validate: departureDateTimeValidator
                }}
                render={({field}) => (
                  <StyledTimePicker
                    isValid={!errors.departureDateTime}
                    className="optimization-time-picker departureTime"
                    {...field}
                    value={field.value ? new Date(field.value) : undefined}
                    onChange={(value: unknown) => handleTimeChange(value, field)}
                  />
                )}
              />
            </div>
            <StyledCheckboxContainer>
              <StyledCheckbox label={"Current Date & Time"} onChange={handleCurrentDeparture} />
            </StyledCheckboxContainer>
          </DateTimeGroup>
          {/* Optional Peices and Weight */}
          <InfoTitleLeft>
            4. Pieces / Weight <LighterText>(optional)</LighterText>
          </InfoTitleLeft>
          <PiecesAndWeightGroup>
            <div>
              <StyledLabel htmlFor="pieces">Pieces</StyledLabel>
              <Controller
                name="pieces"
                control={control}
                disabled={piecesOnTabExists}
                render={({field}) => <StyledInputGroup placeholder="0" {...field} />}
              ></Controller>
            </div>
            <div>
              <StyledLabel htmlFor="weight">Weight</StyledLabel>
              <Controller
                name="weight"
                control={control}
                disabled={piecesOnTabExists}
                render={({field}) => (
                  <StyledInputGroup placeholder="0" {...field} rightElement={<WeightLbsTag>lbs</WeightLbsTag>} />
                )}
              ></Controller>
            </div>
          </PiecesAndWeightGroup>
          <ButtonGroup>
            <CancelButton
              minimal
              text="Cancel"
              onClick={() => {
                onCancel?.();
              }}
            />
            <MarkCompleteButton type="submit" text="Mark Complete" disabled={!isValid || !isEmpty(errors)} />
          </ButtonGroup>
          {[...new Set(Object.values(errors).map((err) => err.message))].map((item) => (
            <StyledErrorText data-testid="error-message" key={item}>
              {item}
            </StyledErrorText>
          ))}
        </StyledForm>
      </DialogBody>
    </StyledDialog>
  );
};

const StyledErrorText = styled(Text)`
  font-family: Roboto;
  font-size: smaller;
  color: rgba(250, 84, 94, 1);

  margin: 10px;
`;

const StyledSelectButton = styled(Button)`
  color: rgba(141, 164, 190, 1) !important;
  background-color: white !important;
  width: 216px;

  &.hasvalue,
  &.hasvalue:hover {
    background-color: rgba(141, 164, 190, 1) !important;
    color: white !important;
  }

  &.hasvalue .bp4-icon,
  &.hasvalue .bp4-icon:hover {
    background-color: rgba(141, 164, 190, 1) !important;
    color: white !important;
  }

  :disabled {
    color: rgba(95, 107, 124, 0.6) !important;
  }

  .bp4-button-text {
    flex: 1;
  }

  .bp4-icon {
    color: rgba(141, 164, 190, 1) !important;
  }
`;
const StyledSelect = styled(Select2<EventType>)`
  width: 216px;

  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;

const CalendarIcon = styled(FontAwesomeIcon)`
  margin: 0.5em;
`;

const StyledTimePicker = styled(TimePicker)<{isValid?: boolean}>`
  width: 120px;
  .MuiInputBase-root {
    width: 120px !important;
    font-size: 14px !important;
    padding-left: 12px;

    .MuiOutlinedInput-notchedOutline {
      border: ${(props) => (props.isValid ? "solid 1px #dedede !important" : "solid 1px #cd4246 !important")};
    }
  }

  svg {
    font-size: 1.25rem;
  }
`;

const StyledDateInput = styled(DateInput2)<{isValid?: boolean}>`
  font-family: Roboto;
  input {
    border: ${(props) => (props.isValid ? "solid 1px #dedede !important" : "solid 1px #cd4246 !important")};
    box-shadow: none;
    border-radius: 4px;
    height: 32px;
  }

  .bp4-divider {
    display: none;
  }

  .DayPicker-Day--selected {
    background-color: #8da4be !important;
    border-radius: 4px !important;
  }

  .bp4-datepicker-day-wrapper {
    font-size: 14px;
    font-weight: 400;
    padding: 0;
    box-shadow: none;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
  }

  .DayPicker-Weekdays {
    font-size: 15px;
    font-weight: 500;

    .DayPicker-Weekday {
      padding: 0;
      height: 40px;
      font-size: 15px;
      font-weight: 500;
    }
  }

  .DayPicker-Caption {
    .bp4-datepicker-caption {
      justify-content: center;

      > * {
        flex-grow: 0 !important;
      }
      align-items: center;
      .bp4-html-select {
        select {
          padding: 4px !important;
          font-size: 16px;
          font-weight: 500;
        }
        option {
          padding: 5px !important;
        }
      }

      .bp4-icon-double-caret-vertical {
        display: none;
      }
    }
  }
`;

const StyledInputGroup = styled(InputGroup)`
  width: 216px;
`;

const WeightLbsTag = styled.div`
  margin-top: 0.5em;
  margin-right: 0.5em;
`;

const LighterText = styled.span`
  color: rgba(121, 121, 121, 1);
`;

const StyledLabel = styled(Label)<{isRequired?: boolean}>`
  color: rgba(121, 121, 121, 1);
  font-size: 12px;
  height: 16px;
  margin-bottom: 4px !important;
  display: flex !important;
  align-items: center !important;
  gap: 4px !important;

  :after {
    content: " *";
    color: red;
    display: ${(props) => (props.isRequired ? "inline-block" : "none")};
  }
`;

const DateTimeGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 12px;
  margin-bottom: 12px;
`;

const PiecesAndWeightGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 12px;
  margin-bottom: 12px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 12px;
  margin-top: 48px;
`;

const MarkCompleteButton = styled(Button)`
  &.bp4-button {
    color: white;
    background: linear-gradient(180deg, #214a89 0%, #14305a 100%);
  }

  &.bp4-button:disabled {
    color: rgba(95, 107, 124, 0.6);
    background: rgba(211, 216, 222, 0.5);
  }
`;

const CancelButton = styled(Button)`
  color: rgba(20, 48, 90, 1) !important;
`;

const DialogBody = styled.div`
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InfoGroup = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const InfoTitleLeft = styled.h3`
  font-weight: normal;
`;

const InfoTitleRight = styled.h3`
  font-weight: normal;
  text-align: right;
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoLineItemRight = styled.div`
  text-align: right;
`;

const InfoLineItemLeft = styled.div`
  text-align: left;
`;

const HeaderHR = styled.hr`
  height: 5px;
  background-color: rgba(20, 48, 90, 1);
  width: 90%;
  border-radius: 5px;
`;

const ManifestSequence = styled.div`
  margin-top: 12px;
  font-size: 18px;
  gap: 6px;
`;

const StyledDialog = styled(Dialog)`
  .bp4-dialog-header {
    background-color: rgba(248, 248, 248, 1);
  }

  .bp4-heading {
    font-size: smaller;
    text-transform: uppercase;
  }
`;

const StyledForm = styled.form`
  width: 90%;

  .MuiInputBase-input {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin: 0;
  transform: translate(0, -50%);
`;

const StyledCheckboxContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const DateInputContainer = styled.div`
  width: 135px;
`;
export default CompleteStopDialog;
export type {CompleteStopDialogProps};
export {TimeType, TriggerType};
