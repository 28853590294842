import React, {forwardRef, useContext, useImperativeHandle, useState} from "react";
import styled from "@emotion/styled";
import PresentContinuouslyForm from "./PresentContinuouslyForm";
import PresentUponHoverForm, {hoverOnDriverValues, hoverOnStopsValues} from "./PresentUponHoverForm";
import {FormProvider, SubmitHandler, useForm} from "react-hook-form";
import {PreferenceContext, createJsonPref, extractJsonPref} from "../../../providers/PreferenceProvider";
import {UserPreferences} from "../../common/Constants";
import {useSetUserPrefMutation} from "../../../generated/graphql";
import {AdditionalStopInformationValue, TopStopInformationValue, StopLocationValue} from "./ManifestConfigurationTypes";
import {Button, Dialog, Tab, TabId, Tabs} from "@blueprintjs/core";
import {IConfigurationFormValues} from "./ManifestCardConfiguration";
import {renderManifestOption} from "./ManifestConfigService";

const StyledDialog = styled(Dialog)`
  margin: 0;
  height: 90vh;
  width: 664px;
  font-family: "Roboto", sans-serif;
  color: #161616;
  display: flex;
  flex-direction: column;
  position: relative;

  .bp4-dialog-header {
    padding: 12px 12px 12px 24px;
    line-height: 16px;
    font-size: 12px;
    text-transform: uppercase;
    background-color: #f8f8f8;

    .bp4-heading {
      font-weight: 500;
    }
  }

  .bp4-tab-list {
    display: flex;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 0 24px;
    height: 54px;
    background-color: #ffffff;

    .bp4-tab-indicator-wrapper {
      z-index: 2;
      .bp4-tab-indicator {
        height: 4px;
        background-color: #14305a;
        border-radius: 2px;
      }
    }
    ::after {
      content: "";
      z-index: 1;
      position: absolute;
      width: 612px;
      bottom: 1px;
      border-bottom: 2px solid #d9d9d9;
    }

    .bp4-tab {
      flex: 1;
      display: flex;
      justify-content: center;
    }
  }
`;

const StyledForm = styled.form`
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DialogBody = styled.div`
  margin-top: 54px;
  overflow-y: auto;
  flex-grow: 1;
  background-color: #ffffff;
  padding: 8px 24px 24px;
`;

const DialogFooter = styled.div`
  padding: 24px 24px 28px;
  background-color: #f8f8f8;
  border-radius: 0px 0px 4px 4px;
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UpdateButton = styled(Button)`
  &.bp4-button:not([class*="bp4-intent-"]) {
    color: #ffffff;
    background-color: #14305a;
    background: linear-gradient(0deg, rgba(15, 18, 23, 0.1), rgba(15, 18, 23, 0.1)),
      linear-gradient(0deg, #14305a, #14305a) !important;
    border: 1px solid #d9d9d9 !important;
    box-shadow: 0px 1px 2px 0px #0000000d !important;
  }

  &.bp4-button:not([class*="bp4-intent-"]):disabled {
    color: #fbfbfb;
    background-color: #d9d9d9;
    background: linear-gradient(0deg, rgba(15, 18, 23, 0.1), rgba(15, 18, 23, 0.1)),
      linear-gradient(0deg, #d9d9d9, #d9d9d9) !important;
    border: 1px solid #d9d9d9 !important;
    box-shadow: 0px 1px 2px 0px #0000000d !important;
  }
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  height: 32px;
  padding: 6px 14px;
  border: 1px;
  gap: 7px;
`;

const CancelButton = styled.button`
  background: transparent;
  cursor: pointer;
  padding: 0;
  color: #14305a;
  font-size: 16px;
  font-weight: 500;
  height: 32px;
  border: none !important;

  :disabled {
    color: #d9d9d9;
    cursor: not-allowed;
  }
`;

export const DEFAULT_CARD_CONFIGURATIONS_VALUE: IConfigurationFormValues = {
  driverCapabilities: true,
  driverCode: false,
  driverName: false,
  driverCodeAndName: true,
  topStopInformation: TopStopInformationValue.LastCompletedStop,
  stopLocation: StopLocationValue.FullAddressAndZone,
  additionalStopLocation: AdditionalStopInformationValue.None,
  hoverDriverConfig: renderManifestOption(hoverOnDriverValues).slice(0, 5),
  hoverStopsConfig: renderManifestOption(hoverOnStopsValues).slice(0, 5)
};

export type ConfigurationDialogRef = {
  handleOpenDialog: () => void;
};

const ConfigurationDialog = forwardRef<ConfigurationDialogRef>((_, ref) => {
  const [currentTab, setCurrentTab] = useState<TabId>(0);
  const [isOpen, setIsOpen] = useState(false);
  const [setUserPref, {loading}] = useSetUserPrefMutation();

  const {userPreferences, userPrefsQueryRefetch} = useContext(PreferenceContext);

  const initialValue: IConfigurationFormValues = extractJsonPref(
    userPreferences,
    UserPreferences.manifestCardConfigurations,
    DEFAULT_CARD_CONFIGURATIONS_VALUE
  ).value;

  const methods = useForm<IConfigurationFormValues>({defaultValues: initialValue});

  const {
    formState: {isDirty},
    handleSubmit,
    reset
  } = methods;

  const handleCloseDialog = () => {
    reset();
    setIsOpen(false);
    setCurrentTab(0);
  };
  const onSubmit: SubmitHandler<IConfigurationFormValues> = async (data) => {
    try {
      const updatedValue = createJsonPref(data, true);
      await setUserPref({
        variables: {
          name: UserPreferences.manifestCardConfigurations,
          input: updatedValue
        }
      });
      userPrefsQueryRefetch?.();
      reset(data);
      setIsOpen(false);
      setCurrentTab(0);
    } catch (error) {
      console.error(error);
    }
  };

  useImperativeHandle(ref, () => ({
    handleOpenDialog: () => {
      setIsOpen(true);
    }
  }));

  return (
    <StyledDialog data-testid="" title="manifest card content settings" isOpen={isOpen} onClose={handleCloseDialog}>
      <FormProvider {...methods}>
        <StyledForm
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogBody style={{pointerEvents: loading ? "none" : "auto"}}>
            <Tabs onChange={setCurrentTab} selectedTabId={currentTab} large>
              <Tab
                id={0}
                title="Present Continuously"
                panel={<PresentContinuouslyForm />}
                style={{fontWeight: currentTab === 0 ? "500" : "normal"}}
              />
              <Tab
                id={1}
                title="Present Upon Hover"
                panel={<PresentUponHoverForm />}
                style={{fontWeight: currentTab === 1 ? "500" : "normal"}}
              />
            </Tabs>
          </DialogBody>
          <DialogFooter>
            <ActionsContainer>
              <CancelButton data-testid="cancel-button" onClick={handleCloseDialog}>
                Cancel
              </CancelButton>
              <UpdateButton data-testid="update-button" disabled={!isDirty} type="submit" loading={loading}>
                Update
              </UpdateButton>
            </ActionsContainer>
          </DialogFooter>
        </StyledForm>
      </FormProvider>
    </StyledDialog>
  );
});

ConfigurationDialog.displayName = "ConfigurationDialog";

export default ConfigurationDialog;
