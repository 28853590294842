import {Button, Intent, Text} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {ICellRendererParams} from "@ag-grid-community/core";
import React, {useContext} from "react";
import {User} from "./UserManagement";
import {resendActivation} from "../../../services/TenantService";
import {AppToaster} from "../../../utils/toaster";
import {AuthContext} from "../../AuthProvider";

const Cell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const UserStatusContainer = styled.div`
  min-width: 0;
  line-height: normal;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;

  min-width: 0;
  line-height: normal;
`;

const ResubmitBtn = styled(Button)`
  line-height: normal;
  padding-left: 14px;
  text-decoration: underline;
`;

const handleSendReactivationEmail = (user: User, token: string) => {
  resendActivation(token, user.profile.id)
    .then(() => {
      AppToaster.show({
        intent: Intent.SUCCESS,
        icon: "tick",
        message: `Resent Invite to User: ${user.profile.email}.`
      });
    })
    .catch((e: any) => {
      console.error("Failed to resend invite to user", e);
      AppToaster.show({
        intent: Intent.WARNING,
        icon: "warning-sign",
        message: "Failed to resend invite to user."
      });
    });
};

const StatusCellRenderer = (props: ICellRendererParams<any, number>) => {
  const authContext = useContext(AuthContext);

  return (
    <Cell>
      <UserStatusContainer>
        <StatusContainer>
          <Text ellipsize>{props.data.status}</Text>
        </StatusContainer>
        {props.data.status === "PROVISIONED" && (
          <ResubmitBtn
            data-testid={`resend-activation-btn-${props.data.profile.id}`}
            text={"Resend Invite"}
            intent={Intent.PRIMARY}
            minimal
            small
            onClick={() => handleSendReactivationEmail(props.data, authContext.token as string)}
          />
        )}
      </UserStatusContainer>
    </Cell>
  );
};

export default StatusCellRenderer;
