import styled from "@emotion/styled";

export const ManifestWarningMessage = ({content}: {content: string}) => {
  return (
    <ManifestWarningMessageContainer data-testid="warning-message-container">
      <WarningIcon />
      <ManifestWarningMessageText data-testid="warning-message-text">{content}</ManifestWarningMessageText>
    </ManifestWarningMessageContainer>
  );
};
const ManifestWarningMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-left: 30px;
  margin-bottom: 8px;
`;
const ManifestWarningMessageText = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
`;
const WarningIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 14C8.85651 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85651 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85651 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85651 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14ZM7 3.5C7.36367 3.5 7.65625 3.79258 7.65625 4.15625V7.21875C7.65625 7.58242 7.36367 7.875 7 7.875C6.63633 7.875 6.34375 7.58242 6.34375 7.21875V4.15625C6.34375 3.79258 6.63633 3.5 7 3.5ZM6.125 9.625C6.125 9.39293 6.21719 9.17038 6.38128 9.00628C6.54538 8.84219 6.76794 8.75 7 8.75C7.23206 8.75 7.45462 8.84219 7.61872 9.00628C7.78281 9.17038 7.875 9.39293 7.875 9.625C7.875 9.85706 7.78281 10.0796 7.61872 10.2437C7.45462 10.4078 7.23206 10.5 7 10.5C6.76794 10.5 6.54538 10.4078 6.38128 10.2437C6.21719 10.0796 6.125 9.85706 6.125 9.625Z"
        fill="#FF6B00"
      />
    </svg>
  );
};
