import styled from "@emotion/styled";
import VehicleIconSetupItem from "./VehicleIconSetupItem";
import {VehiclePrefItemType} from "./VehicleIconsSetup";
import {VehicleItemType} from "../../vehicles/VehicleIconResolver";

type VehicleIconTableProps = {
  icons: VehicleItemType[];
  vehicleIconsTenantPref: VehiclePrefItemType[];
  updateTenantPrefs: (prefItem: VehiclePrefItemType) => void;
};

const VehicleIconTable = ({icons, updateTenantPrefs, vehicleIconsTenantPref}: VehicleIconTableProps) => {
  return (
    <Table data-testid="vehicle-icon-setup-table">
      <Header>
        <HeaderCell>Icon</HeaderCell>
        <HeaderCell>Associated strings</HeaderCell>
      </Header>
      <Body>
        {icons.map((item) => (
          <VehicleIconSetupItem
            key={item.id}
            icon={item}
            updateTenantPrefs={updateTenantPrefs}
            vehicleIconsTenantPref={vehicleIconsTenantPref}
          />
        ))}
      </Body>
    </Table>
  );
};

export default VehicleIconTable;

const Table = styled.div`
  flex: 1;
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  overflow: hidden;
  height: fit-content;
`;

const Header = styled.div`
  background-color: #f3f4f6;
  color: #4b5563;
  text-transform: uppercase;
  display: grid;
  grid-template-columns: 70px auto;
  font-size: 14px;
`;

const HeaderCell = styled.div`
  padding: 12px 20px;
  font-size: 13px;
`;

const Body = styled.div`
  div:nth-last-child(1) {
    border-bottom: none;
  }
`;
