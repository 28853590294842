import React, {useEffect} from "react";
import styled from "@emotion/styled";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Checkbox, Radio, Text} from "@blueprintjs/core";
import {RadioGroupCustom} from "../../settings/CompanySettings";
import {
  AdditionalStopInformationValue,
  DriverInformationValue,
  TopStopInformationValue,
  StopLocationValue
} from "./ManifestConfigurationTypes";
import {Controller, useFormContext} from "react-hook-form";
import {IConfigurationFormValues} from "./ManifestCardConfiguration";
import {Tooltip2} from "@blueprintjs/popover2";
import DriverInfoPreviewImage from "./card-config-svgs/DriverInfoPreviewImage.svg";
import StopLocationPreviewImage from "./card-config-svgs/StopLocationPreviewImage.svg";
import AdditionalStopInfoPreviewImage from "./card-config-svgs/AdditionalStopInfoPreviewImage.svg";
import ShowLastComplStopPreviewImage from "./card-config-svgs/ShowLastComplStopPreviewImage.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
`;

const Section = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
`;

const FormItem = styled.div``;

const ImageContainer = styled.div`
  text-align: right;
`;

const Title = styled(Text)`
  font-size: 16px;
  margin-bottom: 8px;
`;

const Subtitle = styled(Text)`
  font-size: 14px;
  color: #797979;
  line-height: 16px;
  margin-bottom: 20px;
`;

export const StyledCheckbox = styled(Checkbox)`
  input:checked ~ .bp4-control-indicator {
    background-color: #14305a !important;
  }

  .label-text {
    color: #797979 !important;
  }

  input:disabled ~ .label-text {
    color: #7777774d !important;
  }

  input:checked ~ .label-text {
    color: #161616 !important;
  }
`;

type TFormOptions = {
  label: string;
  value: DriverInformationValue | AdditionalStopInformationValue | StopLocationValue;
};

const DriverInformationOptions: (TFormOptions & {key: keyof IConfigurationFormValues})[] = [
  {
    key: "driverName",
    label: "Driver Name",
    value: DriverInformationValue.DriverName
  },
  {
    key: "driverCode",
    label: "Driver Code",
    value: DriverInformationValue.DriveCode
  },
  {
    key: "driverCapabilities",
    label: "Driver Capabilities",
    value: DriverInformationValue.DriverCapabilities
  },
  {
    key: "driverCodeAndName",
    label: "Driver Code + Name",
    value: DriverInformationValue.DriverCodeAndName
  }
];

const StopLocationOptions: TFormOptions[] = [
  {
    label: "Full Address + Zone",
    value: StopLocationValue.FullAddressAndZone
  },
  {
    label: "City, State, Zone",
    value: StopLocationValue.CityStateZone
  },
  {
    label: "Zone Only",
    value: StopLocationValue.ZoneOnly
  }
];

const AdditionalStopInformationOptions: TFormOptions[] = [
  {
    label: "Customer Name",
    value: AdditionalStopInformationValue.CustomerName
  },
  {
    label: "Pieces Count",
    value: AdditionalStopInformationValue.PiecesCount
  },
  {
    label: "Route Number",
    value: AdditionalStopInformationValue.RouteNumber
  },
  {
    label: "Stop Name",
    value: AdditionalStopInformationValue.StopName
  }
];

const TopStopInformationOptions: {label: string; value: TopStopInformationValue}[] = [
  {
    label: "Last Completed Stop",
    value: TopStopInformationValue.LastCompletedStop
  },
  {
    label: "Next Stop in Manifest",
    value: TopStopInformationValue.NextStopInManifest
  },
  {
    label: "First Stop in Manifest",
    value: TopStopInformationValue.FirstStopInManifest
  }
];

const PresentContinuouslyForm = () => {
  const {control, watch, setValue} = useFormContext<IConfigurationFormValues>();
  const driverCodeAndNameValue = watch("driverCodeAndName");
  const stopLocationValue = watch("stopLocation");

  useEffect(() => {
    if (driverCodeAndNameValue) {
      setValue("driverCode", false);
      setValue("driverName", false);
    }
    if (stopLocationValue === StopLocationValue.FullAddressAndZone) {
      setValue("additionalStopLocation", AdditionalStopInformationValue.None);
    }
  }, [driverCodeAndNameValue, stopLocationValue, setValue]);

  return (
    <Container>
      <Section>
        <FormItem>
          <Title>1. Driver’s Information</Title>
          <Subtitle>Select one or two options</Subtitle>
          {DriverInformationOptions.map((option) => (
            <Controller
              key={option.key}
              control={control}
              name={option.key}
              render={({field: {onChange, value}}) => (
                <StyledCheckbox
                  disabled={["driverName", "driverCode"].includes(option.key) && driverCodeAndNameValue}
                  onChange={onChange}
                  checked={value as boolean}
                >
                  <span className="label-text">{option.label}</span>
                </StyledCheckbox>
              )}
            />
          ))}
        </FormItem>
        <ImageContainer>
          <img alt="image" src={DriverInfoPreviewImage} />
        </ImageContainer>
      </Section>
      <Section>
        <FormItem>
          <Title>2. Stop Location</Title>
          <Subtitle>Select one option</Subtitle>
          <Controller
            control={control}
            name="stopLocation"
            render={({field: {onChange}}) => (
              <RadioGroupCustom onChange={onChange} selectedValue={stopLocationValue}>
                {StopLocationOptions.map((option) => (
                  <Radio key={option.value} label={option.label} value={option.value} />
                ))}
              </RadioGroupCustom>
            )}
          />
        </FormItem>
        <ImageContainer>
          <img alt="image" src={StopLocationPreviewImage} />
        </ImageContainer>
      </Section>
      <Section>
        <FormItem>
          <Title>
            3. Additional Stop Information &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Tooltip2
              content={
                <Text style={{fontSize: "12px", maxWidth: "270px"}}>
                  Available only if <b>‘City, State, Zone’</b> or <b>‘Zone Only’</b> is selected as Stop Location
                </Text>
              }
              popoverClassName="white-popover"
              position="top"
            >
              <FontAwesomeIcon icon={solid("circle-info")} size={"lg"} color="#797979" style={{cursor: "pointer"}} />
            </Tooltip2>
          </Title>
          <Subtitle style={{opacity: stopLocationValue === StopLocationValue.FullAddressAndZone ? "0.7" : "1"}}>
            Select one option
          </Subtitle>
          <Controller
            control={control}
            name="additionalStopLocation"
            defaultValue={AdditionalStopInformationValue.None}
            render={({field}) => (
              <RadioGroupCustom
                {...field}
                selectedValue={field.value}
                disabled={stopLocationValue === StopLocationValue.FullAddressAndZone}
              >
                {AdditionalStopInformationOptions.map((option) => (
                  <Radio
                    key={option.value}
                    label={option.label}
                    value={option.value}
                    onClick={(e: any) => {
                      const newValue = e.target.value;
                      field.onChange(field.value === newValue ? AdditionalStopInformationValue.None : newValue);
                    }}
                  />
                ))}
              </RadioGroupCustom>
            )}
          />
        </FormItem>
        <ImageContainer style={{opacity: stopLocationValue === StopLocationValue.FullAddressAndZone ? "0.3" : "1"}}>
          <img alt="image" src={AdditionalStopInfoPreviewImage} />
        </ImageContainer>
      </Section>
      <Section>
        <FormItem>
          <Title>4. Top Stop Information</Title>
          <Controller
            control={control}
            name="topStopInformation"
            defaultValue={TopStopInformationValue.LastCompletedStop}
            render={({field: {onChange, value}}) => (
              <RadioGroupCustom onChange={onChange} selectedValue={value}>
                {TopStopInformationOptions.map((option) => (
                  <Radio key={option.label} label={option.label} value={option.value} />
                ))}
              </RadioGroupCustom>
            )}
          />
        </FormItem>
        <ImageContainer>
          <img alt="image" src={ShowLastComplStopPreviewImage} />
        </ImageContainer>
      </Section>
    </Container>
  );
};

export default PresentContinuouslyForm;
