import _ from "lodash";
import {IndicatorLogicType, UserColor} from "./types/indicatorLogicType";

const getColorRankMap = (rules: IndicatorLogicType[]) => {
  //sort rules by rank
  const sortedRules = _.sortBy(rules, "rank");
  const colorMap = new Map<UserColor, number>();
  let rank = 1;
  sortedRules.forEach((rule) => {
    if (rule.color && !colorMap.has(rule.color)) {
      colorMap.set(rule.color, rank++);
    }
  });
  return colorMap;
};

const getRankLogicsGroupedByColor = (logics: IndicatorLogicType[]) => {
  const rules = [...logics];

  const colorMap = getColorRankMap(rules);

  //re-map rank
  const updatedRules = rules.map((rule) => {
    if (rule.color) {
      return {
        ...rule,
        rank: colorMap.get(rule.color)
      };
    }
  });

  updatedRules.sort((a, b) => {
    if (a?.rank && b?.rank) {
      return a.rank - b.rank;
    }
    return 0;
  });

  return updatedRules;
};

export {getColorRankMap, getRankLogicsGroupedByColor};
