import styled from "@emotion/styled";
import {Button, Divider} from "@blueprintjs/core";
import {SettingItemEditorMode} from "./types/SettingItemEditorMode";

const FlexFlow = styled.div`
  display: flex;
  flex-direction: row;
`;

const FormContainer = styled.div`
  background-color: #ededed;
`;

const EditorContainer = styled.div`
  padding: 25px 25px 30px;
  height: 100%;
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
`;

const EditorHeader = styled(FlexFlow)`
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px 20px;
`;

const EditorBody = styled.div<{isDisable: boolean}>`
  width: 100%;
  margin-top: 20px;
  opacity: ${(props) => (props.isDisable ? 0.5 : 1)};
  pointer-events: ${(props) => (props.isDisable ? "none" : "auto")};
`;

const EditorFooter = styled(FlexFlow)`
  justify-content: flex-end;
  align-items: end;
  gap: 70px;
  margin: 0;
  flex: 1;
`;

export const EditorTitle = styled.h2`
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 700;
  color: #797979;
  margin: 0;
  text-transform: uppercase;
`;

export const SettingEditorButton = styled(Button)`
  &.bp4-button:not([class*="bp4-intent-"]) {
    color: #ffffff;
    background-color: #14305a;
    background: linear-gradient(0deg, rgba(15, 18, 23, 0.1), rgba(15, 18, 23, 0.1)),
      linear-gradient(0deg, #14305a, #14305a) !important;
    border: 1px solid #d9d9d9 !important;
    box-shadow: 0px 1px 2px 0px #0000000d !important;
  }

  &.bp4-button:not([class*="bp4-intent-"]):disabled {
    color: #fbfbfb;
    background-color: #d9d9d9;
    background: linear-gradient(0deg, rgba(15, 18, 23, 0.1), rgba(15, 18, 23, 0.1)),
      linear-gradient(0deg, #d9d9d9, #d9d9d9) !important;
    border: 1px solid #d9d9d9 !important;
    box-shadow: 0px 1px 2px 0px #0000000d !important;
  }
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  height: 32px;
  padding: 6px 14px;
  border: 1px;
  gap: 7px;
`;

export const NoBackGroundSettingEditorButton = styled.button`
  background: transparent;
  cursor: pointer;
  padding: 0;
  color: #14305a;
  font-size: 16px;
  font-weight: 500;
  height: 32px;
  border: none !important;

  :disabled {
    color: #d9d9d9;
    cursor: not-allowed;
  }
`;

const EditorOptionsGrid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

type EditorOptionItemProps = {
  isHidden?: boolean;
  isMultiOptions?: boolean;
};

type EditorItemLabelProps = {
  fieldName: string;
  isRequired: (fieldName: string) => boolean;
};
export const EditorOptionItem = styled.div<EditorOptionItemProps>`
  display: ${(props) => (props.isHidden ? "none" : "grid")};
  grid-template-columns: 220px auto;
  align-items: ${(props) => (props.isMultiOptions ? "start" : "center")};
`;

export const EditorOptionLabel = styled.div<EditorItemLabelProps>`
  font-size: 16px;

  :after {
    content: " *";
    color: #fa545e;
    display: ${(props) => (props.isRequired(props.fieldName) ? "inline" : "none")};
  }
`;

type SettingItemEditorContainerProps = {
  title: string;
  testId?: string;
  createLabel: string;
  editorMode: SettingItemEditorMode;
  onCreateClick: () => void;
  headerButtons?: Element | JSX.Element | Element[] | JSX.Element[] | false | null;
  footerButtons?: Element | JSX.Element | Element[] | JSX.Element[] | false | null;
  children?: Element | JSX.Element | Element[] | JSX.Element[] | false | null;
};

const SettingItemEditor = ({
  title,
  testId,
  createLabel,
  editorMode,
  onCreateClick,
  headerButtons,
  footerButtons,
  children
}: SettingItemEditorContainerProps) => {
  return (
    <FormContainer>
      <EditorContainer data-testid={`${testId}-panel`}>
        <EditorHeader key="header">
          <EditorTitle>{title}</EditorTitle>
          <SettingEditorButton
            disabled={editorMode !== SettingItemEditorMode.Disable}
            onClick={onCreateClick}
            text={createLabel}
            name={createLabel}
            data-testid={"add-new-logic-button"}
          />
          {headerButtons}
        </EditorHeader>
        <Divider key="divider" style={{margin: "0px"}} />
        <EditorBody key="body" isDisable={editorMode === SettingItemEditorMode.Disable}>
          <EditorOptionsGrid>{children}</EditorOptionsGrid>
        </EditorBody>
        <EditorFooter key="footer">{footerButtons}</EditorFooter>
      </EditorContainer>
    </FormContainer>
  );
};

export default SettingItemEditor;
