import {Button, ButtonGroup} from "@blueprintjs/core";
import {Job, Manifest} from "../../generated/graphql";
import styled from "@emotion/styled";

type AssignmentBarProps = {
  className?: string;
  selectedJobs: Job[];
  selectedManifest: Manifest | undefined;
  onAssign(): void;
  onClearSelection(): void;
};

const AssignmentBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 466px;
  height: 50px;
  background: #fcfcfc;
  border-radius: 12px;

  filter: drop-shadow(0px 10px 1px rgba(0, 0, 0, 0.02)) drop-shadow(0px 7px 20px rgba(0, 0, 0, 0.4));
`;

const SelectedText = styled.div`
  width: 100px;
  margin-left: 30px;
  margin-right: 41px;
  color: #797979;
`;

const ClearSelectionBtn = styled(Button)`
  width: 167px;
  height: 40px;
  border-radius: 8px 0px 0px 8px;
  font-size: 16px;

  &.bp4-button {
    background-color: #ffdee0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  }

  &.bp4-button:hover {
    background-color: #ffdee0;
    border-color: #285e8e;
  }
`;

const AssignBtn = styled(Button)`
  width: 122px;
  height: 40px;
  border-radius: 0px 8px 8px 0px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 2.5px;
  font-variant: all-small-caps;

  &.bp4-button {
    color: #ffffff;
    background-color: #14305a;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  }

  &.bp4-button:hover {
    background-color: #14305a;
    border-color: #285e8e;
  }
`;

const AssignmentBar = ({className, selectedJobs, selectedManifest, onAssign, onClearSelection}: AssignmentBarProps) => {
  return (
    <AssignmentBarContainer data-testid={"assignment-bar"} className={className}>
      <SelectedText>
        Selected {selectedJobs.length} Job{selectedJobs.length > 1 ? "s" : ""}
      </SelectedText>
      <ButtonGroup>
        <ClearSelectionBtn data-testid={"clear-selection-btn"} text={"Clear Selection"} onClick={onClearSelection} />
        <AssignBtn
          data-testid={"assign-btn"}
          text={"Assign"}
          onClick={onAssign}
          disabled={selectedManifest === undefined}
        />
      </ButtonGroup>
    </AssignmentBarContainer>
  );
};

export default AssignmentBar;
