import {MessageInput, MessageToSend, useChannelActionContext} from "stream-chat-react";

const DispatchMessageInput: React.FC = () => {
  const {sendMessage} = useChannelActionContext();

  const overrideSubmitHandler = (message: MessageToSend) => {
    sendMessage(message, {silent: true}).catch((reason) => {
      console.error("Error sending message: ", reason);
    });
  };

  return <MessageInput grow overrideSubmitHandler={overrideSubmitHandler} />;
};

export default DispatchMessageInput;
