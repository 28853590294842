import {LoginCallback, SecureRoute} from "@okta/okta-react";
import "./App.css";
import {Redirect, Route, Switch} from "react-router-dom";
import {LoadingSpinner} from "./components/common/LoadingSpinner";
import useUserGuiding from "./components/hooks/useUserGuiding";
import WhoAmIView from "./views/WhoAmIView";
import UnassignedJobsViewContainer from "./views/UnassignedJobsViewContainer";
import SettingsViewContainer from "./views/SettingsViewContainer";
import React from "react";

const AppRoutes = () => {
  useUserGuiding();

  const CustomLoginCallback = () => {
    return <LoginCallback loadingElement={<LoadingSpinner />} />;
  };

  return (
    <Switch>
      <SecureRoute exact path="/">
        <Redirect to="/jobs" />
      </SecureRoute>
      <SecureRoute path="/whoami" component={WhoAmIView} />
      <SecureRoute path="/jobs" component={UnassignedJobsViewContainer} />
      <SecureRoute path="/settings" component={SettingsViewContainer} />
      <Route path="/login/callback" component={CustomLoginCallback} />
    </Switch>
  );
};

export default AppRoutes;
