import {IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterParams} from "@ag-grid-community/core";
import {forwardRef, useEffect, useState, useCallback, ChangeEvent, useImperativeHandle} from "react";
import {SearchableSortDirection, ServiceSortableField, useSearchServicesQuery} from "../../../../generated/graphql";
import {andSearchWithWildcards} from "../../../settings/ColorizedIndicators/RuleGenerator.service";
import {ServiceFilter} from "../../../../generated/graphql";
import styled from "@emotion/styled";
import {Button, Checkbox, InputGroup} from "@blueprintjs/core";
import {getArrayFromField} from "../../../../utils/General";
import {ServiceBadge} from "../../../job/ServiceBadge";
import {heightDifferenceFromTop} from "../../../common/CustomerFilter";

type ServiceTypeOptions = {
  serviceName: string;
  serviceId: number;
};

export const ServiceTypeFilter = forwardRef((props: IFilterParams, ref) => {
  const [afterGuiAttachedParams, setAfterGuiAttachedParams] = useState<IAfterGuiAttachedParams>();
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(true);
  const [triggerSelectedAll, setTriggerSelectedAll] = useState<boolean>(false);
  const [serviceTypeQuery, setServiceTypeQuery] = useState<string>("");
  const [serviceTypeFilter, setServiceTypeFilter] = useState<ServiceFilter | undefined>();
  const [serviceTypeOptions, setServiceTypeOptions] = useState<ServiceTypeOptions[]>([]);
  const [selectedServiceTypes, setSelectedServiceTypes] = useState<ServiceTypeOptions[]>([]);
  const {fetchMore, refetch} = useSearchServicesQuery({
    variables: {
      limit: 20,
      sort: {
        field: ServiceSortableField.Service,
        direction: SearchableSortDirection.Asc
      },
      filter: {
        ...serviceTypeFilter,
        status: {
          match: "A"
        }
      },
      offset: 0
    },
    onCompleted: (data) => {
      const serviceOptions = getArrayFromField(data.searchServices?.items).map((val) => ({
        serviceName: val.service,
        serviceId: val.serviceId
      }));
      setServiceTypeOptions(serviceOptions);
      if (isSelectedAll) {
        setSelectedServiceTypes(serviceOptions);
      }
    },
    onError: (error) => {
      console.error(error);
    }
  });

  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params: IDoesFilterPassParams) {
        if (isSelectedAll) {
          return true;
        }
        return selectedServiceTypes.some((service) => service.serviceName === params.data.order.service);
      },
      isFilterActive() {
        return !isSelectedAll;
      },
      getModel() {
        if (isSelectedAll) {
          return null;
        }
        const model = {filterType: "set"};
        const values = selectedServiceTypes.map((service) => service.serviceName);
        return {...model, values, selectedServiceTypes: selectedServiceTypes};
      },
      setModel(model: any) {
        if (!model) {
          onReset();
          return;
        }
        setIsSelectedAll(false);
        setSelectedServiceTypes(model.selectedServiceTypes);
      },
      afterGuiAttached(params: IAfterGuiAttachedParams) {
        setAfterGuiAttachedParams(params);
      }
    };
  });

  const handleChangeServiceQuery = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setServiceTypeQuery(event.target.value);
  }, []);

  const handleSelectAll = useCallback(() => {
    setTriggerSelectedAll(true);
    setIsSelectedAll((prevState) => !prevState);
  }, []);

  const handleSelectService = useCallback(
    (serviceId: number) => {
      setTriggerSelectedAll(false);
      const findService = serviceTypeOptions.find((service) => service.serviceId === serviceId);
      if (isSelectedAll) {
        setIsSelectedAll(false);
        setSelectedServiceTypes([findService!]);
      } else {
        const findIndex = selectedServiceTypes.findIndex((service) => service.serviceId === serviceId);
        if (findIndex === -1) {
          setSelectedServiceTypes((prevState) => [...prevState, findService!]);
        } else {
          setSelectedServiceTypes((prevState) => prevState.filter((service) => service.serviceId !== serviceId));
        }
      }
    },
    [isSelectedAll, selectedServiceTypes, serviceTypeOptions]
  );

  const onLoadMore = useCallback(() => {
    fetchMore({
      variables: {
        offset: serviceTypeOptions.length
      }
    }).then((result) => {
      const serviceOptions = getArrayFromField(result.data.searchServices?.items).map((val) => ({
        serviceName: val.service,
        serviceId: val.serviceId
      }));
      setServiceTypeOptions((prevState) => [...prevState, ...serviceOptions]);
      if (isSelectedAll) {
        setSelectedServiceTypes([...selectedServiceTypes, ...serviceOptions]);
      }
    });
  }, [fetchMore, serviceTypeOptions.length, isSelectedAll, selectedServiceTypes]);

  const onApply = () => {
    props.filterChangedCallback();
    afterGuiAttachedParams?.hidePopup?.();
  };

  const onReset = useCallback(() => {
    setTriggerSelectedAll(false);
    refetch({
      limit: 20,
      sort: {
        field: ServiceSortableField.Service,
        direction: SearchableSortDirection.Asc
      },
      filter: {
        status: {
          match: "A"
        }
      },
      offset: 0
    }).then((result) => {
      const serviceOptions = getArrayFromField(result.data?.searchServices?.items).map((val) => ({
        serviceName: val.service,
        serviceId: val.serviceId
      }));
      setServiceTypeOptions(serviceOptions);
      setSelectedServiceTypes(serviceOptions);
      setIsSelectedAll(true);
      setServiceTypeQuery("");
      props.filterChangedCallback();
      afterGuiAttachedParams?.hidePopup?.();
    });
  }, [afterGuiAttachedParams, props, refetch]);

  useEffect(() => {
    if (triggerSelectedAll && !isSelectedAll) {
      setSelectedServiceTypes([]);
    } else if (triggerSelectedAll && isSelectedAll) {
      setSelectedServiceTypes(serviceTypeOptions);
    }
  }, [isSelectedAll, serviceTypeOptions, triggerSelectedAll]);

  useEffect(() => {
    if (serviceTypeQuery?.trim()) {
      const tokens = serviceTypeQuery.trim().split(" ");
      setServiceTypeFilter(andSearchWithWildcards("service", tokens));
    } else {
      setServiceTypeFilter(undefined);
    }
  }, [serviceTypeQuery]);

  useEffect(() => {
    const container = document.getElementById("infinity-scroll-list");
    if (!container) return;

    const handleScroll = () => {
      const scrollTop = container.scrollTop + heightDifferenceFromTop;
      const clientHeight = container.clientHeight;
      const scrollHeight = container.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        onLoadMore();
      }
    };

    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <ServiceTypeContainer data-testid="service-filter-container">
      <SearchBoxContainer>
        <SearchBox
          data-testid="service-searchbox"
          placeholder={"Search Services..."}
          value={serviceTypeQuery}
          onChange={handleChangeServiceQuery}
        />
      </SearchBoxContainer>
      <OptionContainer id="infinity-scroll-list">
        {serviceTypeOptions.length > 0 && (
          <>
            <Checkbox
              data-testid="checkbox-select-all"
              label={"Select All"}
              onChange={handleSelectAll}
              checked={isSelectedAll}
            />
            {serviceTypeOptions.map((option) => {
              return (
                <Checkbox
                  key={option.serviceId}
                  onChange={() => handleSelectService(option.serviceId)}
                  checked={selectedServiceTypes.some((service) => service.serviceId === option.serviceId)}
                >
                  {<ServiceBadge service={option.serviceName} />}
                </Checkbox>
              );
            })}
          </>
        )}
      </OptionContainer>
      <ActionContainer>
        <ApplyButton intent="primary" onClick={onApply}>
          Apply
        </ApplyButton>
        <ResetButton onClick={onReset}>Reset</ResetButton>
      </ActionContainer>
    </ServiceTypeContainer>
  );
});

ServiceTypeFilter.displayName = "ServiceFilter";

const ServiceTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 3px;
`;

const SearchBoxContainer = styled.div`
  padding: 4px 6px;
`;
const SearchBox = styled(InputGroup)`
  .bp4-input {
    border-radius: 4px;
  }
`;

const OptionContainer = styled.div`
  height: 200px;
  overflow: auto;
  padding-left: 5px;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  padding: 5px 12px;
  border-top: 1px solid #d3d3d3;
`;

const ApplyButton = styled(Button)``;
const ResetButton = styled(Button)``;
