import {useContext, useEffect} from "react";
import {EditorTitle} from "../components/SettingItemEditor";
import styled from "@emotion/styled";
import ServiceTypeColorItem from "./ServiceTypeColorItem";
import {ServiceTypeColorType} from "./ServiceTypeColor";
import ColorSelector from "./ColorSelector";
import {ServiceTypeColorContext, ServiceTypeColorDispatchContext} from "./ServiceTypeColorStateProvider";

type ServiceTypeColorListProps = {
  services: ServiceTypeColorType[];
};

const ServiceTypeColorConfig = ({services}: ServiceTypeColorListProps) => {
  const {selectedServices, selectedColor} = useContext(ServiceTypeColorContext);
  const serviceTypeColorDispatch = useContext(ServiceTypeColorDispatchContext);

  useEffect(() => {
    let isComponentMounted = true;
    if (isComponentMounted) {
      serviceTypeColorDispatch({
        type: "SET_IS_DATA_CHANGED",
        payload: !!(selectedColor && selectedServices.find((sv) => sv.color !== selectedColor))
      });
    }
    return () => {
      isComponentMounted = false;
    };
  }, [selectedColor, selectedServices, serviceTypeColorDispatch]);

  return (
    <Container>
      <Header>
        <EditorTitle>service type list</EditorTitle>
        <ColorSelector />
      </Header>
      <Body>
        <List>
          {services.map((item, index) => (
            <ServiceTypeColorItem
              key={item.name}
              item={item}
              index={index}
              isSelected={selectedServices.some((sv) => sv.name === item.name)}
            />
          ))}
        </List>
      </Body>
    </Container>
  );
};

export default ServiceTypeColorConfig;

const Container = styled.div`
  height: calc(100% - 70px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const Header = styled.div`
  padding: 12px 33px 12px 25px;
  display: flex;
  justify-content: space-between;
`;
const Body = styled.div`
  flex-grow: 1;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const List = styled.ul`
  margin: 0;
  flex-grow: 1;
  padding: 2px 25px;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    background-color: #ffffff;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #d9d9d9;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 30px;
    background-color: #d9d9d9;
  }
`;
