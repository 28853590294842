import {Button} from "@blueprintjs/core";
import {Popover2} from "@blueprintjs/popover2";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dispatch} from "react";
import ManifestSortMenu from "./ManifestSortMenu";
import {CardType} from "../../views/JobAssignmentView";
import {JobViewActions, ManifestSortOptions} from "../../views/JobAssignmentViewReducer";
import ViewChangerMenu from "../ViewChangerMenu";
import styled from "@emotion/styled";
import {SearchableSortDirection} from "../../generated/graphql";

type ManifestMenuProps = {
  dispatch: Dispatch<JobViewActions>;
  manifestSort: ManifestSortOptions;
  selectedView: CardType;
  onViewChanged(viewType: CardType): void;
  sortByColor: {
    enable: boolean;
    direction: SearchableSortDirection;
  };
};

const ManifestMenu = ({dispatch, manifestSort, onViewChanged, selectedView, sortByColor}: ManifestMenuProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderSettingsPopoverTarget = ({isOpen, ref, ...targetProps}: any) => {
    return (
      <Button
        {...targetProps}
        elementRef={ref}
        minimal
        icon={<FontAwesomeIcon icon={solid("ellipsis-vertical")} />}
        data-testid="viewchanger-button"
      />
    );
  };

  const renderSettingsPopoverContent = () => {
    return (
      <MenuContent>
        <ViewChangerMenu selectedView={selectedView} onViewChanged={onViewChanged} />
        <ManifestSortMenu dispatch={dispatch} manifestSort={manifestSort} sortByColor={sortByColor} />
      </MenuContent>
    );
  };

  return (
    <Popover2
      interactionKind="click"
      usePortal
      popoverClassName="Classes.POPOVER2_CONTENT_SIZING"
      placement="bottom-end"
      content={renderSettingsPopoverContent()}
      renderTarget={renderSettingsPopoverTarget}
    />
  );
};
export default ManifestMenu;

const MenuContent = styled.div`
  width: 187px;
`;
