import {ManifestViewFilter} from "./types/ManifestViewFilter";
import {ManifestFilter} from "../../generated/graphql";
import {DriverGroupManifestFilter} from "./types/DriverGroupManifestFilter";
import {MaxAgeManifestFilter} from "./types/MaxAgeManifestFilter";
import {StopCountManifestFilter} from "./types/StopCountManifestFilter";
import {StopStatusManifestFilter} from "./types/StopStatusManifestFilter";
import {ManifestDateRangeFilter} from "./types/ManifestDateRangeFilter";
import {ManifestFilterTypePayload} from "../../views/JobAssignmentViewReducer";
import {DateRange} from "@mui/icons-material";

export enum ManifestFilterType {
  DRIVER_GROUP = "driverGroup",
  DATE_RANGE = "dateRange",
  MANIFEST_AGE = "age",
  STOP_COUNT = "stop_count",
  STOP_STATUS = "stop_status",
  NONE = "none"
}
export class ManifestFilterState {
  private _manifestFilters: Map<ManifestFilterType, ManifestViewFilter<any>> = new Map();

  get manifestFilters(): Map<ManifestFilterType, ManifestViewFilter<any>> {
    return new Map<ManifestFilterType, ManifestViewFilter<any>>(this._manifestFilters);
  }
  public addManifestFilterValue<T>(filterValue: T | undefined, filterType: ManifestFilterType): ManifestFilterState {
    let filter = this._manifestFilters.get(filterType) as ManifestViewFilter<T>;
    if (filterValue !== undefined && filterValue !== null) {
      if (!filter) {
        filter = this.createFilter(filterType) as ManifestViewFilter<T>;
        this._manifestFilters.set(filterType, filter);
      }
      filter.value.add(filterValue);
    }
    return this.copy();
  }

  public addManifestFilterValues<T>(filters: ManifestFilterTypePayload[]): ManifestFilterState {
    filters.forEach((filterObj) => {
      const {value, filterType} = filterObj;
      let filter = this._manifestFilters.get(filterType) as ManifestViewFilter<T>;
      if (value) {
        if (!filter) {
          filter = this.createFilter(filterType) as ManifestViewFilter<T>;
          this._manifestFilters.set(filterType, filter);
        }
        if (Array.isArray(value) && value.length > 0) {
          filter.value = new Set<any>([...filter.value, ...value]);
        } else if (typeof value === "string" || typeof value === "number") {
          filter.value.add(value as T);
        } else if (value instanceof Date || value instanceof DateRange) {
          filter.value.add(value as T);
        }
      }
    });
    return this.copy();
  }

  public removeManifestFilterValue<T>(filterValue: T, filterType: ManifestFilterType): ManifestFilterState {
    const filter = this._manifestFilters.get(filterType) as ManifestViewFilter<T>;
    if (filter) {
      filter.value.delete(filterValue);
    }
    if (filter && filter.value.size === 0) {
      this._manifestFilters.delete(filterType);
    }
    return this.copy();
  }

  public clearManifestFilterType(filterType: ManifestFilterType): ManifestFilterState {
    this._manifestFilters.delete(filterType);
    return this.copy();
  }

  public clearManifestFilterTypes(filterTypes: ManifestFilterType[]): ManifestFilterState {
    filterTypes.forEach((filterType) => {
      this._manifestFilters.delete(filterType);
    });
    return this.copy();
  }

  public replaceManifestFilterValue<T>(filterValue: T, filterType: ManifestFilterType): ManifestFilterState {
    this._manifestFilters.delete(filterType);
    return this.addManifestFilterValue(filterValue, filterType);
  }

  public hasFilterValue<T>(filterValue: T, filterType: ManifestFilterType): boolean {
    const filter = this._manifestFilters.get(filterType) as ManifestViewFilter<T>;
    if (filter) {
      return filter.value.has(filterValue);
    }
    return false;
  }

  public hasFilter(filterType: ManifestFilterType): boolean {
    return this._manifestFilters.has(filterType);
  }

  public copy(): ManifestFilterState {
    const newState = new ManifestFilterState();
    newState._manifestFilters = new Map(this._manifestFilters);
    return newState;
  }

  public appendToFilter(manifestFilter: ManifestFilter): void {
    if (manifestFilter) {
      this._manifestFilters.forEach((filter) => {
        const filterItem = filter.toFilter();
        const nestedFilterItem = filter.toNestedFilter();
        if (filterItem) {
          this.ensureFilterStructure(manifestFilter);
          manifestFilter?.manifest?.and?.push(filterItem);
        }
        if (nestedFilterItem) {
          this.ensureStopsFilter(manifestFilter);
          manifestFilter?.stops?.push(nestedFilterItem);
        }
      });
    }
  }

  createFilter(filterType: ManifestFilterType): ManifestViewFilter<any> | undefined {
    switch (filterType) {
      case ManifestFilterType.MANIFEST_AGE:
        return new MaxAgeManifestFilter();
      case ManifestFilterType.STOP_COUNT:
        return new StopCountManifestFilter();
      case ManifestFilterType.DATE_RANGE:
        return new ManifestDateRangeFilter();
      case ManifestFilterType.STOP_STATUS:
        return new StopStatusManifestFilter();
      case ManifestFilterType.NONE:
        return {
          name: filterType,
          value: new Set<string>(),
          toFilter: () => {
            return undefined;
          },
          toNestedFilter: () => {
            return undefined;
          }
        };
      case ManifestFilterType.DRIVER_GROUP:
        return new DriverGroupManifestFilter();
      default:
        throw new Error(`Unknown filter type ${filterType}`);
    }
  }

  protected ensureFilterStructure(filter: ManifestFilter): void {
    if (!filter.manifest) {
      filter.manifest = {};
    }
    if (!filter.manifest.and) {
      filter.manifest.and = [];
    }
  }

  protected ensureStopsFilter(filter: ManifestFilter): ManifestFilter {
    if (!filter.stops) {
      filter.stops = [];
    }
    return filter;
  }
}
