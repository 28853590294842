import styled from "@emotion/styled";
import {DriverQualification} from "../../generated/graphql";
import {Popover2, Tooltip2} from "@blueprintjs/popover2";
import {CSSProperties} from "react";

const DriverQualificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
  text-transform: uppercase;
  background-color: #e5e6e6;
  padding: 3px 4px;
  border-radius: 4px;
`;

const DriverQualificationContent = styled.div`
  cursor: pointer;
  font-family: Roboto;
  font-size: 11px;
  line-height: 11px;
  font-weight: 500;
  color: #797979;
`;

const DriverQualificationPopoverContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 3px;
`;

type NewDriverQualTagProps = {
  qualification: DriverQualification;
  mimimal?: boolean;
};

const DriverQualTagV3 = ({qualification, mimimal = false}: NewDriverQualTagProps) => {
  const styles: CSSProperties = mimimal
    ? {}
    : {textTransform: "uppercase", backgroundColor: "#e5e6e6", padding: "3px 4px", borderRadius: "4px"};

  return (
    <DriverQualificationContent>
      {(qualification.name?.length as number) > 3 ? (
        <Tooltip2 content={qualification.name ?? ""} position="bottom" popoverClassName="manifest-card-header-popover">
          <DriverQualificationContent style={styles}>{qualification.name?.substring(0, 3)}</DriverQualificationContent>
        </Tooltip2>
      ) : (
        <DriverQualificationContent style={styles}>{qualification.name?.substring(0, 3)}</DriverQualificationContent>
      )}
    </DriverQualificationContent>
  );
};

type DriverQualificationProps = {
  qualifications: DriverQualification[] | null | undefined;
};

const DriverQualV3 = ({qualifications}: DriverQualificationProps) => {
  return qualifications ? (
    <DriverQualificationContainer>
      <DriverQualTagV3 qualification={qualifications[0]} mimimal />
      {qualifications.length > 1 && (
        <Popover2
          interactionKind="hover"
          placement="top"
          popoverClassName="manifest-card-header-popover"
          content={
            <DriverQualificationPopoverContent>
              {qualifications.map((qual) => (
                <DriverQualTagV3 key={`qualification-tag-${qual.id}`} qualification={qual} />
              ))}
            </DriverQualificationPopoverContent>
          }
        >
          <DriverQualificationContent data-testid="driver-capabilities">{`+${
            qualifications.length - 1
          }`}</DriverQualificationContent>
        </Popover2>
      )}
    </DriverQualificationContainer>
  ) : null;
};

export default DriverQualV3;
