import styled from "@emotion/styled";
import {Elevation} from "@blueprintjs/core";
import {createCardHandlers} from "../ManifestCard";
import {CardContainerV3, MinimalManifestCardV3} from "../ManifestDriverCardStyles";
import {getFinalCompletedStop, getNextActiveStop, getOnSiteStop} from "../../../services/ManifestStopService";
import React, {useContext, useEffect} from "react";
import {CardGrid, scrollToElement} from "../card/ManifestDriverCardV3";
import {ManifestCardProps} from "../ManifestCardCommon";
import {ManifestCardHeader, StopInfoItem} from "../ManifestCardCommonV3";
import {Manifest} from "../../../generated/graphql";
import {getArrayFromField} from "../../../utils/General";
import {FeatureFlagContext} from "../../../providers/FeatureFlagProvider";

const getStopList = (manifest: Manifest) => {
  const finalCompletedStop = getFinalCompletedStop(manifest);
  const nextActiveStop = getNextActiveStop(manifest);
  const onSiteStop = getOnSiteStop(manifest);

  //if on site stop exists, return it
  if (onSiteStop) {
    return [onSiteStop];
  }

  //if all stops is completed, return lowest rank completed stop
  if (finalCompletedStop) {
    return [finalCompletedStop];
  }

  //if no onsite or all stops is incompleted, return next the active stop
  return getArrayFromField(nextActiveStop);
};

const ManifestDriverMinimumCardV3 = ({
  manifest,
  colors,
  isSelected,
  isHighlighted,
  disabled,
  configurationState,
  cardType,
  onSelectedManifest,
  onDrillDownManifest,
  onLocationMarkerClick
}: ManifestCardProps) => {
  const {compactCard: compactCardEnalbled} = useContext(FeatureFlagContext);

  const {handleOnSelected, handleDrillDownManifest} = createCardHandlers({
    onSelectedManifest,
    onDrillDownManifest
  });

  const stopInfoStops = getStopList(manifest);

  useEffect(() => {
    if (isSelected) {
      scrollToElement(`manifest-minimal-card-item-${manifest.manifestDriverId}`);
    }
  }, [isSelected, manifest.manifestDriverId]);

  return (
    <CardContainerV3
      id={`manifest-dnd-target-${manifest.manifestDriverId}`}
      data-manifestdriverid={manifest.manifestDriverId}
      data-cardtype={"min-card"}
    >
      <MinimalManifestCardV3
        id={`manifest-minimal-card-item-${manifest.manifestDriverId}`}
        elevation={Elevation.ONE}
        isSelected={isSelected}
        isHighlighted={isHighlighted}
        disabled={disabled}
        data-testid={`manifest-minimal-card-item-${manifest.manifestDriverId}`}
        data-manifest={JSON.stringify(manifest)}
        compactCardEnalbled={compactCardEnalbled}
        onClick={(e: any) => {
          if (!disabled) {
            handleOnSelected(e, manifest);
          }
        }}
      >
        <CardGrid data-testid={"card-grid"} isSelected={isSelected} bodyHeight={32}>
          <ManifestCardHeader
            manifest={manifest}
            handleDrillDownManifest={handleDrillDownManifest}
            onLocationMarkerClick={onLocationMarkerClick}
            stopsColor={colors ?? []}
            isSelected={isSelected}
            disabled={disabled}
            configurationState={configurationState}
            cardType={cardType}
          />
          <StopListContainer>
            {stopInfoStops?.map((x) => {
              const color = (colors ?? []).find((y) => y.jobStopID === x.jobStopId)?.color ?? "";
              return (
                <StopInfoItem
                  key={`card-stop-info-${x.jobStopId}`}
                  stop={x}
                  color={color}
                  disabled={disabled ?? false}
                  configurationState={configurationState}
                  cardType={cardType}
                />
              );
            })}
          </StopListContainer>
        </CardGrid>
      </MinimalManifestCardV3>
    </CardContainerV3>
  );
};
export default ManifestDriverMinimumCardV3;

const StopListContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 4px 8px 8px 8px !important;
`;
