import {ManifestAttributeFilter, ManifestStopFilter, NestedJobStopFilterInput} from "../../../generated/graphql";
import {ManifestFilterType} from "../ManifestFilterState";
import {AbstractManifestViewFilter} from "./AbstractManifestViewFilter";

export enum StopStatusCategory {
  COMPLETE = "Complete",
  INCOMPLETE = "Incomplete"
}
export class StopStatusManifestFilter extends AbstractManifestViewFilter<string> {
  constructor() {
    super(ManifestFilterType.STOP_STATUS);
  }

  toFilter(): ManifestAttributeFilter | undefined {
    return undefined;
  }

  protected toStopsFilter(): ManifestStopFilter | undefined {
    return undefined;
  }

  toNestedFilter(): NestedJobStopFilterInput | undefined {
    if (this.value.size == 1) {
      const statuses = ["A", "Q"];
      const filterItems: ManifestStopFilter[] = statuses.map((value) => {
        return {
          jobStopStatus: {
            match: value
          }
        } as ManifestStopFilter;
      });
      return (
        this.value.has(StopStatusCategory.COMPLETE)
          ? {
              not: {
                or: filterItems
              }
            }
          : {
              or: filterItems
            }
      ) as ManifestStopFilter;
    }
    return undefined;
  }
}
