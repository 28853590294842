/** @jsxImportSource @emotion/react */
import {ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache, ServerError} from "@apollo/client";
import {HotkeysProvider} from "@blueprintjs/core";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {OktaAuth, toRelativeUrl} from "@okta/okta-auth-js";
import {Security} from "@okta/okta-react";
import {LicenseManager} from "@ag-grid-enterprise/core";
import {AUTH_TYPE, AuthOptions, createAuthLink} from "aws-appsync-auth-link";
import {createSubscriptionHandshakeLink} from "aws-appsync-subscription-link";
import {ConfigCatProvider, createConsoleLogger, LogLevel, PollingMode} from "configcat-react";
import {useCallback, useState} from "react";
import {ConfigProvider} from "react-avatar";
import {useHistory} from "react-router-dom";
import {CenterType, Fill, Top, ViewPort} from "react-spaces";
import {AppContextProvider} from "./ApplicationContext";
import AppRoutes from "./AppRoutes";
import AuthProvider from "./components/AuthProvider";
import {Constants, UserPreferences} from "./components/common/Constants";
import DataDogProvider from "./components/common/DataDogProvider";
import ErrorBoundary from "./components/common/ErrorBoundary";
import {LoadingSpinner} from "./components/common/LoadingSpinner";
import DispatchNavBar from "./components/DispatchNavBar";
import {CONFIG_CAT_KEY, CONFIG_CAT_REFRESH_INTERVAL, GQL_API_URL, oktaAuthConfig, REGION} from "./config";
import "./App.css";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import FeatureFlagProvider from "./providers/FeatureFlagProvider";
import DispatchConfigurationProvider from "./providers/DispatchConfigurationProvider";
import DispatchStationDataProvider from "./components/common/DispatchStationDataProvider";
import PreferenceProvider from "./providers/PreferenceProvider";
import {onError} from "@apollo/client/link/error";
import {AppToaster} from "./utils/toaster";
import {throttle} from "lodash";
import OktaConfigProvider from "./providers/OktaConfigProvider";

LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-051702}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{e-Courier}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{next-gen}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{next-gen}_need_to_be_licensed___{next-gen}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{31_January_2025}____[v3]_[01]_MTczODI4MTYwMDAwMA==1ee79f800c7a0226f6a5acfdac744788"
);

const oktaAuth = new OktaAuth(oktaAuthConfig);
oktaAuth.start(); // start the service

const apolloCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        searchManifests: {
          keyArgs: ["sort", "filter"],
          merge: false
        }
      }
    },
    RouteOptimizationActivity: {
      keyFields: ["id", "type"]
    }
  },
  Manifest: {
    keyFields: ["manifestDriverId"],
    fields: {
      stops: {
        merge: false
      }
    }
  },
  JobStop: {
    keyFields: ["jobStopId"]
  },
  Job: {
    keyFields: ["jobId"]
  },
  JobStops: {
    keyFields: ["jobStopId"]
  },
  Driver: {
    keyFields: ["driverId"]
  },
  DispatchGroup: {
    keyFields: ["dispatchGroupId"]
  },
  UserPref: {
    keyFields: ["name"]
  },
  DriverLocation: {
    keyFields: ["driverId"]
  },
  NestedOrder: {
    keyFields: ["orderId"]
  },
  LateStop: {
    keyFields: ["lateStopId"]
  },
  EventType: {
    keyFields: ["eventTypeId"]
  }
};

const App = () => {
  const history = useHistory();
  const login = async () => history.push("/");
  const logout = async () => {
    window.sessionStorage.removeItem(UserPreferences.jobGridFilterState);
    oktaAuth.signOut().catch((error) => {
      console.error(`Error signing user out of okta ${error.message}`, error);
    });
  };
  const [, setRefreshState] = useState({});
  const [loading, setLoading] = useState(false);
  const configCatLogger = createConsoleLogger(LogLevel.Error);

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  const region = REGION;
  const auth: AuthOptions = {
    type: AUTH_TYPE.AWS_LAMBDA,
    token: () =>
      `Bearer ${JSON.parse(window.localStorage.getItem("okta-token-storage") as string).accessToken.accessToken}`
  };

  const httpLink = createHttpLink({
    uri: GQL_API_URL
  });

  const errorLink = onError(({graphQLErrors, networkError, operation, forward}) => {
    for (const gqlError of graphQLErrors ?? []) {
      console.error(`[GraphQL Link] - error: ${gqlError.message}`);
    }

    if (networkError) {
      if (networkError.name === "ServerError" && (networkError as ServerError).statusCode === 401) {
        console.error(`[GraphQL Link] - authenticationError: ${JSON.stringify(networkError)}`);

        oktaAuth.tokenManager
          .renew("accessToken")
          .then(() => {
            try {
              const headers = operation.getContext().headers;
              operation.setContext({
                headers: {
                  ...headers,
                  authorization: `Bearer ${oktaAuth.tokenManager.getTokensSync().accessToken}`
                }
              });
              return forward(operation);
            } catch (e) {
              console.error("[GraphQL Link] - Failed to update operation context.", e);
              return forward(operation);
            }
          })
          .catch((e) => {
            console.error("[GraphQL Link] - Failed to renew token", e);
          });
      } else {
        console.error(`[GraphQL Link] - networkError: ${JSON.stringify(networkError)}`);
      }
    }
  });

  const clockCheckLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      try {
        const dateHeader = operation.getContext().response.headers.get("Date");
        if (dateHeader) {
          const serverTime = new Date(dateHeader).getTime();
          const systemTime = new Date().getTime();
          const diff = Math.abs(serverTime - systemTime);
          if (diff >= Constants.CLOCK_SKEW_WARNING_THRESHOLD_MILLIS) {
            console.warn("Clock skew detected. System time = " + systemTime + ", server time = " + serverTime);
            throttleClockSkewWarning();
          }
        }
        return response;
      } catch (e) {
        console.error("[GraphQL Link] - Failed in clockCheckLink", e);
        return response;
      }
    });
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttleClockSkewWarning = useCallback(
    throttle(() => {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message: "Clock skew detected. Local system time is out of sync, you may encounter errors"
      });
    }, 30000),
    []
  );

  const link = ApolloLink.from([
    errorLink,
    clockCheckLink,
    createAuthLink({url: GQL_API_URL, region, auth}),
    createSubscriptionHandshakeLink({url: GQL_API_URL, region, auth}, httpLink)
  ]);

  const client = new ApolloClient({
    // connectToDevTools: true,
    connectToDevTools: process.env.NODE_ENV !== "production",
    link: link,
    cache: new InMemoryCache(apolloCacheConfig)
  });

  const AppContainer = styled.div({
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap"
  });

  const splitter = css(`
        .spaces-resize-handle {
            background-color: #F6F7F9
        }
    `);

  const refresh = () => {
    setRefreshState({});
  };

  const mainLoading = (isLoading: boolean) => {
    setLoading(isLoading);
  };

  const renderContent = () => {
    if (loading) {
      return <LoadingSpinner />;
    }
    return <AppRoutes />;
  };

  return (
    <AppContainer>
      <ApolloProvider client={client}>
        <ConfigProvider
          colors={[
            "#1F4B99",
            "#3A729E",
            "#6096A1",
            "#93B9A2",
            "#D7D7A1",
            "#F8CE87",
            "#E7A55D",
            "#D27D3A",
            "#B9561F",
            "#9E2B0E"
          ]}
          avatarRedirectUrl="https://avatar-redirect.appspot.com"
        >
          <HotkeysProvider>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
              <AuthProvider>
                <DispatchConfigurationProvider>
                  <ConfigCatProvider
                    sdkKey={CONFIG_CAT_KEY}
                    pollingMode={PollingMode.AutoPoll}
                    options={{pollIntervalSeconds: CONFIG_CAT_REFRESH_INTERVAL, logger: configCatLogger}}
                  >
                    <FeatureFlagProvider>
                      <OktaConfigProvider>
                        <DataDogProvider>
                          <AppContextProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <PreferenceProvider>
                                <DispatchStationDataProvider>
                                  <ViewPort className="App" css={splitter}>
                                    <Top size={52} centerContent={CenterType.Vertical}>
                                      <DispatchNavBar
                                        authLogout={logout}
                                        authLogin={login}
                                        refresh={refresh}
                                        mainLoading={mainLoading}
                                      />
                                    </Top>
                                    <Fill>
                                      <Fill>
                                        <ErrorBoundary>{renderContent()}</ErrorBoundary>
                                      </Fill>
                                    </Fill>
                                  </ViewPort>
                                </DispatchStationDataProvider>
                              </PreferenceProvider>
                            </LocalizationProvider>
                          </AppContextProvider>
                        </DataDogProvider>
                      </OktaConfigProvider>
                    </FeatureFlagProvider>
                  </ConfigCatProvider>
                </DispatchConfigurationProvider>
              </AuthProvider>
            </Security>
          </HotkeysProvider>
        </ConfigProvider>
      </ApolloProvider>
    </AppContainer>
  );
};

export default App;
export {apolloCacheConfig};
