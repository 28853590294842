import styled from "@emotion/styled";
import ColorizedIndicatorItem from "./ColorizedIndicatorItem";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  DroppableProvided,
  OnDragEndResponder
} from "react-beautiful-dnd";
import {IndicatorLogicType, UserColor} from "./types/indicatorLogicType";
import {useContext} from "react";
import {ColorizedIndicatorContext, ColorizedIndicatorDispatchContext} from "./ColorizedIndicatorStateProvider";

type ExpandedState = {
  [key in UserColor]: boolean;
};

type ColorizedIndicatorsListProps = {
  onSelectLogicHandler(logic: IndicatorLogicType): void;
  onChangeRank: (logics: IndicatorLogicType[]) => void;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
  const commonStyles = {
    ...draggableStyle
  };
  let additionalStyles;
  if (!isDragging) {
    additionalStyles = {
      background: "#fff",
      border: "1px dashed rgba(0,0,0,0)"
    };
  } else {
    additionalStyles = {
      background: " rgba(244, 245, 247, 0.5)",
      border: "1px dashed #CBD0DF"
    };
  }

  return {...commonStyles, ...additionalStyles};
};

const ColorizedIndicatorsList = ({onSelectLogicHandler, onChangeRank}: ColorizedIndicatorsListProps) => {
  const indicatorColorState = useContext(ColorizedIndicatorContext);
  const indicatorDispatch = useContext(ColorizedIndicatorDispatchContext);

  const handleToggleCollapse = (color: UserColor) => {
    indicatorDispatch({
      type: "SET_EXPANDED_STATE",
      payload: {...indicatorColorState.expandedState, [color]: !indicatorColorState.expandedState[color]}
    });
  };

  const logics = indicatorColorState.logicData?.rules ?? [];

  //Get the list of the distinct colors and sort ASC by the rank
  const distinctLogicList = logics.reduce((acc: IndicatorLogicType[], logic: IndicatorLogicType) => {
    const existingLogic = acc.find((l) => l.color === logic.color);
    if (!existingLogic) {
      acc.push(logic);
    }
    acc.sort((a, b) => a.rank - b.rank);
    return acc;
  }, []);

  const onDragEnd: OnDragEndResponder = (result: DropResult) => {
    //Do nothing if order not change
    if (result.source.index === result.destination?.index || !result.destination) return;

    //Update new list
    const _Items = [...distinctLogicList];
    const DragItem = _Items.splice(result.source.index, 1)[0];
    _Items.splice(result.destination?.index, 0, DragItem);

    _Items.forEach((item, index) => {
      item.rank = index + 1;
    });
    const newLogics = logics.map((logic) => {
      const newLogic = _Items.find((l) => l.color === logic.color);
      return {...logic, rank: newLogic?.rank};
    });

    //Send request to save to datebase
    onChangeRank(newLogics as IndicatorLogicType[]);
  };

  if (distinctLogicList.length === 0) {
    return <CenterDiv>No logic found!</CenterDiv>;
  }

  return (
    <ListContainer>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided: DroppableProvided) => {
            return (
              <div style={{flexGrow: 1}} {...provided.droppableProps} ref={provided.innerRef}>
                {distinctLogicList.map((logic, index) => (
                  <Draggable key={logic.color} draggableId={logic.color as string} index={index}>
                    {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          {...provided.draggableProps}
                        >
                          <ColorizedIndicatorItem
                            color={logic.color as UserColor}
                            logics={logics.filter((l) => l.color === logic.color)}
                            isExpanded={indicatorColorState.expandedState[logic.color as UserColor]}
                            onToggleCollapse={handleToggleCollapse}
                            onEdit={onSelectLogicHandler}
                            dragProps={{...provided.dragHandleProps}}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </ListContainer>
  );
};

export default ColorizedIndicatorsList;
export type {ExpandedState};

const ListContainer = styled.div`
  height: calc(100% - 65px);
  overflow-y: auto;
  overflow-x: auto;
  display: flex;
`;

const CenterDiv = styled.div`
  margin: auto;
  font-style: italic;
  text-align: center;
  font-size: 16px;
`;
