import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {ManifestStop} from "../../../../generated/graphql";
import {AdjustTimeState} from "../ManifestDetailsV2";
import styled from "@emotion/styled";
import {Divider, InputGroup, Tag, Text} from "@blueprintjs/core";
import {Controller, useForm} from "react-hook-form";
import {ActionButton} from "../../../common/ConfirmationDialog";
import {StyledCheckbox} from "../../card-configuration/PresentContinuouslyForm";
import {isNumber} from "lodash";
import {navigateToCmsOrder} from "../../../../services/CmsService";
import {AuthContext} from "../../../AuthProvider";
import {PreferenceContext} from "../../../../providers/PreferenceProvider";

export type AdjustStopTimeProps = {
  stops: ManifestStop[];
  onConfirm: (state: AdjustTimeState) => void;
  onClose: () => void;
};

type CheckedState = {
  all: boolean;
  scheduledTime: boolean;
  lateTime: boolean;
  allValue: number;
  scheduledTimeValue: number;
  lateTimeValue: number;
};

type Value = Record<keyof Pick<CheckedState, "all" | "scheduledTime" | "lateTime">, {checked: boolean; value: number}>;
type ValidValue = {key: keyof Value; value: number};
type ShowOptions = Record<keyof Pick<CheckedState, "scheduledTime" | "lateTime">, boolean>;

const getAdjustTimeState = (values: ValidValue[]) => {
  const retVal: AdjustTimeState = {};
  values.forEach((item) => {
    if (item.key === "all") {
      retVal.adjustScheduledMinutes = item.value;
      retVal.adjustLateMinutes = item.value;
    }
    if (item.key === "scheduledTime") {
      retVal.adjustScheduledMinutes = item.value;
    }
    if (item.key === "lateTime") {
      retVal.adjustLateMinutes = item.value;
    }
  });
  return retVal;
};

const initValue: CheckedState = {
  all: false,
  scheduledTime: false,
  lateTime: false,
  allValue: 0,
  scheduledTimeValue: 0,
  lateTimeValue: 0
};

const AdjustStopTime = ({stops, onConfirm, onClose}: AdjustStopTimeProps) => {
  const [validValues, setValidValues] = useState<ValidValue[]>([]);
  const [showOptions, setShowOptions] = useState<ShowOptions>({lateTime: false, scheduledTime: false});

  const {setValue, watch, control} = useForm<CheckedState>({defaultValues: initValue});
  const {tenant, token} = useContext(AuthContext);
  const {tenantPreferences} = useContext(PreferenceContext);
  const [all, scheduledTime, lateTime, allValue, scheduledTimeValue, lateTimeValue] = [
    watch("all"),
    watch("scheduledTime"),
    watch("lateTime"),
    watch("allValue"),
    watch("scheduledTimeValue"),
    watch("lateTimeValue")
  ];

  const values: Value = useMemo(() => {
    if (showOptions.lateTime && showOptions.scheduledTime) {
      return {
        all: {checked: all, value: allValue},
        scheduledTime: {checked: scheduledTime, value: scheduledTimeValue},
        lateTime: {checked: lateTime, value: lateTimeValue}
      };
    } else {
      return {
        all: {checked: false, value: allValue},
        scheduledTime: {checked: true, value: scheduledTimeValue},
        lateTime: {checked: true, value: lateTimeValue}
      };
    }
  }, [
    all,
    allValue,
    lateTime,
    lateTimeValue,
    scheduledTime,
    scheduledTimeValue,
    showOptions.lateTime,
    showOptions.scheduledTime
  ]);

  useEffect(() => {
    setShowOptions({
      lateTime: stops.some((stop) => stop.lateDateTime),
      scheduledTime: stops.some((stop) => stop.scheduledDateTime)
    });
  }, [stops]);

  useEffect(() => {
    if (all) {
      setValue("scheduledTime", false);
      setValue("lateTime", false);
    }
  }, [all, setValue]);

  useEffect(() => {
    const validValues: ValidValue[] = Object.entries(values)
      .filter(([, value]) => value.checked && isNumber(value.value) && value.value !== 0)
      .map((item) => {
        return {key: item[0] as keyof Value, value: item[1].value};
      });
    setValidValues(validValues);
  }, [values]);

  const handleInputChange = useCallback(
    (e) => {
      if (e.target.value === "") {
        return setValue(e.target.name, "");
      }
      if (e.target.value === "-" || !isNaN(parseInt(e.target.value))) {
        return setValue(e.target.name, e.target.value === "-" ? "-" : parseInt(e.target.value.replace(".", "")));
      }
    },
    [setValue]
  );

  const handleInputBlur = useCallback(
    (e) => {
      const value = parseInt(e.target.value);
      setValue(e.target.name, isNaN(value) ? 0 : value);
    },
    [setValue]
  );

  const handleInputFocus = useCallback((e) => e.target.select(), []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleConfirm = useCallback(() => {
    onConfirm(getAdjustTimeState(validValues));
  }, [onConfirm, validValues]);
  const handleClickToJobNumberLink = useCallback(() => {
    navigateToCmsOrder(tenant!, token!, Number(stops[0].job.jobNumber), tenantPreferences).then(() => {
      /**/
    });
  }, [stops, tenant, tenantPreferences, token]);

  return (
    <Container data-testid="adjust-stop-time-dialog">
      <Header>
        <InfoIcon />
        <Text>
          This will adjust the time for all incomplete stops on job{" "}
          <StyledJobStopNumber data-testid="job-number-text" onClick={handleClickToJobNumberLink}>
            #{stops.at(0)?.job.jobNumber}
          </StyledJobStopNumber>
        </Text>
      </Header>
      <Body>
        {showOptions.lateTime && showOptions.scheduledTime ? (
          <>
            <Item>
              <Text>Time</Text>
              <Text>Adjust by</Text>
            </Item>
            <Item>
              <Controller
                control={control}
                name="all"
                render={({field: {onChange, value}}) => (
                  <StyledCheckbox
                    data-testid="adjust-stop-time-all-time-checkbox"
                    label="All times"
                    onChange={onChange}
                    checked={value}
                  />
                )}
              />
              <Controller
                control={control}
                name="allValue"
                render={({field: {value, name}}) => (
                  <InputGroup
                    data-testid="adjust-stop-time-all-time-input"
                    name={name}
                    disabled={!all}
                    rightElement={
                      <MinTag style={{color: !all ? "#797979" : "#161616"}} minimal>
                        min
                      </MinTag>
                    }
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    onFocus={handleInputFocus}
                    value={value.toString()}
                    maxLength={7}
                  />
                )}
              />
            </Item>
            <Divider />
            <Item>
              <Controller
                control={control}
                name="scheduledTime"
                render={({field: {onChange, value}}) => (
                  <StyledCheckbox
                    data-testid="adjust-stop-time-scheduled-time-checkbox"
                    label="Scheduled Time"
                    disabled={all}
                    onChange={onChange}
                    checked={value}
                  />
                )}
              />
              <Controller
                control={control}
                name="scheduledTimeValue"
                render={({field: {value, name}}) => (
                  <InputGroup
                    data-testid="adjust-stop-time-scheduled-time-input"
                    name={name}
                    disabled={!scheduledTime}
                    rightElement={
                      <MinTag style={{color: !scheduledTime ? "#797979" : "#161616"}} minimal>
                        min
                      </MinTag>
                    }
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    onFocus={handleInputFocus}
                    value={value.toString()}
                    maxLength={7}
                  />
                )}
              />
            </Item>
            <Item>
              <Controller
                control={control}
                name="lateTime"
                render={({field: {onChange, value}}) => (
                  <StyledCheckbox
                    data-testid="adjust-stop-time-late-time-checkbox"
                    label="Late Time"
                    disabled={all}
                    onChange={onChange}
                    checked={value}
                  />
                )}
              />
              <Controller
                control={control}
                name="lateTimeValue"
                render={({field: {value, name}}) => (
                  <InputGroup
                    data-testid="adjust-stop-time-late-time-input"
                    name={name}
                    disabled={!lateTime}
                    rightElement={
                      <MinTag style={{color: !lateTime ? "#797979" : "#161616"}} minimal>
                        min
                      </MinTag>
                    }
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    onFocus={handleInputFocus}
                    value={value.toString()}
                    maxLength={7}
                  />
                )}
              />
            </Item>
          </>
        ) : (
          <>
            {showOptions.scheduledTime && (
              <SingleItem>
                <Text>Adjust Scheduled Time by</Text>
                <Controller
                  control={control}
                  name="scheduledTimeValue"
                  render={({field: {value, name}}) => (
                    <InputGroup
                      data-testid="adjust-stop-time-scheduled-time-input"
                      name={name}
                      rightElement={<MinTag minimal>min</MinTag>}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      onFocus={handleInputFocus}
                      value={value.toString()}
                      maxLength={7}
                    />
                  )}
                />
              </SingleItem>
            )}
            {showOptions.lateTime && (
              <SingleItem>
                <Text>Adjust Late Time by</Text>
                <Controller
                  control={control}
                  name="lateTimeValue"
                  render={({field: {value, name}}) => (
                    <InputGroup
                      data-testid="adjust-stop-time-late-time-input"
                      name={name}
                      rightElement={<MinTag minimal>min</MinTag>}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      onFocus={handleInputFocus}
                      value={value.toString()}
                      maxLength={7}
                    />
                  )}
                />
              </SingleItem>
            )}
          </>
        )}
      </Body>
      <Footer>
        <ActionButton data-testid={"cancel-button"} text={"Cancel"} onClick={handleClose} />
        <ActionButton
          data-testid={"adjust-button"}
          text={"Adjust time"}
          disabled={validValues.length === 0}
          style={{
            color: "white",
            background: validValues.length === 0 ? "#d9d9d9" : "linear-gradient(180deg, #214A89 0%, #14305A 100%)",
            width: "110px"
          }}
          onClick={handleConfirm}
        />
      </Footer>
    </Container>
  );
};

export default AdjustStopTime;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Header = styled.div`
  background-color: #f4f5f7;
  border-radius: 8px;
  padding: 8px;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr auto;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: right;
  gap: 10px;
`;

const Item = styled.div`
  display: grid;
  grid-template-columns: 1fr 112px;
  align-items: center;
  justify-content: center;
  gap: 50px;

  .bp4-checkbox {
    margin: 0;
  }

  .bp4-input {
    border-radius: 8px;
    border: 1px solid #c3c3c373;
    box-shadow: none;
  }
`;

const SingleItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 12px 0;

  .bp4-checkbox {
    margin: 0;
  }

  .bp4-input {
    border-radius: 8px;
    width: 112px;
    border: 1px solid #c3c3c373;
    box-shadow: none;
  }
`;
const StyledJobStopNumber = styled.span`
  color: #406aff;
  font-weight: bold;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const MinTag = styled(Tag)`
  background: none !important;
`;

const InfoIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.83333 12.3333H9V9H8.16667M9 5.66667H9.00833M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
      stroke="#161616"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
