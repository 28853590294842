import {createContext} from "react";
import {GeoCodeServiceType} from "../services/GeoCodeService";
import {isTestingEnv} from "../config";

type ConfigurationProviderProps = {
  children: JSX.Element[] | JSX.Element;
};

type DispatchConfigurationState = {
  geocodingServiceType: GeoCodeServiceType;
  userGuidingEnabled: boolean;
};

const initialState: DispatchConfigurationState = {
  geocodingServiceType: GeoCodeServiceType.appSyncProxied,
  userGuidingEnabled: !isTestingEnv()
};

const DispatchConfigurationContext = createContext<DispatchConfigurationState>(initialState);

const DispatchConfigurationProvider = ({children}: ConfigurationProviderProps) => {
  return <DispatchConfigurationContext.Provider value={initialState}>{children}</DispatchConfigurationContext.Provider>;
};

export default DispatchConfigurationProvider;
export {DispatchConfigurationContext};
export type {DispatchConfigurationState};
