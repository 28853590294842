import {
  IndicatorConfigsFilter,
  Job,
  Manifest,
  ManifestStop,
  NestedJobStop,
  useSearchIndicatorConfigsQuery
} from "../../generated/graphql";
import {createContext, useEffect, useMemo, useState} from "react";
import {ApolloError} from "@apollo/client";
import {RuleProperties, Operator, Engine, Rule} from "json-rules-engine";
import {addMinutes} from "date-fns";
import {Constants} from "./Constants";
import {IndicatorLogicSettings} from "../settings/ColorizedIndicators/types/indicatorLogicSettings";
import {JobTypes} from "../settings/ColorizedIndicators/types/jobTypeSelectorValues";
import {UserColor} from "../settings/ColorizedIndicators/types/indicatorLogicType";
import _ from "lodash";
import {getArrayFromField} from "../../utils/General";

type DispatchRulesDataProviderProps = {
  children: JSX.Element[] | JSX.Element;
  filter?: IndicatorConfigsFilter;
  jobType: JobTypes;
};

type ManifestEngineProps = {
  manifest: Manifest;
  stop?: ManifestStop;
};

type JobEngineProps = {
  job: Job;
  stop: NestedJobStop;
};

type DispatchRulesDataState = {
  rules: RuleProperties[] | undefined;
  colorRank: ColorRank[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  getConfiguredEngine(fact: ManifestEngineProps | JobEngineProps, rules: RuleProperties[]): Engine | undefined;
};

type ColorRank = {
  color: UserColor | undefined;
  rank: number;
};

type EventOperatorProps = {
  lowerBound: number;
  upperBound: number;
};

const initialDispatchRulesDataState: DispatchRulesDataState = {
  rules: undefined,
  loading: true,
  error: undefined,
  colorRank: undefined,
  getConfiguredEngine: () => {
    console.debug("Engine not configured");
    return undefined;
  }
};

enum CustomOperators {
  BeforeEvent = "beforeEvent",
  AfterEvent = "afterEvent",
  BeforeAfterEvent = "beforeAfterEvent"
}

const DispatchRulesDataContext = createContext<DispatchRulesDataState>(initialDispatchRulesDataState);

const DispatchRulesDataProvider = ({children, jobType}: DispatchRulesDataProviderProps) => {
  const [ruleData, setRuleData] = useState<RuleProperties[]>([]);
  const [colorRank, setColorRank] = useState<ColorRank[]>([]);
  const [rulesLoading, setRulesLoading] = useState(false);
  const [state, setState] = useState<DispatchRulesDataState>({
    ...initialDispatchRulesDataState
  });

  const indicatorResult = useSearchIndicatorConfigsQuery({
    pollInterval: Constants.RULE_REFRESH_INTERVAL,
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        siteId: {eq: 0}
      }
    }
  });

  const customOperators: Operator[] = useMemo(() => {
    return [
      new Operator(
        CustomOperators.BeforeEvent,
        (factValue: number, jsonValue: EventOperatorProps) => {
          const factDate = new Date(factValue);
          const lowerResult =
            jsonValue.lowerBound === 999999
              ? true
              : Date.now() >= addMinutes(factDate, jsonValue.lowerBound * -1).getTime();
          const upperResult =
            jsonValue.upperBound === 999999
              ? true
              : Date.now() <= addMinutes(factDate, jsonValue.upperBound * -1).getTime();
          return lowerResult && upperResult;
        },
        (factValue: number) => {
          return validateDate(factValue);
        }
      ),
      new Operator(
        CustomOperators.AfterEvent,
        (factValue: number, jsonValue: EventOperatorProps) => {
          const factDate = new Date(factValue);
          const lowerResult =
            jsonValue.lowerBound === 999999 ? true : Date.now() >= addMinutes(factDate, jsonValue.lowerBound).getTime();
          const upperResult =
            jsonValue.upperBound === 999999 ? true : Date.now() <= addMinutes(factDate, jsonValue.upperBound).getTime();
          return lowerResult && upperResult;
        },
        (factValue: number) => {
          return validateDate(factValue);
        }
      ),
      new Operator(
        CustomOperators.BeforeAfterEvent,
        (factValue: number, jsonValue: EventOperatorProps) => {
          const factDate = new Date(factValue);
          const lowerResult =
            jsonValue.lowerBound === 999999
              ? true
              : Date.now() >= addMinutes(factDate, jsonValue.lowerBound * -1).getTime();
          const upperResult =
            jsonValue.upperBound === 999999 ? true : Date.now() <= addMinutes(factDate, jsonValue.upperBound).getTime();
          return lowerResult && upperResult;
        },
        (factValue: number) => {
          return validateDate(factValue);
        }
      )
    ];
  }, []);

  const validateDate = (date: number) => {
    return date !== null && date !== undefined && new Date(date).toString() !== "Invalid Date";
  };

  useEffect(() => {
    setRulesLoading(indicatorResult.loading);
    if (
      indicatorResult.data?.searchIndicatorConfigs &&
      indicatorResult.data.searchIndicatorConfigs.items &&
      indicatorResult.data.searchIndicatorConfigs.items.length > 0 &&
      !indicatorResult.loading
    ) {
      const ruleContainerObj: IndicatorLogicSettings = JSON.parse(
        indicatorResult.data?.searchIndicatorConfigs?.items?.[0]?.config
      );

      const colorRank: ColorRank[] =
        ruleContainerObj?.rules
          ?.filter((rule) => rule.color)
          .map((rule) => {
            return {
              color: rule.color,
              rank: rule.rank
            };
          })
          .filter(
            (obj, index, self) => index === self.findIndex((item) => item.color === obj.color && item.rank === obj.rank)
          ) ?? [];

      const sorted = getArrayFromField(ruleContainerObj?.rules);
      sorted.sort((a, b) => b.rank - a.rank); //inverting rank for engine
      let rank = 0;

      const rules: RuleProperties[] = sorted
        .filter((rule) => rule.rule && jobType === rule.type?.jobTypeValue)
        .map((rule) => {
          return {
            ...rule.rule,
            name: rule.key,
            priority: ++rank
          };
        });

      setColorRank(colorRank);
      setRuleData([...rules]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicatorResult.data?.searchIndicatorConfigs, indicatorResult.loading]);

  useEffect(() => {
    if (indicatorResult.error) {
      console.error("[ERROR] - ManifestDataContext", indicatorResult.error);
      setState({
        ...initialDispatchRulesDataState,
        loading: indicatorResult.loading,
        error: indicatorResult.error
      });
    }
  }, [indicatorResult.error, indicatorResult.loading]);

  useEffect(() => {
    const updatedState = {
      ...initialDispatchRulesDataState,
      loading: rulesLoading,
      rules: ruleData,
      colorRank: colorRank,
      getConfiguredEngine: (fact: ManifestEngineProps | JobEngineProps, rules: RuleProperties[] | undefined) => {
        if (fact === undefined || rules?.length === 0) return undefined;

        //sort the stops by sequence
        let jobWithSortedStops;
        if (jobType === JobTypes.UnassignedJobs) {
          const sortedStops = _.sortBy((fact as JobEngineProps).job.stops, ["sequence"]);
          jobWithSortedStops = {
            ...(fact as JobEngineProps).job,
            stops: sortedStops
          };
        } else {
          //We are not going to sort the stops for manifests because we don't have any rules that would benefit at this time.
          //For performance reasons
        }

        const engine = new Engine();

        engine.addFact(
          jobType === JobTypes.UnassignedJobs ? "job" : "manifest",
          jobType === JobTypes.UnassignedJobs ? jobWithSortedStops : (fact as ManifestEngineProps).manifest
        );
        engine.addFact("stop", fact.stop);

        customOperators.forEach((op) => {
          engine.addOperator(op);
        });

        rules?.forEach((rule) => {
          try {
            engine.addRule(
              new Rule({
                ...rule
              })
            );
          } catch (e) {
            console.error("Error adding rule to engine", e);
          }
        });
        return engine;
      }
    };

    setState({...updatedState});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleData, colorRank, rulesLoading]);

  return <DispatchRulesDataContext.Provider value={state}>{children}</DispatchRulesDataContext.Provider>;
};

export default DispatchRulesDataProvider;
export {DispatchRulesDataContext, CustomOperators};
export type {DispatchRulesDataState, ColorRank, EventOperatorProps};
