export enum TriggerEventType {
  BeforeEvent = "BEFOREEVENT",
  AfterEvent = "AFTEREVENT"
}

export interface TriggerSelectorValues {
  lowerValue: number;
  lowerType: TriggerEventType;
  upperValue: number;
  upperType: TriggerEventType;
}
