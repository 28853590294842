import React, {ReactElement, useCallback, useEffect, useMemo, useState, useContext} from "react";
import {Dialog, Button, Radio, RadioGroup, InputGroup} from "@blueprintjs/core";
import {Constants, DwellTimeToolTip, MIN_DWELL_TIME, TenantPreferences} from "../../../common/Constants";
import PlacesAutoCompleteInput from "./PlacesAutoCompleteInput";
import styled from "@emotion/styled";
import {Tooltip2} from "@blueprintjs/popover2";
import {formatDate, getAddressText, getTimeZoneIdByAddress} from "../../../../utils/General";
import {DriverLocation, Manifest, ManifestStop, SearchDriverLocationQuery} from "../../../../generated/graphql";
import axios from "axios";
import {Address} from "../../../../domain/geocode-types";
import TimePickerCommon from "./TimePickerCommon";
import {isArray} from "lodash";
import {TimeTag} from "../../../settings/ColorizedIndicators/TriggerSelector";
import {ActionButton} from "../../../common/ConfirmationDialog";
import StopLocationSelector, {TStopLocation} from "./StopLocationSelector";
import {isCompleted} from "../../../../services/ManifestStopService";
import {StopLocationValue} from "../../card-configuration/ManifestConfigurationTypes";
import {extractJsonPref, PreferenceContext} from "../../../../providers/PreferenceProvider";
import {findEarliestStopDateV2} from "../../../map/optimization/OptimizationService";
import {differenceInMilliseconds, isValid} from "date-fns";
import {QueryResult} from "@apollo/client";

export const InformationIcon = ({fill}: {fill?: string}) => {
  return (
    <svg
      data-testid="information-icon"
      width="15"
      height="15"
      style={{cursor: "pointer"}}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 14.7059C9.48912 14.7059 11.3968 13.9312 12.8033 12.5523C14.2098 11.1733 15 9.30306 15 7.35294C15 5.40282 14.2098 3.53257 12.8033 2.15363C11.3968 0.774683 9.48912 0 7.5 0C5.51088 0 3.60322 0.774683 2.1967 2.15363C0.790176 3.53257 0 5.40282 0 7.35294C0 9.30306 0.790176 11.1733 2.1967 12.5523C3.60322 13.9312 5.51088 14.7059 7.5 14.7059ZM7.5 3.67647C7.88965 3.67647 8.20312 3.9838 8.20312 4.36581V7.58272C8.20312 7.96473 7.88965 8.27206 7.5 8.27206C7.11035 8.27206 6.79688 7.96473 6.79688 7.58272V4.36581C6.79688 3.9838 7.11035 3.67647 7.5 3.67647ZM6.5625 10.1103C6.5625 9.86653 6.66127 9.63275 6.83709 9.46038C7.0129 9.28801 7.25136 9.19118 7.5 9.19118C7.74864 9.19118 7.9871 9.28801 8.16291 9.46038C8.33873 9.63275 8.4375 9.86653 8.4375 10.1103C8.4375 10.3541 8.33873 10.5878 8.16291 10.7602C7.9871 10.9326 7.74864 11.0294 7.5 11.0294C7.25136 11.0294 7.0129 10.9326 6.83709 10.7602C6.66127 10.5878 6.5625 10.3541 6.5625 10.1103Z"
        fill={fill ?? "#FF6B00"}
      />
    </svg>
  );
};

export interface OptimizeDialogProps {
  onClose: () => void;
  onSave: () => void;
  onDataChange: (data: OptimizeFormData) => void;
  homeAddress?: Address;
  manifest?: Manifest;
  setIsOptimizeFromHomeLocation: React.Dispatch<React.SetStateAction<boolean>>;
  driverLocationResult?: QueryResult<SearchDriverLocationQuery>;
}

const convertToDateTimeLocalString = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export type OptimizeFormData = {
  startingLocation?: string;
  startingTime?: string;
  dwellTime?: number;
  endLocation?: string;
};

export type TOptimizationInfoItem = {
  title: string;
  required: boolean;
  subTitle?: string[];
  infoIcon?: {
    icon: ReactElement<any, any>;
    content: string;
  };
};

type TOptimizationInfo = {
  startingLocation: TOptimizationInfoItem;
  dataTimeTrip: TOptimizationInfoItem;
  dwellTime: TOptimizationInfoItem;
  finalLocation: TOptimizationInfoItem;
};

const SIZE_OF_OPTIMIZED_DIALOG = 448;
const PRIMARY_TEXT_COLOR = "#161616";
const SECONDARY_TEXT_COLOR = "#797979";
const PRIMARY_BUTTON = "#14305A";

const optimizationsInfo: ({isTimeZoneOfJobStopEnabled}: {isTimeZoneOfJobStopEnabled: boolean}) => TOptimizationInfo = ({
  isTimeZoneOfJobStopEnabled
}) => ({
  startingLocation: {
    title: "Driver's Starting Location",
    required: true
  },
  dataTimeTrip: {
    title: "Start Time of the Trip for",
    required: true,
    subTitle: [
      isTimeZoneOfJobStopEnabled
        ? "Start date of the trip is based on the date of the earliest stop in the manifest. Timezone of starting time is based on the timezone of the Driver’s starting location"
        : "Start date of the trip is based on the date of the earliest stop in the manifest. Timezone of starting time is based on the timezone of the Dispatcher. "
    ]
  },
  dwellTime: {
    title: "Dwell Time Average",
    required: true,
    infoIcon: {
      icon: <InformationIcon fill={"grey"} />,
      content: DwellTimeToolTip.INFORMATION_ICON
    }
  },
  finalLocation: {
    title: "Final Location",
    required: false
  }
});

export const distanceFromLeftOptimizeDialog = () => {
  const widthOfManifestDrawer = (window.innerWidth * Constants.MANIFEST_DRAWER_RATIO_WIDTH) / 100;
  return (widthOfManifestDrawer - SIZE_OF_OPTIMIZED_DIALOG) / 2;
};
const OptimizeDialog: React.FC<OptimizeDialogProps> = ({
  onClose,
  onSave,
  onDataChange,
  homeAddress,
  manifest,
  setIsOptimizeFromHomeLocation,
  driverLocationResult
}) => {
  const [startLocation, setStartLocation] = useState("");
  const [timeZoneAddress, setTimeZoneAddress] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState("");
  const [dwellTime, setDwellTime] = useState("");
  const [endLocation, setEndLocation] = useState("");
  const [earliestDate, setEarliestDate] = useState<Date>(new Date());
  const [currentDriverLocationPlace, setCurrentDriverLocationPlace] = useState("");
  const [currentDriverLocationIsOld, setCurrentDriverLocationIsOld] = useState(true);
  const [isOptimizeEnabled, setIsOptimizeEnabled] = useState(false);
  const {tenantPreferences} = useContext(PreferenceContext);

  const isTimeZoneOfJobStopEnabled = useMemo(() => {
    return extractJsonPref(tenantPreferences, TenantPreferences.timezoneOfJobStop, false).value.value;
  }, [tenantPreferences]);

  useEffect(() => {
    if (startLocation) {
      getTimeZoneIdByAddress(startLocation)
        .then((res) => {
          if (res) {
            setTimeZoneAddress(res);
          } else {
            setTimeZoneAddress(undefined);
          }
        })
        .catch((error) => console.error(error));
    } else {
      setTimeZoneAddress(undefined);
    }
  }, [startLocation]);

  useEffect(() => {
    if (manifest) {
      if (isTimeZoneOfJobStopEnabled) {
        //If timezone of job stop is enabled, then the earliest date is based on the timezone of the job stop
        if (timeZoneAddress) {
          const findEarliestStopDate = findEarliestStopDateV2(manifest?.stops, timeZoneAddress);
          setEarliestDate(findEarliestStopDate);
        } else {
          const findEarliestStopDate = findEarliestStopDateV2(manifest?.stops);
          setEarliestDate(findEarliestStopDate);
        }
      } else {
        setEarliestDate(new Date());
      }
    }
  }, [isTimeZoneOfJobStopEnabled, manifest, timeZoneAddress]);

  useEffect(() => {
    onDataChange({
      startingLocation: startLocation,
      dwellTime: Number(dwellTime),
      startingTime: startDate,
      endLocation: endLocation
    });
  }, [startLocation, currentDriverLocationPlace, startDate, dwellTime, endLocation, onDataChange]);

  useEffect(() => {
    if (!driverLocationResult?.loading) {
      const data = driverLocationResult?.data?.searchDriverLocations?.items as DriverLocation[];
      if (data && data.length > 0) {
        const driverLocation = data[0];
        const fixDateTime = new Date(driverLocation.fixDateTime);
        setCurrentDriverLocationIsOld(differenceInMilliseconds(Date.now(), fixDateTime) > 5 * 60 * 1000);

        const lat = driverLocation.coordinates.split(",")[0];
        const lng = driverLocation.coordinates.split(",")[1];
        const response = axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=street_address&key=${Constants.GOOGLE_API_KEY}`
        );
        response.then((res) => {
          if (res.data.results.length > 0) {
            setCurrentDriverLocationPlace(res.data.results[0].formatted_address);
          }
        });
      }
    }
  }, [
    currentDriverLocationPlace,
    driverLocationResult?.loading,
    driverLocationResult?.data,
    driverLocationResult?.error
  ]);

  const handleStartDateChange = useCallback((event: Date | null) => {
    if (!event) {
      setStartDate("");
    } else {
      setStartDate(convertToDateTimeLocalString(event));
    }
  }, []);

  const handleDwellTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDwellTime(event.target.value);
  };

  const handleCancel = () => {
    onClose();
    setEndLocation("");
    setStartLocation("");
    setStartDate("");
    setDwellTime("");
    setIsOptimizeEnabled(false);
    setTimeZoneAddress(undefined);
  };

  const handleOptimize = () => {
    onSave();
    handleCancel();
  };

  useEffect(() => {
    const isOptimizeEnable = Boolean(
      startLocation && isValid(new Date(startDate)) && dwellTime && Number(dwellTime) >= MIN_DWELL_TIME
    );
    setIsOptimizeEnabled(isOptimizeEnable);
  }, [startLocation, startDate, dwellTime]);

  useEffect(() => {
    return () => {
      handleCancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderOptimizationInfo = useMemo(() => {
    return optimizationsInfo({isTimeZoneOfJobStopEnabled});
  }, [isTimeZoneOfJobStopEnabled]);

  return (
    <StyledDialog isOpen={true} onClose={onClose} distanceFromLeft={distanceFromLeftOptimizeDialog()}>
      <DialogContainer data-testid="optimization-dialog-container">
        <DialogHeader>
          <DialogTitle data-testid="dialog-title">MANIFEST OPTIMIZATION</DialogTitle>
          <DialogCloseIcon minimal data-testid="dialog-close-icon" onClick={onClose} icon="cross" />
        </DialogHeader>
        <DialogBody>
          <OptimizationOptions
            optionInfo={renderOptimizationInfo.startingLocation}
            renderer={
              <ProvideLocation
                homeAddress={homeAddress}
                currentAddress={currentDriverLocationPlace}
                type={ProvideLocationType.starting}
                onChange={setStartLocation}
                currentDriverLocationIsOld={currentDriverLocationIsOld}
                manifestStops={manifest?.stops ?? []}
                setIsOptimizeFromHomeLocation={setIsOptimizeFromHomeLocation}
              />
            }
          />
          <OptimizationOptions
            optionInfo={renderOptimizationInfo.dataTimeTrip}
            earliestDateString={formatDate(earliestDate)}
            renderer={
              <StartDateTime
                timeZoneAddress={timeZoneAddress}
                onChange={handleStartDateChange}
                earliestDate={earliestDate}
              />
            }
          />
          <OptimizationOptions
            optionInfo={renderOptimizationInfo.dwellTime}
            renderer={<DwellTimeInput value={dwellTime} handleChangeDwell={handleDwellTimeChange} min={0} max={60} />}
          />
          <OptimizationOptions
            optionInfo={renderOptimizationInfo.finalLocation}
            renderer={
              <ProvideLocation
                type={ProvideLocationType.final}
                startLocation={startLocation}
                homeAddress={homeAddress}
                onChange={setEndLocation}
              />
            }
          />
        </DialogBody>
        <Footer>
          <ActionButton data-testid="dialog-cancel-button" onClick={handleCancel}>
            Cancel
          </ActionButton>
          <ActionButton
            data-testid="dialog-optimize-button"
            disabled={!isOptimizeEnabled}
            onClick={handleOptimize}
            style={{
              color: "white",
              background: !isOptimizeEnabled ? "#d9d9d9" : "linear-gradient(180deg, #214A89 0%, #14305A 100%)",
              width: "92px"
            }}
            text="Optimize"
          />
        </Footer>
      </DialogContainer>
    </StyledDialog>
  );
};

export default OptimizeDialog;
interface DialogContainerProps {
  distanceFromLeft: number;
}
interface OptimizationOptionsProps {
  renderer: any;
  optionInfo: TOptimizationInfoItem;
  earliestDateString?: string;
}
export const OptimizationOptions = ({optionInfo, earliestDateString, renderer}: OptimizationOptionsProps) => {
  const {title, required, subTitle, infoIcon} = optionInfo;
  return (
    <OptimizationOptionsContainer
      data-testid={`optimize-option-container-${title.toLocaleLowerCase().replaceAll(" ", "-")}`}
      subTitle={subTitle}
    >
      <OptimizationTitle data-testid="optimize-option-title">
        {title}
        {infoIcon && (
          <Tooltip2 content={infoIcon.content} placement={"right"}>
            <OptimizationIconContainer>{infoIcon.icon}</OptimizationIconContainer>
          </Tooltip2>
        )}
        {<DateText>{earliestDateString}</DateText>}
        {!required && <OptimizationRequired data-testid="optimize-option-optional">(optional)</OptimizationRequired>}
      </OptimizationTitle>
      {isArray(subTitle) && subTitle.length > 0 && (
        <>
          {subTitle?.map((x) => (
            <OptimizationSubTitle key={x} data-testid="optimize-option-subtitle">
              {x}
            </OptimizationSubTitle>
          ))}
        </>
      )}
      {renderer}
    </OptimizationOptionsContainer>
  );
};

export enum ProvideLocationType {
  starting,
  final
}
export interface ProvideLocationProps {
  type: ProvideLocationType;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  currentAddress?: string;
  homeAddress?: Address;
  currentDriverLocationIsOld?: boolean;
  manifestStops?: ManifestStop[];
  startLocation?: string;
  setIsOptimizeFromHomeLocation?: React.Dispatch<React.SetStateAction<boolean>>;
}
type SelectTypeState = "home" | "current" | "custom" | "stop" | "start" | "";

export const ProvideLocation = ({
  type,
  onChange,
  currentAddress,
  homeAddress,
  currentDriverLocationIsOld,
  manifestStops,
  startLocation,
  setIsOptimizeFromHomeLocation
}: ProvideLocationProps) => {
  const [selectTypeLocation, setSelectTypeLocation] = useState<SelectTypeState>("");
  const [isDisablePlaceAuto, setIsDisablePlaceAuto] = useState(true);
  const [placeInputPlace, setPlaceInputPlace] = useState("");
  const [selectedStopLocation, setSelectedStopLocation] = React.useState<TStopLocation | undefined>();

  const stopLocationOptions: TStopLocation[] | undefined = useMemo(
    () =>
      manifestStops
        ?.filter((stop) => !isCompleted(stop))
        .sort((a, b) => a.manifestSequence - b.manifestSequence)
        .map((stop) => {
          return {
            key: stop.jobStopId,
            address: getAddressText({
              stop,
              stopLocationState: StopLocationValue.FullAddressAndZone,
              hasDispatchZone: true
            }),
            stopType: stop.stopType,
            name: stop.name ?? ""
          };
        }) ?? [],
    [manifestStops]
  );

  const handleSelectPlaceInput = useCallback(
    (e: string) => {
      setPlaceInputPlace(e);
      onChange(e);
    },
    [setPlaceInputPlace, onChange]
  );

  const handleSelectStopLocation = useCallback(
    (item: TStopLocation) => {
      setSelectedStopLocation(item);
      onChange(item.address ?? "");
    },
    [onChange]
  );

  const handleSelectType = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const typeOfLocation = e.currentTarget.value as SelectTypeState;
      setSelectTypeLocation(typeOfLocation);
      if (typeOfLocation === "home") {
        setPlaceInputPlace(homeAddress?.formatAddress() ?? "");
        setIsDisablePlaceAuto(true);
        onChange(homeAddress?.formatAddress() ?? "");
        setIsOptimizeFromHomeLocation && setIsOptimizeFromHomeLocation(true);
      }
      if (typeOfLocation === "current") {
        setPlaceInputPlace(currentAddress ?? "");
        setIsDisablePlaceAuto(true);
        onChange(currentAddress ?? "");
        setIsOptimizeFromHomeLocation && setIsOptimizeFromHomeLocation(false);
      }
      if (typeOfLocation === "stop") {
        setPlaceInputPlace("");
        setIsDisablePlaceAuto(true);
        onChange(selectedStopLocation?.address ?? "");
        setIsOptimizeFromHomeLocation && setIsOptimizeFromHomeLocation(false);
      }
      if (typeOfLocation === "custom") {
        setPlaceInputPlace("");
        setIsDisablePlaceAuto(false);
        onChange("");
        setIsOptimizeFromHomeLocation && setIsOptimizeFromHomeLocation(false);
      }
      if (typeOfLocation === "start") {
        setPlaceInputPlace(startLocation ?? "");
        setIsDisablePlaceAuto(true);
        onChange(startLocation ?? "");
      }
    },
    [homeAddress, onChange, setIsOptimizeFromHomeLocation, currentAddress, selectedStopLocation?.address, startLocation]
  );

  const clearSelection = useCallback(() => {
    setSelectTypeLocation("");
    setPlaceInputPlace("");
    onChange("");
    setIsDisablePlaceAuto(true);
  }, [onChange]);

  const handleClickRadio = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      if (
        type === ProvideLocationType.final &&
        event.currentTarget.value === selectTypeLocation &&
        selectTypeLocation !== ""
      ) {
        clearSelection();
      }
    },
    [clearSelection, selectTypeLocation, type]
  );

  const renderTooltipContent = useCallback(() => {
    if (currentAddress && currentDriverLocationIsOld) {
      return "Driver's location data is at least 5m old and is unreliable";
    } else {
      return "Driver’s location isn’t available. ";
    }
  }, [currentAddress, currentDriverLocationIsOld]);

  const isDisableCurrentLocationOption = useMemo(() => {
    return !currentAddress || currentDriverLocationIsOld;
  }, [currentAddress, currentDriverLocationIsOld]);

  useEffect(() => {
    if (selectTypeLocation === "current" && currentDriverLocationIsOld) {
      clearSelection();
    }
  }, [clearSelection, currentDriverLocationIsOld, selectTypeLocation]);

  useEffect(() => {
    if (selectTypeLocation === "start") {
      if (!startLocation) {
        clearSelection();
      } else {
        setPlaceInputPlace(startLocation);
        onChange(startLocation);
      }
    }
  }, [clearSelection, onChange, selectTypeLocation, startLocation]);

  return (
    <RadioGroupCustom onChange={handleSelectType} selectedValue={selectTypeLocation}>
      <Radio value={"home"} onClick={handleClickRadio} disabled={!homeAddress}>
        <DialogText primary={Boolean(homeAddress)}>Home</DialogText>{" "}
        <DialogText className="optimize-subtext">
          {`( ${homeAddress?.formatAddress() ?? "Home Address Not Available"} )`}
        </DialogText>
      </Radio>
      {type === ProvideLocationType.starting && (
        <Radio disabled={isDisableCurrentLocationOption} value={"current"}>
          <DialogText primary={!isDisableCurrentLocationOption}>Current Location</DialogText>
          {isDisableCurrentLocationOption && (
            <Tooltip2
              className="custom-tooltip"
              content={renderTooltipContent()}
              placement="left"
              transitionDuration={0}
            >
              <InformationIcon />
            </Tooltip2>
          )}
        </Radio>
      )}
      {type === ProvideLocationType.starting && (
        <Radio value={"stop"}>
          <DialogText primary>Stop Location from Manifest</DialogText>
          {selectTypeLocation === "stop" && (
            <StopLocationSelector
              items={stopLocationOptions}
              selectedItem={selectedStopLocation}
              onSelectItem={handleSelectStopLocation}
            />
          )}
        </Radio>
      )}
      {type === ProvideLocationType.final && (
        <Radio onClick={handleClickRadio} value={"start"} disabled={!startLocation}>
          <DialogText primary={Boolean(startLocation)}>Start Location</DialogText>{" "}
          <DialogText className="optimize-subtext">{startLocation ? `( ${startLocation} )` : ""}</DialogText>
        </Radio>
      )}
      <RadioCustomContainer onChange={handleSelectType} selectedValue={selectTypeLocation}>
        <Radio onClick={handleClickRadio} value={"custom"} className="custom-radio">
          <DialogText primary>Custom</DialogText>
        </Radio>
        {selectTypeLocation && selectTypeLocation !== "stop" && (
          <PlacesAutoCompleteInput
            value={placeInputPlace}
            onChange={handleSelectPlaceInput}
            disabled={isDisablePlaceAuto}
            type={type}
          />
        )}
      </RadioCustomContainer>
    </RadioGroupCustom>
  );
};
export interface StartDateTimeProps {
  onChange: (...arg: any) => void;
  earliestDate?: Date;
  timeZoneAddress?: string;
}

export const StartDateTime = ({onChange, earliestDate, timeZoneAddress}: StartDateTimeProps) => {
  return <TimePickerCommon onChange={onChange} earliestDate={earliestDate} timeZoneAddress={timeZoneAddress} />;
};
export interface DwellTimePickerProps {
  value: string;
  min: number;
  max: number;
  handleChangeDwell: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const DwellTimeInput = ({value, handleChangeDwell, min, max}: DwellTimePickerProps) => {
  const [openWarningToolTip, setOpenWarningToolTip] = useState(false);

  const onChangeDwell = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (Number(e.target.value) < min || Number(e.target.value) > max || isNaN(Number(e.target.value))) return;
      if (Number(e.target.value) < MIN_DWELL_TIME && e.target.value !== "") {
        setOpenWarningToolTip(true);
      } else {
        setOpenWarningToolTip(false);
      }
      handleChangeDwell(e);
    },
    [handleChangeDwell, min, max]
  );
  return (
    <DwellTimeContainer>
      <Tooltip2 isOpen={openWarningToolTip} intent="danger" content={DwellTimeToolTip.WARNING} placement="top">
        <DwellInput
          data-testid="dwell-input"
          type="number"
          placeholder="5"
          value={value}
          onChange={onChangeDwell}
          rightElement={<TimeTag>min</TimeTag>}
        />
      </Tooltip2>
    </DwellTimeContainer>
  );
};

const DwellInput = styled(InputGroup)`
  width: fit-content;

  .bp4-input {
    width: 184px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #c3c3c36e;
    box-shadow: none;
    padding: 0 12px;

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ::placeholder {
      color: ${SECONDARY_TEXT_COLOR};
    }
  }

  .bp4-tag {
    margin-right: 12px;
  }
`;

const DwellTimeContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CloseIcon = () => {
  return (
    <svg
      data-testid="close-icon"
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.73773 2.19658C9.0893 1.80419 9.0893 1.16694 8.73773 0.774549C8.38617 0.382157 7.81523 0.382157 7.46367 0.774549L4.50211 4.0832L1.53773 0.777688C1.18617 0.385296 0.615234 0.385296 0.263672 0.777688C-0.0878906 1.17008 -0.0878906 1.80732 0.263672 2.19972L3.22805 5.50522L0.266484 8.81387C-0.0850781 9.20626 -0.0850781 9.84351 0.266484 10.2359C0.618047 10.6283 1.18898 10.6283 1.54055 10.2359L4.50211 6.92725L7.46648 10.2328C7.81805 10.6252 8.38898 10.6252 8.74055 10.2328C9.09211 9.84037 9.09211 9.20312 8.74055 8.81073L5.77617 5.50522L8.73773 2.19658Z"
        fill="#161616"
      />
    </svg>
  );
};

export const StyledDialog = styled(Dialog)<DialogContainerProps>`
  position: absolute;
  left: ${(props) => props.distanceFromLeft && `${props.distanceFromLeft}px`};
  &.bp4-dialog {
    background-color: #fcfcfc;
    border-radius: 0;
    border-radius: 16px;
    font-family: Roboto, sans-serif;
  }
`;

export const DialogContainer = styled.div`
  padding: 24px;
`;
export const DialogHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const DialogBody = styled.div`
  padding: 24px 8px 32px 0;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: space-between;
  max-height: calc(100vh - 150px);
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #d9d9d9;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: right;
  gap: 10px;
`;

export const DialogTitle = styled.h2`
  font-size: 16px;
  font-weight: 700;
  color: #797979;
  line-height: 1;
  margin: 0;
`;
export const DialogCloseIcon = styled(Button)``;

interface OptimizationOptionsContainerProps {
  subTitle?: string[];
}
const OptimizationOptionsContainer = styled.div<OptimizationOptionsContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const OptimizationTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: ${PRIMARY_TEXT_COLOR};

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const OptimizationIconContainer = styled.div`
  transform: translateY(2px);
`;

const OptimizationSubTitle = styled.div`
  padding: 0;
  color: ${SECONDARY_TEXT_COLOR};
  font-size: 12px;
`;
const OptimizationRequired = styled.span`
  color: ${SECONDARY_TEXT_COLOR};
`;

const RadioGroupCustom = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .bp4-control input:checked ~ .bp4-control-indicator {
    background-color: ${PRIMARY_BUTTON} !important;
  }
  .bp4-control input:checked ~ span {
    color: ${PRIMARY_TEXT_COLOR};
  }
  .custom-tooltip {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .bp4-radio {
    margin: 0;
  }
`;
const RadioCustomContainer = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  height: fit-content;
`;

export const DialogText = styled.span<{primary?: boolean}>`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => (props.primary ? `${PRIMARY_TEXT_COLOR}` : `${SECONDARY_TEXT_COLOR}`)};
  &.optimize-subtext {
    color: ${SECONDARY_TEXT_COLOR} !important;
  }
`;
const DateText = styled.span`
  font-size: 16px;
  font-weight: 500;
`;
