import React from "react";
import {DoubleFieldCellValue, StackedFieldCellRenderer} from "./DoubleFieldCellRenderer";
import {ManifestStop, NestedJobStop} from "../../../generated/graphql";
import {getStopPreposition, getStopTime} from "../../../utils/StopTime";
import {getJobStopDate, getJobStopTime, getLocalDate, getLocalTime} from "../../../utils/DateUtils";
import {ICellRendererParams} from "@ag-grid-community/core";

export interface GetStopTimeCellValueProps {
  data: NestedJobStop | ManifestStop;
  is24HourFormat: boolean;
  timezoneOfJobStop: boolean;
}

export const getStopTimeCellValue = ({timezoneOfJobStop, is24HourFormat, data}: GetStopTimeCellValueProps) => {
  const stopTime = getStopTime(data);
  if (!stopTime) {
    return undefined;
  }

  let localTime = timezoneOfJobStop
    ? getJobStopTime(stopTime.toISOString(), data.timeZone, is24HourFormat, {includeZone: true})
    : getLocalTime(stopTime, data.timeZone, is24HourFormat, {includeZone: true});

  const stopPrep = getStopPreposition(data);
  if (stopPrep) {
    localTime = `${stopPrep} ${localTime}`;
  }

  const localDate = timezoneOfJobStop
    ? getJobStopDate(stopTime.toISOString(), data.timeZone, "MMM dd")
    : getLocalDate(stopTime, data.timeZone, "MMM dd");

  const doubleFieldValue: DoubleFieldCellValue = {
    upperField: {
      value: localTime!,
      style: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16px",
        color: "#111827"
      }
    },
    lowerField: {
      value: localDate!,
      style: {
        fontSize: "14px",
        fontWeight: "lighter",
        color: "#6B7280"
      }
    }
  };

  return doubleFieldValue;
};

export const StopTimeCellRenderer = (params: ICellRendererParams<DoubleFieldCellValue | undefined>) => {
  if (!params.value) {
    return <></>;
  }
  return StackedFieldCellRenderer(params.value);
};
