import {Divider, Radio, RadioGroup, Text} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {FunctionComponent} from "react";
import {RouteViewMode} from "./ManifestDetailsMap";
import {MAP_OVERLAY_BREAK_POINTS} from "../../common/Constants";

type ManifestDetailsMapOverlayViewProps = {
  isOptimized: boolean;
  viewMode: RouteViewMode;
  setViewMode: (viewMode: RouteViewMode) => void;
  saveInfo: {time: string; distance: number} | null;
};

const OverlayContainer = styled.div<{isOptimized: boolean}>`
  position: absolute;
  top: 10px;
  right: 15px;
  ${(props) => (props.isOptimized ? "left: 15px;" : "")}
  z-index: 1000;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

const ManifestRouteContent = styled.p`
  color: var(--Secondary-text, #797979);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0;
  cursor: pointer;
`;

const Container = styled.div`
  padding: 13px 25px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto" sans-serif;
`;

const ManifestRouteContainer = styled.div``;

const ManifestOptimizeRouteContainer = styled.div`
  display: grid;
  grid-auto-columns: auto auto 1fr;
  grid-auto-flow: column;
`;
const OptimizationInfo = styled.div`
  margin-right: 20px;
`;

const OptimizationInfoHeader = styled.div`
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #797979;
  @media screen and (max-width: ${MAP_OVERLAY_BREAK_POINTS.CHANGE_TO_SMALL_TEXT}) {
    font-size: 13px;
  }
`;

const OptimizationInfoSaveTime = styled.div`
  color: #32a467;
  @media screen and (max-width: ${MAP_OVERLAY_BREAK_POINTS.CHANGE_TO_SMALL_TEXT}) {
    font-size: 12px;
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @media screen and (max-width: ${MAP_OVERLAY_BREAK_POINTS.CHANGE_TO_COLUMN}) {
    flex-direction: column;
    gap: 10px;
  }

  .bp4-radio {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    color: #797979;

    input:checked ~ .bp4-control-indicator {
      background-color: #14305a;
    }
    input:checked ~ .label-text {
      color: #14305a;
      @media screen and (max-width: ${MAP_OVERLAY_BREAK_POINTS.CHANGE_TO_SMALL_TEXT}) {
        font-size: 12px;
      }
    }
  }
`;

const StyledRadioLabel = styled(Text)`
  @media screen and (max-width: ${MAP_OVERLAY_BREAK_POINTS.CHANGE_TO_SMALL_TEXT}) {
    font-size: 12px;
  }
`;

const ManifestDetailsMapOverlay: FunctionComponent<ManifestDetailsMapOverlayViewProps> = ({
  isOptimized,
  viewMode,
  setViewMode,
  saveInfo
}) => {
  return (
    <OverlayContainer isOptimized={isOptimized}>
      <Container>
        {isOptimized ? (
          <ManifestOptimizeRouteContainer data-testid="optimize-overlay">
            <OptimizationInfo>
              <OptimizationInfoHeader>optimization results preview</OptimizationInfoHeader>
              {saveInfo && (
                <OptimizationInfoSaveTime>
                  Saved <b>{saveInfo.distance} mi</b> and <b>{saveInfo.time}</b>
                </OptimizationInfoSaveTime>
              )}
            </OptimizationInfo>
            <Divider />
            <StyledRadioGroup
              onChange={(e) => setViewMode(e.currentTarget.value as RouteViewMode)}
              selectedValue={viewMode}
            >
              <Radio
                labelElement={<StyledRadioLabel className="label-text">Show Optimized Route</StyledRadioLabel>}
                value={RouteViewMode.Optimized}
                data-testid="optimize-radio-optimized"
              />
              <Radio
                labelElement={<StyledRadioLabel className="label-text">Show Original Route</StyledRadioLabel>}
                value={RouteViewMode.Original}
                data-testid="optimize-radio-original"
              />
            </StyledRadioGroup>
          </ManifestOptimizeRouteContainer>
        ) : (
          <ManifestRouteContainer>
            <ManifestRouteContent data-testid="default-overlay">Manifest route</ManifestRouteContent>
          </ManifestRouteContainer>
        )}
      </Container>
    </OverlayContainer>
  );
};
export default ManifestDetailsMapOverlay;
