import decodePolyLine, {decodedItem} from "./DecodePolyLine";
import {DriverLocation} from "../../generated/graphql";
import {DriverCords} from "./driver/DriverMarker";
import {RoutedEntityVisibilities} from "./MapVisibilityContext";
import {sequenceComparator} from "../../services/JobStopService";
import {jobMapIconColors} from "./LocationMarker";
import {MappedStop} from "./AssignmentMapV2";
import {RouteInfo, RoutePath} from "./useMapRoutes";

export type Cords = {
  lat: number;
  lng: number;
};

export const getMapBounds = (maps: any, cords: Cords[]) => {
  const bounds = new maps.LatLngBounds();

  cords.forEach((cord: Cords) => {
    if (!isNaN(cord.lat) && !isNaN(cord.lng)) {
      bounds.extend(new maps.LatLng(cord.lat, cord.lng));
    }
  });
  return bounds;
};

const getDriverCords = (location: DriverLocation) => {
  const split = location.coordinates.split(",");
  return {
    ...location,
    lat: parseFloat(split[0]),
    lng: parseFloat(split[1])
  } as DriverCords;
};

export const getRoutePaths = (
  latLngStops: Record<string, MappedStop[]>,
  routedEntities: RoutedEntityVisibilities,
  driverLocations: Map<number, DriverLocation>,
  isSortWithSequence = true,
  isActiveCompletedStop = false
): RoutePath[] => {
  type StopWithLatLng = MappedStop & {lat: number; lng: number};
  const stopsWithLatLng: StopWithLatLng[] = [];
  for (const key of Object.keys(latLngStops)) {
    const [lat, lng] = key.split(",").map((s) => Number(s));
    const stops: StopWithLatLng[] = latLngStops[key].map((s) => {
      return {...s, lat, lng};
    });
    stops.forEach((s) => stopsWithLatLng.push(s));
  }
  const paths: RoutePath[] = [];
  for (const routedManifest of routedEntities.manifests) {
    const entityId = routedManifest.getMapKey();
    const points: Cords[] = [];
    const manifestLocations = stopsWithLatLng.filter((s) => s.stopInfoMetaData.key === entityId);
    const isCompletedStops = manifestLocations.some((stop) => stop.jobStopStatus === "C");
    // with only active stop => driver location is the first in points[]
    if (
      (routedManifest.hasLocationData && !isActiveCompletedStop) ||
      (routedManifest.hasLocationData && isActiveCompletedStop && !isCompletedStops)
    ) {
      const driverLocation = driverLocations.get(routedManifest.driverId);
      if (driverLocation) {
        const driverCoords = getDriverCords(driverLocation);
        points.push({lat: driverCoords.lat, lng: driverCoords.lng});
      }
    }

    if (
      (manifestLocations && !isActiveCompletedStop) ||
      (manifestLocations && isActiveCompletedStop && !isCompletedStops)
    ) {
      const sortedLocations = isSortWithSequence
        ? [...manifestLocations].sort((a, b) => sequenceComparator(a, b))
        : [...manifestLocations];
      sortedLocations.forEach(({lat, lng}) => points.push({lat, lng}));
    }

    if (isActiveCompletedStop && isCompletedStops) {
      // push completed location first
      manifestLocations.filter((stop) => stop.jobStopStatus === "C").forEach(({lat, lng}) => points.push({lat, lng}));
      // push driver location next
      if (routedManifest.hasLocationData) {
        const driverLocation = driverLocations.get(routedManifest.driverId);
        if (driverLocation) {
          const driverCoords = getDriverCords(driverLocation);
          points.push({lat: driverCoords.lat, lng: driverCoords.lng});
        }
      }
      // push active location final
      manifestLocations.filter((stop) => stop.jobStopStatus !== "C").forEach(({lat, lng}) => points.push({lat, lng}));
    }
    paths.push({key: entityId, points: points, color: routedManifest.routeColor});
  }

  if (routedEntities.jobs.length <= jobMapIconColors.length) {
    for (const routedJob of routedEntities.jobs) {
      const entityId = routedJob.getMapKey();
      const points: Cords[] = [];
      const jobLocations = stopsWithLatLng.filter((s) => s.stopInfoMetaData.key === entityId);
      if (jobLocations) {
        const sortedLocations = isSortWithSequence
          ? [...jobLocations].sort((a, b) => sequenceComparator(a, b))
          : [...jobLocations];
        sortedLocations.forEach(({lat, lng}) => points.push({lat, lng}));
      }
      paths.push({key: entityId, points: points, color: routedJob.routeColor});
    }
  }

  return paths;
};

export type MapPath = {
  route?: RouteInfo;
  polyline: google.maps.Polyline | google.maps.Polyline[];
};
const lineSymbolBorder = {
  path: "M 0,1 0,1",
  strokeOpacity: 0.8,
  strokeColor: "white",
  scale: 1,
  strokeWeight: 8
};

const lineSymbol = {
  path: "M 0,1 0,1",
  strokeOpacity: 1,
  scale: 1,
  strokeWeight: 5
};

const solidPolyline = (path: decodedItem[], map: any, color?: string) => {
  return new google.maps.Polyline({
    strokeColor: color ?? "#161616",
    strokeOpacity: 1,
    strokeWeight: 4,
    path: path,
    map: map
  });
};

const dashedPolyline = (path: decodedItem[], map: any, color?: string) => {
  return new google.maps.Polyline({
    strokeOpacity: 0,
    strokeWeight: 4,
    geodesic: true,
    icons: [
      {
        icon: lineSymbolBorder,
        offset: "0",
        repeat: "10px"
      },
      {
        icon: {...lineSymbol, strokeColor: color ?? "#161616"},
        offset: "0",
        repeat: "10px"
      }
    ],
    path: path,
    map: map
  });
};

export const createSinglePolyline = (route: RouteInfo, map: any, color?: string) => {
  if (/Manifest/.exec(route.key)) {
    return solidPolyline(decodePolyLine(route.paths[0].points?.encodedLine as string), map, color ?? route.color);
  } else {
    return dashedPolyline(decodePolyLine(route.paths[0].points?.encodedLine as string), map, color ?? route.color);
  }
};

export const createMultiPolyline = (
  route: RouteInfo,
  map: any,
  slicePointsBySnappedWaypoints: decodedItem[][],
  stopList: boolean[]
) => {
  const polylines: google.maps.Polyline[] = [];
  if (/Manifest/.exec(route.key)) {
    slicePointsBySnappedWaypoints.forEach((path, index) => {
      if (stopList[index] && stopList[index + 1]) {
        polylines.push(solidPolyline(path, map, "#161616"));
      } else {
        polylines.push(solidPolyline(path, map, route.color));
      }
    });
  } else {
    slicePointsBySnappedWaypoints.forEach((path, index) => {
      if (stopList[index] && stopList[index + 1]) {
        polylines.push(dashedPolyline(path, map, "#161616"));
      } else {
        polylines.push(dashedPolyline(path, map, route.color));
      }
    });
  }

  return polylines;
};
