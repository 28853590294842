import {Tag} from "@blueprintjs/core";
import {JobStatus, jobStatuses} from "../../services/JobStatusResolver";

type StatusBadgeProps = {
  status: JobStatus;
};

type StatusDisplay = {
  color: string | undefined;
  backgroundColor: string | undefined;
};

const statusMap = new Map<string, StatusDisplay>([
  [jobStatuses.completed.name, {color: "#F6F7F9", backgroundColor: "#32A467"}],
  [jobStatuses.new.name, {color: "#F6F7F9", backgroundColor: "#2d72d2"}]
]);

const ServiceBadge = ({status}: StatusBadgeProps) => {
  const resolvedStatus = statusMap.get(status.name);
  let style = {};
  if (resolvedStatus) {
    style = {...resolvedStatus};
  }
  return (
    <Tag minimal style={style}>
      {status.name}
    </Tag>
  );
};

export default ServiceBadge;
