import {Button, Divider, Tab, TabId, Tabs} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {duotone} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useCallback, useContext, useState} from "react";
import {AuthContext} from "../components/AuthProvider";
import UserManagement from "../components/settings/UserManagement/UserManagement";
import UserSettings from "../components/settings/UserSettings";
import ColorizedIndicators from "../components/settings/ColorizedIndicators/ColorizedIndicators";
import CompanySettings from "../components/settings/CompanySettings";
import ServiceTypeColor from "../components/settings/ServiceTypeColor/ServiceTypeColor";
import {Prompt} from "react-router-dom";
import {
  ServiceTypeColorContext,
  ServiceTypeColorDispatchContext
} from "../components/settings/ServiceTypeColor/ServiceTypeColorStateProvider";
import VehicleIconSetupView from "../components/settings/VehicleIconSetup/VehicleIconSetupView";

const Container = styled.div`
  width: 100%;
  height: 100%;
  font-family: "Roboto", serif;
`;

const MenuPanelTitle = styled.h2`
  color: #8da4be;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin: 5px 20px 15px;
`;

const SettingsTabs = styled(Tabs)`
  height: 100%;
  width: 100%;

  & .bp4-tab-panel {
    max-width: 100% !important;
    overflow: hidden;
    padding: 0 !important;
  }

  & .bp4-tab-list {
    padding: 20px;
    width: 200px;
    height: 100%;
    box-shadow: 0px 4px 4px 0px #00000040;
    gap: 10px;

    & .bp4-tab {
      font-size: 16px;
      font-weight: 400;
    }
  }
`;

const StyledDivider = styled(Divider)`
  width: 196px;
  margin: 0 0 0 -18px;
`;

const StyledSettingsText = styled.span`
  text-transform: uppercase;

  &::before {
    content: &nbsp;
  }
`;

const ColorSettingMenu = styled(Button)`
  width: 100%;
  justify-content: space-between;
  font-size: 16px;
  :hover {
    color: #215db0 !important;
    background: none !important;

    .bp4-icon {
      color: #215db0 !important;
    }
  }
`;

const ColorSettingCollapser = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const SubTabsItem = styled(Button)<{isSelected: boolean}>`
  width: 100%;
  padding: 0 0 0 15px;
  justify-content: left;
  font-size: 16px;
  color: ${(props) => (props.isSelected ? "#161616 !important" : "#797979 !important")};
  background: ${(props) => (props.isSelected ? "#0038ff1a !important" : "")};
  :hover {
    color: #215db0 !important;
    background: ${(props) => (props.isSelected ? "#0038ff1a !important" : "none !important")};
  }
`;

const SettingsView = () => {
  const {isAdmin} = useContext(AuthContext);
  const [panelTabId, setPanelTabId] = useState<TabId>("user-settings");
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const {isDataChanged} = useContext(ServiceTypeColorContext);
  const serviceTypeColorDispatch = useContext(ServiceTypeColorDispatchContext);

  const handleOnChangeTab = useCallback(
    (navbarTabId: TabId) => {
      if (isDataChanged) {
        serviceTypeColorDispatch({
          type: "SHOW_CONFIRM_WITHOUT_SAVING",
          payload: {value: true, redirectFunc: setPanelTabId, redirectParams: navbarTabId}
        });
        return;
      }
      setPanelTabId((prevState) => {
        return prevState === navbarTabId ? prevState : navbarTabId;
      });
    },
    [isDataChanged, serviceTypeColorDispatch]
  );

  const handleCollapseMenu = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  return (
    <Container>
      <Prompt
        when={isDataChanged}
        message={"You have unsaved adjustments that will be lost if you Leave this page without saving."}
      />
      <SettingsTabs
        key={panelTabId}
        animate
        vertical
        selectedTabId={panelTabId}
        onChange={handleOnChangeTab}
        renderActiveTabPanelOnly={true}
        className="setting-tabs"
      >
        <MenuPanelTitle>
          <FontAwesomeIcon icon={duotone("gear")} size={"1x"} color={"#8DA4BE"} />
          <StyledSettingsText>Settings</StyledSettingsText>
        </MenuPanelTitle>
        <StyledDivider />
        <Tab id="user-settings" title="User Settings" panel={<UserSettings />} />
        {isAdmin && <Tab id="user-management" title="User Management" panel={<UserManagement />} />}
        {isAdmin && <Tab id="vehicle-icon-setup" title="Vehicle Icons Setup" panel={<VehicleIconSetupView />} />}
        {isAdmin && (
          <>
            <ColorSettingMenu
              minimal
              onClick={handleCollapseMenu}
              rightIcon={isCollapsed ? "chevron-up" : "chevron-down"}
              name="Color Settings"
            >
              Color Settings
            </ColorSettingMenu>
            {isCollapsed && (
              <ColorSettingCollapser>
                <SubTabsItem
                  isSelected={panelTabId === "colorized-indicators"}
                  minimal
                  name="Colorized Indicators"
                  onClick={() => handleOnChangeTab("colorized-indicators")}
                >
                  Colorized Indicators
                </SubTabsItem>
                <SubTabsItem
                  isSelected={panelTabId === "service-type-color"}
                  minimal
                  name="Service Type Color"
                  onClick={() => handleOnChangeTab("service-type-color")}
                >
                  Service Type Color
                </SubTabsItem>
              </ColorSettingCollapser>
            )}
          </>
        )}
        {isAdmin && <Tab id="company-settings" title="Company Settings" panel={<CompanySettings />} />}
        <Tab id="colorized-indicators" panel={<ColorizedIndicators />} />
        <Tab id="service-type-color" panel={<ServiceTypeColor />} />
      </SettingsTabs>
    </Container>
  );
};

export default SettingsView;
