const ActiveIcon = () => {
  return (
    <svg
      data-testid="active-icon"
      width="16"
      height="16"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99828 13.3989C10.5322 13.3989 13.3969 10.5341 13.3969 7.00023C13.3969 3.46634 10.5322 0.601562 6.99828 0.601562C3.46439 0.601562 0.599609 3.46634 0.599609 7.00023C0.599609 10.5341 3.46439 13.3989 6.99828 13.3989ZM9.96334 5.96613C10.2757 5.65378 10.2757 5.14735 9.96334 4.83499C9.65099 4.52264 9.14456 4.52264 8.83221 4.83499L6.19844 7.46876L5.16434 6.43466C4.85199 6.12231 4.34556 6.12231 4.03321 6.43466C3.72085 6.74701 3.72085 7.25344 4.03321 7.5658L5.63287 9.16546C5.94523 9.47782 6.45166 9.47782 6.76401 9.16546L9.96334 5.96613Z"
        fill="#32A467"
      />
    </svg>
  );
};

const FutureIcon = () => {
  return (
    <svg
      data-testid="future-icon"
      width="16"
      height="16"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99534 13.3989C10.5292 13.3989 13.394 10.5341 13.394 7.00023C13.394 3.46634 10.5292 0.601562 6.99534 0.601562C3.46146 0.601562 0.59668 3.46634 0.59668 7.00023C0.59668 10.5341 3.46146 13.3989 6.99534 13.3989ZM9.96041 6.43466L7.56091 4.03516C7.24855 3.72281 6.74213 3.72281 6.42977 4.03516C6.11742 4.34752 6.11742 4.85394 6.42977 5.1663L7.46387 6.2004L4.59584 6.20039C4.15411 6.20039 3.79601 6.55849 3.79601 7.00023C3.79601 7.44196 4.15411 7.80006 4.59584 7.80006H7.46387L6.42977 8.83416C6.11742 9.14651 6.11742 9.65294 6.42977 9.9653C6.74213 10.2776 7.24855 10.2776 7.56091 9.9653L9.96041 7.5658C10.2728 7.25344 10.2728 6.74701 9.96041 6.43466Z"
        fill="#14305A"
      />
    </svg>
  );
};

const InactiveIcon = () => {
  return (
    <svg
      data-testid="inactive-icon"
      width="16"
      height="16"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99144 13.3989C10.5253 13.3989 13.3901 10.5341 13.3901 7.00023C13.3901 3.46634 10.5253 0.601562 6.99144 0.601562C3.45755 0.601562 0.592773 3.46634 0.592773 7.00023C0.592773 10.5341 3.45755 13.3989 6.99144 13.3989ZM4.59194 6.2004C4.1502 6.2004 3.79211 6.55849 3.79211 7.00023C3.79211 7.44196 4.1502 7.80006 4.59194 7.80006H9.39094C9.83267 7.80006 10.1908 7.44196 10.1908 7.00023C10.1908 6.55849 9.83267 6.2004 9.39094 6.2004H4.59194Z"
        fill="#6B7280"
      />
    </svg>
  );
};

export {ActiveIcon, FutureIcon, InactiveIcon};
