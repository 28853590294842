import {createContext, useState} from "react";
import LocationMap from "./LocationMap";
import {ManifestStop, NestedJobStop} from "../../generated/graphql";
import {StopInfoMetadata} from "../map/stop/StopInfo";
import {useMapVisibilityContext} from "../map/MapVisibilityContext";
import {Address, AddressResolutionStatus, LatLong, ResolvedAddress} from "../../domain/geocode-types";
import {getGeoCodeService} from "../../services/GeoCodeService";
import {useApolloClient} from "@apollo/client";

class AddressResolverAddress extends ResolvedAddress {
  private _info?: StopInfoMetadata;
  private _stop?: NestedJobStop | ManifestStop;

  constructor(
    address: Address,
    stop?: NestedJobStop | ManifestStop,
    info?: StopInfoMetadata,
    latLng: LatLong = {lat: 0, lng: 0}
  ) {
    super(address, latLng);
    this._stop = stop;
    this._info = info;
  }

  get stop(): NestedJobStop | ManifestStop | undefined {
    return this._stop;
  }

  set stop(value: NestedJobStop | ManifestStop | undefined) {
    this._stop = value;
  }

  get info(): StopInfoMetadata | undefined {
    return this._info;
  }

  set info(value: StopInfoMetadata | undefined) {
    this._info = value;
  }
}

type AddressResolverState = {
  addresses: AddressResolverAddress[];
  locations: Record<number, LatLong>;
  processAddresses(state: AddressResolverState, addresses: AddressResolverAddress[]): void;
};

const AddressResolverContext = createContext<AddressResolverState>({
  addresses: [],
  locations: LocationMap.getInstance(),
  processAddresses: () => {
    console.error("This is the default implementation, you should not be here");
  }
});

type AddressResolverProviderProps = {
  children: JSX.Element[] | JSX.Element;
};

const AddressResolverProvider = ({children}: AddressResolverProviderProps) => {
  const apolloClient = useApolloClient();
  const {dispatch: mapVisibilityDispatch} = useMapVisibilityContext();
  const processAddresses = async (state: AddressResolverState, addresses: AddressResolverAddress[]) => {
    if (addresses && addresses.length > 0) {
      console.debug(`Processing ${addresses.length} Addresses`);
      const resolvedAddresses = await getGeoCodeService({apolloClient: apolloClient}).geoCodeAddresses(
        addresses.map((ara) => ara.address)
      );

      addresses.forEach((address) => {
        const resolvedAddress = resolvedAddresses.get(address.hashAddress())!;
        if (AddressResolutionStatus.unresolvableAddress == resolvedAddress.resolutionStatus) {
          console.warn("Cannot resolve address", address);
          if (address.stop as ManifestStop) {
            mapVisibilityDispatch({type: "FlagBadAddress", stopInfo: address.info!});
          }
        }
      });

      setState({
        ...state,
        locations: LocationMap.getInstance()
      });
    }
  };

  const [state, setState] = useState<AddressResolverState>({
    addresses: [],
    locations: LocationMap.getInstance(),
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    processAddresses: processAddresses
  });

  return <AddressResolverContext.Provider value={state}>{children}</AddressResolverContext.Provider>;
};

export default AddressResolverProvider;
