import {Button, Dialog, Intent, Spinner} from "@blueprintjs/core";
import React, {forwardRef, useCallback, useContext, useImperativeHandle, useMemo, useState} from "react";
import {AuthContext} from "../../AuthProvider";
import styled from "@emotion/styled";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {UserProfile} from "./UserProfile";
import {User, defaultGroups} from "./UserManagement";
import {createUser} from "../../../services/TenantService";
import UserForm from "./UserForm";
import {uniqueId} from "lodash";
import {AppToaster} from "../../../utils/toaster";

interface InviteUserDialogProps {
  refreshData: () => void;
}

export interface InviteUserDialogRef {
  onOpen: () => void;
  onClose: () => void;
  onOpenUpdate: (userToEdit: User) => void;
}

export enum FormMode {
  new,
  edit
}

enum SuccessCodeStatus {
  OK = 200,
  CREATED = 201
}

type UserEditorState = {
  formId: string;
  formData: UserProfile;
  emailValid: boolean;
  firstNameValid: boolean;
  lastNameValid: boolean;
  legacyUserIdValid: boolean;
  formValid: boolean;
};

const produceSuccessMessage = (formMode: FormMode, userValues: UserProfile[]): string => {
  const emailsString = userValues.map((item) => item.email).join(", ");

  return formMode === FormMode.new
    ? `Invited User(s): ${emailsString} to Dispatch! An email has been sent in order to activate their account.`
    : `User: ${emailsString} updated!`;
};

const produceFailureMessage = (formMode: FormMode, userValues: UserProfile[]): string => {
  const emailsString = userValues.map((item) => item.email).join(", ");
  const legacyUserIdString = Array.from(new Set(userValues.map((item) => item.legacyUserId))).join(", ");

  return `Failed to ${
    formMode === FormMode.new ? "invite" : "update"
  } user(s): ${emailsString}. Please make sure the e-Courier Username: ${legacyUserIdString}, exists in CMS.`;
};

const validateForm = (formState: UserEditorState, fieldName: keyof UserProfile) => {
  const value = formState.formData[fieldName]!;
  switch (fieldName) {
    case "email":
      formState.emailValid = value.length > 0;
      break;
    case "firstName":
      formState.firstNameValid = value.length > 0;
      break;
    case "lastName":
      formState.lastNameValid = value.length > 0;
      break;
    case "legacyUserId":
      formState.legacyUserIdValid = value.length > 0;
      break;
    default:
      break;
  }
  formState.formValid =
    formState.emailValid && formState.firstNameValid && formState.lastNameValid && formState.legacyUserIdValid;
};

const MAXIMUM_INVITED_USERS = 10;

const InviteUserDialog = forwardRef<InviteUserDialogRef, InviteUserDialogProps>(({refreshData}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const authContext = useContext(AuthContext);

  const newUserState = useCallback((): UserProfile => {
    return new UserProfile(
      "",
      "",
      "",
      "",
      "",
      "",
      authContext.tenantId as string,
      defaultGroups(authContext),
      createUser
    );
  }, [authContext]);

  const createUserForm = useCallback((): UserEditorState => {
    return {
      formId: uniqueId(),
      formData: newUserState(),
      emailValid: false,
      firstNameValid: false,
      lastNameValid: false,
      legacyUserIdValid: false,
      formValid: false
    };
  }, [newUserState]);

  const [beforeUpdateUserValues, setBeforeUpdateUserValues] = useState<UserProfile>();
  const [userForms, setUserForms] = useState<UserEditorState[]>([createUserForm()]);
  const [usersLoading, setUsersLoading] = useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.new);
  const [showResendButton, setShowResendButton] = useState(false);

  const handleUserFormChange = (formId: string, fieldName: keyof UserProfile, value: any) => {
    const editingFormIndex = userForms.findIndex((item) => item.formId === formId);
    let editingFormState = {...userForms[editingFormIndex]};
    editingFormState = {...editingFormState, formData: {...editingFormState.formData, [fieldName]: value}};
    validateForm(editingFormState, fieldName);
    const newUserEditorFormState = [...userForms];
    newUserEditorFormState[editingFormIndex] = editingFormState;
    setUserForms(newUserEditorFormState);
  };

  const resetForm = useCallback(() => {
    setFormMode(FormMode.new);
    setUserForms([createUserForm()]);
  }, [createUserForm]);

  const onOpen = useCallback(() => {
    setIsOpen(true);
    resetForm();
  }, [resetForm]);

  const onClose = useCallback(() => {
    setShowResendButton(false);
    setIsOpen(false);
    resetForm();
  }, [resetForm]);

  const onOpenUpdate = useCallback((userToEdit: User) => {
    setIsOpen(true);
    const userProfile = userToEdit.profile;
    setBeforeUpdateUserValues(userProfile);
    setFormMode(FormMode.edit);
    setUserForms([
      {
        formId: uniqueId(),
        formData: userProfile,
        emailValid: true,
        firstNameValid: true,
        lastNameValid: true,
        legacyUserIdValid: true,
        formValid: true
      }
    ]);
  }, []);

  const handleAddNewForm = useCallback(() => {
    setShowResendButton(false);
    setUserForms((prev) => [...prev, createUserForm()]);
  }, [createUserForm]);

  const handleRemoveForm = useCallback((formId: string) => {
    setUserForms((prev) => prev.filter((item) => item.formId != formId));
  }, []);

  useImperativeHandle(ref, () => ({
    onClose,
    onOpen,
    onOpenUpdate
  }));

  const handleUserEditorSubmit = async () => {
    setUsersLoading(true);
    const invitedFormsStatus: {formId: string; status: "success" | "failed"}[] = [];
    await Promise.all(
      userForms.map((item) =>
        item.formData
          .persistHandler(authContext.token as string, item.formData)
          .then((r) => {
            invitedFormsStatus.push({
              formId: item.formId,
              status: r.status === SuccessCodeStatus.OK || r.status === SuccessCodeStatus.CREATED ? "success" : "failed"
            });
          })
          .catch((e: any) => {
            invitedFormsStatus.push({
              formId: item.formId,
              status: "failed"
            });
            console.error(e);
          })
      )
    );
    if (invitedFormsStatus.every((item) => item.status === "success")) {
      AppToaster.show({
        intent: Intent.SUCCESS,
        icon: "tick",
        message: produceSuccessMessage(
          formMode,
          userForms.map((item) => item.formData)
        )
      });
      refreshData();
      onClose();
    } else {
      const successFormIds = invitedFormsStatus.filter((item) => item.status === "success").map((item) => item.formId);
      const failedFormDatas = userForms
        .filter((item) => !successFormIds.includes(item.formId))
        .map((item) => item.formData);
      if (successFormIds.length > 0) {
        const successFormDatas = userForms
          .filter((item) => successFormIds.includes(item.formId))
          .map((item) => item.formData);
        AppToaster.show({
          intent: Intent.SUCCESS,
          icon: "tick",
          message: produceSuccessMessage(formMode, successFormDatas)
        });
      }
      AppToaster.show({
        intent: Intent.WARNING,
        icon: "warning-sign",
        message: produceFailureMessage(formMode, failedFormDatas)
      });
      if (formMode === FormMode.new) {
        setShowResendButton(true);
      }
      setUserForms((prev) => prev.filter((item) => !successFormIds.includes(item.formId)));
    }
    setUsersLoading(false);
  };

  const isEnableButton = useMemo(() => {
    if (formMode === FormMode.edit) {
      return userForms[0].formValid && JSON.stringify(beforeUpdateUserValues) !== JSON.stringify(userForms[0].formData);
    } else {
      return userForms.every((item) => item.formValid);
    }
  }, [beforeUpdateUserValues, formMode, userForms]);

  return (
    <StyledDialog
      isOpen={isOpen}
      title={
        formMode === FormMode.new
          ? "Invite User to Dispatch"
          : `Edit ${beforeUpdateUserValues?.firstName} ${beforeUpdateUserValues?.lastName}`
      }
      onClose={onClose}
      icon={<FontAwesomeIcon style={{paddingRight: "10px"}} icon={solid("user-plus")} />}
      canEscapeKeyClose
      isCloseButtonShown
    >
      <DialogBody data-testid="create-user-dialog">
        <CustomerName>Customer: {`${authContext.tenant?.shortName}`}</CustomerName>
        <FormList>
          {userForms.map((form) => (
            <UserForm
              key={form.formId}
              formMode={formMode}
              formId={form.formId}
              formData={form.formData}
              onFormChange={handleUserFormChange}
              onRemoveForm={userForms.length > 1 ? handleRemoveForm : undefined}
            />
          ))}
        </FormList>
        {formMode === FormMode.new && (
          <AddNewButton
            data-testid="add-new-user-form"
            disabled={userForms.length >= MAXIMUM_INVITED_USERS}
            minimal
            icon="plus"
            onClick={handleAddNewForm}
          >
            Add new user
          </AddNewButton>
        )}
      </DialogBody>
      <DialogFooter>
        {usersLoading && <Spinner size={25} intent={Intent.PRIMARY} />}
        <Button
          style={{marginLeft: "10px"}}
          data-testid={"create-user-submit-btn"}
          disabled={!isEnableButton || usersLoading}
          text={showResendButton ? "Resend Invitation" : formMode === FormMode.new ? "Send Invitation" : "Update User"}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleUserEditorSubmit}
          intent={Intent.PRIMARY}
        />
      </DialogFooter>
    </StyledDialog>
  );
});

InviteUserDialog.displayName = "InviteUserDialog";

export default InviteUserDialog;

const StyledDialog = styled(Dialog)`
  border-radius: 12px;
  overflow: hidden;
  height: 595px;
  background: #fff;
  display: flex;
  font-family: Roboto;
`;

const CustomerName = styled.div`
  font-weight: 500;
  font-size: 16px;
`;

const DialogBody = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
  overflow: hidden;
`;

const FormList = styled.div`
  overflow-y: auto;
`;

const DialogFooter = styled.div`
  display: flex;
  justify-content: right;
  padding: 0 50px 24px;
`;

const AddNewButton = styled(Button)`
  width: fit-content;
`;
