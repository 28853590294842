import _ from "lodash";
import {SettingItemEditorMode} from "../components/types/SettingItemEditorMode";
import {ExpandedState} from "./ColorizedIndicatorsList";
import {IndicatorLogicSettings} from "./types/indicatorLogicSettings";
import {ComparisonTypes, IndicatorLogicType, UserColor} from "./types/indicatorLogicType";
import {TriggerEventType, TriggerSelectorValues} from "./types/triggerSelectorValues";
import {JobTypeSelectorValues} from "./types/jobTypeSelectorValues";
import generateRule from "./RuleGenerator.service";
import {OptionType} from "../../common/types/optionType";
import {ColorizedIndicatorState} from "./ColorizedIndicatorStateProvider";
import {ManifestStatusTypes} from "./types/manifestAttributes";
import {getColorRankMap} from "./ColorRank.service";

type ColorizedIndicatorReducerAction =
  | {type: "SET_SELECTED_LOGIC"; payload: IndicatorLogicType}
  | {type: "SET_BEFORE_EDIT_LOGIC"; payload: IndicatorLogicType}
  | {type: "SET_IS_EDITING_LOGIC_CHANGED"; payload: boolean}
  | {type: "SET_FORM_MODE"; payload: SettingItemEditorMode}
  | {type: "SET_LOGIC_DATA"; payload: IndicatorLogicSettings}
  | {type: "SET_EXPANDED_STATE"; payload: ExpandedState}
  | {type: "SET_IS_FORM_VALID"; payload: boolean}
  | {type: "SET_JOB_TYPE"; payload: JobTypeSelectorValues}
  | {type: "SET_COLOR"; payload: UserColor}
  | {type: "SET_JOB_ATTRIBUTES_SERVICE"; payload: OptionType[]}
  | {type: "SET_JOB_ATTRIBUTES_CUSTOMER"; payload: OptionType[]}
  | {type: "SET_JOB_ATTRIBUTES_VEHICLETYPE"; payload: OptionType[]}
  | {type: "SET_JOB_ATTRIBUTES_STOPSTATUS"; payload: string[] | undefined}
  | {type: "SET_JOB_ATTRIBUTES_SITE"; payload: OptionType[]}
  | {type: "SET_PRIORITY"; payload: OptionType[]}
  | {type: "SET_MANIFEST_ATTRIBUTES_STATUS"; payload: ManifestStatusTypes | undefined}
  | {type: "SET_COMPARISON_TYPE"; payload: ComparisonTypes}
  | {type: "SET_TRIGGER_SELECTOR"; payload: TriggerSelectorValues}
  | {type: "SET_TRIGGER_SELECTOR_LOWERVALUE"; payload: number}
  | {type: "SET_TRIGGER_SELECTOR_LOWERTYPE"; payload: TriggerEventType}
  | {type: "SET_TRIGGER_SELECTOR_UPPERVALUE"; payload: number}
  | {type: "SET_TRIGGER_SELECTOR_UPPERTYPE"; payload: TriggerEventType}
  | {type: "SET_TRIGGER_SELECTOR_STEP_SIZE"; payload: number}
  | {type: "SET_TRIGGER_SELECTOR_SLIDER_POSITION"; payload: number[]}
  | {
      type: "SET_TRIGGER_SELECTOR_SLIDER_POSITION_VALUES";
      payload: {position: number[]; lowerValue: number; upperValue: number};
    }
  | {type: "SET_ENGINE_RULES"};

const colorizedIndicatorReducer = (state: ColorizedIndicatorState, action: ColorizedIndicatorReducerAction) => {
  switch (action.type) {
    case "SET_SELECTED_LOGIC":
      if (!_.isEqual(state.selectedLogic, action.payload)) {
        return {
          ...state,
          selectedLogic: {
            ...action.payload
          }
        } as ColorizedIndicatorState;
      } else {
        return state;
      }
    case "SET_IS_FORM_VALID": {
      if (state.isFormValid !== action.payload) {
        return {
          ...state,
          isFormValid: action.payload
        };
      } else {
        return state;
      }
    }
    case "SET_JOB_TYPE": {
      if (!_.isEqual(state.selectedLogic?.type, action.payload)) {
        if (action.payload.jobTypeValue !== state.selectedLogic?.type?.jobTypeValue) {
          return {
            ...state,
            selectedLogic: {
              ...state.selectedLogic,
              type: {
                ...action.payload
              },
              comparison: undefined
            }
          } as ColorizedIndicatorState;
        }
        return {
          ...state,
          selectedLogic: {
            ...state.selectedLogic,
            type: {
              ...action.payload
            }
          }
        } as ColorizedIndicatorState;
      } else {
        return state;
      }
    }
    case "SET_COLOR": {
      if (!_.isEqual(state.selectedLogic?.color, action.payload)) {
        // if the updated color does not exist in the list, set rank to highest rank
        let updatedRank = undefined;
        if (state.logicData) {
          const colorRankMap = getColorRankMap(state.logicData?.rules);
          const colorExists = colorRankMap.has(action.payload);

          if (!colorExists) {
            updatedRank = colorRankMap.size + 1;
          }
        }

        return {
          ...state,
          selectedLogic: {
            ...state.selectedLogic,
            color: action.payload,
            ...(updatedRank !== undefined && {rank: updatedRank})
          }
        } as ColorizedIndicatorState;
      } else {
        return state;
      }
    }
    case "SET_JOB_ATTRIBUTES_SERVICE": {
      if (!_.isEqual(state.selectedLogic?.jobAttributes.service, action.payload)) {
        return {
          ...state,
          selectedLogic: {
            ...state.selectedLogic,
            jobAttributes: {
              ...state.selectedLogic?.jobAttributes,
              service: action.payload
            }
          }
        } as ColorizedIndicatorState;
      } else {
        return state;
      }
    }
    case "SET_JOB_ATTRIBUTES_CUSTOMER": {
      if (!_.isEqual(state.selectedLogic?.jobAttributes.customer, action.payload)) {
        return {
          ...state,
          selectedLogic: {
            ...state.selectedLogic,
            jobAttributes: {
              ...state.selectedLogic?.jobAttributes,
              customer: action.payload
            }
          }
        } as ColorizedIndicatorState;
      } else {
        return state;
      }
    }
    case "SET_JOB_ATTRIBUTES_VEHICLETYPE": {
      if (!_.isEqual(state.selectedLogic?.jobAttributes.vehicleType, action.payload)) {
        return {
          ...state,
          selectedLogic: {
            ...state.selectedLogic,
            jobAttributes: {
              ...state.selectedLogic?.jobAttributes,
              vehicleType: action.payload
            }
          }
        } as ColorizedIndicatorState;
      } else {
        return state;
      }
    }
    case "SET_JOB_ATTRIBUTES_STOPSTATUS": {
      if (!_.isEqual(state.selectedLogic?.jobAttributes.stopStatus, action.payload)) {
        return {
          ...state,
          selectedLogic: {
            ...state.selectedLogic,
            jobAttributes: {
              ...state.selectedLogic?.jobAttributes,
              stopStatus: action.payload
            }
          }
        } as ColorizedIndicatorState;
      } else {
        return state;
      }
    }

    case "SET_JOB_ATTRIBUTES_SITE": {
      if (!_.isEqual(state.selectedLogic?.jobAttributes.site, action.payload)) {
        return {
          ...state,
          selectedLogic: {
            ...state.selectedLogic,
            jobAttributes: {
              ...state.selectedLogic?.jobAttributes,
              site: action.payload
            }
          }
        } as ColorizedIndicatorState;
      } else {
        return state;
      }
    }

    case "SET_PRIORITY": {
      if (!_.isEqual(state.selectedLogic?.orderPriority, action.payload)) {
        return {
          ...state,
          selectedLogic: {
            ...state.selectedLogic,
            orderPriority: action.payload
          }
        } as ColorizedIndicatorState;
      } else {
        return state;
      }
    }
    case "SET_MANIFEST_ATTRIBUTES_STATUS": {
      if (!_.isEqual(state.selectedLogic?.manifestAttributes?.manifestStatus, action.payload)) {
        return {
          ...state,
          selectedLogic: {
            ...state.selectedLogic,
            manifestAttributes: {
              ...state.selectedLogic?.manifestAttributes,
              manifestStatus: action.payload
            }
          }
        } as ColorizedIndicatorState;
      } else {
        return state;
      }
    }
    case "SET_COMPARISON_TYPE": {
      if (state.selectedLogic?.comparison !== action.payload) {
        return {
          ...state,
          selectedLogic: {
            ...state.selectedLogic,
            comparison: action.payload
          }
        } as ColorizedIndicatorState;
      } else {
        return state;
      }
    }
    case "SET_TRIGGER_SELECTOR_STEP_SIZE": {
      if (state.triggerSelectorStepSize !== action.payload) {
        return {
          ...state,
          triggerSelectorStepSize: action.payload
        };
      } else {
        return state;
      }
    }
    case "SET_TRIGGER_SELECTOR_SLIDER_POSITION_VALUES": {
      if (!_.isEqual(state.triggerSelectorSliderPosition, action.payload.position)) {
        return {
          ...state,
          triggerSelectorSliderPosition: action.payload.position,
          selectedLogic: {
            ...state.selectedLogic,
            trigger: {
              ...state.selectedLogic?.trigger,
              lowerValue: action.payload.lowerValue,
              upperValue: action.payload.upperValue
            }
          }
        } as ColorizedIndicatorState;
      } else {
        return state;
      }
    }
    case "SET_TRIGGER_SELECTOR_SLIDER_POSITION": {
      if (!_.isEqual(state.triggerSelectorSliderPosition, action.payload)) {
        return {
          ...state,
          triggerSelectorSliderPosition: action.payload
        };
      } else {
        return state;
      }
    }
    case "SET_TRIGGER_SELECTOR":
      if (!_.isEqual(state.selectedLogic?.trigger, action.payload)) {
        return {
          ...state,
          selectedLogic: {
            ...state.selectedLogic,
            trigger: {
              ...action.payload
            }
          }
        } as ColorizedIndicatorState;
      } else {
        return state;
      }
    case "SET_TRIGGER_SELECTOR_LOWERVALUE":
      if (!_.isEqual(state.selectedLogic?.trigger?.lowerValue, action.payload)) {
        return {
          ...state,
          selectedLogic: {
            ...state.selectedLogic,
            trigger: {
              ...state.selectedLogic?.trigger,
              lowerValue: action.payload
            }
          }
        } as ColorizedIndicatorState;
      } else {
        return state;
      }
    case "SET_TRIGGER_SELECTOR_LOWERTYPE":
      if (!_.isEqual(state.selectedLogic?.trigger?.lowerType, action.payload)) {
        return {
          ...state,
          selectedLogic: {
            ...state.selectedLogic,
            trigger: {
              ...state.selectedLogic?.trigger,
              lowerType: action.payload
            }
          }
        } as ColorizedIndicatorState;
      } else {
        return state;
      }
    case "SET_TRIGGER_SELECTOR_UPPERVALUE":
      if (!_.isEqual(state.selectedLogic?.trigger?.upperValue, action.payload)) {
        return {
          ...state,
          selectedLogic: {
            ...state.selectedLogic,
            trigger: {
              ...state.selectedLogic?.trigger,
              upperValue: action.payload
            }
          }
        } as ColorizedIndicatorState;
      } else {
        return state;
      }
    case "SET_TRIGGER_SELECTOR_UPPERTYPE":
      if (!_.isEqual(state.selectedLogic?.trigger?.upperType, action.payload)) {
        return {
          ...state,
          selectedLogic: {
            ...state.selectedLogic,
            trigger: {
              ...state.selectedLogic?.trigger,
              upperType: action.payload
            }
          }
        } as ColorizedIndicatorState;
      } else {
        return state;
      }
    case "SET_BEFORE_EDIT_LOGIC":
      return {...state, beforeEditLogic: action.payload};
    case "SET_IS_EDITING_LOGIC_CHANGED":
      if (state.isEditingLogicChanged !== action.payload) {
        return {
          ...state,
          isEditingLogicChanged: action.payload
        };
      } else {
        return state;
      }
    case "SET_FORM_MODE":
      return {...state, formMode: action.payload};
    case "SET_LOGIC_DATA":
      if (!_.isEqual(state.logicData, action.payload)) {
        return {...state, logicData: action.payload};
      } else {
        return state;
      }
    case "SET_EXPANDED_STATE":
      return {...state, expandedState: action.payload};
    case "SET_ENGINE_RULES": {
      const rules = generateRule(state.selectedLogic as IndicatorLogicType);
      console.debug("SET_ENGINE_RULES - Generate Rule", JSON.stringify(rules));
      return {
        ...state,
        selectedLogic: {
          ...state.selectedLogic,
          rule: rules
        }
      } as ColorizedIndicatorState;
    }
    default:
      return state;
  }
};

export default colorizedIndicatorReducer;
export type {ColorizedIndicatorReducerAction};
