import {Tooltip2} from "@blueprintjs/popover2";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

const DragCellRenderer = ({
  isCompleted,
  isAllowResequence,
  content
}: {
  isCompleted: boolean;
  isAllowResequence: boolean;
  content: string;
}) => {
  return (
    !isCompleted && (
      <Tooltip2
        popoverClassName="white-popover"
        disabled={isAllowResequence}
        content={content}
        placement="right"
        interactionKind="hover"
        enforceFocus={false}
        hoverCloseDelay={100}
      >
        <FontAwesomeIcon
          data-testid="drag-row-icon"
          size="lg"
          icon={solid("grip-dots-vertical")}
          color={isAllowResequence ? "#9CA3AF" : "#D1D5DB"}
          style={{cursor: "pointer"}}
        />
      </Tooltip2>
    )
  );
};

export default DragCellRenderer;
