import {
  ModelOrderPriorityFilterInput,
  SearchableOrderPrioritySortableFields,
  SearchableSortDirection,
  useSearchOrderPrioritiesQuery
} from "../../../generated/graphql";
import FilterableMultiSelect from "../../common/FilterableMultiSelect";
import {useContext, useEffect, useMemo, useState} from "react";
import {getArrayFromField, getOptionsFromArrayOfObjects} from "../../../utils/General";

import {OptionType} from "../../common/types/optionType";
import {ColorizedIndicatorContext, ColorizedIndicatorDispatchContext} from "./ColorizedIndicatorStateProvider";

const PrioritySelector = () => {
  const colorizedIndicatorState = useContext(ColorizedIndicatorContext);
  const colorizedIndicatorDispatch = useContext(ColorizedIndicatorDispatchContext);

  const [orderPriorityQuery, setOrderPriorityQuery] = useState<string>("");
  const [orderPriorityFilter, setOrderPriorityFilter] = useState<ModelOrderPriorityFilterInput | undefined>();

  const orderPriorities = useSearchOrderPrioritiesQuery({
    variables: {
      limit: 200,
      filter: orderPriorityFilter,
      sort: {
        field: SearchableOrderPrioritySortableFields.Description,
        direction: SearchableSortDirection.Asc
      }
    }
  });

  const selectedOrderPriorities = colorizedIndicatorState.selectedLogic?.orderPriority as OptionType[];

  useEffect(() => {
    let isComponentMounted = true;
    if (isComponentMounted) {
      if (orderPriorityQuery?.trim()) {
        setOrderPriorityFilter({
          description: {
            wildcard: orderPriorityQuery
          }
        });
      } else {
        setOrderPriorityFilter(undefined);
      }
    }
    return () => {
      isComponentMounted = false;
    };
  }, [orderPriorityQuery]);

  const orderPrioritiesOptions = useMemo(() => {
    const options = getOptionsFromArrayOfObjects(
      getArrayFromField(orderPriorities?.data?.searchOrderPriorities?.items),
      "orderPriorityId",
      "description"
    );
    return options;
  }, [orderPriorities]);

  const onPrioritiesSelect = (item: OptionType) => {
    const existingItemIndex = selectedOrderPriorities?.findIndex((p) => p.key === item.key);
    let newSelectedPriorities = [...selectedOrderPriorities];
    if (existingItemIndex === -1) {
      newSelectedPriorities.push(item);
    } else {
      newSelectedPriorities = selectedOrderPriorities?.filter((p) => p.key !== item.key);
    }

    colorizedIndicatorDispatch({type: "SET_PRIORITY", payload: newSelectedPriorities});
    colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
  };

  const onClearPriorities = () => {
    colorizedIndicatorDispatch({type: "SET_PRIORITY", payload: []});
    colorizedIndicatorDispatch({type: "SET_ENGINE_RULES"});
  };

  return (
    <FilterableMultiSelect
      options={orderPrioritiesOptions}
      testIdPrefix={"priority"}
      onOptionSelect={onPrioritiesSelect}
      selectedOptions={selectedOrderPriorities ?? []}
      onClear={onClearPriorities}
      onQueryChange={setOrderPriorityQuery}
      placeholder="Search properties"
      text={`Priority(s)${selectedOrderPriorities?.length > 0 ? ` (${selectedOrderPriorities.length})` : ""}`}
      onLoadMore={() => {
        orderPriorities.refetch({
          limit: orderPrioritiesOptions.length + 20
        });
      }}
    />
  );
};

export default PrioritySelector;
