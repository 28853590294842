/* eslint-disable @typescript-eslint/no-misused-promises */
import React, {useEffect, useMemo} from "react";
import clsx from "clsx";
import {DefaultStreamChatGenerics} from "stream-chat-react/dist/types/types";
import {
  MessageContextValue,
  MessageErrorIcon,
  MessageInput,
  MessageText,
  MessageUIComponentProps,
  Modal,
  areMessageUIPropsEqual,
  useComponentContext,
  useMessageContext,
  useChatContext,
  Avatar as DefaultAvatar,
  EditMessageForm as DefaultEditMessageForm,
  MessageDeleted as DefaultMessageDeleted,
  MessageOptions as DefaultMessageOptions,
  MessageStatus as DefaultMessageStatus,
  MessageTimestamp as DefaultMessageTimestamp
} from "stream-chat-react";
import styled from "@emotion/styled";

const AvatarContainer = styled.div<{count: number; isMine: boolean}>`
  position: ${(props) => (props.count > 1 && props.isMine ? "" : "absolute")};
  right: -18px;
  bottom: 0;
  margin-right: ${(props) => (props.count > 1 && props.isMine ? "-18px" : "")};
  display: flex;
  gap: 1px;
`;

const CUSTOM_MESSAGE_TYPE = {
  date: "message.date",
  intro: "channel.intro"
} as const;

export type MessageSimpleWithContextProps<
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
> = MessageContextValue<StreamChatGenerics>;

export const MessageSimpleWithContext = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  props: MessageSimpleWithContextProps<StreamChatGenerics> & {driverTitle: string}
) => {
  const {
    additionalMessageInputProps,
    clearEditingState,
    editing,
    endOfGroup,
    firstOfGroup,
    groupedByUser,
    handleRetry,
    highlighted,
    isMyMessage,
    message,
    readBy,
    onUserClick,
    onUserHover,
    renderText,
    driverTitle
  } = props;

  const {
    Avatar = DefaultAvatar,
    EditMessageInput = DefaultEditMessageForm,
    MessageDeleted = DefaultMessageDeleted,
    MessageOptions = DefaultMessageOptions,
    MessageStatus = DefaultMessageStatus,
    MessageTimestamp = DefaultMessageTimestamp
  } = useComponentContext<StreamChatGenerics>("MessageSimple");
  const {themeVersion, client} = useChatContext("MessageSimple");

  const readByOthers = useMemo(
    () => readBy?.filter((item) => item.id !== message.user?.id && item.id !== client.user?.id),
    [client.user?.id, message.user?.id, readBy]
  );

  useEffect(() => {
    const driverReadAvatars = document.getElementsByClassName("read_avatars");
    const lastReadMessageAvatars = Array.from(driverReadAvatars).at(-1)?.childNodes;
    const lastReadMessageAvatarIds = Array.from(lastReadMessageAvatars ?? []).map((node) => (node as HTMLElement).id);

    Array.from(driverReadAvatars).forEach((item, index) => {
      if (index === driverReadAvatars?.length - 1) return;
      item.childNodes.forEach((node) => {
        if (lastReadMessageAvatarIds.includes((node as HTMLElement)?.id)) {
          node?.replaceWith("");
        }
      });
    });
  }, [readByOthers]);

  if (message.customType === CUSTOM_MESSAGE_TYPE.date) {
    return null;
  }

  if (message.deleted_at || message.type === "deleted") {
    return <MessageDeleted message={message} />;
  }

  const showMetadata = !groupedByUser || endOfGroup;
  const allowRetry = message.status === "failed" && message.errorStatusCode !== 403;

  const rootClassName = clsx(
    "str-chat__message str-chat__message-simple",
    `str-chat__message--${message.type}`,
    `str-chat__message--${message.status}`,
    isMyMessage() ? "str-chat__message--me str-chat__message-simple--me" : "str-chat__message--other",
    message.text ? "str-chat__message--has-text" : "has-no-text",
    {
      "pinned-message": message.pinned,
      "str-chat__message--highlighted": highlighted,
      "str-chat__message-send-can-be-retried": message?.status === "failed" && message?.errorStatusCode !== 403,
      "str-chat__virtual-message__wrapper--end": endOfGroup,
      "str-chat__virtual-message__wrapper--first": firstOfGroup,
      "str-chat__virtual-message__wrapper--group": groupedByUser
    }
  );
  return (
    <>
      {editing && (
        <Modal onClose={clearEditingState} open={editing}>
          <MessageInput
            clearEditingState={clearEditingState}
            grow
            hideSendButton
            Input={EditMessageInput}
            message={message}
            {...additionalMessageInputProps}
          />
        </Modal>
      )}
      {
        <div className={rootClassName} key={message.id} data-testid="message">
          {message.user && (
            <Avatar
              image={message.user.image}
              name={message.user.name ?? message.user.id}
              onClick={onUserClick}
              onMouseOver={onUserHover}
              user={message.user}
            />
          )}
          <div
            className={clsx("str-chat__message-inner", {
              "str-chat__simple-message--error-failed": allowRetry
            })}
            data-testid="message-inner"
            onClick={allowRetry ? () => handleRetry(message) : undefined}
            onKeyUp={allowRetry ? () => handleRetry(message) : undefined}
          >
            <MessageOptions />
            <div className="str-chat__message-bubble">
              <MessageText message={message} renderText={renderText} />
              {themeVersion === "2" && <MessageErrorIcon />}
            </div>
          </div>
          {showMetadata && themeVersion === "2" && (
            <div className="str-chat__message-data str-chat__message-simple-data str-chat__message-metadata">
              <MessageStatus />
              {!isMyMessage() && !!message.user && (
                <span className="str-chat__message-simple-name">
                  {message.user.role === "driver" ? driverTitle : message.user.name ?? message.user.id}
                </span>
              )}
              <MessageTimestamp calendar customClass="str-chat__message-simple-timestamp" />
            </div>
          )}
          {readByOthers && readByOthers.length > 0 && (
            <AvatarContainer className="read_avatars" count={readByOthers.length} isMine={isMyMessage()}>
              {readByOthers.map((item) => (
                <div key={item.id} id={item.id}>
                  <Avatar name={item.name} size={15} />
                </div>
              ))}
            </AvatarContainer>
          )}
        </div>
      }
    </>
  );
};

const MemoizedMessageSimple = React.memo(
  MessageSimpleWithContext,
  areMessageUIPropsEqual
) as typeof MessageSimpleWithContext;

/**
 * The default UI component that renders a message and receives functionality and logic from the MessageContext.
 */
export const CustomMessage = <StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics>(
  props: MessageUIComponentProps<StreamChatGenerics> & {driverTitle: string}
) => {
  const messageContext = useMessageContext<StreamChatGenerics>("MessageSimple");
  const {driverTitle} = props;

  return <MemoizedMessageSimple {...messageContext} {...props} driverTitle={driverTitle} />;
};
