import {AllConditions, AnyConditions, ConditionProperties, Event} from "json-rules-engine";
import {ComparisonTypes, IndicatorLogicType} from "./types/indicatorLogicType";
import {JsonRule} from "./types/jsonRule";
import {JobTypes, StopTypes} from "./types/jobTypeSelectorValues";
import {TriggerEventType, TriggerSelectorValues} from "./types/triggerSelectorValues";
import {CustomOperators, EventOperatorProps} from "../../common/DispatchRulesDataProvider";

type GetComparatorConditionProps = {
  selectedLogic: IndicatorLogicType;
  comparisonType?: ComparisonTypes;
};

export enum ConditionNames {
  JOBATTRIBUTES = "JobAttributes",
  JOBATTRIBUTES_CUSTOMER = "JobAttributes_Customer",
  JOBATTRIBUTES_SERVICE = "JobAttributes_Service",
  JOBATTRIBUTES_VEHICLE = "JobAttributes_Vehicle",
  ORDERPRIORITY = "OrderPriorities",
  MANIFESTONLY = "ManifestOnly",
  MANIFESTATTRIBUTES = "ManifestAttributes",
  MANIFESTATTRIBUTES_STATUS = "ManifestAttributes_Status",
  JOBTYPEVALUE = "JobTypeValue",
  COMPARATOR = "Comparator"
}

const configureUnassignedServices = (logic: IndicatorLogicType, conditions: AllConditions) => {
  //Add Services - Unassigned
  if (logic.jobAttributes.service.length > 0 && logic.type?.jobTypeValue === JobTypes.UnassignedJobs) {
    const jobAttributes = conditions.all.filter((x) => x.name === ConditionNames.JOBATTRIBUTES)[0] as AllConditions;
    jobAttributes.all.push({
      name: ConditionNames.JOBATTRIBUTES_SERVICE,
      fact: "job",
      operator: "in",
      path: "$.service",
      value: logic.jobAttributes.service.map((x) => x.value)
    } as ConditionProperties);
  }
};

const configureAssignedServices = (logic: IndicatorLogicType, conditions: AllConditions) => {
  //Add Services - Assigned
  if (logic.jobAttributes.service.length > 0 && logic.type?.jobTypeValue === JobTypes.AssignedStops) {
    const jobAttributes = conditions.all.filter((x) => x.name === ConditionNames.JOBATTRIBUTES)[0] as AllConditions;
    jobAttributes.all.push({
      name: ConditionNames.JOBATTRIBUTES_SERVICE,
      fact: "stop",
      operator: "in",
      path: "$.order.service",
      value: logic.jobAttributes.service.map((x) => x.value)
    } as ConditionProperties);
  }
};

const configureCustomers = (logic: IndicatorLogicType, conditions: AllConditions) => {
  //Add Customers
  if (logic.jobAttributes.customer.length > 0) {
    const jobAttributes = conditions.all.filter((x) => x.name === ConditionNames.JOBATTRIBUTES)[0] as AllConditions;
    jobAttributes.all.push({
      name: ConditionNames.JOBATTRIBUTES_CUSTOMER,
      fact: logic.type?.stopTypeValue ? "stop" : "job",
      operator: "in",
      path: "$.order.customer.customerId",
      value: logic.jobAttributes.customer.map((x) => x.key)
    } as ConditionProperties);
  }
};

const configureVehicles = (logic: IndicatorLogicType, conditions: AllConditions) => {
  //Add Vehicles
  if (logic.jobAttributes.vehicleType.length > 0) {
    const jobAttributes = conditions.all.filter((x) => x.name === ConditionNames.JOBATTRIBUTES)[0] as AllConditions;
    jobAttributes.all.push({
      name: ConditionNames.JOBATTRIBUTES_VEHICLE,
      fact: logic.type?.stopTypeValue ? "manifest" : "job",
      operator: "in",
      path: "$.vehicleType.vehicleTypeId",
      value: logic.jobAttributes.vehicleType.map((x) => x.key)
    } as ConditionProperties);
  }
};

const configureSites = (logic: IndicatorLogicType, conditions: AllConditions) => {
  //Add Sites
  if (logic.jobAttributes.site?.length > 0) {
    const jobAttributes = conditions.all.filter((x) => x.name === "JobAttributes")[0] as AllConditions;
    jobAttributes.all.push({
      name: "JobAttributes_Site",
      fact: logic.type?.stopTypeValue ? "stop" : "job",
      operator: "in",
      path: logic.type?.stopTypeValue ? "$.job.site.siteId" : "$.site.siteId",
      value: logic.jobAttributes.site.map((x) => x.key)
    } as ConditionProperties);
  }
};

const configureStopStatus = (logic: IndicatorLogicType, conditions: AllConditions) => {
  //Add Stop Status
  if (logic.jobAttributes.stopStatus) {
    const jobAttributes = conditions.all.filter((x) => x.name === "JobAttributes")[0] as AllConditions;
    jobAttributes.all.push({
      name: "JobAttributes_StopStatus",
      fact: "stop",
      operator: "in",
      path: "$.jobStopStatus",
      value: logic.jobAttributes.stopStatus?.length > 0 ? logic.jobAttributes.stopStatus : ["A", "Q"]
    });
  }
};

const configureOrderPriority = (logic: IndicatorLogicType, conditions: AllConditions) => {
  //Add Order Priority
  if (logic.orderPriority.length > 0) {
    conditions.all.push({
      name: ConditionNames.ORDERPRIORITY,
      any: [
        {
          fact: logic.type?.stopTypeValue ? "stop" : "job",
          name: "OrderPriority",
          operator: "in",
          path: "$.order.priority.orderPriorityId",
          value: logic.orderPriority.map((x) => x.key)
        } as ConditionProperties
      ]
    } as AnyConditions);
  }
};

const configureManifestStatus = (logic: IndicatorLogicType, conditions: AllConditions) => {
  //Add Order Priority
  if (logic.type?.stopTypeValue && logic.manifestAttributes?.manifestStatus) {
    const manifestAttributes = conditions.all.filter(
      (x) => x.name === ConditionNames.MANIFESTATTRIBUTES
    )[0] as AllConditions;
    manifestAttributes.all.push({
      fact: "manifest",
      name: ConditionNames.MANIFESTATTRIBUTES_STATUS,
      operator: "in",
      path: "$.manifestStatus",
      value: [logic.manifestAttributes.manifestStatus]
    } as ConditionProperties);
  }
};

const configureComparatorAndTrigger = (logic: IndicatorLogicType, conditions: AllConditions) => {
  // Add Comparator and Trigger
  if (logic.comparison && logic.trigger && logic.comparison !== ComparisonTypes.Continuous) {
    conditions.all.push({
      name: ConditionNames.COMPARATOR,
      all: [
        {
          fact: logic.type?.stopTypeValue ? "stop" : "job",
          operator: getCustomOperator(logic.trigger),
          path: getComparatorPath({selectedLogic: logic, comparisonType: logic.comparison}),
          value: {
            upperBound: logic.trigger?.upperValue,
            lowerBound: logic.trigger?.lowerValue
          } as EventOperatorProps
        } as ConditionProperties
      ]
    } as AllConditions);
  }
};

const configureJobType = (logic: IndicatorLogicType, conditions: AllConditions) => {
  // Add JobType rule if assigned
  if (logic.type?.stopTypeValue) {
    conditions.all.push(
      ...[
        {
          fact: "stop",
          name: ConditionNames.JOBTYPEVALUE,
          operator: "in",
          path: "$.stopType",
          value: getJobTypeArray(logic.type?.stopTypeValue)
        } as ConditionProperties
      ]
    );
  }
};

const getCustomOperator = (triggerValues: TriggerSelectorValues) => {
  if (
    triggerValues.lowerType === TriggerEventType.BeforeEvent &&
    triggerValues.upperType === TriggerEventType.BeforeEvent
  ) {
    return CustomOperators.BeforeEvent;
  } else if (
    triggerValues.lowerType === TriggerEventType.AfterEvent &&
    triggerValues.upperType === TriggerEventType.AfterEvent
  ) {
    return CustomOperators.AfterEvent;
  } else {
    return CustomOperators.BeforeAfterEvent;
  }
};

const getComparatorPath = ({selectedLogic, comparisonType}: GetComparatorConditionProps) => {
  const selectedComparison = comparisonType ?? selectedLogic.comparison;

  //determine path
  let path;
  switch (selectedComparison) {
    case ComparisonTypes.FirstStop:
      path = "$.stops[0].scheduledDateTime";
      break;
    case ComparisonTypes.LastStop:
      path = "$.stops[(@.length-1)].scheduledDateTime";
      break;
    case ComparisonTypes.ScheduledTime:
      path = "$.scheduledDateTime";
      break;
    case ComparisonTypes.LateTime:
      path = "$.lateDateTime";
      break;
    default:
  }
  return path;
};

const andSearchWithWildcards = <T>(field: string, tokens: string[]): T => {
  return {
    and: tokens.map((token) => {
      return JSON.parse(`{"${field}":{"wildcard":"*${token}*"}}`);
    })
  } as T;
};

export const getJobTypeArray = (stopType: string) => {
  switch (stopType) {
    case StopTypes.Pickup:
      return ["P"];
    case StopTypes.Delivery:
      return ["D"];
    case StopTypes.Both:
      return ["B"];
    case StopTypes.DeliveryOrBoth:
      return ["D", "B"];
    case StopTypes.PickupOrBoth:
      return ["P", "B"];
    default:
      return [];
  }
};

const generateRule = (logic: IndicatorLogicType): JsonRule => {
  const conditions = {
    all: []
  } as AllConditions;

  // Add Color
  const event = {
    type: "IndicatorLogic",
    params: {
      color: logic.color
    }
  } as Event;

  //Add Job Attributes
  conditions.all.push({
    name: ConditionNames.JOBATTRIBUTES,
    all: []
  } as AllConditions);

  //Add Manifest Attributes
  if (logic?.manifestAttributes?.manifestStatus) {
    conditions.all.push({
      name: ConditionNames.MANIFESTATTRIBUTES,
      all: []
    } as AllConditions);
  }

  if (!logic.comparison && logic.manifestAttributes?.manifestStatus) {
    conditions.name = ConditionNames.MANIFESTONLY;
    configureManifestStatus(logic, conditions);
  } else {
    configureUnassignedServices(logic, conditions);
    configureAssignedServices(logic, conditions);
    configureCustomers(logic, conditions);
    configureVehicles(logic, conditions);
    configureSites(logic, conditions);
    configureOrderPriority(logic, conditions);
    configureManifestStatus(logic, conditions);
    configureComparatorAndTrigger(logic, conditions);
    configureJobType(logic, conditions);
    configureStopStatus(logic, conditions);
  }

  logic.rule = {
    conditions: conditions,
    event: event
  } as JsonRule;

  return {
    conditions: conditions,
    event: event
  } as JsonRule;
};

export default generateRule;
export {andSearchWithWildcards};
