import {ManifestFilterType} from "../ManifestFilterState";
import {ManifestAttributeFilter} from "../../../generated/graphql";
import {AbstractManifestViewFilter} from "./AbstractManifestViewFilter";

export class MaxAgeManifestFilter extends AbstractManifestViewFilter<number> {
  constructor() {
    super(ManifestFilterType.MANIFEST_AGE);
  }
  toFilter(): ManifestAttributeFilter | undefined {
    if (this.value.size > 0) {
      const filterItems: ManifestAttributeFilter[] = Array.from(this.value).map((value) => {
        return {
          createdDateTime: {
            gte: `now-${value}s`
          }
        } as ManifestAttributeFilter;
      });
      return this.handleMultiValuedFilter(filterItems);
    }
    return undefined;
  }
}
