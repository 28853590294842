import ColorizedIndicatorStateProvider from "../components/settings/ColorizedIndicators/ColorizedIndicatorStateProvider";
import ServiceTypeColorStateProvider from "../components/settings/ServiceTypeColor/ServiceTypeColorStateProvider";
import SettingsView from "./SettingsView";

const SettingsViewContainer = () => {
  return (
    <ColorizedIndicatorStateProvider>
      <ServiceTypeColorStateProvider>
        <SettingsView />
      </ServiceTypeColorStateProvider>
    </ColorizedIndicatorStateProvider>
  );
};

export default SettingsViewContainer;
