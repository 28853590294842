import {createContext, useCallback, useEffect, useState} from "react";
import {
  DataChangeEntityType,
  useEnableDataChangedEventsMutation,
  useOnDataChangedSubscription
} from "../../../generated/graphql";
import {debounce} from "lodash";

type ManifestDetailsChangeState = {
  entityId?: string;
  entityType?: string;
  updated?: number;
};

const ManifestDetailsChangeContext = createContext<ManifestDetailsChangeState | undefined>({
  entityId: undefined,
  entityType: undefined,
  updated: undefined
});

type ManifestDetailsChangeEventProviderProps = {
  children: JSX.Element[] | JSX.Element;
  manifestDriverId: number;
};

const ManifestDetailsChangeEventProvider = ({manifestDriverId, children}: ManifestDetailsChangeEventProviderProps) => {
  const [state, setState] = useState<ManifestDetailsChangeState>();

  const [enableDataChangedEvents] = useEnableDataChangedEventsMutation();

  const {data, loading, error} = useOnDataChangedSubscription({
    variables: {
      entityType: DataChangeEntityType.Manifest,
      entityId: manifestDriverId.toString()
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = useCallback(
    debounce(
      (data, loading, error) => {
        if (error) {
          console.error("Error in data change subscription.", error, error.graphQLErrors);
        }

        if (!loading && data) {
          setState({
            ...data.onDataChanged,
            updated: Date.now()
          } as ManifestDetailsChangeState);
          console.debug("Data change propagated.", data.onDataChanged);
        }
      },
      2000,
      {maxWait: 5000}
    ),
    [manifestDriverId]
  );

  useEffect(() => {
    enableDataChangedEvents({
      variables: {
        entityType: DataChangeEntityType.Manifest,
        entityId: manifestDriverId.toString(),
        ttl: 600
      }
    }).then((r) => {
      if (r.errors) {
        console.error("Encountered error(s) enabling data change events for %s: %s", manifestDriverId, r.errors);
      } else {
        console.debug("Enabled data change events for %s", manifestDriverId);
      }
    });
  }, [enableDataChangedEvents, manifestDriverId]);

  useEffect(() => {
    debouncedUpdate(data, loading, error);
    console.debug("Data change debounced.");
  }, [data, loading, error, debouncedUpdate]);

  return <ManifestDetailsChangeContext.Provider value={state}>{children}</ManifestDetailsChangeContext.Provider>;
};

export type {ManifestDetailsChangeState};
export default ManifestDetailsChangeEventProvider;
export {ManifestDetailsChangeContext};
