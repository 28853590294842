import {Radio, RadioGroup} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {FormEvent, useContext} from "react";
import {ComparisonTypes} from "./types/indicatorLogicType";
import {JobTypes} from "./types/jobTypeSelectorValues";
import {ColorizedIndicatorContext, ColorizedIndicatorDispatchContext} from "./ColorizedIndicatorStateProvider";

const StyledRadioGroup = styled(RadioGroup)`
  .bp4-control input:checked ~ .bp4-control-indicator {
    background-color: #14305a;
  }
`;

const ComparisonTypeSelector = () => {
  const indicatorColorState = useContext(ColorizedIndicatorContext);
  const indicatorDispatch = useContext(ColorizedIndicatorDispatchContext);

  const selectedValue = indicatorColorState.selectedLogic;

  const handleOnComparisonTypeChange = (event: FormEvent<HTMLInputElement>): void => {
    indicatorDispatch({type: "SET_COMPARISON_TYPE", payload: event.currentTarget.value as ComparisonTypes});
    indicatorDispatch({type: "SET_ENGINE_RULES"});
  };

  return (
    <StyledRadioGroup onChange={handleOnComparisonTypeChange} selectedValue={selectedValue?.comparison}>
      <Radio
        label={
          selectedValue?.type?.jobTypeValue === JobTypes.AssignedStops
            ? "Scheduled Time"
            : "Scheduled Time of first stop"
        }
        value={
          selectedValue?.type?.jobTypeValue === JobTypes.AssignedStops
            ? ComparisonTypes.ScheduledTime
            : ComparisonTypes.FirstStop
        }
      ></Radio>
      <Radio
        label={
          selectedValue?.type?.jobTypeValue === JobTypes.AssignedStops ? "Late Time" : "Scheduled Time of last stop"
        }
        value={
          selectedValue?.type?.jobTypeValue === JobTypes.AssignedStops
            ? ComparisonTypes.LateTime
            : ComparisonTypes.LastStop
        }
      ></Radio>
      <Radio label="Continuous" value={ComparisonTypes.Continuous}></Radio>
    </StyledRadioGroup>
  );
};

export default ComparisonTypeSelector;
