import styled from "@emotion/styled";
import {Checkbox, Text} from "@blueprintjs/core";
import {ServiceTypeColorType} from "./ServiceTypeColor";
import {ServiceBadge} from "../../job/ServiceBadge";
import {useCallback, useContext} from "react";
import {ServiceTypeColorDispatchContext} from "./ServiceTypeColorStateProvider";
import {capitalize} from "lodash";

type ServiceTypeColorItemProps = {
  item: ServiceTypeColorType;
  index: number;
  isSelected: boolean;
};

const ServiceTypeColorItem = ({item, index, isSelected}: ServiceTypeColorItemProps) => {
  const serviceTypeColorDispatch = useContext(ServiceTypeColorDispatchContext);

  const onChangeItemHandle = useCallback(
    (item: ServiceTypeColorType) => {
      serviceTypeColorDispatch({type: "SET_SELECTING_SERVICES", payload: item});
    },
    [serviceTypeColorDispatch]
  );

  return (
    <Container data-testid="service-color-item" checked={isSelected} onChange={() => onChangeItemHandle(item)}>
      <Background className="background" style={{background: index % 2 ? "#fcfcfc" : ""}} />
      <Text>{index + 1}.</Text>
      <Text ellipsize>{item.description}</Text>
      <Text ellipsize>{item.color ? "Color: " + capitalize(item.color) : ""}</Text>
      <TagContainer>
        <ServiceBadge ellipsize service={item.name} />
      </TagContainer>
    </Container>
  );
};

export default ServiceTypeColorItem;

const Container = styled(Checkbox)`
  position: relative;
  height: 52px;
  width: 100%;
  display: grid;
  padding: 10px 12px 10px 12px;
  grid-template-columns: 28px 28px 282px 180px 110px;
  align-items: center;
  margin-bottom: 0px;
  border: 1px solid #eeeeee;
  margin-top: -1px;

  input:checked ~ .background {
    background: linear-gradient(0deg, rgba(0, 56, 255, 0.2), rgba(0, 56, 255, 0.2)),
      linear-gradient(0deg, #eeeeee, #eeeeee) !important;
  }

  input:checked ~ .bp4-control-indicator {
    background-color: #14305a !important;
  }

  .bp4-control-indicator {
    transform: translateX(12px);
  }

  > .bp4-text-overflow-ellipsis {
    padding-left: 10px;
  }
`;

const TagContainer = styled.div`
  width: fit-content;
  justify-self: right;
`;

const Background = styled.div`
  position: absolute;
  inset: 0;
  z-index: -1;
`;
