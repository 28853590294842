import styled from "@emotion/styled";
import "../App.css";
import React, {FormEvent, useCallback, useContext} from "react";
import {AuthContext} from "../components/AuthProvider";
import {InformationIcon} from "../components/settings/CompanySettings";
import {Radio, RadioGroup} from "@blueprintjs/core";
import {useAppContext} from "../ApplicationContext";
import {
  TenantPreferences,
  ClockTimeFormat,
  ClockTimeFormatLabels,
  UserPreferences,
  ClockTimeToolTipContent
} from "../components/common/Constants";
import {useSetUserPrefMutation} from "../generated/graphql";
import {Tooltip2} from "@blueprintjs/popover2";

const WhoAmIView = () => {
  const {user, groups, tenants, isAdmin, isSuperUser, isEcourierUser} = useContext(AuthContext);
  const {appState, dispatch} = useAppContext();
  const [setUserPref] = useSetUserPrefMutation();

  const updateAppStatePrefs = useCallback(
    (key: UserPreferences | TenantPreferences, value: any) => {
      setUserPref({
        variables: {
          name: key,
          input: {
            value: JSON.stringify(value)
          }
        }
      }).catch((error: Error) => {
        console.error(error);
      });
    },
    [setUserPref]
  );

  const handleTimeFormatChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value === ClockTimeFormat.TWENTY_FOUR_HOUR;
      dispatch({type: "SetIs24HrTimeFormat", payload: value});
      updateAppStatePrefs(UserPreferences.isTimeFormat24hr, value);
    },
    [dispatch, updateAppStatePrefs]
  );

  const WhoAmIViewContainer = styled.div({
    display: "flex",
    padding: "20px"
  });

  const Column = styled.div({
    display: "flex",
    flexDirection: "column",
    flexBasis: "50%",
    flex: "1"
  });

  const ClockSettingHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  `;

  const ClockSettingRadioGroup = styled(RadioGroup)`
    .bp4-control input:checked ~ .bp4-control-indicator {
      background: #14305a;
    }
  `;

  return (
    <WhoAmIViewContainer data-testid={"who-am-i-view"}>
      <Column>
        <h3>User Info</h3>
        <br />
        <div>
          <b>Name:</b> {user?.name}
        </div>
        <div>
          <b>Email:</b> {user?.email}
        </div>
        <div>
          <b>Subject:</b> {user?.sub}
        </div>
        <div>
          <b>Tenant ID:</b> {user?.tenantId}
        </div>
        <div>
          <b>Legacy User ID:</b> {user?.legacyUserId}
        </div>
        <div>
          <b>Groups:</b> {groups}
        </div>
        <div>
          <b>Allowed Tenants:</b> {isAdmin ? "All," : ""} {tenants}
        </div>
        <div>
          <b>Is Admin:</b> {isAdmin?.toString()}
        </div>
        <div>
          <b>Is Super Admin:</b> {isSuperUser?.toString()}
        </div>
        <div>
          <b>Is e-Courier User:</b> {isEcourierUser?.toString()}
        </div>
        <br />
        <ClockSettingHeader>
          <h3>Clock Settings</h3>
          <Tooltip2
            popoverClassName="content-tooltip"
            content={ClockTimeToolTipContent}
            placement="right"
            intent="none"
          >
            <InformationIcon />
          </Tooltip2>
        </ClockSettingHeader>
        <ClockSettingRadioGroup
          onChange={handleTimeFormatChange}
          selectedValue={appState.isTimeFormat24hr ? ClockTimeFormat.TWENTY_FOUR_HOUR : ClockTimeFormat.TWELVE_HOUR}
          inline
        >
          <Radio label={ClockTimeFormatLabels[ClockTimeFormat.TWELVE_HOUR]} value={ClockTimeFormat.TWELVE_HOUR} />
          <Radio
            label={ClockTimeFormatLabels[ClockTimeFormat.TWENTY_FOUR_HOUR]}
            value={ClockTimeFormat.TWENTY_FOUR_HOUR}
          />
        </ClockSettingRadioGroup>
      </Column>
      <Column>
        <h3>User JSON: </h3>
        <div style={{overflow: "scroll", height: "600px"}}>
          <pre>{JSON.stringify(user, null, 2)}</pre>
        </div>
      </Column>
    </WhoAmIViewContainer>
  );
};
export default WhoAmIView;
