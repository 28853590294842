import {Text} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {useRef, useContext} from "react";
import {DefaultGenerics} from "stream-chat";
import {
  Avatar,
  ChannelPreviewUIComponentProps,
  MessageTimestamp,
  SearchResultItemProps,
  getLatestMessagePreview,
  useTranslationContext
} from "stream-chat-react";
import {isSameDate} from "../../utils/General";
import {AuthContext} from "../AuthProvider";
import clsx from "clsx";
import {PreferenceContext, extractJsonPref} from "../../providers/PreferenceProvider";
import {Constants, UserPreferences} from "../common/Constants";
import {DEFAULT_CARD_CONFIGURATIONS_VALUE} from "../manifest/card-configuration/ManifestCardConfigurationDialog";
import {IConfigurationFormValues} from "../manifest/card-configuration/ManifestCardConfiguration";
import {TSetSeletedChannel, getDisplayTitle} from "./DispatchMessaging";

export type ChannelPreviewProps = ChannelPreviewUIComponentProps<DefaultGenerics> & {
  setSelectedChannel: TSetSeletedChannel;
};

export type SearchChannelPreviewProps = SearchResultItemProps<any> & {
  setSelectedChannel: TSetSeletedChannel;
};

const Tiltle = styled.div`
  padding: 12px;
  height: 58px;
  border-right: solid 1px #e5e5ea;
  border-bottom: solid 1px #ededed;

  .title {
    text-transform: uppercase;
    font-weight: 500;
    color: #161616;
    font-size: 16px;
    letter-spacing: 2px;
  }
  .number-driver {
    color: #797979;
    font-size: 12px;
  }
`;

const ChannelTitle = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: #161616;
`;

const MessageContent = styled.div`
  display: grid;
  grid-template-columns: min-content auto minmax(0, 50px);
  gap: 2px;
`;

const MessageContentItem = styled(Text)`
  display: flex;
  font-size: 12px;
  color: #797979;
`;

const MessageTime = styled(MessageContentItem)`
  justify-content: flex-end;
`;

const ReadIcon = () => (
  <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7931 1.00035L4.63338 8.87886L1.142 5.53954"
      stroke="#32A467"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.7397 1.00006L9.57958 8.87898L6.98346 6.42009"
      stroke="#32A467"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ChatTitle = () => {
  return (
    <Tiltle>
      <Text className="title" data-testid="chat-title">
        chat
      </Text>
    </Tiltle>
  );
};

const options: Intl.DateTimeFormatOptions = {day: "numeric", month: "short"};

export const CustomChannelPreview = (previewProps: ChannelPreviewProps) => {
  const {
    active,
    channel,
    latestMessage,
    lastMessage,
    onSelect: customOnSelectChannel,
    setActiveChannel,
    unread,
    watchers,
    setSelectedChannel
  } = previewProps;
  const authContext = useContext(AuthContext);
  const {userPreferences} = useContext(PreferenceContext);

  const cardConfigurationState = extractJsonPref(
    userPreferences,
    UserPreferences.manifestCardConfigurations,
    DEFAULT_CARD_CONFIGURATIONS_VALUE
  ).value as IConfigurationFormValues;

  const channelTitle = getDisplayTitle(channel, cardConfigurationState);

  const channelPreviewButton = useRef<HTMLButtonElement | null>(null);

  const today = new Date();

  const renderTimeStamp = () => {
    if (lastMessage?.created_at) {
      const messageDateTime = new Date(lastMessage?.created_at);
      if (isSameDate(today, messageDateTime)) {
        return (
          <MessageTime data-testid="message-time">
            <MessageTimestamp message={lastMessage} />
          </MessageTime>
        );
      } else {
        return <MessageTime>{new Intl.DateTimeFormat("en-US", options).format(messageDateTime)}</MessageTime>;
      }
    }
  };

  const onSelectChannel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (customOnSelectChannel) {
      customOnSelectChannel(e);
    } else if (setActiveChannel) {
      setActiveChannel(channel, watchers);
      setSelectedChannel(channel);
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur();
    }
  };

  return (
    <button
      aria-label={`Select Channel: ${channelTitle ?? ""}`}
      aria-selected={active}
      className={`str-chat__channel-preview-messenger str-chat__channel-preview ${
        active && "str-chat__channel-preview-messenger--active"
      } ${unread && unread >= 1 && "str-chat__channel-preview-messenger--unread"}`}
      data-testid="channel-preview-button"
      onClick={onSelectChannel}
      ref={channelPreviewButton}
    >
      <div className="str-chat__channel-preview-messenger--right str-chat__channel-preview-end">
        <div className="str-chat__channel-preview-end-first-row">
          <ChannelTitle ellipsize>{channelTitle}</ChannelTitle>
          {!!unread && (
            <div className="str-chat__channel-preview-unread-badge" data-testid="unread-badge">
              {unread <= Constants.MAX_UNREAD_MESSAGE_COUNT ? unread : Constants.MAX_UNREAD_MESSAGE_COUNT + "+"}
            </div>
          )}
        </div>
        <MessageContent>
          <MessageContentItem>{lastMessage && !unread && <ReadIcon />}</MessageContentItem>
          <MessageContentItem
            className="str-chat__channel-preview-messenger--last-message"
            style={{color: !unread ? "#797979" : "#161616"}}
          >
            {lastMessage?.user?.id === authContext.user?.sub && <span>You:&nbsp;</span>}
            <Text ellipsize>{latestMessage}</Text>
          </MessageContentItem>
          {renderTimeStamp()}
        </MessageContent>
      </div>
    </button>
  );
};

export const CustomSearchResultItem = (props: SearchChannelPreviewProps) => {
  const {focusedUser, index, result, selectResult, setSelectedChannel} = props;
  const {userPreferences} = useContext(PreferenceContext);

  const cardConfigurationState = extractJsonPref(
    userPreferences,
    UserPreferences.manifestCardConfigurations,
    DEFAULT_CARD_CONFIGURATIONS_VALUE
  ).value as IConfigurationFormValues;

  const channelTitle = getDisplayTitle(result, cardConfigurationState);

  const {t, userLanguage} = useTranslationContext("ChannelPreview");
  const focused = focusedUser === index;

  const unread = result?.countUnread();
  const latestMessage = getLatestMessagePreview(result, t, userLanguage);

  const className = clsx(
    "str-chat__channel-preview-messenger",
    "str-chat__channel-preview",
    "str-chat__channel-search-result",
    focused && "str-chat__channel-search-result--focused focused"
  );

  const displayTitle = (result.data as any).name;

  return (
    <div
      className={className}
      data-testid="search-channel-preview-item"
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick={() => {
        selectResult(result);
        setSelectedChannel(result);
      }}
    >
      <div className="str-chat__channel-preview-messenger--left">
        <Avatar name={displayTitle} size={40} />
      </div>
      <div className="str-chat__channel-preview-messenger--right str-chat__channel-preview-end">
        <div className="str-chat__channel-preview-end-first-row">
          <ChannelTitle ellipsize>{channelTitle}</ChannelTitle>
          {!!unread && (
            <div className="str-chat__channel-preview-unread-badge" data-testid="unread-badge">
              {unread <= Constants.MAX_UNREAD_MESSAGE_COUNT ? unread : Constants.MAX_UNREAD_MESSAGE_COUNT + "+"}
            </div>
          )}
        </div>
        <MessageContentItem
          className="str-chat__channel-preview-messenger--last-message"
          ellipsize
          style={{color: !unread ? "#797979" : "#161616"}}
        >
          {latestMessage}
        </MessageContentItem>
      </div>
    </div>
  );
};
