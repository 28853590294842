import {Checkbox, Divider} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {Fragment, useContext} from "react";
import {createAvatarComponent, GoogleSource, GravatarSource, IconSource, ValueSource} from "react-avatar";
import {useAppContext} from "../../ApplicationContext";
import {useSetUserPrefMutation} from "../../generated/graphql";
import {AuthContext} from "../AuthProvider";
import {UserPreferences} from "../common/Constants";
import {Tooltip2} from "@blueprintjs/popover2";

const Container = styled.div`
  font-family: Roboto;

  padding: 25px;
  height: 100%;
  width: calc(100vw - 200px);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LeftHeader = styled.div`
  margin-right: auto;
  margin-left: 10px;
  white-space: nowrap;
`;

const RightHeader = styled.div`
  margin-right: 10px;
`;

const Section = styled.div`
  padding-bottom: 10px;
`;

const SectionHeader = styled.h2`
  margin-top: 10px;
`;

const UserSettings = () => {
  const {user, isSuperUser} = useContext(AuthContext);
  const {appState, dispatch} = useAppContext();

  const [setUserPref] = useSetUserPrefMutation();

  const CustomAvatar = createAvatarComponent({
    sources: [GravatarSource, ValueSource, IconSource, GoogleSource]
  });

  const handlePresentationModeChange = (event: any) => {
    dispatch({type: "SetIsPresentationMode", payload: event.target.checked});
    updateAppStatePrefs(UserPreferences.isPresentationMode, event.target.checked);
  };

  const updateAppStatePrefs = (key: string, value: any) => {
    setUserPref({
      variables: {
        name: key,
        input: {
          value: JSON.stringify(value)
        }
      }
    }).catch((error: Error) => {
      console.error(error);
    });
  };

  return (
    <Container>
      <Header>
        <LeftHeader>
          <h2>
            <CustomAvatar
              name={user?.name}
              email={user?.email}
              round={true}
              size="50"
              textSizeRatio={2}
              style={{marginRight: "10px"}}
            />
            {user?.name}
          </h2>
        </LeftHeader>
        <RightHeader></RightHeader>
      </Header>
      <Divider />
      <Section>
        <SectionHeader>
          <FontAwesomeIcon icon={solid("user")} style={{marginRight: "10px"}} /> Personal Information
        </SectionHeader>
        <div>First Name: {user?.given_name}</div>
        <div>Last Name: {user?.family_name}</div>
        <div>Email: {user?.email}</div>
      </Section>
      {isSuperUser && (
        <Fragment>
          <Divider />
          <Section>
            <SectionHeader>
              <FontAwesomeIcon icon={solid("user-astronaut")} style={{marginRight: "10px"}} /> Admin Settings
            </SectionHeader>
            <Tooltip2 content={"Disables any new Feature Flags"}>
              <Checkbox
                checked={appState.isPresentationMode}
                label="Presentation Mode"
                onChange={handlePresentationModeChange}
              />
            </Tooltip2>
          </Section>
        </Fragment>
      )}
      <Divider />
    </Container>
  );
};

export default UserSettings;
