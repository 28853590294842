import styled from "@emotion/styled";
import {Button} from "@blueprintjs/core";
import {Tooltip2} from "@blueprintjs/popover2";

export enum ContainerPosition {
  LEFT = "left",
  RIGHT = "right"
}

type ActionButtonProps = {
  dataTestId?: string;
  active?: boolean;
  onClick: () => void;
  icon?: JSX.Element;
  tooltipContent?: {
    active?: string | JSX.Element;
    inactive?: string | JSX.Element;
  };
  isHideText?: boolean;
  text: any;
  rigthIcon?: JSX.Element;
  newWindow?: Window;
  usePortal?: boolean;
  switchToVerticalMenu?: boolean;
  containerPosition?: ContainerPosition;
};

const ActionButton = ({
  active,
  onClick,
  icon,
  dataTestId,
  tooltipContent,
  isHideText,
  text,
  rigthIcon,
  newWindow,
  usePortal,
  switchToVerticalMenu,
  containerPosition
}: ActionButtonProps) => {
  const content = active ? tooltipContent?.active : tooltipContent?.inactive;
  const renderToolTipPosition = () => {
    if (switchToVerticalMenu && containerPosition) {
      if (containerPosition === ContainerPosition.LEFT) {
        return "right";
      } else {
        return "left";
      }
    }
    return "bottom";
  };
  return (
    <Tooltip2
      popoverClassName="map-action-popover"
      position={renderToolTipPosition()}
      content={content}
      usePortal={usePortal}
      portalContainer={newWindow?.document.body}
    >
      <MenuButton
        data-testid={dataTestId}
        active={active}
        onClick={onClick}
        icon={
          <CenterDiv>
            {icon}
            {isHideText && rigthIcon}
          </CenterDiv>
        }
        isHideText={isHideText}
      >
        {isHideText ? null : text}
      </MenuButton>
    </Tooltip2>
  );
};

export default ActionButton;

const MenuButton = styled(Button)<{isHideText?: boolean}>`
  font-family: "Roboto", sans-serif !important;
  font-size: 12px !important;
  padding: 10px 0 !important;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-template-rows: ${(props) => (props.isHideText ? "1fr" : "1fr 1fr")};
  max-width: 100%;
  width: ${(props) => (props.isHideText ? "40px" : "85px")};
  height: 100%;
  max-height: ${(props) => (props.isHideText ? "40px" : "63px")};
  padding: 0;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  line-height: 1.2;
  font-weight: 500;
  word-wrap: break-word;
  word-break: break-word;

  :hover {
    &.bp4-button:not([class*="bp4-intent-"]) {
      background: linear-gradient(180deg, #ededed 0%, #ededed 100%) !important;
    }

    &.bp4-button.bp4-active {
      background: linear-gradient(180deg, #35578a 0%, #35578a 100%) !important;
    }
  }

  svg {
    margin: 0;
  }

  &.bp4-button:not([class*="bp4-intent-"]) {
    background: linear-gradient(180deg, #fcfcfc 0%, #f3f3f3 100%) !important;
    color: #161616;
    font-weight: 400;
  }

  &.bp4-button.bp4-active {
    background: linear-gradient(180deg, #214a89 0%, #14305a 100%) !important;
    color: #ffffff;

    path {
      fill: #ffffff;
    }
  }
`;

const CenterDiv = styled.div`
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
