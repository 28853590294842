const GreenBeacon = () => {
  return (
    <svg
      width="16"
      height="12"
      preserveAspectRatio="none"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.00556 0.978889C3.38889 1.28826 3.45 1.84848 3.14444 2.23311C2.28889 3.30338 1.77778 4.65794 1.77778 6.13514C1.77778 7.61233 2.28889 8.96689 3.14167 10.0372C3.45 10.4218 3.38611 10.982 3.00278 11.2914C2.61944 11.6008 2.06111 11.5367 1.75278 11.152C0.658333 9.77796 0 8.0332 0 6.13514C0 4.23708 0.658333 2.49232 1.75556 1.11825C2.06389 0.733619 2.62222 0.672301 3.00556 0.978889ZM12.9972 0.978889C13.3806 0.669514 13.9389 0.733619 14.2472 1.11825C15.3417 2.49232 16 4.23708 16 6.13514C16 8.0332 15.3417 9.77796 14.2444 11.152C13.9361 11.5367 13.3778 11.598 12.9944 11.2914C12.6111 10.9848 12.55 10.4218 12.8556 10.0372C13.7083 8.96689 14.2194 7.61233 14.2194 6.13514C14.2194 4.65794 13.7083 3.30338 12.8556 2.23311C12.5472 1.84848 12.6111 1.28826 12.9944 0.978889H12.9972ZM6.44444 6.13514C6.44444 5.72119 6.60833 5.32419 6.90006 5.03148C7.19178 4.73877 7.58744 4.57433 8 4.57433C8.41256 4.57433 8.80822 4.73877 9.09994 5.03148C9.39167 5.32419 9.55556 5.72119 9.55556 6.13514C9.55556 6.54909 9.39167 6.94609 9.09994 7.2388C8.80822 7.53151 8.41256 7.69595 8 7.69595C7.58744 7.69595 7.19178 7.53151 6.90006 7.2388C6.60833 6.94609 6.44444 6.54909 6.44444 6.13514ZM5.68056 4.05313C5.18611 4.60499 4.88889 5.33522 4.88889 6.13514C4.88889 6.93505 5.18889 7.6625 5.68056 8.21715C6.00833 8.58505 5.97778 9.14806 5.61111 9.47694C5.24444 9.80583 4.68333 9.77517 4.35556 9.40727C3.58333 8.53767 3.11111 7.39215 3.11111 6.13514C3.11111 4.87813 3.58333 3.7326 4.35556 2.8658C4.68333 2.49789 5.24444 2.46723 5.61111 2.79612C5.97778 3.125 6.00833 3.68801 5.68056 4.05592V4.05313ZM11.6444 2.86301C12.4167 3.73261 12.8889 4.87813 12.8889 6.13514C12.8889 7.39215 12.4167 8.53767 11.6444 9.40448C11.3167 9.77238 10.7556 9.80304 10.3889 9.47416C10.0222 9.14527 9.99167 8.58227 10.3194 8.21436C10.8139 7.6625 11.1111 6.93227 11.1111 6.13235C11.1111 5.33244 10.8111 4.60499 10.3194 4.05034C9.99167 3.68244 10.0222 3.11943 10.3889 2.79054C10.7556 2.46166 11.3167 2.49232 11.6444 2.86022V2.86301Z"
        fill="#32A467"
      />
    </svg>
  );
};

const YellowBeacon = () => {
  return (
    <svg
      width="16"
      height="12"
      preserveAspectRatio="none"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.00556 0.978889C3.38889 1.28826 3.45 1.84848 3.14444 2.23311C2.28889 3.30338 1.77778 4.65794 1.77778 6.13514C1.77778 7.61233 2.28889 8.96689 3.14167 10.0372C3.45 10.4218 3.38611 10.982 3.00278 11.2914C2.61944 11.6008 2.06111 11.5367 1.75278 11.152C0.658333 9.77796 0 8.0332 0 6.13514C0 4.23708 0.658333 2.49232 1.75556 1.11825C2.06389 0.733619 2.62222 0.672301 3.00556 0.978889ZM12.9972 0.978889C13.3806 0.669514 13.9389 0.733619 14.2472 1.11825C15.3417 2.49232 16 4.23708 16 6.13514C16 8.0332 15.3417 9.77796 14.2444 11.152C13.9361 11.5367 13.3778 11.598 12.9944 11.2914C12.6111 10.9848 12.55 10.4218 12.8556 10.0372C13.7083 8.96689 14.2194 7.61233 14.2194 6.13514C14.2194 4.65794 13.7083 3.30338 12.8556 2.23311C12.5472 1.84848 12.6111 1.28826 12.9944 0.978889H12.9972ZM6.44444 6.13514C6.44444 5.72119 6.60833 5.32419 6.90006 5.03148C7.19178 4.73877 7.58744 4.57433 8 4.57433C8.41256 4.57433 8.80822 4.73877 9.09994 5.03148C9.39167 5.32419 9.55556 5.72119 9.55556 6.13514C9.55556 6.54909 9.39167 6.94609 9.09994 7.2388C8.80822 7.53151 8.41256 7.69595 8 7.69595C7.58744 7.69595 7.19178 7.53151 6.90006 7.2388C6.60833 6.94609 6.44444 6.54909 6.44444 6.13514ZM5.68056 4.05313C5.18611 4.60499 4.88889 5.33522 4.88889 6.13514C4.88889 6.93505 5.18889 7.6625 5.68056 8.21715C6.00833 8.58505 5.97778 9.14806 5.61111 9.47694C5.24444 9.80583 4.68333 9.77517 4.35556 9.40727C3.58333 8.53767 3.11111 7.39215 3.11111 6.13514C3.11111 4.87813 3.58333 3.7326 4.35556 2.8658C4.68333 2.49789 5.24444 2.46723 5.61111 2.79612C5.97778 3.125 6.00833 3.68801 5.68056 4.05592V4.05313ZM11.6444 2.86301C12.4167 3.73261 12.8889 4.87813 12.8889 6.13514C12.8889 7.39215 12.4167 8.53767 11.6444 9.40448C11.3167 9.77238 10.7556 9.80304 10.3889 9.47416C10.0222 9.14527 9.99167 8.58227 10.3194 8.21436C10.8139 7.6625 11.1111 6.93227 11.1111 6.13235C11.1111 5.33244 10.8111 4.60499 10.3194 4.05034C9.99167 3.68244 10.0222 3.11943 10.3889 2.79054C10.7556 2.46166 11.3167 2.49232 11.6444 2.86022V2.86301Z"
        fill="#FFC701"
      />
    </svg>
  );
};
const RedBeacon = () => {
  return (
    <svg
      width="16"
      height="12"
      preserveAspectRatio="none"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.00556 0.978889C3.38889 1.28826 3.45 1.84848 3.14444 2.23311C2.28889 3.30338 1.77778 4.65794 1.77778 6.13514C1.77778 7.61233 2.28889 8.96689 3.14167 10.0372C3.45 10.4218 3.38611 10.982 3.00278 11.2914C2.61944 11.6008 2.06111 11.5367 1.75278 11.152C0.658333 9.77796 0 8.0332 0 6.13514C0 4.23708 0.658333 2.49232 1.75556 1.11825C2.06389 0.733619 2.62222 0.672301 3.00556 0.978889ZM12.9972 0.978889C13.3806 0.669514 13.9389 0.733619 14.2472 1.11825C15.3417 2.49232 16 4.23708 16 6.13514C16 8.0332 15.3417 9.77796 14.2444 11.152C13.9361 11.5367 13.3778 11.598 12.9944 11.2914C12.6111 10.9848 12.55 10.4218 12.8556 10.0372C13.7083 8.96689 14.2194 7.61233 14.2194 6.13514C14.2194 4.65794 13.7083 3.30338 12.8556 2.23311C12.5472 1.84848 12.6111 1.28826 12.9944 0.978889H12.9972ZM6.44444 6.13514C6.44444 5.72119 6.60833 5.32419 6.90006 5.03148C7.19178 4.73877 7.58744 4.57433 8 4.57433C8.41256 4.57433 8.80822 4.73877 9.09994 5.03148C9.39167 5.32419 9.55556 5.72119 9.55556 6.13514C9.55556 6.54909 9.39167 6.94609 9.09994 7.2388C8.80822 7.53151 8.41256 7.69595 8 7.69595C7.58744 7.69595 7.19178 7.53151 6.90006 7.2388C6.60833 6.94609 6.44444 6.54909 6.44444 6.13514ZM5.68056 4.05313C5.18611 4.60499 4.88889 5.33522 4.88889 6.13514C4.88889 6.93505 5.18889 7.6625 5.68056 8.21715C6.00833 8.58505 5.97778 9.14806 5.61111 9.47694C5.24444 9.80583 4.68333 9.77517 4.35556 9.40727C3.58333 8.53767 3.11111 7.39215 3.11111 6.13514C3.11111 4.87813 3.58333 3.7326 4.35556 2.8658C4.68333 2.49789 5.24444 2.46723 5.61111 2.79612C5.97778 3.125 6.00833 3.68801 5.68056 4.05592V4.05313ZM11.6444 2.86301C12.4167 3.73261 12.8889 4.87813 12.8889 6.13514C12.8889 7.39215 12.4167 8.53767 11.6444 9.40448C11.3167 9.77238 10.7556 9.80304 10.3889 9.47416C10.0222 9.14527 9.99167 8.58227 10.3194 8.21436C10.8139 7.6625 11.1111 6.93227 11.1111 6.13235C11.1111 5.33244 10.8111 4.60499 10.3194 4.05034C9.99167 3.68244 10.0222 3.11943 10.3889 2.79054C10.7556 2.46166 11.3167 2.49232 11.6444 2.86022V2.86301Z"
        fill="#FA545E"
      />
    </svg>
  );
};

const GrayBeacon = () => {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.00556 0.978889C3.38889 1.28826 3.45 1.84848 3.14444 2.23311C2.28889 3.30338 1.77778 4.65794 1.77778 6.13514C1.77778 7.61233 2.28889 8.96689 3.14167 10.0372C3.45 10.4218 3.38611 10.982 3.00278 11.2914C2.61944 11.6008 2.06111 11.5367 1.75278 11.152C0.658333 9.77796 0 8.0332 0 6.13514C0 4.23708 0.658333 2.49232 1.75556 1.11825C2.06389 0.733619 2.62222 0.672301 3.00556 0.978889ZM12.9972 0.978889C13.3806 0.669514 13.9389 0.733619 14.2472 1.11825C15.3417 2.49232 16 4.23708 16 6.13514C16 8.0332 15.3417 9.77796 14.2444 11.152C13.9361 11.5367 13.3778 11.598 12.9944 11.2914C12.6111 10.9848 12.55 10.4218 12.8556 10.0372C13.7083 8.96689 14.2194 7.61233 14.2194 6.13514C14.2194 4.65794 13.7083 3.30338 12.8556 2.23311C12.5472 1.84848 12.6111 1.28826 12.9944 0.978889H12.9972ZM6.44444 6.13514C6.44444 5.72119 6.60833 5.32419 6.90006 5.03148C7.19178 4.73877 7.58744 4.57433 8 4.57433C8.41256 4.57433 8.80822 4.73877 9.09994 5.03148C9.39167 5.32419 9.55556 5.72119 9.55556 6.13514C9.55556 6.54909 9.39167 6.94609 9.09994 7.2388C8.80822 7.53151 8.41256 7.69595 8 7.69595C7.58744 7.69595 7.19178 7.53151 6.90006 7.2388C6.60833 6.94609 6.44444 6.54909 6.44444 6.13514ZM5.68056 4.05313C5.18611 4.60499 4.88889 5.33522 4.88889 6.13514C4.88889 6.93505 5.18889 7.6625 5.68056 8.21715C6.00833 8.58505 5.97778 9.14806 5.61111 9.47694C5.24444 9.80583 4.68333 9.77517 4.35556 9.40727C3.58333 8.53767 3.11111 7.39215 3.11111 6.13514C3.11111 4.87813 3.58333 3.7326 4.35556 2.8658C4.68333 2.49789 5.24444 2.46723 5.61111 2.79612C5.97778 3.125 6.00833 3.68801 5.68056 4.05592V4.05313ZM11.6444 2.86301C12.4167 3.73261 12.8889 4.87813 12.8889 6.13514C12.8889 7.39215 12.4167 8.53767 11.6444 9.40448C11.3167 9.77238 10.7556 9.80304 10.3889 9.47416C10.0222 9.14527 9.99167 8.58227 10.3194 8.21436C10.8139 7.6625 11.1111 6.93227 11.1111 6.13235C11.1111 5.33244 10.8111 4.60499 10.3194 4.05034C9.99167 3.68244 10.0222 3.11943 10.3889 2.79054C10.7556 2.46166 11.3167 2.49232 11.6444 2.86022V2.86301Z"
        fill="#999999"
      />
    </svg>
  );
};

export {GreenBeacon, YellowBeacon, RedBeacon, GrayBeacon};
