import {MenuItem} from "@blueprintjs/core";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type FaIconProps = {
  isVisible: boolean;
};

export const FaIcon = ({isVisible}: FaIconProps) => {
  return <FontAwesomeIcon icon={solid("check")} fixedWidth={true} visibility={isVisible ? "initial" : "hidden"} />;
};

type SortMenuItemProps = {
  isSelected: boolean;
  text: string;
  propertyKey: string;
  handleMenuItemClick(key: string): void;
};

const SortMenuItem = ({isSelected, text, propertyKey, handleMenuItemClick}: SortMenuItemProps) => {
  return (
    <MenuItem
      icon={<FaIcon isVisible={isSelected} />}
      text={text}
      onClick={() => handleMenuItemClick(propertyKey)}
      data-dd-action-name={`Sort Option ${propertyKey}`}
    ></MenuItem>
  );
};

export default SortMenuItem;
