export enum DriverInformationValue {
  DriverName = "DriverName",
  DriveCode = "DriveCode",
  DriverCapabilities = "DriverCapabilities",
  DriverCodeAndName = "DriverCodeAndName"
}

export enum AdditionalStopInformationValue {
  CustomerName = "CustomerName",
  PiecesCount = "PiecesCount",
  RouteNumber = "RouteNumber",
  StopName = "StopName",
  None = "None"
}

export enum StopLocationValue {
  FullAddressAndZone = "FullAddressAndZone",
  CityStateZone = "CityStateZone",
  ZoneOnly = "ZoneOnly"
}

export enum TopStopInformationValue {
  LastCompletedStop = "LastCompletedStop",
  NextStopInManifest = "NextStopInManifest",
  FirstStopInManifest = "FirstStopInManifest"
}

export enum HoverOnDriversValues {
  DRIVER_PHONE = "Driver Phone",
  ALIAS = "Alias",
  MANIFEST_ID = "Manifest ID",
  MANIFEST_DATE = "Manifest Date",
  LAST_ZONE = "Last Zone",
  NOTES = "Notes",
  END_ZIP = "End Zip",
  TOTAL_PIECES = "Total Pieces",
  DRIVER_CODE = "Driver Code",
  DRIVER_NAME = "Driver Name"
}
export enum HoverOnStopsValues {
  STOP_ADDRESS = "Stop Address",
  CUSTOMER = "Customer",
  STOP_SEE = "Stop See",
  STOP_PHONE = "Stop Phone",
  ROUTE_NUMBER = "Route #",
  SERVICE = "Service",
  STOP_NAME = "Stop Name",
  STOP_NOTES = "Stop Notes",
  PIECES_OR_WEIGHT = "Pieces / Weight",
  STOP_ROOM = "Stop Room",
  DESCRIPTION = "Description",
  CALLER_AND_PHONE = "Caller and phone",
  AUTH = "Auth",
  ALIAS = "Alias",
  COD_STATUS_AND_AMOUNT = "COD Status and COD Amount",
  OTHER_REFERENCE = "Other Reference",
  INVOICE_REFERENCE = "Invoice Reference",
  JOB_NUMBER = "Job Number"
}
