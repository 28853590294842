import React, {useContext} from "react";
import styled from "@emotion/styled";
import {activeStops} from "../../services/ManifestStopService";
import {ManifestStop, Maybe} from "../../generated/graphql";
import {IndicatorColors} from "../settings/ColorizedIndicators/ColorizedIndicators";
import {UserColor} from "../settings/ColorizedIndicators/types/indicatorLogicType";
import {CardType} from "../../views/JobAssignmentView";
import {isHasUnsupportedStatus, isSmallCard} from "../../utils/General";
import {FeatureFlagContext} from "../../providers/FeatureFlagProvider";
import {Tooltip2} from "@blueprintjs/popover2";
import {WarningIcon} from "./StatusIconV3";
import {Text} from "@blueprintjs/core";
type StopCountIndicatorProps = {
  stops: Maybe<ManifestStop[]> | undefined;
  fontSize?: string;
  indicatorColor?: string;
  cardType?: CardType;
};

const getFontSize = (numberOfStops: number, cardType: CardType | undefined, compactCardEnalbled: boolean) => {
  return isSmallCard(cardType, compactCardEnalbled) && numberOfStops.toString().length >= 3 ? 8 : 12;
};

const StopCountIndicatorV3 = ({stops, indicatorColor, cardType}: StopCountIndicatorProps) => {
  const {compactCard: compactCardEnalbled} = useContext(FeatureFlagContext);
  const activeStopsCount = activeStops(stops);
  const isShowWarningIcon = isHasUnsupportedStatus(stops);
  const totalStops = stops?.length ?? 0;

  const stopCountWidth = cardType ? (isSmallCard(cardType, compactCardEnalbled) ? 21 : 27) : 32;
  const stopCountHeight = cardType ? (isSmallCard(cardType, compactCardEnalbled) ? 16 : 20) : 24;

  return (
    <NewStopCountIndicatorContainer data-testid="new-stop-count-indicator">
      <StopCount
        backgroundColor={indicatorColor ? IndicatorColors[indicatorColor as UserColor].hex : ""}
        width={stopCountWidth}
        height={stopCountHeight}
        fontSize={getFontSize(activeStopsCount, cardType, compactCardEnalbled)}
      >
        {isShowWarningIcon ? (
          <Tooltip2
            content={
              <>
                <Text>The stop statuses of Queued, Accepted, and Cleared are supported on Dispatch.</Text>
                <Text>
                  Please navigate to Utility - Job Stop Status to make sure this stop type matches one of those 3
                  statuses.
                </Text>
              </>
            }
            popoverClassName="manifest-warning-popover"
            position="bottom"
          >
            <WarningIcon color={indicatorColor ? "#ffffff" : "#161616"} size={12} />
          </Tooltip2>
        ) : (
          activeStopsCount
        )}
      </StopCount>
      <StopCount
        backgroundColor={indicatorColor ? IndicatorColors[indicatorColor as UserColor].hex : ""}
        width={stopCountWidth}
        height={stopCountHeight}
        fontSize={getFontSize(totalStops, cardType, compactCardEnalbled)}
      >
        {totalStops}
      </StopCount>
    </NewStopCountIndicatorContainer>
  );
};

export default StopCountIndicatorV3;

const NewStopCountIndicatorContainer = styled.div`
  border-radius: 4px;
  height: fit-content;
  overflow: hidden;
  font-size: 12px;
  display: flex;
  gap: 2px;
`;
interface StopsCountProps {
  backgroundColor?: string;
  width: number;
  height: number;
  fontSize: number;
}

const StopCount = styled.div<StopsCountProps>`
  width: ${(props) => props.width && `${props.width}px`};
  height: ${(props) => props.height && `${props.height}px`};
  color: ${(props) => (props.backgroundColor ? "white" : "black")};
  background-color: ${(props) => props.backgroundColor || "#E0E0E0"};
  text-align: center;
  font-size: ${(props) => `${props.fontSize}px`};
  line-height: ${(props) => `${props.height}px`};
  font-weight: 500;
  .bp4-popover2-target {
    padding-bottom: 2px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
