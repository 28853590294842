import styled from "@emotion/styled";
import {useMemo} from "react";
import {ActiveIcon, FutureIcon, InactiveIcon} from "../../manifest-filter/ManifestIcons";

export enum ManifestStatusType {
  ACTIVE = "A",
  FUTURE = "F",
  INACTIVE = "I",
  UNKNOWN = "U"
}

interface ManifestStatusProps {
  manifestStatusType: string;
}

const ManifestStatus = ({manifestStatusType}: ManifestStatusProps) => {
  const renderStatusText = useMemo(() => {
    switch (manifestStatusType) {
      case ManifestStatusType.ACTIVE:
        return "Active";
      case ManifestStatusType.FUTURE:
        return "Future";
      case ManifestStatusType.INACTIVE:
        return "Inactive";
      default:
        return "Unknown";
    }
  }, [manifestStatusType]);
  const renderStatusIcon = useMemo(() => {
    switch (manifestStatusType) {
      case ManifestStatusType.ACTIVE:
        return <ActiveIcon />;
      case ManifestStatusType.FUTURE:
        return <FutureIcon />;
      case ManifestStatusType.INACTIVE:
        return <InactiveIcon />;
      default:
        return null;
    }
  }, [manifestStatusType]);
  return (
    <ManifestStatusButton>
      {renderStatusIcon}
      <ManifestStatusText isUnknown={manifestStatusType === ManifestStatusType.UNKNOWN}>
        {renderStatusText}
      </ManifestStatusText>
    </ManifestStatusButton>
  );
};

export {ManifestStatus};

const ManifestStatusButton = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  justify-content: flex-start;
  height: 24px;

  background-color: white;
  border: none;
`;

const ManifestStatusText = styled.div<{isUnknown: boolean}>`
  padding: 0;
  margin: 0;
  transform: translate(0, 1px);
  color: ${(props) => (props.isUnknown ? "#797979" : "#161616")};
`;
