import {ManifestAttributeFilter} from "../../../generated/graphql";
import {ManifestFilterType} from "../ManifestFilterState";
import {AbstractManifestViewFilter} from "./AbstractManifestViewFilter";

export class StopCountManifestFilter extends AbstractManifestViewFilter<number> {
  constructor() {
    super(ManifestFilterType.STOP_COUNT);
  }
  toFilter(): ManifestAttributeFilter | undefined {
    if (this.value.size > 0) {
      const filterItems: ManifestAttributeFilter[] = Array.from(this.value).map((value) => {
        return {
          stopCount: {eq: value}
        } as ManifestAttributeFilter;
      });
      return this.handleMultiValuedFilter(filterItems);
    }
    return undefined;
  }
}
