import styled from "@emotion/styled";
import {HTMLAttributes} from "react";
import {JobNumber, SequenceNumber} from "./JobStopIcon";
import {StopInfoMetadata} from "./StopInfo";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const DotIcon = styled.svg`
  width: fit-content;
  left: 16px;
  bottom: 27px;
`;

const PinIcon = styled.svg``;

const IconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 41px;
  cursor: pointer;
`;

const theme = {
  textColor: "#FFFFFF",
  borderColor: "#FFFFFF",
  backgroundColor: "#000000"
};

type MultiStopIconProps = HTMLAttributes<HTMLDivElement> & {
  jobStopId: number;
  jobNumber?: number;
  showCounter: boolean;
  showJobNumber: boolean;
  jobColor: string;
  symbol: string;
  counter: number;
  primaryColor: string;
  secondaryColor?: string;
  mapJobStop?: number[];
  handleAssignmentByClickingMarker: (info: StopInfoMetadata) => void;
  info?: StopInfoMetadata;
};

const MultiStopIcon = ({
  jobStopId,
  symbol,
  counter,
  primaryColor,
  secondaryColor,
  jobColor,
  mapJobStop,
  jobNumber,
  showJobNumber,
  handleAssignmentByClickingMarker,
  info
}: MultiStopIconProps) => {
  return (
    <Container>
      <IconWrapper
        onClick={() => handleAssignmentByClickingMarker(info as StopInfoMetadata)}
        className="icon-wrapper"
        data-testid={`multi-stop-${jobStopId}`}
      >
        <PinIcon className="pin" viewBox="0 0 42 46" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="B with number">
            <g id="material-symbols" filter="url(#filter0_d_627_29587)">
              <g id="Group 824">
                <path
                  id="Vector"
                  d="M20.6667 40.1667C16.0826 36.266 12.6591 32.6432 10.3962 29.2982C8.13319 25.9533 7.00114 22.8567 7 20.0083C7 15.7375 8.37407 12.3351 11.1222 9.80104C13.8703 7.26701 17.0518 6 20.6667 6C24.2826 6 27.4647 7.26701 30.2128 9.80104C32.961 12.3351 34.3345 15.7375 34.3333 20.0083C34.3333 22.8556 33.2013 25.9522 30.9372 29.2982C28.6731 32.6443 25.2496 36.2671 20.6667 40.1667Z"
                  fill={mapJobStop?.includes(jobStopId) ? "#E51212" : primaryColor}
                />
                {secondaryColor && (
                  <path
                    id="Subtract"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.3962 29.2982C12.6591 32.6432 16.0826 36.266 20.6667 40.1667C20.6674 40.166 20.6682 40.1654 20.6689 40.1647V6C20.6682 6 20.6674 6 20.6667 6C17.0518 6 13.8703 7.26701 11.1222 9.80104C8.37407 12.3351 7 15.7375 7 20.0083C7.00114 22.8567 8.13319 25.9533 10.3962 29.2982Z"
                    fill={secondaryColor}
                  />
                )}
                <path
                  id="Vector_2"
                  d="M20.6667 40.1667C16.0826 36.266 12.6591 32.6432 10.3962 29.2982C8.13319 25.9533 7.00114 22.8567 7 20.0083C7 15.7375 8.37407 12.3351 11.1222 9.80104C13.8703 7.26701 17.0518 6 20.6667 6C24.2826 6 27.4647 7.26701 30.2128 9.80104C32.961 12.3351 34.3345 15.7375 34.3333 20.0083C34.3333 22.8556 33.2013 25.9522 30.9372 29.2982C28.6731 32.6443 25.2496 36.2671 20.6667 40.1667Z"
                  stroke={theme.borderColor}
                  strokeWidth="2"
                />
                <text
                  id="B"
                  fill={theme.textColor}
                  xmlSpace="preserve"
                  fontFamily="Arial"
                  fontSize="16"
                  fontWeight="bold"
                  letterSpacing="0px"
                >
                  <tspan x="15.5134" y="26.8442">
                    {symbol}
                  </tspan>
                </text>
              </g>
            </g>
          </g>
          <defs>
            <filter
              id="box_filter"
              x="0"
              y="0"
              width="90"
              height="90"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_627_29587" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_627_29587" result="shape" />
            </filter>
            <filter
              id="filter0_d_627_29587"
              x="-4"
              y="3"
              width="49"
              height="49"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_627_29587" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_627_29587" result="shape" />
            </filter>
            <filter
              id="filter1_d_627_29587"
              x="13.8"
              y="44"
              width="14"
              height="14"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_627_29587" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_627_29587" result="shape" />
            </filter>
          </defs>
        </PinIcon>
        <DotIcon
          className="dot-icon"
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="5" cy="5" r="4" fill="black" stroke="white" strokeWidth="1.5" />
        </DotIcon>
        <SequenceNumber className="counter" backgroundColor={jobColor}>
          {counter}
        </SequenceNumber>
      </IconWrapper>
      {showJobNumber && (
        <JobNumber className={"job-number"} ellipsize>
          {jobNumber}
        </JobNumber>
      )}
    </Container>
  );
};

export default MultiStopIcon;
