import {Fragment, useContext, useEffect, useState} from "react";
import {ManifestDataContext, ManifestDataState} from "../../ManifestDataProvider";
import {getMatchRequirementManifests} from "../../../job/JobPanel";
import {showQualifiedDriversWarningToast} from "../../../../utils/qualified_drivers_toaster/QualifiedDriversToaster";
import {
  JobAssignmentActions,
  JobAssignmentAsyncActions,
  JobAssignmentState
} from "../../../common/JobAssignmentReducer";
import {Job, useMap_SearchJobsQuery} from "../../../../generated/graphql";
import {useListActionBarDispatch} from "../../../common/ListActionBarProvider";

type AssignDriverContainerProps = {
  children: JSX.Element[] | JSX.Element;
  highlightRequirementManifests: boolean;
  assignmentState: JobAssignmentState;
  assignmentDispatch: React.Dispatch<JobAssignmentActions | JobAssignmentAsyncActions>;
};
const AssignDriverContainer = ({
  children,
  assignmentState,
  assignmentDispatch,
  highlightRequirementManifests
}: AssignDriverContainerProps) => {
  const manifestDataState = useContext<ManifestDataState>(ManifestDataContext);
  const [job, setJob] = useState<Job>();
  const [manifestJob, setManifestJob] = useState<Job>();
  const listActionBarDispatch = useListActionBarDispatch();

  const {data, error, loading} = useMap_SearchJobsQuery({
    variables: {
      filter: {
        job: {
          jobId: {
            eq: manifestJob?.jobId
          }
        }
      }
    }
  });

  useEffect(() => {
    if (assignmentState.selectedJobs.length > 0) {
      setManifestJob(assignmentState.selectedJobs[0]);
    }
  }, [assignmentState]);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
    if (!loading && data?.searchJobs?.items) {
      setJob(data.searchJobs.items[0] as Job);
    }
  }, [data, error, loading]);

  useEffect(() => {
    if (job?.order.requirements && job?.order.requirements?.length > 0) {
      if (highlightRequirementManifests) {
        const highlightManifests = getMatchRequirementManifests({
          selectedJobs: [job],
          manifests: manifestDataState.data
        });
        assignmentDispatch({type: "SetHighlightManifest", payload: highlightManifests});
        if (highlightManifests.length === 0) {
          showQualifiedDriversWarningToast();
        }
      } else {
        assignmentDispatch({type: "SetHighlightManifest", payload: []});
      }
      listActionBarDispatch({type: "SET_BUTTON_HIDDEN", payload: {id: "qualified-drivers", hidden: false}});
    } else {
      //hide qualified drivers button
      listActionBarDispatch({type: "SET_BUTTON_HIDDEN", payload: {id: "qualified-drivers", hidden: true}});
      assignmentDispatch({type: "SetHighlightManifest", payload: []});
    }
  }, [assignmentDispatch, highlightRequirementManifests, job, listActionBarDispatch, manifestDataState.data]);

  return <Fragment>{children}</Fragment>;
};

export default AssignDriverContainer;
