const PickupIcon = (color?: string) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={"pickup-stop-type-icon"}
    >
      <g id="P">
        <g id="P for manifest">
          <circle id="Ellipse 86" cx="11" cy="11" r="10" stroke={color || "#406AFF"} />
          <path
            id="P_2"
            d="M11.2461 11.8887H9.04883V10.623H11.2461C11.6055 10.623 11.8965 10.5645 12.1191 10.4473C12.3457 10.3262 12.5117 10.1621 12.6172 9.95508C12.7227 9.74414 12.7754 9.50391 12.7754 9.23438C12.7754 8.97266 12.7227 8.72852 12.6172 8.50195C12.5117 8.27539 12.3457 8.0918 12.1191 7.95117C11.8965 7.81055 11.6055 7.74023 11.2461 7.74023H9.57617V15H7.96484V6.46875H11.2461C11.9102 6.46875 12.4766 6.58789 12.9453 6.82617C13.418 7.06055 13.7773 7.38672 14.0234 7.80469C14.2734 8.21875 14.3984 8.69141 14.3984 9.22266C14.3984 9.77344 14.2734 10.248 14.0234 10.6465C13.7773 11.0449 13.418 11.3516 12.9453 11.5664C12.4766 11.7812 11.9102 11.8887 11.2461 11.8887Z"
            fill={color || "#406AFF"}
          />
        </g>
      </g>
    </svg>
  );
};

const DeliveryIcon = (color?: string) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={"delivery-stop-type-icon"}
    >
      <g id="D">
        <g id="D for manifest">
          <circle id="Ellipse 86" cx="11" cy="11" r="10" stroke={color || "#32A467"} />
          <path
            id="D_2"
            d="M10.4551 15H8.61523L8.62695 13.7344H10.4551C10.9512 13.7344 11.3672 13.625 11.7031 13.4062C12.0391 13.1836 12.293 12.8652 12.4648 12.4512C12.6367 12.0332 12.7227 11.5332 12.7227 10.9512V10.5117C12.7227 10.0625 12.6738 9.66602 12.5762 9.32227C12.4785 8.97852 12.334 8.68945 12.1426 8.45508C11.9551 8.2207 11.7227 8.04297 11.4453 7.92188C11.168 7.80078 10.8496 7.74023 10.4902 7.74023H8.58008V6.46875H10.4902C11.0605 6.46875 11.5801 6.56445 12.0488 6.75586C12.5215 6.94727 12.9297 7.22266 13.2734 7.58203C13.6211 7.9375 13.8867 8.36328 14.0703 8.85938C14.2578 9.35547 14.3516 9.91016 14.3516 10.5234V10.9512C14.3516 11.5605 14.2578 12.1152 14.0703 12.6152C13.8867 13.1113 13.6211 13.5371 13.2734 13.8926C12.9297 14.248 12.5195 14.5215 12.043 14.7129C11.5664 14.9043 11.0371 15 10.4551 15ZM9.51758 6.46875V15H7.90625V6.46875H9.51758Z"
            fill={color || "#32A467"}
          />
        </g>
      </g>
    </svg>
  );
};

const BothIcon = (color?: string) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={"both-stop-type-icon"}
    >
      <g id="Both">
        <g id="Both_2">
          <g id="Mask group">
            <mask
              id="mask0_226_3714"
              style={{maskType: "alpha"}}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="22"
              height="22"
            >
              <g id="B for manifest">
                <g id="P/D/B for manifest">
                  <circle id="Ellipse 86" cx="11" cy="11" r="10" stroke="#161616" />
                  <path
                    id="B"
                    d="M11.2754 11.1973H9.0957L9.08398 10.0605H10.9531C11.2734 10.0605 11.541 10.0156 11.7559 9.92578C11.9707 9.83594 12.1328 9.70508 12.2422 9.5332C12.3555 9.36133 12.4121 9.15234 12.4121 8.90625C12.4121 8.63281 12.3594 8.41016 12.2539 8.23828C12.1523 8.06641 11.9922 7.94141 11.7734 7.86328C11.5547 7.78125 11.2773 7.74023 10.9414 7.74023H9.61719V15H8.00586V6.46875H10.9414C11.4258 6.46875 11.8574 6.51562 12.2363 6.60938C12.6191 6.69922 12.9434 6.83984 13.209 7.03125C13.4746 7.22266 13.6758 7.46289 13.8125 7.75195C13.9531 8.04102 14.0234 8.38477 14.0234 8.7832C14.0234 9.13477 13.9414 9.45898 13.7773 9.75586C13.6172 10.0488 13.3691 10.2871 13.0332 10.4707C12.7012 10.6543 12.2793 10.7598 11.7676 10.7871L11.2754 11.1973ZM11.2051 15H8.62109L9.29492 13.7344H11.2051C11.5254 13.7344 11.7891 13.6816 11.9961 13.5762C12.207 13.4707 12.3633 13.3262 12.4648 13.1426C12.5703 12.9551 12.623 12.7402 12.623 12.498C12.623 12.2324 12.5762 12.002 12.4824 11.8066C12.3926 11.6113 12.248 11.4609 12.0488 11.3555C11.8535 11.25 11.5957 11.1973 11.2754 11.1973H9.59961L9.61133 10.0605H11.7441L12.1133 10.5C12.6055 10.5039 13.0059 10.6016 13.3145 10.793C13.627 10.9844 13.8574 11.2305 14.0059 11.5312C14.1543 11.832 14.2285 12.1562 14.2285 12.5039C14.2285 13.0508 14.1094 13.5098 13.8711 13.8809C13.6367 14.252 13.293 14.5312 12.8398 14.7188C12.3906 14.9062 11.8457 15 11.2051 15Z"
                    fill="#161616"
                  />
                </g>
              </g>
            </mask>
            <g mask="url(#mask0_226_3714)">
              <rect id="Rectangle 472" x="11" width="12" height="22" fill={color || "#32A467"} />
            </g>
          </g>
          <mask
            id="mask1_226_3714"
            style={{maskType: "alpha"}}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="22"
            height="22"
          >
            <g id="B for manifest_2">
              <g id="P/D/B for manifest_2">
                <circle id="Ellipse 86_2" cx="11" cy="11" r="10" stroke="#161616" />
                <path
                  id="B_2"
                  d="M11.2754 11.1973H9.0957L9.08398 10.0605H10.9531C11.2734 10.0605 11.541 10.0156 11.7559 9.92578C11.9707 9.83594 12.1328 9.70508 12.2422 9.5332C12.3555 9.36133 12.4121 9.15234 12.4121 8.90625C12.4121 8.63281 12.3594 8.41016 12.2539 8.23828C12.1523 8.06641 11.9922 7.94141 11.7734 7.86328C11.5547 7.78125 11.2773 7.74023 10.9414 7.74023H9.61719V15H8.00586V6.46875H10.9414C11.4258 6.46875 11.8574 6.51562 12.2363 6.60938C12.6191 6.69922 12.9434 6.83984 13.209 7.03125C13.4746 7.22266 13.6758 7.46289 13.8125 7.75195C13.9531 8.04102 14.0234 8.38477 14.0234 8.7832C14.0234 9.13477 13.9414 9.45898 13.7773 9.75586C13.6172 10.0488 13.3691 10.2871 13.0332 10.4707C12.7012 10.6543 12.2793 10.7598 11.7676 10.7871L11.2754 11.1973ZM11.2051 15H8.62109L9.29492 13.7344H11.2051C11.5254 13.7344 11.7891 13.6816 11.9961 13.5762C12.207 13.4707 12.3633 13.3262 12.4648 13.1426C12.5703 12.9551 12.623 12.7402 12.623 12.498C12.623 12.2324 12.5762 12.002 12.4824 11.8066C12.3926 11.6113 12.248 11.4609 12.0488 11.3555C11.8535 11.25 11.5957 11.1973 11.2754 11.1973H9.59961L9.61133 10.0605H11.7441L12.1133 10.5C12.6055 10.5039 13.0059 10.6016 13.3145 10.793C13.627 10.9844 13.8574 11.2305 14.0059 11.5312C14.1543 11.832 14.2285 12.1562 14.2285 12.5039C14.2285 13.0508 14.1094 13.5098 13.8711 13.8809C13.6367 14.252 13.293 14.5312 12.8398 14.7188C12.3906 14.9062 11.8457 15 11.2051 15Z"
                  fill="#161616"
                />
              </g>
            </g>
          </mask>
          <g mask="url(#mask1_226_3714)">
            <rect id="Rectangle 472_2" x="-1" width="12" height="22" fill={color || "#406AFF"} />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const renderStopTypeIcon = (stopType: string, color?: string) => {
  switch (stopType) {
    case "P":
      return PickupIcon(color);
    case "D":
      return DeliveryIcon(color);
    case "B":
      return BothIcon(color);
    default:
      return <span />;
  }
};
