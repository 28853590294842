import React, {useCallback, useContext, useEffect, useState} from "react";
import {PreferenceContext, extractSimplePref} from "../../../providers/PreferenceProvider";
import {TenantPreferences} from "../../common/Constants";
import styled from "@emotion/styled";
import VehicleIconTable from "./VehicleIconSetupTable";
import {DefaultVehicleIcons} from "../../vehicles/VehicleIconResolver";
import {useSetTenantPrefMutation} from "../../../generated/graphql";

export type VehiclePrefItemType = {
  id: string;
  value: string[];
};

const VehicleIconsSetup = () => {
  const [vehicleIcons, setVehicleIcons] = useState(DefaultVehicleIcons);
  const [vehicleIconsTenantPref, setVehicleIconsTenantPref] = useState<VehiclePrefItemType[]>([]);
  const {tenantPreferences, tenantPrefsQueryRefetch} = useContext(PreferenceContext);
  const [setTenantPref] = useSetTenantPrefMutation();

  useEffect(() => {
    const vehicleIconSetupData = extractSimplePref(tenantPreferences, TenantPreferences.vehicleIconSetup, [])
      .value as VehiclePrefItemType[];
    setVehicleIconsTenantPref(vehicleIconSetupData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newVehicleIcons = [...vehicleIcons];
    newVehicleIcons.forEach((item, index) => {
      const existingSavedItem = vehicleIconsTenantPref.find((savedItem) => savedItem.id === item.id);
      if (existingSavedItem) {
        newVehicleIcons[index] = {...item, customValues: existingSavedItem.value};
      } else {
        newVehicleIcons[index] = DefaultVehicleIcons.find((o) => o.id === item.id)!;
      }
    });
    setVehicleIcons(newVehicleIcons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleIconsTenantPref]);

  const handleUpdateTenantPrefs = useCallback(
    (prefItem: VehiclePrefItemType) => {
      const newSaveValue = vehicleIconsTenantPref.filter((item) => item.id !== prefItem.id);

      if (prefItem.value.length > 0) {
        newSaveValue.push(prefItem);
      }
      setVehicleIconsTenantPref(newSaveValue);
      setTenantPref({
        variables: {
          name: TenantPreferences.vehicleIconSetup,
          input: {
            value: JSON.stringify({value: newSaveValue})
          }
        }
      }).then(() => {
        tenantPrefsQueryRefetch?.();
      });
    },
    [setTenantPref, tenantPrefsQueryRefetch, vehicleIconsTenantPref]
  );

  return (
    <Container>
      <VehicleIconTable
        icons={vehicleIcons.slice(0, 13)}
        updateTenantPrefs={handleUpdateTenantPrefs}
        vehicleIconsTenantPref={vehicleIconsTenantPref}
      />
      <VehicleIconTable
        icons={vehicleIcons.slice(13)}
        updateTenantPrefs={handleUpdateTenantPrefs}
        vehicleIconsTenantPref={vehicleIconsTenantPref}
      />
    </Container>
  );
};

export default VehicleIconsSetup;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 0 24px 24px;
`;
