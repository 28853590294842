import React from "react";
import SettingViewContainer from "../components/SettingViewContainer";
import VehicleIconsSetup from "./VehicleIconsSetup";

const VehicleIconSetupView = () => {
  return (
    <SettingViewContainer
      title="Vehicle Icons Setup"
      subTitle="Here you can manage the strings that match these icons. Click a row to add or remove strings."
      divideSection={false}
    >
      <VehicleIconsSetup />
    </SettingViewContainer>
  );
};

export default VehicleIconSetupView;
