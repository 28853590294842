import {ICellRendererParams, SetFilterValuesFuncParams} from "@ag-grid-community/core";
import {ApolloQueryResult, LazyQueryResult, QueryLazyOptions} from "@apollo/client";
import {Bucket, Exact, GetDistinctValuesQuery, InputMaybe} from "../generated/graphql";

export const getFilterValues = (
  params: SetFilterValuesFuncParams,
  getDistinctValues: (
    options?:
      | QueryLazyOptions<Exact<{field: string; typeName: string; limit?: InputMaybe<number> | undefined}>>
      | undefined
  ) => Promise<LazyQueryResult<GetDistinctValuesQuery, Exact<any>>>,
  typename: string,
  field: string
) => {
  getDistinctValues({variables: {typeName: typename, field: field}})
    .then((x: ApolloQueryResult<any>) => {
      params.success(x.data.getDistinctValues.items.map((y: Bucket) => y.key));
    })
    .catch((error: Error) => {
      console.error(error);
    });
};

export const clearAllSort = (params: ICellRendererParams) => {
  const allColumns = params.columnApi.getColumns() ?? [];
  const columnState = allColumns.map((column) => {
    return {
      colId: column.getColId(),
      sort: null
    };
  });
  params.columnApi.applyColumnState({state: columnState});
};
