import {ListActionButton} from "./ListActionBar";
import {ListActionBarActions, ListActionBarState} from "./ListActionBarProvider";

const listActionBarReducer = (state: ListActionBarState, action: ListActionBarActions) => {
  switch (action.type) {
    case "SET_BUTTONS": {
      return {
        ...state,
        buttons: action.payload
      };
    }
    case "SET_IS_SHOWING": {
      return {
        ...state,
        isShowing: action.payload
      };
    }
    case "TOGGLE_BUTTON_ACTIVE": {
      return {
        ...state,
        buttons: [
          ...state.buttons.map((button) => {
            if (button.id === action.payload.id) {
              return {
                ...button,
                active: !button.active
              } as ListActionButton;
            }
            return button;
          })
        ]
      };
    }
    case "SET_BUTTON_HIDDEN": {
      return {
        ...state,
        buttons: [
          ...state.buttons.map((button) => {
            if (button.id === action.payload.id) {
              return {
                ...button,
                hidden: action.payload.hidden
              } as ListActionButton;
            }
            return button;
          })
        ]
      };
    }
    case "SET_BUTTON_DISABLED": {
      return {
        ...state,
        buttons: [
          ...state.buttons.map((button) => {
            if (button.id === action.payload.id) {
              return {
                ...button,
                disabled: action.payload.disabled
              } as ListActionButton;
            }
            return button;
          })
        ]
      };
    }
    default: {
      return state;
    }
  }
};

export default listActionBarReducer;
