import DispatchStation from "./types/DispatchStation";
import {createContext, useState} from "react";

type DispatchStationDataProviderProps = {
  children: JSX.Element[] | JSX.Element;
};

type DispatchStationDataState = {
  selectedDispatchStations: Map<string, DispatchStation>;
  hasSelectedDispatchStations: () => boolean;
  addSelectedDispatchStation: (dispatchStation: DispatchStation) => void;
  removeSelectedDispatchStation: (dispatchStation: DispatchStation) => void;
  clearSelectedDispatchStations: () => void;
  initialSelectedDispatchStations: (dispatchStations: DispatchStation[]) => void;
};

const initialState: DispatchStationDataState = {
  selectedDispatchStations: new Map<string, DispatchStation>(),
  hasSelectedDispatchStations: () => {
    console.warn("DispatchStationDataProvider not initialized");
    return false;
  },
  addSelectedDispatchStation: () => {
    console.warn("DispatchStationDataProvider not initialized");
  },
  removeSelectedDispatchStation: () => {
    console.warn("DispatchStationDataProvider not initialized");
  },
  clearSelectedDispatchStations: () => {
    console.warn("DispatchStationDataProvider not initialized");
  },
  initialSelectedDispatchStations: () => {
    console.warn("DispatchStationDataProvider not initialized");
  }
};
const DispatchStationDataContext = createContext<DispatchStationDataState>(initialState);
const DispatchStationDataProvider = ({children}: DispatchStationDataProviderProps) => {
  const [state, setState] = useState<DispatchStationDataState>({
    ...initialState,
    hasSelectedDispatchStations: (): boolean => {
      return state?.selectedDispatchStations.size > 0;
    },
    addSelectedDispatchStation: (dispatchStation: DispatchStation): void => {
      console.debug("Adding to selected dispatch stations: ", dispatchStation);
      if (!dispatchStation) {
        console.warn("Attempted to add undefined dispatch station to selected dispatch stations");
      } else {
        setState({
          ...state,
          selectedDispatchStations: state.selectedDispatchStations.set(dispatchStation.stationId, dispatchStation)
        });
      }
    },
    removeSelectedDispatchStation: (dispatchStation: DispatchStation): void => {
      console.debug("Removing from selected dispatch stations: ", dispatchStation);
      if (!dispatchStation) {
        console.warn("Attempted to remove undefined dispatch station to selected dispatch stations");
      } else {
        setState({
          ...state,
          selectedDispatchStations: state.selectedDispatchStations.delete(dispatchStation.stationId)
            ? new Map<string, DispatchStation>(state.selectedDispatchStations)
            : state.selectedDispatchStations
        });
      }
    },
    clearSelectedDispatchStations: (): void => {
      console.debug("Clearing selected dispatch stations");
      state.selectedDispatchStations.clear();
      setState({
        ...state
      });
    },
    initialSelectedDispatchStations: (dispatchStations: DispatchStation[]): void => {
      console.debug("Initializing selected dispatch stations: ", dispatchStations);

      dispatchStations.forEach((dispatchStation) => {
        state.selectedDispatchStations.set(dispatchStation.stationId, dispatchStation);
      });
      setState({
        ...state
      });
    }
  });

  return <DispatchStationDataContext.Provider value={state}>{children}</DispatchStationDataContext.Provider>;
};

export default DispatchStationDataProvider;
export {DispatchStationDataContext};
export type {DispatchStationDataState};
