import {Manifest, SearchableSortDirection} from "../../generated/graphql";
import {DragAndDropTargetPayload} from "../../views/JobAssignmentViewReducer";
import {PendingAssignment} from "../../utils/PendingAssignment";
import {ManifestVisibilityState} from "../map/MapVisibilityContext";
import {CardType} from "../../views/JobAssignmentView";
import {Engine} from "json-rules-engine";
import {ColorRank} from "../common/DispatchRulesDataProvider";
import {StopColor} from "./ManifestCardCommonV3";
import {IConfigurationFormValues} from "./card-configuration/ManifestCardConfiguration";

export type ManifestCardListProps = {
  isManifestDetailsDrawerOpen: boolean;
  selectedManifest?: Manifest;
  disabledManifestIds: number[];
  data: Manifest[];
  cardType: CardType;
  sortByColor?: {
    enable: boolean;
    direction: SearchableSortDirection;
  };

  onSelectedManifest(manifest: Manifest, isSelected: boolean): void;
  onDrillDownManifest(manifest: Manifest): void;
  handleUpdateDropTargets(dragAndDropPayload: DragAndDropTargetPayload[]): void;
  pendingActions?: PendingAssignment[];
  visibleManifests: Map<number, ManifestVisibilityState>;
  onLocationMarkerClick(manifest: Manifest): void;
};

export type ManifestCardProps = {
  manifest: Manifest;
  colors?: StopColor[];
  isSelected: boolean;
  isHighlighted?: boolean;
  disabled?: boolean;
  pendingActions: PendingAssignment[] | undefined;
  configurationState: IConfigurationFormValues;
  onSelectedManifest(manifest: Manifest, isSelected: boolean): void;
  onDrillDownManifest(manifest: Manifest): void;
  onLocationMarkerClick(manifest: Manifest): void;
  cardType: CardType;
};

export const getHighestRankedColor = (colors: string[], colorRank: ColorRank[]) => {
  colors.sort((a, b) => {
    const colorA = colorRank.find((rank: ColorRank) => rank.color === a);
    const colorB = colorRank.find((rank: ColorRank) => rank.color === b);

    return colorA && colorB ? colorA.rank - colorB.rank : 0;
  });

  return colors[0];
};

export const getColorIndicator = async (engine: Engine): Promise<any> => {
  try {
    const result = await engine.run();

    if (result.events.length > 0) {
      return result.events[0].params?.color;
    }
  } catch (e) {
    console.error("Error running engine", e);
  }
};
