import {Intent} from "@blueprintjs/core";
import {Tenant} from "./TenantService";
import {getLegacyUserGuid} from "./TenantService";
import {AppToaster} from "../utils/toaster";
import {Pref} from "../generated/graphql";
import {extractSimplePref} from "../providers/PreferenceProvider";
import {TenantPreferences} from "../components/common/Constants";
import {OrderEntryType} from "../components/settings/types/OrderEntryType";

const CURRENT = "orderscreen.cshtml";
const LEGACY = "orderscreen.asp";
const navigateToCmsOrder = (
  tenant: Tenant,
  token: string,
  orderId: number,
  tenantPrefs: Pref[] = [] as Pref[]
): Promise<void> => {
  const orderEntryTypePref = extractSimplePref(tenantPrefs, TenantPreferences.orderEntryType, OrderEntryType.Current);
  return getLegacyUserGuid(token as string)
    .then((res) => {
      const userGuid = res.data as string;
      window.open(
        `${tenant?.legacyCmsUrl}/${tenant?.shortName}/software/orderentryx/${
          OrderEntryType.Legacy === orderEntryTypePref.value ? LEGACY : CURRENT
        }?UserGUID=${userGuid}&OrderID=${orderId}`
      );
    })
    .catch((e2) => {
      AppToaster.show({
        intent: Intent.WARNING,
        icon: "warning-sign",
        message: `Failed to get user from CMS: ${e2}`
      });
      console.error("Error getting legacy user id.", e2);
    });
};

export {navigateToCmsOrder};
