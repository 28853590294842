import {ServiceTypeColorState, initialServiceTypeColorState} from "./ServiceTypeColorStateProvider";
import {UserColor} from "../ColorizedIndicators/types/indicatorLogicType";
import {ServiceTypeColorType} from "./ServiceTypeColor";
import {TabId} from "@blueprintjs/core";
import {isEqual} from "lodash";

type ConfirmSavingPayload = {
  value: boolean;
  redirectFunc?: (redirectParams: TabId) => void;
  redirectParams?: TabId;
};

type ServiceTypeColorReducerAction =
  | {type: "SET_COLOR"; payload: UserColor}
  | {type: "SET_SELECTING_SERVICES"; payload: ServiceTypeColorType}
  | {type: "SET_IS_DATA_CHANGED"; payload: boolean}
  | {type: "SHOW_CONFIRM_WITHOUT_SAVING"; payload: ConfirmSavingPayload}
  | {type: "RESET_STATE"};

const serviceTypeColorReducer = (
  state: ServiceTypeColorState,
  action: ServiceTypeColorReducerAction
): ServiceTypeColorState => {
  switch (action.type) {
    case "SET_COLOR": {
      return {
        ...state,
        selectedColor: action.payload,
        isUserSelected: true
      };
    }

    case "SET_SELECTING_SERVICES": {
      const newSelectedServices = updateSelectedServices(state.selectedServices, action.payload);
      if (newSelectedServices.length === 0) {
        return {...state, selectedServices: newSelectedServices, selectedColor: undefined, isUserSelected: false};
      }
      if (state.isUserSelected) {
        return {...state, selectedServices: newSelectedServices};
      }
      return {
        ...state,
        selectedServices: newSelectedServices,
        selectedColor:
          new Set(newSelectedServices.map((item) => item.color)).size > 1 ? undefined : newSelectedServices[0].color
      };
    }

    case "SET_IS_DATA_CHANGED": {
      if (state.isDataChanged !== action.payload) {
        return {
          ...state,
          isDataChanged: action.payload
        };
      }
      return state;
    }

    case "SHOW_CONFIRM_WITHOUT_SAVING": {
      if (!isEqual(state.confirmWithoutSaving, action.payload)) {
        return {...state, confirmWithoutSaving: {...action.payload}};
      }
      return state;
    }

    case "RESET_STATE": {
      return initialServiceTypeColorState;
    }

    default:
      return state;
  }
};
const updateSelectedServices = (currentServices: ServiceTypeColorType[], updatedService: ServiceTypeColorType) => {
  if (currentServices.some((service) => service.name === updatedService.name)) {
    return currentServices.filter((service) => service.name !== updatedService.name);
  }
  return [...currentServices, updatedService];
};

export default serviceTypeColorReducer;
export type {ServiceTypeColorReducerAction, ConfirmSavingPayload};
