import {Dispatch, createContext, useContext, useReducer} from "react";
import {ListActionButton} from "./ListActionBar";
import listActionBarReducer from "./ListActionBarReducer";

type ListActionBarState = {
  isShowing: boolean;
  buttons: ListActionButton[];
};
const listActionBarInitialState: ListActionBarState = {
  isShowing: false,
  buttons: []
};

const ListActionBarContext = createContext<ListActionBarState>(listActionBarInitialState);
const ListActionBarDispatchContext = createContext<Dispatch<ListActionBarActions>>(() => {
  /* DO Nothing */
});

const useListActionBarState = () => {
  return useContext(ListActionBarContext);
};

const useListActionBarDispatch = () => {
  return useContext(ListActionBarDispatchContext);
};

type ListActionBarProvierProps = {
  children: JSX.Element[] | JSX.Element;
};

const ListActionBarProvider = ({children}: ListActionBarProvierProps) => {
  const [state, dispatch] = useReducer(listActionBarReducer, listActionBarInitialState);

  return (
    <ListActionBarContext.Provider value={state}>
      <ListActionBarDispatchContext.Provider value={dispatch}>{children}</ListActionBarDispatchContext.Provider>
    </ListActionBarContext.Provider>
  );
};

type ListActionBarActions =
  | {type: "SET_BUTTONS"; payload: ListActionButton[]}
  | {type: "TOGGLE_BUTTON_ACTIVE"; payload: {id: string}}
  | {type: "SET_IS_SHOWING"; payload: boolean}
  | {type: "SET_BUTTON_HIDDEN"; payload: {id: string; hidden: boolean}}
  | {type: "SET_BUTTON_DISABLED"; payload: {id: string; disabled: boolean}};

export default ListActionBarProvider;
export type {ListActionBarProvierProps, ListActionBarState, ListActionBarActions};
export {ListActionBarContext, ListActionBarDispatchContext, useListActionBarState, useListActionBarDispatch};
