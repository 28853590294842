import styled from "@emotion/styled";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {CSSProperties, useContext} from "react";
import {DriverEllipse} from "./DriverEllipse";
import {DriverTitle} from "./DriverTitle";
import {DriverLocation, Manifest} from "../../../generated/graphql";
import vehicleIconResolver from "../../vehicles/VehicleIconResolver";
import {PreferenceContext} from "../../../providers/PreferenceProvider";

interface DriverMarkerProps {
  manifest: Manifest;
  $hover?: boolean;
  lat: any;
  lng: any;
  cords: DriverCords;
  onClick?(manifestDriverId: number): void;
  isSelected: boolean;
}

export interface DriverCords extends DriverLocation {
  lat: number;
  lng: number;
}

const width = 42;
const height = 42;

const Marker = styled.div`
  position: relative;
  z-index: 1000;
  width: ${width}px;
  height: ${height}px;
  left: ${-width / 2}px;
  top: ${-width / 2}px;

  cursor: pointer;
`;

const driverEllipseStyle = {
  position: "absolute",
  zIndex: 999,
  left: "-8px",
  top: "-10px"
} as CSSProperties;

export type DriverIconProps = {
  isSelected: boolean;
  color?: string;
};

const DriverIcon = styled(FontAwesomeIcon)<DriverIconProps>`
  position: absolute;
  z-index: 1001;
  left: 9px;
  top: 10px;

  color: ${(props) => (props.isSelected ? "#DD4B3E" : "#161616")};
  --fa-primary-color: #ffffff;
  --fa-secondary-color: ${(props) => (props.isSelected ? "#DD4B3E" : "#161616")};
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
`;

const DriverMarker: React.FC<DriverMarkerProps> = ({manifest, $hover, onClick, isSelected, cords}) => {
  const {tenantPreferences} = useContext(PreferenceContext);

  const resolvedIcon = vehicleIconResolver(manifest.vehicleType.description, tenantPreferences);

  return (
    <Marker
      data-testid={`driver-marker-${manifest.manifestDriverId}`}
      onClick={onClick ? () => onClick(manifest.manifestDriverId) : undefined}
    >
      <DriverTitle driver={manifest.driver} cords={cords} $hover={$hover} />
      <DriverEllipse style={driverEllipseStyle} />
      <DriverIcon size={"2x"} icon={resolvedIcon} isSelected={isSelected} fillOpacity={100} />
    </Marker>
  );
};

export default DriverMarker;
