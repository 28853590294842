import {Icon, Text} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {useCallback, useEffect, useMemo, useState} from "react";
import {ClearInputButton, TChangedDispactGroupState, TGroupOption} from "./create-manifest/CreateManifestModal";

interface SingleSelectProps {
  initGroup: TGroupOption;
  options: TGroupOption[];
  selectedGroup: TGroupOption | undefined;
  changedDispactGroupState?: TChangedDispactGroupState;
  onSelectGroup: (group: TGroupOption) => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  originalGroupId?: number;
  originalText?: string;
  maxHeight?: string;
  maxWidth?: string;
}

const DispatchGroupsSeletor = ({
  initGroup,
  options,
  selectedGroup,
  changedDispactGroupState,
  onSelectGroup,
  onConfirm,
  onCancel,
  originalGroupId,
  originalText,
  maxHeight,
  maxWidth
}: SingleSelectProps) => {
  const [groupQuery, setGroupQuery] = useState<string>("");
  const [initSelectedOptionKey, setInitSelectedOptionKey] = useState<number>(
    Number(changedDispactGroupState?.key ?? initGroup.key)
  );

  const filteredOptions = useMemo(() => {
    const regx = new RegExp(groupQuery.trim().toLocaleLowerCase());
    return options.filter((option) => regx.exec(option.value.toLocaleLowerCase()));
  }, [groupQuery, options]);

  const onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setGroupQuery(query);
  };

  const renderOptionList = useCallback(
    (options: TGroupOption[]) => {
      return options.map((option) => {
        const {key} = option;
        const isSelected = key === selectedGroup?.key || (!selectedGroup && key === initSelectedOptionKey);
        return (
          <Option data-testid="group-item" key={key} onClick={() => onSelectGroup(option)} isSelected={isSelected}>
            <Text ellipsize title={option.value}>
              {option.value}
            </Text>
            {key === originalGroupId && <Text style={{fontStyle: "italic", color: "#797979"}}> {originalText}</Text>}
          </Option>
        );
      });
    },
    [initSelectedOptionKey, onSelectGroup, originalGroupId, originalText, selectedGroup]
  );

  useEffect(() => {
    return () => {
      setGroupQuery("");
    };
  }, [setGroupQuery]);

  useEffect(() => {
    setInitSelectedOptionKey(Number(changedDispactGroupState?.key ?? initGroup.key));
  }, [changedDispactGroupState?.key, initGroup]);

  const showFooter = useMemo(() => Boolean(onCancel || onConfirm), [onCancel, onConfirm]);

  return (
    <Container
      data-testid={"dispatch-groups-selector"}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      showFooter={showFooter}
    >
      <Header>
        <Text>SELECT A NEW GROUP</Text>
        <Search>
          <StyledIcon icon="search" />
          <StyledInput
            data-testid={"change-group-search-input"}
            type="text"
            value={groupQuery}
            placeholder="Search Groups"
            onChange={onChangeFilter}
          />
          {groupQuery && (
            <ClearInputButton
              data-testid={"change-group-clear-input"}
              icon="cross"
              minimal
              onClick={() => setGroupQuery("")}
            />
          )}
        </Search>
      </Header>
      <Body>
        <OptionList data-testid="infinity-scroll-list">{renderOptionList(filteredOptions)}</OptionList>
      </Body>
      {showFooter && (
        <Footer>
          <GroupSelectorButton data-testid="close-change-group-button" onClick={onCancel}>
            Cancel
          </GroupSelectorButton>
          <GroupSelectorButton
            data-testid="confirm-change-group-button"
            onClick={onConfirm}
            disabled={!(selectedGroup && selectedGroup.key !== initSelectedOptionKey)}
          >
            OK
          </GroupSelectorButton>
        </Footer>
      )}
    </Container>
  );
};

export default DispatchGroupsSeletor;

const Container = styled.div<{maxHeight?: string; maxWidth?: string; showFooter: boolean}>`
  font-family: "Roboto", sans-serif;
  height: ${(props) => props.maxHeight ?? "100%"};
  width: ${(props) => props.maxWidth ?? "100%"};
  background-color: white;
  box-shadow: 0px 2px 6px 0px #0000001a;
  border-radius: 4px;
  display: grid;
  grid-template-rows: ${(props) => (props.showFooter ? "90px 1fr 62px" : "90px 1fr")};
  justify-content: space-between;
  overflow: hidden;
`;

const Header = styled.div`
  padding: 10px;
  box-shadow: 0px 1px 2px 0px #0000001a;
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const OptionList = styled.ul`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  text-overflow: ellipsis;
  padding: 0;
  margin: 0;

  ::-webkit-scrollbar-track {
    background-color: #ffffff;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 3px;

  & ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #d9d9d9;
  }

  & ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 30px;
    background-color: #d9d9d9;
  }
`;

const Footer = styled.div`
  padding: 10px;
  background-color: #fcfcfc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 3px 0px #00000026;
  font-size: 16px;
`;

const Option = styled.li<{isSelected: boolean}>`
  border-radius: 4px;
  padding: 5px;
  margin: 5px;
  color: #161616;
  font-size: 14px;
  background-color: ${(props) => (props.isSelected ? "#0038FF33" : "")};
  display: flex;
  justify-content: space-between;

  :hover {
    background-color: #0038ff33;
    cursor: pointer;
  }
`;

const Search = styled.div`
  width: 100%;
  font-family: "Roboto", sans-serif;
  position: relative;
  border-radius: 4px;
  display: flex;
  height: 32px;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px 0px #0000001a inset;
  border: 1px solid #c3c3c373;
`;

const StyledIcon = styled(Icon)`
  color: #a4a5a6;
  padding: 8px 8px 8px 12px;
`;

const StyledInput = styled.input`
  display: inline;
  width: 100%;
  line-height: 16px;
  border: none;
  outline: none;
`;

const GroupSelectorButton = styled.button`
  font-weight: 500;
  line-height: 14px;
  border: none;
  color: #14305a;
  background-color: transparent;
  cursor: pointer;

  span {
    color: #fa545e;
  }

  :disabled {
    opacity: 0.5;
  }
`;
