import {AxiosResponse} from "axios";

export interface UserData {
  profile: UserProfile;
}

export class UserProfile {
  id = "";
  firstName: string;
  lastName: string;
  login?: string;
  email: string;
  phone?: string;
  legacyUserId: string;
  tenantId: string;
  groups: string[];
  persistHandler: (token: string, userValues: UserProfile) => Promise<AxiosResponse>;

  constructor(
    firstName: string,
    lastName: string,
    login: string,
    email: string,
    phone: string,
    legacyUserId: string,
    tenantId: string,
    groups: string[],
    persistHandler: (token: string, userValues: UserProfile) => Promise<AxiosResponse>,
    id = ""
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.login = login;
    this.email = email;
    this.phone = phone;
    this.legacyUserId = legacyUserId;
    this.tenantId = tenantId;
    this.groups = groups;
    this.persistHandler = persistHandler;
  }
}
