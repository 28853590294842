import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Fragment, useContext} from "react";
import styled from "@emotion/styled";
import {jobTypes, stopTypes, additionalStopTypes} from "./JobTypeSelector";
import {Button} from "@blueprintjs/core";
import {getArrayFromField} from "../../../utils/General";
import {ComparisonTypes, IndicatorLogicType} from "./types/indicatorLogicType";
import {TriggerEventType} from "./types/triggerSelectorValues";
import {JobTypes, StopTypes} from "./types/jobTypeSelectorValues";
import {ManifestStatusTypes} from "./types/manifestAttributes";
import {renderStopStatusText} from "./stopStatus.service";
import {FeatureFlagContext} from "../../../providers/FeatureFlagProvider";
import {manifestStatusOptions} from "./ManifestAttributeSelector";

const AttributeKey = styled.span`
  color: #161616;
  font-size: 12px;
`;

const GridItem = styled.div`
  font-size: 12px;
  padding: 6px;
`;

const GridItemText = styled.div`
  line-height: 1.2rem;
`;

const GridRowTextInline = styled.span`
  color: #797979;
`;
const GridRowText = styled(GridItem)`
  color: #797979;
  padding: 6px;
`;

type ColorizedIndicatorCardProps = {
  onEditLogicHandler(logic: IndicatorLogicType): void;
  logic: IndicatorLogicType;
};

export enum ComparisonTexts {
  "SCHEDULEDTIME" = "Scheduled time",
  "LATETIME" = "Late time",
  "CONTINUOUS" = "Continuous",
  "FIRSTSTOP" = "Scheduled first stop",
  "LASTSTOP" = "Scheduled last stop"
}

export const getTimeLogicText = (logic: IndicatorLogicType) => {
  if (!logic.comparison || logic.comparison === ComparisonTypes.Continuous) {
    return "";
  } else if (logic.trigger?.lowerType === logic.trigger?.upperType) {
    return `${logic.trigger?.lowerValue}m - ${logic.trigger?.upperValue}${
      Number(logic.trigger?.upperValue) > 0 ? "m" : ""
    } ${logic.trigger?.lowerType === TriggerEventType.BeforeEvent ? "Before" : "After"}`;
  }

  return `${logic.trigger?.lowerValue}m Before - ${logic.trigger?.upperValue}m After`;
};

export const getComparisonText = (comparision: ComparisonTypes) => {
  switch (comparision) {
    case ComparisonTypes.Continuous:
      return ComparisonTexts.CONTINUOUS;
    case ComparisonTypes.FirstStop:
      return ComparisonTexts.FIRSTSTOP;
    case ComparisonTypes.LastStop:
      return ComparisonTexts.LASTSTOP;
    case ComparisonTypes.ScheduledTime:
      return ComparisonTexts.SCHEDULEDTIME;
    case ComparisonTypes.LateTime:
      return ComparisonTexts.LATETIME;
    default:
      return "";
  }
};

const ColorizedIndicatorsGridRow = ({logic, onEditLogicHandler}: ColorizedIndicatorCardProps) => {
  const {
    colorsDP1664: stopStatusEnabled,
    colorsDP1665: additionalStopTypeEnabled,
    colorsDP1666: manifestStatusEnabled
  } = useContext(FeatureFlagContext);

  const renderStopTypes = additionalStopTypeEnabled ? additionalStopTypes : stopTypes;

  const stopStatus =
    !logic.jobAttributes.stopStatus && logic.type?.stopTypeValue ? [] : logic?.jobAttributes?.stopStatus;
  const serviceType =
    getArrayFromField(logic?.jobAttributes?.service)
      ?.map((service) => service.value)
      .join(", ") || "all";
  const customer =
    getArrayFromField(logic?.jobAttributes?.customer)
      ?.map((customer) => customer.value)
      .join(", ") || "all";
  const vehicleType =
    getArrayFromField(logic?.jobAttributes?.vehicleType)
      ?.map((vehicleType) => vehicleType.value)
      .join(", ") || "all";
  const priority =
    getArrayFromField(logic?.orderPriority)
      ?.map((priority) => priority.value)
      .join(", ") || "all";
  const manifestStatus = manifestStatusOptions.get(logic?.manifestAttributes?.manifestStatus as ManifestStatusTypes);
  const stopType = renderStopTypes.get(logic?.type?.stopTypeValue as StopTypes);
  const site =
    getArrayFromField(logic?.jobAttributes?.site)
      ?.map((site) => site.value)
      .join(", ") || "all";

  const stopStatusText = stopStatus ? renderStopStatusText(stopStatus) : "";
  const timeLogicText = getTimeLogicText(logic);
  const comparisonText = getComparisonText(logic?.comparison as ComparisonTypes);

  return (
    <Fragment>
      <GridRowText>1</GridRowText>
      <GridRowText>
        {logic?.type?.jobTypeValue === JobTypes.UnassignedJobs
          ? jobTypes.get(logic?.type?.jobTypeValue as JobTypes)
          : renderStopTypes.get(logic?.type?.stopTypeValue as StopTypes)}
      </GridRowText>
      <GridItem>
        {stopType && (
          <GridItemText>
            <AttributeKey>Stop Type:</AttributeKey>
            <GridRowTextInline>{` ${stopType}`}</GridRowTextInline>
          </GridItemText>
        )}
        <GridItemText>
          <AttributeKey>Service types:</AttributeKey>
          <GridRowTextInline>{` ${serviceType}`}</GridRowTextInline>
        </GridItemText>
        <GridItemText>
          <AttributeKey>Customer:</AttributeKey>
          <GridRowTextInline>{` ${customer}`}</GridRowTextInline>
        </GridItemText>
        <GridItemText>
          <AttributeKey>Vehicle type:</AttributeKey>
          <GridRowTextInline>{` ${vehicleType}`}</GridRowTextInline>
        </GridItemText>
        {manifestStatusEnabled && (
          <GridItemText>
            <AttributeKey>Priority:</AttributeKey>
            <GridRowTextInline>{` ${priority}`}</GridRowTextInline>
          </GridItemText>
        )}
        <GridItemText>
          <AttributeKey>Site:</AttributeKey>
          <GridRowTextInline>{` ${site}`}</GridRowTextInline>
        </GridItemText>
        {stopStatusEnabled && stopStatusText && (
          <GridItemText>
            <AttributeKey>Stop Status:</AttributeKey>
            <GridRowTextInline>{` ${stopStatusText}`}</GridRowTextInline>
          </GridItemText>
        )}
        {manifestStatusEnabled && manifestStatus && (
          <GridItemText style={{marginTop: "10px"}}>
            <AttributeKey>Manifest status:</AttributeKey>
            <GridRowTextInline>{` ${manifestStatus}`}</GridRowTextInline>
          </GridItemText>
        )}
      </GridItem>
      <GridRowText>{timeLogicText}</GridRowText>
      <GridRowText>{comparisonText}</GridRowText>
      <GridItem>
        <Button minimal onClick={() => onEditLogicHandler(logic)} data-testid="color-logic-item-edit">
          <FontAwesomeIcon icon={solid("pencil")} />
        </Button>
      </GridItem>
    </Fragment>
  );
};

export default ColorizedIndicatorsGridRow;
