import {OptionType} from "../../common/types/optionType";

export const renderManifestOption = (arrayOfValue: string[]): OptionType[] => {
  return arrayOfValue.map((value) => ({
    key: value,
    value: value
  }));
};

export const getMaxHeightOfOptionOrSelection = (
  maxHeight: number,
  heightOfSearchBox: number,
  heightOfFooter: number,
  minusPadding: number
) => {
  return (maxHeight - heightOfSearchBox - heightOfFooter) / 2 - minusPadding;
};
