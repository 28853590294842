import styled from "@emotion/styled";
import {MapPopOutIcon, ZoomAllIcon, ZoomDriversIcon, ZoomInIcon, ZoomJobsIcon, ZoomOutIcon} from "./MapIcons";
import {Button} from "@blueprintjs/core";
import {DEFAULT_ZOOM} from "../AssignmentMapV2";
import {Tooltip2} from "@blueprintjs/popover2";
import {Cords, getMapBounds} from "../MappingService";
import {memo, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {isEmpty} from "lodash";
import {FeatureFlagContext} from "../../../providers/FeatureFlagProvider";
import {AppContext} from "../../../ApplicationContext";

export const reZoom = (map: any) => {
  const currentZoom = map.getZoom();
  if (currentZoom && currentZoom > 15) {
    map.setZoom(15);
  }
};

export const paddingBounds = {
  top: 103,
  right: 63,
  bottom: 74,
  left: 47
};

export type MapReframeProps = {
  driverCords: Cords[];
  jobCords: Cords[];
  stopCords: Cords[];
  routeCords: Cords[];
  maps: any;
  map: any;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  newWindow?: Window;
  usePortal?: boolean;
};

type ReframeButtonProps = {
  dataTestId?: string;
  active?: boolean;
  onClick: () => void;
  icon?: JSX.Element;
  tooltipContent?: string;
  newWindow?: Window;
  usePortal?: boolean;
};

const ReframeButton = ({
  dataTestId,
  active,
  onClick,
  icon,
  tooltipContent,
  newWindow,
  usePortal
}: ReframeButtonProps) => {
  return (
    <Tooltip2
      popoverClassName="map-action-popover"
      content={tooltipContent}
      usePortal={usePortal}
      portalContainer={newWindow?.document.body}
    >
      <StyledButton data-testid={dataTestId} active={active} icon={icon} onClick={onClick} />
    </Tooltip2>
  );
};

const MapReframe = ({
  driverCords,
  jobCords,
  stopCords,
  routeCords,
  maps,
  map,
  onZoomIn,
  onZoomOut,
  newWindow,
  usePortal
}: MapReframeProps) => {
  const [zoomLevel, setZoomLevel] = useState<number>(DEFAULT_ZOOM);
  const {popOutMap: popOutMapPortalEnable} = useContext(FeatureFlagContext);
  const appContext = useContext(AppContext);

  useEffect(() => {
    if (!maps) return;
    maps.event.addListener(map, "zoom_changed", () => {
      const currentZoom = map.getZoom();
      setZoomLevel(currentZoom);
    });
    return () => {
      maps.event.clearListeners(map, "zoom_changed");
    };
  }, [map, maps]);

  const seeDrivers = useCallback(() => {
    if (map && driverCords && !isEmpty(driverCords)) {
      const bounds = getMapBounds(maps, driverCords);
      map.fitBounds(bounds, paddingBounds);
    }
    reZoom(map);
  }, [driverCords, map, maps]);

  const seePackages = useCallback(() => {
    if (map && jobCords && !isEmpty(jobCords)) {
      const bounds = getMapBounds(maps, jobCords);
      map.fitBounds(bounds, paddingBounds);
      reZoom(map);
    }
  }, [jobCords, map, maps]);

  const seeAll = useCallback(() => {
    if (map && maps) {
      const bounds = getMapBounds(
        maps,
        [...jobCords, ...driverCords, ...stopCords, ...routeCords].filter((cord) => cord)
      );
      map.fitBounds(bounds, paddingBounds);
      reZoom(map);
    }
  }, [driverCords, jobCords, map, maps, routeCords, stopCords]);

  const zoomIn = useCallback(() => {
    const currentZoom = map?.getZoom();
    if (currentZoom < 22) {
      map.setZoom(currentZoom + 1);
    }
    if (onZoomIn) onZoomIn();
  }, [map, onZoomIn]);

  const zoomOut = useCallback(() => {
    const currentZoom = map?.getZoom();
    if (currentZoom > 3) {
      map.setZoom(currentZoom - 1);
    }
    if (onZoomOut) onZoomOut();
  }, [map, onZoomOut]);

  const handleOpenMapPortal = useCallback(() => {
    appContext.dispatch({
      type: "SetOpenMapPortal",
      payload: true
    });
  }, [appContext]);

  const showPopOutMapButton = useMemo(
    () => popOutMapPortalEnable && !appContext.appState.isMapPortalOpen,
    [appContext.appState.isMapPortalOpen, popOutMapPortalEnable]
  );
  return (
    <Container data-testid="map-reframe">
      <ReframeContainer>
        {showPopOutMapButton && (
          <ReframeButton
            active={true}
            dataTestId="pop-out-map"
            icon={<MapPopOutIcon />}
            tooltipContent="Pop Out Map"
            onClick={handleOpenMapPortal}
          />
        )}

        <ReframeButton
          dataTestId="see-all"
          active={!isEmpty(jobCords) || !isEmpty(driverCords)}
          icon={<ZoomAllIcon />}
          tooltipContent="Reframe to see All"
          onClick={seeAll}
          newWindow={newWindow}
          usePortal={usePortal}
        />
        <ReframeButton
          dataTestId="see-all-drivers"
          active={!isEmpty(driverCords)}
          icon={<ZoomDriversIcon />}
          tooltipContent="Reframe to see All Drivers"
          onClick={seeDrivers}
          newWindow={newWindow}
          usePortal={usePortal}
        />
        <ReframeButton
          dataTestId="see-all-jobs"
          active={!isEmpty(jobCords)}
          icon={<ZoomJobsIcon />}
          tooltipContent="Reframe to see All Jobs"
          onClick={seePackages}
          newWindow={newWindow}
          usePortal={usePortal}
        />
      </ReframeContainer>
      <ReframeContainer>
        <ReframeButton dataTestId="zoom-in" active={zoomLevel < 22} icon={<ZoomInIcon />} onClick={zoomIn} />
        <ReframeButton dataTestId="zoom-out" active={zoomLevel > 3} icon={<ZoomOutIcon />} onClick={zoomOut} />
      </ReframeContainer>
    </Container>
  );
};

export default memo(MapReframe);

const Container = styled.div`
  position: absolute;
  z-index: 1000;
  bottom: 50px;
  left: max(12px, calc(100% - 52px));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`;

const ReframeContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 10px;
  overflow: hidden;
`;

const StyledButton = styled(Button)`
  width: 40px;
  height: 45px;
  border: 1px solid #ededed !important;
  box-shadow: none !important;

  :hover {
    &.bp4-button.bp4-active {
      background: linear-gradient(180deg, #ededed 0%, #ededed 100%) !important;
    }
  }

  &.bp4-button:not([class*="bp4-intent-"]) {
    background: white !important;

    path {
      fill: #ededed;
    }
  }

  &.bp4-button.bp4-active {
    background: white !important;

    path {
      fill: #161616;
    }
  }
`;
