import styled from "@emotion/styled";
import {VehiclePrefItemType} from "./VehicleIconsSetup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TagInput} from "@blueprintjs/core";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Tooltip2} from "@blueprintjs/popover2";
import {VehicleItemType} from "../../vehicles/VehicleIconResolver";

type VehicleIconSetupItemProps = {
  icon: VehicleItemType;
  updateTenantPrefs: (prefItem: VehiclePrefItemType) => void;
  vehicleIconsTenantPref: VehiclePrefItemType[];
};

const EnterIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 1C2.79086 1 1 2.79086 1 5V11C1 13.2091 2.79086 15 5 15H11C13.2091 15 15 13.2091 15 11V5C15 2.79086 13.2091 1 11 1H5ZM10.76 4C10.76 3.58579 10.4242 3.25 10.01 3.25C9.5958 3.25 9.26001 3.58579 9.26001 4V8.25H5.78521L6.4922 7.52281C6.78094 7.22582 6.77425 6.75099 6.47726 6.46225C6.18027 6.17351 5.70545 6.1802 5.41671 6.47719L3.47226 8.47719C3.18926 8.76828 3.18926 9.23172 3.47226 9.52281L5.41671 11.5228C5.70545 11.8198 6.18027 11.8265 6.47726 11.5377C6.77425 11.249 6.78094 10.7742 6.4922 10.4772L5.78521 9.75H10.01C10.4242 9.75 10.76 9.41421 10.76 9V4Z"
      fill="#9CA3AF"
    />
  </svg>
);

const VehicleIconSetupItem = ({icon, updateTenantPrefs, vehicleIconsTenantPref}: VehicleIconSetupItemProps) => {
  const [values, setValues] = useState<string[]>([]);
  const [showEnterMessage, setShowEnterMessage] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [showError, setShowError] = useState(false);

  const allVehicleIconsTenantPref = useMemo(
    () =>
      vehicleIconsTenantPref
        .map((item) => item.value)
        .flat()
        .map((str) => str.trim().toLowerCase()),
    [vehicleIconsTenantPref]
  );

  useEffect(() => {
    if (icon.customValues.length > 0) {
      setValues(icon.customValues);
    }
  }, [icon.customValues]);

  const onAdd = useCallback(
    (addValues: string[]) => {
      if (addValues.length === 0) {
        setShowError(false);
        setInputValue("");
      } else {
        const inputValue = addValues[0].trim();
        if (allVehicleIconsTenantPref.includes(inputValue.toLocaleLowerCase())) {
          setShowError(true);
        } else {
          const newValues = [...values, inputValue];
          setValues(newValues);
          updateTenantPrefs({id: icon.id, value: newValues});
          setInputValue("");
          setShowError(false);
        }
      }
      setShowEnterMessage(false);
    },
    [allVehicleIconsTenantPref, icon.id, updateTenantPrefs, values]
  );

  const onRemove = useCallback(
    (removeValue) => {
      const newValues = values.filter((value) => value !== removeValue);
      setValues(newValues);
      updateTenantPrefs({id: icon.id, value: newValues});
      setShowError(false);
    },
    [icon.id, updateTenantPrefs, values]
  );

  const onInpuChange = useCallback((e) => {
    setInputValue(e.target.value);
    setShowEnterMessage(Boolean((e.target as HTMLInputElement).value.trim()));
    setShowError(false);
  }, []);

  const onInpuBlur = useCallback(() => {
    setIsFocus(false);
    setShowError(false);
  }, []);

  const onInpuFocus = useCallback((e) => {
    setShowEnterMessage(Boolean((e.target as HTMLInputElement).value.trim()));
    setIsFocus(true);
    setShowError(false);
  }, []);

  return (
    <Container data-testid="vehicle-icon-setup-item" isFocus={isFocus}>
      <Icon data-testid="vehicle-icon-setup-item-icon">
        <VehicleIcon icon={icon.icon} size="lg" />
      </Icon>
      <Value data-testid="vehicle-icon-setup-item-value">
        <Tooltip2
          isOpen={showError}
          position="bottom-left"
          minimal
          intent="danger"
          content={<span data-testid="error-message">This custom string was already used!</span>}
        >
          <TagInput
            values={values}
            onAdd={onAdd}
            onRemove={onRemove}
            addOnBlur={true}
            inputValue={inputValue}
            onInputChange={onInpuChange}
            inputProps={{onFocus: onInpuFocus, onBlur: onInpuBlur}}
            rightElement={
              showEnterMessage ? (
                <EnterMessage>
                  Press enter to add <EnterIcon />
                </EnterMessage>
              ) : undefined
            }
          />
        </Tooltip2>
      </Value>
    </Container>
  );
};

export default VehicleIconSetupItem;

const Container = styled.div<{isFocus: boolean}>`
  display: grid;
  min-height: 52px;
  height: fit-content;
  width: 100%;
  grid-template-columns: 70px auto;
  border-bottom: 1px solid #e5e7eb;
  background-color: ${(props) => (props.isFocus ? "#f9fafb" : "#fff")};

  .bp4-tag-input {
    background-color: ${(props) => (props.isFocus ? "#f9fafb" : "#fff")};
  }

  :hover {
    background-color: #f9fafb;
    .bp4-tag-input {
      background-color: #f9fafb;
    }
  }
`;

const Icon = styled.div`
  padding: 16px 20px;
  display: flex;
  justify-content: center;
`;

const Value = styled.div`
  padding: 8px 20px;

  .bp4-popover2-target {
    width: 100%;
  }

  .bp4-tag-input {
    box-shadow: none;
  }

  .bp4-tag {
    border-radius: 6px;
    background-color: #dbeafe;
    color: #1e40af;
    font-size: 14px;
    padding: 4px 8px;

    .bp4-icon-small-cross {
      color: #3f61cf;
    }
  }
`;

const VehicleIcon = styled(FontAwesomeIcon)`
  --fa-primary-color: #ffffff;
  --fa-secondary-color: #161616;
  --fa-primary-opacity: 1;
  --fa-secondary-opacity: 1;
`;

const EnterMessage = styled.div`
  margin-top: auto;
  margin-bottom: 5px;
  font-size: 12px;
  gap: 4px;
  color: #9ca3af;
  display: flex;
  align-items: center;
`;
