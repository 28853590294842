import {createContext, useContext, useEffect, useMemo, useReducer} from "react";
import {defaultMapIconColors, jobMapIconColors, manifestMapIconColors} from "./LocationMarker";
import {Manifest} from "../../generated/graphql";
import {StopInfoMetadata} from "./stop/StopInfo";
import {mapCompare} from "../../utils/General";
import {isEmpty} from "lodash";
import {DispatchGroupDataContext, DispatchGroupDataState} from "../common/DispatchGroupDataProvider";

export class EntityVisibilityState {
  readonly entityId: number;
  readonly entityType: "Manifest" | "Job";
  visibilityLevel: EntityVisibilityLevel = EntityVisibilityLevel.NONE;
  routeColor?: string;
  error?: string;

  constructor(entityId: number, entityType: "Manifest" | "Job") {
    this.entityId = entityId;
    this.entityType = entityType;
  }

  public getMapKey() {
    return `${this.entityType}_${this.entityId}`;
  }

  /**
   * advance/rotate Job or Manifest's tri-state visibility level and manage available route
   * colors
   * return updated Job or Manifest availableRouteColors
   * @param availableRouteColors
   */
  incrementVisibility(availableRouteColors: string[]) {
    const nextVisibilityLevel = this.getNextVisibilityLevel();
    let resultRouteColors = [...availableRouteColors];
    let newError;
    if (nextVisibilityLevel === EntityVisibilityLevel.ROUTE) {
      if (availableRouteColors.length > 0) {
        this.visibilityLevel = nextVisibilityLevel;
        this.setRouteColor(resultRouteColors[0]);
        resultRouteColors = resultRouteColors.slice(1);
      } else if (this.entityType === "Manifest") {
        newError = `Only ${manifestMapIconColors.length} ${this.entityType}s can be routed simultaneously`;
        if (this.visibilityLevel === EntityVisibilityLevel.MARKER) {
          if (newError === this.error) {
            //we've already presented the error, we want to hide
            newError = undefined;
            this.visibilityLevel = EntityVisibilityLevel.NONE;
          }
        }
      } else {
        this.visibilityLevel = nextVisibilityLevel;
      }
    } else {
      this.visibilityLevel = nextVisibilityLevel;
      if (nextVisibilityLevel === EntityVisibilityLevel.NONE) {
        if (this.routeColor !== undefined && this.routeColor !== defaultMapIconColors) {
          resultRouteColors.push(this.routeColor);
          this.setRouteColor(undefined);
        }
      }
    }
    this.error = newError;
    return resultRouteColors;
  }

  setRouteColor(color: string | undefined): void {
    this.routeColor = color;
  }

  getNextVisibilityLevel(): EntityVisibilityLevel {
    return this.isRouted() ? EntityVisibilityLevel.NONE : this.visibilityLevel + 1;
  }

  toVisibilityAttributes(): MapVisibilityAttributes {
    console.debug("toVisibilityAttributes ", this);
    return {
      visible: this.visibilityLevel !== EntityVisibilityLevel.NONE,
      color: this.routeColor,
      error: this.error
    };
  }

  isVisible(): boolean {
    return this.visibilityLevel !== EntityVisibilityLevel.NONE;
  }

  isRouted(): boolean {
    return this.visibilityLevel === EntityVisibilityLevel.ROUTE;
  }
}

export class ManifestVisibilityState extends EntityVisibilityState {
  hasLocationData: boolean | undefined;
  driverId: number;

  constructor(entityId: number, driverId: number, hasLocationData?: boolean) {
    super(entityId, "Manifest");
    this.driverId = driverId;
    this.hasLocationData = hasLocationData;
  }

  getNextVisibilityLevel(): EntityVisibilityLevel {
    if (this.hasLocationData) {
      return super.getNextVisibilityLevel();
    }
    if (this.visibilityLevel === EntityVisibilityLevel.NONE) {
      return EntityVisibilityLevel.ROUTE;
    }
    return EntityVisibilityLevel.NONE;
  }
}

export enum EntityVisibilityLevel {
  NONE,
  MARKER,
  ROUTE
}

interface AvailableRouteColors {
  jobs: string[];
  manifests: string[];
}

export type MapVisibilityState = {
  jobs: Map<number, EntityVisibilityState>;
  availableRouteColors: AvailableRouteColors;
  manifests: Map<number, ManifestVisibilityState>;
  isShowAllAvailableDrivers: boolean;
  isJobPanelLoaded?: boolean;
  isViewGestured?: boolean;
  unassignedJobVisibilityType: UnassignedJobsVisibilityTypes;
};

export type MapVisibilityAttributes = {
  visible: boolean;
  color?: string;
  error?: string;
};

export type MapVisibilityAction =
  | {type: "IncrementJobVisibility"; jobId: number}
  | {type: "IncrementManifestVisibility"; manifest: Manifest}
  | {type: "SetManifestsWithLocation"; manifests: Manifest[]}
  | {type: "ToggleAllAvailableDrivers"; isShowAllAvailableDrivers: boolean}
  | {type: "FlagBadAddress"; stopInfo: StopInfoMetadata}
  | {type: "SetAllUnassignedJobsVisibility"; jobIds: number[]}
  | {type: "ClearAllMapVisibility"}
  | {type: "SetJobPanelLoaded"; isLoaded: boolean}
  | {type: "ClearJobVisibility"; jobId: number}
  | {type: "SetIsViewGestured"; payload: boolean}
  | {type: "SetUnassignedJobsVisibilityType"; visibilityType: UnassignedJobsVisibilityTypes};

const mapVisibilityReducer = (state: MapVisibilityState, action: MapVisibilityAction): MapVisibilityState => {
  switch (action.type) {
    case "IncrementJobVisibility": {
      return incrementJobVisibility(state, action.jobId);
    }
    case "IncrementManifestVisibility": {
      return incrementManifestVisibility(state, action.manifest);
    }
    case "SetManifestsWithLocation": {
      return setManifestsWithLocation(state, action.manifests);
    }
    case "ToggleAllAvailableDrivers": {
      return toggleAllAvailableDrivers(state, action.isShowAllAvailableDrivers);
    }
    case "FlagBadAddress": {
      return flagBadAddress(state, action.stopInfo);
    }

    case "SetAllUnassignedJobsVisibility": {
      if (action.jobIds.length === 0) {
        return clearAllJobsVisibility(state);
      }

      Array.from(new Map(state.jobs).values()).forEach((job) => {
        if (!action.jobIds.includes(job.entityId)) {
          if (job.isRouted()) {
            state = incrementJobVisibility(state, job.entityId);
          }
          state.jobs.delete(job.entityId);
        }
      });

      return {
        ...action.jobIds
          .filter((jobId) => !state.jobs.get(jobId))
          .reduce((acc, jobId) => incrementJobVisibility(acc, jobId), state)
      };
    }
    case "SetUnassignedJobsVisibilityType": {
      return {
        ...state,
        unassignedJobVisibilityType: action.visibilityType,
        isViewGestured: false
      };
    }
    case "ClearAllMapVisibility": {
      clearAllDriversVisibility(state);
      return {
        ...state,
        jobs: new Map(),
        availableRouteColors: {
          jobs: jobMapIconColors,
          manifests: manifestMapIconColors
        },
        isShowAllAvailableDrivers: false,
        unassignedJobVisibilityType: UnassignedJobsVisibilityTypes.NONE,
        isViewGestured: false
      };
    }

    case "SetJobPanelLoaded": {
      return {
        ...state,
        isJobPanelLoaded: action.isLoaded,
        isViewGestured: false
      };
    }

    case "ClearJobVisibility": {
      const job = state.jobs.get(action.jobId);
      if (!job) return state;
      if (job.isRouted()) {
        return incrementJobVisibility(state, job.entityId);
      } else {
        state.jobs.delete(job.entityId);
        return state;
      }
    }
    case "SetIsViewGestured": {
      return {...state, isViewGestured: action.payload};
    }

    default:
      return state;
  }
};
const clearAllJobsVisibility = (state: MapVisibilityState): MapVisibilityState => {
  return {
    ...state,
    jobs: new Map(),
    availableRouteColors: {
      ...state.availableRouteColors,
      jobs: jobMapIconColors
    }
  };
};
const incrementJobVisibility = (state: MapVisibilityState, jobId: number): MapVisibilityState => {
  const updated = new Map(state.jobs);
  let jobState = updated.get(jobId);

  if (jobState === undefined) {
    jobState = new EntityVisibilityState(jobId, "Job");
    updated.set(jobId, jobState);
  }
  let colors = jobState.incrementVisibility(state.availableRouteColors.jobs);

  const jobsHasRouteLevel = Array.from(updated.values()).filter((value) => value.isRouted());

  if (jobsHasRouteLevel.length > jobMapIconColors.length) {
    updated.forEach((job) => job.isRouted() && job.setRouteColor(defaultMapIconColors));
  }

  if (
    jobsHasRouteLevel.length === jobMapIconColors.length &&
    jobsHasRouteLevel.some((job) => job.routeColor === defaultMapIconColors)
  ) {
    colors = [...jobMapIconColors];
    updated.forEach((job) => {
      if (job.isRouted()) {
        job.setRouteColor(colors[0]);
        colors.shift();
      }
    });
  }

  updated.forEach((job) => !job.isVisible() && updated.delete(job.entityId));

  const availableColors = {...state.availableRouteColors, jobs: colors};
  return {...state, jobs: updated, availableRouteColors: availableColors};
};

const incrementManifestVisibility = (state: MapVisibilityState, manifest: Manifest): MapVisibilityState => {
  const updated = new Map(state.manifests);
  let manifestState = updated.get(manifest.manifestDriverId);
  if (manifestState === undefined) {
    manifestState = new ManifestVisibilityState(manifest.manifestDriverId, manifest.driver.driverId);
    updated.set(manifest.manifestDriverId, manifestState);
  }
  const priorVisibility = manifestState.visibilityLevel;
  const colors = manifestState.incrementVisibility(state.availableRouteColors.manifests);
  const availableColors = {...state.availableRouteColors, manifests: colors};
  let newShowAllDrivers = state.isShowAllAvailableDrivers;
  if (
    manifestState.hasLocationData &&
    priorVisibility !== manifestState.visibilityLevel &&
    !manifestState.isVisible()
  ) {
    newShowAllDrivers = false;
  }
  return {
    ...state,
    isShowAllAvailableDrivers: newShowAllDrivers,
    manifests: updated,
    availableRouteColors: availableColors
  };
};

const setManifestsWithLocation = (state: MapVisibilityState, manifests: Manifest[]): MapVisibilityState => {
  const updated = new Map(state.manifests);
  const manifestDriverIds = manifests.map((m) => m.manifestDriverId);
  updated.forEach((v, k) => (v.hasLocationData = k in manifestDriverIds));
  manifests.forEach((manifest) => {
    let manifestState = updated.get(manifest.manifestDriverId);
    if (manifestState === undefined) {
      manifestState = new ManifestVisibilityState(manifest.manifestDriverId, manifest.driver.driverId, true);
      updated.set(manifest.manifestDriverId, manifestState);
      if (state.isShowAllAvailableDrivers) {
        manifestState.incrementVisibility([]);
      }
    } else {
      const existing = manifestState.hasLocationData;
      manifestState.hasLocationData = true;
      if (existing === undefined && state.isShowAllAvailableDrivers) {
        manifestState.incrementVisibility([]);
      }
    }
  });

  return mapCompare<number, ManifestVisibilityState>(state.manifests, updated)
    ? state
    : {
        ...state,
        manifests: updated
      };
};

const toggleAllAvailableDrivers = (
  state: MapVisibilityState,
  isShowAllAvailableDrivers: boolean
): MapVisibilityState => {
  const updated = new Map(state.manifests);
  updated.forEach((v) => {
    if (isShowAllAvailableDrivers) {
      if (!v.isVisible() && v.hasLocationData) {
        v.visibilityLevel = EntityVisibilityLevel.MARKER;
      }
    } else if (v.isVisible() && !v.isRouted()) {
      v.visibilityLevel = EntityVisibilityLevel.NONE;
    }
  });
  return {...state, isViewGestured: false, manifests: updated, isShowAllAvailableDrivers: isShowAllAvailableDrivers};
};

const clearAllDriversVisibility = (state: MapVisibilityState): void => {
  state.manifests.forEach((v) => {
    if (v.isRouted()) {
      v.incrementVisibility(state.availableRouteColors.manifests);
    } else {
      v.visibilityLevel = EntityVisibilityLevel.NONE;
    }
  });
};

const flagBadAddress = (state: MapVisibilityState, stopInfo: StopInfoMetadata): MapVisibilityState => {
  const updated = new Map(state.manifests);
  const manifestDriverId = Number(stopInfo.key.substring("Manifest_".length));
  const manifestState = updated.get(manifestDriverId);

  if (manifestState != undefined) {
    manifestState.error = "Invalid address in route";
    const colors = manifestState.incrementVisibility(state.availableRouteColors.manifests);
    const availableColors = {...state.availableRouteColors, manifests: colors};
    if (manifestState.routeColor !== undefined) {
      colors.push(manifestState.routeColor);
      manifestState.routeColor = undefined;
    }

    return {
      ...state,
      manifests: updated,
      availableRouteColors: availableColors
    };
  }
  return {
    ...state
  };
};

export enum UnassignedJobsVisibilityTypes {
  PICK_UP = "P",
  DELIVERIES = "D",
  NONE = "None"
}

const defaultValues: MapVisibilityState = {
  jobs: new Map(),
  manifests: new Map(),
  isShowAllAvailableDrivers: true,
  availableRouteColors: {
    jobs: jobMapIconColors,
    manifests: manifestMapIconColors
  },
  unassignedJobVisibilityType: UnassignedJobsVisibilityTypes.NONE
};

const initialState = {
  state: defaultValues,
  dispatch: () => {
    /**/
  }
};

export const MapVisibilityContext = createContext<{
  state: MapVisibilityState;
  dispatch: React.Dispatch<MapVisibilityAction>;
}>(initialState);

interface Props {
  children: React.ReactNode;
}

export const MapVisibilityProvider = ({children}: Props) => {
  const [state, dispatch] = useReducer(mapVisibilityReducer, defaultValues);
  const {jobGroups} = useContext<DispatchGroupDataState>(DispatchGroupDataContext);

  useEffect(() => {
    if (!isEmpty(jobGroups)) {
      dispatch({
        type: "SetIsViewGestured",
        payload: false
      });
    }
  }, [jobGroups]);

  const value = useMemo(() => ({state, dispatch}), [state, dispatch]);

  return <MapVisibilityContext.Provider value={value}>{children}</MapVisibilityContext.Provider>;
};

export const useMapVisibilityContext = () => useContext(MapVisibilityContext);

export interface RoutedEntityVisibilities {
  jobs: EntityVisibilityState[];
  manifests: ManifestVisibilityState[];
}

export const getRoutedEntities = <T extends EntityVisibilityState>(entityMap: Map<number, T>) => {
  return Array.from(entityMap.values()).filter((vs) => vs.isRouted());
};

export const getRouted = (mapVisibilityState: MapVisibilityState): RoutedEntityVisibilities => {
  return {
    jobs: getRoutedEntities(mapVisibilityState.jobs),
    manifests: getRoutedEntities(mapVisibilityState.manifests)
  };
};
