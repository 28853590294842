import {Intent} from "@blueprintjs/core";
import {RowDropZoneParams} from "@ag-grid-community/core";
import {IConfigCatClient, User} from "configcat-react";
import {SizeUnit} from "react-spaces/dist/core-types";
import {CardType} from "./JobAssignmentView";
import {Driver, Manifest, SearchableSortDirection} from "../generated/graphql";
import {AppToaster} from "../utils/toaster";
import _, {isEqual} from "lodash";
import {ManifestFilterState, ManifestFilterType} from "../components/manifest/ManifestFilterState";
import {DateRange} from "@blueprintjs/datetime";

export type PollingOptions = {
  startPolling(pollingInterval: number): void;
  stopPolling(): void;
  pollingInterval: number;
};

type ManifestSortOptions = {
  property: string;
  sortDirection: SearchableSortDirection;
};

type FeatureFlag = {
  client: IConfigCatClient;
  user: User;
  lastUpdated: number;
};

type ManifestDetailsViewState = {
  manifestDriverId: number | undefined;
  driverId: number | undefined;
  isDrawerOpen: boolean;
};

type JobAssignmentViewState = {
  manifestFilters: ManifestFilterState;
  manifestQuery?: string;
  manifestSort: ManifestSortOptions;
  jobPanelSize: SizeUnit;
  manifestPanelSize: SizeUnit;
  manifestDetailsState: ManifestDetailsViewState;
  manifestViewType: CardType;
  selectedCreateManifests: Driver[];
  featureFlag: FeatureFlag;
  dragAndDropTargets: DragAndDropTargetPayload[];
  currentDragAndDropTargets: RowDropZoneParams[];
  showingJobIds: number[];
  totalJobIds: number;
  unassignedJobsPage: number;
  assignmentTotalJobsNumbers: string[];
  totalJobsNumberAndJobsIdOnPages: {jobId: number; jobNumber: string}[];
  sortByColor: {
    enable: boolean;
    direction: SearchableSortDirection;
  };
};

type OpenManifestDetailsPayload = {
  manifestDriverId: number | undefined;
  driverId: number | undefined;
};

type CreateManifestPayload = {
  drivers: Driver[];
  mutate: any;
  date: string;
};

type DragAndDropTargetPayload = {
  element: HTMLElement;
  manifest: Manifest;
};

export type ManifestFilterTypePayload = {
  filterType: ManifestFilterType;
  value: string | number | Date | DateRange | undefined;
};

type SetShowingJobIdsPayload = {
  jobIds: number[];
  total: number;
};

export enum JobViewActionTypes {
  SET_JOB_PANEL_SIZE = "SetJobPanelSize",
  SET_MANIFEST_PANEL_SIZE = "SetManifestPanelSize",
  SET_MANIFEST_VIEW_TYPE = "SetManifestViewType",
  SET_MANIFEST_SORT = "SetManifestSort",
  SET_MANIFEST_DETAILS_DRAWER_OPEN = "SetManifestDetailsDrawerOpen",
  CLEAR_MANIFEST_DETAILS_STATE = "ClearManifestDetailsState",
  SET_SELECTED_CREATE_MANIFEST = "SetSelectedCreateManifest",
  CLEAR_SELECTED_CREATE_MANIFESTS = "ClearSelectedCreateManifests",
  CREATE_MANIFEST = "CreateManifest",
  ADD_MANIFEST_FILTER = "AddManifestFilter",
  REMOVE_MANIFEST_FILTER = "RemoveManifestFilter",
  REPLACE_MANIFEST_FILTER = "ReplaceManifestFilter",
  CLEAR_MANIFEST_FILTER = "ClearManifestFilter",
  SET_FEATURE_FLAG = "SetFeatureFlag",
  SET_DRAG_AND_DROP_TARGETS = "SetDragAndDropTargets",
  SET_CURRENT_DRAG_AND_DROP_TARGETS = "SetCurrentDragAndDropTargets",
  SET_MANIFEST_QUERY = "SetManifestQuery",
  SET_SHOWING_JOBIDS = "SetShowingJobIds",
  SET_UNASSIGNED_JOBS_PAGE = "SetUnassignedJobsPage",
  SET_JOBS_NUMBER_ON_ALL_ASSIGNMENTS = "SetJobsNumberOnAllAssignments",
  SET_CURRENT_JOBS_IDS_AND_NUMBERS_ON_PAGES = "SetCurrentJobsIdsAndNumbersOnPages",
  SET_SORT_BY_COLOR = "SetSortByColor"
}

type JobViewActions =
  | {type: JobViewActionTypes.SET_JOB_PANEL_SIZE; payload: SizeUnit}
  | {type: JobViewActionTypes.SET_MANIFEST_PANEL_SIZE; payload: SizeUnit}
  | {type: JobViewActionTypes.SET_MANIFEST_VIEW_TYPE; payload: CardType}
  | {type: JobViewActionTypes.SET_MANIFEST_SORT; payload: ManifestSortOptions}
  | {type: JobViewActionTypes.SET_MANIFEST_DETAILS_DRAWER_OPEN; payload: OpenManifestDetailsPayload}
  | {type: JobViewActionTypes.CLEAR_MANIFEST_DETAILS_STATE}
  | {type: JobViewActionTypes.SET_SELECTED_CREATE_MANIFEST; payload: Driver}
  | {type: JobViewActionTypes.CLEAR_SELECTED_CREATE_MANIFESTS}
  | {type: JobViewActionTypes.CREATE_MANIFEST; payload: CreateManifestPayload}
  | {type: JobViewActionTypes.ADD_MANIFEST_FILTER; payload: ManifestFilterTypePayload | ManifestFilterTypePayload[]}
  | {type: JobViewActionTypes.REMOVE_MANIFEST_FILTER; payload: ManifestFilterTypePayload}
  | {type: JobViewActionTypes.REPLACE_MANIFEST_FILTER; payload: ManifestFilterTypePayload}
  | {type: JobViewActionTypes.CLEAR_MANIFEST_FILTER; payload: ManifestFilterType | ManifestFilterType[]}
  | {type: JobViewActionTypes.SET_FEATURE_FLAG; payload: FeatureFlag}
  | {type: JobViewActionTypes.SET_DRAG_AND_DROP_TARGETS; payload: DragAndDropTargetPayload[]}
  | {type: JobViewActionTypes.SET_CURRENT_DRAG_AND_DROP_TARGETS; payload: RowDropZoneParams[]}
  | {type: JobViewActionTypes.SET_MANIFEST_QUERY; payload: string}
  | {type: JobViewActionTypes.SET_SHOWING_JOBIDS; payload: SetShowingJobIdsPayload}
  | {type: JobViewActionTypes.SET_UNASSIGNED_JOBS_PAGE; payload: number}
  | {type: JobViewActionTypes.SET_JOBS_NUMBER_ON_ALL_ASSIGNMENTS; payload: string}
  | {type: JobViewActionTypes.SET_CURRENT_JOBS_IDS_AND_NUMBERS_ON_PAGES; payload: {jobId: number; jobNumber: string}[]}
  | {
      type: JobViewActionTypes.SET_SORT_BY_COLOR;
      payload: {
        enable: boolean;
        direction: SearchableSortDirection;
      };
    };

const JobAssignmentViewReducer = (state: JobAssignmentViewState, action: JobViewActions): JobAssignmentViewState => {
  console.debug("JobAssignmentViewReducer: ", action);
  switch (action.type) {
    case JobViewActionTypes.SET_JOB_PANEL_SIZE: {
      return {
        ...state,
        jobPanelSize: action.payload
      };
    }
    case JobViewActionTypes.SET_MANIFEST_PANEL_SIZE: {
      return {
        ...state,
        manifestPanelSize: action.payload
      };
    }
    case JobViewActionTypes.SET_MANIFEST_DETAILS_DRAWER_OPEN: {
      const newDetailState = {
        manifestDriverId: action.payload.manifestDriverId,
        isDrawerOpen: true,
        driverId: action.payload.driverId
      } as ManifestDetailsViewState;
      if (!_.isEqual(state.manifestDetailsState, newDetailState)) {
        return {
          ...state,
          manifestDetailsState: newDetailState
        };
      }
      return state;
    }
    case JobViewActionTypes.CLEAR_MANIFEST_DETAILS_STATE: {
      const newDetailState = {
        manifestDriverId: undefined,
        isDrawerOpen: false,
        driverId: undefined
      } as ManifestDetailsViewState;

      return {
        ...state,
        manifestDetailsState: newDetailState
      };
    }
    case JobViewActionTypes.SET_MANIFEST_VIEW_TYPE: {
      return {
        ...state,
        manifestViewType: action.payload
      };
    }
    case JobViewActionTypes.SET_MANIFEST_SORT: {
      return {
        ...state,
        manifestSort: action.payload
      };
    }
    case JobViewActionTypes.SET_SELECTED_CREATE_MANIFEST: {
      const index = state.selectedCreateManifests.findIndex((item) => item.driverId === action.payload.driverId);
      let selection = [];
      if (index > -1) {
        selection = state.selectedCreateManifests.filter((driver) => driver.driverId !== action.payload.driverId);
      } else {
        selection = [...state.selectedCreateManifests, action.payload];
      }
      return {
        ...state,
        selectedCreateManifests: selection
      };
    }
    case JobViewActionTypes.CLEAR_SELECTED_CREATE_MANIFESTS: {
      return {
        ...state,
        selectedCreateManifests: []
      };
    }
    case JobViewActionTypes.CREATE_MANIFEST: {
      const drivers = action.payload.drivers;
      const manifestDate = action.payload.date;

      const promises: Promise<any>[] = [];

      drivers.forEach((driver) => {
        promises.push(
          action.payload.mutate({
            variables: {
              driverId: driver.driverId,
              siteId: driver.siteId,
              driverCode: driver.driverCode,
              dispatchGroupId: driver.dispatchGroupId,
              vehicleTypeId: driver.vehicleTypeId,
              startZone: driver.startZone,
              startZip: driver.startZip,
              endZip: driver.endZip,
              autoSend: driver.autoSend,
              autoAccept: driver.autoAccept,
              manifestDate: manifestDate
            }
          })
        );
      });

      const driversListMsg = drivers.map((x) => `${x.driverCode}-${x.name}`).join(", ");
      Promise.allSettled(promises)
        .then(() => {
          AppToaster.show({
            intent: Intent.SUCCESS,
            icon: "tick",
            message: `Created${drivers.length > 1 ? "" : " a"} Manifest${
              drivers.length > 1 ? "s" : ""
            } for ${driversListMsg}`
          });
        })
        .catch((e: any) => {
          console.error(`Create Manifest Failed for Drivers Error: ${e}`);
          AppToaster.show({
            intent: Intent.DANGER,
            icon: "warning-sign",
            message: `Could not create${drivers.length > 1 ? "" : " a"} Manifest${
              drivers.length > 1 ? "s" : ""
            } for ${driversListMsg}`
          });
        });

      return {
        ...state,
        selectedCreateManifests: []
      };
    }

    case JobViewActionTypes.ADD_MANIFEST_FILTER: {
      if (Array.isArray(action.payload)) {
        return {
          ...state,
          manifestFilters: state.manifestFilters.addManifestFilterValues(action.payload)
        };
      }
      return {
        ...state,
        manifestFilters: state.manifestFilters.addManifestFilterValue(action.payload.value, action.payload.filterType)
      };
    }
    case JobViewActionTypes.REMOVE_MANIFEST_FILTER: {
      return {
        ...state,
        manifestFilters: state.manifestFilters.removeManifestFilterValue(
          action.payload.value,
          action.payload.filterType
        )
      };
    }
    case JobViewActionTypes.CLEAR_MANIFEST_FILTER: {
      if (Array.isArray(action.payload)) {
        return {
          ...state,
          manifestFilters: state.manifestFilters.clearManifestFilterTypes(action.payload)
        };
      }
      return {
        ...state,
        manifestFilters: state.manifestFilters.clearManifestFilterType(action.payload)
      };
    }
    case JobViewActionTypes.REPLACE_MANIFEST_FILTER: {
      return {
        ...state,
        manifestFilters: state.manifestFilters.replaceManifestFilterValue(
          action.payload.value,
          action.payload.filterType
        )
      };
    }
    case JobViewActionTypes.SET_FEATURE_FLAG: {
      return {
        ...state,
        featureFlag: action.payload
      };
    }
    case JobViewActionTypes.SET_DRAG_AND_DROP_TARGETS: {
      return {
        ...state,
        dragAndDropTargets: action.payload
      };
    }
    case JobViewActionTypes.SET_CURRENT_DRAG_AND_DROP_TARGETS: {
      if (!_.isEqual(JSON.stringify(state.currentDragAndDropTargets), JSON.stringify(action.payload))) {
        return {
          ...state,
          currentDragAndDropTargets: action.payload
        };
      } else {
        return state;
      }
    }
    case JobViewActionTypes.SET_MANIFEST_QUERY: {
      return {
        ...state,
        manifestQuery: action.payload
      };
    }
    case JobViewActionTypes.SET_SHOWING_JOBIDS: {
      if (isEqual(state.showingJobIds, action.payload.jobIds)) {
        return state;
      }

      return {
        ...state,
        showingJobIds: action.payload.jobIds,
        totalJobIds: action.payload.total
      };
    }
    case JobViewActionTypes.SET_UNASSIGNED_JOBS_PAGE: {
      return {
        ...state,
        unassignedJobsPage: action.payload
      };
    }

    case JobViewActionTypes.SET_JOBS_NUMBER_ON_ALL_ASSIGNMENTS: {
      if (state.assignmentTotalJobsNumbers.includes(action.payload)) {
        const remainingJobsNumber = state.assignmentTotalJobsNumbers
          .slice()
          .filter((jobNumber) => jobNumber !== action.payload);

        return {
          ...state,
          assignmentTotalJobsNumbers: remainingJobsNumber
        };
      } else {
        const newJobsNumber = [...state.assignmentTotalJobsNumbers, action.payload];

        return {
          ...state,
          assignmentTotalJobsNumbers: newJobsNumber
        };
      }
    }
    case JobViewActionTypes.SET_CURRENT_JOBS_IDS_AND_NUMBERS_ON_PAGES: {
      return {
        ...state,
        totalJobsNumberAndJobsIdOnPages: action.payload
      };
    }
    case JobViewActionTypes.SET_SORT_BY_COLOR: {
      return {
        ...state,
        sortByColor: action.payload
      };
    }
    default:
      return state;
  }
};

export default JobAssignmentViewReducer;
export type {JobViewActions, JobAssignmentViewState, ManifestSortOptions, FeatureFlag, DragAndDropTargetPayload};
