import {ICellRendererParams} from "@ag-grid-community/core";
import styled from "@emotion/styled";
import {AgJob} from "../../job/JobPanel";
import {EntityVisibilityState, useMapVisibilityContext} from "../../map/MapVisibilityContext";
import {LocationMarker} from "../../map/LocationMarker";
import MapIconTooltip from "../MapIconTooltip";

const MapIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const MapIconCellRenderer = (props: ICellRendererParams<AgJob, number>) => {
  const {state: mapVisibiltyState, dispatch: mapVisibilityDispatch} = useMapVisibilityContext();
  const jobId = props.value;
  const visibilityState = mapVisibiltyState.jobs.get(jobId) ?? new EntityVisibilityState(jobId, "Job");
  return (
    <MapIconTooltip isOpen={visibilityState.error !== undefined} text={visibilityState.error}>
      <MapIconContainer
        data-testid={`map-icon-job-${jobId}`}
        ref={(ref) => {
          if (!ref) return;
          ref.onclick = (e: MouseEvent) => {
            e.stopPropagation();
            mapVisibilityDispatch({type: "IncrementJobVisibility", jobId: jobId});
            mapVisibilityDispatch({type: "SetIsViewGestured", payload: false});
          };
        }}
      >
        <LocationMarker isVisible={visibilityState.isVisible()} color={visibilityState.routeColor} />
      </MapIconContainer>
    </MapIconTooltip>
  );
};

export default MapIconCellRenderer;
