type JobStatus = {
  code: string | string[];
  name: string;
  color: string;
};

enum JobStatusEnum {
  Assigned = "assigned",
  Completed = "completed",
  New = "new",
  Deleted = "deleted",
  Other = "other"
}

const jobStatuses: {[key in JobStatusEnum]: {name: string; code: string | string[]; color: string}} = {
  [JobStatusEnum.Assigned]: {
    name: "Assigned",
    code: "A",
    color: "#2d72d2"
  },
  [JobStatusEnum.Completed]: {
    name: "Completed",
    code: "C",
    color: "#32A467FF"
  },
  [JobStatusEnum.New]: {
    name: "New",
    code: "N",
    color: "#7CDDDD"
  },
  [JobStatusEnum.Deleted]: {
    name: "Deleted",
    code: ["X", "Z"],
    color: "#EC9A3C"
  },
  [JobStatusEnum.Other]: {
    name: "Other",
    code: ["I", "P", "V", "E", "T"],
    color: "#ac2f33"
  }
};

const jobStatusResolver = (code: string): JobStatus => {
  const unknown = {code: "U", name: code} as JobStatus;
  for (const key in jobStatuses) {
    const value = jobStatuses[key as JobStatusEnum];
    if (value.code.includes(code)) {
      return value as JobStatus;
    }
  }
  return unknown;
};

export {jobStatusResolver, jobStatuses};
export type {JobStatus};
