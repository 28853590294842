import {ReactElement, useMemo} from "react";
import styled from "@emotion/styled";
import {Icon} from "@blueprintjs/core";

interface ManifestTagProps {
  icon?: ReactElement;
  text: string;
  hasCloseIcon: boolean;
  isSelected: boolean;
  onClick: () => void;
  onClickCloseIcon?: () => void;
}

export const ManifestTag = ({icon, text, isSelected, hasCloseIcon, onClickCloseIcon, onClick}: ManifestTagProps) => {
  const onContainerClick = () => {
    if (isSelected && hasCloseIcon) return;
    return onClick();
  };
  const onRemoveClick = () => {
    if (onClickCloseIcon) {
      return onClickCloseIcon();
    }
  };
  const showPointer = useMemo(() => {
    return hasCloseIcon ? !isSelected : true;
  }, [hasCloseIcon, isSelected]);

  return (
    <StyledManifestTagContainer
      data-testid="manifest-tag-container"
      isSelected={isSelected}
      showPointer={showPointer}
      onClick={onContainerClick}
    >
      {icon}
      <StyledManifestTagText data-testid="manifest-tag-text" isSelected={isSelected}>
        {text}
      </StyledManifestTagText>
      {hasCloseIcon && isSelected && (
        <Icon
          data-testid="manifest-tag-close-icon"
          style={{cursor: "pointer"}}
          icon="cross"
          size={16}
          color={"#1E40AF"}
          onClick={onRemoveClick}
        />
      )}
    </StyledManifestTagContainer>
  );
};

const StyledManifestTagContainer = styled.div<{isSelected: boolean; showPointer: boolean}>`
  font-size: 14px;
  border-radius: 6px;
  padding: 4px 8px;
  background-color: ${(props) => (props.isSelected ? "#DBEAFE" : "#F3F4F6")};
  cursor: ${(props) => (props.showPointer ? "pointer" : "default")};

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const StyledManifestTagText = styled.p<{isSelected: boolean}>`
  padding: 0;
  margin: 0;
  color: ${(props) => (props.isSelected ? "#1E40AF" : "#1F2937")};
`;
