import {createContext, useReducer} from "react";
import serviceTypeColorReducer, {ConfirmSavingPayload, ServiceTypeColorReducerAction} from "./ServiceTypeColorReducer";
import {UserColor} from "../ColorizedIndicators/types/indicatorLogicType";
import {ServiceTypeColorType} from "./ServiceTypeColor";

type ServiceTypeColorState = {
  selectedServices: ServiceTypeColorType[];
  selectedColor: UserColor | undefined;
  isDataChanged: boolean;
  isUserSelected: boolean;
  confirmWithoutSaving: ConfirmSavingPayload;
};

type ServiceTypeColorStateProviderProps = {
  children: React.ReactNode;
};

const initialServiceTypeColorState: ServiceTypeColorState = {
  isDataChanged: false,
  selectedColor: undefined,
  selectedServices: [],
  isUserSelected: false,
  confirmWithoutSaving: {
    value: false,
    redirectFunc: undefined,
    redirectParams: undefined
  }
};

const ServiceTypeColorContext = createContext<ServiceTypeColorState>(initialServiceTypeColorState);
const ServiceTypeColorDispatchContext = createContext<React.Dispatch<ServiceTypeColorReducerAction>>(() => {
  /*No OP*/
});

const ServiceTypeColorStateProvider = ({children}: ServiceTypeColorStateProviderProps) => {
  const [state, dispatch] = useReducer(serviceTypeColorReducer, initialServiceTypeColorState);

  return (
    <ServiceTypeColorContext.Provider value={state}>
      <ServiceTypeColorDispatchContext.Provider value={dispatch}>{children}</ServiceTypeColorDispatchContext.Provider>
    </ServiceTypeColorContext.Provider>
  );
};

export default ServiceTypeColorStateProvider;
export type {ServiceTypeColorState};
export {ServiceTypeColorContext, ServiceTypeColorDispatchContext, initialServiceTypeColorState};
