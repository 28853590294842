import {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const InfoIcon = styled(FontAwesomeIcon)`
  color: #797979;
`;

const TextBox = styled.div`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 132.19%;
  text-align: left;
  width: 326px;
  padding-left: 20px;
  color: #161616;
`;

export default class ManifestDetailsNoRowsOverlay extends Component {
  render() {
    return (
      <Container>
        <InfoIcon icon={solid("circle-info")} size={"2xl"} />
        <TextBox>
          This Driver Manifest is empty. Once you assign jobs to this manifest, it will reflect in this page.
        </TextBox>
      </Container>
    );
  }
}
