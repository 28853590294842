import styled from "@emotion/styled";

const FlexStack = styled.div`
  height: 100%;
  width: calc(100vw - 200px);
  overflow-x: hidden;
`;

const ViewBody = styled.div<{divideSection: boolean; subTitle: string}>`
  height: ${(props) => `calc(100% - ${props.subTitle ? "100px" : "70px"})`};
  overflow: auto;
  display: grid;
  grid-template-columns: ${(props) => (props.divideSection ? "minmax(725px, max-content) auto" : "")};
`;

const ViewHeader = styled.h2`
  font-size: 24px;
  font-weight: 400;
  color: #161616;
  line-height: 30px;
  letter-spacing: 1px;
  padding: 20px 24px;
  margin: 0;
`;

const ViewSubTitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #6b7280;
  letter-spacing: normal;
  margin: 0;
`;

type SettingViewContainerProps = {
  title: string;
  subTitle?: string;
  children?: Element | JSX.Element | Element[] | JSX.Element[] | false | null;
  divideSection?: boolean;
};
const SettingViewContainer = ({title, subTitle = "", children, divideSection = true}: SettingViewContainerProps) => {
  return (
    <FlexStack>
      <ViewHeader>
        {title}
        {subTitle && <ViewSubTitle>{subTitle}</ViewSubTitle>}
      </ViewHeader>
      <ViewBody divideSection={divideSection} subTitle={subTitle}>
        {children}
      </ViewBody>
    </FlexStack>
  );
};

export default SettingViewContainer;
