import {useContext, useMemo, CSSProperties} from "react";
import styled from "@emotion/styled";
import {Button, Intent, Text} from "@blueprintjs/core";
import {MaybeElement} from "@blueprintjs/core/src/common/props";
import {IconName} from "@blueprintjs/icons";
import {ICellRendererParams} from "@ag-grid-community/core";
import {AuthContext, AuthState} from "../../AuthProvider";
import {Tooltip2} from "@blueprintjs/popover2";
import _ from "lodash";
import {deliveryStop, pickupStop} from "../../../services/JobStopService";
import {getAddressText} from "../../../utils/General";
import {EmptyValueStrings} from "../Constants";

type ButtonProps = {
  text?: string;
  onClick(event: MouseEvent, data: any, authState: AuthState): void;
  intent?: Intent;
  icon?: IconName | MaybeElement;
  minimal?: boolean;
  testIdPrefix?: string;
};

export type ContentItemType = {key: string; title: string; value: any; path?: string};

const Container = styled.div`
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #161616;
  background-color: #ffffff;
  max-height: 95vh;
  overflow-y: auto;

  //set width of scrollbar
  ::-webkit-scrollbar {
    width: 6px;
  }

  //set border radius for scrollbar
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #16161680;
  }
`;
const ItemContainer = styled.div`
  display: flex;
  line-height: 22px;
`;
const ItemKey = styled(Text)`
  text-transform: uppercase;
  font-weight: 500;
  width: 160px;
`;
const ItemValue = styled(Text)`
  flex: 1;
  padding-right: 2px;
`;

const CUSTOM_TITLE_FIELDS: {key: string; customTitle: string}[] = [
  {key: "SVC Type", customTitle: "svt"},
  {key: "Customer Account", customTitle: "cust. account"},
  {key: "Customer Notes", customTitle: "cust. notes"},
  {key: "Customer Alias", customTitle: "cust. alias"},
  {key: "Total Mileage", customTitle: "ttl mileage"},
  {key: "Order Notes", customTitle: "notes"}
];

const COMBINE_VALUE_FIELDS: string[] = ["Address", "Time"];

const HIDE_FIELDS: string[] = ["Color"];

const getTitleByKey = (key: string) => {
  const customTitle = CUSTOM_TITLE_FIELDS.find((c) => c.key === key);
  if (customTitle) {
    return customTitle.customTitle;
  }
  return key;
};

export const getDisplayValue = (item: ContentItemType) => {
  if (_.isNumber(item.value) || _.isBoolean(item.value)) {
    return item.value.toString();
  }

  if (!item.value) {
    return "";
  }

  if (_.isString(item.value)) {
    return item.value.length > 120 ? item.value.substring(0, 120) + "..." : item.value;
  }

  switch (item.key) {
    case "Job #": {
      return item.value?.text ?? "";
    }

    case "Zone":
    case "Time": {
      return (
        <div>
          <span>{_.get(item.value, "upperField.value") ?? EmptyValueStrings.notProvided}</span>
          <span> / </span>
          <span style={{color: "#797979"}}>
            {_.get(item.value, "lowerField.value") ?? EmptyValueStrings.notProvided}
          </span>
        </div>
      );
    }

    case "Address": {
      return (
        <div>
          <span>{_.get(item.value, "upperField.value") ?? EmptyValueStrings.notProvided}</span>
          <span> / </span>
          <br />
          <span style={{color: "#797979"}}>
            {_.get(item.value, "lowerField.value") ?? EmptyValueStrings.notProvided}
          </span>
        </div>
      );
    }

    case "Ready":
    case "Due By":
    case "Order Placed": {
      if (!_.get(item.value, "upperField.value") && !_.get(item.value, "lowerField.value")) {
        return "";
      }
      return (
        <div>
          {_.get(item.value, "upperField.value")} {_.get(item.value, "lowerField.value")}
        </div>
      );
    }

    default:
      return item.value;
  }
};

const ContentItem = ({item}: {item: ContentItemType}) => {
  const styling: CSSProperties = {};

  if (/Notes/.exec(item.key) && item.value) {
    styling.color = "#797979";
    styling.fontStyle = "italic";
    styling.maxWidth = "350px";
  }

  if (/SVC Type/.exec(item.key) || /Job #/.exec(item.key)) {
    styling.fontWeight = 500;
  }

  const displayValue = getDisplayValue(item) || EmptyValueStrings.notProvided;

  return (
    <ItemContainer data-testid="job-number-tooltip-conten-item">
      <ItemKey>{item.title}:</ItemKey>
      <ItemValue style={styling}>{displayValue}</ItemValue>
    </ItemContainer>
  );
};

const JobnumberTooltip = ({data}: {data: ICellRendererParams<any, ButtonProps>}) => {
  const rowNode = useMemo(() => data.api.getDisplayedRowAtIndex(data.rowIndex), [data.api, data.rowIndex]);

  const displayedFields: ContentItemType[] = data.columnApi
    .getAllDisplayedColumns()
    .filter((c) => !c.getUserProvidedColDef()?.suppressColumnsToolPanel)
    .map((c) => {
      const columnId = c.getColId();
      const columnName = c.getUserProvidedColDef()?.headerName ?? c.getColDef()?.headerName ?? "";
      return {
        key: columnName,
        path: columnId,
        title: getTitleByKey(columnName),
        value: data.api.getValue(columnId, rowNode!)
      };
    });

  const sortedDisplayedFields = _.sortBy(displayedFields, ({key}) => {
    return /Order Notes/.exec(key) ? 1 : 0;
  });
  COMBINE_VALUE_FIELDS.forEach((f) => {
    const combineFieldIndex = sortedDisplayedFields.findIndex((df) => df.key === f);
    const updatedField = {...sortedDisplayedFields[combineFieldIndex]};

    if (combineFieldIndex) {
      if (f === "Address") {
        const pickup = pickupStop(rowNode?.data?.stops);
        const delivery = deliveryStop(rowNode?.data?.stops);
        const pickupAddress = pickup ? getAddressText({stop: pickup, hasDispatchZone: true}) : "";
        const deliveryAddress = delivery ? getAddressText({stop: delivery, hasDispatchZone: true}) : "";
        _.set(updatedField, "value.upperField.value", pickupAddress);
        _.set(updatedField, "value.lowerField.value", deliveryAddress);
        sortedDisplayedFields[combineFieldIndex] = updatedField;
      }
    }
  });

  const fields = sortedDisplayedFields.filter((f) => !HIDE_FIELDS.includes(f.key));

  return (
    <Container data-testid="job-number-tooltip">
      {fields.map((c) => (
        <ContentItem key={c.key} item={c} />
      ))}
    </Container>
  );
};

const ButtonCellRenderer = (props: ICellRendererParams<any, ButtonProps>) => {
  const authState = useContext(AuthContext);

  return (
    <Tooltip2
      popoverClassName="job-number-tooltip-popover"
      content={<JobnumberTooltip data={props} />}
      position="auto"
      interactionKind="hover"
      hoverCloseDelay={100}
    >
      <Button
        data-testid={`${props.value.testIdPrefix ? props.value.testIdPrefix : "button-cell"}-${props.node.id}`}
        text={props.value.text}
        intent={props.value.intent}
        minimal={props.value.minimal}
        icon={props.value.icon}
        ref={(ref) => {
          if (!ref) return;
          ref!.buttonRef!.onclick = (e: MouseEvent) => {
            e.stopPropagation();
            props.value.onClick(e, props.data, authState);
          };
        }}
        style={{fontSize: "12px"}}
      />
    </Tooltip2>
  );
};

export default ButtonCellRenderer;
