import {useContext, useEffect, useState} from "react";
import {GeoCodeService, GeoCodeServiceProperties, getGeoCodeService} from "../../services/GeoCodeService";
import {ApolloClient, useApolloClient} from "@apollo/client";
import {DispatchConfigurationContext} from "../../providers/DispatchConfigurationProvider";

type UseGeoCodeServiceProps = {
  apolloClientHook: () => ApolloClient<any>;
};
const useGeoCodeService = (
  {apolloClientHook}: UseGeoCodeServiceProps = {
    apolloClientHook: useApolloClient
  }
): GeoCodeService => {
  const [geocodeService, setGeocodeService] = useState<GeoCodeService>();
  const apolloClient = apolloClientHook();
  const {geocodingServiceType} = useContext(DispatchConfigurationContext);

  useEffect(() => {
    const geoCodeServiceProps = {
      apolloClient: apolloClient,
      serviceType: geocodingServiceType
    } as GeoCodeServiceProperties;
    console.debug("Obtaining geocode service based on properties: ", geoCodeServiceProps);
    setGeocodeService(getGeoCodeService(geoCodeServiceProps));
  }, [apolloClient, geocodingServiceType]);
  return geocodeService as GeoCodeService;
};

export type {UseGeoCodeServiceProps};
export {useGeoCodeService};
