import styled from "@emotion/styled";

const ItemContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
  line-height: 30px;
  padding: 25px;
  background-color: #ededed;
`;

const ItemHeader = styled.h2`
  font-size: 16px;
  font-weight: 700;
  color: #797979;
  letter-spacing: 1px;
  margin: 15px 0 20px;
  text-transform: uppercase;
`;

type SettingItemContainerProps = {
  title: string;
  children?: Element | JSX.Element | Element[] | JSX.Element[] | false | null;
};
const SettingItemsContainer = ({title, children}: SettingItemContainerProps) => {
  return (
    <ItemContainer>
      <ItemHeader>{title}</ItemHeader>
      {children}
    </ItemContainer>
  );
};

export default SettingItemsContainer;
