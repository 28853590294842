import axios, {AxiosResponse} from "axios";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../components/AuthProvider";
import {UserProfile} from "../components/settings/UserManagement/UserProfile";
import {SERVICES_API_URL} from "../config";

type TenantServiceProps = {
  url: string;
};

export interface Tenant {
  id: string;
  shortName: string;
  hubName: string;
  legacyCmsUrl: string;
}

const useTenantService = ({url}: TenantServiceProps) => {
  const [data, setData] = useState<Tenant[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();
  const {token} = useContext(AuthContext);

  useEffect(() => {
    const fetchData = () => {
      const controller = new AbortController();
      const signal = controller.signal;

      axios
        .get(`${SERVICES_API_URL}/tenants${url}`, {
          signal: signal,
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((response) => {
          setLoading(false);
          setData(response.data);
        })
        .catch((e) => {
          controller.abort();
          setError(e);
        });
    };

    if (token) fetchData();
  }, [url, token]);

  return {error, loading, data};
};

const getTenant = (token: string, tenantId: string): Promise<AxiosResponse> => {
  return axios.get(`${SERVICES_API_URL}/tenants/tenant/${tenantId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const switchTenants = (token: string, tenantId: string): Promise<AxiosResponse> => {
  return axios.get(`${SERVICES_API_URL}/tenants/switch/${tenantId}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const createUser = (token: string, user: UserProfile): Promise<AxiosResponse> => {
  return axios.post(`${SERVICES_API_URL}/tenants/users/create`, user, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const updateUser = (token: string, user: UserProfile): Promise<AxiosResponse> => {
  return axios.post(`${SERVICES_API_URL}/tenants/users/update`, user, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const deleteUser = (token: string, user: UserProfile): Promise<AxiosResponse> => {
  return axios.post(`${SERVICES_API_URL}/tenants/users/disable/${user.id}`, null, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const resendActivation = (token: string, userId: string): Promise<AxiosResponse> => {
  return axios.post(`${SERVICES_API_URL}/tenants/users/reactivate/${userId}`, null, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const getLegacyUserGuid = (token: string): Promise<AxiosResponse> => {
  return axios.get(`${SERVICES_API_URL}/tenants/getLegacyUserGuid`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

export {
  useTenantService,
  getTenant,
  switchTenants,
  getLegacyUserGuid,
  createUser,
  updateUser,
  deleteUser,
  resendActivation
};
