import {format, formatInTimeZone, toDate} from "date-fns-tz";
import {Maybe} from "../generated/graphql";
import {startOfDay} from "date-fns";
import {DateFormats} from "../components/common/Constants";

export type UnknownString = Maybe<string> | string | undefined;

interface TimeFormatOptions {
  includeZone?: boolean;
  amPmFormat?: string;
}

const IANATimeZoneMapping: Record<string, string> = {
  EST: "America/New_York",
  CST: "America/Chicago",
  MST: "America/Denver",
  PST: "America/Los_Angeles",
  HST: "Pacific/Honolulu",
  AKST: "America/Anchorage",
  AKS: "America/Anchorage",
  NST: "America/St_Johns",
  AST: "America/Halifax",
  ATL: "America/Halifax",
  ICT: "Asia/Bangkok"
};

export const getIANATimezone = (timezone: string) => {
  return IANATimeZoneMapping[timezone] ?? timezone;
};

const getLocalDate = (dateData: UnknownString | Date, timeZone: UnknownString, formatStr?: string) => {
  if (dateData && timeZone) {
    const date = toDate(dateData, {timeZone: timeZone});
    return format(date, formatStr ? formatStr : "M/d/yy");
  }
  return null;
};

const getLocalTime = (
  dateData: UnknownString | Date,
  timeZone: UnknownString,
  isTimeFormat24hr: boolean,
  options?: TimeFormatOptions
) => {
  if (dateData && timeZone) {
    const date = toDate(dateData, {timeZone: timeZone});
    const zone = options?.includeZone ? " z" : "";
    let hr = "h";
    let ampm = options?.amPmFormat ? ` ${options.amPmFormat}` : " aa";
    if (isTimeFormat24hr) {
      hr = "HH";
      ampm = "";
    }
    return format(date, `${hr}:mm${ampm}${zone}`);
  }
  return null;
};

const getLocalDateTime = ({
  dateData,
  timeZone,
  isTimeFormat24hr,
  timeFormat = ""
}: {
  dateData: UnknownString;
  timeZone: UnknownString;
  isTimeFormat24hr: boolean;
  timeFormat?: string;
}) => {
  if (dateData && timeZone) {
    const date = toDate(dateData, {timeZone: timeZone});
    const timeFormatString = ["M/d/yy"];
    if (isTimeFormat24hr) {
      timeFormatString.push("HH:mm");
    } else {
      timeFormatString.push("h:mm aa");
    }
    timeFormatString.push("z");

    return format(date, timeFormat ? timeFormat : timeFormatString.join(" "));
  }
  return null;
};

function removeTimeFromDate(inputDate: Date): Date {
  const dateWithoutTime = startOfDay(inputDate);
  return dateWithoutTime;
}

const getJobStopTime = (
  dateData: UnknownString,
  timeZone: UnknownString,
  isTimeFormat24hr: boolean,
  options?: TimeFormatOptions
) => {
  if (dateData && timeZone) {
    const zone = options?.includeZone ? " zzz" : "";
    let hr = "h";
    let ampm = options?.amPmFormat ? ` ${options.amPmFormat}` : " aa";
    if (isTimeFormat24hr) {
      hr = "HH";
      ampm = "";
    }
    return formatInTimeZone(dateData, getIANATimezone(timeZone), `${hr}:mm${ampm}${zone}`);
  }
  return "";
};

const getJobStopDate = (dateData: UnknownString, timeZone: UnknownString, formatStr?: string) => {
  if (dateData && timeZone) {
    return formatInTimeZone(dateData, getIANATimezone(timeZone), formatStr ?? "M/d/yy");
  }
  return "";
};

const getJobStopDateTime = ({
  dateData,
  timeZone,
  isTimeFormat24hr,
  timeFormat = ""
}: {
  dateData: UnknownString;
  timeZone: UnknownString;
  isTimeFormat24hr: boolean;
  timeFormat?: string;
}) => {
  if (dateData && timeZone) {
    const timeFormatString = ["M/d/yy"];
    if (isTimeFormat24hr) {
      timeFormatString.push("HH:mm");
    } else {
      timeFormatString.push("h:mm aa");
    }
    timeFormatString.push("zzz");

    return formatInTimeZone(dateData, getIANATimezone(timeZone), timeFormat ? timeFormat : timeFormatString.join(" "));
  }
  return "";
};

const formatCMSDateTimeInTimeZone = (dateData: string | number | Date, timeZone: string, formatStr?: string) => {
  if (dateData && timeZone) {
    return formatInTimeZone(dateData, getIANATimezone(timeZone), formatStr ?? DateFormats.awsDateTimeWithOffset);
  }
  return undefined;
};

const isValidDateTime = (dateTime: any) => {
  if (typeof dateTime === "string") {
    return Date.parse(dateTime) > 0;
  }
  return Boolean(dateTime && !isNaN(dateTime));
};

const formatDateStringMDYToYDM = (inputDateString: string) => {
  const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (!dateRegex.test(inputDateString)) {
    return inputDateString;
  }
  const parts = inputDateString.split("/");
  const formattedDate = `${parts[2]}-${parts[0].padStart(2, "0")}-${parts[1].padStart(2, "0")}`;
  return formattedDate;
};

export {
  getLocalDateTime,
  getLocalTime,
  getLocalDate,
  removeTimeFromDate,
  getJobStopTime,
  getJobStopDate,
  getJobStopDateTime,
  formatCMSDateTimeInTimeZone,
  isValidDateTime,
  formatDateStringMDYToYDM
};
