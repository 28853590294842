import stringHash from "string-hash";

export interface LatLong {
  lat: number;
  lng: number;
}

export enum AddressResolutionStatus {
  cacheHit,
  cacheMiss,
  unresolvableAddress
}

export class Address {
  private _streetAddress: string;
  private _city: string;
  private _state: string;
  private _zip: string;

  constructor(streetAddress: string, city: string, state: string, zip: string) {
    this._streetAddress = streetAddress;
    this._city = city;
    this._state = state;
    this._zip = zip;
  }

  get zip(): string {
    return this._zip;
  }

  set zip(value: string) {
    this._zip = value;
  }

  get state(): string {
    return this._state;
  }

  set state(value: string) {
    this._state = value;
  }

  get city(): string {
    return this._city;
  }

  set city(value: string) {
    this._city = value;
  }

  get streetAddress(): string {
    return this._streetAddress;
  }

  set streetAddress(value: string) {
    this._streetAddress = value;
  }

  public formatAddress(): string {
    return `${this._streetAddress}, ${this._city}, ${this._state} ${this._zip}`;
  }

  public hashAddress(): number {
    return stringHash(this.formatAddress());
  }
}

export class ResolvedAddress {
  private _address: Address;
  private _latLong: LatLong;
  private _resolutionStatus: AddressResolutionStatus;

  constructor(
    address: Address,
    latLong: LatLong,
    _resolutionStatus: AddressResolutionStatus = AddressResolutionStatus.unresolvableAddress
  ) {
    this._address = address;
    this._latLong = latLong;
    this._resolutionStatus = _resolutionStatus;
  }

  get lng(): number {
    return this._latLong.lng;
  }

  get lat(): number {
    return this._latLong.lat;
  }

  get address(): Address {
    return this._address;
  }

  set address(value: Address) {
    this._address = value;
  }

  get latLong(): LatLong {
    return this._latLong;
  }

  set latLong(value: LatLong) {
    this._latLong = value;
  }

  get resolutionStatus(): AddressResolutionStatus {
    return this._resolutionStatus;
  }

  set resolutionStatus(value: AddressResolutionStatus) {
    this._resolutionStatus = value;
  }

  public hashAddress(): number {
    return this._address.hashAddress();
  }
}
