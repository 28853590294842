import "../../../App.css";
import GoogleMapReact from "google-map-react";
import {FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Constants, OptimizedResultCords, UserPreferences} from "../../common/Constants";
import ManifestDetailsMapOverlay from "./ManifestDetailsMapOverlay";
import {
  DriverLocation,
  Manifest,
  ManifestStop,
  SearchDriverLocationQuery,
  useSetUserPrefMutation
} from "../../../generated/graphql";
import StopMarker from "../stop/StopMarker";
import {useGeoCodeService} from "../../hooks/useGeoCodeService";
import {getRouted, getRoutedEntities, useMapVisibilityContext} from "../MapVisibilityContext";
import {isCompleted} from "../../../services/JobStopService";
import DriverMarker from "../driver/DriverMarker";
import {Cords, createSinglePolyline, getMapBounds, getRoutePaths, MapPath} from "../MappingService";
import {ManifestStopEnhanced, SaveMilesAndTime} from "../../manifest/details/ManifestDetailsV2";
import {getDriverCords, getStopLatLng, MappedStop} from "../AssignmentMapV2";
import useMapRoutes, {RoutePath} from "../useMapRoutes";
import _ from "lodash";
import {convertMilesFromMeters, millisecondsToHMS} from "./ManifestMapService";
import {Icon} from "@blueprintjs/core";
import styled from "@emotion/styled";
import {mapCompare} from "../../../utils/General";
import {QueryResult} from "@apollo/client";
import {ManifestDetailActionBar} from "./ManifestDetailActionbar";
import {createJsonPref, extractJsonPref, PreferenceContext} from "../../../providers/PreferenceProvider";

export enum RouteViewMode {
  Optimized = "optimized",
  Original = "original"
}

export type ManifestDetailsMapViewProps = {
  manifestState: Manifest | undefined;
  optimizedStops?: ManifestStopEnhanced[];
  driverLocationResult: QueryResult<SearchDriverLocationQuery>;
  isDraggedStops?: boolean;
  mapJobStop?: number[];
  isOptimized: boolean;
  saveDistanceAndTime: SaveMilesAndTime | null;
  optimizedResultCords: Map<OptimizedResultCords, {lat: number; lng: number}[]> | null;
};

interface MapActionBarPrefs {
  isActiveCompletedStops: boolean;
}

const defaultMapActionBarPrefs = {
  isActiveCompletedStops: false
};

const ManifestDetailsMap: FunctionComponent<ManifestDetailsMapViewProps> = ({
  manifestState: manifest,
  driverLocationResult,
  optimizedStops,
  isDraggedStops,
  mapJobStop,
  isOptimized,
  saveDistanceAndTime,
  optimizedResultCords
}) => {
  const geoCodeService = useGeoCodeService();
  const [apiLoaded, setApiLoaded] = useState<boolean>(false);
  const [map, setMap] = useState<any>();
  const [maps, setMaps] = useState<any>();
  const {state: mapVisibilityState, dispatch: mapVisibilityDispatch} = useMapVisibilityContext();

  const [latLngStops, setLatLngStops] = useState<Record<string /* lat,lng */, MappedStop[]>>({});
  const [driverLocations, setDriverLocations] = useState<Map<number, DriverLocation>>(new Map());
  const [, setRoutePathLine] = useState<MapPath | null>(null);
  const [routeInfo, setRoutes] = useMapRoutes();
  const [viewMode, setViewMode] = useState<RouteViewMode>(RouteViewMode.Original);
  const {userPreferences, userPrefsQueryRefetch} = useContext(PreferenceContext);
  const [setUserPref] = useSetUserPrefMutation();
  const [initialManifestMap, setInitialManifestMap] = useState<boolean>(true);
  const [apiKey] = useState({key: Constants.GOOGLE_API_KEY});
  const [defaultCenter] = useState({lat: 37.0902, lng: -95.7129});
  const [userGestureActive, setUserGestureActive] = useState(false);
  const [isActiveCompletedStops, setIsActiveCompletedStops] = useState(defaultMapActionBarPrefs.isActiveCompletedStops);

  const mapActionBarPrefs = useRef<MapActionBarPrefs>(defaultMapActionBarPrefs);

  const updateMapActionBarPrefs = useCallback(() => {
    setUserPref({
      variables: {
        name: UserPreferences.manifestMapActionBar,
        input: createJsonPref(mapActionBarPrefs.current, true)
      }
    }).then(() => {
      userPrefsQueryRefetch?.();
    });
  }, [setUserPref, userPrefsQueryRefetch]);

  const onToggleActiveCompletedStops = useCallback(() => {
    setIsActiveCompletedStops((prevState) => {
      mapActionBarPrefs.current.isActiveCompletedStops = !prevState;
      updateMapActionBarPrefs();
      return !prevState;
    });
  }, [updateMapActionBarPrefs]);

  useEffect(() => {
    if (initialManifestMap && userPreferences.length > 0) {
      const mapActionBarPrefs = extractJsonPref(userPreferences, UserPreferences.manifestMapActionBar)?.value;
      if (mapActionBarPrefs) {
        setIsActiveCompletedStops(mapActionBarPrefs.isActiveCompletedStops);
      }
      setInitialManifestMap(false);
    }
  }, [initialManifestMap, userPreferences, userPreferences.length]);

  const handleUserGestureActive = useCallback(() => {
    setUserGestureActive(true);
  }, []);

  useEffect(() => {
    if (isOptimized) {
      setViewMode(RouteViewMode.Optimized);
    } else {
      setViewMode(RouteViewMode.Original);
    }
  }, [isOptimized]);

  useEffect(() => {
    /*
     * Convert visible jobs and manifests into a KV map. Keys is lat/lng, values
     * are an array of stopped at key's location
     */
    let stillMounted = true;
    (async () => {
      const routedVisibleManifests = getRoutedEntities(mapVisibilityState.manifests);
      const visibleManifestStops: MappedStop[] =
        routedVisibleManifests.length === 0
          ? []
          : routedVisibleManifests.flatMap(({entityId, visibilityLevel}) => {
              if (!manifest) {
                return [];
              }
              if (!manifest.stops) return [];
              const stops = (
                (viewMode === RouteViewMode.Optimized || isDraggedStops) && optimizedStops
                  ? optimizedStops
                  : manifest.stops
              ) as (ManifestStopEnhanced | ManifestStop)[];
              return stops.flatMap((stop, i): MappedStop[] => {
                if ((!isActiveCompletedStops || isOptimized) && isCompleted(stop)) return [];
                return [
                  {
                    ...stop,
                    stopInfoMetaData: {
                      key: `Manifest_${entityId}`,
                      customer: stop.order.customer.name!,
                      jobNumber: stop.job.jobNumber,
                      service: stop.order.service!,
                      isFirstStop: i === 0,
                      visibilityLevel,
                      routeColor: "#000000"
                    }
                  }
                ];
              });
            });
      const locations = await Promise.all(
        [...visibleManifestStops].map((stop) =>
          getStopLatLng(stop, geoCodeService).then((latLngPair) => {
            return {latLngPair, stop};
          })
        )
      );
      const latLngStops: Record<string, MappedStop[]> = {};
      for (const {latLngPair, stop} of locations) {
        /* skip addresses that fail to produce a lat/lng */
        if (latLngPair) {
          if (!latLngStops[latLngPair]) latLngStops[latLngPair] = [];
          latLngStops[latLngPair].push(stop);
        }
      }
      if (stillMounted) {
        setLatLngStops(latLngStops);
      }
    })();
    return () => {
      stillMounted = false;
    };
  }, [
    mapVisibilityState.manifests,
    geoCodeService,
    manifest,
    optimizedStops,
    map,
    maps,
    viewMode,
    isDraggedStops,
    isActiveCompletedStops,
    isOptimized
  ]);

  // Set Driver Locations
  useEffect(() => {
    if (!driverLocationResult.loading) {
      const newDriverLocations = new Map<number, DriverLocation>();
      const data = driverLocationResult.data?.searchDriverLocations?.items as DriverLocation[];
      if (data) {
        for (const driverLocation of data) {
          newDriverLocations.set(driverLocation.driverId, driverLocation);
        }
      } else {
        console.error("Unable to retrieve driver locations", driverLocationResult.error);
      }
      if (!mapCompare<number, DriverLocation>(driverLocations, newDriverLocations)) {
        setDriverLocations(newDriverLocations);
      }
    }
  }, [
    setDriverLocations,
    driverLocations,
    driverLocationResult.loading,
    driverLocationResult.data,
    driverLocationResult.error
  ]);

  useEffect(() => {
    // set driver id from manifest data
    if (manifest) {
      // set visible manifests to those that have a driver location
      const manifestsWithLocation = driverLocations.has(manifest.driver.driverId) ? [manifest] : [];
      mapVisibilityDispatch({
        type: "SetManifestsWithLocation",
        manifests: manifestsWithLocation
      });
    }
  }, [manifest, driverLocations, mapVisibilityDispatch]);

  // Set center & clear route on new manifest or job selection
  useEffect(() => {
    if (manifest && mapVisibilityState.manifests) {
      const routeMarkers = [
        ...(routeInfo ?? []).flatMap((x) =>
          x.points.map((y) => {
            return {lat: y[0], lng: y[1]};
          })
        )
      ];
      const stopMarkers = Object.keys(latLngStops).map((key) => {
        const [lat, lng] = key.split(",");
        return {lat: Number(lat), lng: Number(lng)};
      });

      const driverCords = ([getDriverCords(manifest.driver.driverId, driverLocations)] as Cords[]) || [];
      const markers = [...routeMarkers, ...stopMarkers, ...driverCords].filter((d) => d);
      if (apiLoaded && markers.length > 0 && !userGestureActive) {
        const bounds = getMapBounds(maps, markers);
        map.fitBounds(bounds);
        const currentZoom = map.getZoom();
        if (currentZoom && currentZoom > 15) {
          map.setZoom(15);
        }
      }
    }
  }, [
    map,
    maps,
    apiLoaded,
    routeInfo,
    mapVisibilityState.manifests,
    latLngStops,
    manifest,
    driverLocations,
    userGestureActive
  ]);

  //Setting stop gps points for routing path
  useEffect(() => {
    if (isOptimized && !isDraggedStops) return;
    const routedEntities = getRouted(mapVisibilityState);
    if (map && routedEntities.manifests.length > 0) {
      // stop locations array, keyed by unique entity id (manifest or nested job id)
      for (const key of Object.keys(latLngStops)) {
        const mappedStops = latLngStops[key];
        if (mappedStops) {
          for (const mappedStop of mappedStops) {
            const entityId = mappedStop.stopInfoMetaData.key;
            const x = map?.get(entityId) ?? [];
            x.push(mappedStop);
            map.set(entityId, x);
          }
        }
      }
      const paths = getRoutePaths(latLngStops, routedEntities, driverLocations, false);
      const lastRoutePath = paths.at(-1);
      const newPaths: RoutePath[] = lastRoutePath
        ? [
            {
              ...lastRoutePath,
              key: `${lastRoutePath.key}_${Object.keys(latLngStops).join("_")}`,
              mode: viewMode
            }
          ]
        : [];
      setRoutes(newPaths);
    } else {
      setRoutes([]);
    }
  }, [driverLocations, latLngStops, mapVisibilityState, map, setRoutes, viewMode, isOptimized, isDraggedStops]);

  useEffect(() => {
    if (isOptimized && !isDraggedStops) return;
    if (routeInfo && routeInfo.length > 0) {
      const lastRouteInfo = routeInfo.filter((route) => route.mode === viewMode).at(-1);
      if (lastRouteInfo) {
        const mapPath: MapPath = {
          route: lastRouteInfo,
          polyline: createSinglePolyline(
            lastRouteInfo,
            map,
            viewMode === RouteViewMode.Optimized ? "#003DB5" : "#797979"
          )
        };
        setRoutePathLine((mapPathPrev) => {
          removePolyLines(mapPathPrev?.polyline);
          return mapPath;
        });
      }
    }
    if (Object.keys(latLngStops).length === 0) {
      setRoutePathLine((mapPathPrev) => {
        removePolyLines(mapPathPrev?.polyline);
        return null;
      });
    }
  }, [map, routeInfo, viewMode, latLngStops, isOptimized, isDraggedStops]);

  useEffect(() => {
    if (!isOptimized || !optimizedResultCords || isDraggedStops) return;

    if (viewMode === RouteViewMode.Optimized) {
      const optimizedCords = optimizedResultCords.get(OptimizedResultCords.OptimizedCords);
      const newPath: MapPath = {
        polyline: new google.maps.Polyline({
          strokeColor: "#003DB5",
          strokeOpacity: 1,
          strokeWeight: 4,
          path: optimizedCords,
          map: map
        })
      };
      setRoutePathLine((mapPathPrev) => {
        removePolyLines(mapPathPrev?.polyline);
        return newPath;
      });
    } else {
      const originalCords = optimizedResultCords.get(OptimizedResultCords.OriginalCords);
      const newPath: MapPath = {
        polyline: new google.maps.Polyline({
          strokeColor: "#797979",
          strokeOpacity: 1,
          strokeWeight: 4,
          map: map,
          path: originalCords
        })
      };
      setRoutePathLine((mapPathPrev) => {
        removePolyLines(mapPathPrev?.polyline);
        return newPath;
      });
    }
  }, [isDraggedStops, isOptimized, map, optimizedResultCords, viewMode]);

  const removePolyLines = (lines: google.maps.Polyline | google.maps.Polyline[] | undefined) => {
    if (lines !== undefined) {
      if (_.isArray(lines)) {
        lines.forEach((polyline) => {
          polyline.setMap(null);
        });
      } else {
        lines.setMap(null);
      }
    }
  };

  /* event from GoogleMapReact when map is loading in browser */
  const apiIsLoaded = useCallback((maps: {map: any; maps: any; ref: Element}) => {
    setMap(maps.map);
    setMaps(maps.maps);
    setApiLoaded(true);
  }, []);

  const renderManifestDriver = () => {
    if (manifest) {
      const cords = getDriverCords(manifest.driver.driverId, driverLocations);
      return cords ? (
        <DriverMarker
          manifest={manifest}
          key={`manifest-driver-marker-${manifest.manifestDriverId}`}
          isSelected={true}
          lat={cords.lat}
          lng={cords.lng}
          cords={cords}
        />
      ) : null;
    }
    return null;
  };

  const saveTimeAndDistanceInfo = useMemo(() => {
    if (!routeInfo || !saveDistanceAndTime || (isOptimized && isDraggedStops)) return null;

    return {
      time: millisecondsToHMS(saveDistanceAndTime.time),
      distance: convertMilesFromMeters(saveDistanceAndTime.distance)
    };
  }, [isDraggedStops, isOptimized, routeInfo, saveDistanceAndTime]);

  const renderHomeLocation = useCallback(() => {
    if (
      !isOptimized ||
      !optimizedResultCords ||
      !optimizedResultCords.has(OptimizedResultCords.HomeCords) ||
      isDraggedStops
    ) {
      return null;
    }

    const homeCords = optimizedResultCords.get(OptimizedResultCords.HomeCords)?.at(0);
    return (
      <IconHomeContainer lat={homeCords?.lat ?? 0} lng={homeCords?.lng ?? 0}>
        <Icon icon={"home"} size={20} color={"red"} />
      </IconHomeContainer>
    );
  }, [isDraggedStops, isOptimized, optimizedResultCords]);

  return (
    <div
      className={`${map ? "google-map" : ""}`}
      data-testid={"manifest-details-map"}
      style={{height: "100%", position: "relative"}}
      onWheel={handleUserGestureActive}
    >
      <ManifestDetailActionBar
        isOptimized={isOptimized}
        isActiveCompletedStops={isActiveCompletedStops}
        onToggleActiveCompletedStops={onToggleActiveCompletedStops}
      />
      <ManifestDetailsMapOverlay
        isOptimized={isOptimized}
        viewMode={viewMode}
        setViewMode={setViewMode}
        saveInfo={saveTimeAndDistanceInfo}
      />
      <GoogleMapReact
        bootstrapURLKeys={apiKey}
        defaultCenter={defaultCenter}
        defaultZoom={5}
        yesIWantToUseGoogleMapApiInternals
        options={{disableDefaultUI: true, zoomControl: true, clickableIcons: true}}
        onGoogleApiLoaded={apiIsLoaded}
        onDragEnd={handleUserGestureActive}
        onChildClick={handleUserGestureActive}
      >
        {renderManifestDriver()}
        {renderHomeLocation()}
        {Object.keys(latLngStops).length !== 0 &&
          Object.keys(latLngStops).map((key) => {
            const mappedLocations = latLngStops[key];
            const [lat, lng] = key.split(",");
            return (
              <StopMarker
                mapJobStop={mapJobStop}
                key={key}
                lat={Number(lat)}
                lng={Number(lng)}
                mappedStops={mappedLocations}
              />
            );
          })}
      </GoogleMapReact>
    </div>
  );
};
export default ManifestDetailsMap;

const IconHomeContainer = styled.div<{lat: number; lng: number}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
`;
