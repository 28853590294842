export const renderStopStatusText = (stopStatus: string[]) => {
  switch (stopStatus.join(",")) {
    case "A":
      return "Active";
    case "Q":
      return "Queued";
    default:
      return "all";
  }
};

export const renderSelectedTextStatusItem = (stopStatus: string | undefined) => {
  switch (stopStatus) {
    case "A":
      return "Active";

    case "Q":
      return "Queued";

    default:
      return undefined;
  }
};
