import {ICellRendererParams} from "@ag-grid-community/core";
import {ColorChip} from "../../settings/ColorizedIndicators/JobColorSelector";
import styled from "@emotion/styled";
import {Text} from "@blueprintjs/core";
import _ from "lodash";
import {UserColor} from "../../settings/ColorizedIndicators/types/indicatorLogicType";
import {IndicatorColors} from "../../settings/ColorizedIndicators/ColorizedIndicators";

const NoColorOptions = ["(Select All)", "No Color"];

const ColorCellRenderer = (props: ICellRendererParams<any, string>) => {
  return (
    <ColorFilterItem>
      {!NoColorOptions.includes(props.value) && <ColorChip color={IndicatorColors[props.value as UserColor].hex} />}
      <Text>{!NoColorOptions.includes(props.value) ? _.capitalize(props.value) : props.value}</Text>
    </ColorFilterItem>
  );
};

export default ColorCellRenderer;

const ColorFilterItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
