import styled from "@emotion/styled";
import StopInfo, {StopInfoMetadata} from "./StopInfo";
import {NestedJobStop} from "../../../generated/graphql";

import React, {HTMLAttributes, MutableRefObject, useCallback, useEffect, useState} from "react";
import ManifestStopIcon from "./ManifestStopIcon";
import {isCompleted} from "../../../services/JobStopService";
import PackagePanel from "./PackagePanel";
import {Popover2, Tooltip2} from "@blueprintjs/popover2";
import {PopoverPosition} from "@blueprintjs/core";
import {EntityVisibilityLevel} from "../MapVisibilityContext";
import JobStopIcon from "./JobStopIcon";
import {ManifestStopEnhanced} from "../../manifest/details/ManifestDetailsV2";
import {MinimalStopIcon} from "../action/MapIcons";
import {JobAssignmentViewState} from "../../../views/JobAssignmentViewReducer";
import {AgGridReact} from "@ag-grid-community/react";
import {AgJob} from "../../job/JobPanel";
import {findObjectByCondition} from "../../../utils/General";
import {MappedStop} from "../AssignmentMapV2";

export const getAttributes = (stop: NestedJobStop | ManifestStopEnhanced) => {
  let attributes: any = {};
  switch (stop.stopType) {
    case "B":
      attributes = {symbol: "B", primaryColor: "#25A567", secondaryColor: "#0038FF"};
      break;
    case "P":
      attributes = {symbol: "P", primaryColor: "#0038FF"};
      break;
    case "D":
      attributes = {symbol: "D", primaryColor: "#25A567"};
      break;
  }
  if (isCompleted(stop)) {
    attributes = {...attributes, primaryColor: "#777777", secondaryColor: undefined};
  }
  return attributes;
};

const StylingMultiStop = styled.div`
  width: 22px;
  height: 22px;
  background-color: black;
  border-width: 1px;
  border-radius: 50%;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
`;

enum PackagePanelOpenMode {
  manual,
  auto
}

const MultiStopMarker = ({
  mappedStops,
  mapJobStop,
  alwaysShowJobNumber,
  assignmentViewState,
  handleAssignmentByClickingMarker,
  usePortal,
  newWindow
}: {
  mappedStops: MappedStop[];
  mapJobStop?: number[];
  alwaysShowJobNumber?: boolean;
  assignmentViewState?: JobAssignmentViewState;
  handleAssignmentByClickingMarker: (info: StopInfoMetadata) => void;
  usePortal?: boolean;
  newWindow?: Window;
}) => {
  const [packagePanelOpenState, setPackagePanelOpenState] = useState(false);
  const [packagePanelOpenMode, setPackagePanelOpenMode] = useState<PackagePanelOpenMode>(PackagePanelOpenMode.auto);

  useEffect(() => {
    if (packagePanelOpenMode === PackagePanelOpenMode.auto) {
      setPackagePanelOpenState(mappedStops.some((stop) => mapJobStop?.includes(stop.jobStopId)));
    }
  }, [mapJobStop, mappedStops, packagePanelOpenMode]);

  const handleOpenPanelByManual = useCallback(() => {
    setPackagePanelOpenMode(PackagePanelOpenMode.manual);
    setPackagePanelOpenState(true);
  }, []);

  const handleClosePanelByManual = useCallback(() => {
    setPackagePanelOpenMode(PackagePanelOpenMode.auto);
    setPackagePanelOpenState(false);
  }, []);

  return (
    <Popover2
      popoverClassName="multiple-stops-popover"
      content={
        <PackagePanel
          mappedStops={mappedStops}
          mapJobStop={mapJobStop}
          alwaysShowJobNumber={alwaysShowJobNumber}
          assignmentViewState={assignmentViewState}
          handleAssignmentByClickingMarker={handleAssignmentByClickingMarker}
          usePortal={usePortal}
          newWindow={newWindow}
          setPackagePanelOpenState={handleClosePanelByManual}
        />
      }
      isOpen={packagePanelOpenState}
      onClose={handleClosePanelByManual}
      position={PopoverPosition.TOP}
      usePortal={usePortal}
      portalContainer={newWindow?.document.body}
    >
      <Tooltip2
        content={<span data-testid="multi-stop-marker-tooltip">Click on the marker to see overlapping stops</span>}
        openOnTargetFocus={false}
        position={PopoverPosition.BOTTOM}
        popoverClassName={"marker-popover"}
        usePortal={usePortal}
        portalContainer={newWindow?.document.body}
      >
        <StylingMultiStop
          data-testid="multi-stop-marker"
          className="multi-stop-styling"
          onClick={handleOpenPanelByManual}
        >
          {mappedStops.length}
        </StylingMultiStop>
      </Tooltip2>
    </Popover2>
  );
};

const SingleStopMarker = ({
  stop,
  info,
  mapJobStop,
  additionalMapActionsFeature,
  minimal,
  alwaysShowJobNumber,
  handleAssignmentByClickingMarker,
  assignmentViewState,
  usePortal,
  newWindow
}: {
  stop: NestedJobStop | ManifestStopEnhanced;
  info: StopInfoMetadata;
  mapJobStop: number[];
  additionalMapActionsFeature?: boolean;
  minimal?: boolean;
  alwaysShowJobNumber?: boolean;
  assignmentViewState?: JobAssignmentViewState;
  handleAssignmentByClickingMarker: (info: StopInfoMetadata, jobId: number) => void;
  usePortal?: boolean;
  newWindow?: Window;
}) => {
  const renderMarker = (stop: NestedJobStop | ManifestStopEnhanced) => {
    const attributes = getAttributes(stop);

    if (minimal) {
      const color = isCompleted(stop) ? "#797979" : "#003DB5";
      return <MinimalStopIcon color={color} />;
    }

    if (stop.__typename === "ManifestStop") {
      return (
        <ManifestStopIcon
          jobStopId={stop.jobStopId}
          {...attributes}
          {...(mapJobStop.includes(stop.jobStopId) ? {primaryColor: "red"} : {})}
          sequenceNumber={stop.optimizedSequence ?? stop.manifestSequence}
          sequenceNumberColor={info.routeColor}
          isCompletedStop={isCompleted(stop) && additionalMapActionsFeature}
        />
      );
    } else {
      const isExpanded = info.visibilityLevel === EntityVisibilityLevel.ROUTE;
      return (
        <JobStopIcon
          jobStopId={stop.jobStopId}
          jobNumber={info.jobNumber}
          info={info}
          {...attributes}
          {...(mapJobStop.includes(stop.jobStopId) ? {primaryColor: "red"} : {})}
          sequenceNumber={stop.sequence as number}
          showPin={isExpanded}
          showSequenceNumber={isExpanded}
          sequenceNumberColor={
            (assignmentViewState?.assignmentTotalJobsNumbers || []).includes(info.jobNumber) ? "red" : info.routeColor
          }
          showJobNumber={info.isFirstStop || !isExpanded || alwaysShowJobNumber}
          handleAssignmentByClickingMarker={handleAssignmentByClickingMarker}
        />
      );
    }
  };
  return (
    <Tooltip2
      content={<StopInfo stop={stop} info={info} color={alwaysShowJobNumber ? "#000000" : "#FA545E"} />}
      openOnTargetFocus={false}
      position={PopoverPosition.TOP}
      usePortal={usePortal}
      portalContainer={newWindow?.document.body}
      popoverClassName={"marker-popover"}
    >
      <>{renderMarker(stop)}</>
    </Tooltip2>
  );
};

const SingleStopMarkerContainer = styled.div`
  position: absolute;
  transform: translate(-60%, -70%);
`;

const Styling = styled.div`
  width: fit-content;
  position: relative;
`;

const StopMarker: React.FC<
  {
    lat: number;
    lng: number;
    mappedStops: MappedStop[];
    mapJobStop?: number[];
    minimal?: boolean;
    additionalMapActionsFeature?: boolean;
    alwaysShowJobNumber?: boolean;
    assignmentViewState?: JobAssignmentViewState;
    gridRef?: MutableRefObject<AgGridReact<AgJob> | undefined>;
    usePortal?: boolean;
    newWindow?: Window;
  } & HTMLAttributes<HTMLDivElement>
> = ({
  mappedStops,
  mapJobStop = [],
  additionalMapActionsFeature,
  minimal,
  alwaysShowJobNumber,
  assignmentViewState,
  gridRef,
  usePortal,
  newWindow
}) => {
  const handleAssignmentByClickingMarker = useCallback(
    (info: StopInfoMetadata) => {
      const jobNumber = info.jobNumber;
      if (assignmentViewState?.totalJobsNumberAndJobsIdOnPages) {
        const jobId = findObjectByCondition(
          assignmentViewState?.totalJobsNumberAndJobsIdOnPages,
          "jobNumber",
          jobNumber
        )?.jobId;
        const rowNode = gridRef?.current?.api.getRowNode(`${jobId}`);
        if (rowNode) {
          if (rowNode.isSelected()) {
            rowNode.setSelected(false);
          } else {
            rowNode.setSelected(true);
            gridRef?.current?.api.ensureNodeVisible(rowNode, "middle");
          }
        }
      }
    },
    [assignmentViewState?.totalJobsNumberAndJobsIdOnPages, gridRef]
  );
  return (
    <Styling data-testid="stop-marker" className="stop-marker-styling relative">
      {mappedStops.length === 1 ? (
        <SingleStopMarkerContainer>
          <SingleStopMarker
            mapJobStop={mapJobStop}
            data-testid={`stop-marker-${mappedStops[0].jobStopId}`}
            stop={mappedStops[0]}
            info={mappedStops[0].stopInfoMetaData}
            additionalMapActionsFeature={additionalMapActionsFeature}
            minimal={minimal}
            alwaysShowJobNumber={alwaysShowJobNumber}
            handleAssignmentByClickingMarker={handleAssignmentByClickingMarker}
            assignmentViewState={assignmentViewState}
            usePortal={usePortal}
            newWindow={newWindow}
          />
        </SingleStopMarkerContainer>
      ) : (
        <MultiStopMarker
          usePortal={usePortal}
          newWindow={newWindow}
          mappedStops={mappedStops}
          mapJobStop={mapJobStop}
          alwaysShowJobNumber={alwaysShowJobNumber}
          handleAssignmentByClickingMarker={handleAssignmentByClickingMarker}
          assignmentViewState={assignmentViewState}
        />
      )}
    </Styling>
  );
};

export default StopMarker;
