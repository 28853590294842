/* eslint-disable react/no-string-refs */
import {Icon, Text} from "@blueprintjs/core";
import {Tooltip2} from "@blueprintjs/popover2";
import {IHeaderParams, SortDirection} from "@ag-grid-community/core";
import {Manifest} from "../../../../generated/graphql";
import {ManifestDetailsContext} from "../ManifestDetailsV2";
import styled from "@emotion/styled";
import {useState} from "react";

export interface JobColHeaderRendererParams extends IHeaderParams<Manifest, ManifestDetailsContext> {
  jobColoringIndex: Map<string, number>;
  stopLength: number;
  extraManifestFiltersEnable: boolean;
}

export const WarningIcon = ({color = "#FF6B00"}: {color?: string}) => (
  <svg
    style={{cursor: "pointer"}}
    width="14"
    height="14"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99976 16C10.1215 16 12.1563 15.1571 13.6566 13.6569C15.1569 12.1566 15.9998 10.1217 15.9998 8C15.9998 5.87827 15.1569 3.84344 13.6566 2.34315C12.1563 0.842855 10.1215 0 7.99976 0C5.87802 0 3.84319 0.842855 2.3429 2.34315C0.842611 3.84344 -0.000244141 5.87827 -0.000244141 8C-0.000244141 10.1217 0.842611 12.1566 2.3429 13.6569C3.84319 15.1571 5.87802 16 7.99976 16ZM7.99976 4C8.41538 4 8.74976 4.33437 8.74976 4.75V8.25C8.74976 8.66562 8.41538 9 7.99976 9C7.58413 9 7.24976 8.66562 7.24976 8.25V4.75C7.24976 4.33437 7.58413 4 7.99976 4ZM6.99976 11C6.99976 10.7348 7.10511 10.4804 7.29265 10.2929C7.48019 10.1054 7.73454 10 7.99976 10C8.26497 10 8.51933 10.1054 8.70686 10.2929C8.8944 10.4804 8.99976 10.7348 8.99976 11C8.99976 11.2652 8.8944 11.5196 8.70686 11.7071C8.51933 11.8946 8.26497 12 7.99976 12C7.73454 12 7.48019 11.8946 7.29265 11.7071C7.10511 11.5196 6.99976 11.2652 6.99976 11Z"
      fill={color}
    />
  </svg>
);

export const FilterIcon = () => {
  return (
    <svg
      data-testid="filter-icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      height="18px"
      viewBox="0 0 32 32"
    >
      <path d="M26 8.184c-0.066 2.658-4.058 5.154-6.742 7.974-0.168 0.196-0.252 0.424-0.258 0.682v3.66l-6 4.5c0-2.74 0.066-5.482-0.002-8.222-0.018-0.234-0.102-0.442-0.256-0.62-2.716-2.854-6.682-5.548-6.742-7.974v-2.184h20v2.184zM8 8c0 0.304 0.060 0.612 0.258 0.842 2.716 2.854 6.682 5.548 6.742 7.974v4.184l2-1.5v-2.684c0.066-2.658 4.058-5.154 6.742-7.974 0.198-0.23 0.258-0.538 0.258-0.842h-16z"></path>
    </svg>
  );
};

const renderSortIcon = (sortDirection: SortDirection | undefined) => {
  if (!sortDirection) return;
  return (
    <div style={{width: "16px", height: "16px"}}>
      {sortDirection === "asc" ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          fill="#4B5563"
          fillRule="evenodd"
          clipRule="evenodd"
          strokeLinejoin="round"
          strokeMiterlimit={2}
        >
          <path
            id="asc"
            d="M5.333,16L7.213,17.88L14.666,10.44L14.666,26.667L17.333,26.667L17.333,10.44L24.773,17.893L26.666,16L15.999,5.333L5.332,16L5.333,16Z"
          />
        </svg>
      ) : (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          fill="#4B5563"
          fillRule="evenodd"
          clipRule="evenodd"
          strokeLinejoin="round"
          strokeMiterlimit={2}
        >
          <path
            id="desc"
            d="M26.667,16L24.787,14.12L17.334,21.56L17.334,5.333L14.667,5.333L14.667,21.56L7.227,14.107L5.334,16L16.001,26.667L26.668,16L26.667,16Z"
          />
        </svg>
      )}
    </div>
  );
};
const JobColHeaderRenderer = ({
  jobColoringIndex,
  stopLength,
  progressSort,
  column,
  showColumnMenu,
  eGridHeader,
  extraManifestFiltersEnable
}: JobColHeaderRendererParams) => {
  const [showFilterIcon, setShowFilterIcon] = useState(false);
  const [hoverOnFilterIcon, setHoverOnFilterIcon] = useState(false);
  const sortDirection = column.getSort();
  const isFilterActive = column.isFilterActive();

  const handleShowFilterIcon = () => {
    setShowFilterIcon(true);
  };
  const handleHideFilterIcon = () => {
    setShowFilterIcon(false);
  };

  const handlePointerOnFilterIcon = () => {
    setHoverOnFilterIcon(true);
  };

  const handlePointerLeaveFilterIcon = () => {
    setHoverOnFilterIcon(false);
  };

  const handleShowFilter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    showColumnMenu(eGridHeader);
  };

  return (
    <Container
      onClick={() => progressSort()}
      onPointerEnter={handleShowFilterIcon}
      onPointerLeave={handleHideFilterIcon}
    >
      <Left>
        <Text ellipsize>JOB #</Text>
        {jobColoringIndex.size === 0 && stopLength > 0 && (
          <Tooltip2
            content={"Unique colors for each Job are only possible when there are 20 or less Jobs in the manifest."}
            popoverClassName="manifest-job-color-tooltip"
            placement="top-start"
          >
            <WarningIcon />
          </Tooltip2>
        )}
        {isFilterActive && <FilterIcon />}
        {renderSortIcon(sortDirection)}
      </Left>
      {extraManifestFiltersEnable && showFilterIcon && (
        <Right onClick={handleShowFilter}>
          <Icon
            data-testid="filter-icon"
            icon="menu"
            size={10}
            onPointerEnter={handlePointerOnFilterIcon}
            onPointerLeave={handlePointerLeaveFilterIcon}
            color={hoverOnFilterIcon ? "#2196f3" : "#797979"}
          />
        </Right>
      )}
    </Container>
  );
};

const Left = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;
  align-items: center;
`;
const Right = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default JobColHeaderRenderer;
