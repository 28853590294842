import {LatLong} from "../../domain/geocode-types";

class LocationMap {
  private static instance: Record<number, LatLong>;

  public static getInstance(): Record<number, LatLong> {
    if (!LocationMap.instance) {
      LocationMap.instance = {};
    }
    return LocationMap.instance;
  }

  public static clearInstance() {
    LocationMap.instance = {};
  }
}

export default LocationMap;
