import React, {useCallback, useEffect, useMemo, useState} from "react";
import styled from "@emotion/styled";
import FilterableMultiSelect from "../../common/FilterableMultiSelect";
import {OptionType} from "../../common/types/optionType";
import {PresentTagCommon} from "./PresentTagCommon";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

export interface PresentUponHoverBase {
  mainTitle: string;
  subTitle: string;
  reArrangeTitle: string;
  mainImg: string;
  dataTestId?: string;
  totalOptions: OptionType[];
  exceedMaxSelection: number;
  maxHeightOfOptionOrSelection?: number;
}
interface PresentUponHoverFormState {
  onFieldChange: (event: OptionType[]) => void;
  fieldValue: OptionType[];
}
export type PresentUponHoverBlockCommonProps = PresentUponHoverBase & PresentUponHoverFormState;
const emptySelectionText = "Don’t show anything when hover";
const defaultMaxWithOfDropDown = 200;
const emptySelectionMaxWitdhOfDropDown = 242;
const SecondaryColor = "#797979";
export const PresentUponHoverBlockCommon: React.FC<PresentUponHoverBlockCommonProps> = ({
  mainTitle,
  subTitle,
  reArrangeTitle,
  mainImg,
  dataTestId,
  totalOptions,
  exceedMaxSelection,
  onFieldChange,
  fieldValue,
  maxHeightOfOptionOrSelection
}) => {
  //Use State Section
  const [totalOptionsState, setTotalOptionsState] = useState<OptionType[]>(totalOptions);
  const [searchKeyWordsQuery, setSearchKeyWordsQuery] = useState("");
  //Use Callback Section
  const onOptionSelect = useCallback(
    (option: OptionType) => {
      if (fieldValue.includes(option)) {
        const remainingOptions = fieldValue.filter((selectedOption) => selectedOption !== option);
        onFieldChange(remainingOptions);
      } else if (!fieldValue.includes(option) && fieldValue.length < exceedMaxSelection) {
        const newSelectedOptions = [...fieldValue, option];
        onFieldChange(newSelectedOptions);
      }
    },
    [exceedMaxSelection, onFieldChange, fieldValue]
  );
  const onClear = useCallback(() => {
    onFieldChange([]);
  }, [onFieldChange]);

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }
      const newItems = [...fieldValue];
      const [removed] = newItems.splice(result.source.index, 1);
      newItems.splice(result.destination.index, 0, removed);
      onFieldChange(newItems);
    },
    [onFieldChange, fieldValue]
  );
  const renderFilterableMultiSelectText = useMemo(() => {
    return fieldValue.length > 0 ? `Preferred Hover Info (${fieldValue.length})` : emptySelectionText;
  }, [fieldValue.length]);

  const renderMaxWidthOfDropDown = useMemo(() => {
    return fieldValue.length > 0 ? defaultMaxWithOfDropDown : emptySelectionMaxWitdhOfDropDown;
  }, [fieldValue.length]);

  //Use Effect Section
  useEffect(() => {
    const filteredOptions = totalOptions.filter((option) => {
      return typeof option.key === "string" && option.key.toLowerCase().includes(searchKeyWordsQuery.toLowerCase());
    });
    setTotalOptionsState(filteredOptions);
  }, [searchKeyWordsQuery, totalOptions]);

  return (
    <HoverContainer data-testid={dataTestId}>
      <HoverTop>
        <LeftContent>
          <HoverTitle>{mainTitle}</HoverTitle>
          <HoverSubtitle>{subTitle}</HoverSubtitle>
          <FilterableMultiSelect
            options={totalOptionsState}
            selectedOptions={fieldValue}
            onOptionSelect={onOptionSelect}
            onClear={onClear}
            text={renderFilterableMultiSelectText}
            activeBackGroundColor="#8DA4BE"
            defaultTextColor="#161616"
            iconSize={14}
            maxWith={renderMaxWidthOfDropDown}
            onQueryChange={setSearchKeyWordsQuery}
            placeholder={"Search Values"}
            exceedMaxSelection={exceedMaxSelection}
            maxHeightOfOptionOrSelection={maxHeightOfOptionOrSelection}
          />
        </LeftContent>
        <RightContent>
          <img src={mainImg} alt="" />
        </RightContent>
      </HoverTop>
      <HoverBottom>
        <HoverSubtitle>{reArrangeTitle}</HoverSubtitle>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={"present-tag"} direction="horizontal">
            {(provided) => (
              <RenderTagContainer {...provided.droppableProps} ref={provided.innerRef}>
                {fieldValue.map((option, index) => {
                  return (
                    <Draggable key={option.key as string} draggableId={option.key as string} index={index}>
                      {(provided, snapshot) => {
                        return (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <PresentTagCommon
                              key={option.key}
                              option={option}
                              onChange={onOptionSelect}
                              isDragging={snapshot.isDragging}
                            />
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
              </RenderTagContainer>
            )}
          </Droppable>
        </DragDropContext>
      </HoverBottom>
    </HoverContainer>
  );
};

const HoverContainer = styled.div`
  font-family: "Roboto", sans-serif;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
`;

const HoverTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;
const LeftContent = styled.div``;
const RightContent = styled.div`
  margin-top: 10px;
`;
const HoverTitle = styled.p`
  font-size: 16px;
  font-weight: 400;
`;

const HoverSubtitle = styled.p`
  max-width: 314px;
  font-size: 14px;
  font-weight: 400;
  color: ${SecondaryColor};
  line-height: 16.41px;
  margin-bottom: 20px;
`;

const HoverBottom = styled.div``;

const RenderTagContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
