import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {SERVICES_API_URL} from "../../config";
import {AuthContext} from "../AuthProvider";
import _ from "lodash";

export interface ChatUserDetails {
  userId: string;
  userToken: string;
  apiKey: string;
}

const useChatUserDetails = (driverMessaging: boolean) => {
  const authContext = useContext(AuthContext);
  const [chatUserDetails, setChatUserDetails] = useState<ChatUserDetails | null>();

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (driverMessaging && authContext.token) {
        try {
          const response = await axios.get(`${SERVICES_API_URL}/chat/user-details`, {
            headers: {
              Authorization: `Bearer ${authContext.token}`
            }
          });

          if (response.data && response.status === 200) {
            const newDetails = response.data as ChatUserDetails;
            console.debug("Retrieved chat user details", newDetails);
            if (!_.isEqual(chatUserDetails, newDetails)) {
              console.debug("Updating chat user details", newDetails);
              setChatUserDetails(newDetails);
            }
          } else {
            console.error("Error retrieving user details for chat", response.data);
          }
        } catch (error) {
          console.error("Error fetching user details for chat", error);
        }
      }
    };

    fetchUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverMessaging, authContext.token]);

  return chatUserDetails;
};

export default useChatUserDetails;
