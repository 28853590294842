import {Button, Icon} from "@blueprintjs/core";
import {Placement} from "@blueprintjs/popover2";
import {ItemRenderer, Select2} from "@blueprintjs/select";
import styled from "@emotion/styled";
import {ReactElement} from "react";

type IndicatorDropDownProps<T> = {
  name:
    | "colorized-indicators"
    | "service-type-color"
    | "job-type"
    | "stop-type"
    | "color-selector"
    | "stop-status"
    | "manifest-status"
    | "lower-bound"
    | "upper-bound";
  dropdownPlacement?: Placement;
  disable?: boolean;
  onSelectedItem(item: any): void;
  itemRenderer: ItemRenderer<T>;
  items: T[];
  selectedItemText?: string;
  placeholderText?: string;
  tag?: ReactElement;
  filterable?: boolean;
  itemPredicate?: (query: string, item: any) => boolean;
  onClear?: () => void;
};

type StyledButtonProps = {
  hasValue: boolean;
  defaultTextColor: string;
  activeBackGroundColor: string;
  maxWidth?: number;
};

export const StyledButton = styled(Button)<StyledButtonProps>`
  position: relative;
  width: ${(props) => props.maxWidth && `${props.maxWidth}px`};
  .bp4-button-text {
    display: flex;
    flex-direction: row;
    align-items: end;
  }

  .clear-icon {
    position: absolute;
    opacity: 0;
    color: #ffffff;
    right: 10px;

    :hover {
      opacity: 1;
      background-color: #8da4be;
    }
  }

  .dropdown-icon {
    color: ${(props) => (props.hasValue ? "#ffffff" : `${props.defaultTextColor} !important`)};
  }

  background: ${(props) => (props.hasValue ? `${props.activeBackGroundColor} !important` : "#ffffff !important")};
  color: ${(props) => (props.hasValue ? "#ffffff !important" : `${props.defaultTextColor} !important`)};
  box-shadow: 0px 1px 2px 0px #00000040 !important;
  padding: 6px 10px 6px 10px !important;
  border-radius: 4px !important;

  :disabled {
    opacity: 0.5;
  }
`;

const StyledButtonText = styled.div`
  margin-left: 4px;
`;
type IndicatorListItemProps = {
  isSelected: boolean;
};

const IndicatorListItem = styled.li<IndicatorListItemProps>`
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  padding: 6px;
  border-radius: 4px;
  align-items: center;
  margin: 2px 0px;
  background-color: ${(props) => (props.isSelected ? "#0038FF1A" : "transparent")};

  :hover {
    background: #0038ff1a;
  }
`;

const IndicatorListTagItem = styled(IndicatorListItem)<IndicatorListItemProps>`
  display: flex;
  flex-direction: row;
  gap: 12px;
  color: #161616;
`;

const IndicatorSelect = <T,>({
  name,
  dropdownPlacement = "bottom",
  disable = false,
  onSelectedItem,
  itemRenderer,
  items,
  selectedItemText,
  placeholderText,
  tag,
  filterable = false,
  itemPredicate,
  onClear
}: IndicatorDropDownProps<T>) => {
  const StyledSelect = styled(Select2<T>)`
    font-family: "Roboto", sans-serif;
  `;

  return (
    <StyledSelect
      popoverProps={{portalClassName: `${name}-select-popover`, matchTargetWidth: true, placement: dropdownPlacement}}
      itemRenderer={itemRenderer}
      onItemSelect={onSelectedItem}
      items={items}
      filterable={filterable}
      itemPredicate={itemPredicate}
      noResults={<div>No results</div>}
      className={`${name}-target`}
    >
      <StyledButton
        disabled={disable}
        hasValue={Boolean(selectedItemText)}
        rightIcon={<Icon className="dropdown-icon" icon="chevron-down" />}
        activeBackGroundColor={"#8DA4BE"}
        defaultTextColor={"#8DA4BE"}
        data-testid={`${name}-action-button`}
      >
        {selectedItemText ? tag : ""}
        <StyledButtonText>{selectedItemText ?? placeholderText}</StyledButtonText>
        {onClear && Boolean(selectedItemText) && (
          <Icon data-testid="clear-button" className="clear-icon" icon="cross" onClick={onClear} />
        )}
      </StyledButton>
    </StyledSelect>
  );
};

export default IndicatorSelect;
export {IndicatorListItem, IndicatorListTagItem};
