import {Spinner} from "@blueprintjs/core";
import styled from "@emotion/styled";

type LoadingSpinnerProps = {
  loading?: boolean;
  children?: JSX.Element;
  size?: number;
};

const CenteredSpinner = styled(Spinner)`
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const LoadingSpinner = ({loading, children, size}: LoadingSpinnerProps) => {
  if (loading !== undefined && children && !loading) {
    return children;
  } else {
    return <CenteredSpinner size={size} />;
  }
};
