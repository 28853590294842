import {useEffect, useState} from "react";
import {
  RoutingRequest,
  RoutingResponseInfo,
  RoutingResultPath,
  Scalars,
  useGetRouteLazyQuery
} from "../../generated/graphql";
import decodePolyLine from "./DecodePolyLine";
import {Cords} from "./MappingService";
import {RouteViewMode} from "./manifest-details/ManifestDetailsMap";
import {Constants} from "../common/Constants";
import {debounce} from "lodash";

export interface RoutePath {
  key: string;
  points: Cords[];
  color?: string;
  mode?: RouteViewMode;
}

export interface RouteInfo {
  key: string;
  info: RoutingResponseInfo;
  paths: RoutingResultPath[];
  color?: string;
  /*
   * These are the decoded points, the original encoded or decoded versions are under paths
   */
  points: Array<Array<Scalars["Float"]>>;
  mode?: RouteViewMode;
}

const useMapRoutes = (): [RouteInfo[] | undefined, (routes: RoutePath[]) => void] => {
  const [routes, setRoutes] = useState<RoutePath[]>();
  const [routeInfo, setRouteInfo] = useState<RouteInfo[]>([]);
  const [getRoutes] = useGetRouteLazyQuery();

  useEffect(() => {
    const processRouteRequest = async (route: RoutePath) => {
      const routeResponse = await getRoutes({
        variables: {
          routingRequest: {
            calcPoints: true,
            optimize: false,
            points: route.points.map((point: {lng: number; lat: number}) => {
              return [point.lng, point.lat];
            }),
            pointsEncoded: true,
            source: Constants.GRAPHHOPPER_SOURCE
          } as RoutingRequest
        }
      });

      if (routeResponse.error) {
        console.error("[useMapRoutes3] Error processing route request", routeResponse.error);
      }

      const routeInfos = routeResponse.data?.route?.result.paths.map((path) => {
        const points = path.pointsEncoded
          ? decodePolyLine(path.points?.encodedLine as string).map((item) => [item.lat, item.lng])
          : path.points?.coordinates;

        return {
          key: route.key,
          color: route.color,
          mode: route.mode,
          points: points,
          paths: routeResponse.data?.route?.result.paths,
          info: routeResponse.data?.route?.result.info
        } as RouteInfo;
      });

      return routeInfos ?? [];
    };
    const handleSetRoutesInfo = debounce(() => {
      setRouteInfo([]);
      routes?.forEach((route) => {
        if (route.points.length > 0) {
          processRouteRequest(route).then((result) => {
            setRouteInfo((prevState) => {
              return [...prevState, ...result];
            });
          });
        }
      });
    }, 300);

    handleSetRoutesInfo();
    return () => {
      handleSetRoutesInfo.cancel();
    };
  }, [getRoutes, routes]);
  return [routeInfo, setRoutes];
};

export default useMapRoutes;
