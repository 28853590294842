import {useEffect, useRef} from "react";

type IntervalFunction = () => unknown | void;
interface UseIntervalProps {
  callback: IntervalFunction;
  delay: number;
}

const useInterval = ({callback, delay}: UseIntervalProps) => {
  const savedCallback = useRef<IntervalFunction | null>(null);

  useEffect(() => {
    savedCallback.current = callback;
  });
  useEffect(() => {
    const tick = (): void => {
      if (savedCallback.current) {
        console.debug("Executing interval callback.");
        savedCallback.current();
      }
    };
    const id = setInterval(tick, delay);
    return () => {
      console.debug("Clearing interval.");
      clearInterval(id);
    };
  }, [delay]);

  return this;
};

export type {IntervalFunction};
export default useInterval;
