import {Elevation} from "@blueprintjs/core";
import {useEffect} from "react";
import {Manifest} from "../../../generated/graphql";
import {createCardHandlers} from "../ManifestCard";
import {CardContainerV3, ManifestCardV3} from "../ManifestDriverCardStyles";
import styled from "@emotion/styled";
import {ManifestCardHeader, StopInfoItem} from "../ManifestCardCommonV3";
import {
  getAllActiveStops,
  getFinalCompletedStop,
  getNextActiveStops,
  getTopStopByConfig
} from "../../../services/ManifestStopService";
import {ManifestCardProps} from "../ManifestCardCommon";
import {TopStopInformationValue} from "../card-configuration/ManifestConfigurationTypes";
import {Constants} from "../../common/Constants";

export const getStopList = (
  manifest: Manifest,
  isSelected: boolean,
  topStopInfo: TopStopInformationValue,
  numberOfStops: number
) => {
  const finalCompletedStop = getFinalCompletedStop(manifest);

  //if final completed stop exists, return it
  if (finalCompletedStop) {
    return [finalCompletedStop];
  }

  const allActiveStops = getAllActiveStops(manifest);

  const topStop = getTopStopByConfig(manifest, topStopInfo);

  if (topStop) {
    const newStopList = allActiveStops?.filter((s) => ![topStop.jobStopId].includes(s.jobStopId));
    newStopList?.unshift(topStop);
    return isSelected ? newStopList : newStopList?.slice(0, numberOfStops);
  }

  //if no onsite or lowest rank completed stop, return next active stops
  return isSelected ? allActiveStops : getNextActiveStops(manifest, numberOfStops);
};

export const scrollToElement = (elementId: string) => {
  const element = document.getElementById(elementId);

  if (element) {
    const elementRect = element.getBoundingClientRect();
    const elementTop = elementRect.top;
    const elementBottom = elementRect.bottom;

    if (elementTop < 0 || elementBottom > window.innerHeight) {
      element.scrollIntoView({behavior: "smooth"});
    }
  }
};

const ManifestDriverCardV3 = ({
  manifest,
  colors,
  isSelected,
  isHighlighted,
  disabled,
  configurationState,
  cardType,
  onSelectedManifest,
  onDrillDownManifest,
  onLocationMarkerClick
}: ManifestCardProps) => {
  const {handleOnSelected, handleDrillDownManifest} = createCardHandlers({
    onSelectedManifest,
    onDrillDownManifest
  });

  const stopList = getStopList(
    manifest,
    isSelected,
    configurationState.topStopInformation,
    Constants.NUMBER_OF_DETAILED_CARD_STOPS
  );

  useEffect(() => {
    if (isSelected) {
      scrollToElement(`manifest-detailed-card-item-${manifest.manifestDriverId}`);
    }
  }, [isSelected, manifest.manifestDriverId]);

  return (
    <CardContainerV3
      id={`manifest-dnd-target-${manifest.manifestDriverId}`}
      data-manifestdriverid={manifest.manifestDriverId}
      data-cardtype={"card"}
    >
      <ManifestCardV3
        id={`manifest-detailed-card-item-${manifest.manifestDriverId}`}
        elevation={Elevation.ONE}
        isSelected={isSelected}
        isHighlighted={isHighlighted}
        interactive={!disabled}
        disabled={disabled}
        data-testid={`manifest-detailed-card-item-${manifest.manifestDriverId}`}
        data-manifest={JSON.stringify(manifest)}
        onClick={(e: any) => {
          if (!disabled) {
            handleOnSelected(e, manifest);
          }
        }}
      >
        <CardGrid data-testid={"card-grid"} isSelected={isSelected} bodyHeight={105}>
          <ManifestCardHeader
            manifest={manifest}
            handleDrillDownManifest={handleDrillDownManifest}
            onLocationMarkerClick={onLocationMarkerClick}
            stopsColor={colors ?? []}
            isSelected={isSelected}
            disabled={disabled}
            configurationState={configurationState}
            cardType={cardType}
          />
          <StopListContainer>
            <ScrollableList
              id={`scroll-list-${manifest.manifestDriverId}`}
              data-testid="scroll-list"
              isSelected={isSelected}
              hasScroll={(stopList ?? []).length > Constants.NUMBER_OF_DETAILED_CARD_STOPS}
            >
              {stopList?.map((x, index) => {
                const color = (colors ?? []).find((y) => y.jobStopID === x.jobStopId)?.color ?? "";
                return (
                  <StopInfoItem
                    index={index}
                    key={`card-stop-info-${x.jobStopId}`}
                    stop={x}
                    color={color}
                    disabled={disabled ?? false}
                    configurationState={configurationState}
                    cardType={cardType}
                  />
                );
              })}
            </ScrollableList>
          </StopListContainer>
        </CardGrid>
      </ManifestCardV3>
    </CardContainerV3>
  );
};

export default ManifestDriverCardV3;

export const CardGrid = styled.div<{isSelected: boolean; bodyHeight: number}>`
  position: relative;
  display: grid;
  grid-template-rows: ${(props) => `32px ${props.bodyHeight}px`};
  justify-items: center;
  align-items: center;
  height: 100%;

  .small-card {
    grid-template-columns: 44px auto 16px 42px !important;

    .stop-time {
      letter-spacing: 0.5px !important;
    }
  }

  & > *:first-child {
    background-color: ${(props) => (props.isSelected ? "rgba(64, 105, 255, 0.04) !important" : "#f4f4f4 !important")};
  }

  &:hover > *:first-child {
    background-color: rgba(64, 105, 255, 0.04) !important;
  }
`;

export const StopListContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 4px 4px 8px 8px !important;
`;

export const ScrollableList = styled.div<{isSelected?: boolean; hasScroll?: boolean}>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: ${(props) => (props.isSelected ? "auto" : "hidden")};
  position: relative;
  padding-right: ${(props) => (props.isSelected && props.hasScroll ? "2px" : "4px")};

  //set width of scrollbar
  ::-webkit-scrollbar {
    width: 2px;
  }

  //set border radius for scrollbar
  ::-webkit-scrollbar-thumb {
    border-radius: 1px;
    margin: 2px;
    background-color: #16161680;
  }
`;
