// Pulled this from the Graphhopper api
//https://github.com/graphhopper/directions-api-js-client/blob/master/src/GHUtil.js

type decodedItem = {
  lat: number;
  lng: number;
};
const decodePolyLine = (encoded: string) => {
  const len = encoded.length;
  let index = 0;
  const array = [];
  let lat = 0;
  let lng = 0;

  while (index < len) {
    let b;
    let shift = 0;
    let result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    const deltaLat = result & 1 ? ~(result >> 1) : result >> 1;
    lat += deltaLat;

    shift = 0;
    result = 0;
    do {
      b = encoded.charCodeAt(index++) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    const deltaLon = result & 1 ? ~(result >> 1) : result >> 1;
    lng += deltaLon;

    array.push({
      lat: lat * 1e-5,
      lng: lng * 1e-5
    } as decodedItem);
  }
  // var end = new Date().getTime();
  // console.log("decoded " + len + " coordinates in " + ((end - start) / 1000) + "s");
  return array;
};

export default decodePolyLine;
export type {decodedItem};
