import {Card} from "@blueprintjs/core";
import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";

const shouldForwardPropForDispatchCard = (property: string) => {
  const validHtmlProperty = isPropValid(property);
  const shouldPassUpToStyledComponent = property === "interactive" || property === "elevation";
  const shouldNotPass = property === "isSelected" || property === "isHighlighted" || property === "visible";

  return (validHtmlProperty || shouldPassUpToStyledComponent) && !shouldNotPass;
};

type DispatchCardProps = {
  isSelected?: boolean;
  isHighlighted?: boolean;
  disabled?: boolean;
  visible?: boolean;
};

const DispatchCardV3 = styled(Card, {
  shouldForwardProp(propName: string) {
    return shouldForwardPropForDispatchCard(propName);
  }
})<DispatchCardProps>`
  display: ${(props) => (props.visible !== undefined && !props.visible ? "none" : "block")};
  box-shadow: 0px 2px 1px 0px #2c3a6e0f;
  background-color: ${(props) => (props.isSelected ? "#e3e3fd" : "#FCFCFC")};
  border: ${(props) => (props.isSelected || props.isHighlighted ? "1px solid #406AFF" : "1px solid #CFD4DF")};
  border-radius: 8px;
  overflow: hidden;
  transition: ${(props) => (props.isHighlighted ? "border 0.5s ease-in-out" : "")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover:not([disabled]) {
    background-color: ${(props) => (props.isSelected ? "" : "#f6f6fe !important")};
    border: 1px solid #406aff;
    box-shadow: 0px 2px 1px 0px #2c3a6e0f;
  }
`;

export {DispatchCardV3};
