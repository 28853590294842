import styled from "@emotion/styled";
import {DateRangePicker} from "@blueprintjs/datetime";

const ManifestDateRangePicker = styled(DateRangePicker)`
  width: 100%;
  padding: 20px 10px 20px;

  .bp4-divider {
    display: none;
  }

  .bp4-datepicker,
  .bp4-datepicker-content,
  .DayPicker,
  .DayPicker-wrapper,
  .DayPicker-Month {
    width: 100%;
  }

  .DayPicker-Month {
    margin: 0;
  }

  .DayPicker-Day--isToday {
    position: relative;

    ::after {
      position: absolute;
      content: "";
      width: 30px;
      height: 30px;
      border-radius: 50% !important;
      border: 1px solid #436bf8;
      top: 5px;
      right: 6px;
    }
  }

  .DayPicker-Day--selected {
    background-color: #8da4be !important;
    border-radius: 4px !important;

    ::after {
      display: none;
    }
  }

  .bp4-datepicker-day-wrapper {
    font-size: 14px;
    font-weight: 400;
    padding: 0;
    box-shadow: none;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
  }

  .DayPicker-Weekdays {
    font-size: 15px;
    font-weight: 500;

    .DayPicker-Weekday {
      padding: 0;
      height: 40px;
      font-size: 15px;
      font-weight: 500;
    }
  }

  .DayPicker-Caption {
    .bp4-datepicker-caption {
      justify-content: center;

      > * {
        flex-grow: 0 !important;
      }
      align-items: center;
      .bp4-html-select {
        select {
          padding: 4px !important;
          font-size: 16px;
          font-weight: 500;
        }
        option {
          padding: 5px !important;
        }
      }

      .bp4-icon-double-caret-vertical {
        display: none;
      }
    }
  }
`;

export default ManifestDateRangePicker;
